import React, { Component, Fragment } from "react";
import "./LoanMakePay.css";
import { InputGroup, Input, Label, Card, Button, Row, Col } from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import CalanderIcon from "../../../images/date-icon.png";
import {urls} from "../../../services/UrlList";
import UserPicker from "../LoanDetails/UserPicker";

export default class LoanMakePay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRePay: new Date(),
      comment:'',
      amount:'',
      mode:'',
      errors:[],
      selectedUser:0,
    };
  }

  onChangeRePay = e => {
    this.setState({ dateOnePay: e });
  };

  postRepayLoan = () =>
  {
    if(this.state.amount < 0 || this.state.amount === '')
    {
       this.setState(previousState => ({
            errors: [...previousState.errors, 'amount']
        }));
        return false;
    }else if( this.state.mode ==='')
    {
        this.setState(previousState => ({
            errors: [...previousState.errors, 'mode']
        }));
        return false;
    }else if(this.state.selectedUser <= 0 || this.state.selectedUser === "")
    {
       this.setState(previousState => ({
            errors: [...previousState.errors, 'member']
        }));
        return false;
    }
    else
    {
        let opts = {
          user_id: this.state.selectedUser,
          reviewed_by: this.props.myid,
          repay_amount: this.state.amount,
          mode: this.state.mode,
          comment: this.state.comment,
          repaydate: this.state.dateRePay
        };
        console.log(opts);
        fetch(urls.postRepayLoan,
        {
          headers:
          {
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {
            this.props.refreshaction();
        })
        .catch((error) =>
        {
            this.setState({        
                progressBar:false,
              });
        })
    }
  }

  onPeopleChange = (people) =>
  {
    this.setState({selectedUser: people});
  }

  render() {
    return (
      <Card id="replay-loan" className="loan-module">
         <div className="form-body">
           <Row>
             <Col xs={12} lg={12}>
             <InputGroup className="repay-amount choose_user">
              <Label htmlFor="repay-apply-amount">Member</Label>
              <UserPicker 
              classname={this.state.errors.includes('member')? 'error-input':''} 
               myid={this.props.myid} peoplechange = {this.onPeopleChange} /> 
            </InputGroup>
             </Col>
             <Col xs={12} lg={12}>
             <InputGroup className="repay-repay-date">
              <Label htmlFor="repay-apply-repay-date">Repayment Date</Label>
              <DateTimePicker
                format="dd-MM-y"
                value={this.state.dateRePay}
                onChange={this.onChangeRePay.bind(this)}
                disableClock="true"
                clearIcon
                calendarIcon={
                  <img
                    className="calander-icon"
                    src={CalanderIcon}
                    width="18"
                    height="19"
                    alt="calander-icon"
                  />
                }
              />
            </InputGroup> 
             </Col>
             <Col xs={12} lg={12}>
             <InputGroup className="repay-amount">
              <Label htmlFor="repay-apply-amount">Repayment Amount</Label>
              <Input id="repay-apply-amount" type="number" placeholder=""  onChange={(e)=>this.setState({amount:e.target.value, errors:[],}) }
              className={this.state.errors.includes('amount')? 'error-input':''}  />
            </InputGroup>
             </Col>
             <Col xs={12} lg={12}>
             <InputGroup className="repay-apply-date">
              <Label htmlFor="repay-apply-amount">Payment Mode</Label>
              <Input id="repay-apply-amount" type="select" placeholder="" onChange={(e)=>this.setState({mode:e.target.value, errors:[]}) }
              className={this.state.errors.includes('mode')? 'error-input':''} >
                <option value=''>Select</option>
                <option value='1'>Google Pay</option>
                <option value='2'>Amazon Pay</option>
                <option value='3'>Bank Transfer</option>
                <option value='4' >Cash in Hand</option>   
                <option value='5' >Deduction from Salary</option>             
              </Input>
            </InputGroup>
             </Col>
             <Col xs={12} lg={12}>
             <div className="repay-apply-reason">
              <Label htmlFor="repay-apply-reason">Comments</Label>
                <Input
                  id="repay-apply-reason"
                  type="textarea"
                  placeholder="Comments...(optional)"
                  onChange={(e)=>this.setState({comment:e.target.value}) }
                />
              </div>
             </Col>
             <Col xs={12} lg={12}>
             <div className="repay-apply-button">
            <Button id="leave-apply-btn" className="btn btn-green" onClick={this.postRepayLoan.bind(this)}>
              Submit
            </Button>
          </div>
             </Col>
           </Row>
         
          
        </div>
      </Card>
    );
  }
}