import React, { Component } from 'react'
import './FeedComments.css'
import FeedCommentLike from './FeedCommentAction/FeedCommentLike/FeedCommentLike';
import FeedCommentCount from './FeedCommentAction/FeedCommentCount/FeedCommentCount';
import FeedCommentItem from './FeedCommentItem/FeedCommentItem'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';


export default class FeedComments extends Component {
  constructor(props){
    super(props)
    this.state={
      open: false,
      activeEditor:0,
    }
  }

  handleUpdateComment = (id, e) => {
    this.setState({activeEditor: id});
    this.props.commentAction(e.target.name, e.target.value)
  }
    
  handlePostComment = (e) => {
    if(e.key === 'Enter') {
      this.props.postFeedComment( this.props.feedId, this.props.commentValue, this.props.fName )
    }
  }

  toggleComment = () => {
    this.setState({open: !this.state.open})
  }

  render() {
    const { 
      feedId, 
      isLike, 
      likeCount, 
      likeAction, 
      commentCount, 
      commentValue, 
      commentAction, 
      postCommentAction,
      allComments, 
      allCommentsLengh,
      fName,
      showComment } = this.props;
    let s = this.state.open ? 'feed_comment_open' : 'feed_comment_close';
    let cls = allCommentsLengh > 1 && 'fixed_height';
    return (
      <div className="feed_comment_wrapper">
        <div className="feed_comment_action_wrapper d-flex">
          
          <FeedCommentLike 
            isliked={isLike} 
            likeCount={likeCount} 
            likeAction={likeAction} 
            feedId={feedId} />

          <FeedCommentCount 
            count={commentCount}
            feedId={feedId} />

        </div>
        
            <Input 
              type="textarea"
              className="feed_comment_input sdfadfadsf"
              placeholder="Write a comment.."
              onChange={this.handleUpdateComment.bind(this, feedId)}
              onKeyDown={this.handlePostComment}
              name={this.props.fName}
              value={this.state.activeEditor === feedId? commentValue :'' } />

            

            <>
            {allCommentsLengh ? (
              <div className={`feed_comment_items_wrapper ${s} ${cls}`} >
                  { this.props.allComments.map((cm, index) => {
                      return (
                        <FeedCommentItem 
                          name={cm.name} 
                          msg={cm.comment} 
                          cmDate={cm.commented_on}
                          userid={cm.id} />
                      )
                    })
                  }
                </div>
                ) : ''
            }
            </>
            
        
        { allComments.length > 2 && <div className="loadMore_comment" onClick={this.toggleComment}></div> }
      </div>
    )
  }
}
