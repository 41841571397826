

const NotificationCountReducer = (state = 0, action) =>
{
    switch(action.type)
    {
        case 'AddNotificationCount':
           // console.log(action.payload);
            return action.payload;
        case 'AdditionNotificationCount':
                return state+1;
        case 'MinusNotificationCount':
            return state-1;
        case 'UnreadNotificationCount':
            return action.payload;
        case 'EmptyNotificationCount':
                return 0;
        default:
            return state;
    }
}

export default NotificationCountReducer;