import React, {Component, Fragment} from 'react'
import "./AddNewFeed.css"
import { Input, Label } from 'reactstrap'
import AddNewFeedForm from './AddNewFeedForm/AddNewFeedForm'

export default class AddNewFeed extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: this.props.showForm,
      displayFake: true
    }
    this.mockInputAddFeedRef = React.createRef();
    this.mockButonAddFeedRef = React.createRef();
  }

  showForm = () => {
    this.setState({display: true})
  }

  onCancelForm = () => {
    this.setState({display: false})
  }

  render() {
    return (
      <section 
        id="addNewFeedWrapper"
        className={this.props.showForm} >
        {
          this.state.display
            ? <AddNewFeedForm 
                display={this.state.display}
                onCancelForm={this.onCancelForm.bind(this)}
                postNewFeed={this.props.postNewFeed}
                myid={this.props.myid}
              />
            : (
              <div className="d-flex addNewFeedAnchor">
                <Input 
                  type="text"
                  id="addNewFeed_input" 
                  placeholder="Write something here.."
                  onClick={this.showForm}
                  ref={this.mockInputAddFeedRef} />
                <Label 
                  for="addNewFeed" 
                  className="addNewFeed_label"
                  onClick={this.showForm} 
                  ref={this.mockButonAddFeedRef} >Add Post</Label>
              </div>
            )
        }
      </section>
    )
  }
}