import React, { Component, Fragment } from 'react';
import DatePickerTick from "./DatePickerTick";
import UserPicker from "./UserPickerDailyLog";
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button, Label } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import StopSearch from "../../images/stop-search.png";
import UserTeams from "./userTeams";
import MaterialIcon, { Outlined } from 'material-icons-react';

import 'react-dates/initialize';
import { DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

export default class Filter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {              
      selectedUser:'',
      start_date:momentStringStart,
      end_date:momentStringEnd, 
      startDate:momentObjs,
      endDate:'', 
      myPrivileges:[],  
      pmTeamLead:0,   
      myId:'', 
      myBaseId:'',
      userTeam:'',
      selectedTeam:0,
      selectedCount:'Select',      
      showAllPeople: 1,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
};
onDateRangeChangeEnd = (end) => {
  let momentObj = moment(end);
  let momentString = momentObj.format("DD-MM-YYYY");
  this.setState({end_date:momentString});
};

handleChange = ({ target }) => {
  this.setState({ [target.name]: target.value });
};

onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}

onTeamChange = (teamid) =>
{
 this.setState({selectedTeam:teamid});
}

searchEntriesnew = () =>
{  
  this.props.searchEntriesone(this.state.startDate, this.state.endDate, this.state.selectedUser, this.state.selectedTeam, this.state.pmTeamLead);
}

hoursRender = () =>  {
  const optionsval = [];
  for (var i=1; i <=  12; i++) {
    optionsval.push(<option selected={this.state.filterHour == i? 'selected':''} value={i}>{"< "+i+" hour"}</option>);
  }
  return optionsval;
};

pendngTaskRender = () =>  {
  const optionsval = [];
  for (var i=0; i <=  15; i++) {
    optionsval.push(<option selected={this.state.selectedCount == i? 'selected':''} value={i}>{"<= "+i+" Tasks"}</option>);
  }
  return optionsval;
};

filterByHours=(e)=>
{ 
  this.setState({filterHour:e.target.value}); 
  if (e.target.checked) {
  this.props.filterbyhour(e.target.value);
  }else
  {
    this.props.filterbyhour('');
  }
};

filterByTasks=(e)=>
{ 
  this.setState({selectedCount:e.target.value}); 
  this.props.filterbytask(e.target.value);
};

filterByNonProductive = (e)=>
{
  if (e.target.checked) {
    this.props.filterbynonproductive(e.target.value);
  }else
  {
    this.props.filterbynonproductive('');
  }
}
showPeopleChange = () =>
{
  if(this.state.showAllPeople == 0)
  {
    this.setState({showAllPeople: 1});
  }else
  {
    this.setState({showAllPeople: 0});
  }
}

stopActiveSearch =()=>
{
  this.props.stopactivesearch();
}

  render() {
    let searchContent = window.innerWidth >= 600 ?  <MaterialIcon icon="arrow_forward"/> : 'Search Log';
    return (
      <Fragment>
      
      <div id="filter"  className="log-timer-filters">
      <Row>
      <Col xs={12} sm={10} lg={12}>
         <Row>
          <Col xs={12} sm={6} lg={4}>
              <div className="start-date date-col datepicker-col daily-logg-filters">

                <p>Date: </p>
                {/* <DatePickerTick startPoint='1' onDateRangeChange={this.onDateRangeChangeStart} /> */}
                <DateRangePicker
                    showClearDates={()=>true}
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="sid" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="eid" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
              </div>
          </Col>
        
            {this.state.myPrivileges.includes('access_others_report')?

              (
              
              <Col xs={12} sm={6} lg={3}>
              <UserPicker myid={this.state.myId} baseid={this.state.myBaseId} teamid={this.state.userTeam} usertype={this.state.showPeopleType} onPeopleChange={this.onPeopleChange.bind(this)}  showall={this.state.showAllPeople}/>
              </Col>
              )
      
             
              :
              null
              }
        
         
          {(this.state.myPrivileges.includes('admin_level_filters') || this.state.myPrivileges.includes('access_others_report')) ?
              (

              <>
              <Col xs={12} sm={6} lg={2}>
               <div class="people date-col pl_list">
                  <p class="filter-label">PL: </p>
                  <select name="pmTeamLead" className="form-control" 
                    onChange={this.handleChange} >
                        <option value="">Select</option>
                    {this.props.pmTeamLeads.map((item, index)=>
                    {
                      return(
                        <option key={index} value={item.id} 
                        selected={this.state.pmTeamLead == item.id? 'selected':''}
                        >{item.name}</option>
                      )
                    })}
                  </select>
                  </div>
               </Col>

                 <Col xs={12} sm={6} lg={2}>
                  <UserTeams myid={this.state.myId} baseid={this.state.myBaseId} teamid={this.state.userTeam} usertype={this.state.showPeopleType} onteamchange={this.onTeamChange}  showall={this.state.showAllPeople}/>
                 </Col>
                 
              </>

              )
              :
              null
              }
              <Col xs={12} sm={2} lg={1}>
        <div className="search-btn d-flex align-items-center">
                  
                  {(this.props.searchrunning && this.props.listcount>0) ?
                  (
                    <img title="stop currently running search" src={StopSearch} className="stop-active-search" onClick={this.stopActiveSearch} />
                  ):
                  (<Button  color="success"  onClick={this.searchEntriesnew.bind(this)} >
                    { searchContent }                  
                  </Button>
                  )}
                </div>
        </Col>
         </Row>
       </Col>
    
       
       </Row>
          {this.state.myPrivileges.includes('access_others_report')? null
              :
              (<div className="end-date date-col datepicker-col"></div>)
              }
       
  
         
         
            <div className="d-flex daily-log-adv-filter daily-log-adv-filter2">
            {(this.props.listcount>0 && this.state.myPrivileges.includes('admin_level_filters')) && 
               (
                <div className="hours d-flex ml-auto p-2 min-work-hour">
                   <FormGroup check>
                      <Input
                        id="hourfilter"
                        name="check"
                        type="checkbox"
                        value="7:30" onChange={this.filterByHours.bind(this)}
                      />
                      <Label
                        check
                        for="hourfilter"
                      >
                      {'< 07:30 Work Hours'}
                      </Label>
                    </FormGroup>
                </div>
               )
              }
              {(this.props.listcount>0 && this.state.myPrivileges.includes('admin_level_filters')) && 
               (
                <div className="hours d-flex  p-2 min-work-hour">
                  <FormGroup check>
                      <Input
                        id="nonproductive"
                        name="check"
                        type="checkbox"
                        onChange={this.filterByNonProductive.bind(this)}
                      />
                      <Label
                        check
                        for="nonproductive"
                      >
                      {'> 2 hrs Unproductive'}
                      </Label>
                    </FormGroup>
                  
                </div>
               )
              }
              </div>

            
          
    
      </div>
      </Fragment>
    )
  }
}