import React, { Component, Fragment } from 'react';
import "./momeeting.css"
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Select from 'react-select';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  FormGroup, 
  Input, 
  Button, 
  Table,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Label } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
const options = [
  { value: 'PL Team Meeting', label: 'PL Team Meeting' },
  { value: 'Qa Meeting', label: 'Qa Meeting' },
];

const MeetingMembersoptions = [
  { value: 'Prem', label: 'Prem' },
  { value: 'Tibin', label: 'Tibin' },
  { value: 'Murali M', label: 'Murali M' },
  { value: 'Hasthik', label: 'Hasthik' },
  { value: 'Suhas', label: 'Suhas' },
  { value: 'Megha', label: 'Megha' },

];



export default class Meetinglist extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      allBoards:[],
      allBoardLastupdate:'',
      firstHalf:[],
      secondHalf:[],
      orginalUsers: [],
      singleCollection:[],
      showSingle:false,
      initialLoader:true,
      activeUserId:'',
      boardUrl:'',
      actionStatus:false,
      showMenu:true,
      modal: false,
      selectedOption: null,
      selectedOptionhost: null,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
   this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  handleChangehost = (selectedOptionhost) => {
    this.setState({ selectedOptionhost }, () =>
      console.log(`Option selected:`, this.state.selectedOptionhost)
    );
  };

  componentDidMount =() =>
  {   
    document.title = "HUB - Trello Active Boards";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {  
      if(_this.state.myPrivileges.includes('i_am_pl'))
      {
          _this.getAllCollections();
          _this.getAllBoards();
      }else
      {
        _this.getMyTrelloCollection();
      }
    }, 200);
  };

  componentDidUpdate(prevState) {  
    if (prevState.actionStatus !== this.state.actionStatus  && this.state.actionStatus === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({actionStatus:false})
      }, 6000);  
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }

  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  getMyTrelloCollection = () =>{        
    fetch(urls.getMyTrelloCollection+this.state.myId,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {  
        if(response[0].collection.length>0) 
        {
          console.log(response);
          this.setState({
            selectedUser: response[0].collection[0].trello_name
          }); 
          const _this = this;
          setTimeout(function() {     
            _this.getSingleBoard();
          }, 200);
        }else
        {
          this.getAllCollections();
          this.getAllBoards();
        }  

    })
    .catch(error =>
      {
        this.getAllCollections();
        this.getAllBoards();
      })
  };

  getAllBoards = () =>{        
    this.setState({initialLoader:true});
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getAllTrelloBoardsQa;
    }else
    {
      var furl = urls.getAllTrelloBoards;
    }
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.splitArray(response[0].members); 
        this.setState({
            allBoards: response[0].members,  
            allBoardLastupdate: response[0].last_sync,
            initialLoader:false  
        });            
    })
  };

  splitArray = (allboards)=>
  {
    const list = allboards;
    const middleIndex = Math.ceil(list.length / 2);

    this.setState({firstHalf: list.splice(0, middleIndex), secondHalf:list.splice(-middleIndex)});
  
  }

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getSingleBoard = () =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      datte: 7
    } 
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleQa;
    }else
    {
      var furl = urls.getTrelloSingle;
    }

    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        console.log(response);
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          activeUserId: response[0].member_uid,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  showSingleboard = (member) =>
  {
    this.setState({
      selectedUser: member
    }); 
    const _this = this;
    setTimeout(function() {     
      _this.getSingleBoard();
    }, 200);
  }

  getSingleBoardBell = (board_name) =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      board_name: board_name,
      datte: 7
    } 

    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleBellQa;
    }else
    {
      var furl = urls.getTrelloSingleBell;
    }
    
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  // handleChange = (selectedOptions) => {
  //   var size = Object.keys(selectedOptions).length;
  //   if(size>0)
  //   {
  //     this.setState({ selectedUser:selectedOptions[0].collection});
  //   }
  // }
  
  // handleInputChange(input, e) {
  //   console.log("value", input)
  // }

  sortBoards(order, response) {
		let temp = response;
		  if (order === "asc") {
			temp.sort(ascOrder);
			return temp;
		  } else if (order === "desc") {
      temp.sort(descOrder);
      return temp;
	  }
	
	
		function ascOrder(a, b) {
      if(a.bell_active=== 1)
			return new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.last_activity).getTime() - new Date(a.last_activity).getTime();
		}
	}

  fetchBaseandRedirect = (board_name) =>
  {
    const boarddata = board_name.split("Kimp");
    let opts ={    
      board_name: boarddata[0]
    } 
    fetch(urls.findBoardByName,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if(response.bid_cpt !=='')
      {
        var burl = urls.notificationUrl+"/task-list/"+response.bid_cpt;
        window.open(burl, '_blank').focus();
      }
    })
  }


  addRemoveTrelloWatch = (id) =>
  {
    let opts ={    
      boardurl: this.state.boardUrl,
      stype: id,
      user_id: this.state.myId
    } 
    console.log(opts);
    fetch(urls.addRemoveTrelloWatch,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      
      if(response[0]['success']==1 || response[0]['success']==2)
      {
        this.setState({
          boardUrl:'',
          actionStatus:true
        })
      }
    })
  }

  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  showAllTrello = ()=>
  {
    this.setState({showSingle:false});
  }
  render() {
    const { selectedOption } = this.state;
    const { selectedOptionhost } = this.state;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
    <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="Minutes of Meeting" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
      <div className="themeContentBody active_trello">
        <Row>
          <Col sm={12} xl={12}>
            <Card className="cl_helth_report">
               <div className="d-flex justify-content-between align-items-center">
               <h1 class="page-title text-center her_title">Meeting List</h1>
               <Button className="meet_btn" onClick={this.toggle}>Create Minutes of Meeting</Button>
               </div>
               
               <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Meeting Name</th>
                      <th>Meeting Created By</th>
                      <th>Meeting To</th>
                      <th>Meeting Date</th>
                      <th align="right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Meeting With PL Teams
                      </td>
                      <td>
                          Senthu
                      </td>
                      <td>
                          Prem
                      </td>
                      <td>
                          Wed 6 Jul 12.05 PM
                      </td>
                      <td align="right" className="d-flex justify-content-end">
                        <Button className="meeting_edit text_btn">Add</Button>
                        <Button className="meeting_delete text_btn">View</Button>
                      </td>
                    </tr>
                  </tbody>
               </Table>
            </Card>
            </Col>
           
        </Row>
      </div>
      </Row>

     {/* Create Meeting From */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className, 'newmeeting_modal'}>
          <ModalHeader toggle={this.toggle}>Invite Member</ModalHeader>
          <ModalBody>
          <Form>
               <FormGroup>
                  <Label for="MeetingText">Meeting Text</Label>
                  <Input type="text" name="text" id="MeetingText" placeholder="example: Lorem ipsum dolor sit amet, qui minim labore adipisicing minim" />
                </FormGroup>
                <FormGroup>
                <Label>Choose Meeting Name</Label>
                <Select
                  closeMenuOnSelect={false}
                  value={selectedOption}
                  onChange={this.handleChange}
                  options={options}
                  isMulti
                  className="select_custom_meetiing"
                />
              </FormGroup>
              <FormGroup>
                <Label>Choose Meeting Members</Label>
                <Select
                  value={selectedOptionhost}
                  onChange={this.handleChangehost}
                  options={MeetingMembersoptions}
                  className="select_custom_meetiing"
                />
              </FormGroup>
          </Form>
          </ModalBody>
          <ModalFooter>
            <Button className="sub_btn_meet" color="primary">Invite</Button>{' '}
           
          </ModalFooter>
        </Modal>
       {/* END Create Meeting From */}
      </Container>
      </div>
    )
  }
}
