import React, {Component, Fragment} from 'react';
import {urls} from "../../services/UrlList.js";
import { BrowserRouter as Router,Route,Link} from "react-router-dom";
import {Container, Row, Col, Card, Button, Progress, Input, Label, Table} from 'reactstrap';
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from '../header/Header';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import "./usermanagement.css";
import EditIcon from '../svgicon/EditIcon';
import DeleteIcon from '../svgicon/DeleteIcon';
import DeleteIconWhite from '../svgicon/DeleteIconWhite';
import AddButton from "../../images/add-button.png";
import ConfirmAction from "../admincomment/ConfirmAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { Typeahead } from 'react-bootstrap-typeahead';
import MaterialIcon from "material-icons-react";
export default class UsersList extends Component{
	constructor(props)
	{
		super(props);
		this.state={	
			showNewModal:false,		
			showMenu:true,			
			initialLoader:true,
			pageNum:0,
			searchKey:'',
			userProfiles:[],
			showDeletionDialogue:false,
			deleteUserid:'',
			myPrivileges: [],
			myId:'',
			subscribedOnInAscOrder: false,
			nameInAscOrder:false,
			positionInAscOrder:false,
			freshSearch:false,
			userRoles:[],
			selectedRoleId:'',
			selectedRoleName:'',
			newname:'',
			newemail:'',
			newpass:'',
			errorItems:''
		};
		this.keyPress = this._handleKeyDown.bind(this);
	} 

	componentDidMount = () =>
	{
		document.title = "HUB - Profile Management";    
		this.checkUserPrivilege();
		document.addEventListener("scroll", this.trackScrolling);
		this.getUserProfiles();
		this.getUserRoles();
	}

	componentWillUnmount()
	{
		document.removeEventListener("scroll", this.trackScrolling);
	}

	checkUserPrivilege = () => {
		if (localStorage.getItem("user") !== null) {
		  var login = JSON.parse(localStorage.getItem("user"));
		  console.log(login.privileges);
		  this.setState({
			myPrivileges: login.privileges,
			myId: login.uid
		  });
		}
	  };

	_handleKeyDown = (e) => {
		var code = (e.keyCode ? e.keyCode : e.which);
		if(code == 13) {   
			this.setState({
				userProfiles: [],
				pageNum:0,
				freshSearch:true,
				initialLoader: true,
			});    
			const _this = this;
				setTimeout(function() {          
				_this.getUserProfiles();
			}, 500);     
			
		}
	  }
	

	signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
    };

	toggleSidebar = () =>
	{
		this.setState({showMenu: !this.state.showMenu});
	}

	hideMenu = () =>
	{
	 	this.setState({showMenu: !this.state.showMenu});
	}

	searchProfile =() =>
	{
		this.setState({
			userProfiles: [],
			pageNum:0,
			freshSearch:true,
			initialLoader: true,
		});    
		const _this = this;
		setTimeout(function() {          
		_this.getUserProfiles();
		}, 500);    
		
	}
	
	getUserProfiles = () => {
		
		let opts = {
		  pagenum:this.state.pageNum,
		  search_key:this.state.searchKey
		};
		console.log(opts);
		fetch(urls.getUserProfiles,{
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
		  },
		  method: "post",
		  body: JSON.stringify(opts)
		})
		.then(response => response.json())
		  .then(response => {     
			  console.log(response[0].profiles);
			let list = this.getSortedRecords(response[0].profiles);   
			  this.setState({
				userProfiles: list,
				initialLoader: false
			  });
			  if (response[0].profiles.length === 300) {
				document.addEventListener("scroll", this.trackScrolling);
			  }
		  })
		  .catch(error => {
			  console.log(error);
			  this.setState({
				initialLoader: false
			  });
		  });
	  };

	getUserRoles = () => {
		fetch(urls.getDUserRoles,{
		  headers: {
			"Access-Control-Allow-Origin": "*",
			"Content-Type": "application/json"
		  }
		})
		.then(response => response.json())
		  .then(response => {
			  this.setState({
				userRoles:response.roles
			  })
		  })
		  .catch(error => {
		  });
	};

	isBottom(el){
		return el.getBoundingClientRect().bottom <= window.innerHeight;  
	}
  
	trackScrolling = () =>
	{    
	const wrappedElement = document.getElementById("user-list-management-section");
	if(this.isBottom(wrappedElement))
	{    
		this.setState(
		{
			pageNum: this.state.pageNum +1,
			showProgress: true,
			freshSearch:false,
		});
		this.getUserProfiles();
		document.removeEventListener("scroll", this.trackScrolling)      ;
	}
	};
	

	getSortedRecords = response => {    
		if(this.state.freshSearch)
		{
			return response;
		}
		else
		{
			let temp_list = this.state.userProfiles.concat(response);
			return temp_list;
			
		}
		
	  };

	convertTimeFormat = (time) =>
	{
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}

	closeDialogue = () => {
		this.setState({
		  showDeletionDialogue: false
		});
	  };

	handleDeletionConfirm = () => {
	this.setState({ showDeletionDialogue: !this.state.showDeletionDialogue });
	const _this = this;
	setTimeout(function() {
		_this.handleProfileDeletion();
	}, 1000);
	};

	handleProfileDeletion = ()=>
	{
		this.setState({
			initialLoader: true
		  });
		  let opts = {
			  deleteid:this.state.deleteUserid
		  };
		  console.log(opts);
		  fetch(urls.deleteUserProfile,{
			headers: {
			  "Access-Control-Allow-Origin": "*",
			  "Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(opts)
		  })
		  .then(response => response.json())
			.then(response => {
				this.setState(
					{
						userProfiles:[],
						pageNum: this.state.pageNum +1,
						initialLoader: true,
						searchKey:''
					});
				this.getUserProfiles();
			})
			.catch(error => {
				console.log(error);
				this.setState({
					initialLoader: false
				});
			});
	}

	triggerDelete = (id) =>
	{
		console.log(id);
		this.setState(
			{
				showDeletionDialogue: true,
				deleteUserid: id
			});
	}

	sortSubscribers(order, column) {
		let temp = this.state.userProfiles;
		if (column !== "date_of_joining") {
		  if (order === "asc") {
			temp = temp.sort(function(a, b) {
			  if (a[column] < b[column]) {
				return -1;
			  }
			  if (a[column] > b[column]) {
				return 1;
			  }
			  return 0;
			});
		  } else if (order === "desc") {
			temp = temp.sort(function(a, b) {
			  if (a[column] > b[column]) {
				return -1;
			  }
			  if (a[column] < b[column]) {
				return 1;
			  }
			  return 0;
			});
		  }
		  switch (column) {
			case "name":
			  if (order === "asc") {
				this.setState({
				  nameInAscOrder: true,
				  userProfiles: temp
				});
			  } else if (order === "desc") {
				this.setState({
				  nameInAscOrder: false,
				  userProfiles: temp,
				  nameInAscOrder: false
				});
			  }
			  break;
			case "position":
			  if (order === "asc") {
				this.setState({
				  emailInAscOrder: true,
				  userProfiles: temp,
				  positionInAscOrder: true
				});
			  } else if (order === "desc") {
				this.setState({
				  emailInAscOrder: false,
				  userProfiles: temp,
				  positionInAscOrder: false
				});
			  }
			  break;
			default:
			  return null;
		  }
		} else {
		  if (order === "asc") {
			temp.sort(ascOrder);
			this.setState({
			  dojInAscOrder: true,
			  userProfiles: temp
			});
		  } else if (order === "desc") {
			temp.sort(descOrder);
			this.setState({
				dojInAscOrder: false,
				userProfiles: temp
			});
		  }
		}
	
		function ascOrder(a, b) {
			return new Date(a.date_of_joining_sort).getTime() - new Date(b.date_of_joining_sort).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.date_of_joining_sort).getTime() - new Date(a.date_of_joining_sort).getTime();
		}
	  }

	handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

	handleRoleChange = (selectedOptions) => {
		var size = Object.keys(selectedOptions).length;
		if(size>0)
		{
			this.setState({ 
				selectedRoleId:selectedOptions[0].id,
				selectedRoleName:selectedOptions[0].name
			});
		}
	}
	showAddModal = ()=>
	{
		this.setState({
			showNewModal:true
		})
	}
	addNewUser = ()=>
	{
		let temp ='';
		if(this.state.newname ==='')
		{
			temp = 'name';
		}else if(this.state.newemail ==='')
		{
			temp = 'email';
		}else if(this.state.newpass ==='')
		{
			temp = 'password';
		}else if(this.state.selectedRole ==='')
		{
			temp = 'role';
		}
		console.log(temp);
		this.setState({errorItems:temp});
		if(temp!='')
		{
			return false;
		}
		let opts = {
			name:this.state.newname,
			email:this.state.newemail,
			password:this.state.newpass,
			roleid: this.state.selectedRoleId,
			rolename: this.state.selectedRoleName
		  };
		  console.log(opts);
		  fetch(urls.addNewUser,{
			headers: {
			  "Access-Control-Allow-Origin": "*",
			  "Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(opts)
		  })
		  .then(response => response.json())
			.then(response => {     
				this.setState({
					showNewModal:false
				});
				if(response.success == 1)
				{
					this.props.history.push("/usermanagement/"+response.uid);
				}
			})
			.catch(error => {
				this.setState({
					showNewModal:false
				})
			});
	}

	hideAddModal = ()=>
	{
		this.setState({
			showNewModal:false
		})
	}
	render()
	{
		 const variants = {
	      hidden: { opacity: 0 },
	      visible: { opacity: 1 },
	    }
		return(
			<div className="fullwidth_mobilemenu">
			<Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}  >
			<Row>
				<div className="theme_header">
				<Header {...this.props}  
					            classname="login-portal-page" 
					            logout={this.signOut.bind(this)} 
					            onmenuaction={this.toggleSidebar.bind(this)}
								quickactionblock={true} />
				</div>
				<div className="SideNavbar">
				<SideMenu  
					activeitem="admin" 
					showmenu={this.state.showMenu} 
					hidemenu={this.hideMenu.bind(this)}  />
				</div>
				<div className="themeContentBody themefullwidthContentBoday">
				<Row>
            	<Col xs={12} md={12}>
            		<Card className="cl_helth_report">
        			<h1 class="page-title text-center her_title">Employee Management</h1>
					<motion.div 
					initial="hidden"
					animate="visible"
					variants={variants}>
						<div id="user-list-management-section">
							<div className="filter-bar">
								<Row>
									<Col xl="4" className="plzro">
										<Input type="text" name="search" className="serchinput" placeholder="Search" 
										onKeyDown={this.keyPress} 
										onChange={(e) => this.setState({searchKey: e.target.value})}  
										/>
										<button className="search_btn" type="submit" onClick={this.searchProfile}><MaterialIcon icon="search" /> </button>
									</Col>
									<Col xl="4">
										
									</Col>
									
									<Col xl="4">
										{ this.state.myPrivileges.includes("delete_profiles") ?
										(<div className="d-flex justify-content-end">
											<div className="add_new_userbtn d-flex justify-content-center" onClick={this.showAddModal.bind(this)}>Add New</div>
											{/* <div className="delete-all d-flex justify-content-center" >Delete <DeleteIconWhite/> </div> */}
											<Button className="btn btn-green deletbtn"><MaterialIcon icon="delete_outline" /> </Button>
										</div>)
										:
										null
										}
									</Col>
								</Row>
							</div>
								<LoadingWrapper isLoading={this.state.initialLoader}>
								<Table responsive className="theme_table usermange_table">
									<thead>
										<tr>
											<th></th>
											<th>ID</th>
											<th>
											Name
											&nbsp;
											{
												this.state.nameInAscOrder
												? <span className="fas fa-chevron-up" onClick={this.sortSubscribers.bind( this, "desc", "name" )} ><FontAwesomeIcon icon={faAngleDown} /></span>
												: <span className="fas fa-chevron-down" onClick={this.sortSubscribers.bind( this, "asc", "name" )} ><FontAwesomeIcon icon={faAngleUp} /></span>
											}

											</th>
											<th>Position
											&nbsp;
											{
												this.state.positionInAscOrder
												? <span className="fas fa-chevron-up" onClick={this.sortSubscribers.bind( this, "desc", "position" )} ><FontAwesomeIcon icon={faAngleDown} /></span>
												: <span className="fas fa-chevron-down" onClick={this.sortSubscribers.bind( this, "asc", "position" )} ><FontAwesomeIcon icon={faAngleUp} /></span>
											}</th>
											<th>
											DOJ
											&nbsp;
											{
												this.state.dojInAscOrder
												? <span className="fas fa-chevron-up" onClick={this.sortSubscribers.bind( this, "desc", "date_of_joining" )} ><FontAwesomeIcon icon={faAngleDown} /></span>
												: <span className="fas fa-chevron-down" onClick={this.sortSubscribers.bind( this, "asc", "date_of_joining" )} ><FontAwesomeIcon icon={faAngleUp} /></span>
											}
											</th>
											<th>Reports To</th>
											<th>Manage</th>
										</tr>
									</thead>
								   <tbody>
								   {this.state.userProfiles.length >0?
										(
											<>
												{this.state.userProfiles.map((item, index) =>
												{
													return(
														<tr>
															<td><input type="checkbox" /></td>
															<td>{"K"+item.employee_id}</td>
															<td>{item.name}</td>
															<td>{item.position}</td>
															<td>{item.date_of_joining}</td>
															<td>{item.reporting}</td>
															<td className="d-flex justify-content-end actions_user">
															<span className="edit-profile"> 
															    <Link to={"/usermanagement/"+item.id}><MaterialIcon icon="mode" /> </Link>
																{/* <EditIcon ppath={"/usermanagement/"+item.id} /> */}
															</span>
															{ this.state.myPrivileges.includes("delete_profiles") ?
																(<>
															    <Button className="user_delete" itemid={item.id} onClick={this.triggerDelete}><MaterialIcon icon="delete" /> </Button>
																{/* <span className="delete-profile"> <DeleteIcon itemid={item.id} deleteuser={this.triggerDelete} /></span> */}
																</>)
																:
																null
															}
															</td>
															
														</tr>
													)
												})}
											</>
										):
										(
											<Row className="list-body-cont d-flex">
												<Col> <span className="norecords">No matching records found</span></Col>
											</Row>	
										)
										}
								   </tbody>

								</Table>
								<div className="User-Profiles">
									<div id="list-body" className="user-list-view">
										{/* {this.state.userProfiles.length >0?
										(
											<>
												{this.state.userProfiles.map((item, index) =>
												{
													return(
														<Row className="list-body-cont d-flex">
															<Col xl='1'><input type="checkbox" /></Col>
															<Col>{"K"+item.employee_id}</Col>
															<Col xl="3">{item.name}</Col>
															<Col>{item.position}</Col>
															<Col>{item.date_of_joining}</Col>
															<Col>{item.reporting}</Col>
															<Col className="d-flex editbox">
															<span className="edit-profile"> 
																<EditIcon ppath={"/usermanagement/"+item.id} />
															</span>
															{ this.state.myPrivileges.includes("delete_profiles") ?
																(<>
																<span className="seperator">|</span>
																<span className="delete-profile"> <DeleteIcon itemid={item.id} deleteuser={this.triggerDelete} /></span>
																</>)
																:
																null
															}
															</Col>
														</Row>
													)
												})}
											</>
										):
										(
											<Row className="list-body-cont d-flex">
												<Col> <span className="norecords">No matching records found</span></Col>
											</Row>	
										)
										} */}
										
									</div>
							</div>
							</LoadingWrapper>
						</div>{/*user-list-management-section*/}

						{this.state.showNewModal &&
									(
										<div className="add-new-people-wrap">
											<div className="new-people-overlay" onClick={this.hideAddModal}></div>
											<div className="new-people-content">
												<div className="field-item">
													<Input placeholder="Full Name" className={this.state.errorItems === 'name'? 'error-field': ''} name="newname" type="text" onChange={this.handleChange} ></Input>
												</div>
												<div className="field-item">
													<Input placeholder="Email Address" name="newemail" className={this.state.errorItems === 'email'? 'error-field': ''} type="text" onChange={this.handleChange} ></Input>
												</div>
												<div className="field-item">
													<Input placeholder="Password" name="newpass" type="password" className={this.state.errorItems === 'password'? 'error-field': ''} onChange={this.handleChange} ></Input>
												</div>
												<div className="field-item">
													<Typeahead
														className={this.state.errorItems === 'role'? 'error-field': ''}
														id="user-picker"
														labelKey="name"
														options={this.state.userRoles}
														placeholder="Choose designation"
														onChange={this.handleRoleChange}
														/>
												</div>
												<div className="d-flex actions-btns">
													<Button className="btn btn-grey" onClick={this.hideAddModal}>Cancel</Button>
													<Button className="btn btn-green" onClick={this.addNewUser.bind(this)}>Save and Continue</Button>
												</div>
											</div>
										</div>
									)}
					</motion.div>
					</Card>
				</Col>
			</Row>
				</div>
			</Row>
			<>
				{this.state.showDeletionDialogue ? (
				<ConfirmAction
					purpose="change"
					closeDialogue={this.closeDialogue}
					show={this.state.showDeletionDialogue}
					note="*This action cannot be undone, are you sure?"
					content="You are about to delete a user profile permanently."
					handleCompletionConfirm={this.handleDeletionConfirm}
					resolveid=""
					resolveStatus=""
					adminid=""
					confirmBoxAction="markasdeleted"
				/>
				) : null}
				
			</>
		</Container>
			
		</div>
		);
	}
}
