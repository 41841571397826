import React, { Component, Fragment } from "react";
import "./ApprovalContainer.css";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import Header from "../../header/Header";
import { Container, Col, Row, Card, CardTitle } from "reactstrap";
import ApprovalHead from "../approvalHead/ApprovalHead";
import CarousalContainer from "../CarousalContainer/CarousalContainer";
import ApprovalModules from "../ApprovalModules/ApprovalModules";
import LateWeekly from "../LateWeekly/LateWeekly";
import LateMonthly from "../LateMonthly/LateMonthly";
import WeeklyQuota from "../OverQuotaWeekly/WeeklyQuota";
import MonthlyQuota from "../OverQuotaMonthly/MonthlyQuota";
import WorkHours from "../WorkHours/WorkHours";
import { urls } from "../../../services/UrlList";
import { isMobile } from "react-device-detect";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
export default class ApprovalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      isMobileView: 0,
      breakRequests: [],
      shiftRemovalRequest: [],
      progressBar: true,
      weeklyLateAlert: 0,
      monthlyLateAlert:0,
      weeklyQuotaAlert:0,
      monthlyQuotaAlert:0,
      dailyWorkAlert:0,
      shiftUnblockCount: 0,
      wfhRequestCount: 0,
      leaveRequestCount: 0,
      showMenu:true,
    };
  }

  componentDidMount = () => {
    document.title = "HUB - Approve Requests";
    this.checkUserPrivilege();
    this.renderContentDevice();
    // this.getBreakRequest();
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    this.setState({ myPrivileges: login.privileges, myId: login.uid });
    if (!login.privileges.includes("approve_user_requests")) {
      this.props.history.push("/tick-report");
    }
  };
  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  componentWillMount = () => {
    //this.getBreakRequest();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  onLateWeeklyFunctionCall = count => {   
    this.setState({ weeklyLateAlert: count });
  };

  onLateMonthlyFunctionCall = count => {
    this.setState({ monthlyLateAlert: count });
  };

  onQuotaWeeklyFunctionCall = count => {
    this.setState({ weeklyQuotaAlert: count });
  };

  onQuotaMonthlyFunctionCall = count => {
    this.setState({ monthlyQuotaAlert: count });
  };

  onWorkHoursFunctionCall = count => {
    this.setState({ dailyWorkAlert: count });
  };
  

  scrollToElem = param => {
    let element = document.getElementById(param);
    let elementOffset = document.getElementById(param);    
    if (element == "break-module") {
      elementOffset = 0;
    }
    window.scroll({ top: elementOffset.offsetTop - 120, behavior: "smooth" });
  };

 

  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >

    <Row>
        <div className="theme_header">
        <Header {...this.props}  
              logout={this.signOut.bind(this)}  
              onmenuaction={this.toggleSidebar.bind(this)}
              quickactionblock={true} />            
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="approval" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody adminnotipage" style={{ width:'85.5%', }}>
        <Card className="switch_user_card">
              <CardTitle tag="h1">Admin Notifications</CardTitle>
              <ApprovalHead
                weeklylate={this.state.weeklyLateAlert}
                monthlylate={this.state.monthlyLateAlert}
                weeklyquota={this.state.weeklyQuotaAlert}
                monthlyquota={this.state.monthlyQuotaAlert}
                dailyworkhours={this.state.dailyWorkAlert}
                scrollToElem={this.scrollToElem}
              />
              <section className="caros-wrapper">
                <LateWeekly
                  classname="break-over-quata"
                  title="Late Weekly"
                  onweeklylate={this.onLateWeeklyFunctionCall}
                />
                <LateMonthly
                  classname="break-over-quata"
                  title="Late Monthly"
                  onmonthlylate={this.onLateMonthlyFunctionCall}
                />
                 <WeeklyQuota
                  classname="break-over-quata"
                  title="Weekly Over Quota"
                  onweeklyquota={this.onQuotaWeeklyFunctionCall}
                />
                 <MonthlyQuota
                  classname="break-over-quata"
                  title="Monthly Over Quota"
                  onmonthlyquota={this.onQuotaMonthlyFunctionCall}
                />
                <WorkHours
                  classname="break-over-quata"
                  title="Work Hours"
                  onworkhours={this.onWorkHoursFunctionCall}
                />
                {/* <ShiftChange
                  classname="break-over-quata"
                  title="Shift CHANGE"
                /> */}
                {/* <Wfh
                  classname="break-over-quata"
                  title="WFH"
                  onwfhfuncall={this.onWfhFunctionCall}
                />
                <ApprovalLeave
                  classname="break-over-quata"
                  title="Leave"
                  onleavefuncall={this.onLeaveFunctionCall}
                /> */}
              </section>
        </Card>
        </div>
      
    </Row>




</Container>
</div>
    );
  }
}
