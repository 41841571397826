import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import '../Reports/list-head.css';
import '../Reports/listcard.css';
import '../Reports/LogTimer.css'
import {urls} from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table, Label } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import axios from 'axios';
import { Typeahead } from 'react-bootstrap-typeahead';

export default class CloneInternalBoard extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

    this.state = {         
      myPrivileges:[], 
      myId:'', 
      showProgress: false,   
      showFetchProgress: false,
      cloneProgress:false,                   
      showMenu:true, 
      projects:[],
      selectedProjectobj:[],
      selectedProject:'',
      boardDescription:'',
      boardName:'',
      boarderrors:[] 
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Clone Internal Board";
    this.checkUserPrivilege(); 
    this.getInternalBoards();    
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 

  timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
    }

    getInternalBoards = async () => {
        this.setState({
          progressBar: true
        })
        const data = {
          user_id: this.props.myid
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.getInternalActiveProjects, data, {
          headers: headers
        })
          .then((response) => {
            this.setState({
              progressBar: false,
              projects: response.data.projects
            })
          })
          .catch((error) => {
            this.setState({
              progressBar: false
            })
          })
    };

    handleInputChange(input, e)
    {
    console.log("value", input)
    }
      
    handleChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if(size>0)
        {
            this.setState({ selectedProject:selectedOptions[0].id, selectedProjectobj: selectedOptions});
            this.getTaskList(selectedOptions[0].id);
        }else
        {
            this.setState({ selectedProject:''});
        }
    }

    getTaskList = async (selectedProject) => {
        this.setState({
            showFetchProgress: true
        })
        const data = {
          project_id: selectedProject
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.getListGroup, data, {
          headers: headers
        })
          .then((response) => {
            this.setState({
              accessDenied: response.data.success === 10 ? true : false,
              showFetchProgress: false
            })
            let listgroups = this.reArrangeTasks(response.data);
            this.setState({ taskListGroup: listgroups});
          })
          .catch((error) => {
            this.setState({
             showFetchProgress: false,
              accessDenied: true
            })
          })
      };
    
    
      reArrangeTasks = (data) => {
        let lists = data.tasks_list;
        let groups = data.tasks_group;
        let temp = [];
        var l = 0; var g = 0; var t = 0;
        lists.forEach(list =>   // Lists 
        {
          temp[l] = [];
          temp[l] = list;
          let temp1 = [];
          var g = 0;
          temp[l].tasks = [];
          groups.forEach(group =>  // Groups
          {
            if (list.id === group.task_list_id) {
              temp1[g] = [];
              temp1[g] = group;
            }
            g = g + 1;
          })
          temp[l]['group'] = temp1.filter(val => val);
          
          l = l + 1;
        })
        console.log(temp);
        return temp;
      }

    cloneBoard = () =>
    {
        this.setState({boarderrors:[]});
        if(this.state.boardName.length <=0 )
        { 
            this.setState({boarderrors: [...[], 'boardname']});
            return false;
        }else if(this.state.boardDescription.length <=0)
        {
            this.setState({boarderrors: [...[], 'boarddescription']});
            return false;
        }
        this.setState({
            cloneProgress: true
          })
          const data = {
            board_id: this.state.selectedProject,
            board_name: this.state.boardName,
            board_description: this.state.boardDescription
          };
          const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
          }
          axios.post(urls.cloneBoard, data, {
            headers: headers
          })
        .then((response) => {
            this.setState({
                cloneProgress: false,
                taskListGroup:[],
                selectedProject:'',
                boardName:'',
                boardDescription:''
            })
            if(response.data.bid_cpt!='')
            window.open(urls.notificationUrl+"/internal-task-list/"+response.data.bid_cpt, "_blank");
        })
        .catch((error) => {
            this.setState({
            cloneProgress: false
            })
        })

    }

  render() {
    return (
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " project-report"}>
        <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
              <Card id="filter" className="cl_helth_report clone_ib_card">
                <h1 class="page-title text-center her_title">Clone Internal Board</h1>
                <LoadingWrapper isLoading={this.state.progressBar}>
                <div className="clone-board-wrap">
                    <Row>
                        <Col sm="6">
                        
                            <div className="form-group">
                                <label>Internal Board</label>
                                <div className="people date-col">
                                  <Typeahead
                                  id="project-picker"
                                  labelKey="name"
                                  onInputChange={this.handleInputChange}
                                  onChange={this.handleChange}
                                  options={this.state.projects}
                                  placeholder="Choose Board"
                                  selected=''
                                  />
                                </div>
                               
                             
                             
                            </div>
                            <LoadingWrapper isLoading={this.state.showFetchProgress}>
                                {this.state.selectedProject>0 &&
                                (
                                    <div className='cloning-board-details'>
                                        {this.state.taskListGroup && this.state.taskListGroup.length>0 ?
                                        (
                                            <>
                                                <h6>Lists and Groups</h6>
                                                <ul>
                                                {this.state.taskListGroup.map((item, index)=>
                                                {
                                                    return(
                                                        <li key={item.id} className="list-name-items"><Label>{item.list_name}</Label>
                                                            {item.group && item.group.length>0 &&
                                                            (
                                                                <ol>
                                                                    {item.group.map((gitem, gindex)=>
                                                                    {
                                                                        return(
                                                                            <li key={gindex}>{gitem.group_name}</li>
                                                                        )
                                                                    })}
                                                                </ol>
                                                            )}
                                                        </li>
                                                        
                                                    )
                                                })}
                                                </ul>
                                            </>
                                        ):
                                        (
                                            <span>No lists or groups available in this board.</span>
                                        )}
                                </div>
                                )}
                            </LoadingWrapper>
                        </Col>
                        <Col sm="6">
                            {this.state.selectedProject>0 &&
                            (
                                <div>
                                    <div className="form-group">
                                        <label>New Board Name</label>
                                        <Input name="boardName" value={this.state.boardName} onChange={e=>
                                        this.setState({boardName:e.target.value})}
                                        className={'text_box' + (this.state.boarderrors.includes('boardname') ? " error-field" : "")} />
                                    </div>
                                    <div className="form-group">
                                        <label>New Board Description</label>
                                        <Input name="boardDescription" type="textarea"
                                        onChange={e=> this.setState({boardDescription:e.target.value})}
                                        className={'textarea_box' + (this.state.boarderrors.includes('boarddescription') ? " error-field" :
                                        "")}
                                        />
                                    </div>
                                    <div className='save-copy-list'>
                                        <Button name="Save" className={`btn btn-green save_btn ${this.state.cloneProgress && 'disable-it'}`} onClick={this.cloneBoard}>Copy & Save</Button>
                                        {this.state.cloneProgress &&
                                        (
                                            <div className='cloning-msg'>Please wait while we copy and create new board.</div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
                </LoadingWrapper> 
            </Card>
        </Col>
    </Row>
    </div>
    </Row>
    </Container>
    )
  }
}
