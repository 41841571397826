import React, { Component } from 'react';
import { Redirect } from 'react-router';
import './datalist.css';
import { Container, Row, Col, Card } from 'reactstrap';
import ListCard from '../listcard/ListCard';
import SimpleCard from '../simplecard/SimpleCard';
import ProjectCompanyCard from '../project-comany-card/ProjectCompanyCard';
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import moment from "moment";
import AdminComment from '../admincomment/AdminComment';

export default class DataList extends Component {

  constructor(props) {   
    super(props);   
    var s = new Date();
    s.setDate(s.getDate() - 1);
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("YYYY-MM-DD H:mm");

    var e = new Date();
    e.setDate(e.getDate());
    e.setHours(6);
    e.setMinutes(0);
    let momentObje = moment(e);
    let momentStringEnd = momentObje.format("YYYY-MM-DD H:mm");   
    this.state = {
      progressBar:false,  
      fullEntries:[],
      orginalEntries: [],          
      filterHour:'',
      showSelectedResult:false, 
      selectedUser:this.props.selectedpeople,
      start_date:momentStringStart,
      end_date:momentStringEnd,
      isLoggedOut:false,
    };    
  }
  
  componentDidMount = () => {   
    this.checkUserPrivilege();     
    this.getAllEntries();  
  };

  componentDidUpdate(prevProps, prevState) {  
    if (prevProps.start_date !== this.props.start_date || prevProps.end_date !== this.props.end_date || prevProps.selectedpeople !== this.props.selectedpeople) {      
      this.getAllEntries();  
    }

    if(prevProps.filterhour !== this.props.filterhour)
    {
      this.filterByHours(this.props.filterhour);
    }
  }


  componentWillUnmount()
  {
    
  }

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") === null)
    {
      this.setState({isLoggedOut:true});
    }else
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  getAllEntries = () =>{  
    
    let opts ={
      start_date: this.props.start_date,
      end_date: this.props.end_date,
    };
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      let useridd='';
      if(!login.privileges.includes('access_all_reports') && !login.privileges.includes('access_team_reports'))
      { 
        opts.user_id = login.tick_user;
      }else
      {
        opts.user_id = this.props.selectedpeople;
      }
    }
    
    
    this.setState({         
      progressBar: true
    });   
    
    if(this.props.selectedpeople !='')
    {
      var fUrl = urls.tickEntriesCustomPeople;    
    }else{
      var fUrl = urls.tickEntriesCustomLocal;    
    }
   
    console.log(fUrl);
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: 'post',
        body: JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {   
       console.log(response)     ;
       // console.log(response[0]['projects'][0]['tasks'][0]['comment_resolved']);
        this.setState({
          fullEntries:response,
          orginalEntries: response,          
          progressBar: false,
          filterHour:'',
          showSelectedResult: this.props.selectedpeople !=''? true:false,
        });       
      } else {       
        this.setState({
          progressBar: false,
          orginalEntries: [],
        });
      }

    })
    .catch(error => {
      this.setState({
        progressBar: false
      });
    });
  };
  

updateEntriesOriginal = (status) =>
{
  let orginals = this.state.orginalEntries;
  orginals[this.state.commentUserId].projects[this.state.commentProjectId].tasks[this.state.commentTaskId].comment_resolved = status;
  this.setState({
    orginalEntries: orginals
  });
}

  showDetailEntryDate = (date)=>
  {
    this.setState({
      show_detail:true,
      show_detail_id:0,
      show_detail_date: this.state.show_detail_date === date? 0:date
    });
  };

  showDetailEntry = (id)=>
  {
    this.setState({
      show_detail:true,
      show_detail_date:0,
      show_detail_id: this.state.show_detail_id === id? 0:id
    });

  };

  showAdminCommentsBlock =(id, userid, projectid, taskid) =>
  {    
    this.setState({
      showAdminComments:true,
      adminCommentId:id,
      commentUserId:userid,
      commentProjectId:projectid,
      commentTaskId:taskid,
    });      
  };

  AdminCommentboxClose = () =>
{
  this.setState({
    showAdminComments:false,
  })
};

filterByHours=(value)=>
{ 
  this.setState({filterHour:value}); 
  if(value !=''){
    setTimeout(() => {
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      temp.forEach(p1=>
      {
        console.log(this.state.filterHour);
       if(parseInt(p1.total_hours) < parseInt(this.state.filterHour)) 
       {
         list.push(p1);
       }
      })
      this.setState({orginalEntries:list});   
    }, 100);
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
};


  render() {
    if (this.state.isLoggedOut === true) {
      clearInterval(this.interval);
      return <Redirect to='/' />
    }

    return (
      <Container>
        <Row>
        <Col sm="12">
          {this.state.progressBar? <LoadingBar /> : null}
            <div id="date-list-contianer">
            
            { this.state.orginalEntries.map( ( entries, index ) => {
              return (
                    <SimpleCard cardName={(this.state.show_detail && (this.state.show_detail_id === entries.uid || this.state.show_detail_date === entries.date))? "user-active":null } showDetailEntryDate={this.showDetailEntryDate} showDetailEntry={this.showDetailEntry} showSelectedResult={this.state.showSelectedResult}  uid={entries.uid} name={entries.name} date={entries.date} time={entries.total_hours} rate={this.props.showRate === true? entries.total_rate:null } projects={entries.total_projects}>
                    {(this.state.show_detail && (this.state.show_detail_id === entries.uid || this.state.show_detail_date === entries.date)) ?
                    (<div><ProjectCompanyCard cardName="card-brand" name="Doto Studio" time="Time Entered" rate="Total Rate" projects="Projects" />
                    {entries.projects.map((projects, ikey) =>
                    {
                      return(
                          <ListCard userkey={index} projectkey={ikey} showRate={this.props.showRate} projects={projects} commentStatus={true} cardName="project-card" showAdminCommentsBlock={this.showAdminCommentsBlock} />
                      )
                      }
                    )}
                    </div>)
                    :
                    null
                    }

                  </SimpleCard>
              )
            })
          }
            </div>
          </Col>
        </Row>
        {this.state.showAdminComments?
        ( <AdminComment onActionEdit={this.updateEntriesOriginal}  onClose={this.AdminCommentboxClose} entryId={this.state.adminCommentId}  showComment={this.state.showAdminComments}/>)
        :
        null
        }
      </Container>
    )
  }
}