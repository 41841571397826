import React, { Component } from 'react';
import './icon.css';
import { BrowserRouter as Router,Route,Link} from "react-router-dom";

export default class ProfileIcon extends Component {
  constructor( props ) {
    super( props );
    this.state = {};
  }
  handleAction = () =>
  {
    this.props.action();
  }
  render( props ) {
    const { src, width, height, classname, alt } = this.props;
    const classes = "icon "+ classname;
    return (
      <div className={classes} >
        <Link to={this.props.ppath}>
            <img src={src} width={width} height={height} alt={alt}  />
         </Link>
        
      </div>
    )
  }
}