import React from 'react';
import { BrowserRouter as Router,Switch,Route,Link,useRouteMatch} from "react-router-dom";

export default function ApprovalIcon( props ) {
  const classes = "approval-icon menu-icon "+ props.classname;
  return (
    <div className={classes}>
      <Link to={props.to}>
        <div className="cont">      
          <svg width="45" height="45" viewBox="0 0 80 80">  
              <path style={{ fillRule:"evenodd", fill: '#dbdbdb' }}  d="M40,0A40,40,0,1,1,0,40,40,40,0,0,1,40,0ZM36.4,59.211L19.372,42.18l6.38-6.381L35.794,45.841l19.4-23.28,6.936,5.781Z"/>
          </svg>       
          <p className="mb-0 text-center mt-1">{props.title}</p>
        </div>
      </Link>
      <span className="arrow"></span>
    </div>
  )
}