import React, { Component } from 'react';
import './list-head.css';
import {Container, Row, Col, Input} from 'reactstrap';

export default class ListHead extends Component {
  constructor( props ) {
    super( props );         
    this.state = {              
      myPrivileges:[],
      showRate:false,    
    };   
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };


  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };
 
  showMeRate = ()=>
  {
    this.props.showMeRate();
  };

  render() {
    return (
      <div id="list-head">
        <Container>
          <Row>
            <Col sm="12">
              <div className="list-head-cont d-flex">
                <p className="name">{this.props.selectedpeople > 0?'Date':'Name'}</p>
                <p className="time-entered">Time</p>               
                <p className="projects text-left">Projects</p>
                {this.state.myPrivileges.includes('show_rates')?
                (<div className="total-rate d-flex">
                  <p>Total Rate</p>
                  <Input type="checkbox" className="rate-enable" checked={this.props.showRate === true?'checked':null} onClick={this.showMeRate.bind(this)} />
                </div>)
                :
                (<div className="total-rate d-flex"></div>)
                }
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}