import React, { Component, Fragment } from "react";
import "./ApprovalLeaveComment.css";

export default class ApprovalLeaveComment extends Component {
  onActionTriger = () =>
  {
    this.props.onclick();
  }
  render() {
    let leave_approve_status = ['approval-status-icon', this.props.value === 0 && 'appr-leave-approved'].join(' ') ;
    return (
      <div title={this.props.reporter =='0'? this.props.adminperson : this.props.reportingofficers} className="appr-leave-comment d-flex" onClick={this.props.onaction == '1' ? this.onActionTriger.bind(this) : null }>
        <p className="title">{this.props.label}</p>
        <div className="d-flex ml-auto">
          {this.props.value=== 1 || this.props.value === 0 ? (<p title={this.props.reporter =='0'? this.props.approver : this.props.manager} className={leave_approve_status}></p>) : null }
          <p title={this.props.comment} className={"appr-comment-icon ml-auto "+(this.props.comment !='' && this.props.comment !=null ? 'with-comment': null) } ></p>
        </div>
        {/* {this.props.value === 1 ?
          (<p className="appr-comment-icon ml-auto"></p>)
          : null
        }         */}
      </div>
    );
  }
}
