import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import Homebackicon from '../../images/bc_homeicon.svg'
import PeopleTags from "./PeopleTag";
import KFileUpload from './kFileUpload';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import MaterialIcon from "material-icons-react";
import ProjectCurrentStatus from './ProjectCurrentStatus';
import ImageUploader from "quill-image-uploader";
import LightboxPop from "./LightboxPop";



Quill.register("modules/imageUploader", ImageUploader);
const notifySuccess = () => toast('Card created successfully.');
const notifyDraft = () => toast('Card has been saved as draft.');
const notifyError = () => toast('Something went wrong, please try again.');

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link','image'],
  ['clean']    
];



export default class NewTaskList extends Component {
  fileObj = []; 
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      saveupdateProgress:false,
      taskName:'',
      activeClass:'',
      dueDate:'',
      errors: [],
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      myTimezone:'',
      showProgress: true,
      taskAttachmentsChange:false,
      textInstructionFilesChange:false,
      imageryFilesChange:false,
      referenceFilesChange:false,
      approvedWorkFilesChange:false,
      selectedTemplate:'',
      availableAssignees:[],
      availableNotifiers:[],
      labels:[],
      tassignees:[],
      tnotifiers:[],
      selectedLabels:[],
      tags: [],
      setTags:[],
      templateFields:[],
      brandingFiles:[],
      textInstructionFiles:[],
      imageryFiles:[],
      referenceFiles:[],
      approvedWorkFiles:[],
      taskAttachments:[],
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      showNetworks:false,
      outputFileFormats:[],
      softwarestoUse:[],
      Style_Colors:[],
      addonColors:[],
      Guidelines:'',
      brandPredefinedColors:[],
      ColorInstructions:'',
      FontInstructions:'',
      showBrandColors:false,
      showAddonColors:false,
      showColorGuides:false,
      addonColors:[],
      draftAssigneWarning:false,
      animate: false,
      fileFormatOther:'',
      fileFormatOtherShow:false,
      softwareOther:'',
      outsoftInstructions:'',
      softwareOtherShow:false,
      resizeCrop:'',
      bleedSize:'',
      bleedUnit:'Inch',
      sizeFields:[{
          width:'',
          height:'',
          unit:'',
          note:'',
      }],
      lastSelectedUnit:'',
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true,
      },
      taskAttachmentTextOptions:1,
      taskAttachmentTextArray:[],

      textInstructionFilesTextOptions:1,
      textInstructionFilesTextArray:[],

      imageryFilesTextOptions:1,
      imageryFilesTextArray:[],

      referenceFilesTextOptions:1,
      referenceFilesTextArray:[],

      approvedWorkFilesTextOptions:1,
      approvedWorkFilesTextArray:[],
      taskGroup: this.props.selectedGroupid>0 ? this.props.selectedGroupid : (this.props.groups && this.props.groups.length>0 ? this.props.groups[0].id : '')
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.reactTags = React.createRef();
  }

  modules = {
    toolbar: toolbarOptions,
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('filename', file.name);
          data.append('filesize', file.size);
          data.append('filetype', file.type);
          data.append('folder_path', 'editor')
          data.append('user_id', 1);
          axios.post(urls.uploadProjectMediaEditor, data, { 
          })
          .then(res => {
            console.log(res);
            resolve(res.data.file_name);
          })
          .catch(error => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    }
  };

  minimodules = {
    toolbar:  [
      ['bold', 'italic', 'underline'], 
      [{ 'color': [] }, { 'background': [] }],           
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
      ['link']    
    ]
  };

  handleClick(e) {
    // modify the state, this will automatically recall render() below.
    this.setState((prevState) => {
      return { animate: !prevState.animate }
    });
  }

  componentDidMount = () => {
    document.title = "KIMPHUB - Boards";
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.getTaskAssets();
    }, 500);
  };

  componentWillUnmount() { 
    this.checkUserPrivilege(); 
    this.getTaskAssets();
  }


  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));             
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home");
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('am_admin')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getTaskAssets = ()=>
  {
      const data = {
        project_id: this.props.bid
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskAssets, data, {
          headers: headers
        })
      .then((response) => {
        this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          projectDetails: response.data.success !== 10 && response.data.project_details[0],
          availableAssignees: response.data.assignees,
          availableNotifiers: response.data.notifiers,
          availableBrands: response.data.brands,
          designTypes: response.data.design_types,
          labels: response.data.labels,
          progressBar:false
        })
      })
      .catch((error) => {
        this.setState({
         progressBar:false
        })
      })
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  /*handleChangeEditor(value) {
    this.setState({ ticketDetails: value })
  }*/

  handleChangeEditor = (e) => {
    //this.setState({ [target.id]: target.value });
    console.log(e.target.id);
    this.setState({name : e});
  };


  

showDashboard =() =>
  {
    this.props.history.push("/home");
  }

  showTicketList = () =>
  {
    this.props.history.push("/tickets");
  }


onTdateChange = (date) => 
  {
    this.setState({ ticketDate: date });
  }

  showAssigneeNames = () =>
  {
    var tempassginees = '';
    let temp = this.state.tassignees.map(Number);
    this.state.availableAssignees.forEach(assignee => {
      if(temp.includes(assignee.id))
      {
        tempassginees += assignee.name +', ';
      }
    });
    this.setState({
      tassigneesnames: tempassginees.slice(0, -2)
    })
  }

  

  onDelete (i) {
    const tassignees = this.state.tassignees.slice(0)
    tassignees.splice(i, 1)
    this.setState({ tassignees })
  }
 
  onAddition (tag) {
    const tassignees = [].concat(this.state.tassignees, tag)
    this.setState({ tassignees })
  }

  onDeleteNotifiers (i) {
    const tnotifiers = this.state.tnotifiers.slice(0)
    tnotifiers.splice(i, 1)
    this.setState({ tnotifiers })
  }
 
  onAdditionNotifiers (tag) {
    const tnotifiers = [].concat(this.state.tnotifiers, tag)
    this.setState({ tnotifiers })
  }


  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags })
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
  }

  setAssignees = (tags) =>
  {
    this.setState({tassignees: tags })
  }

  setNotifiers = (tags) =>
  {
    this.setState({tnotifiers: tags })
  }

  setLabels = (tags) =>
  {
    this.setState({selectedLabels: tags })
  }
  removePreviewImg = (url) =>
  {
    var index = this.fileArray.indexOf(url); // 1
    this.fileArray.splice(index, 1);
    this.fileNamesArray.splice(index, 1);
    this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
  }


  

  onPeopleChange = (userid) =>
  {        
   this.setState({responsibleBy: userid});
  }

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("YYYY-MM-DD");
    this.setState({incidentDate:momentString});
  };

  setReminderime = (e)=>
  {
    console.log(e);
    this.setState({
      reminderTime:e
    })
  }

  setDueDate = (e)=>
  {
    this.setState({
      dueDate:e
    })
  }

  setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderOptions: e.target.value,
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

 
  handleOnChange(key, e) {
    console.log(e.target.value);
    let post = this.state.post;
    post[key] = e.target.value; // key variable can be a string
    console.log(this.state);
  }

  handleTemplateChange = async(e) =>
  {
    if(e.target.value<=0)
    {
      this.setState({selectedTemplate:e.target.value, templateFields:[]});  
      return;
    }
    this.setState({
      selectedTemplate:e.target.value
    });  
    const data = {
        design_type_id: e.target.value
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskTemplateFields, data, {
          headers: headers
        })
        .then((response) => {
          this.setState({
            templateFields: response.data.fields
          })
        })
        .catch((error) => {
          
        })
  }

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };


  handleChangeUrls = ({ target }) => {
    let url = target.value.replace(/(^\w+:|^)\/\//, '');
    this.setState({ [target.name]: url });
  };

  storeFileNames = async(arrayname, item)=>
  {
      if(arrayname === 'brandingFiles')  
      { 
        let temp =[...this.state.brandingFiles];
        await this.setState({brandingFiles:[]});
        temp.push(item);
        await this.setState({brandingFiles: temp});
      }else if(arrayname === 'textInstructionFiles')  
      {
        let temp =[...this.state.textInstructionFiles];
        let tempText = [...this.state.textInstructionFilesTextArray];
        await this.setState({textInstructionFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({textInstructionFiles: temp, 
          textInstructionFilesTextArray: tempText});
      
      }else if(arrayname === 'imageryFiles')  
      {
        let temp =[...this.state.imageryFiles];
        let tempText = [...this.state.imageryFilesTextArray];
        await this.setState({imageryFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({imageryFiles: temp, imageryFilesTextArray: tempText});
      }else if(arrayname === 'referenceFiles')  
      {
        let temp =[...this.state.referenceFiles];
        let tempText = [...this.state.referenceFilesTextArray];
        await this.setState({referenceFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({referenceFiles: temp, referenceFilesTextArray: tempText});
      }else if(arrayname === 'approvedWorkFiles')  
      {
        let temp =[...this.state.approvedWorkFiles];
        let tempText = [...this.state.approvedWorkFilesTextArray];
        await this.setState({approvedWorkFiles:[]});
        temp.push(item);
        tempText.push('');
        await this.setState({approvedWorkFiles: temp, approvedWorkFilesTextArray: tempText});
      }
  }


  storeTaskFileNames = async(arrayname, item)=>
  {
    
    let temp =[...this.state.taskAttachments];
    let tempText = [...this.state.taskAttachmentTextArray];
    await this.setState({taskAttachments:[]});
    temp.push(item);
    tempText.push('');
    await this.setState({taskAttachments: temp, taskAttachmentTextArray: tempText});
  }
  
  deletefiles = (filename, arrayname) =>
  {
    if(arrayname == 'taskAttachments')
    {
      this.setState({taskAttachmentsChange:true});
      var assetupdate = [...this.state.taskAttachments];
      var assetTextupdate = [...this.state.taskAttachmentTextArray];
      this.setState({taskAttachments:[]});
      var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
      assetupdate.splice(itemkey, 1);
      assetTextupdate.splice(itemkey, 1);
        const _this = this;
        setTimeout(()=>
        {
          _this.setState({taskAttachments: assetupdate, taskAttachmentTextArray:assetTextupdate, taskAttachmentsChange:false});
        }, 500);
      }else if(arrayname == 'textInstructionFiles')
      {
        this.setState({textInstructionFilesChange:true});
        var assetupdate = [...this.state.textInstructionFiles];
        var assetTextupdate = [...this.state.textInstructionFilesTextArray];
        this.setState({textInstructionFiles:[]});
        var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
        assetupdate.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
          const _this = this;
          setTimeout(()=>
          {
            _this.setState({textInstructionFiles: assetupdate, textInstructionFilesTextArray:assetTextupdate, textInstructionFilesChange:false});
          }, 500);
        }else if(arrayname == 'imageryFiles')
        {
          this.setState({imageryFilesChange:true});
          var assetupdate = [...this.state.imageryFiles];
          var assetTextupdate = [...this.state.imageryFilesTextArray];
          this.setState({imageryFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({imageryFiles: assetupdate,
                imageryFilesTextArray:assetTextupdate, 
                imageryFilesChange:false});
            }, 500);
        }else if(arrayname == 'referenceFiles')
        {
          this.setState({referenceFilesChange:true});
          var assetupdate = [...this.state.referenceFiles];
          var assetTextupdate = [...this.state.referenceFilesTextArray];
          this.setState({referenceFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({referenceFiles: assetupdate,
                referenceFilesTextArray: assetTextupdate, 
                referenceFilesChange:false});
            }, 500);
        }else if(arrayname == 'approvedWorkFiles')
        {
          this.setState({approvedWorkFilesChange:true});
          var assetupdate = [...this.state.approvedWorkFiles];
          var assetTextupdate = [...this.state.approvedWorkFilesTextArray];
          this.setState({approvedWorkFiles:[]});
          var itemkey = assetupdate.map(function (item) { return item.file_name; }).indexOf(filename); 
          assetupdate.splice(itemkey, 1);
          assetTextupdate.splice(itemkey, 1);
            const _this = this;
            setTimeout(()=>
            {
              _this.setState({approvedWorkFiles: assetupdate, 
                approvedWorkFilesTextArray: assetTextupdate,
                approvedWorkFilesChange:false});
            }, 500);
        } 
  }

  updateFileText = (arrayname, values) =>
  {
    if(arrayname == 'taskAttachments')
    {
      this.setState({taskAttachmentTextArray:values});
    }else  if(arrayname == 'textInstructionFiles')
    {
      this.setState({textInstructionFilesTextArray:values});
    }else  if(arrayname == 'imageryFiles')
    {
      this.setState({imageryFilesTextArray:values});
    }else  if(arrayname == 'referenceFiles')
    {
      this.setState({referenceFilesTextArray:values});
    }else  if(arrayname == 'approvedWorkFiles')
    {
      this.setState({approvedWorkFilesTextArray:values});
    }
  }
  saveNewTask = async(type)=>
  {
    let temp = [];
    this.setState({errors:[], draftAssigneWarning:false});
    if(type == 2 && this.state.tassignees.length>0)
    {
        this.setState({draftAssigneWarning:true});
        return false;
    }
    else if(this.state.taskName == '')
    {
      temp.push('taskName');
      await this.setState({errors: temp});
      return false;
    }
    this.setState({errors: [], saveupdateProgress:true});
    const data = {
      savetype: type,
      project_id: this.props.bid,
      project_name: this.props.project_name,
      task_list_id:this.props.activelist,
      task_group_id: this.state.taskGroup,
      creator: this.state.myId,
      mname: this.state.myName,
      task_name: this.state.taskName,
      template_id: this.state.selectedTemplate,
      assignees:this.state.tassignees,
      notifiers: this.state.tnotifiers,
      labels: this.state.selectedLabels,
      priority: this.state.priority,
      brand_id: this.state.brand,
      description: this.state.description,
      task_attachments: this.state.taskAttachments,
      dueDate:this.state.dueDate,
      reminder: this.state.reminderTime,
      task_type: this.state.TaskType,
      fieldDataText: {
        Size: this.state.sizeFields,
        FileSizeLimit: this.state.FileSizeLimit,
        Purpose: this.state.Purpose,
        Guidelines: (this.state.Guidelines && this.state.Guidelines.length<=0) ? ' ' : this.state.Guidelines,
        Website: this.state.Website && this.state.Website.replace(/(^\w+:|^)\/\//, ''),
        Facebook:this.state.Facebook && this.state.Facebook.replace(/(^\w+:|^)\/\//, ''),
        Twitter: this.state.Twitter && this.state.Twitter.replace(/(^\w+:|^)\/\//, ''),
        LinkedIn: this.state.LinkedIn && this.state.LinkedIn.replace(/(^\w+:|^)\/\//, ''),
        Instagram: this.state.Instagram && this.state.Instagram.replace(/(^\w+:|^)\/\//, ''),
        Pinterest: this.state.Pinterest && this.state.Pinterest.replace(/(^\w+:|^)\/\//, ''),
        OtherMedia: this.state.OtherMedia && this.state.OtherMedia.replace(/(^\w+:|^)\/\//, ''),
        Text_Instructions: this.state.Text_Instructions,
        Style_Info: this.state.Style_Info,
        Style_Colors: (this.state.addonColors && this.state.addonColors.length>0) ?  this.state.Style_Colors.concat(this.state.addonColors) : this.state.Style_Colors,
        Style_color_predefined: this.state.Style_Colors,
        Style_color_addon: this.state.addonColors,
        color_instructions: this.state.ColorInstructions,
        Style_Fonts: this.state.Style_Fonts,
        font_instructions:this.state.FontInstructions,
        Target_Audience: this.state.Target_Audience,
        Other_Instructions: this.state.Other_Instructions,
        File_Formats: this.state.outputFileFormats,
        File_Formats_other: this.state.fileFormatOther,
        Softwares: this.state.softwarestoUse,
        Software_other: this.state.softwareOther,
        outsoftInstructions: this.state.outsoftInstructions,
        Watermark: this.state.Watermark,
        Mockup: this.state.Mockup,
        Provided_by_client: this.state.Provided_by_client,
        Previous_Approved_work: this.state.Previous_Approved_work,
        resize_crop: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.resizeCrop : '' ),
        bleedSize: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedSize : '' ),
        bleedUnit: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedUnit : '' )
      },
      fieldDataLinkText:
      {
        taskAttachmentsText: this.state.taskAttachmentTextOptions == 1 ? this.state.taskAttachmentsText:'',
        taskAttachmentTextArray: this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextArray:[]
      },
      fieldDataUploads:
      {
        brandingFiles: [],
        textInstructionFiles: [],
        imageryFiles: [],
        referenceFiles: [],
        approvedWorkFiles: []
      }
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.addNewListTask, data, {
        headers: headers
      })
      .then((response) => {
        response.data.success === 1? notifySuccess() : (response.data.success === 2 ? notifyDraft() : notifyError() )
        if(response.data.success === 1 || response.data.success === 2)
        {
         this.setState({taskAttachments:[], taskAttachmentsText:'',taskAttachmentTextArray:[]})
         this.props.closeaddpop();
        }
      })
      .catch((error) => {
        console.log(error);
        notifyError();
      })
  }

  goToHome = () =>
  {
    this.props.history.push("/home");
  }

  goToProjectsHome = () =>
  {
    this.props.history.push("/boards");
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl);
  }
 
  addSizeFields = () =>
  {
    const values = [...this.state.sizeFields];
    values.push({ width: '', height: '' ,unit:this.state.lastSelectedUnit, note:''});
    this.setState({sizeFields:values})
  }
  
 handleSizeInputChange = (index, event) => {
  const values = [...this.state.sizeFields];
    if (event.target.name === "widthsize") {
      values[index].width = event.target.value;
    } else if (event.target.name === "heightsize") {
      values[index].height = event.target.value;
    }else if (event.target.name === "unitsize") {
      values[index].unit = event.target.value;
      this.setState({lastSelectedUnit: event.target.value});
    }else if (event.target.name === "note") {
      values[index].note = event.target.value;
    }
    this.setState({sizeFields:values})
  };


  removeSizeFields = index => {
    const values = [...this.state.sizeFields];
    values.splice(index, 1);
    this.setState({sizeFields:values})
  }

  toggleNetwork = () =>
  {
    this.setState({showNetworks: !this.state.showNetworks})
  }


  clearSelection = (type) => {
    type == 'Watermark-clear' && this.setState({'Watermark': '' });
    type == 'Mockup-clear' && this.setState({'Mockup': '' });
    type == 'Provided-clear' && this.setState({'Provided_by_client': '' });
    type == 'Approved-clear' && this.setState({'Previous_Approved_work': '' });
    type == 'Reminder-clear' && this.setState({'reminderOptions': '', reminderTime:'' });
    type == 'tasktype-clear' && this.setState({'TaskType': ''});
  };

  handleFileFormats = e => {
    let fileFormats = this.state.outputFileFormats;
    if (e.target.checked) {
      fileFormats.push(e.target.value);
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:true});
      }
    } else {
      var array = [...this.state.outputFileFormats]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ outputFileFormats: array });
      }
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:false, fileFormatOther:''});
      }
    }
  };
   
  handleSoftwares = e => {
    let softwares = this.state.softwarestoUse;
    if (e.target.checked) {
      softwares.push(e.target.value);
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:true});
      }
    } else {
      var array = [...this.state.softwarestoUse]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ softwarestoUse: array });
      }
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:false, softwareOther:''});
      }
    }
  };


  handlePriority = e => {
    if (e.target.checked) {
      this.setState({priority:1});
    } else {
      this.setState({priority:0});
    }
  };

  handleColorInputChange =  (index, event) => {
    const values = [...this.state.addonColors];
      if (event.target.name === "hexcode") {
        values[index]= event.target.value;
      }
     this.setState({addonColors: values});
 }

  addColorFields = ()=>
  {
      const values = [...this.state.addonColors];
      values.push('');
      this.setState({addonColors: values});
  }

  removeColorFields = (index) => {
      const values = [...this.state.addonColors];
      values.splice(index, 1);
      this.setState({addonColors: values});
  }

  showOptions = ({target}) =>
  {
      if(target.name ==='brandcolors')
      {
        let temp =[];
        this.state.brandPredefinedColors.forEach(p1 => {
          temp.push(p1.hexcode);
        });

        this.setState({Style_Colors:temp});

        this.setState({
          showBrandColors: !this.state.showBrandColors,
        })
      }else if(target.name ==='addoncolors')
      {
        this.setState({
          showAddonColors: !this.state.showAddonColors,
        })
      }else if(target.name ==='brandinstro')
      {
        if(target.checked)
        {
          this.setState({
            showColorGuides: !this.state.showColorGuides,
            ColorInstructions: this.state.ColorInstructionsTemp ? this.state.ColorInstructionsTemp : '',
            FontInstructions: this.state.FontInstructionsTemp? this.state.FontInstructionsTemp : ''
          })
        }else
        {
          this.setState({
            showColorGuides: !this.state.showColorGuides,
            ColorInstructions: '',
            FontInstructions: ''
          })
        }
      }
  }

  addSelectedColors = (code) =>
  {
    let temp = [...this.state.Style_Colors];
    if(temp.length>0)
    {
      var index = temp.indexOf(code);
      if (index !== -1) {
        temp.splice(index, 1);
        this.setState({ Style_Colors: temp });
      }else
      {
        temp.push(code);
        this.setState({ Style_Colors: temp });
      }
    }else
    {
      temp.push(code);
      this.setState({ Style_Colors: temp });
    }
  }

  showActiveClass = ()=>
  {
    this.setState({activeClass:'active'});
  }

  hideActiveClass = ()=>
  {
    this.setState({activeClass:''});
  }

 handleBleedInputChange =  (event) => {
    if (event.target.name === "bleed-size") {
        this.setState({bleedSize:event.target.value});
        } else if (event.target.name === "unitsize") {
          this.setState({bleedUnit:event.target.value});
      }
};

handClickUpdate = ({ target }) => {
  if(target.name=='taskAttachmentTextOptions')
  {
    this.setState({ [target.name]: target.value, taskAttachmentsChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({taskAttachmentsChange:false});
    }, 500);
  }else if(target.name=='textInstructionFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, textInstructionFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({textInstructionFilesChange:false});
    }, 500);
  }else if(target.name=='imageryFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, imageryFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({imageryFilesChange:false});
    }, 500);
  }else if(target.name=='referenceFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, referenceFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({referenceFilesChange:false});
    }, 500);
  }else if(target.name=='approvedWorkFilesTextOptions')
  {
    this.setState({ [target.name]: target.value, approvedWorkFilesChange:true });
    const _this = this;
    setTimeout(()=>
    {
      _this.setState({approvedWorkFilesChange:false});
    }, 500);
  }
};

closeMyModal = ()=>
{
  this.props.closecancel()
}

  render() {
    let animationClasses = (this.state.animate ? ' active_arrow': '');
    return (
      <Fragment>
      <Container fluid className="top-bar-inactive bc_module_container">
       <div className="">
       <LoadingWrapper isLoading={this.state.progressBar}>
            {this.state.accessDenied ?
            (
              <>
                <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
              </>
            ):
            (
            <>
            <Row>
            <Col sm="12" className="bc_module">
            <Toaster position="top-right" containerClassName="notifier"
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                    },}}
              />
           
          </Col>
          </Row>
          <div className="new-list-task new_tas_formbody bc_module">
          <Row className="justify-content-center">
          <Col sm="11" xl="10">
            <div className="newtask_form_body" id="newtask">
            
                <div className="form-group">
                  <label>Card Name</label>
                  <Input autoComplete="off" type="text" 
                  className={this.state.errors.includes('taskName')? 'field-error text_box' : 'text_box'}
                  name="taskName" onChange={this.handleChange} />
                  
                </div>
                <Row>
                 
                  {this.props.groups && this.props.groups.length>0 &&
                  (
                    <Col sm="4">
                         <div className="form-group">
                            <label>Task Group</label>
                            <select className="choose_temp" name="taskGroup" onChange={this.handleChange}>
                              <option value="">Select</option>
                              {this.props.groups.map((group, index)=>
                              {
                                return(
                                  <option selected={this.state.taskGroup === group.id && 'selected'} value={group.id}>{group.group_name}</option>
                                )
                              })}
                            </select>
                          </div>
                    </Col>
                  )}
                  <Col sm="4">
                    {this.state.availableAssignees && this.state.availableAssignees.length>0 &&
                    (
                      <div className="form-group">
                        <label>Assigned To</label>
                          <PeopleTags tagslist={this.state.availableAssignees} 
                          settags={this.setAssignees}
                          placeholder="Add Assignees"
                          tags={this.state.tassignees}
                          />
                      </div>
                    )}
                  </Col>
                  <Col sm="4">
                    {this.state.availableNotifiers && this.state.availableNotifiers.length>0 &&
                    (
                      <div className="form-group">
                        <label>Notify when done</label>
                        <PeopleTags tagslist={this.state.availableNotifiers} 
                        settags={this.setNotifiers}
                        tags={this.state.tnotifiers}
                        placeholder="Add Notifiers" />
                      </div>
                    )}
                  </Col>
                </Row>
                <>
                    <div className="form-group manager-comments">
                      {(this.state.selectedTemplate === '' || this.state.selectedTemplate <=0) ?
                      (<label>Description</label>)
                      :
                      (<label>Other Instructions by Project Manager</label>)
                      }
                      <ReactQuill 
                        id="description" 
                        onChange={(e)=>this.setState({description:e})}
                        className={this.state.detailError? 'field-error' : ''}
                        placeholder= "Add Comments Here....." 
                        theme="snow" 
                        modules={this.modules}
                      />

                      {/* <Editor /> */}
                    </div>
                    {(this.state.selectedTemplate === '' || this.state.selectedTemplate <=0) &&
                    (
                      <div className="form-group">
                        <Row className={`uploader-text ${this.state.taskAttachmentTextOptions==2 && 'indv-instructions-wrap'}`}>
                          <Col sm="6">
                            <KFileUpload myid={this.state.myId} 
                              folderpath="task/attachments" 
                              arrayname="taskAttachments" 
                              storefiles={this.storeTaskFileNames} 
                            />
                          </Col>
                          <Col sm="6">
                              <ReactQuill 
                                id="taskAttachmentsText" 
                                onChange={(e)=>this.setState({taskAttachmentsText:e})}
                                className={this.state.detailError? 'field-error' : ''}
                                placeholder= "Add the file details or links" 
                                theme="snow" 
                                modules={this.minimodules}
                              />
                            </Col>
                            <Col sm="12" >
                                {(this.state.taskAttachments && this.state.taskAttachments.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.taskAttachmentTextOptions == 1 && 'checked'}  id="taskAttachmentscombined" 
                                      type="radio" name="taskAttachmentTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="taskAttachmentscombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.taskAttachmentTextOptions == 2 && 'checked'} id="taskAttachmentsseparate" 
                                      type="radio" name="taskAttachmentTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="taskAttachmentsseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.taskAttachments && this.state.taskAttachments.length>0 && !this.state.taskAttachmentsChange) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.taskAttachments} 
                                  deleteitem="yes"
                                  deletefiles={this.deletefiles} 
                                  arrayname={'taskAttachments'}
                                  listtype={this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextOptions : 1}
                                  taskAttachmenttextarray={this.state.taskAttachmentTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                        </Row>
                      </div>
                    )}
                    
                </>
               
              <Row>
                <Col xs={12} sm={4}>
                <div className="form-group mt-4 internal_duedate">
                  <label>Due Date and Time</label>
                  <Datetime  Placement="top"
                  className={this.state.errors.includes('dueDate')? 'field-error text_box duedate_placement' : 'text_box duedate_placement'}
                  dateFormat="DD-MM-YYYY"
                  value={this.state.dueDate}
                  onChange={this.setDueDate}
                  inputPops={{disabled: true}}
                  />
                </div>
                </Col>
                <Col xs={12} sm={4}>
                  <div className="form-group priority_checkbox">
                  <FormGroup>
                    <Input type="checkbox" id="taskpriority" name='priority'
                    onClick={(e) => this.handlePriority(e)} />
                    <label for="taskpriority">Priority Card</label>
                  </FormGroup>
                  </div>
                 </Col>
              </Row>  
                {this.state.draftAssigneWarning &&
                  (
                    <div className='remove-assignee'>You cannot add an assignee, if you are saving this task as draft.</div>
                  )}
                <div className="form-group btn_sec">
                  
                  <div className="d-flex">
                    <Button disabled={this.state.saveupdateProgress && 'disabled'} className="sd_btn" onClick={this.saveNewTask.bind(this, 2)}>Save to Drafts</Button>
                  </div>
                  <div className="d-flex">
                    <Button className="cancel_btn" onClick={()=>this.props.closecancel()}>Cancel</Button>
                    <Button disabled={this.state.saveupdateProgress && 'disabled'} type="submit" className={`save_btn task_save_btn  ${this.state.saveupdateProgress && ' disable-action'}`} onClick={this.saveNewTask.bind(this, 1)}>Save</Button>
                  </div>
                </div> 
            </div>
          </Col>
          </Row>
          </div>
          </>
          )}    
        </LoadingWrapper>  
       </div>      
      </Container>
    </Fragment>
    )
  }
}
