const NewNotificationMessageReducer = (state=[], action) =>
{
    switch(action.type)
    {
        case 'AppendNotificationMessage':
            state.message = action.payload.notification;
            state.oldmessage = 0;
            return state;
        case 'SubstractNotificationMessage':
            state.message = action.payload.notification;
            state.oldmessage = 0;
            return state;
        case 'EmptyNotificationMessage':
                state.oldmessage = 1;
                return state;
        default:
            state.message = action.payload;
            state.oldmessage = 1;
            return state;
    }
}

export default NewNotificationMessageReducer;