import React, { Component } from 'react';
import AvailabilityUsers from "./AvailabilityUsers";
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";

import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';


export default class AvailabilityFilter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {              
      selectedUser:'',
      startDate:moment(),
      endDate:moment().add(1, 'days'),
      myPrivileges:[],     
      myId:'', 
      myBaseId:'',
      userTeam:'',
      selectedCount:'Select',      
      showAllPeople: 0,
      showMin2:this.props.min2,
      showMobile:this.props.mobile,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();   
      
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
  };

onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}
onChangeMinsFilter = ()=>
{
  this.setState({showMin2: !this.state.showMin2});
  const _this = this;
	setTimeout(function() {
		_this.filterResults();
	}, 100);
  
}
onChangeMobileFilter = ()=>
{
  this.setState({showMobile: !this.state.showMobile});
  const _this = this;
	setTimeout(function() {
		_this.filterResults();
	}, 100);
}

filterResults = () =>
{
  this.props.filterlist(this.state.showMin2, this.state.showMobile);
}

searchEntriesnew = () =>
{  
  this.props.searchEntries(this.state.startDate, this.state.endDate, this.state.selectedUser);
}

  render() {
    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : <SearchIcon />;
    return (
      <div id="filter">
        
            <div className="availability-filter filter-row d-flex justify-content-between">
              <div className="fliterbox">
              <Row>
                <Col xs={12} sm={3}>
                <div className="start-date date-col date_picker_filter">
                <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
              </div>
                </Col>
                <Col xs={12} sm={3}>
                {this.state.myPrivileges.includes('access_others_report')?
              (<AvailabilityUsers onPeopleChange={this.onPeopleChange.bind(this)}  />)
              :
              null
              }
                </Col>
                <Col xs={12} sm={2}>
                <div className="search-btn">
                <Button className="" color="success"  onClick={this.searchEntriesnew.bind(this)} >
                  { searchContent }                  
                </Button>
                </div>
                </Col>
                <Col xs={12} sm={4}>
                <div className="list-filter d-flex">
                <div className="filter-wrap online_check_box">
                  <span className="min-2">
                    <Input type="checkbox" name="greater2Mins" onClick={this.onChangeMinsFilter} checked={this.state.showMin2? 'checked':''} /> { '> 2 mins'}
                    </span>
                  <span className="device-mobile">
                    <Input type="checkbox" name="deviceMobile" onClick={this.onChangeMobileFilter}  checked={this.state.showMobile? 'checked':''} /> { 'Mobile'} 
                    </span>
                </div>
               </div>
                </Col>
              </Row>
              
              
              </div>
             
            </div>
           
      </div>
    )
  }
}