import React, { Component, Fragment } from "react";
import "./ApprovalContainer.css";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import Header from "../../header/Header";
import { Container, Col, Row, Card, CardTitle } from "reactstrap";
import ApprovalHead from "../approvalHead/ApprovalHead";
import CarousalContainer from "../CarousalContainer/CarousalContainer";
import ApprovalModules from "../ApprovalModules/ApprovalModules";
import Break from "../Break/Break";
import ShiftChange from "../ShiftChange/ShiftChange";
import UnblockShift from "../UnblockShift/UnblockShift";
import Wfh from "../Wfh/Wfh";
import ApprovalLeave from "../ApprovalLeave/ApprovalLeave";
import { urls } from "../../../services/UrlList";
import { isMobile } from "react-device-detect";

export default class ApprovalContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      isMobileView: 0,
      breakRequests: [],
      shiftRemovalRequest: [],
      progressBar: false,
      breakRequestCount: 0,
      shiftUnblockCount: 0,
      wfhRequestCount: 0,
      leaveRequestCount: 0,
      showMenu:true,
      activeItem:"leave",
    };
  }

  componentDidMount = () => {
    document.title = "HUB - Approve Requests";
    this.checkUserPrivilege();
    this.renderContentDevice();
    // this.getBreakRequest();
  };

  checkUserPrivilege = () => {
    var login = JSON.parse(localStorage.getItem("user"));
    this.setState({ myPrivileges: login.privileges, myId: login.uid });
    if (!login.privileges.includes("approve_user_requests")) {
      this.props.history.push("/tick-report");
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});    
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  componentWillMount = () => {
    //this.getBreakRequest();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  onShiftFunctionCall = count => {
    this.setState({ shiftUnblockCount: count });
  };

  onBreakFunctionCall = count => {
    this.setState({ breakRequestCount: count });
  };

  onWfhFunctionCall = count => {
    this.setState({ wfhRequestCount: count });
  };

  onLeaveFunctionCall = count => {
    this.setState({ leaveRequestCount: count });
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };  



  scrollToElem = param => {
   
  };

  activeTabItem = (item) =>
  {
    this.setState({activeItem:item});
  }

  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
        <Row>
        <div className="theme_header">
        <Header {...this.props}  
                  logout={this.signOut.bind(this)}
                  onmenuaction={this.toggleSidebar.bind(this)}
                  quickactionblock={true} />          
        </div>
        <div className="SideNavbar">
        <SideMenu 
                  activeitem="approval" 
                  showmenu={this.state.showMenu} 
                  hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody">
        <Card className="switch_user_card">
              <CardTitle tag="h1">Activity</CardTitle>
              <section id="approval_wrapper">         
            

                <ApprovalHead
                  breakcount={this.state.breakRequestCount}
                  shiftcount={this.state.shiftUnblockCount}
                  wfhcount={this.state.wfhRequestCount}
                  leavecount={this.state.leaveRequestCount}
                  scrollToElem={this.scrollToElem}
                  activetabitem={this.activeTabItem}
                />
                <section className="caros-wrapper">
                  {this.state.activeItem =='break' &&
                  (
                  <Break
                    classname="break-over-quata"
                    title="Break Over Quota"
                    onbreakfuncall={this.onBreakFunctionCall}
                  />
                  )} 
                  {this.state.activeItem =='shift' &&
                  (
                  <UnblockShift
                    classname="break-over-quata"
                    title="Unblock Shift"
                    onshiftfuncall={this.onShiftFunctionCall}
                  />
                  )}
                      
                  {/* <motion.div variants={item}>
                    <ShiftChange
                      classname="break-over-quata"
                      title="Shift CHANGE"
                    />
                  </motion.div> 
                  <motion.div variants={item}>
                  <Wfh
                    classname="break-over-quata"
                    title="WFH"
                    onwfhfuncall={this.onWfhFunctionCall}
                  />
                  </motion.div>*/}
                  {this.state.activeItem =='leave' &&
                  (
                    <ApprovalLeave
                    classname="break-over-quata"
                    title="Leave"
                    onleavefuncall={this.onLeaveFunctionCall}
                    />
                  )}    
                  
                </section>
                
      </section>
        </Card>
       </div>
      </Row>
      
      </Container>
      </div>
    );
  }
}
