import React, { Component } from 'react';
import AvailabilityUsers from "./AvailabilityUsers";
import './filter.css';
import { Container, Row, Col, Label, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';


export default class RatioFilter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
    s.setDate(s.getDate());
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("DD-MM-YYYY");

    var e = new Date();
    e.setDate(e.getDate());
    e.setHours(6);
    e.setMinutes(0);
    let momentObje = moment(e);
    let momentStringEnd = momentObje.format("DD-MM-YYYY");
    this.state = {              
      selectedUser:'',
      selectedClient:'',
      startDate:moment(),
      endDate:moment().add(1, 'days'),
      myPrivileges:[],     
      myId:'', 
      myBaseId:'',
      selectedCount:'Select',      
      showAllPeople: 0,
      showRating4:this.props.ratingfilter,
      delayedCheck:0
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();   
      
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
  };

onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}
onChangeRatingFilter = ()=>
{
  this.setState({showRating4: !this.state.showRating4});
  const _this = this;
	setTimeout(function() {
		_this.filterResults();
	}, 10);
  
}

handleInputChange(input, e) {
    console.log("value", input)
}

onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    }
};

onChangeClient = (e) => {
  var size = Object.keys(e).length;
  if(size>0)
  {
  this.setState({ selectedClient:e[0].id});
  }
};

filterResults = () =>
{ 
  this.props.filterlist(this.state.showRating4);
}

searchEntriesnew = () =>
{  
  this.props.searchReportAction(this.state.startDate, this.state.endDate, this.state.selectedUser, this.state.selectedClient, this.state.delayedCheck);
}

delayedCheck = (e) => {
  if (e.target.checked) {
    this.setState({delayedCheck: e.target.value});
  }else
  {
    this.setState({delayedCheck:0})
  }
}
  render() {
    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : <SearchIcon />;
    return (
      <div id="filter" className="health-filter">
        <Container>
          <Row>
            <Col sm="12">
            <div className="availability-filter filter-row d-flex justify-content-between">
              <div className="fliterbox">
              <div className="start-date date-col datepicker-col">
                <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
              </div>
              {this.props.peoplelist.length>0 &&
                (
                  <Col sm="3">
                    <Typeahead
                    id="user-picker"
                    labelKey="collection"
                    onInputChange={this.handleInputChange}
                    onChange={this.onChangePeople}
                    options={this.props.peoplelist}
                    placeholder="Choose Collection"
                    selected=''
                    />
                  </Col>
                )
              }
              
             <div className="search-btn">
                <Button className="" color="success"  onClick={this.searchEntriesnew.bind(this)} >
                  { searchContent }                  
                </Button>
              </div>
              </div>
             
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}