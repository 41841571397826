import React, { Component, Fragment } from 'react';
import { urls } from "../../services/UrlList.js";
import { Container, Row, Col, Card, Button, Progress, Input, Label, FormGroup } from 'reactstrap';
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from '../header/Header';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import FullUserPicker from "./FullUserPicker";
import { motion } from 'framer-motion'
import "./usermanagement.css";
import AttachmentIcon from "../svgicon/AttachmentIcon";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import Lock from "../../images/lock-icon.png";
import UnLock from "../../images/unlock-icon.png";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import MaterialIcon from "material-icons-react";

export default class UserManagement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			myId: '',
			myPrivileges: [],
			selectedUser: '',
			allUsers: [],
			allBaseUsers: [],
			showMenu: true,
			userData: '',
			selectedBaseUser: '',
			allNotificationRoles: [],
			allDotoRoles: [],
			allDotoTeams: [],
			allDotoShifts: [],
			initialLoader: true,
			userId: '',
			reportingOfficers: [],
			projectManagers: [],
			pmTeamLeads: [],
			coms: [],
			ops: [],
			teamLeads: [],
			currencies: [],
			timeZones: [],
			fullName: '',
			realName: '',
			marital: '',
			lastDate: '',
			empId: '',
			roleDoto: '',
			gender: '',
			doj: '',
			dob: '',
			ticketAssignee: '',
			PIP: '',
			Automation: '',
			officialEmail: '',
			personalEmail: '',
			employmentType: '',
			team: '',
			reportingTo: '',
			projectManager: '',
			opsManager:'',
			comManager:'',
			currency: '',
			salaryPerMonth: '',
			salaryDisbursement: '',
			salaryRevisions: [],
			revisedPack: '',
			revisedPercentage: '',
			revisedDate: '',
			privateNote: '',
			addedBy: '',
			privateNotes: [],
			showAddNote: false,
			showSalaryRevision: false,
			selectedOfferLetter: '',
			selectedOfferLetterName: '',
			selectedContractFileName: '',
			selectednationalIdName: '',
			selectedpanIdName: '',
			selectedpassportIdName: '',
			selecteddrivingIdName: '',
			selectedOtheridName: '',
			uploadingOffer: false,
			employeeTypes: [],
			teamCountries: [],
			profileStatus: '',
			activationLoader: true,
			hiddenclass: 'hidetext',
			hiddenClassActive: true,
			contractFile: '',
			oldData: [],
			changedFields: [],
			showFlock:false,
			flockError:false,
			kimp360Profiles:[],
			kimp360Association:''
		};
	}

	componentDidMount = () => {
		document.title = "HUB - Profile Management";
		this.checkUserPrivilege();
		const _this = this;
		setTimeout(function () {
			_this.getAllBaseProfiles();
			_this.getEmployeeTypes();
			_this.getTeamCountries();
			_this.getAllNotificationRoles();
			_this.getAllDotoRolesTeams();
			_this.getReportingOfficers();
			_this.getPMTeamLeads();
			_this.getProjectManagers();
			_this.getteamLeads();
			_this.getCurrencies();
			_this.getTimezones();
			_this.onPeopleChange(_this.state.userId);
			_this.getSalaryRevisions(_this.state.userId);
			_this.getPrivateNotes(_this.state.userId);
			_this.getProfileaStatus(_this.state.userId);
			_this.get360Profiles();
		}, 1000);

	}

	checkUserPrivilege = () => {
		if (localStorage.getItem("user") !== null) {
			var login = JSON.parse(localStorage.getItem("user"));
			this.setState({
				myPrivileges: login.privileges,
				myId: login.uid
			});

			if (login.privileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) {
				this.setState({ userId: this.props.match.params.id });
			} else {
				this.setState({ userId: login.uid });
			}
		}
	};

	signOut = () => {
		fetch(urls.logmeout)
			.then(response => response.json())
			.then(response => {
				localStorage.clear();
				this.props.history.push("/");
			})
			.catch(error => {
				localStorage.clear();
				this.props.history.push("/");
				fetch(urls.logmeout)
					.then(response => response.json())
					.then(response => {
						localStorage.clear();
						this.props.history.push("/");
					})
					.catch(error => {
						localStorage.clear();
						this.props.history.push("/");
					});

			});
	};

	getTimezones = () => {
		fetch(urls.getTimezones,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					timeZones: response[0].timezones,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getCurrencies = () => {
		fetch(urls.getCurrencies,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					currencies: response[0].currencies,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}


	getPMTeamLeads = () => {
		fetch(urls.getPMTeamLeads,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					pmTeamLeads: response[0].pmtls,
					coms: response[0].coms,
					ops: response[0].ops,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	get360Profiles = () => {
		fetch(urls.show360Profiles,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					kimp360Profiles: response[0].kimp360profiles
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}


	getProjectManagers = () => {
		fetch(urls.getProjectManagers,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					projectManagers: response[0].pms,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}


	getteamLeads = () => {
		fetch(urls.getteamLeads,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					teamLeads: response[0].tls,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}


	getReportingOfficers = () => {
		fetch(urls.getReportingOfficers,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					reportingOfficers: response[0].officers,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}
	getAllBaseProfiles = () => {
		fetch(urls.getAllBaseProfiles,
			{
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
				}
			}
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					allBaseUsers: response.base
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getAllNotificationRoles = () => {
		fetch(urls.getNotificationRoles,
			{
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
				}
			}
		)
			.then(response => response.json())
			.then(response => {
				this.setState({
					allNotificationRoles: response.notificationroles
				});
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getAllDotoRolesTeams = () => {
		fetch(urls.getDotoRolesTeams,
			{
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
				}
			}
		)
		.then(response => response.json())
		.then(response => {
			this.setState({
				allDotoRoles: response.dotoroles,
				allDotoTeams: response.teams,
				allDotoShifts: response.shifts,
				initialLoader: false
			});
		})
		.catch((error) => {
			this.setState({
				initialLoader: false
			});
		})
	}

	toggleSidebar = () => {
		this.setState({ showMenu: !this.state.showMenu });
	}

	hideMenu = () => {
		this.setState({ showMenu: !this.state.showMenu });
	}

	onChangeProfile = (e) => {
		this.setState({
			selectedBaseUser: e.target.value
		});
	}

	onChangeNRole = (e) => {

	}

	onChangeDRole = (e) => {

	}

	onPeopleChange = (userid) => {
		this.setState({
			userData: [],
			initialLoader: true
		});
		var fUrl = urls.finduseforedit + userid;
		fetch(fUrl,
			{
				headers: {
					"Access-Control-Allow-Origin": "*",
					"Content-Type": "application/json"
				}
			}
		)
			.then(response => response.json())
			.then(response => {

				this.setState({
					oldData: response['user'],
					userid: response['user'].userid,
					userData: response['user'],
					basecampAssociation: response['user'].basecamp_user_id,
					kimp360Association: response['user'].kimp360_id,
					initialLoader: false,
					fullName: response['user'].name,
					realName: response['user'].full_name,
					marital: response['user'].marital_status,
					lastDate: response['user'].last_day_of_service,
					empId: response['user'].employee_id,
					roleDoto: response['user'].doto_role_id,
					gender: response['user'].gender,
					doj: response['user'].date_of_joining,
					dob: response['user'].date_of_birth,
					ticketAssignee: response['user'].ticket_assignee,
					PIP: response['user'].on_pip,
					Automation: response['user'].allow_automation,
					officialEmail: response['user'].email,
					personalEmail: response['user'].personal_email,
					employmentType: response['user'].employee_type,
					team: response['user'].user_team_id,
					reportingTo: response['user'].my_reporting_officer,
					projectManager: response['user'].project_manager_id,
					pmTeamLead: response['user'].pm_tl_id,
					teamLead: response['user'].tl_id,
					comManager: response['user'].coms,
					opsManager: response['user'].ops,
					currency: response['user'].currency,
					salaryPerMonth: response['user'].salary,
					salaryDisbursement: response['user'].salary_disbursement,
					extendedBreak: response['user'].user_extended_break_access,
					combineLeaves: response['user'].user_allow_combine_leave,
					probation: response['user'].on_probation,
					reportingOfficer: response['user'].user_main_reporting_officer,
					workShift: response['user'].user_shift,
					workCity: response['user'].work_city,
					timezone: response['user'].work_timezone,
					residentialAddress: response['user'].residential_address,
					city: response['user'].city,
					country: response['user'].country,
					zipcode: response['user'].pincode,
					phone: response['user'].personal_phone,
					alternatePhone: response['user'].alternative_phone,
					offerLetter: response['user'].offer_letter,
					contractFile: response['user'].signed_contract_file,
					nationId: response['user'].national_id,
					panId: response['user'].pan_card,
					passportId: response['user'].passport,
					drivingId: response['user'].driving,
					otherId: response['user'].other_id,
					notificationAdmin: response['user'].notification_admin_category_id,
					notificationRole: response['user'].notification_role_id,
					generalLeave: response['user'].aloc_general_leave,
					earnedLeave: response['user'].aloc_earned_leave,
					sickLeave: response['user'].aloc_sick_leave,
					casualLeave: response['user'].aloc_casual_leave,
					maternityLeave: response['user'].aloc_maternity_leave,
					paternityLeave: response['user'].aloc_paternity_leave,
					compensatoryOff: response['user'].aloc_compensatory_off,
					teamCountryAssigned: response['user'].team_holiday_country,
					countryHolidays: response['user'].aloc_company_holiday,
					bereavmentLeave: response['user'].aloc_bereavement_leave,
					accountHolder: response['user'].account_holder,
					bankName: response['user'].bank_name,
					accountNumber: response['user'].account_number,
					ifscCode: response['user'].ifsc_swift_code,
					bankBranch: response['user'].branch,
					payPalAddress: response['user'].paypal_address,
					accountType: response['user'].account_type,
					paymentType: response['user'].payment_method,
					leaveProfileType: response['user'].leave_profile_type,
					amountCurrency: response['user'].amount_currency,
					sourceCurrency: response['user'].source_currency,
					targetCurrency: response['user'].target_currency,
					onlineCheck: response['user'].want_online_check,
					clientAssignment: response['user'].disable_client_task_assigning,
					trackingenabled: response['user'].tracking_enabled,
					flockChannel: response['user'].flock_channel,
					showFlock: response['user'].want_online_check === 1? true : false
				});

			})
			.catch((error) => {
				this.setState({
					initialLoader: false
				});
			})
	}

	async compareandUpdate(inital, current, changefield) {
		if (inital !== current) {
			let temp = [...this.state.changedFields];
			temp.push(changefield + ": " + inital + " => " + current);
			this.setState({
				changedFields: temp
			})
		}
	}

	updateProfile = async () => {
		this.setState({ changedFields: [] });
		await this.compareandUpdate(this.state.oldData.full_name, this.state.realName, "Full Name");
		await this.compareandUpdate(this.state.oldData.name, this.state.fullName, "Display Name");
		await this.compareandUpdate(this.state.oldData.marital_status, this.state.marital, "Marital Status");
		await this.compareandUpdate(this.state.oldData.last_day_of_service, this.state.lastDate, "Last Day of Service");
		await this.compareandUpdate(this.state.oldData.gender, this.state.gender, "Gender");
		await this.compareandUpdate(this.state.oldData.date_of_joining, this.state.doj, "Date of Joining");
		await this.compareandUpdate(this.state.oldData.date_of_birth, this.state.dob, "Date of Birth");
		this.setState({
			initialLoader: true
		});
		let opts = {
			userid: this.state.userid,
			fullName: this.state.fullName,
			realName: this.state.realName,
			marital: this.state.marital,
			lastDate: this.state.lastDate,
			empId: this.state.empId,
			roleDoto: this.state.roleDoto,
			gender: this.state.gender,
			doj: this.state.doj,
			dob: this.state.dob,
			officialEmail: this.state.officialEmail,
			personalEmail: this.state.personalEmail,
			employmentType: this.state.employmentType,
			team: this.state.team,
			reportingTo: this.state.reportingTo,
			pmTeamLead: this.state.pmTeamLead,
			projectManager: this.state.projectManager,
			teamLead: this.state.teamLead,
			coms: this.state.comManager,
			ops: this.state.opsManager,
			currency: this.state.currency,
			salaryPerMonth: this.state.salaryPerMonth,
			salaryDisbursement: this.state.salaryDisbursement,
			extendedBreak: this.state.extendedBreak,
			combineLeaves: this.state.combineLeaves,
			probation: this.state.probation,
			//ticketAssignee: this.state.ticketAssignee,
			//pip: this.state.PIP,
			//automation: this.state.Automation,
			reportingOfficer: this.state.reportingOfficer,
			workShift: this.state.workShift,
			workCity: this.state.workCity,
			timezone: this.state.timezone,
			residentialAddress: this.state.residentialAddress,
			city: this.state.city,
			country: this.state.country,
			zipcode: this.state.zipcode,
			phone: this.state.phone,
			alternatePhone: this.state.alternatePhone,
			offerLertter: this.state.offerLertter,
			contractFile: this.state.contractFile,
			nationId: this.state.nationId,
			panId: this.state.panId,
			passportId: this.state.passportId,
			drivingId: this.state.drivingId,
			otherId: this.state.otherId,
			notificationAdmin: this.state.notificationAdmin,
		}

		// console.log(opts);
		// return false;

		fetch(urls.updateUserProfile, {
			headers: {
				'Access-Control-Allow-origin': '*',
				'Content-Type': 'application/json'
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					initialLoader: false
				})

			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	updateCurrentSalary = () => {
		this.setState({
			initialLoader: true
		});
		let opts = {
			userid: this.state.userid,
			currency: this.state.currency,
			salaryPerMonth: this.state.salaryPerMonth,
			salaryDisbursement: this.state.salaryDisbursement
		}
		fetch(urls.updateCurrentSalary, {
			headers: {
				'Access-Control-Allow-origin': '*',
				'Content-Type': 'application/json'
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					initialLoader: false
				})

			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	updateBasecampProfile = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			basecampAssociation: this.state.basecampAssociation
		};
		fetch(urls.updateBasecampProfile,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					initialLoader: false
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	updateKimp360Profile = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			kimp360Association: this.state.kimp360Association
		};
		console.log(opts);
		fetch(urls.updateKimp360Profile,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					initialLoader: false
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}


	updateNotificationProfile = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			notificationRole: this.state.notificationRole
		};
		fetch(urls.updateNotificationProfile,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					initialLoader: false
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	updateLeaveProfile = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			generalLeave: this.state.generalLeave,
			earnedLeave: this.state.earnedLeave,
			sickLeave: this.state.sickLeave,
			casualLeave: this.state.casualLeave,
			maternityLeave: this.state.maternityLeave,
			paternityLeave: this.state.paternityLeave,
			compensatoryOff: this.state.compensatoryOff,
			teamCountryAssigned: this.state.teamCountryAssigned,
			countryHolidays: this.state.countryHolidays,
			bereavmentLeave: this.state.bereavmentLeave,
			leaveProfileType: this.state.leaveProfileType
		};
		fetch(urls.updateLeaveProfile,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					initialLoader: false
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	updateBankProfile = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			accountHolder: this.state.accountHolder,
			bankName: this.state.bankName,
			accountNumber: this.state.accountNumber,
			ifscCode: this.state.ifscCode,
			bankBranch: this.state.bankBranch,
			payPalAddress: this.state.payPalAddress,
			accountType: this.state.accountType,
			paymentType: this.state.paymentType,
			amountCurrency:this.state.amountCurrency,
			sourceCurrency: this.state.sourceCurrency,
			targetCurrency: this.state.targetCurrency
		};
		fetch(urls.updateBankProfile,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					initialLoader: false
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	addPackRevision = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userid,
			revisedPack: this.state.revisedPack,
			revisedPercentage: this.state.revisedPercentage,
			revisedDate: this.state.revisedDate
		};
		fetch(urls.addPackRevision,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				this.getSalaryRevisions(this.state.userId);
				this.setState({
					initialLoader: false,
					revisedPack: '',
					revisedPercentage: '',
					revisedDate: '',
					showSalaryRevision: !this.state.showSalaryRevision
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	addPrivateNote = () => {
		this.setState({
			initialLoader: true
		})
		let opts = {
			userid: this.state.userId,
			notes: this.state.privateNote,
			added_by: this.state.myId
		};
		fetch(urls.addPrivateNote,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				},
				method: "post",
				body: JSON.stringify(opts)
			})
			.then(response => response.json())
			.then(response => {
				this.getPrivateNotes(this.state.userId);
				this.setState({
					initialLoader: false,
					notes: '',
					showAddNote: !this.state.showAddNote
				})
			})
			.catch(error => {
				this.setState({
					initialLoader: false
				})
			})
	}

	getSalaryRevisions = () => {
		fetch(urls.getSalaryRevisions + this.state.userId,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					salaryRevisions: response[0].revision,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}
	getPrivateNotes = () => {
		fetch(urls.getPrivateNotes + this.state.userId,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					privateNotes: response[0].privatenote,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	convertTimeFormat = (time) => {
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}


	handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

	showAddNotes = () => {
		this.setState({
			showAddNote: !this.state.showAddNote
		});
	}

	showAddRevisions = () => {
		this.setState({
			showSalaryRevision: !this.state.showSalaryRevision
		});
	}

	onChangeHandlerOffer = event => {
		this.setState({
			selectedOfferLetterName: event.target.files[0].name,
			offerLetter: event.target.files[0].name,
			uploadingOffer: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'offer-letter');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingOffer: false
				})
			})
	}

	onChangeHandlerContract = event => {
		this.setState({
			selectedContractFileName: event.target.files[0].name,
			contractFile: event.target.files[0].name,
			uploadingContractFile: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'contract-file');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingContractFile: false
				})
			})
	}

	onChangeHandlerNationalId = event => {
		console.log('test2');
		this.setState({
			selectednationalIdName: event.target.files[0].name,
			nationId: event.target.files[0].name,
			uploadingNationalId: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'national-id');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingNationalId: false
				})
			})
	}

	onChangeHandlerPanId = event => {
		console.log(event.target.files[0].name);
		this.setState({
			selectedpanIdName: event.target.files[0].name,
			panId: event.target.files[0].name,
			uploadingPanId: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'pan-id');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				console.log(data);
				this.setState({
					uploadingPanId: false
				})
			})
	}

	onChangeHandlerPassportId = event => {
		console.log('test1');
		this.setState({
			selectedpassportIdName: event.target.files[0].name,
			passportId: event.target.files[0].name,
			uploadingPassportId: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'passport-id');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingPassportId: false
				})
			})
	}

	onChangeHandlerDrivingId = event => {
		this.setState({
			selecteddrivingIdName: event.target.files[0].name,
			drivingId: event.target.files[0].name,
			uploadingDrivingId: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'driving-id');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingDrivingId: false
				})
			})
	}


	onChangeHandlerOtherid = event => {
		console.log('test3');
		this.setState({
			selectedOtheridName: event.target.files[0].name,
			otherId: event.target.files[0].name,
			uploadingOtherId: true
		})
		const data = new FormData()
		data.append('file', event.target.files[0]);
		data.append('filename', event.target.files[0].name);
		data.append('filetypee', 'other-id');
		data.append('user_id', this.state.userid);
		axios.post(urls.uploadUserFiles, data, {
			// receive two    parameter endpoint url ,form data
		})
			.then(res => {
				this.setState({
					uploadingOtherId: false
				})
			})
	}

	getEmployeeTypes = () => {
		fetch(urls.getEmployeeTypes,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					employeeTypes: response[0].types,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getTeamCountries = () => {
		fetch(urls.getTeamCountries,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					teamCountries: response[0].countries,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	getProfileaStatus = (id) => {
		fetch(urls.getProfileaStatus + id,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					profileStatus: response[0].status,
					activationLoader: false
				})
			})
			.catch((error) => {
				this.setState({
					activationLoader: false
				})
			})
	}

	enableDisableAccount = (type) => {
		this.setState({
			activationLoader: true
		});
		let opts = {
			userid: this.state.userid,
			todo: type
		};
		console.log(opts);
		fetch(urls.updateProfileStatus, {
			headers: {
				'Access-Control-Allow-origin': '*',
				'Content-Type': 'application/json'
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {
				console.log(response);
				this.setState({
					profileStatus: response[0].status,
					activationLoader: false
				});

			})
			.catch(error => {
				console.log(error);
				this.setState({
					activationLoader: false
				})
			})
	}

	changeLock = () => {
		this.setState({
			hiddenClassActive: !this.state.hiddenClassActive
		})
	}

	ProfileSettingsChange = ({target}) =>
	{
		if([target.name] == 'ticketAssigneee')
		{
			if(target.checked === true)
			{
				this.setState({ticketAssignee:1});
			}else
			{
				this.setState({ticketAssignee:0});
			}
		}else if([target.name] == 'pip')
		{
			if(target.checked === true)
			{
				this.setState({PIP:1});
			}else
			{
				this.setState({PIP:0});
			}
		}else if([target.name] == 'automation')
		{
			if(target.checked === true)
			{
				this.setState({Automation:1});
			}else
			{
				this.setState({Automation:0});
			}
		}else if([target.name] == 'clientassignment')
		{
			if(target.checked === true)
			{
				this.setState({clientAssignment:1});
			}else
			{
				this.setState({clientAssignment:0});
			}
		}else if([target.name] == 'onlinecheck')
		{
			if(target.checked === true)
			{
				this.setState({onlineCheck:1, showFlock:true});
			}else
			{
				this.setState({onlineCheck:0});
			}
		}else if([target.name] == 'trackingenabled')
		{
			if(target.checked === true)
			{
				this.setState({trackingenabled:1});
			}else
			{
				this.setState({trackingenabled:0});
			}
		}
	}

	updateProfileSettings = () =>
	{
		this.setState({flockError:false});
		if(this.state.onlineCheck===1 && (this.state.flockChannel==='' || this.state.flockChannel=== null))
		{
			this.setState({flockError:true});
			return;
		}	
		this.setState({initialLoader: true});

		const data = {
			want_online_check:this.state.onlineCheck,
			on_pip: this.state.PIP,
			allow_automation: this.state.Automation,
			disable_client_task_assigning: this.state.clientAssignment,
			ticket_assignee: this.state.ticketAssignee,
			flock_channel: this.state.flockChannel,
			tracking_enabled: this.state.trackingenabled,
			user_id:this.props.match.params.id
			};

			console.log(data);
			
			const headers = {
			'Content-Type': 'application/json',
			'Access-Control-Allow-origin':'*',
			}
			axios.post(urls.updateProfileSettings, data, {
				headers: headers
			})
			.then((response) => {
				this.setState({
					initialLoader: false
				})
			})
			.catch((error) => {
				this.setState({
					initialLoader: false
				})
			})
	}


	render() {
		const variants = {
			hidden: { opacity: 0 },
			visible: { opacity: 1 },
		}
		return (
			<div className="fullwidth_mobilemenu mobileflex">
				<Container fluid className={(this.state.myPrivileges.includes('i_am_designer') ? 'top-bar-active' : 'top-bar-inactive')} >
					<Row>
						<div className="theme_header">
							<Header {...this.props}
								classname="login-portal-page"
								logout={this.signOut.bind(this)}
								onmenuaction={this.toggleSidebar.bind(this)}
								quickactionblock={true} />
						</div>
						<div className="SideNavbar">
							<SideMenu
								activeitem="admin"
								showmenu={this.state.showMenu}
								hidemenu={this.hideMenu.bind(this)} />
						</div>
						<div className="themeContentBody">
							<Row>
								<Col xs={12} sm={12} md={12} lg={8}>
									<Card className="cl_helth_report">
										<h1 class="page-title text-center her_title">My Profile</h1>
										<div className="user_information_form">
											<motion.div
												initial="hidden"
												animate="visible"
												variants={variants}>
												<Tabs>
													<TabList>
														<Tab>Basic Information</Tab>
														<Tab>Leave Profile</Tab>
														<Tab>Payment Details </Tab>
													</TabList>

													<TabPanel>
														<LoadingWrapper isLoading={this.state.initialLoader}>
															<Row>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Full Name</Label>
																		<Input type="text" name="realName" value={this.state.realName !== null ? this.state.realName : ''}
																			onChange={this.handleChange}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Display Name</Label>
																		<Input type="text" name="fullName" value={this.state.fullName !== null ? this.state.fullName : ''}
																			onChange={this.handleChange}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Employee ID</Label>
																		<Input readOnly='readonly' type="text" name="empId" value={this.state.empId !== null ? "K" + this.state.empId : ''}
																			onChange={this.handleChange}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Designation</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} className="form-control" onChange={this.handleChange} name="roleDoto">
																			<option value="">Select</option>
																			{this.state.allDotoRoles.map((role, index) => {
																				return (
																					<option value={role.id}
																						selected={this.state.roleDoto == role.id ? 'selected' : ''}
																					>{role.designation_role}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Marital Status</Label>
																		<Input type="text" name="marital" value={this.state.marital !== null ? this.state.marital : ''}
																			onChange={this.handleChange}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Last date of service</Label>
																		<Input type="text" name="lastDate" value={this.state.lastDate !== null ? this.state.lastDate : ''}
																			onChange={this.handleChange}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Gender</Label>
																		<Input type="text" name="gender" value={this.state.gender !== null ? this.state.gender : ''}
																			onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Date of Joining</Label>
																		<Input readOnly={this.state.myPrivileges.includes("am_admin") ? '' : 'readonly'} type="text" name="doj" className="dateicon" value={this.state.doj !== null ? this.state.doj : ''}
																			onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Date of Birth</Label>
																		<Input placeholder="dd-mm-yyyy" type="text" name="dob" value={this.state.dob !== null ? this.state.dob : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Official Email</Label>
																		<Input readOnly={this.state.myPrivileges.includes("am_admin") ? '' : 'readonly'} type="text" name="officialEmail" value={this.state.officialEmail !== null ? this.state.officialEmail : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Personal Email</Label>
																		<Input type="text" name="personalEmail" value={this.state.personalEmail !== null ? this.state.personalEmail : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Employed By</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="employmentType" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.employeeTypes.map((item, index) => {
																				return (
																					<option key={index} value={item.id} selected={this.state.employmentType === item.id ? 'selected' : ''} >{item.employee_type}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={12} md={12} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Notification Admin Category</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="notificationAdmin" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.allNotificationRoles.map((role, index) => {
																				return (
																					<option value={role.id}
																						selected={this.state.notificationAdmin == role.id ? 'selected' : ''}
																					>{role.user_category}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Reporting To</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="reportingTo" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.reportingOfficers.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.reportingTo == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Team</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="team" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.allDotoTeams.map((team, index) => {
																				return (
																					<option value={team.id}
																						selected={this.state.team == team.id ? 'selected' : ''}
																					>{team.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>PM Team Lead</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="pmTeamLead" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.pmTeamLeads.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.pmTeamLead == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Project Manager</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="projectManager" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.projectManagers.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.projectManager == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Team Lead</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="teamLead" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.teamLeads.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.teamLead == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Creative Operations Manager</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="comManager" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.coms.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.comManager == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Operations Manager</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="opsManager" className="form-control"
																			onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.ops.map((item, index) => {
																				return (
																					<option key={index} value={item.id}
																						selected={this.state.opsManager == item.id ? 'selected' : ''}
																					>{item.name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Is this a reporting Officer?</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="reportingOfficer" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.reportingOfficer == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.reportingOfficer == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Allow Extended Break</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="extendedBreak" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.extendedBreak == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.extendedBreak == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Allow Combining Leaves?</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="combineLeaves" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.combineLeaves == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.combineLeaves == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>
																{/* <Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Ticket Assignee?</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="ticketAssignee" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.ticketAssignee == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.ticketAssignee == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>On PIP</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="PIP" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.PIP == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.PIP == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Enable for Automation</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="Automation" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.Automation == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.Automation == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col> */}
																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>On Probation?</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="probation" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			<option value="1"
																				selected={this.state.probation == 1 ? 'selected' : ''}
																			>Yes</option>
																			<option value="0"
																				selected={this.state.probation == 0 ? 'selected' : ''}
																			>No</option>
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Work City</Label>
																		<Input readOnly={this.state.myPrivileges.includes("am_admin") ? '' : 'readonly'} type="text" name="workCity" value={this.state.workCity !== null ? this.state.workCity : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Timezone</Label>
																		<select defaultValue={this.state.timezone} disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="timezone" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.timeZones.map((item, index) => {
																				return (
																					<option key={index} value={item.zone_id} selected={this.state.timezone == item.zone_id ? 'selected' : ''}  >{item.zone_name}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>

																<Col xs={12} sm={6} md={6} lg={6}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Work Shift</Label>
																		<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="workShift" className="form-control" onChange={this.handleChange} >
																			<option value="">Select</option>
																			{this.state.allDotoShifts.map((shift, index) => {
																				return (
																					<option value={shift.id}
																						selected={this.state.workShift == shift.id ? 'selected' : ''}
																					>{this.convertTimeFormat(shift.slot_start)} - {this.convertTimeFormat(shift.slot_end)}</option>
																				)
																			})}
																		</select>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={12} md={12} lg={12}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Residential Address</Label>
																		<Input type="text" name="residentialAddress" value={this.state.residentialAddress !== null ? this.state.residentialAddress : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>City</Label>
																		<Input type="text" name="city" value={this.state.city !== null ? this.state.city : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Country</Label>
																		<Input type="text" name="country" value={this.state.country !== null ? this.state.country : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<Label>Postal Code / Pincode / Zipcode</Label>
																		<Input type="text" name="zipcode" value={this.state.zipcode !== null ? this.state.zipcode : ''} onChange={this.handleChange} />
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box phoneNo_input">
																		<Label>Phone Number</Label>
																		<PhoneInput
																			country={'us'}
																			value={this.state.phone}
																			onChange={phone => this.setState({ phone })}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box phoneNo_input">
																		<Label>Home / Alternative Phone</Label>
																		<PhoneInput
																			country={'us'}
																			value={this.state.alternatePhone}
																			onChange={alternatePhone => this.setState({ alternatePhone })}
																		/>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>Offer Letter</span>
																				{this.state.offerLetter !== null &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.offerLetter}><MaterialIcon icon="remove_red_eye" />  </a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingOffer}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="offer_leeter" id="offer_leeter" onChange={this.onChangeHandlerOffer}></Input>
																					<Label className="d-flex" htmlFor="offer_leeter" for="exampleSelect">
																						<p>{this.state.selectedOfferLetterName != '' ? this.state.selectedOfferLetterName : (this.state.offerLetter !== '' ? this.state.offerLetter : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>Signed Contract File</span>
																				{(this.state.contractFile !== null && this.state.contractFile !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.contractFile}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingContractFile}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="contrat_file" id="contrat_file" onChange={this.onChangeHandlerContract} ></Input>
																					<Label className="d-flex" htmlFor="contrat_file" for="exampleSelect">
																						<p>{this.state.selectedContractFileName != '' ? this.state.selectedContractFileName : ((this.state.contractFile && this.state.contractFile.length > 0) ? this.state.contractFile : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>National ID</span>
																				{(this.state.nationId !== null && this.state.nationId !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.nationId}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingNationalId}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="nid" id="nid" onChange={this.onChangeHandlerNationalId} ></Input>
																					<Label className="d-flex" htmlFor="nid" for="exampleSelect">
																						<p>{this.state.selectednationalIdName != '' ? this.state.selectednationalIdName : ((this.state.nationId && this.state.nationId.length > 0) ? this.state.nationId : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>PAN</span>
																				{(this.state.panId !== null && this.state.panId !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.panId}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingPanId}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="panid" id="panid" onChange={this.onChangeHandlerPanId} ></Input>
																					<Label className="d-flex" htmlFor="panid" for="exampleSelect">
																						<p>{this.state.selectedpanIdName != '' ? this.state.selectedpanIdName : ((this.state.panId && this.state.panId.length > 0) ? this.state.panId : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>PASSPORT</span>
																				{(this.state.passportId !== null && this.state.passportId !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.passportId}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingPassportId}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="passportid" id="passportid" onChange={this.onChangeHandlerPassportId} ></Input>
																					<Label className="d-flex" htmlFor="passportid" for="exampleSelect">
																						<p>{this.state.selectedpassportIdName != '' ? this.state.selectedpassportIdName : ((this.state.passportId && this.state.passportId.length > 0) ? this.state.passportId : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">
																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>DRIVING LICENSE</span>
																				{(this.state.drivingId !== null && this.state.drivingId !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.drivingId}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingDrivingId}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="drivingid" id="drivingid" onChange={this.onChangeHandlerDrivingId} ></Input>
																					<Label className="d-flex" htmlFor="drivingid" for="exampleSelect">
																						<p>{this.state.selecteddrivingIdName != '' ? this.state.selecteddrivingIdName : ((this.state.drivingId && this.state.drivingId.length > 0) ? this.state.drivingId : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={6} md={4} lg={4}>
																	<FormGroup className="equalHM inputform_box">

																		<div id="attachment" className="p-2  flex-fill equalHM fileuplod">
																			<span className="d-flex with-attachment">
																				<span>Other ID</span>
																				{(this.state.otherId !== null && this.state.otherId !== '') &&
																					(
																						<span className="uploaded-file"><a target="_blank" href={"https://Kimphub.com/service/storage/profile/" + this.props.match.params.id + "/files/" + this.state.otherId}><AttachmentIcon /></a></span>
																					)}
																			</span>
																			<LoadingWrapper isLoading={this.state.uploadingOtherId}>
																				<div className="file-wrapper">
																					<Input disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'disabled'} type="file" name="oid" id="oid" onChange={this.onChangeHandlerOtherid} ></Input>
																					<Label className="d-flex" htmlFor="oid" for="exampleSelect">
																						<p>{this.state.selectedOtheridName != '' ? this.state.selectedOtheridName : ((this.state.otherId && this.state.otherId.length > 0) ? this.state.otherId : 'Attach File')}</p>
																						<AttachmentIcon />
																					</Label>
																				</div>
																			</LoadingWrapper>
																		</div>
																	</FormGroup>
																</Col>
																<Col xs={12} sm={12} md={12} lg={12}>
																	<Button name="save-profile" className="savebtn" onClick={this.updateProfile.bind(this)}>Save</Button>
																</Col>

															</Row>
														</LoadingWrapper>
													</TabPanel> {/*TAB CONTENT ONE*/}
													<TabPanel>{/*START TAB CONTENT TWO*/}
														{this.state.myPrivileges.includes("am_admin") &&
															(<Row>
																<Col sm="12">
																	<Card>
																		<LoadingWrapper isLoading={this.state.initialLoader}>
																			<div className="profile-fields leave-profile">
																				<div className="d-flex lev_heder">
																					<div className="flex-fill equalHM pay-options text-right">
																						<select defaultValue={this.state.leaveProfileType} name="leaveProfileType" className="leavchose form-control"
																							onChange={this.handleChange} >
																							<option value="">Select</option>
																							{this.state.employeeTypes.map((item, index) => {
																								return (
																									<option key={index} value={item.id} selected={this.state.leaveProfileType === item.id ? 'selected' : ''} >{item.employee_type}</option>
																								)
																							})}
																						</select>
																					</div>
																				</div>
																				{(this.state.leaveProfileType == 1 || this.state.leaveProfileType == 3) ?
																					(
																						<div class="profile-fields topgap">
																							<Row>

																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Earned Leave</Label>
																										<Input className="p-2 " type="text" name="earnedLeave" value={this.state.earnedLeave !== null ? this.state.earnedLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>

																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Sick Leave</Label>
																										<Input className="p-2 " type="text" name="sickLeave" value={this.state.sickLeave !== null ? this.state.sickLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Casual Leave</Label>
																										<Input className="p-2 " type="text" name="casualLeave" value={this.state.casualLeave !== null ? this.state.casualLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Maternity Leave</Label>
																										<Input className="p-2 " type="text" name="maternityLeave" value={this.state.maternityLeave !== null ? this.state.maternityLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Paternity Leave</Label>
																										<Input className="p-2 " type="text" name="paternityLeave" value={this.state.paternityLeave !== null ? this.state.paternityLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Compensatory off</Label>
																										<Input className="p-2 " type="text" name="compensatoryOff" value={this.state.compensatoryOff !== null ? this.state.compensatoryOff : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Bereavement Leave</Label>
																										<Input className="p-2 " type="text" name="bereavmentLeave" value={this.state.bereavmentLeave !== null ? this.state.bereavmentLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Team Holidays</Label>
																										<Input className="p-2 " type="text" name="countryHolidays" value={this.state.countryHolidays !== null ? this.state.countryHolidays : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Team Country</Label>
																										<select name="teamCountryAssigned" defaultValue={this.state.teamCountryAssigned} className="form-control"
																											onChange={this.handleChange} >
																											<option value="">Select</option>
																											{this.state.teamCountries.map((item, index) => {
																												return (
																													<option key={index} value={item.id} selected={this.state.teamCountryAssigned == item.id ? 'selected' : ''} >{item.country_name}</option>
																												)
																											})}
																										</select>
																									</FormGroup>
																								</Col>

																								<Col xs={12} md={12}>
																									<Button name="save-leave-profile" className="savebtn" onClick={this.updateLeaveProfile.bind(this)} >Save</Button>
																								</Col>
																							</Row>

																						</div>
																					)
																					:
																					(
																						<div class="profile-fields">
																							<Row>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Personal Leave</Label>
																										<Input className="p-2 " type="text" name="generalLeave"
																											value={this.state.generalLeave !== null ? this.state.generalLeave : ''} onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Team Holidays</Label>
																										<Input className="p-2 " type="text" name="countryHolidays" value={this.state.countryHolidays !== null ? this.state.countryHolidays : ''}
																											onChange={this.handleChange} />
																									</FormGroup>

																								</Col>

																								<Col xs={12} sm={6} md={4} lg={4}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Team Country</Label>
																										<select name="teamCountryAssigned" defaultValue={this.state.teamCountryAssigned} className="form-control"
																											onChange={this.handleChange} >
																											<option value="">Select</option>
																											{this.state.teamCountries.map((item, index) => {
																												return (
																													<option key={index} value={item.id} selected={this.state.teamCountryAssigned === item.id ? 'selected' : ''} >{item.country_name}</option>
																												)
																											})}
																										</select>
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={6} lg={6}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Bereavement Leave</Label>
																										<Input className="p-2 " type="text" name="bereavmentLeave" value={this.state.bereavmentLeave !== null ? this.state.bereavmentLeave : ''}
																											onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={6} lg={6}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Compensatory off</Label>
																										<Input className="p-2 " type="text" name="compensatoryOff" value={this.state.compensatoryOff !== null ? this.state.compensatoryOff : ''}
																											onChange={this.handleChange} /></FormGroup>
																								</Col>

																								<Col xs={12} sm={6} md={6}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Maternity Leave</Label>
																										<Input className="p-2 " type="text" name="maternityLeave" value={this.state.maternityLeave !== null ? this.state.maternityLeave : ''}
																											onChange={this.handleChange} />
																									</FormGroup>
																								</Col>
																								<Col xs={12} sm={6} md={6}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Paternity Leave</Label>
																										<Input className="p-2 " type="text" name="paternityLeave" value={this.state.paternityLeave !== null ? this.state.paternityLeave : ''}
																											onChange={this.handleChange} />
																									</FormGroup>
																								</Col>

																								<Col xs={12} sm={12} md={12}>
																									<Button name="save-leave-profile" className="savebtn"
																										onClick={this.updateLeaveProfile.bind(this)} >Save</Button>
																								</Col>




																							</Row>



																						</div>
																					)
																				}
																			</div>
																		</LoadingWrapper>
																	</Card>
																</Col>
															</Row>
															)}
													</TabPanel>{/*END TAB CONTENT TWO*/}
													<TabPanel>{/*START TAB CONTENTTHREE*/}

														<Row>
															<Col sm="12">
																<Card>
																	<Row>
																		<LoadingWrapper isLoading={this.state.initialLoader}>
																			<div className="profile-fields leave-profile paymentchoose">



																				<div className="d-flex lev_heder">
																					<div className="p-2  flex-fill equalHM pay-options text-right flexbox">
																						<Input type="radio" value="bank-account" name="paymentType" className="option-input" checked={this.state.paymentType === 'bank-account' ? 'checked' : ''} onChange={this.handleChange} /> Bank Account
																						<Input type="radio" value="paypal" name="paymentType" className="option-input" checked={this.state.paymentType === 'paypal' ? 'checked' : ''} onChange={this.handleChange} /> Paypal
																					</div>
																				</div>
																				<div class="profile-fields">
																					<Row>
																						{this.state.paymentType === 'paypal' ?


																							(

																								<Col xs={12} lg={12}>
																									<FormGroup className="equalHM inputform_box">
																										<Label>Paypal Address</Label>
																										<Input className="p-2 " type="text" name="payPalAddress"
																											value={this.state.payPalAddress !== null ? this.state.payPalAddress : ''}
																											onChange={this.handleChange} />
																									</FormGroup>
																								</Col>

																							)
																							:
																							(
																								<>
																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Account Holder Name</Label>
																											<Input className="p-2 " type="text" name="accountHolder"
																												value={this.state.accountHolder !== null ? this.state.accountHolder : ''} onChange={this.handleChange} />
																										</FormGroup>
																									</Col>
																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Bank Name</Label>
																											<Input className="p-2 " type="text" name="bankName"
																												value={this.state.bankName !== null ? this.state.bankName : ''} onChange={this.handleChange} />
																										</FormGroup>
																									</Col>
																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Account Number</Label>
																											<Input className="p-2 " type="text" name="accountNumber"
																												value={this.state.accountNumber !== null ? this.state.accountNumber : ''} onChange={this.handleChange} />
																										</FormGroup>
																									</Col>



																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>IFSC Code / SWIFT Code / Bank Code</Label>
																											<Input className="p-2 " type="text" name="ifscCode"
																												value={this.state.ifscCode !== null ? this.state.ifscCode : ''}
																												onChange={this.handleChange} />
																										</FormGroup>
																									</Col>
																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Branch / Bank Code</Label>
																											<Input className="p-2 " type="text" name="bankBranch" value={this.state.bankBranch !== null ? this.state.bankBranch : ''}
																												onChange={this.handleChange} />
																										</FormGroup>
																									</Col>
																									

																									<Col xs={12} sm={6} md={6} lg={6}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Account Type</Label>
																											<div className="d-flex align-items-center banktype">

																												<div>
																													<Input type="radio" value="checking-current" className="option-input" name="accountType" checked={this.state.accountType === 'checking-current' ? 'checked' : ''} onChange={this.handleChange} /> Checking / Current
																												</div>
																												<div>
																													<Input type="radio" value="savings" className="option-input" name="accountType" checked={this.state.accountType === 'savings' ? 'checked' : ''} onChange={this.handleChange} /> Savings
																												</div>
																											</div>
																										</FormGroup>
																									</Col>

																									<Col xs={4} sm={4} lg={4}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Amount Currency</Label>
																											<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="amountCurrency" className="form-control"
																												onChange={this.handleChange} >
																												<option value="">Select</option>
																												{this.state.currencies.map((item, index) => {
																													return (
																														<option key={index} value={item.code} selected={this.state.amountCurrency === item.code ? 'selected' : ''} >{item.code}</option>
																													)
																												})}
																											</select>
																										</FormGroup>
																									</Col>
																									<Col xs={4} sm={4} lg={4}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Source Currency</Label>
																											<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="sourceCurrency" className="form-control"
																												onChange={this.handleChange} >
																												<option value="">Select</option>
																												{this.state.currencies.map((item, index) => {
																													return (
																														<option key={index} value={item.code} selected={this.state.sourceCurrency === item.code ? 'selected' : ''} >{item.code}</option>
																													)
																												})}
																											</select>
																										</FormGroup>
																									</Col>
																									<Col xs={4} sm={4} lg={4}>
																										<FormGroup className="equalHM inputform_box">
																											<Label>Target Currency</Label>
																											<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="targetCurrency" className="form-control"
																												onChange={this.handleChange} >
																												<option value="">Select</option>
																												{this.state.currencies.map((item, index) => {
																													return (
																														<option key={index} value={item.code} selected={this.state.targetCurrency === item.code ? 'selected' : ''} >{item.code}</option>
																													)
																												})}
																											</select>
																										</FormGroup>
																									</Col>

																								</>
																							)
																						}



																						<div className="p-2  flex-fill">
																							<Button name="save-leave-profile" className="savebtn"
																								onClick={this.updateBankProfile.bind(this)} >Save</Button>
																						</div>
																					</Row>
																				</div>

																			</div>
																		</LoadingWrapper>
																	</Row>
																</Card>

															</Col>
														</Row>

													</TabPanel>{/*END TAB CONTENTTHREE*/}
												</Tabs>
											</motion.div>
										</div>
									</Card>
								</Col>
								<Col xs={12} sm={12} md={12} lg={4}>

									{localStorage.getItem("superadmin") !== null &&
										(<Card className="cl_helth_report credentials_card">
											<LoadingWrapper isLoading={this.state.activationLoader}>
												<div className="profile-fields enable-disable-profile">
													<h1 class="page-title text-center her_title">KimpHub Profile Status</h1>
													<div className="add-note-block">
														{this.state.profileStatus === 1 ?
															(
																<Button name="save-revision" className="savebtn_50 savebtn btn-grey"
																	onClick={this.enableDisableAccount.bind(this, 2)} >Disable</Button>
															)
															:
															(
																<Button name="save-revision" className="savebtn_50 savebtn"
																	onClick={this.enableDisableAccount.bind(this, 1)} >Enable</Button>
															)}

													</div>
												</div>
											</LoadingWrapper>
										</Card>
									)}

									<Card className="cl_helth_report credentials_card">
										<h1 class="page-title text-center her_title">Profile Settings</h1>
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="profile-fields profile-settings">
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="ticketAssigneee" id="ticketAssigneee"
													checked={this.state.ticketAssignee === 1 && 'checked'} 
													onChange={this.ProfileSettingsChange.bind(this)}/>
													<Label for="ticketAssigneee">Allow Ticket Assigning</Label>
												</div>
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="automation" id="automation"
													checked={this.state.Automation == 1 ? 'checked' : ''} 
													onChange={this.ProfileSettingsChange.bind(this)}  />
													<Label for="automation">Enable Automation</Label>
												</div>
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="clientassignment" id="clientassignment"
													checked={this.state.clientAssignment === 1 && 'checked'} 
													onChange={this.ProfileSettingsChange.bind(this)}/>
													<Label for="clientassignment">Disable Assigning Client Task</Label>
												</div>
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="pip" id="pip" 
													checked={this.state.PIP == 1 ? 'checked' : ''}
													onChange={this.ProfileSettingsChange.bind(this)} />
													<Label for="pip">Turn On PIP</Label>
												</div>
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="trackingenabled" id="trackingenabled"
													 checked={this.state.trackingenabled == 1 ? 'checked' : ''}
													 onChange={this.ProfileSettingsChange.bind(this)}
													/>
													<Label for="onlinecheck">Enable Tracking</Label>
												</div>
												<div className='d-flex align-items-center'>
													<Input type='checkbox' name="onlinecheck" id="onlinecheck"
													 checked={this.state.onlineCheck == 1 ? 'checked' : ''}
													 onChange={this.ProfileSettingsChange.bind(this)}
													/>
													<Label for="onlinecheck">Enable Online Check</Label>
												</div>
												{this.state.showFlock &&
												(
													<div className='flock-channel'>
														<Label for="onlinecheck">Flock Channel API URL</Label>
														<Input type='text' name="flockChannel" 
														className={` ${this.state.flockError && 'error-field'}`}
														value={this.state.flockChannel}
														onChange={(e)=>this.setState({flockChannel: e.target.value})}
														placeholder="Contact Administrator for URL"
														/>
														
													</div>
												)}
												{this.state.myPrivileges.includes("am_admin") &&
													(<div className="save-button">
														<Button name="save-profile" className="savebtn_50 savebtn"
															onClick={this.updateProfileSettings.bind(this)}>Save</Button>
													</div>)
												}
											</div>
										</LoadingWrapper>

									</Card>

									<Card className="cl_helth_report credentials_card">
										<h1 class="page-title text-center her_title">Basecamp Association</h1>
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="basecamp-profile">
												<div class="profile-fields">
													<div className="d-flex ">
														<div className="flex-fill">
															<Label>Basecamp Profile</Label>
															<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="basecampAssociation" className="form-control" onChange={this.handleChange} >
																<option value="">Select</option>
																{this.state.allBaseUsers.map((user, index) => {
																	return (
																		<option value={user.basecamp_user_id}
																			selected={this.state.basecampAssociation == user.basecamp_user_id ? 'selected' : ''}
																		>{user.name + " <" + user.email_address + ">"}</option>
																	)
																})}
															</select>
														</div>
													</div>
													{this.state.myPrivileges.includes("am_admin") &&
														(<div className="save-button">
															<Button name="save-profile" className="savebtn_50 savebtn"
																onClick={this.updateBasecampProfile.bind(this)}>Save</Button>
														</div>)
													}
												</div>
											</div>
										</LoadingWrapper>
									</Card>

									<Card className="cl_helth_report credentials_card">
										<h1 class="page-title text-center her_title">Kimp360 Association</h1>
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="basecamp-profile">
												<div class="profile-fields">
													<div className="d-flex ">
														<div className="flex-fill">
															<Label>Kimp360 Profile</Label>
															<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="kimp360Association" className="form-control" onChange={this.handleChange} >
																<option value="">Select</option>
																{this.state.kimp360Profiles.map((user, index) => {
																	return (
																		<option value={user.user_id}
																			selected={this.state.kimp360Association == user.user_id ? 'selected' : ''}
																		>{user.email}</option>
																	)
																})}
															</select>
														</div>
													</div>
													{this.state.myPrivileges.includes("am_admin") &&
														(<div className="save-button">
															<Button name="save-profile" className="savebtn_50 savebtn"
																onClick={this.updateKimp360Profile.bind(this)}>Save</Button>
														</div>)
													}
												</div>
											</div>
										</LoadingWrapper>
									</Card>

									<Card className="cl_helth_report credentials_card">
										<h1 class="page-title text-center her_title">KimpHub Notification Role</h1>
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="hub-notification-role">
												<div class="profile-fields">
													<div className="d-flex ">
														<div className="flex-fill">
															<Label>Kimphub Notification Role</Label>
															<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="notificationRole" className="form-control" onChange={this.handleChange} >
																<option value="">Select</option>
																{this.state.allNotificationRoles.map((role, index) => {
																	return (
																		<option value={role.id}
																			selected={this.state.notificationRole == role.id ? 'selected' : ''}
																		>{role.user_category}</option>
																	)
																})}
															</select>
														</div>
													</div>
													{this.state.myPrivileges.includes("am_admin") &&
														(<div className="save-button">
															<Button name="save-profile" className="savebtn_50 savebtn"
																onClick={this.updateNotificationProfile.bind(this)} >Save</Button>
														</div>)
													}
												</div>
											</div>
										</LoadingWrapper>
									</Card>


									<Card className="cl_helth_report credentials_card">
										<h1 class="page-title text-center her_title">Private Notes</h1>
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="profile-fields">
												{this.state.privateNotes.map((item, index) => {
													return (
														<div className="notes-added">
															<div className="posted-by"><h3>{item.name}</h3> <span className="note-on d-flex flex-row-reverse">{item.added_on}</span></div>
															<div className="posted-note">
																<span className="note">{item.notes}</span>

															</div>
														</div>
													)
												})}
												<div className="add-note-block">
													<div className="add-note" onClick={this.showAddNotes.bind(this)}>
														Add Private Note
													</div>
													{this.state.showAddNote ?
														(
															<>
																<Row className="add-note-fields">
																	<Col sm="12">
																		<Input className="" type="text" name="privateNote" value={this.state.privateNote} onChange={this.handleChange} />
																	</Col>

																</Row>
																<Row className="add-note-fields">

																	<Col sm="12">
																		<Button name="save-revision" className="savebtn_50 savebtn"
																			onClick={this.addPrivateNote.bind(this)} >Save</Button>
																	</Col>
																</Row>
															</>
														) : null
													}

												</div>

											</div>
										</LoadingWrapper>

									</Card>

									<Card className="cl_helth_report credentials_card">
										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="profile-fields salary-revision">
												<div className="d-flex justify-content-center align-items-center">
													<h1 className="page-title text-center her_title">Salary</h1>
													{this.state.hiddenClassActive ?
														(<img className="lock-symbol ml-auto" src={Lock} onClick={this.changeLock.bind(this)} />)
														:
														(<img className="lock-symbol ml-auto" src={UnLock} onClick={this.changeLock.bind(this)} />)
													}

												</div>
												<div className={this.state.hiddenClassActive ? this.state.hiddenclass : ''}>
													<Row>
														<Col xs={12} sm={12}>
															<FormGroup className="equalHM inputform_box">

																<Label>Salary Currency</Label>
																<select disabled={this.state.myPrivileges.includes("am_admin") ? '' : 'true'} name="currency" className="form-control"
																	onChange={this.handleChange} >
																	<option value="">Select</option>
																	{this.state.currencies.map((item, index) => {
																		return (
																			<option key={index} value={item.code} selected={this.state.currency === item.code ? 'selected' : ''} >{item.code}</option>
																		)
																	})}
																</select>

															</FormGroup>
														</Col>
														<Col xs={12} sm={12}>
															<FormGroup className="equalHM inputform_box">

																<Label>Salary Per Month</Label>
																<Input readOnly={this.state.myPrivileges.includes("am_admin") ? '' : 'readonly'} type="text" name="salaryPerMonth" value={this.state.salaryPerMonth !== null ? this.state.salaryPerMonth : ''} onChange={this.handleChange} />

															</FormGroup>
														</Col>
														<Col xs={12} sm={12}>
															<FormGroup className="equalHM inputform_box">
																<Label>Salary Disbursment</Label>
																<select defaultValue={this.state.salaryDisbursement} name="salaryDisbursement" className="form-control" onChange={this.handleChange} >
																	<option value="">Select</option>
																	<option selected={this.state.salaryDisbursement === 1 ? 'selected' : ''} value='1'>1st of every month</option>
																	<option selected={this.state.salaryDisbursement === 5 ? 'selected' : ''} value='5'>5th of every month</option>
																	<option selected={this.state.salaryDisbursement === 15 ? 'selected' : ''} value='15'>1st & 15th of every month</option>

																</select>
															</FormGroup>
														</Col>
														<Col xs={12} sm={12}>
															<Button name="save-leave-profile" className="savebtn"
																onClick={this.updateCurrentSalary.bind(this)} >Save</Button>
														</Col>
													</Row>

													<div className="salary-block">
														<Row className="list-head">
															<Col sm="4">Salary</Col>
															<Col sm="4">Percentage</Col>
															<Col sm="4">Revised On</Col>
														</Row>
														{this.state.salaryRevisions && this.state.salaryRevisions.map((item, index) => {
															return (
																<Row key={index} className="list-body">
																	<Col sm="4">{item.salary}</Col>
																	<Col sm="4">{item.percentage}</Col>
																	<Col sm="4">{item.revised_on}</Col>
																</Row>
															)
														})}
													</div>
													<div className="revision-block">
														<div className="add-revision" onClick={this.showAddRevisions.bind(this)}>
															Add Revised Salary
														</div>
														{this.state.showSalaryRevision ?
															(
																<>
																	<Row className="add-revision-fields">
																		<Col sm="4">
																			<Input placeholder="amount" className="p-2 " type="text" name="revisedPack" value={this.state.revisedPack} onChange={this.handleChange} />
																		</Col>
																		<Col sm="4">
																			<Input placeholder="percentage" className="p-2 " type="text" name="revisedPercentage" value={this.state.revisedPercentage} onChange={this.handleChange} />
																		</Col>
																		<Col sm="4">
																			<Input placeholder="Date" className="p-2 " type="text" name="revisedDate" value={this.state.revisedDate} onChange={this.handleChange} />
																		</Col>
																	</Row>
																	<Row className="add-revision-fields">
																		<Col sm="12">
																			<Button name="save-revision" className="savebtn_50 savebtn"
																				onClick={this.addPackRevision.bind(this)} >Save</Button>
																		</Col>
																	</Row>
																</>
															) : null
														}
													</div>
												</div>
											</div>
										</LoadingWrapper>
									</Card>


								</Col>
							</Row>
						</div>
					</Row>
				</Container>

			</div>
		);
	}
}
