import React, { Component } from 'react';
import { Fragment } from 'react';
import ReactTags from 'react-tag-autocomplete'

export default class Qaitemslogout extends Component {     
  constructor(props){
    super(props);
    this.state = {
    showcolor:false,
    activeoption:0,
    showoption:false,
    activeItem:0 ,
    answer:'',
    tagdata:[]
    };
    this.reactTags = React.createRef();
  }

  enableYes =(id, triger, type) =>
  {
    this.setState({
      showcolor:true,
      activeoption:1,
      showoption: triger == 1 ? true : false,
      activeItem:id
    })
    if(triger == 0)
    {
      this.setState({
        answer:''
      })
      this.updateQrAnswer(type, id, 1, '', [], []);
    }
  }
  enableNo =(id, triger, type) =>
  {
    this.setState({
      showcolor:true,
      activeoption:0,
      showoption: triger == 0 ? true : false,
      activeItem:id
    })

    if(triger == 1)
    {
      this.setState({
        answer:'',
        tagdata:[]
      })
      this.updateQrAnswer(type, id, 0, '', [], []);
    }else
    {
      this.setState({
        answer:'',
        tagdata:[]
      })
    }
  }
  
  updateAnswerText = (e)=>
  {
    if(e.target.getAttribute('stype') == 5)
    {
      var cindex =  this.findMatchingIndex(this.props.logoutquestions, e.target.id);
      this.props.logoutquestions[cindex].answer_text = e.target.value;
      this.setState({answer: e.target.value});
    }else if(e.target.getAttribute('stype') == 4)
    {
      var cindex =  this.findMatchingIndex(this.props.questions2, e.target.id);
      this.props.questions2[cindex].answer_text = e.target.value;
      this.setState({answer: e.target.value});
    }
  }

  updateQrAnswer = (qstype, qid, answer_opt, answer_text, pms, dsgns)=>
  {
      this.props.saveanswer(qstype, qid, answer_opt, answer_text, pms, dsgns);
      this.setState({
       showoption:false,
       })
    }

  onDeleteDesigners = ()=>
  {

  }
  onDeletePms = ()=>
  {

  }

  onAdditionDesigners (section, k, tag, etags) {
    const tagdata = [].concat(tag, etags);
    this.setState({ tagdata })
    this.props.updatetagsdesigners(section, k, tagdata);
  }

  onAdditionPms (section, k, tag, etags) {
    const tagdata = [].concat(tag, etags);
    this.setState({ tagdata })
    this.props.updatetagspms(section, k, tagdata);
  }

  findMatchingIndex (details, qid) {
    var r;
    var c;
    for (r = 0; r < details.length; ++r) {
       const nsDetails = details[r];
       if(nsDetails.question_id == qid)
       {
         return r;
       }
    }
    return;
 }

render() {
  return (    
      <>    
          {this.props.logoutquestions.map((itm,k)=>{
          return(
            <>
            {itm.question &&
            (
              <div className="qa-card clearfix">
              <div className="das-qa">{itm.question}</div>
              <div className="button-wrap">
                <div onClick={this.enableYes.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 1 ? 'checkqa green': 'checkqa'}>Yes</div>
                <div onClick={this.enableNo.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 0 ?  'checkqa green': 'checkqa'}>No</div>
              </div>
                    
              { this.state.showoption && this.state.activeItem == itm.question_id ? 
                <div class="desc qa_addcomment">
                {itm.answer_type == 1 &&
                (
                  <>
                    <textarea onChange={this.updateAnswerText.bind(this)} stype="5" id={itm.question_id} class="textareabox" placeholder="Write your comment here….">{itm.answer_text}</textarea>
                    <button type="submit" class="add_comment_btn" onClick={this.updateQrAnswer.bind(this, 5, itm.question_id, itm.popup_required, itm.answer_text, itm.project_managers, itm.designers)}>Save</button>
                  </>
                )}
                {(itm.answer_type == 2 && itm.related_group == 1) &&
                (
                  <>
                    <div className="d-flex answers-part">
                      <ReactTags
                          ref={this.reactTags}
                          tags={itm.designers}
                          suggestions={this.props.designers}
                          onDelete={this.onDeleteDesigners.bind(this)}
                          onAddition={this.onAdditionDesigners.bind(this, 5, k, itm.designers)}
                          placeholderText="Start typing to select designers" />
                      <textarea onChange={this.updateAnswerText.bind(this)} stype="5" id={itm.question_id} class="textareabox" placeholder="Write your comment here….">{itm.answer_text}</textarea>
                    </div>
                    <button type="submit" class="add_comment_btn" onClick={this.updateQrAnswer.bind(this, 5, itm.question_id, itm.popup_required, itm.answer_text, itm.project_managers, itm.designers)}>Save</button>
                  </>
                )}
                {(itm.answer_type == 2 && (itm.related_group == 2 || itm.related_group == 3)) &&
                (
                  
                  <>
                    <div className="d-flex answers-part">
                      <ReactTags
                          ref={this.reactTags}
                          tags={itm.project_managers}
                          suggestions={this.props.managers}
                          onDelete={this.onDeletePms.bind(this)}
                          onAddition={this.onAdditionPms.bind(this, 5, k, itm.project_managers)}
                          placeholderText="Start typing to select project managers" />
                      <textarea onChange={this.updateAnswerText.bind(this)} stype="5" id={itm.question_id} class="textareabox" placeholder="Write your comment here….">{itm.answer_text}</textarea>
                    </div>
                    <button type="submit" class="add_comment_btn" onClick={this.updateQrAnswer.bind(this, 5, itm.question_id, itm.popup_required, itm.answer_text, itm.project_managers, itm.designers)}>Save</button>
                  </>
                )}

              </div>
              : null 
              }
            </div>  
            )
            }
          </>  
          )}
        )}
      </>          
    )}
}


