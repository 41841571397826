import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Header from '../../header/Header';
import {urls} from "../../../services/UrlList";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import {Container, Row, Col, Card, CardTitle, FormGroup, Label, Input, Button} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './InternaltimerStyle.css'
import FollowActivites from './followActivites' 
import UrgentMessageModal from './urgentMessage'
import MaterialIcon from "material-icons-react";
export default class internalActivities extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      isActive: false,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }
  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });  };

  componentDidMount =() =>
  {   
    document.title = "HUB - Apply & Approve Team Holiday";
    this.checkUserPrivilege(); 
    // this.renderContentDevice(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    if(!login.privileges.includes('am_admin'))
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  componentDidUpdate(prevState) {
    if (prevState.showSuccessMsg !== this.state.showSuccessMsg && this.state.showSuccessMsg === true)
    {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showSuccessMsg: false });
        window.location.reload();
      }, 6000);
    }
  }
  

  

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  holidayChange = (holiday, holidayname)=>
  {
    console.log(holiday);
    this.setState({
      selectedHoliday:holiday,
      selectedHolidayName:holidayname,
      holidayNotSet: false
    })
  }

  holidayPeople = (peoples)=>
  {
    console.log(peoples);
    this.setState({
      selectedPeoples:peoples
    })
  }


  trigerHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      console.log("ddd");
      this.setState({peopleNotSet: true});
      return false;
    }
    this.setState({
      showNotice:true
    });
  }

  cancelHolidayLeave = ()=>
  {
    this.setState({
      showNotice:false
    });
  }
  
  applyHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      this.setState({peopleNotSet: true});
      return false;
    }
   

    fetch(urls.applyLeaveHoliday,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
      .then(response => {      
          console.log(response);
          ReactDOM.findDOMNode(this).scrollIntoView();
          this.setState({showSuccessMsg:true, showNotice:false});
      })
      .catch(error => {
          console.log("error");
      });
      

  }

  render() 
  
  {
    const isActive = this.state.isActive;
    
    return (
      <>
     
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
               classname="tick-timer-header"  
               logout={this.signOut.bind(this)} 
               onmenuaction={this.toggleSidebar.bind(this)} 
               quickactionblock={true} />
        </div>
        <div className="SideNavbar">
          <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody">
        <Row>
          <Col sm={12} lg={12}>
            <Card className="switch_user_card allnotifiction">
              <CardTitle tag="h1">Activities</CardTitle>
               <Row>
                 <Col xs={12} sm={4} lg={4}>
                 <FormGroup className="activity_filterbox">
                    <Label for="exampleEmail"> Member </Label>
                    <Input
                      id="exampleEmail"
                      name="email"
                      placeholder="Search Member..."
                      type="email"
                    />
                  </FormGroup>
                 </Col>
                 <Col xs={12} sm={4} lg={4}>
                 <FormGroup className="activity_filterbox">
                  <Label for="exampleSelect">
                     Project Lead
                  </Label>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                  >
                    <option>
                        Select Project Lead
                      </option>
                      <option>
                        2
                      </option>
                      <option>
                        3
                      </option>
                      <option>
                        4
                      </option>
                      <option>
                        5
                      </option>
                    </Input>
                  </FormGroup>
                 </Col>
                 <Col xs={12} sm={4} lg={4}>
                 <FormGroup className="activity_filterbox">
                  <Label for="exampleSelect">
                   Project Manager
                  </Label>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                  >
                    <option>
                        Select Project Manager
                      </option>
                      <option>
                        2
                      </option>
                      <option>
                        3
                      </option>
                      <option>
                        4
                      </option>
                      <option>
                        5
                      </option>
                    </Input>
                  </FormGroup>
                 </Col>
               </Row>
               <div className="team_member_list">
                 <Row>
                   <Col xs={12} md={5}>
                     <div className="team_member_list_card">
                       <span className="user_pic"></span>
                       <h2>Team Member's Name</h2>
                     </div>
                   </Col>
                  <Col xs={12} md={7}>
                      <div className="button_grp_activitys">
                        <FollowActivites/>
                        <Button onClick={this.handleToggle }>See Previous Activities</Button>
                        <UrgentMessageModal/>
                        
                      </div>
                  </Col>
                 </Row>

                 <div className={isActive ? "active_all_activity_poper" : null}>
                 <div className="all_activity_poper">
                    <h3>Current Activity <Button className="back_arow_icon"><MaterialIcon icon="east"/> </Button></h3>
                    <div className="current_activity_list">
                      <Row>
                        <Col xs={12} md={7}>
                          <div className="current_activity_list_content">
                            <h3>Anto M is on call with the client <span className="client_name_bold">Benslater</span>, Sansa requested Anto's  help to have the call.</h3>
                            <span className="date_n_time_calc">05:30 pm, 28 March 2022</span>
                          </div>
                        </Col>
                        <Col xs={12} md={5}>
                        <div className="button_grp_activitys">
                          <FollowActivites/>
                          <Button onClick={this.handleToggle }>See Previous Activities</Button>
                          <UrgentMessageModal/>
                        </div>
                        </Col>
                      </Row>

                    </div>
                    <div className="all_old_activities">
                      <h4>Previous Tasks of Anto M</h4>
                      <Row>
                        <Col xs={12} md={7}>
                          <div className="current_activity_list_content">
                            <h3>Anto M is on call with the client <span className="client_name_bold">Benslater</span>, Sansa requested Anto's  help to have the call.</h3>
                            <span className="date_n_time_calc">05:30 pm, 28 March 2022</span>
                          </div>
                        </Col>
                        <Col xs={12} md={5}></Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={7}>
                          <div className="current_activity_list_content">
                            <h3>Anto M is on call with the client <span className="client_name_bold">Benslater</span>, Sansa requested Anto's  help to have the call.</h3>
                            <span className="date_n_time_calc">05:30 pm, 28 March 2022</span>
                          </div>
                        </Col>
                        <Col xs={12} md={5}></Col>
                      </Row>
                    </div>
                 </div>

             

               </div>

               </div>
               
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
</Container>
      </>
    )
  }
}

