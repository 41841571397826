import React, { Component } from "react";
import { Input, Button, Container, Row, Col } from "reactstrap";
import PageSubTitle from "../../page-title/PageSubTitle";
import "./Logamend.css";
import LoadingBar from "../../common/LoadingBar";
import ConfirmAction from "../../admincomment/ConfirmAction";
import CloseIcon from "../../svgicon/CloseIcon";

export default class LogAmend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tassignees: this.props.tassignees,
      newassignees: ["" + this.props.myid + ""],
      newassignees: this.props.taskassigneearray,
      myid: this.props.myid,
      activeme: true,
      completionStatus: false,
      showCompletionDialogue: false,
      myPrivileges: [],
      showCompletion: false,
      hideshowbox:true,
    };
  }

  componentDidMount() {
    this.checkUserPrivilege();

    // let members = this.state.newassignees;
    // this.props.taskassigneearray.forEach(function(item){
    //   members.push(""+item+"");
    // });
    // this.setState({newassignees:members});
  }
  //   componentDidMount () {
  //     let members = [...this.state.newassignees];
  //     members.push(this.state.myid);
  //     this.setState({newassignees: members});
  // }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({ myPrivileges: login.privileges });
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
  };

  handleClick = e => {
    console.log("myids" + this.state.newassignees);
    let members = this.state.newassignees;
    this.setState({ myid: "" });
    var ret = this.state.tassignees.replace(e.target.value, "");
    this.setState({ tassignees: ret });
    if (e.target.checked) {
      members.push(e.target.value);
      this.setState({
        tassignees: this.state.tassignees + "," + e.target.value
      });
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      this.setState({ newassignees: array });
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handleunassignme = e => {
    this.setState({ activeme: !this.state.activeme });
    let members = this.state.newassignees;
    if (e.target.checked) {
      members.push(e.target.value);
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ newassignees: array });
      }
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handlecompletion = () => {
    this.setState({ showCompletionDialogue: true, hideshowbox:false });
  };

  handleAssigneeUpdate = () => {
    this.props.onassigneupdate(
      this.state.newassignees,
      this.state.completionStatus
    );
  };

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false,
      hideshowbox:true
    });
  };

  handleCompletionConfirm = () => {
    this.setState({ completionStatus: !this.state.completionStatus });
    // this.setState({completionStatus: false});
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.handleAssigneeUpdate();
    }, 1000);
  };

  closePopup = () => {
    this.props.closeaction();
  };

  render() {
    return (
      <Container className="logamend-modal">
        
        <div className="overlay-log" onClick={this.closePopup}></div>
        {this.state.hideshowbox && 
        (
          <div className="logamend">
          <CloseIcon closeaction={this.closePopup} />
          {this.props.loading === true ? (
            <LoadingBar />
          ) : (
            <Row>
              <Col className="has-border-right">
                <div className="change-assignee">
                  <PageSubTitle title="Change Status" />
                  <Container>
                    <Row>
                      <Col>
                      <span className="member-not-found text-center ">
                      Can't see the Team Member you wish to reassign to? Please navigate to "Task" in the menu and select "Add New Task" to add your task once more. This will refresh the list of Team Members.
                      </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <div className="d-flex">
                          <Input
                            type="checkbox"
                            value={this.props.myid}
                            checked={this.state.activeme ? "checked" : null}
                            onChange={this.handleunassignme.bind(this)}
                          />
                          <span className="assigni-name">
                            {this.props.myname}
                          </span>
                        </div>
                        {this.props.passignees &&
                        this.props.passignees.length > 0 &&
                        this.state.tassignees !== "" ? (
                          <div className="all-project-users">
                            <Row>
                            {this.props.passignees.map((user, index) => {
                              return (
                                <Col sm="4">
                                <div className="d-flex" key={index}>
                                  <Input
                                    type="checkbox"
                                    value={user.basecamp_user_id}
                                    checked={
                                      this.state.tassignees.includes(
                                        user.basecamp_user_id
                                      )
                                        ? "checked"
                                        : null
                                    }
                                    onChange={this.handleClick.bind(this)}
                                  />
                                  <span className="assigni-name">
                                    {user.name}
                                  </span>
                                </div>
                                </Col>
                              );
                            })}
                            </Row>
                          </div>
                        ) : null}
                      </Col>
                      <Col sm="12">
                        {/* Mark Complete
                          <div>
                            <Input type="checkbox"  onChange={this.handlecompletion.bind(this)} /></div> */}
                        <Button
                          className="project-assignee-submit btn-green"
                          onClick={this.handleAssigneeUpdate.bind(this)}
                        >
                          Reassign
                        </Button>

                        {this.state.showCompletion ? (
                          <Button
                            className="project-assignee-submit btn-green"
                            onClick={this.handlecompletion.bind(this)}
                          >
                            Mark as Completed
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          )}
        </div>
        )
        }
        {this.state.showCompletionDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showCompletionDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to change the status of this task to completed. This will mark the task status in basecamp as completed."
            handleCompletionConfirm={this.handleCompletionConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
      </Container>
    );
  }
}
