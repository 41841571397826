import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import WeeklyQuotaCard from "./WeeklyQuotaCard/WeeklyQuotaCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";
import {urls} from "../../../services/UrlList";

export default class WeeklyQuota extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',        
      isMobileView:0,
      weeklyQuota:[],
      requestPending:0,
      progressBar:true,
    };
  }

  componentDidMount =() =>
  {    
    this.checkUserPrivilege();
    this.getWeeklyQuotaNotifications();
    const _this = this;
    setInterval(() => _this.getWeeklyQuotaNotifications(),5000); 
  };

  componentWillMount = () =>
  {
    this.getWeeklyQuotaNotifications();
  }

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges, myId:login.uid});
    if(!login.privileges.includes('approve_user_requests'))
    {
      this.props.history.push("/tick-report");
    }
   
  };

  async getWeeklyQuotaNotifications()
  {    
    fetch(urls.getAdminWQuotaNotifications,{
        headers:
        {
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
        }
    })
    .then(response => response.json())
    .then(response =>
    {                 
        this.setState({weeklyQuota: response[0].weekly_quota,progressBar:false});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(response[0].weekly_quota);
          _this.props.onweeklyquota(_this.state.requestPending);
        }, 100); 
    })
    .catch(error =>
    {
        this.setState({progressBar:false});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(_this.state.weeklyQuota);
          _this.props.onweeklyquota(_this.state.requestPending);
        }, 100); 
    })
  };

  countPendingRequest = (response) =>
  {
    let temp = response;
    var count = 0;
    temp.forEach(p1 =>
      {
        if(p1.notification_admin_resolve_status !== 1)
        {
          count++;
        }
      })
    this.setState({requestPending:count});
  }
 
  resolveApprovalAction = (id) =>
  {
    this.setState({progressBar:true});
    let opts ={
        notification_id:id,
        approver_id:this.state.myId
    };
    fetch(urls.resolveNotification,{
       headers:{
           'Access-Control-Allow-origin':'*',
           'Content-Type':'application/json'
       },
       method:'post',
       body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {
        this.getWeeklyQuotaNotifications();
        this.setState({progressBar:false});
    })
    .catch(error =>
    {
        this.setState({progressBar:false}); 
    })    
  };

  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 681,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Fragment>
        <ApprovalModules classname="appr-pending" id="weekly-quota" title="Weekly Over Quota">
          <Slider {...settings}>
          {
          this.state.weeklyQuota.map((item, index)=>
          {return(
              <WeeklyQuotaCard approved={item.notification_admin_resolve_status === 1? "true": ''} key={index} weeklyquota={item} onresolveaction={this.resolveApprovalAction} />
            )
          })}            
          </Slider>
        </ApprovalModules>
      </Fragment>
    );
  }
}
