import React, { Component, Fragment } from "react";
import Header from "../header/Header";
import "./list-head.css";
import "./listcard.css";
import "./LogTimer.css";
import { urls } from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Input,
  Button,
  CardTitle,
  Table
} from "reactstrap";
import BasecampIcon from "../svgicon/BasecampIcon";
import TrelloIcon from "../svgicon/TrelloIcon";
import { isMobile } from "react-device-detect";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { motion } from "framer-motion";
import LogFilter from "../filter/LogFilter";

export default class LateReport extends Component {
  constructor(props) {
    super(props);
    var s = new Date();
    s.setDate(s.getDate());
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("DD-MM-YYYY");
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      showProgress: false,
      showProgressSearch: false,
      orginalEntries: [],
      fullEntries: [],
      selectedUser: "",
      selectedProject: "",
      show_detail: false,
      show_detail_id: 0,
      startDate: null,
      endDate: null,
      running_status: 0,
      showMenu: true,
      projectView: false,
      memberView: true,
      filterHour: "",
      detailEntries: [],
      DetailView: true,
      showDetailProgress: false,
      project_id: "",
      showTaskPeopleId: "",
      showTaskPeople: "",
      pmTeamLeads:[]
    };
  }

  componentDidMount = () => {
    document.title = "HUB - Late Coming Logs";
    this.checkUserPrivilege();
    this.renderContentDevice();
    this.getPMTeamLeads();
    const _this = this;
    /*setTimeout(() => {
      _this.getlogTimerReport(); 
    }, 100);*/
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then((response) => response.json())
      .then((response) => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then((response) => response.json())
          .then((response) => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch((error) => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myBaseId: login.basecampid,
      });

      if(!login.privileges.includes('i_am_pl') && !login.privileges.includes('i_am_com') && !login.privileges.includes('i_am_hr') 
      && !login.privileges.includes('i_am_admin') && !login.privileges.includes('i_am_hradmin') && !login.privileges.includes('i_am_operations') )
      {
        this.props.history.push("/home");
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes("mobile_access")) {
      this.setState({ isMobileView: 1 });
    }
    if (isMobile) {
      this.setState({ showMenu: false });
    }
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  showDetailEntry = (id) => {
    this.setState({
      show_detail: true,
      show_detail_date: 0,
      show_detail_id: this.state.show_detail_id === id ? 0 : id,
    });
  };

  searchEntriestwo = ( user, creator, start, end, team, pl) => {
    this.setState({
      orginalEntries: [],
      showProgress: true,
      selectedUser: user,
      startDate: start != "" ? start.format("YYYY-MM-DD") : "",
      endDate: end != "" ? end.format("YYYY-MM-DD") : "",
    });

      let opts = {
        member: user,
        start_date: start != "" ? start.format("YYYY-MM-DD") : "",
        end_date: end != "" ? end.format("YYYY-MM-DD") : "",
        pl_id:pl,
        team_id: team,
      };
      fetch(urls.lateComingLog, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            orginalEntries: response["results"],
            fullEntries: response["results"],
            showProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
  };

  filterbyhour = (value) => {
    this.setState({
      filterHour: value,
    });
    this.filterByHours(value);
  };

  filterByHours = (value) => {
    this.setState({ filterHour: value });
    if (value != "") {
      setTimeout(() => {
        let temp = [];
        temp = this.state.fullEntries;
        let list = [];
        temp.forEach((p1) => {
          if (p1.total_hr > parseInt(this.state.filterHour)) {
            list.push(p1);
          }
        });
        this.setState({ orginalEntries: list });
      }, 100);
    } else {
      this.setState({ orginalEntries: this.state.fullEntries });
    }
  };

  timeConvert = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
  };

  replaceText = (str) =>
  {
    console.log('test');
    let result='';
   
    result = str.replace("You are ", "");
   
    return result ;  
  }


  getPMTeamLeads = ()=>
	{
		fetch(urls.getPMTeamLeads,
			{
				headers:{
					'Access-Control-Allow-origin':'*',
					'Content-Type':'application/json'
				}
			})
			.then(response => response.json())
			.then(response =>{
				this.setState({
					pmTeamLeads: response[0].pmtls,
				})
			})
			.catch((error) =>
			{
				console.log(error);
			})
	}


  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        },
      },
      hidden: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        },
      },
    };

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    };

    let deviceWidth = window.innerWidth;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
        <Container
          fluid
          className={
            (this.state.myPrivileges.includes("i_am_designer")
              ? "top-bar-active"
              : "top-bar-inactive") + " project-report"
          }
        >
          <Row>
            <div className="theme_header">
              <Header
                {...this.props}
                classname="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={true}
              />
            </div>
            <div className="SideNavbar">
              <SideMenu
                activeitem="report"
                showmenu={this.state.showMenu}
                hidemenu={this.hideMenu.bind(this)}
              />
            </div>
            <div className="themeContentBody late-coming-wrapper">
              <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">
                Late Coming Logs
                </CardTitle>
                <div className="filter_header_wrap">
                  <LogFilter
                    pmTeamLeads = {this.state.pmTeamLeads}
                    filterbytask={this.filterbytask}
                    filterbyhour={this.filterbyhour}
                    searchEntriesone={this.searchEntriestwo}
                    showteamfilter={true}
                    usertype={'id'}
                    userslist={2}
                    userlabel1={'Member'}
                    userlabel2={''}
                  />
                </div>
                 <>
                    <Table responsive className="theme_table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>PL</th>
                          <th>PM</th>
                          <th>No.of Days</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                         {(this.state.orginalEntries && this.state.orginalEntries.length>0) ? (
                            <>
                                {this.state.orginalEntries.map((entries, index) => {
                                    return ( 
                                    <>
                                    <tr onClick={this.showDetailEntry.bind(this,entries.user_id)}
                                            className={(this.state.show_detail && this.state.show_detail_id === entries.user_id) && 'active-item-row'}
                                            >
                                        <td>{entries.name}</td>
                                        <td>{entries.pl}</td>
                                        <td>{entries.pm}</td>
                                        <td>{entries.items?.length}</td>
                                        </tr>
                                        
                                        {(this.state.show_detail && this.state.show_detail_id === entries.user_id ) && (
                                        <>
                                        <LoadingWrapper
                                            LoaderCount={5}
                                            isLoading={
                                            this.state
                                                .showDetailProgress
                                            }
                                        ></LoadingWrapper>

                                        <tr class="sub-level">
                                            {(entries.items.length >  0 && this.state.show_detail_id === entries.user_id) && (
                                                <td colspan="12" className="p0">
                                                    <table className="w-100">
                                                        <thead>
                                                            <tr>
                                                            <th>Shift Day</th>
                                                            <th>Regular Start</th>
                                                            <th>Started At</th>
                                                            <th>Late Time</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {entries.items.map((entry, kindex)=>
                                                        {
                                                            return(
                                                                <tr>
                                                                    <td className="name">{entry.sdate}
                                                                    </td>
                                                                    <td className="name">
                                                                        {entry.regular}
                                                                    </td>
                                                                    <td className="name">
                                                                        {entry.time_converted}
                                                                    </td>
                                                                    <td className="late-text">
                                                                        {this.replaceText(entry.comment)}
                                                                    </td>
                                                                </tr>  
                                                            )
                                                        })}         
                                                        </tbody>
                                                    </table>
                                            </td>
                                            )}
                                        </tr>
                                        </>
                                        )}    
                                    </>
                                    )} 
                                )} 
                                </>         
                        ) :
                        (
                          <>
                          {this.state.selectedUser!='' || this.state.startDate!=null || this.state.endDate!=null ?
                          (
                              <tr><td colspan="4"><div className="item-msg-center-text">{'No result found.'}</div></td></tr>
                          ):
                          (
                              <tr><td colspan="4"><div className="item-msg-center-text">{'Please choose options and search to see results.'}</div></td></tr>
                          )}
                          </>
                      )}
                     </tbody>
                </Table>
                </>
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
