import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, Table} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'

export default class ClosedTrelloBoards extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      closedBoards:[],
      initialLoader:true,
      unlinkingId:'',
      unlinkingLoader:false,
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Trello Active Boards";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {     
      _this.getClosedTrelloBoards();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('closed_trello_board_access'))
    {
      this.props.history.push("/home");
    }

  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getClosedTrelloBoards = () =>{        
    this.setState({initialLoader:true});
    fetch(urls.getClosedTrelloBoards,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {     
        var sortResult= this.sortBoards('asc',response);
        this.setState({
            closedBoards:sortResult,
            initialLoader:false  
        });            
    })
  };

  removeClosedBoard = (id) =>{  
    this.setState({unlinkingId:id, unlinkingLoader:true});      
    fetch(urls.unlinkClosedBoard+id,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {     
        var sresult = this.removeUnlinked(id);  
        this.setState({
          closedBoards:sresult,
          unlinkingId:'',
          unlinkingLoader:false
        })         
    })
    .catch(error=>
      {
        this.setState({
          unlinkingId:'',
          unlinkingLoader:false
        }) 
      })
  };

  removeUnlinked = (id) =>
  {
    let temp = this.state.closedBoards;
    let list=[];
    temp.forEach(p1=>
      {   
       if(p1.board_id !=id) 
       {
         list.push(p1);
       }
      })  
    return list;
  }

  sortBoards(order, response) {
		let temp = response;
		  if (order === "asc") {
			temp.sort(ascOrder);
			return temp;
		  } else if (order === "desc") {
      temp.sort(descOrder);
      return temp;
	  }
		function ascOrder(a, b) {
            console.log(new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime());
			
            return new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.last_activity).getTime() - new Date(a.last_activity).getTime();
		}
	}

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"}> 
       <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header" 
         logout={this.signOut.bind(this)} 
         onmenuaction={this.toggleSidebar.bind(this)}
         quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report">
              <h1 class="page-title text-center her_title">Closed Trello Boards</h1>
              <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
 <LoadingWrapper isLoading={this.state.initialLoader}>
              <Table responsive className="theme_table">
             
                <thead>
                  <tr>
                    <th>Board Name</th>
                    <th>Last Activity Date</th>
                    <th>Unlink Action</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.closedBoards.map((item, key)=>
                        {
                            return (
                  <tr>
                    {this.state.unlinkingId=== item.board_id ?
                                    (
                      <Col>
                        <LoadingWrapper isLoading={this.state.unlinkingLoader}></LoadingWrapper>
                       </Col>
                    ):
                    (
                    <>
                    <td className="unlink_taskname"><a href={"https://trello.com/b/"+item.board_id+"/"+item.board_name} target="_blank">{item.board_name}</a></td>
                    <td>{item.last_activity_visible}</td>
                    <td className="text-right"><span className="unlink-board" onClick={this.removeClosedBoard.bind(this, item.board_id)}>Unlink Board</span></td>
                    </>
                   )}
                  </tr>
                     )
                    }
                    )}
                </tbody>
              
              </Table>
              </LoadingWrapper>
            </motion.div>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
</Container>
</div>
    )
  }
}
