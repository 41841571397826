import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import AvailabilityFilter from "../filter/AvailabilityFilter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, Table } from 'reactstrap';
import BasecampIcon from '../svgicon/BasecampIcon';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import ReactTooltip from "react-tooltip";

export default class OnlineAvailabilityReport extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");
    this.state = {         
      myPrivileges:[], 
      myId:'',
      orginalEntries:[],
      fullEntries:[],
      initialLodader:false,
      selectedUser:'',
      min2Set:true,
      mobileSet:false,
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Online Availability Report";
    this.checkUserPrivilege(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onUserNameChange = event => {
    this.setState({
      userName: event.target.value
    });
  };

searchReport = (start_date, end_date, user_id) =>
{
  let opts = {
    start_date:start_date!=''? start_date.format("DD-MM-YYYY"):'',
    end_date:end_date !=''? end_date.format("DD-MM-YYYY"):'',
    user_id: user_id
  }; 
  this.setState({
    initialLodader:true,
    selectedUser: user_id >0? user_id :''
  })
  fetch(urls.reportOnlineCheck,{
    headers:{
      'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json'
    },
    method:'post',
    body:JSON.stringify(opts)
  })
  .then(response => response.json())
  .then(response =>
  {
    console.log(response.report);
    this.setState({
      orginalEntries:response.report,
      fullEntries:response.report,
      initialLodader:false
     });
     this.filterList(this.state.min2Set, this.state.mobileSet);
  })
  .catch(error =>{
    this.setState({initialLodader:false});
  })
};

filterList = (mins, mobile) =>
{
  this.setState({
    min2Set:mins,
    mobileSet:mobile
  });
  let temp = this.state.fullEntries;
  let list=[];
  console.log("hello");
  if(mins && mobile)
  {
    console.log("3");
    temp.forEach(p1 => {
      if((mins && p1.difference_minutes>=2) && (p1.user_device != null && p1.user_device.includes('Mobile')) )
      {
        list.push(p1);
      }
    }); 
    this.setState({orginalEntries:list});
  }else if(mins && !mobile)
  {
    console.log("1");
    temp.forEach(p1 => {
      if((mins && p1.difference_minutes>=2))
      {
        list.push(p1);
      }
    }); 
    this.setState({orginalEntries:list});
  }else if(!mins && mobile)
  {
    console.log("2");
    temp.forEach(p1 => {
      if(p1.user_device != null && p1.user_device.includes('Mobile'))
      {
        list.push(p1);
      }
    }); 
    this.setState({orginalEntries:list});
  }else
  {
    console.log("0");
    this.setState({orginalEntries:this.state.fullEntries});
  }
}


  render() {

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }
    console.log(this.state.orginalEntries);
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " availability-records"} > 
       <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Online Availability Report</h1>
                <AvailabilityFilter searchEntries={this.searchReport} filterlist={this.filterList} min2={this.state.min2Set} mobile={this.state.mobileSet} />
                <div id="list-head" className="daily-log">
 
        <LoadingWrapper isLoading={this.state.initialLodader}>
        {this.state.orginalEntries.length>0 &&
        (
            <>

          <Table responsive className="theme_table">
            <thead>
              <tr>
                {this.state.selectedUser === '' &&
                        (
                <th>Username</th>
                )}
                <th>Shift Date</th>
                <th>Checked</th>
                <th>Responded</th>
                <th>Difference</th>
                <th>User IP</th>
                <th>User Device</th>
              </tr>
              </thead>
              <tbody>
              {this.state.orginalEntries.map((item, index)=>
                {
                    return(
                <tr key={index}>
                  
                  {this.state.selectedUser === '' &&
                                (<td>{item.name.split(" ")[0]}</td> )}

                  <td>{item.shift_date}</td>
                  <td>{item.check_triger_time}</td>
                  <td>{item.check_resolve_time}</td>
                  <td className={item.difference_minutes >=2 && "time-exceeded"}>{item.tdifference}</td>
                  <td>{item.user_ip}</td>
                  <td className={(item.user_device != null && item.user_device.includes('Mobile'))? "devcie-detail mobile text-right": "device-detail text-right"} 
                                data-tip={index}
                                data-for={"devivecomments" + index}>
                                  {item.user_device}
                                <ReactTooltip
                                  id={"devivecomments" + index}
                                  type="warning"
                                  data-effect="solid"
                                  data-multiline="true"
                                >
                                  <span>{item.device_detail}</span>
                                </ReactTooltip>
                                </td>
                </tr>
                   )
                  })}
              </tbody>
            
          </Table>
               




            </>
        )}
        </LoadingWrapper>
     
      </div>
              </Card>
            </Col>
        </Row>
        </div>
      </Row>


</Container>
</div>
    )
  }
}
