import React, {useState, useEffect} from "react";
import "./InternaltimerStyle.css";

export default function FollowActivites() {
    const [toggleOn, setToggleOn] = useState(false);
    const style = {
      on: {
        background: "#fff",
        color: "#495057"
      },
      off: {
        background: "#fff",
        color: "#495057"
      }
    };
    return (
      <button
        onClick={() => setToggleOn(!toggleOn)}
        style={toggleOn ? style.on : style.of}
      >
        {toggleOn ? "Follow Activities" : "Unfollow Activities"}
      </button>
    );
}
