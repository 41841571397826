import React, { Component, Fragment } from "react";
import ApprovalAvatarHead from "../../ApprovalAvatarHead/ApprovalAvatarHead";
import ApprovalTimeRow from "../../ApprovalTimeRow/ApprovalTimeRow";
import { Button } from "reactstrap";
import ApprovedMsg from "../../ApprovedMsg/ApprovedMsg";

export default class ShiftChange extends Component {
  render() {
    let classname = ["caro-box", this.props.approved && "appr-pending"]
      .join()
      .replace(",", " ");
    return (
      <div className={classname}>
        <ApprovalAvatarHead />
        <ApprovalTimeRow />
        {this.props.approved ? (
          <ApprovedMsg />
        ) : (
          <Fragment>
            <ApprovalTimeRow />
            <Button className="appr-btn-approve btn-green txt-upper">
              Approval
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}
