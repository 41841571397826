import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
//import {createStore} from 'redux';
import allReducers from './components/redux/reducers';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from "redux";
import {createStateSyncMiddleware, initMessageListener} from "redux-state-sync"; 

const reduxStateSyncConfig = {};

const state = {};

const reducer = (state, action) => ({...state, ...action});

const myStore = createStore(
    allReducers,
    state,
    applyMiddleware(createStateSyncMiddleware(reduxStateSyncConfig))
);
 
initMessageListener(myStore);


//const myStore = createStore(allReducers);
ReactDOM.render(
        <Provider store={myStore}>
        <App />
        </Provider>
        , document.getElementById('root')
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();