import NotificationCountReducer from "./NotificationCount";
import NewNotificationMessageReducer from "./NotificationMessage";
import NotificationMessagesReducer from "./NotificationMessages";
import ReadNotificationMessagesReducer from "./ReadNotificationMessages";
import TaskRunnerReducer from "./TaskRunnerReducer";

import ProfileImageReducer from "./ProfileImage";
import {combineReducers} from 'redux';

const allReducers  = combineReducers({
    NotificationCountReducer,
    NotificationMessagesReducer,
    NewNotificationMessageReducer,
    ReadNotificationMessagesReducer,
    TaskRunnerReducer,
    ProfileImageReducer
});

export default allReducers;