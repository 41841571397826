import React, { Component, Fragment } from "react";
import "./LeaveBalance.css";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Input, Button, Card, CardTitle, Table} from "reactstrap";
import LeaveHeader from "../LeaveHeader/LeaveHeader";
import LeaveBalenceCard from "./LeaveBalenceCard/LeaveBalenceCard";
import UpcomingCompanyHoliday from "./UpcomingCompanyHoliday/UpcomingCompanyHoliday";
import DownArrow from "./DownArrow/DownArrow";
import {urls} from "../../../services/UrlList";
import LoadingBar from "../../common/LoadingBar";
import { isMobile } from "react-device-detect";
import LoadingWrapper from '../../LoadingBox/LoadingWrapper/LoadingWrapper'
import LeaveCalenderDetails from '../LeaveCalender/LeaveCalender'
export default class LeaveApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leaves:[],
      generalAloc:0,
      earnedLeave:0,
      sickLeave:0,
      casualLeave:0,
      wfh:0,
      companyHoliday:0,
      compensatoryOff:0,
      maternityLeave:0,
      paternityLeave:0,
      generalLeave:0,
      employeeType:'',
      holidays:[],
      showFullList:false,
      leaveLoader: true,
      holidayLoader: true,
      myPrivileges: [],
      holidayBalance:0,
      showMenu:true,
      myId: "",
      allLeave: {
        leave: {
          title: "Casual Leave",
          leave: "04"
        }
      },
      showMenu:true,
      checkDate: new Date().getFullYear()+"-02-15",
    };
  }

  componentDidMount =() =>
  {
    document.title = "KIMPHUB - Employee Leaves";
    this.checkUserPrivilege(); 
    this.renderContentDevice();   
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.getMyLeaves();
      _this.getUpcomingHolidays();
    }, 1000);
    
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid, 
        employeeType: login.employee_type,
      });
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };
  
  getMyLeaves = () => {
    // this.setState({
    //   progressBar:true,
    // })
    fetch(urls.getLeaveBalances+ this.state.myId, {
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response => {   
      console.log(response);
      this.setState({
       generalLeaveCr:response['leaves'][0].carry_fwd_general_leave,
       earnedLeaveCr:response['leaves'][0].carry_fwd_earned_leave,
       takenEarned:response['leaves'][0].taken_earned_leave,
       earnedLeave:response['leaves'][0].earned_leave,
       alocEarned: response['leaves'][0].aloc_earned_leave,
       sickLeave:response['leaves'][0].sick_leave,
       alocSick:response['leaves'][0].aloc_sick_leave,
       takenSick:response['leaves'][0].taken_sick_leave,
       casualLeave:response['leaves'][0].casual_leave,
       alocCasual:response['leaves'][0].aloc_casual_leave,
       takenCasual:response['leaves'][0].taken_casual_leave,
       wfh:response['leaves'][0].work_from_home,
       bereavement: response['leaves'][0].bereavement_leave,
       alocBereavement:response['leaves'][0].aloc_bereavement_leave,
       takenBereavement:response['leaves'][0].taken_bereavement_leave,
       companyHoliday: response['leaves'][0].aloc_company_holiday == 10? '5' : response['leaves'][0].company_holiday,
       alocHolidays: response['leaves'][0].aloc_company_holiday,
       takenCompany:response['leaves'][0].taken_company_holiday,
       compensatoryOff:response['leaves'][0].compensatory_off,
       alocCompoff:response['leaves'][0].aloc_compensatory_off,
       takenCompoff:response['leaves'][0].taken_compensatory_off,
       maternityLeave:response['leaves'][0].maternity_leave,
       alocMaternity:response['leaves'][0].aloc_maternity_leave,
       takenMaternity:response['leaves'][0].taken_maternity_leave,
       paternityLeave:response['leaves'][0].paternity_leave,
       alocPaternity:response['leaves'][0].aloc_paternity_leave,
       takenPaternity:response['leaves'][0].taken_paternity_leave,
       generalLeave:response['leaves'][0].general_leave,
       generalAloc:response['leaves'][0].general_aloc,
       takenGeneral:response['leaves'][0].taken_general_leave,
       companyAloc:response['leaves'][0].company_aloc,
       doj:response['leaves'][0].date_of_joining,
       leaveProfileType: response['leaves'][0].leave_profile_type,
       holidayCountry: response['leaves'][0].country_name,
       leaveLoader:false,
      })
    })
    .catch( err => console.log("Leave Error ", err ) )
  };

  getUpcomingHolidays = () => {
    fetch(urls.getMyUpcomingHolidays+this.state.myId, {
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response => {
      this.setState({
       holidays:response,
       holidayLoader: false,
       holidayBalance:response.length
      })
    })
    .catch( err => console.log("Holiday Error ", err) )
  };

  toggleList = () => {
    this.setState({
      showFullList: !this.state.showFullList
    });
  };

  render() {
    let { leave } = this.state.allLeave.leave;
    console.log(this.state.employeeType);
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
          logout={this.signOut.bind(this)} 
          onmenuaction={this.toggleSidebar.bind(this)}
          quickactionblock={true} />  
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="leave" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody themefullwidthContentBoday">
        <Row>
          <Col xs={12} sm={12} md={12} lg={8} className="mb-4">
            <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title"> Leave</CardTitle>
                <LeaveHeader active="balence" /> 
                <section id="leave_balence">                  
                    {this.state.leaves?
                      ( 
                        <>                       
                        {this.state.leaveProfileType == 2 ?
                          (
                            <>
                              {/* <Card className="leave_bal_header">
                                <Row>
                                  <Col>Leave Type</Col>
                                  <Col>Carry Forward</Col>
                                  <Col>Yearly Leaves</Col>
                                  <Col>Allocated</Col>
                                  <Col>Eligible</Col>
                                  <Col>Availed</Col>
                                  <Col>Balance</Col>
                                </Row>
                              </Card> */}
                                <Table responsive className="theme_table">

                                  <thead>
                                    <tr>
                                      <th>Leave Type</th>
                                      <th>Carried</th>
                                      <th>Yearly</th>
                                      <th>Allocated</th>
                                      <th>Eligible</th>
                                      <th>Availed</th>
                                      <th>Balance</th>
                                    </tr>
                                  </thead>


                              <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                              lname="Personal"
                              fwd={this.state.generalLeaveCr}
                              yearly = {10}
                              allocated={this.state.generalAloc - this.state.generalLeaveCr}
                              eligible={this.state.generalAloc}
                              taken={this.state.takenGeneral}
                              lbalance={this.state.generalLeave} 
                              />
                              <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Team Holidays"
                                  fwd={0}
                                  yearly = {10}
                                  allocated={10}
                                  eligible={10}
                                  taken={parseFloat(10) - parseFloat(this.state.holidayBalance)}
                                  lbalance={this.state.holidayBalance}
                                  />  

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Compensatory Off"
                                  fwd={0}
                                  yearly = {0}
                                  allocated={this.state.alocCompoff}
                                  eligible={this.state.alocCompoff}
                                  taken={parseFloat(this.state.alocCompoff) - parseFloat(this.state.compensatoryOff)}
                                  lbalance={this.state.compensatoryOff}
                                  /> 

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Bereavement"
                                  fwd={0}
                                  yearly = {5}
                                  allocated={5}
                                  eligible={5}
                                  taken={parseFloat(this.state.takenBereavement)}
                                  lbalance={parseFloat(this.state.bereavement)}
                                  /> 

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Maternity"
                                  fwd={0}
                                  yearly = {this.state.alocMaternity}
                                  allocated={this.state.alocMaternity}
                                  eligible={this.state.alocMaternity}
                                  taken={this.state.takenMaternity}
                                  lbalance={this.state.maternityLeave}
                                  />
                              
                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Paternity"
                                  fwd={0}
                                  yearly = {this.state.alocPaternity}
                                  allocated={this.state.alocPaternity}
                                  eligible={this.state.alocPaternity}
                                  taken={this.state.takenPaternity}
                                  lbalance={this.state.paternityLeave}
                                  />
 </Table>
                                <div class="leave-note">
                                  {this.state.doj> this.state.checkDate?
                                    (
                                      <>
                                        <span><strong>Personal & Team Holidays:</strong><br />Each calendar year you are eligible for 10 Team Holidays and 10 Personal Leaves. Depending on the time of year that you join Kimp, these numbers may vary. Please contact the Senior Human Resources Manager for any clarification you might require.</span>
                                        <span>
                                        <strong>Compassionate & bereavement leaves:</strong><br />
                                          All employees are entitled to compassionate leave when an immediate family or household member’s death. These Leaves will be approved only based on valid reason with the Management Approval. ​All the  employees are entitled to have maximum 5 ​compassionate & bereavement leaves in a year. This leave is available to the applicants (​employees including probationers), when one of these immediate family members face above-mentioned circumstances. 
                                          Parents 
                                          Spouse, 
                                          Children, 
                                          Brother or Sister.
                                        </span>
                                      </>
                                    )
                                    :
                                    (
                                      <>
                                        <span><strong>Personal & Team Holidays:</strong><br />Each calendar year you are eligible for 10 Team Holidays and 10 Personal Leaves. Depending on the time of year that you join Kimp, these numbers may vary. Please contact the Senior Human Resources Manager for any clarification you might require.</span>
                                        <span>
                                        <strong>Compassionate & bereavement leaves:</strong><br />
                                          All employees are entitled to compassionate leave when an immediate family member’s death. These Leaves will be approved only based on valid reason with the Management Approval. ​All the  employees are entitled to have maximum 5 ​compassionate & bereavement leaves in a year. This leave is available to the applicants (​employees including probationers), when one of these immediate family members face above-mentioned circumstances. 
                                          Parents, 
                                          Spouse, 
                                          Children, 
                                          Brother or Sister.
                                        </span>
                                      </>
                                    )
                                  }
                                  
                                </div>
                            </>
                          )
                          :
                          (
                            <>
                            <Table responsive className="theme_table">

                                <thead>
                                  <tr>
                                    <th>Leave Type</th>
                                    <th>Carried</th>
                                    <th>Yearly</th>
                                    <th>Allocated</th>
                                    <th>Eligible</th>
                                    <th>Availed</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                               
                 
                               
                              
                                <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Earned"
                                  yearly = {12}
                                  fwd={this.state.earnedLeaveCr}
                                  allocated={this.state.alocEarned - this.state.earnedLeaveCr}
                                  eligible={parseFloat(this.state.earnedLeaveCr) + parseFloat(this.state.alocEarned - this.state.earnedLeaveCr)}
                                  taken={this.state.takenEarned}
                                  lbalance={this.state.earnedLeave} 
                                  />

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Casual"
                                  yearly = {4}
                                  fwd={0}
                                  allocated={this.state.alocCasual}
                                  eligible={this.state.alocCasual}
                                  taken={this.state.takenCasual}
                                  lbalance={parseFloat(this.state.alocCasual) - parseFloat(this.state.takenCasual)}
                                  />

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Sick"
                                  yearly = {4}
                                  fwd={0}
                                  allocated={this.state.alocSick}
                                  eligible={this.state.alocSick}
                                  taken={this.state.takenSick}
                                  lbalance={parseFloat(this.state.alocSick) - parseFloat(this.state.takenSick)}
                                  />  

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Team Holidays"
                                  yearly = {10}
                                  fwd={0}
                                  allocated={10}
                                  eligible={10}
                                  taken={parseFloat(10) - parseFloat(this.state.holidayBalance)}
                                  lbalance={this.state.holidayBalance}
                                  />  

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Compensatory Off"
                                  yearly = {0}
                                  fwd={0}
                                  allocated={this.state.alocCompoff}
                                  eligible={this.state.alocCompoff}
                                  taken={parseFloat(this.state.alocCompoff) - parseFloat(this.state.compensatoryOff)}
                                  lbalance={this.state.compensatoryOff}
                                  /> 

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Bereavement"
                                  yearly = {5}
                                  fwd={0}
                                  allocated={5}
                                  eligible={5}
                                  taken={parseFloat(this.state.takenBereavement)}
                                  lbalance={parseFloat(this.state.bereavement)}
                                  /> 

                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Maternity"
                                  yearly = {this.state.alocMaternity}
                                  fwd={0}
                                  allocated={this.state.alocMaternity}
                                  eligible={this.state.alocMaternity}
                                  taken={this.state.takenMaternity}
                                  lbalance={this.state.maternityLeave}
                                  />
                              
                                  <LeaveBalenceCard loading={this.state.leaveLoader}      classname="co-leave" 
                                  lname="Paternity"
                                  yearly = {this.state.alocPaternity}
                                  fwd={0}
                                  allocated={this.state.alocPaternity}
                                  eligible={this.state.alocPaternity}
                                  taken={this.state.takenPaternity}
                                  lbalance={this.state.paternityLeave}
                                  />

                             </Table>
                             <div class="leave-note">
                               <h3>Team Holidays</h3>
                               <p>Each calendar year you are eligible for 10 Team Holidays. Depending on the time of year that you join Kimp, these numbers may vary. Please contact the Senior Human Resources Manager for any clarification you might require.</p>
                             </div>
                             <div class="leave-note">
                               <h3>Compassionate & bereavement leaves</h3>
                               <p>All employees are entitled to compassionate leave when an immediate family or household member’s death. These Leaves will be approved only based on valid reason with the Management Approval. ​All the  employees are entitled to have maximum 5 ​compassionate & bereavement leaves in a year. This leave is available to the applicants (​employees including probationers), when one of these immediate family members face above-mentioned circumstances. 
                                    Parents,  
                                    Spouse, 
                                    Children, 
                                    Brother or Sister.</p>
                             </div>
                            
                            </>
                          )
                        }                          
                        </>
                        )
                      :
                      null
                    } 
                  
                  {this.state.leaveProfileType >0 ?
                  (
                      <>
                          <div className="upcoming-holiday">
                            <h4 className="title text-center">
                              Upcoming Team Holidays {this.state.holidayCountry !=''? ' - '+this.state.holidayCountry: null}
                            </h4>
                          </div>
                          {this.state.holidays.length>=0?
                          (<Fragment>
                            
                            <LoadingWrapper isLoading={ this.state.holidayLoader }>
                            <div className="upcomeing_leave_card">
                              {this.state.holidays.map( (item, index) =>
                                {
                                 return (
                                  
                                    <UpcomingCompanyHoliday
                                      key={index} 
                                      holidaydate={item.holiday_date} 
                                      holidayday ={item.holiday_day} 
                                      occasion={item.occasion} 
                                      holidaytype={item.holiday_type} 
                                      fulllist={(index > 1 && this.state.showFullList) ? '1':(index<=1?'1':'2') } />
                                 
                                 )   
                                })
                              }
                               </div> 
                            </LoadingWrapper>
                           
                          </Fragment>)
                          :
                          null
                          }                            
                          <DownArrow onclick={this.toggleList} showclass={this.state.showFullList? 'hidelist': ''} />
                      </>
                  )
                  :
                  null
                  }                                                                                     
                </section>    
            </Card>
          </Col>
          <Col xs={12} sm={12} md={12} lg={4}>
           <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">Calendar</CardTitle>
                <LeaveCalenderDetails/>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
      </Container>

      </div>
    );
  }
}
