import React, { useState } from 'react';
import MaterialIcon from "material-icons-react";
const AlertMessages = (props) => {
    const [show, setShow] = useState(true);
    return(
      <>
        {show && 
        <>
        <div className="alert_messages">
          {props.message}
        <button className="closebtn" onClick={() => setShow(prev => !prev)}><MaterialIcon icon="close"/> </button></div>
        </>
        }
      </>
    );
  }
  export default AlertMessages
