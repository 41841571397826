import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import {urls} from "../../services/UrlList";
import axios from 'axios';
import Serachicon from '../../images/search_icon.svg'
import {Container, Row, Col, Input, Button, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import Bcplusicon from '../../images/bc_Plus.svg';
import moment from "moment";
import AccessDenied from "./AccessDenied";
import toast, { Toaster } from 'react-hot-toast';
import TaskItem from "./TaskItem";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import MaterialIcon from "material-icons-react";
import SideMenu from "../NewSideMenu/NewSideMenu";
import MailboxEmail from './MailboxEmail';


const notifyError = () => toast('Something went wrong, please try again.');
const notifyStatus =() => toast('Card status updated');

export default class Tasklistbc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      addmember:false ,
      orginalUsers:[],
      tasksActive:[],
      tasksCompleted:[],
      tasksDraft:[],
      tasksArchived:[],
      tasksAvailable:false,
      projectDetails:[],
      currentView:1,
      taskStatus:1,
      myPrivileges: [],
      projectId: this.props.match.params.bid,
      myId: 0,
      myBaseId: 0,
      showMenu:true,
      myName: '',
      searchkey:'',
      listStatus:'active',
      searchActive:false,
      accessDenied:false,
      progressBar:true,
      taskActiveUpdateProgress:false,
      taskCompleteUpdateProgress:false,
    };
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.reactTags = React.createRef();
    this.keyPress = this._handleKeyDown.bind(this);
  }

toggleDisplay() {
  this.setState({
    display: !this.state.display
  });
}
togglememberDisplay() {
  this.setState({
    addmember: !this.state.addmember
  });
}
handleChangeEditor(value) {
  this.setState({ ticketDetails: value })
}


  componentDidMount () {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    this.getFullUsers();
    const _this = this;
    setTimeout(()=>
    {
      _this.getTaskList(1);
      document.addEventListener("scroll", _this.trackScrolling);
    }, 300);
  };

  componentWillUnmount() {  
    this.checkUserPrivilege();
    this.getFullUsers();
    this.getTaskList(1);
  }

  componentDidUpdate(prevState) {
    if (prevState.searchActive !== this.state.searchActive &&
      this.state.searchActive === true)
    {
      const _this = this;
      setTimeout(()=>
      {
        if(_this.state.searchkey=='')
        {
          _this.setState({searchActive:false});
        }
      }, 8000);
    }
  }

  getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),          
        });            
    })
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

 

  handleChange = ({target}) =>
  {
    this.setState({ [target.name] : target.value})
    localStorage.setItem([target.name], target.value);
    if(target.name == 'ticketStat' && target.value == 8)
    {
      this.setState({
        resolvedDate:'',
        sortBy:'',
      })
    }
  }

  handleInputChange(input, e) {
    this.setState({ selectedClient :e});
  }

  
  convertTimeFormat = (time) =>
	{
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}
 
  getTaskList = async (type) => {
    this.setState({
      progressBar:true
    })
    const data = {
      project_id:this.props.match.params.bid,
      status: type,
      searchkey: this.state.searchkey,
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getTaskList, data, {
        headers: headers
      })
      .then((response) => {
        if(type === 1) // active and completed
        {
          this.setState({
            listStatus:'active',
            projectDetails: response.data.project_details[0],
            tasksActive: response.data.success === 1 ? response.data.tasks_active : [],
            tasksCompleted: response.data.success === 1 ? response.data.tasks_completed : [],
            tasksAvailable: response.data.success == 1 ? true : false,
            currentView:1,
            accessDenied: response.data.success === 10 ? true : false,
            progressBar:false
          })
        }else if(type === 2) // draft
        {
          this.setState({
            listStatus:'draft',
            projectDetails: response.data.project_details,
            tasksDraft: response.data.success === 1 ? response.data.tasks_draft : [],
            currentView:2,
            accessDenied: response.data.success === 10 ? true : false,
            progressBar:false
          })
        }
      })
      .catch((error) => {
        this.setState({
          progressBar:false,
          accessDenied: true
        })
      })
    };

    _handleKeyDown = (e) => 
    {
      var code = (e.keyCode ? e.keyCode : e.which);
      const _this = this;
      setTimeout(()=>
      {
        if(_this.state.searchkey=='')
        {
          _this.setState({searchActive:false});
        }
      }, 8000);
      if(code == 13) {     // pressed enter key 
          if(this.state.listStatus ==='active')
          {
            this.getTaskList(1);
          }else  if(this.state.listStatus ==='draft')
          {
            this.getTaskList(2);
          }
      }
    }

    resetSearch = ()=>
    {
      this.setState({searchkey:''});
      const _this = this;
      setTimeout(()=>
      {
        if(_this.state.searchkey=='')
        {
          _this.setState({searchActive:false});
          _this.getTaskList(1);
        }
      }, 8000);
    }
    
    goToHome = () =>
    {
      this.props.history.push("/home"+"?uuid="+this.state.myId);
    }

    goToProjectsHome = () =>
    {
      this.props.history.push("/boards"+"?uuid="+this.state.myId);
    }
  
    breadNavigation = (murl)=>
    {
      this.props.history.push(murl+"?uuid="+this.state.myId);
    }

    addNewTask = ()=>
    {
      this.props.history.push("/new-task/"+this.props.match.params.bid+"?uuid="+this.state.myId);
    }

  updateTaskStatus = async (task_id, tid, action) =>
  {
    this.setState({
      taskActiveUpdateProgress:action == 1? true : false,
      taskCompleteUpdateProgress: action == 2? true : false,
    })
    const data = {
      project_id:this.props.match.params.bid,
      task_id: tid,
      tid:task_id,
      creator: this.state.myId,
      status: action,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateClientTaskStatus, data, {
        headers: headers
      })
      .then((response) => {
        this.setState({
          taskActiveUpdateProgress:false,
          taskCompleteUpdateProgress: false,
        })
        this.rorderList(tid, action);
        notifyStatus();
      })
      .catch((error) => {
        notifyError();
        this.setState({
          taskActiveUpdateProgress:false,
          taskCompleteUpdateProgress: false,
        })
      })
  }

  rorderList = async (tid, type) =>
  {
    if(type === 1) // marking as incomplete
    {
      var activetasks = [...this.state.tasksActive];
      var completedtasks = [...this.state.tasksCompleted];

      var itemkey = completedtasks.map(function (item) { return item.tid_cpt; }).indexOf(tid); // (2D) finding the matching key providing value
      var itemmoving = completedtasks[itemkey];
      activetasks.push(itemmoving);
      completedtasks.splice(itemkey, 1);

      this.setState({
        tasksActive: activetasks,
        tasksCompleted: completedtasks
      });
    }
    else
    {
      var activetasks = [...this.state.tasksActive];
      var completedtasks = [...this.state.tasksCompleted];

      var itemkey = activetasks.map(function (item) { return item.tid_cpt; }).indexOf(tid); // (2D) finding the matching key providing value
      var itemmoving = activetasks[itemkey];
      completedtasks.push(itemmoving);
      activetasks.splice(itemkey, 1);

      this.setState({
        tasksActive: activetasks,
        tasksCompleted: completedtasks
      });
    }
  }

  render() {
    return (
      <div className="fullwidth_mobilemenu">
       <Container fluid className="top-bar-inactive bc_module_container">
       <Row>
        <div className="theme_header">
        <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
            <div className="SideNavbar">
              <SideMenu  
              activeitem="project" 
              showmenu={this.state.showMenu} 
              hidemenu={this.hideMenu.bind(this)}  /> 
            </div>
        </div>
        <Toaster position="top-right" containerClassName="notifier"
              toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                  background: '#363636',
                  color: '#fff',
                },}}
          />
       <div className="themeContentBody themefullwidthContentBoday">
        <Row>
        <LoadingWrapper isLoading={this.state.progressBar}>
          {this.state.accessDenied?
          (
            <>
              <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
            </>
          ):
          (
            <>
                <Col sm="12">
                  <div className="bc_Board_wrapper">
                    <h2 class="section_title d-flex align-items-center justify-content-center">{this.state.projectDetails.board_name}
                    <ProjectCurrentStatus 
                    graphics={this.state.projectDetails.graphics_pack}
                    video={this.state.projectDetails.video_pack}
                    graphics_video={this.state.projectDetails.graphics_video_pack}
                    whitelabel={this.state.projectDetails.white_label}
                    clientstatus={this.state.projectDetails.client_status} />
                    
                   
                    </h2>
                    <p class="board_discrption">{this.state.projectDetails.short_description}</p>
                    <MailboxEmail canva_access={this.state.projectDetails.canva_access} />   
                    <h2 className="section_title subtitle">Cards</h2>
                    <div className="page_header_wrap">
                      <Row>
                          <Col sm={6}>
                          {AccessCheckBlock('add_new_task') &&
                      (
                        <>
                            {this.state.projectDetails.board_type === 1 ?
                            (
                              <button className="addbtn_text" onClick={this.addNewTask}>
                                <span className="bc_plusicon">
                                  <img src={Bcplusicon} alt="back" />
                                </span> Add New Card
                              </button>
                            ):
                            (
                              <button className="addbtn_text" onClick={this.toggleDisplay}>
                                <span className="bc_plusicon">
                                  <img src={Bcplusicon} alt="back" />
                                </span> Add New Card List
                              </button>
                            )}
                        </>
                      )
                      }

                          </Col>
                          <Col sm={6}>
                          <div className="search-box">
                              <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards')} > <MaterialIcon icon="fact_check"  /> </a>
                              <a className="home_button_box"  onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                          </div>
                          </Col>
                      </Row>
                      
                  

                    </div>
                  </div>
                    
                    <div className="breadcrumb_boards">
                    <Breadcrumb>
                        <BreadcrumbItem><a href={"/boards"+"?uuid="+this.state.myId} >Boards</a></BreadcrumbItem>
                        <BreadcrumbItem><a href={'/boardsdetails/'+this.props.match.params.bid+"?uuid="+this.state.myId}>Board Details</a></BreadcrumbItem>
                        <BreadcrumbItem active>Cards</BreadcrumbItem>
                      </Breadcrumb>
                    </div>
                </Col>
                <div className="bc_module">
                <Col sm="12">
                <div className="task_list_header">
                  <div className="task_sort_btns">
                    <Button className={`draft_btn ${this.state.listStatus==='active' && 'btn-status-active' }`} onClick={this.getTaskList.bind(this, 1)}>View Active</Button>
                    {AccessCheckBlock('view_draft_tasks') &&
                    (<Button className={`draft_btn ${this.state.listStatus==='draft' && 'btn-status-active' }`} onClick={this.getTaskList.bind(this, 2)}>View Drafts</Button>)
                    }
                    {/* {AccessCheckBlock('view_archive_tasks') &&
                    (<Button className={`arch_btn ${this.state.listStatus==='archive' && 'btn-status-active' }`}>View Archive</Button>)
                    } */}
                  </div>
                  <div className="search_task_box">
                      <div className="search_bar search">
                        <Input className={`search__text ${this.state.searchActive && 'search-box-active'}`} type="text" name="taskSearchKey" 
                        value={this.state.searchkey}
                        autoComplete="off"
                        onKeyDown={this.keyPress} 
										    onChange={(e) => this.setState({searchkey: e.target.value})}
                        placeholder="Search - Type and press enter key to search" />
                        {this.state.searchkey !='' && (<span className="reset_btn" onClick={this.resetSearch.bind(this)}>Reset</span>)}
                        {!this.state.searchActive &&
                        (
                          <a onClick={(e)=>this.setState({searchActive:true})} className="search__button">
                            <img src={Serachicon} alt="back" />
                          </a>
                        )}
                      </div>
                    
                   
                  </div>
                </div>
                {(this.state.tasksActive && this.state.tasksActive.length>0 && this.state.currentView === 1) &&
                (
                    <div className="task_list_wrapper">
                      <h4 class="boxtitile">Active Cards</h4>
                      <LoadingWrapper isLoading={this.state.taskCompleteUpdateProgress}>
                      {this.state.tasksActive.map((task, index)=>{
                        return(
                          <TaskItem  
                          task={task} 
                          projectId={this.state.projectId} 
                          myTimezone={this.state.myTimezone} 
                          action="2"
                          searchactive={this.state.searchActive}
                          updatetaskstatus={this.updateTaskStatus}
                          myId={this.state.myId} />
                          )
                        })}
                        </LoadingWrapper>
                    </div>
                )}
                {(this.state.tasksCompleted && this.state.tasksCompleted.length>0 && this.state.currentView === 1) &&
                (
                    <div className="task_list_wrapper list_completed">
                      <h4 class="boxtitile">Completed Cards</h4>
                      <LoadingWrapper isLoading={this.state.taskActiveUpdateProgress}>
                      {this.state.tasksCompleted.map((task, index)=>{
                        return(
                          <div className="task_box" key={task.tid_cpt}>
                          <a href={"/task/detail/"+this.state.projectId+"/"+task.tid_cpt+"?uuid="+this.state.myId}>
                          <div className="task_titile">
                            
                              <span>{task.task_name}</span>
                              {JSON.parse(task.task_label_list).length>0 &&
                              (
                              <div className="priority_badges">
                                {JSON.parse(task.task_label_list).map((label, index)=>{
                                  return(
                                    <span className={`badge_text urgent_badge ${label.name.toLowerCase()}`} title="Urgent">{label.name}</span>
                                  )
                                })}
                              </div>
                              )}
                             </div>
                            </a>
                          
                            <div className="duedate_time client-due">
                            <div className="label">Due Date:</div>
                            <span className="task-items">
                            {(task.due_date && task.due_date !=='')  &&
                            (
                            moment((new Date(task.due_date)).getTime()).tz(this.state.myTimezone).format('DD MMM YYYY h:mm A')
                            )}
                            </span> 
                            </div>
                            <div className="assign_person">
                              <div className="label">Assigned To  :</div>
                              {JSON.parse(task.task_assignee_list).length>0 &&
                              (
                                <div >
                                  {JSON.parse(task.task_assignee_list).map((assignee, index)=>{
                                    return(
                                      <span className="task-items">
                                        {assignee.name}
                                      </span>
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                            <div className="assigned_person">
                              <div className="label">Assigned By  :</div>
                                <span className="task-items">
                                          {task.name}
                                </span>
                            </div>
                            {(AccessCheckBlock('update_task_status') && this.state.projectId !=='gIiAZmVlZoCIgA==') &&
                            (
                              <div className="task-status">
                                <span className="task-items">
                                  <Input className="update-input" checked="checked" value={task.tid_cpt} type="checkbox" name="TaskStatus" onClick={this.updateTaskStatus.bind(this, task.id, task.tid_cpt, 1)} />     
                                </span>
                              </div>
                            )}
                          </div> 
                        )
                      })}
                      </LoadingWrapper>
                    </div>
                )}

                {(this.state.tasksDraft && this.state.tasksDraft.length>0 && this.state.currentView === 2) &&
                (
                    <div className="task_list_wrapper list_completed">
                      <h4 class="boxtitile">Drafted Tasks</h4>
                      {this.state.tasksDraft.map((task, inxex)=>{
                        
                        return(
                          <div className="task_box" key={task.id}>
                          <div className="task_titile">
                            <a href={"/task/detail/"+this.state.projectId+"/"+task.tid_cpt+"?uuid="+this.state.myId}>
                              <span>{task.task_name}</span>
                              {JSON.parse(task.task_label_list).length>0 &&
                              (
                              <div className="priority_badges">
                                {JSON.parse(task.task_label_list).map((label, index)=>{
                                  return(
                                    <span className={`badge_text urgent_badge ${label.name.toLowerCase()}`} title="Urgent">{label.name}</span>
                                  )
                                })}
                              </div>
                              )}
                              </a>
                            </div>
                          
                            <div className="duedate_time">
                            <div className="label">Due Date:</div>
                            <span className="task-items">
                            {(task.due_date && task.due_date !=='')  &&
                            (
                            moment((new Date(task.due_date)).getTime()).tz(this.state.myTimezone).format('DD MMM YYYY h:mm A')
                            )}
                            </span> 
                            </div>
                            <div className="assign_person">
                              <div className="label">Assigned To  :</div>
                              {JSON.parse(task.task_assignee_list).length>0 &&
                              (
                                <div >
                                  {JSON.parse(task.task_assignee_list).map((assignee, index)=>{
                                    return(
                                      <span className="task-items">
                                        {assignee.name}
                                      </span>
                                    )
                                  })}
                                </div>
                              )}
                            </div>
                            <div className="assigned_person">
                              <div className="label">Assigned By  :</div>
                                <span className="task-items">
                                          {task.name}
                                </span>
                            </div>
                          </div> 
                        )
                      })}
                    </div>
                )}

                </Col>
              </div> 
            </>
          )}
        </LoadingWrapper>  
        </Row>
       </div>
      </Row>

      </Container>
    
      
    
      </div>
    )
  }
}
