import React, { Component, Fragment } from "react";
import "./LoanDetails.css";
import { Card, Button, Row, Col } from "reactstrap";
import UserPicker from "./UserPicker";
import LoadingBox from "../../LoadingBox/LoadingBox"
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper"

export default class LoanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApply:false
    };
  }

  showApply = () => {
    this.props.showApply();
  };

  showRepay = () => {
    this.props.showRepay();
  };

  onPeopleChange = (people) =>
  {
    this.props.peoplechange(people);
    if(people >0)
    {
      this.setState({showApply: true});
    }else
    {
      this.setState({showApply: false});
    }
  }

  render() {
    let showApplyClass = [
      "btn " + this.props.activeApply ? "btn-green" : "btn-green-outline"
    ].join(" ");
    return (
      <Card id="loan-details-head" className="d-flex">        
        {!this.props.progress?
          (
            <>
            <Row>
              <Col xs={12} sm={4} md={4} lg={4}>
              <div id="loan-total-taken" className="d-flex">
                <p className="title">Taken</p>
                <p>
                  {this.props.loansummary.credit_amount >0?
                   (this.props.loansummary.credit_amount)
                  :
                  '0'
                  } 
                </p>
              </div>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>
              <div id="loan-total-paid" className="d-flex">
                <p className="title">Repaid</p>
                <p>
                  {this.props.loansummary.debit_amount >0?
                   (this.props.loansummary.debit_amount)
                  :
                    '0'
                  }
                </p>
              </div>
              </Col>
              <Col xs={12} sm={4} md={4} lg={4}>

              <div id="loan-total-outstanding" className="d-flex">
                <p className="title">Outstanding</p>
                <p>
                {this.props.loansummary.credit_amount >0?
                   (this.props.loansummary.credit_amount - this.props.loansummary.debit_amount)
                  :
                  '0'
                }
               </p>
              </div>
              </Col>
            </Row>
                
            
            </>
          )
          :
          (<LoadingWrapper isLoading={this.props.progress}></LoadingWrapper>)
        }                
        {/* <UserPicker myid={this.props.myid} peoplechange = {this.onPeopleChange} /> 
        {this.state.showApply? 
           (<Button
            id="loan-apply-loan-btn"
            className={showApplyClass}
            onClick={() => {
              this.props.showApply();
            }}
          >
            Apply Loan
          </Button>
          )
          :
          null
        }  */}

         {/* {<Button
          id="loan-make-pay-btn"
          className="btn btn-green-outline text-upper"
          onClick={() => {
            this.props.showRepay();
          }}
        >
          make payment
        </Button>} */}
      </Card>
    );
  }
}
