import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import DataList from '../datalist/DataList';
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, CardTitle, Input, Button } from 'reactstrap';
import BasecampIcon from '../svgicon/BasecampIcon';
import {isMobile} from 'react-device-detect';
import FullUserPicker from "./FullUserPicker";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
// LoadingBox
import LoadingBox from '../LoadingBox/LoadingBox'
// Framer Motion
import {motion} from 'framer-motion'
import './SwitchUser.css'

export default class SwitchUser extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      showProgressSearch: true,
      orginalEntries:[],
      fullEntries:[],
      orginalUsers:[],
      selectedUser:'',
      myPrivileges:[],
      showMenu:true,     
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Switch User";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
     // _this.getlogTimerReport(); 
    }, 100);
    
  //  this.reloadChecker(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("superadmin") === null)
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onPeopleChange = (userid) =>
  {        
   // console.log(urls.switchUser+userid);
    let opts = { };
      fetch(urls.switchUser+userid, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(response => {            
          localStorage.removeItem("user");
          if (response && response.length > 0) {
            if (response[0][0]["status"] === 1) {
              opts.loggedIn = true;
              opts.username = response[0][0]["email"];
              opts.uid = response[0][0]["uid"];
              opts.kimp360id = response[0][0]["kimp360_id"];
              opts.name = response[0][0]["name"];
              opts.user_type = response[0][0]["user_type"];
              opts.employee_type = response[0][0]["employee_type"];
              opts.privileges = response[0][0]['privileges'];
              opts.showext = response[0][0]['extended_break_access'];
              opts.usershift = response[0][0]['user_shift'];
              opts.basecampid = response[0][0]['basecamp_uid'];
              opts.user_team = response[0][0]['user_teamid'];
              opts.my_timezone = response[0][0]['my_timezone'];
              localStorage.setItem("user", JSON.stringify(opts));
              localStorage.setItem("firstlogin", 0);
              this.setState({
                progressBar: false,                
              });
              this.props.history.push("/home");
                                 
            } else {
              this.setState({
                progressBar: false,               
              });
            }
          } else {
            this.setState({
              progressBar: false,             
            });
          }
        })
        .catch(error => {
            console.log(error);
          this.setState({
            progressBar: false,
            showAlert: true
          });
        });
  }
  

  render() {

    
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
               classname="tick-timer-header"  
               logout={this.signOut.bind(this)} 
               onmenuaction={this.toggleSidebar.bind(this)} 
               quickactionblock={true} />
        </div>
        <div className="SideNavbar">
           <SideMenu  
            activeitem="home" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
        <div className="themeContentBody">
        <Row>
          <Col sm={12} lg={12}>
            <Card className="switch_user_card">
              <CardTitle tag="h1">Switch User</CardTitle>
              <div id="filter" className="switchUser">                  
                  <div className="switch_user_wrapper">
                    <FullUserPicker 
                      myid={this.state.myId} 
                      baseid={this.state.myBaseId} 
                      teamid={this.state.userTeam} 
                      usertype={this.state.showPeopleType} 
                      onPeopleChange={this.onPeopleChange.bind(this)} />
                  </div>                     
              </div>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>  
      </Container>
     
      </div>
    )
  }
}
