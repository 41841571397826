import React, { Component, Fragment } from "react";
import "./ApprovalModules.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import { InputGroup, Input, Label } from "reactstrap";

export default class ApprovalModules extends Component {
  
  onShowLeavePending = () =>
  {
    this.props.showonpending();
  }

  render() {
    let classname = ["ApprovalModules", this.props.classname]
      .toString()
      .replace(",", " ");
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true
    };

    return (
      <section className={classname} id={this.props.id}>
        <InputGroup className="appr-show-unapproved d-flex">
          <h2 className={"caros-title text-center txt-upper ml-auto"+(this.props.waiting>0? " pending-exist": '')} >
            {this.props.title}
          </h2>
          
          <div className="appr-ckbox-unapproved ml-auto d-flex">
          {this.props.waiting>0?
          (     
            <Fragment>      
                <Input
                  id="unapproved"
                  type="checkbox"
                  name="unapproved"
                  value="unapprove"
                  onClick={this.onShowLeavePending.bind(this)}
                />
                <Label htmlfor="unapproved" style={{paddingTop:'4px'}}>Show Unapproved</Label>
            </Fragment> 
          )
          :
          null
          }  
          </div>        
        </InputGroup>
        {this.props.children}
      </section>
    );
  }
}
