import React from "react";
import "./AvatarApproval.css";
import AvatarImg from "../../../images/avatar-icon.png";

export default function AvatarApprovals() {
  return (
    <div className="appr-avatar">
      <img src={AvatarImg} width="37" height="37" alt="approval-avatar" />
    </div>
  );
}
