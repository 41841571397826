import React, { Component, Fragment } from "react";
import "./LeaveStatus.css";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Label, Button, Card,CardTitle } from "reactstrap";
import LeaveHeader from "../LeaveHeader/LeaveHeader";
import BscIcon from "../../../images/basecampIcon.svg";
import BasecampIcon from "../../svgicon/BasecampIcon.jsx";
import LeaveStatusCard from "./LeaveStatusCard/LeaveStatusCard";
import LoadingBar from "../../common/LoadingBar";
import {urls} from "../../../services/UrlList";
import { isMobile } from "react-device-detect";
import LoadingWrapper from '../../LoadingBox/LoadingWrapper/LoadingWrapper'
import Alertlogmsg from "../../messages/Alertlogmsg";
import LeaveCalenderDetails from "../LeaveCalender/LeaveCalender"


export default class LeaveApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sYear: new Date(),
      progressBar:true,
      myPrivileges: [],
      myId: "",
      myLeaves:[],
      showMenu:true,
      errorMsg:false,
      logMsg:'',
      superadmin:'',
      fridays:0,
      mondays:0,
      others:0
    };
  }

  componentDidMount =() =>
  {
    this.checkUserPrivilege(); 
    this.renderContentDevice();
    const _this = this;
    setTimeout(function() {     
      _this.getMyLeaves();
    }, 100);    
  }

  componentDidUpdate(prevState) {  
    if (prevState.errorMsg !== this.state.errorMsg  && this.state.errorMsg === true) {      
      const _this = this;
      setTimeout(function() {           
       // _this.setState({errorMsg:false})
      }, 20000);  
    } 
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,  
        superadmin: login.superadmin     
      });
    }
    if (localStorage.getItem("superadmin") !== null) {
      this.setState({
        superadmin: localStorage.getItem("superadmin")     
      });
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }



  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  getMyLeaves = () => { 
    // this.setState({progressBar:true});
    let opts={
      leave_year:this.state.sYear,
      user_id: this.state.myId
    }
    fetch(urls.getMyLeaves, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>{
      console.log(response);
      this.setState({
        myLeaves:response[0].my_leaves, 
        mondays: response[0].leave_days.monday,
        fridays: response[0].leave_days.friday,
        others: response[0].leave_days.others,
        progressBar:false
      });
    })
    .catch(err => 
    {
      this.setState({
       progressBar:false
      });
    })
  };

  onLeaveWithdraw = (leave)=> {
    this.setState({progressBar:true});
    let opts ={
      leave:leave,
      user_id: this.state.myId,
      superadmin: this.state.superadmin
    };
    fetch(urls.withdrawLeave, {
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>{
      this.setState({
        logMsg: response[0]['msg'],
        errorMsg:response[0]['status'] === 2? true : false,
      });
      this.getMyLeaves();
    })
    .catch(error =>{
      this.setState({progressBar:false});
      this.getMyLeaves();
    })
  };


  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
           logout={this.signOut.bind(this)} 
           onmenuaction={this.toggleSidebar.bind(this)}
           quickactionblock={true} />      
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="leave" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody themefullwidthContentBoday">
          <Row>
            <Col sm={12} lg={8} className="mb-4">
              <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title"> Leave</CardTitle>
                <LeaveHeader active="status" />
                <section id="leave-balence">
                  <div className="myleave_count">
                    <h4>My Leaves</h4>
                    {this.state.errorMsg && 
                    ( <Row>
                        <Col>
                          <Alertlogmsg 
                            msgtext={this.state.logMsg} 
                            boxclass="task-running" />
                        </Col>
                      </Row>)
                    } 
                    <div className="leave-days d-flex justify-content-between">
                      <div className="date-type">
                        <Label>Monday: </Label>{this.state.mondays}
                      </div>
                      <div className="date-type">
                        <Label>Friday: </Label>{this.state.fridays}
                      </div>
                      <div className="date-type">
                        <Label>Other: </Label>{this.state.others}
                      </div>
                    </div>
                  </div>
                    <LoadingWrapper isLoading={ this.state.progressBar }>
                      {this.state.myLeaves.map((leave, index) =>
                        {
                          return(
                            <LeaveStatusCard  key={index} leave={leave} withdrawaction={this.onLeaveWithdraw}/>
                          )
                        })}
                      </LoadingWrapper>        
                </section>
              </Card>
            </Col>



            <Col sm={12} lg={4}>
            <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">Calendar</CardTitle>
                <LeaveCalenderDetails/>
            </Card>
            </Col>
          </Row>
        </div>
      </Row>
      </Container>

   
    </div>
    );
  }
}
