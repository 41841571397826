
import React, { Component } from 'react';
import Timer from "react-compound-timer";

export default class Dtimer extends Component {
  render() {
    return (     
        <p className="text-left break-timer-live">
          <Timer initialTime={this.props.startTimem}>                       
                      <Timer.Hours /> Hrs: <Timer.Minutes /> Mins: <Timer.Seconds /> Secs                        
         </Timer>        
        </p>
     
    )
  }
}


