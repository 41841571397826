import React, { Component, Fragment } from "react";
import Header from "../header/Header";
import ProjectFilter from "../filter/ProjectFilter";
import "./list-head.css";
import "./listcard.css";
import "./LogTimer.css";
import DataList from "../datalist/DataList";
import { urls } from "../../services/UrlList";
import LoadingBar from "../common/LoadingBar";
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {
  Container,
  Row,
  Col,
  Card,
  FormGroup,
  Input,
  Button,
  CardTitle,
  Table
} from "reactstrap";
import BasecampIcon from "../svgicon/BasecampIcon";
import TrelloIcon from "../svgicon/TrelloIcon";
import { isMobile } from "react-device-detect";

import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";

// LoadingBox
import LoadingBox from "../LoadingBox/LoadingBox";

// Framer Motion
import { motion } from "framer-motion";

export default class ProjectReport extends Component {
  constructor(props) {
    super(props);
    var s = new Date();
    s.setDate(s.getDate());
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("DD-MM-YYYY");
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      showProgress: false,
      showProgressSearch: false,
      orginalEntries: [],
      fullEntries: [],
      selectedUser: "",
      selectedProject: "",
      show_detail: false,
      show_detail_id: 0,
      startDate: null,
      endDate: null,
      running_status: 0,
      showMenu: true,
      overallTime: "",
      projectView: false,
      memberView: true,
      filterHour: "",
      detailEntries: [],
      DetailView: true,
      showDetailProgress: false,
      project_id: "",
      showTaskPeopleId: "",
      showTaskPeople: "",
    };
    // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    document.title = "HUB - Project Logs";
    this.checkUserPrivilege();
    this.renderContentDevice();
    const _this = this;
    /*setTimeout(() => {
      _this.getlogTimerReport(); 
    }, 100);*/
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then((response) => response.json())
      .then((response) => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then((response) => response.json())
          .then((response) => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch((error) => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myBaseId: login.basecampid,
      });

      if (login.privileges.includes("access_all_reports")) {
        this.setState({ showPeople: true, showPeopleType: 1 });
      } else if (login.privileges.includes("access_team_reports")) {
        this.setState({ showPeople: true, showPeopleType: 2 });
      } else {
        this.setState({ showPeople: false, showPeopleType: 3 });
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes("mobile_access")) {
      this.setState({ isMobileView: 1 });
    }
    if (isMobile) {
      this.setState({ showMenu: false });
    }
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  onStatusChange = (event) => {
    this.setState({
      status: event.target.value,
    });
  };

  onUserNameChange = (event) => {
    this.setState({
      userName: event.target.value,
    });
  };

  _handleKeyDown = (e) => {
    var code = e.keyCode ? e.keyCode : e.which;
    // console.log(e);
    if (code == 13) {
      this.setState({
        searchTags:
          this.state.searchTags != ""
            ? this.state.newTag + "," + this.state.searchTags
            : this.state.newTag,
        newTag: "",
        newTagArray: [...this.state.newTagArray, this.state.newTag],
      });
    }
  };
  showDetailEntry = (id) => {
    this.setState({
      show_detail: true,
      show_detail_date: 0,
      show_detail_id: this.state.show_detail_id === id ? 0 : id,
    });
  };

  searchEntriestwo = (project, user, start, end) => {
    this.setState({
      orginalEntries: [],
      overallTime: "",
      showProgress: true,
      selectedUser: user,
      selectedProject: project,
      startDate: start != "" ? start.format("DD-MM-YYYY") : "",
      endDate: end != "" ? end.format("DD-MM-YYYY") : "",
    });

    if (project > 0 || user > 0) {
      let opts = {
        project_id: project,
        designer: user,
        start_date: start != "" ? start.format("DD-MM-YYYY") : "",
        end_date: end != "" ? end.format("DD-MM-YYYY") : "",
      };
      this.setState({
        projectView: true,
        memberView: false,
      });

      fetch(urls.postBaseProjectReportsRange, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            orginalEntries: response["results"],
            fullEntries: response["results"],
            showProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
    } else if (project === "" && user === "" && start !== "" && end !== "") {
      let opts = {
        start_date: start != "" ? start.format("DD-MM-YYYY") : "",
        end_date: end != "" ? end.format("DD-MM-YYYY") : "",
      };

      this.setState({
        projectView: true,
        memberView: false,
      });

      fetch(urls.postBaseProjectReportsRange, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            orginalEntries: response["results"],
            fullEntries: response["results"],
            showProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
    }
  };

  filterbyhour = (value) => {
    this.setState({
      filterHour: value,
    });
    this.filterByHours(value);
  };

  filterByHours = (value) => {
    this.setState({ filterHour: value });
    if (value != "") {
      setTimeout(() => {
        let temp = [];
        temp = this.state.fullEntries;
        let list = [];
        temp.forEach((p1) => {
          if (p1.total_hr > parseInt(this.state.filterHour)) {
            list.push(p1);
          }
        });
        this.setState({ orginalEntries: list });
      }, 100);
    } else {
      this.setState({ orginalEntries: this.state.fullEntries });
    }
  };

  searchEntriesProjectDetails = (project) => {
    if (this.state.project_id != project) {
      this.setState({
        detailEntries: [],
        showDetailProgress: true,
        showTaskPeopleId: 0,
        showTaskPeople: false,
      });
      let opts = {
        project_id: project,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        designer: this.state.selectedUser,
      };

      this.setState({
        DetailView: false,
        project_id: project,
      });
      fetch(urls.projectLogsRange, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        method: "post",
        body: JSON.stringify(opts),
      })
        .then((response) => response.json())
        .then((response) => {
          this.setState({
            detailEntries: response["results"],
            DetailView: true,
            showDetailProgress: false,
          });
        })
        .catch((error) => {
          this.setState({ showDetailProgress: false });
        });
    } else {
      this.setState({
        DetailView: false,
        project_id: "",
      });
    }
  };

  showTaskPeople = (id) => {
    if (id === this.state.showTaskPeopleId) {
      this.setState({
        showTaskPeopleId: id,
        showTaskPeople: !this.state.showTaskPeople,
      });
    } else {
      this.setState({
        showTaskPeopleId: id,
        showTaskPeople: true,
      });
    }
  };

  timeConvert = (n) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
  };

  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        },
      },
      hidden: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        },
      },
    };

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    };

    let deviceWidth = window.innerWidth;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
        <Container
          fluid
          className={
            (this.state.myPrivileges.includes("i_am_designer")
              ? "top-bar-active"
              : "top-bar-inactive") + " project-report"
          }
        >
          <Row>
            <div className="theme_header">
              <Header
                {...this.props}
                classname="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={true}
              />
            </div>
            <div className="SideNavbar">
              <SideMenu
                activeitem="report"
                showmenu={this.state.showMenu}
                hidemenu={this.hideMenu.bind(this)}
              />
            </div>
            <div className="themeContentBody">
              <Card className="themeCard log_timer_card">
                <CardTitle tag="h1" className="themeCard_title">
                Project Logs
                </CardTitle>
                <div className="filter_header_wrap">
                  <ProjectFilter
                    filterbytask={this.filterbytask}
                    filterbyhour={this.filterbyhour}
                    searchEntriesone={this.searchEntriestwo}
                  />
                </div>
                {this.state.memberView ? (
                  <>
                    {this.state.overallTime != "" ? (
                      <div className="overall-time">
                        Overall Time: {this.state.overallTime}
                      </div>
                    ) : null}
                    <Table responsive className="theme_table">
                      <thead>
                        <tr>
                          <th>Task Title</th>
                          <th>Total Time</th>
                         
                        </tr>
                      </thead>
                      
                      <tbody>
                      
                         {this.state.orginalEntries ? (
                                <>
                                  {this.state.orginalEntries.map(
                                    (entries, index) => {
                                      return ( 
                                        <div
                                            className={
                                              this.state.show_detail &&
                                              this.state.show_detail_id ===
                                                entries.task_id
                                                ? "user-active"
                                                : ""
                                            }
                                          >
                                          <tr onClick={this.showDetailEntry.bind(
                                                this,
                                                entries.task_id
                                              )}>
                                            <td>{entries.task_name}</td>
                                            <td>{entries.task_total}</td>
                                          </tr>
                                        </div>
                        );
                                    }
                                  )}
                                </>
                              ) : null}
                      
                      </tbody>
                      <LoadingWrapper
                            LoaderCount={5}
                            isLoading={this.state.showProgress}
                          ></LoadingWrapper>
                    </Table>

                    
                    <Row>
                      <Col sm="12">
                        <div
                          id="date-list-contianer"
                          className={this.state.showProgress && "loading"}
                        >
                         
                            {/* {this.state.showProgress && <LoadingBox /> } */}
                            <motion.section
                              initial="hidden"
                              animate="visible"
                              variants={list}
                            >
                              {this.state.orginalEntries ? (
                                <>
                                  {this.state.orginalEntries.map(
                                    (entries, index) => {
                                      return (
                                        <motion.div variants={item}>
                                          <div
                                            className={
                                              this.state.show_detail &&
                                              this.state.show_detail_id ===
                                                entries.task_id
                                                ? "user-active"
                                                : ""
                                            }
                                          >
                                            <Card
                                              className="d-flex"
                                              onClick={this.showDetailEntry.bind(
                                                this,
                                                entries.task_id
                                              )}
                                            >
                                              <p className="name">
                                                {entries.task_name}
                                              </p>
                                              <div className="mb-scroll-x">
                                                <div className="d-flex inner-width">
                                                  <p className="time-entered">
                                                    {entries.task_total}
                                                  </p>
                                                  {/*<p className="total-rate text-center">{entries.total_pending_tasks}</p> */}
                                                </div>
                                              </div>
                                            </Card>

                                            {entries.items.length > 0 ? (
                                              <div className="project-card work-card">
                                                <Card className="d-flex head">
                                                  <p className="time-entered emp-project-task">
                                                    Project & Task
                                                  </p>
                                                  <p className="time-entered emp-name">
                                                    Name
                                                  </p>
                                                  <p className="time-entered emp-time">
                                                    Time
                                                  </p>
                                                </Card>
                                                <Card className="list-card d-block">
                                                  <div className="card-body">
                                                    {entries.items.map(
                                                      (activity, index) => {
                                                        return (
                                                          <div
                                                            className="single-project d-flex"
                                                            key={index}
                                                          >
                                                            <p className="project-name emp-project-task">
                                                              <span className="project-name-title">
                                                                {
                                                                  activity.project_name
                                                                }
                                                              </span>
                                                              <span className="task-name-title">
                                                                {
                                                                  entries.task_name
                                                                }
                                                              </span>
                                                            </p>
                                                            <p className="project-name emp-name">
                                                              <span className="task-name-title ">
                                                                {activity.name}
                                                              </span>
                                                            </p>
                                                            <p className="time-entered emp-time">
                                                              {
                                                                activity.user_total
                                                              }
                                                            </p>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Card>
                                              </div>
                                            ) : null}
                                          </div>
                                        </motion.div>
                                      );
                                    }
                                  )}
                                </>
                              ) : null}


                            </motion.section>
                           
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Table responsive className="theme_table">
                      <thead>
                        <tr>
                          <th colSpan="6">Project Name</th>
                          <th colSpan="6">Total Time</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                   
                          {/* <div
                            id="date-list-contianer"
                            className={this.state.showProgress && "loading"}
                          > */}
                            <LoadingWrapper
                              LoaderCount={5}
                              isLoading={this.state.showProgress}
                            >
                            </LoadingWrapper>
                              {/* {this.state.showProgress && <LoadingBox /> } */}
                             
                                {this.state.orginalEntries ? (
                                  <>
                                    {this.state.orginalEntries.map(
                                      (entries, index) => {
                                        return (
                                          // <motion.div variants={item}>

                                            <>
                                              <tr onClick={this.searchEntriesProjectDetails.bind(
                                                    this,
                                                    entries.pmt_project_id
                                                  )}>
                                                <td colSpan="6">{entries.project_name}</td>
                                                <td colSpan="6" align="right"> {entries.total}</td>
                                              </tr>
                                            
                                              {this.state.project_id ===
                                                entries.pmt_project_id &&
                                                this.state.showDetailProgress && (
                                                  <LoadingWrapper
                                                    LoaderCount={5}
                                                    isLoading={
                                                      this.state
                                                        .showDetailProgress
                                                    }
                                                  ></LoadingWrapper>
                                                )}
                                                  <tr class="sub-level">
                                                  {this.state.detailEntries.length >
                                                    0 &&
                                                  this.state.project_id ===
                                                  entries.pmt_project_id && (
                                                    
                                                    <td colspan="12" className="p0">
                                                       <table className="w-100">
                                                       <thead>
                                                          <th>Shift Day</th>
                                                          <th>Total Time</th>
                                                          <th></th>
                                                        </thead>
                                                        <tbody>
                                                        {this.state.detailEntries ? (
                                                      <>
                                                        {this.state.detailEntries.map(
                                                          (entries, index) => {
                                                            return (
                                                              <>
                                                              <tr onClick={this.showTaskPeople.bind(
                                                                this,
                                                                entries.shift_day
                                                              )}>
                                                                <td>{entries.shift_day}</td>
                                                                <td>{entries.day_total}</td>
                                                              </tr>
                                                               <tr class="sub-level">
                                                                 <td colspan="12" className="p0">
                                                                  <table className="w-100">
                                                                    <tbody>
                                                                    {this.state
                                                                    .showTaskPeople &&
                                                                    this.state
                                                                      .showTaskPeopleId ===
                                                                      entries.shift_day && (
                                                                      <>
                                                                        {entries.items.map(
                                                                          (
                                                                            people,
                                                                            index
                                                                          ) => {
                                                                            var trello =
                                                                              people.task_name.split(
                                                                                "##"
                                                                              );
                                                                            return (
                                                                      <tr key={
                                                                        index
                                                                      }>
                                                                        <td>{people.task_name}</td>
                                                                        <td>{people.user_total}</td>
                                                                        <td>
                                                                          <div className="d-flex">
                                                                        <BasecampIcon
                                                                                    projectid={
                                                                                      people.project_id
                                                                                    }
                                                                                    taskid={
                                                                                      people.task_id
                                                                                    }
                                                                                    myId={this.state.myId}
                                                                                  />
                                                                                  {trello.length >
                                                                                    1 && (
                                                                                    <a
                                                                                      target="_blank"
                                                                                      href={
                                                                                        "https://trello.com/c/" +
                                                                                        trello[1]
                                                                                      }
                                                                                    >
                                                                                      <TrelloIcon />
                                                                                    </a>
                                                                                  )}
                                                                                  </div>
                                                                        </td>
                                                                      </tr>
                                                                      );
                                                                          }
                                                                        )}
                                                                      </>
                                                                    )}
                                                                      
                                                                    </tbody>
                                                                  </table>
                                                                 </td>
                                                               </tr>
                                                               </>
                                                        );
                                                          }
                                                        )}
                                                      </>
                                                    ) : null}
                                                        </tbody>
                                                       </table>
                                                    </td>
                                                    
                                                  )}
                                                  </tr>

                                              
                                          </>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null}
                          
                         
                          {/* </div> */}
                      
                      </tbody>
                    </Table>
                  </>
                )}
                
              </Card>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
