import React from "react";
import loadingSpinner from '../../images/loader.gif';

const LoadingBar = () => {
  return (
    <div className="overlay">
      <img
        className="spin"
        src={loadingSpinner}
        alt="loading.."
        style={{ height: "50px", width: "50px", margin: "0 auto" }}
      />
    </div>
  );
};

export default LoadingBar;
