import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import './activeRunningStyle.css'
import {urls} from "../../services/UrlList";
import GreenBell from "../svgicon/GreenBell";
import RedBell from "../svgicon/RedBell";
// import RefreshIcon from "../svgicon/RefreshIcon";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import BasecampIconImg from '../svgicon/BasecampIconImg';
import TrelloIcon from '../svgicon/TrelloIcon';
import axios from 'axios';
import Pmtimer from '../bcmodule/Tasks/Pmtimer';
import MaterialIcon from "material-icons-react";
export default class ActiveRunningTasks extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      initialLoader:true,
      actionStatus:false,
      runningTasks:[],
      runningTaskFirst:[],
      lastUpdate:''
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Trello Active Boards";
    this.checkUserPrivilege(); 
    this.getActiveRunningTasks();
  };

  componentDidUpdate(prevState) {  
    if (prevState.actionStatus !== this.state.actionStatus  && this.state.actionStatus === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({actionStatus:false})
      }, 6000);  
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }
  };

  getActiveRunningTasks = () =>
  {
    this.setState({
       initialLoader:true
    })
    const data = {
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getActiveRunningTasks, data, {
        headers: headers
      })
      .then((response) => {
           
           this.setState({
                runningTasks: response.data.taskslogs ? response.data.taskslogs :(response.data[0].taskslogs ? response.data[0].taskslogs :[] ) ,
                lastUpdate: response.data.last_updated ? response.data.last_updated :(response.data[0].last_updated ? response.data[0].last_updated :'' ),
                initialLoader:false
            })
      })
      .catch((error) => {
        this.setState({
           initialLoader:false
        })
      })
  }

  firstRunningTask = (data) =>
  {
    console.log("hello");
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }
  
  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

 findTimeDiffernce = (dated) =>
  {
    var date1, date2;
    let seconds = 0;
    date1 = new Date();
    date2 = new Date( dated );
    return seconds = Math.abs(date1 - date2);
  }
  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
     <Fragment>
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
      <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="project" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report">
               <div className="report_header">
                 <h1 class="her_title">Active Running Tasks</h1>
                  <div className="data_sync_card">
                    <p className="text-right data-sync">Last Data Sync: {this.state.lastUpdate}</p>
                    <span className="refresh-my-boards" onClick={this.getActiveRunningTasks}><MaterialIcon icon="sync"/></span>
                 </div>
               </div>
            
              {/*this.state.orginalUsers.length>0 &&
                (
                  <Col sm="5">
                    <Typeahead
                    id="user-picker"
                    labelKey="collection"
                    onInputChange={this.handleInputChange}
                    onChange={this.onChangePeople}
                    options={this.state.orginalUsers}
                    placeholder="Choose user..."
                    selected=''
                    />
                  </Col>
                )*/
              }
    
    <LoadingWrapper isLoading={this.state.initialLoader}>
          <div id="list-head" className="trello-boards active-running-tasks">
           
                {(this.state.runningTasks && this.state.runningTasks.length>0) &&
                (
                    <>
                    <Table responsive className="theme_table">
                      <thead>
                        <tr>
                          <th>Project Name</th>
                          <th>User Name</th>
                          <th>Task Name</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody >
                    {this.state.runningTasks.map((item, index)=>{ 
                        let stattime = this.findTimeDiffernce(item.start_time);
                        return(
                          <tr key={item.tid_cpt}>
                                <td>{item.project_name}</td>
                                <td>{item.username}</td>
                                <td>{item.task_name}</td>
                                <td className="text-right"><Pmtimer starttimer={stattime} /></td>
                              </tr>
                        )
                    })}
                        </tbody>
                      </Table>
                     </>
                )}
                               
           
          </div>
        </LoadingWrapper>

            </Card>
            </Col>
          </Row>
        </div>
      </Row>
     
     

      </Container>
      </Fragment>
    )
  }
}