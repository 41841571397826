import React from "react";
import basecampColor from "../../images/basecampColor.svg";
import basecampWhite from "../../images/basecampWhite.png";
import { Link } from "react-router-dom";

export default function BasecampIconURL(props) {
  const classes = "basecamp-icon " + props.classname;
  let BscIcon = props.active ? basecampWhite : basecampColor;
  return (
    <div className={classes}>
        <a href={props.projecturl} target="_blank">
            <div style={{
            width: "40px",
            height: "40px",
            background: `url(${BscIcon}) no-repeat center`,
            marginRight: '0',
            backgroundSize: '100% auto'
            }}>                
            </div>
        </a>
    </div>
  )
}