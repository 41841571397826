import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../../services/UrlList";

class BoardUserTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullTeams: this.props.fullteam,
      selectedTeam: this.props.selectedteam,
      value: []
    };  
  }
  componentDidMount = () => {
    
  };

  

  onChangePeople = (e) => {
    this.setState({ selectedTeam:e[0].id});
    setTimeout(() => {
      this.props.onteamchange(this.state.selectedTeam);
    }, 100);  
  };
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  handleChange = (e) => {
    this.setState({ selectedTeam:e.target.value});
    this.props.onteamchange(e.target.value);
  }

    render() {

      return (
        <>
        <div className="people pl_list date-col">
          <select name="team"  className="form-control"
                onChange={this.handleChange.bind(this)} >
                <option value={0}>Select Team</option>
                {this.state.fullTeams.map((team, index) =>
                {
                return(
                    <option value={team.id}
                    selected={this.state.selectedTeam == team.id? 'selected':''}
                    >{team.name}</option>
                )
                })}
            </select>

        </div>
        </>
      );
    }
}

export default BoardUserTeams;
