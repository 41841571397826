import React from 'react';

export default function Searchtickets( props ) {
  return (
    <div className={props.classprop} onClick={props.searchaction}>
     <svg  width="18.103" height="18.863" viewBox="0 0 18.103 18.863">
        <path id="Shape_29" data-name="Shape 29" d="M1317.794,279.181l-4.463-4.669a7.558,7.558,0,1,0-5.794,2.72,7.458,7.458,0,0,0,4.337-1.378l4.5,4.7a.984.984,0,0,0,1.4.027A1,1,0,0,0,1317.794,279.181Zm-10.257-15.194a5.629,5.629,0,1,1-5.6,5.629A5.619,5.619,0,0,1,1307.538,263.987Z" transform="translate(-1299.967 -262)" fill="#fff"/>
     </svg>
    </div>
  )
}