import React, { Component, Fragment } from "react";
import "./MonthlyReport.css";
import Header from "../header/Header";
import ProjectLogFilter from "../filter/ProjectLogFilter";
import "./list-head.css";
import "./listcard.css";
import { urls } from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {isMobile} from 'react-device-detect';
import {Container,Row,Col,Card,} from "reactstrap";
import BasecampIcon from "../svgicon/BasecampIcon";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'

export default class ProjectWorkLog extends Component {
  constructor(props) {
    super(props);
    var e = new Date();
    e.setDate(e.getDate());
    e.setHours(6);
    e.setMinutes(0);
    let momentObje = moment(e);
    let momentStringEnd = momentObje.format("DD-MM-YYYY");

    // var e = new Date();
    // e.setDate(e.getDate());
    // e.setHours(6);
    // e.setMinutes(0);
    // let momentObje = moment(e);
    // let momentStringEnd = momentObje.format("YYYY-MM-DD");

    this.state = {
      myPrivileges: [],
      myId: "",
      showProgress: true,
      showDetailProgress:false,
      orginalEntries: [],
      fullEntries: [],
      orginalUsers: [],
      showMenu:true,
      year:momentObje.format("YYYY"),
      month:momentObje.format("MMMM"),
      detailEntries:[],
      DetailView:false,
      project_id:'',
      showTaskPeople:false,
      showTaskPeopleId:0,
    };
    // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    document.title = "HUB - Login Timer Reports";
    this.checkUserPrivilege();
    this.renderContentDevice(); 
    const _this = this;
    setTimeout(() => {
      _this.getMonthlyReport();
    }, 100);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myBaseId: login.basecampid
      });

      if (login.privileges.includes("access_all_reports")) {
        this.setState({ showPeople: true, showPeopleType: 1 });
      } else if (login.privileges.includes("access_team_reports")) {
        this.setState({ showPeople: true, showPeopleType: 2 });
      } else {
        this.setState({ showPeople: false, showPeopleType: 3 });
      }
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  onStatusChange = event => {
    this.setState({
      status: event.target.value
    });
  };

  hoursRender = () => {
    const optionsval = [];
    for (var i = 1; i <= 12; i++) {
      optionsval.push(
        <option
          selected={this.state.filterHour == i ? "selected" : ""}
          value={i}
        >
          {"< " + i + " hour"}
        </option>
      );
    }
    return optionsval;
  };

  filterbyhour = value => {
    this.setState({
      filterHour: value
    });
    this.filterByHours(value);
  };

  getSortedRecords = response => {
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.user_id === p1.user_id) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;
  };

  getMonthlyReportTriger = (year, month) =>
  {
    this.setState({
        year:year,
        month:month
    });
    const _this = this;
    setTimeout(() => {
        _this.getMonthlyReport(); 
    }, 100); 
  }
  getMonthlyReport = () => {
    this.setState({
        orginalEntries: [],
        showProgress:true
    })
    let opts = {
      year: this.state.year,
      month: this.state.month,
    };
    fetch(urls.getMonthlyReportLog, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
      .then(response => response.json())
      .then(response => {
        if(response.length>0)
        {
          this.sortRecords(response, 'total_time');
        }
        this.setState({
         showProgress: false
        });
      })
      .catch(error => {
        this.setState({ showProgress: false });
      });
  };

  sortRecords(records, column) {
    let temp = records;
    temp.sort(descOrder);

    this.setState({
      orginalEntries: temp
    });

    function descOrder(a, b) {
      return b.total_time - a.total_time;
    }
    
    function ascOrder(a, b) {
      return a.total_time - b.total_time;
    }
}

  timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
    }


 searchEntriesProjectDetails = (project) =>
  {   
   if(this.state.project_id != project)
   {
    this.setState({
        detailEntries:[],
        showDetailProgress:true,
        showTaskPeopleId: 0,
        showTaskPeople: false
      });
      let opts = {
        project_id:project,
        year:this.state.year,
        month:this.state.month,
        designer:''
      };
      this.setState({
         DetailView:false,
         project_id:project
      });
      
      fetch(urls.postBaseProjectReportsForLogs,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {
        console.log(response);
        this.setState({
          detailEntries:response['results'],
          DetailView:true,
          showDetailProgress:false
         });
      })
      .catch(error =>{
        this.setState({showDetailProgress:false});
      })
    }else
    {
        this.setState({
            DetailView:false,
            project_id:''
         });
    }
  }

  showTaskPeople = (id)=>
  {
      if(id === this.state.showTaskPeopleId)
      {
          this.setState({
              showTaskPeopleId: id,
              showTaskPeople: !this.state.showTaskPeople
          })
      }else
      {
        this.setState({
            showTaskPeopleId: id,
            showTaskPeople: true
        })
      }
  }

  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    //let overTime = (this.state.overTime / 60).toFixed(2);
    return (

<>
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
      <Row>
        <div className="theme_header">
          <Header {...this.props} 
            classname="tick-timer-header"
            logout={this.signOut.bind(this)}
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={true}
          />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Project Work Log</h1>
                <main id="reportMonthly">
          <ProjectLogFilter
            searchEntriesone={this.getMonthlyReportTriger}
          />
          <div id="list-head" className="project-work-log">
            <Container>
              <Row>
                <Col sm="12">
                  <div className="list-head-cont d-flex">
                    <p className="project-name">Project Name</p>
                    <p className="log-total client-time">Client Time</p>
                    <p className="log-total practice-time">Practice Time</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col sm="12">
                  {/* {this.state.showProgress ? <LoadingBar /> : null} */}
                  <div id="date-list-contianer" className="project-work-log">
                 
                      <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={list}
                      >
                          {this.state.orginalEntries.map((entries, index) => {
                            return (
                              <motion.div variants={item}>
                                <Row>
                                  <Col sm="12">
                                    <div>
                                      <Card className="d-flex" onClick={this.searchEntriesProjectDetails.bind(this, entries.project_id)}>
                                        <p className="project-name">{entries.project_name}</p>
                                        <p className="log-total client-time">{this.timeConvert(entries.total_time)}</p>
                                        <p className="log-total">{entries.total_time_training ? this.timeConvert(entries.total_time_training): 0}</p>
                                       </Card> 
                                       {this.state.detailEntries.length>0 && this.state.project_id === entries.project_id  &&
                                        (   
                                            <>
                                                <Row>
                                                    <Col sm="12">
                                                        <div className="list-head-cont d-flex">
                                                        <p className="name task-name-head">Task Name</p>
                                                        <p className="time-entered">Total Time</p>                   
                                                        {/*<p className="projects text-left" title="Tasks count except Kimp Timers and GR Tasks" >Pending Tasks</p> */ }
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {this.state.detailEntries?
                                                    (
                                                    <>
                                                        { this.state.detailEntries.map( ( entries, index ) => {
                                                    return (
                                                    <motion.div variants={item}>
                                                        <div className=''>
                                                            <Card
                                                            className="d-flex project-task-items" onClick={this.showTaskPeople.bind(this, entries.task_id)} >
                                                            <p className="name task-name">{entries.task_name}</p>
                                                            <div className="mb-scroll-x">
                                                                <div className="d-flex inner-width">
                                                                <p className="time-entered">{entries.task_total}</p>                                
                                                                {/*<p className="total-rate text-center">{entries.total_pending_tasks}</p> */ }
                                                                </div>
                                                            </div>
                                                            </Card >   
                                                            {this.state.showTaskPeople && this.state.showTaskPeopleId === entries.task_id &&
                                                            (
                                                                <>
                                                                    {entries.items.map((people, index)=>
                                                                    (
                                                                        <Card className="d-flex task-people-items" key={index} >
                                                                            <p className="name task-name">{people.name}</p>
                                                                            <div className="mb-scroll-x">
                                                                                <div className="d-flex inner-width">
                                                                                    <p className="time-entered">{people.user_total}</p>                                
                                                                                </div>
                                                                            </div>
                                                                        </Card> 
                                                                    ))}
                                                                    
                                                                </>
                                                            )}
                                                                                
                                                            </div>
                                                    </motion.div>
                                                    )
                                                })
                                                }
                                                    </>
                                                    )
                                                    :
                                                    null
                                                }
                                            </>
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                                
                              </motion.div>
                            );
                          })}
                      </motion.section>
             
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </main>
              </Card>
            </Col>
        </Row>
        </div>
      </Row>

</Container>
<LoadingWrapper 
LoaderCount={5}
isLoading={this.state.showProgress}> 
</LoadingWrapper>
</>
    );
  }
}
