import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { Typeahead } from 'react-bootstrap-typeahead';

export default class MoveTodo extends Component {
  constructor(props) {
    super(props);
    this.state = {
        progressBar:false,
        progressBarList:false,
        listID:0,
        groupId:0
    };
  }

  componentDidMount = () => {
    console.log(this.props.tasklistgroup);
  };

  
  moveTaskBetween = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      task_id: this.props.taskid,
      list_id: this.state.listID,
      group_id: this.state.groupId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.moveTaskBetween, data, {
      headers: headers
    })
    .then((response) => {
        this.props.onmoveupdate();
    })
    .catch((error) => {
    this.setState({
        progressBar: false
    })
    })
  };

  onSelectItems = (listid, groupid)=>
  {
    this.setState({
        groupId:groupid,
        listID:listid
    })
  }
  render() {
    return (
      <Fragment>
      <Container fluid className="top-bar-inactive bc_module_container group_copy_wrap">
       <div className="">
       <LoadingWrapper isLoading={this.state.progressBar}>
            {this.props.tasklistgroup && this.props.tasklistgroup.length>0 &&
            (
                <Row className="move_todo_content">
                    {this.props.tasklistgroup.map((listitem, index)=>
                    {
                        return(
                            <Col sm="6">
                                <div className='copy-list-board' key={index}>
                                    <span onClick={this.onSelectItems.bind(this,listitem.id,0)} className={this.state.listID === listitem.id && this.state.groupId===0 && 'activecopyid'}>{listitem.list_name}</span>
                                    {listitem.group && listitem.group.length>0 &&
                                    (
                                        <ul className='copy-group-list'>
                                            {listitem.group.map((listgroup, gindex)=>
                                            {
                                                return(
                                                    <li key={gindex} onClick={this.onSelectItems.bind(this,listitem.id, listgroup.id)}
                                                    className={this.state.listID === listitem.id && this.state.groupId=== listgroup.id && 'activecopyid'} >
                                                        {listgroup.group_name}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    )}
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            )}

            <div className='d-flex align-items-center justify-content-center save-copy-list'>
                <Button name="Save" className='btn btn-green' onClick={this.moveTaskBetween}>Move</Button>
            </div>
        </LoadingWrapper>  
       </div>      
      </Container>
    </Fragment>
    )
  }
}
