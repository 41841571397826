import React, { useState, useCallback } from 'react'
import {urls} from "../../../services/UrlList";
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import {Progress } from 'reactstrap';
import { useEffect } from 'react';

export default function KFileUploadProgress(props)
{
    const [files, setFiles] = useState([])
    const [uploadCount, setuploadCount] = useState(0);
    const [uploadingProgress, setuploadingProgress] = useState(false);
    const [uploadedCount, setuploadedCount] = useState(0);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const [extensionError, setExtensionError] = useState(false);


    const fileTypes = ["ai", "bmp", "gif",'ico','jpg','png','ps','psd','svg','tif','tiff','jpeg','webp','eps','jfif','heic','heif',
                  'fnt','fon','otf','ttf','epub', 'indd',
                  'zip', 'fig','mp4','xd','mov',
                  'aif', 'cda','mid','midi','mp3','mpa','ogg','wav','wma','wpl',
                  'doc', 'docx','odt','pdf','rtf','txt','wpd',
                  'ods','xls','xlsm','xlsx',
                  'key','odp','pps','ppt','pptx', 'raw','webm'];
    
    useEffect(()=>{
        setTimeout(() => {
            if(uploadPercentage === 100)
            setUploadPercentage(0)
        }, 1000);
    },[uploadPercentage]);

   const  handleChangeFile1 = (files)  => {
      setExtensionError(false);
      let fextensions = props.folderext.length> 2 ? props.folderext.split(',') : [];
      fextensions.push('zip');
      for (let i = 0; i < files.length; i++) {
        if((props.foldertype== 1 || props.foldertype== 2) && fextensions.length>1 && !fextensions.includes(files[i].name.split('.').pop().toLowerCase()))
        {
          setExtensionError(true);
          return false;
        }
      }

   

      setuploadCount(files.length);
      setuploadingProgress(true);
      
      const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-origin':'*',
     }
     let fileupprogress = [];
      for (let i = 0; i < files.length; i++) {

     let options = {
        onUploadProgress: (progressEvent) => {
            const {loaded, total} = progressEvent;
            let percent = Math.floor( (loaded * 100) / total )
           // console.log( `${loaded}kb of ${total}kb | ${percent}%` );
            if( percent <= 100 ){
              fileupprogress[i] = percent;
              props.filesprogresscount(fileupprogress);
              //console.log(i,percent);
                setUploadPercentage(percent);
            }
        }   
        }
       const data = new FormData();
       data.append('file', files[i]);
       data.append('filename', files[i].name);
       data.append('filesize', files[i].size);
       data.append('filetype', files[i].type);
       data.append('folder_path', props.folderpath);
       data.append('revision_id', props.revisionid);
       data.append('folder_id', props.folderid);
       data.append('task_id', props.taskid);
      axios.post(urls.uploadTaskSource, data, options, { 
         headers: headers
       })
       .then(res => {
        setUploadPercentage( 100 );
        setuploadedCount(i+1);
        props.storefiles();

        if(files.length == i+1)
        {
         setuploadCount(0);
         setuploadingProgress(false);
         setuploadedCount(0);
         setUploadPercentage([]);
         props.filesprogresscount([]);
        }
       })

       
     }
   };

   const TypeError =()=>
   {
    setExtensionError(true);
   }


   const  handleChangeFile = async (files)  => {
    let data={
      folder_path: props.folderpath,
      revision_id: props.revisionid,
      folder_id: props.folderid,
      task_id: props.taskid
    }  
    props.uploadingtriger(files, data);
  };

    return(
        <>
          {extensionError && (<div className='upload-ext-error'>{`Uploading Failed. You can upload only ${props.folderext ? props.folderext.toLowerCase() +" and " : ''}zip files here. Kindly review. `}</div>)}
          <div className="attchfiles_wrap">
            <FileUploader 
            handleChange={handleChangeFile} 
            name="files" 
            types={fileTypes}
            multiple={true} 
            chunkUploads ={true}
            chunkSize = {1024 * 1024 * 10} // 10MB
            label={'Upload or drop files right here.'}
            onTypeError={TypeError}
            fileOrFiles={null}
            />
            
            {(uploadingProgress) &&
            (
              <div className="upload-progress">
                Please wait, uploading in progress ({uploadedCount+"/"+uploadCount})
                <div className='uploading-bar'>
                  <Progress striped  color="success" value={uploadPercentage} />
                </div>
              </div>
            )} 
          </div>
      </>
    )
}