import React, { Component } from 'react'
import "./AddNewFeedForm.css"
import {Input, Label, Form, FormGroup, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
// import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default class AddNewFeedForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      addNewFeedTitle: '',
      titleContentRequired: false,
      // addNewFeedBody: '',
      addNewFeedBody: '',
      bodyContentRequired: false,
      imgUrl: '',
      imgName: '',
      url: '',
      fileData: '',
      addNewFeedPossition: '3',
    }
    this.fileInput = React.createRef();
    this.titleInput = React.createRef();
  }

  formSubmit = (e) => {
    e.preventDefault()
    
    // if( this.state.addNewFeedTitle!='' ) {
    //   this.setState({titleContentRequired: true})
    //   return;
    // } else {
    //   this.setState({titleContentRequired: false})
    // }

    // if( this.state.addNewFeedBody !='') {
    //   this.setState({bodyContentRequired: true})
    //   return;
    // } else {
    //   this.setState({bodyContentRequired: false})
    // }

    let formdata = new FormData();
    formdata.append('title', this.state.addNewFeedTitle)
    formdata.append('body', this.state.addNewFeedBody)
    formdata.append('image', this.state.fileData)
    formdata.append('position', this.state.position)

    // Submit Data
    this.props.postNewFeed({
      'feed_title': this.state.addNewFeedTitle,
      'feed_body' : this.state.addNewFeedBody,
      'feed_image' : this.state.fileData,
      'feed_image_name' :this.state.imgName,     
      'feed_image_position' : this.state.addNewFeedPossition,
      'user_id': this.props.myid

    })
  }

  onCancelForm = () => {
    this.setState({
      addNewFeedTitle: '',
      titleContentRequired: false,
      addNewFeedBody: '',
      bodyContentRequired: false,
      imgUrl: '',
      imgName: '',
      url: '',
      fileData: '',
      addNewFeedPossition: 'bottom',
    });
    this.props.onCancelForm();
  }

  // onSubmitForm = () => {

  // }

  // onFileSelect = (e) => {
  //   const regex = /(image\/)/g;
  //   let file = this.fileInput.current.files[0];
  //   var fileName = this.fileInput.current.files[0].name;
  //   let fileType = this.fileInput.current.files[0].type;
  //   if( fileName.length > 25 ) {
  //     console.log("File:", fileName);
  //     let s = [fileName.slice(0, 25)]
  //     let a = Array(8).fill('.')
  //     fileName = s.concat(a);
  //     fileName.push( fileType.replace(regex, '') )
  //   }    
  //   this.setState({
  //     fileData: file,
  //     imgName: fileName,
  //     imgUrl: URL.createObjectURL(this.fileInput.current.files[0])
  //   })
  //   console.log(this.fileInput.current.files[0])


  //   this.setState({fileData: e.target.files[0]})

  // }


    onFileSelect(e) {
      const regex = /(image\/)/g;
      let file = this.fileInput.current.files[0];
      var fileName = this.fileInput.current.files[0].name;
      let fileType = this.fileInput.current.files[0].type;
      if( fileName.length > 25 ) {
        console.log("File:", fileName);
        let s = [fileName.slice(0, 25)]
        let a = Array(8).fill('.')
        fileName = s.concat(a);
        fileName.push( fileType.replace(regex, '') )
      }    
      this.setState({
        fileData: file,
        imgName: fileName,
        imgUrl: URL.createObjectURL(this.fileInput.current.files[0])
      })

    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;
    this.createImage(files[0]);
    }

    createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
    this.setState({
      fileData: e.target.result
    })
    };
    reader.readAsDataURL(file);
    }






  removeImg = () => {
    this.setState({
      imgUrl: '',
      imgName: ''
    })
  }

  handleFormData = (e) => {
    let field = e.target;
    // if( field.type === 'textarea' && field.value!='' ) {
    //   this.setState({bodyContentRequired: true})
    // } else {
    //   this.setState({ bodyContentRequired: false })
    // }

    // if( field.type === 'text' && field.value!='' ) {
    //   this.setState({titleContentRequired: true})

    // } else {
    //   this.setState({ titleContentRequired: false })
    // }

    this.setState({[field.name]: field.value})
  }

  onContentStateChange = contentState => {
   this.setState({ addNewFeedBody: draftToHtml(contentState)});
  }
  
  render() {
    const { 
      addNewFeedTitle, 
      addNewFeedBody, 
      imgUrl, 
      imgName, 
      addNewFeedPossition, 
      bodyContentRequired,
      titleContentRequired } = this.state;

    let display = this.props.display ? 'show' : 'hide';
    let titleCls = titleContentRequired && "error";
    let bodyCls = bodyContentRequired && "error";

    let toolbarOptions = {
      editorClassName: 'dh-',
      options: ['inline', 'list', 'textAlign', 'link', 'blockType','fontSize',],
      inline: {
        options: ['bold', 'italic', 'underline',]
      },
      blockType: {
        inDropdown: true,
        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      },
      fontSize: {
        options: [14, 16, 18, 24, 30, 36, 48, 60],
      }
    };

    return (
      <div id="addNewFeedFormWrapper" className={display} data-state={display} >
        <Form onSubmit={this.formSubmit} enctype="multipart/form-data">
          <FormGroup>
            <Label for="addNewFeedTitle">Title</Label>
            <Input 
              className={titleCls}
              type="text" 
              name="addNewFeedTitle" 
              id="addNewFeedTitle" 
              value={addNewFeedTitle} 
              onChange={this.handleFormData}
              ref={this.titleInput} />
          </FormGroup>
          {/* <FormGroup>
            <Label for="addNewFeedBody">Body</Label>
            <Input 
              className={bodyCls}
              row="10" 
              type="textarea" 
              name="addNewFeedBody" 
              id="addNewFeedBody" 
              value={addNewFeedBody} 
              onChange={this.handleFormData} />
          </FormGroup> */}
          <FormGroup>
            <Label for="addNewFeedTitle">Body</Label>
            <Editor 
              onContentStateChange={this.onContentStateChange}
              toolbar={toolbarOptions} />
          </FormGroup>
          <div className="d-flex">
            <FormGroup className="feedImg">
              <p>Add Image</p>
              <Label 
                for="addNewFeedImg"
                className="form-control" >{imgName ? imgName : "Add Image here"}</Label>
              <input 
                type="file" 
                name="addNewFeedImg"
                id="addNewFeedImg"
                placeholder=""
                hidden
                onChange={this.onFileSelect.bind(this)} 
                ref={this.fileInput} />
                <div className="feedImgPreview">
                  {
                  imgUrl &&
                    (
                      <>
                      <span style={{ background: 'url('+imgUrl+') no-repeat top left'}}></span>
                      <FontAwesomeIcon onClick={this.removeImg} icon={faTimes} />
                      </>
                    )
                  }
                </div>
            </FormGroup>
            <FormGroup className="feedImgPosition ml-auto">
              <Label for="addNewFeedPossition">Select Position</Label>
              <Input 
                name="addNewFeedPossition" 
                id="addNewFeedPossition" 
                type="select" 
                value={addNewFeedPossition} 
                onChange={this.handleFormData}>
                <option value="3">Bottom (Default)</option>
                <option value="1">Top</option>
                <option value="2">Right</option>
                <option value="4">Left</option>
                <option value="5">As Background</option>
              </Input>
            </FormGroup>
          </div>
          <div className="d-flex newFeedPostBtnWrapper">
            <Button className="btn btn-gray cancel_btn_feed" onClick={this.onCancelForm}>Cancel</Button>
            <Button type="submit" className="btn btn-green add_btn" onClick={this.onSubmitForm}>Add</Button>
          </div>
        </Form>
      </div>
    )
  }

}
