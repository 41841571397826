import React, { Component} from 'react';
import trelloColor from "../../images/trelloColor.png";
import trelloWhite from "../../images/trelloWhite.png";
import { Link } from "react-router-dom";

export default class TrelloIconURL extends Component{
  constructor( props ) {
    super( props );
    this.state = {      
    };
  }
  render() { 
  const classes = "basecamp-icon " + this.props.classname;
  let trelloIcon = this.props.active ? trelloWhite : trelloColor;
  return (
    <div className={classes}>        
           <a href={this.props.projecturl} target="_blank">
                <div style={{
                width: "40px",
                height: "40px",
                background: `url(${trelloIcon}) no-repeat center`,
                marginRight: '19px',
                backgroundSize: '100% auto'
                }}
                >                
                </div>
            </a>
       
    </div>
  )
}
}