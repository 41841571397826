import React from 'react';
import LoadingBox from '../LoadingBoxSmall/LoadingBoxSmall'

export default function LoadingWrapper(props) {
  
  let LoaderCount = <LoadingBox />;
  let c = 'empty';
  
  if(props.LoaderCount > 0) {
    let LoaderCount = new Array(props.LoaderCount).fill(<LoadingBox />);
    c = LoaderCount.map( x => x )
  }

  return (
    <div id="LoadingWrapper">
      { props.isLoading 
        ? ( LoaderCount.length > 0 
            ? {c}
            : LoaderCount )
        : props.children }
    </div>
  )
}
