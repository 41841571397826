import React, { Component } from 'react';
import './QFilter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import { Typeahead } from 'react-bootstrap-typeahead';
import DateTimePicker from 'react-datetime-picker';
import {urls} from "../../services/UrlList";
import CalanderIcon from '../../images/date-icon.png';

export default class Filter extends Component {
  constructor( props ) {
        super( props );
        var d = new Date();
        d.setDate(d.getDate());
        this.state = {              
        selectedUser:'',
        sdate:d,
        myPrivileges:[],     
        orginalUsers:[],
        peopleError:false,
        };
        // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();  
    this.getAllUsers();   
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
};

onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}

searchQr = () =>
{ 
  if(this.state.selectedUser==='')
  {
    this.setState({peopleError:true});
    return false;
  }else
  {
    this.setState({peopleError:false});
    this.props.searchqr(this.state.sdate, this.state.selectedUser);
  }
  
}

getAllUsers = () =>{ 
    fetch(urls.getQrMembers,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
    })
    .then(response => response.json())
    .then(response => {      
      this.setState({
          orginalUsers: response.members,          
        });
    })
  };

  onChangePeople = (e) => {
    this.setState({ selectedUser:e[0].id}); 
  };
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].id});
    }else
    {
      this.setState({ selectedUser:''});
    }
  }

  onChange = (date) => {
    this.setState({ sdate:date});    
  };

  render() {
    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : 'Search Log';
    return (
      <div id="filter" className="Project_Work_Log">
        
            <div className="filter-row d-flex justify-content-between">
              <div className={this.state.peopleError? 'people-error fliterbox': 'fliterbox'}>
              <Row style={{ width:'100%' }}>
               <Col xs={12} md={3}>
              <div className="start-date date-col datepicker-col">
                <p>Date: </p>
                <DateTimePicker         
                format="dd-MM-y"
                onChange={this.onChange.bind(this)}
                value={this.state.sdate}
                disableClock="true"
                clearIcon
                calendarIcon={<img className="calander-icon" src={CalanderIcon} width="18" height="19" alt="calander-icon" />} />
              </div>
              </Col>
              <Col xs={12} md={3}>
              <div className="people">
              <p class="filter-label">User </p>
              <Typeahead
                id="user-picker"
                labelKey="name"
                onInputChange={this.handleInputChange}  
                onChange={this.handleChange}
                options={this.state.orginalUsers}
                placeholder="Choose user..."
                selected=''
             />
              </div>
              </Col>
              <Col xs={12} md={1}>
              <div className="search-btn">
                <Button className="mgTop20" color="success"  onClick={this.searchQr.bind(this)} >
                  { searchContent }                  
                </Button>
              </div>
              </Col>
            </Row>
              </div>
            </div>
        
      </div>
    )
  }
}