import React, {useState, useEffect, useRef, useCallback} from 'react';
import './basecampmodule.css'
import ReactTags from 'react-tag-autocomplete'
import axios from 'axios';
import { urls } from '../../services/UrlList';

export default function PeopleTag(props)
{   
    const [tagslist, setTagsList] = useState(props.tagslist);
    const [tags, setTags] = useState(props.tags);
    const [blockError, setBlockError] = useState(false);
    const [pastDueError, setPastDueError] = useState(false);
    const reactTags = useRef();

    const onDelete = useCallback((tagIndex) => {
       setBlockError(false);
        setTags(tags.filter((_, i) => i !== tagIndex))
      }, [tags])
    
    const onAddition = useCallback((newTag) => {
        setTags([...tags, newTag])
      }, [tags])

    useEffect(() => {
        if(props.type && props.type === 'issues')
        {
          props.settags(tags, props.issueitemid);
        }else
        {
          props.settags(tags);
        }
      },[tags]);

      function showSuggestions()
      {
        props.onfocus && props.onfocus();
      }

      const getBlocks = async (newTag)=>
      {
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin': '*',
        }
       await axios.get(urls.getBlockedMembers,{
          headers: headers
        })
        .then((response) => {
           var itemkey = response.data.members.map(function (item) { return parseInt(item.id); }).indexOf(newTag.id); // 
           if(itemkey === -1)
           {
            setTags([...tags, newTag])
           }else
           {
              setBlockError(true);
           }
        })
        .catch((error) => {
           return;
        })
      }

      function TagComponent({ tag, removeButtonText, onDelete }) {
        return (
          <div className={`react-tags__selected ${tag.name}`} aria-relevant="additions removals" aria-live="polite">
            <button type="button" onClick={onDelete}  class="react-tags__selected-tag" title="Click to remove tag">
              <span class="react-tags__selected-tag-name">{tag.name}</span>
            </button>
          </div>
        )
      }

    return (
      <>
        {blockError &&(
          <div className='blocked-msg'>You can't assign client cards to this person.</div>)
        }
        {pastDueError &&(
          <div className='blocked-msg'>You can't reassign unless you give a future time for due date.</div>
        )}
        <ReactTags  className="text_box" 
        ref={reactTags}
        tags={tags}
        suggestions={tagslist}
        onDelete={onDelete}
        onAddition={onAddition}
        placeholderText={props.placeholder}
        onFocus={showSuggestions}
        tagComponent={TagComponent} />
       </>
    );
}