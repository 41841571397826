import React, { Component, Fragment } from "react";
import "./AlertMessage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-solid-svg-icons";

export default class AlertMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertMesage: this.props.showAlertMesage
    };
  }

  onActionResolve = () =>
  {
    this.props.action();
  }

  render() {

    let severity = this.props.severity === 1? ' alert-red' : (this.props.severity === 2 ? ' alert-dr-orange' : ' alert-orange');
    let smileyType = this.props.severity === 1 || this.props.severity === 2 ? faExclamationTriangle : faSmile;
    return (
      <div id="alert-message" className={"d-flex " +severity}>
        <div className="alert-type d-flex">
          <FontAwesomeIcon icon={smileyType} />
          <p className="text-upper">
            <span>{this.props.type}</span>
          </p>
        </div>
        <div className="alert-message d-flex">
          <p>
            {this.props.msg}
          </p>
          <FontAwesomeIcon onClick={this.onActionResolve.bind(this)} className="ml-auto" icon={faTimesCircle}  />
        </div>
      </div>
    );
  }
}
