import React from "react";
import {
  Col,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Row
} from "reactstrap";

import LoadingBar from "../common/LoadingBar";
import { urls } from "../../services/UrlList";

class ConfirmDialogue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress: false
    };
  }

  handleResolveProcess = (type) =>
  {
      this.setState({
        showProgress:true,
        progressBar: true
      });
      var login = JSON.parse( localStorage.getItem("user") );
      let opts = 
      {
          entryid:this.props.resolveid,
          status:this.props.resolveStatus,
          adminid:login.uid
      };

      console.log(opts);
      fetch(urls.resolveComment, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {
          this.props.handleResolveConfirm();
          
        });

  };

  handleCompletionProcess = () =>
  {
    this.props.handleCompletionConfirm();
  }

  handleTaskDeletion = () =>
  {
    this.props.handledeletion();
  }

  handleProjectDeletion = () =>
  {
    this.props.handledeletion();
  }

  handleAssetDeletion = () =>
  {
    this.props.handledeletion();
  }

  handleBrandDeletion = () =>
  {
    this.props.handledeletion();
  }

  handleBrandNoteDeletion = () =>
  {
    this.props.handledeletion();
  }
  
  handleFolderDeletion = () =>
  {
    this.props.handledeletion();
  }
  handleDuplicateCreation = ()=>
  {
    this.props.handleduplication();
  }

  handlecardmovement = () =>
  {
    this.props.handlecardmovement();
  }

  render() {
    return (
      <div>
        <Modal className="modal-dialog-centered confirm_modal" isOpen={this.props.show} toggle={this.props.closeDialogue}>
          <ModalHeader toggle={this.props.closeDialogue}>
            Confirm Action
          </ModalHeader>
          <ModalBody>
            <div>
              {this.state.showProgress ? <LoadingBar /> : null}
              <h6>{this.props.content}</h6>
              <p>{this.props.note}</p>
              <FormGroup className="mob_btn_action">
              <div className="button_group">
              <Button
                    className="cancel-button"
                    onClick={this.props.closeDialogue}
                  >
                    Cancel
                  </Button>
                  {this.props.confirmBoxAction == "resolvecomment" ?
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleResolveProcess.bind(this)}
                      >
                        Confirm
                      </Button>
                    ): null
                  } 
                  {this.props.confirmBoxAction == "markascomplete" ?
                    (
                      <Button
                        className="submit-btn btn-green"
                        onClick={this.handleCompletionProcess.bind(this)}
                      >
                        Confirm
                      </Button>
                    ): null
                  } 
                  {this.props.confirmBoxAction == "withdrawleave" ?
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleCompletionProcess.bind(this)}
                      >
                        Confirm
                      </Button>
                    ): null
                  }       
                  {this.props.confirmBoxAction == "markasdeleted" ?
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleCompletionProcess.bind(this)}
                      >
                        Confirm
                      </Button>
                    ): null
                    } 

                    {this.props.confirmBoxAction == "deletetask" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleTaskDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )} 

                    {this.props.confirmBoxAction == "deleteproject" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleProjectDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )}  

                    {this.props.confirmBoxAction == "deleteasset" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleAssetDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "deletebrand" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleBrandDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "deletbrandnote" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleBrandNoteDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )} 

                    {this.props.confirmBoxAction == "deletefolder" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleFolderDeletion.bind(this)}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "duplicatetask" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.handleDuplicateCreation.bind(this)}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "downloadassets" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handledownload}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "deletelist" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handledeletion}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "deletegroup" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handledeletion}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "moveclientcard" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handlecardmovement}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "createrevision" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handlecreation}>
                        Confirm
                      </Button>
                    )} 
                    {this.props.confirmBoxAction == "deleteversion" &&
                    (
                      <Button
                        className="submit-btn"
                        onClick={this.props.handleversiondeletion}>
                        Confirm
                      </Button>
                    )} 
                     {this.props.confirmBoxAction == "deleteversionfolder" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handlevfolderdeletion}>
                          Confirm
                        </Button>
                      )} 
                      {this.props.confirmBoxAction == "deleteversionfile" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handlevfiledeletion}>
                          Confirm
                        </Button>
                      )}
                      {this.props.confirmBoxAction == "rejectversionfile" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handlevfilerejection}>
                          Confirm
                        </Button>
                      )} 

                      {this.props.confirmBoxAction == "massapprovefiles" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handlevfileapproval}>
                          Confirm
                        </Button>
                      )} 

                      {this.props.confirmBoxAction == "endshift" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handleendshift}>
                          Confirm
                        </Button>
                      )} 
                      {this.props.confirmBoxAction == "unapprovefileslist" &&
                      (
                        <Button
                          className="submit-btn"
                          onClick={this.props.handlevfileunapproval}>
                          Confirm
                        </Button>
                      )} 
              </div>
                  
               
              </FormGroup>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDialogue;
