import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import './activeRunningStyle.css'
import {urls} from "../../services/UrlList";
import GreenBell from "../svgicon/GreenBell";
import RedBell from "../svgicon/RedBell";
import RefreshIcon from "../svgicon/RefreshIcon";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Label, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import BasecampIconImg from '../svgicon/BasecampIconImg';
import TrelloIcon from '../svgicon/TrelloIcon';
import axios from 'axios';
import Pmtimer from '../bcmodule/Tasks/Pmtimer';
import SearchIcon from "../svgicon/SearchIcon";
import StopSearch from "../../images/stop-search.png";
import MaterialIcon from "material-icons-react";

export default class TaskAssignedSummary extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      initialLoader:false,
      actionStatus:false,
      peopleGroup:[],
      showOnebyOneProgress:false,
      stopRunningSearch:false,
      usersCount:0,
      fullEntries: [], 
      orginalEntries:[],
      empType:'0'
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "KIMPHUB - Assigned Task Summary";
    this.checkUserPrivilege(); 
    this.findPropleGroups();
  };

  componentDidUpdate(prevState) {  
    if (prevState.actionStatus !== this.state.actionStatus  && this.state.actionStatus === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({actionStatus:false})
      }, 6000);  
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }
  };
  startSearch = () =>
  {
    const _this = this;
    this.setState({
        showOnebyOneProgress:true,
        fullEntries:[],
        orginalEntries:[],
        empType:0
    })
    setTimeout(()=>{
        var lcount =   _this.loopThroughUsers(0);
      }, 100);
  }

  searchEntriesOnebyOne = async (id, name, emptype) =>
  {   
    const data = {
        user_id: id,
        username: name,
        emptype: emptype
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.findTaskAssignedCount, data, {
          headers: headers
        })
        .then((response) => {
             this.setState({
                fullEntries: this.state.fullEntries.concat(response.data), 
                orginalEntries:this.state.orginalEntries.concat(response.data),
              })
        })
        .catch((error) => {
          this.setState({
             initialLoader:false,
             showOnebyOneProgress:false
          })
        })
  }

  stopActiveSearch = () =>
  {
    this.setState({
      stopRunningSearch:true,
      showOnebyOneProgress:false
    })
  }

  loopThroughUsers = async (count) =>
  {
    var lcount = count;
    const _this = this;
    this.setState({
      stopRunningSearch:false
    })
    setTimeout(()=>{
      if(lcount === _this.state.usersCount || this.state.stopRunningSearch)
      {
        this.setState({
          showOnebyOneProgress:false,
          stopRunningSearch:false
        })
        return;
      }
      _this.searchEntriesOnebyOne(_this.state.peopleGroup[lcount].id, _this.state.peopleGroup[lcount].name, _this.state.peopleGroup[lcount].EmpType);
      lcount++;
      _this.loopThroughUsers(lcount);
    }, 300);
  }

  findPropleGroups = () =>
  {
    const data = {
      user_id: this.state.myId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getTaskAssigneeGroup, data, {
        headers: headers
      })
      .then((response) => {
           console.log(response.data.members);
           this.setState({
            peopleGroup: response.data.members,
            usersCount: response.data.members.length
            })
      })
      .catch((error) => {
        this.setState({
           initialLoader:false
        })
      })
  }

filterList = (e) =>
{
  this.setState({
    empType:e.target.value
  });
  let temp = this.state.orginalEntries;
  let list=[];
  if(e.target.value>0)
  {
    temp.forEach(p1 => {
      if(p1.emptype === e.target.value)
      {
        list.push(p1);
      }
    }); 
    this.setState({fullEntries:list});
  }else
  {
    this.setState({fullEntries:this.state.orginalEntries});
  }
}

 
  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
    }, 100);
    }else
    {
      this.setState({showSingle:false});
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
       <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="project" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report">
               <div className="report_header">
                <Row>
                  <Col xs={12} sm={6}> <h1 class="her_title">Task Assigned Summary</h1></Col>
                  <Col xs={12} sm={6}> 
                  <div className="data_search_card">
                  {this.state.peopleGroup && this.state.peopleGroup.length>0 &&
                (
                    <>
                    <Col sm="5">
                        <Typeahead
                        id="id"
                        labelKey="name"
                        onInputChange={this.handleInputChange}
                        onChange={this.onChangePeople}
                        options={this.state.peopleGroup}
                        placeholder="Choose user or search all..."
                        selected=''
                        />
                    </Col>
                    <Col sm="1">
                        <div className="search-btn d-flex align-items-center">
                            {(this.state.showOnebyOneProgress) ?
                            (
                                <img title="stop currently running search" src={StopSearch} className="stop-active-search" onClick={this.stopActiveSearch.bind(this)} />
                            ):
                            (<Button className="search_btn" color="success"  onClick={this.startSearch.bind(this)} >
                                <SearchIcon />                 
                            </Button>
                            )}
                        </div>
                    </Col>
                  </>
                )}
                 </div>
                  </Col>
                </Row>
                
           </div>
           <div className="redio_section_box">
           {(!this.state.showOnebyOneProgress && (this.state.orginalEntries && this.state.orginalEntries.length>0)) &&
                (
                    <Col sm="6">
                        <div className="emp-type-filter d-flex justify-content-between reminder-options custom_radio">
                            <div className="d-flex">
                                    <Input name="filterList" id="all" type="radio" value="0"
                                    checked={this.state.empType == '0' && 'checked'}
                                    onClick={this.filterList.bind(this)} />
                                    <Label for="all">All</Label>
                            </div>
                            <div className="d-flex">
                                <Input name="filterList" id="graphics" type="radio" value="2"
                                 checked={this.state.empType === '2' && 'checked'}
                                 onClick={this.filterList.bind(this)} />
                                <Label for="graphics">Graphic Designer</Label>
                            </div>
                            <div className="d-flex">
                                <Input name="filterList" id="illustrator" type="radio" value="3"  
                                checked={this.state.empType == '3' && 'checked'}
                                onClick={this.filterList.bind(this)}  />
                                <Label for="illustrator">Illustrator</Label>
                            </div>
                            <div className="d-flex">
                                <Input name="filterList" id="motion" type="radio" value="1" 
                                checked={this.state.empType === '1' && 'checked'} 
                                onClick={this.filterList.bind(this)} />
                                <Label for="motion">Motion Graphics</Label>
                            </div>
                        </div>
                    </Col>
                )}
                
           </div>
           <LoadingWrapper isLoading={this.state.initialLoader}>
          <div id="list-head" className="trello-boards ">
         

            <Table responsive className="theme_table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Overdue</th>
                    <th>Today</th>
                    <th>Tomorrow</th>
                  </tr>
                </thead>
              


   <tbody>
     
    
     {(this.state.fullEntries && this.state.fullEntries.length>0) &&
        (
            <>
            {this.state.fullEntries.map((item, index)=>{ 
                return(
                  <tr>
                  <td>{item.username}</td>
                  <td>{item.overdue}</td>
                  <td>{item.today}</td>
                  <td className="text-right">{item.tomorrow}</td>
                  </tr>
                  )
                })}
                  </>
            )}
       

   </tbody>
                
              </Table>                
          
          </div>
        </LoadingWrapper>


            </Card>
            </Col>
          </Row>
        </div>

      </Row>
</Container>
    )
  }
}