import React, { useState, useEffect } from "react";
import ConfirmAction from "../admincomment/ConfirmAction";
import axios from "axios";
import Hashids from 'hashids';
import toast, { Toaster } from 'react-hot-toast';
import {
  Input,
  Button,
} from "reactstrap";
import { urls } from "../../services/UrlList";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';

export default function ConnectionWith360(props) {
    const [cardId360, setCardId360] = useState('');
    const [taskUpdateProgress, setTaskUpdateProgress] = useState(false);
    const [cardUpdateProgress, setCardUpdateProgress] = useState(false);
    const [data360, setData360] = useState([]);
    const [project360, setProject360] = useState('');
    const [task360, setTask360] = useState('');
    const [enableUpdate, setEnableUpdate] = useState(false);
    const [task360Data, setTask360Data] = useState(props.taskDetails.task_360_id!=null ? JSON.parse(props.taskDetails.task_360_data):'')
    const [project360Id, setproject360Id] = useState(null);
    const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );
    const [linkedCardData, setLinkedCardData] = useState('');
    
    const findProjectId = (id)=>
    {
      const projectId = hashids.encode(id);
      if(projectId && projectId.length>0)
      {
        return projectId;
      }

    }

    const decodeValueandPost = async ()=>
    {
        const actualId = hashids.decode(cardId360);
        if(actualId && actualId.length>0)
        {
          var data360 = await axios.get(`${urls.domain360}api/v1/task/${actualId[0]}`);
         if(data360?.data.status)
         {
          setData360(data360.data.data);
          setProject360(data360.data.data.project_name);
          setTask360(data360.data.data.task_name);
          setEnableUpdate(true);
         }
        }
    }

   const task360CardUpdate = async () => {
    setCardUpdateProgress(true);
    const data = {
        project_id: props.bid,
        task_id: props.task_id,
        tid: props.tid,
        creator: props.creator,
        cardId: cardId360,
        cardTitle:task360,
        cardProject: project360,
        cardData: data360
      };
      setLinkedCardData('');
      var result = await axios.post(urls.update360CardInfo, data);
      if(result && result.data.success ==1)
      {
        setCardId360('');
        setProject360('');
        setData360([]);
        setEnableUpdate(false);
        props.updateCardInfo(data.cardId, data.cardData);
        toast('360 card information successfully updated.');
      }else if(result && result.data.success ==3)
      {
        setLinkedCardData(result.data.card_added_on);
        toast('Sorry this card is already linked with another card on kimphub.');
      }else
      {
        setCardId360('');
        setProject360('');
        setData360([]);
        toast('Something went wrong, please try again.');
      }
      setCardUpdateProgress(false);
    }

    const clearRelation = async () => {
      setCardUpdateProgress(true);
      const data = {
          project_id: props.bid,
          task_id: props.task_id,
          tid: props.tid
        };
        var result = await axios.post(urls.clearCardRelation, data);
        setCardId360('');
        setProject360('');
        setData360([]);
        if(result && result.data.success == 1)
        {
          setEnableUpdate(false);
          props.updateCardInfo(null, null);
          toast('360 card relation successfully unlinked.');
        }else
        {
          toast('Something went wrong, please try again.');
        }
        setCardUpdateProgress(false);
      }

    const cancelUpdation = ()=>
    {
      setLinkedCardData('');
      setEnableUpdate(false);
      setCardId360('');
      setProject360('');
      setData360([]);
    }
  return (
    <>
      {(AccessCheckBlock('update_task_status') ) &&
        (
        <div className="card task_leftbox">
            <div>
            <LoadingWrapper isLoading={taskUpdateProgress}>
                <div className="d-flex justify-content-between">
                  <h6>360 Card Relation</h6>
                  {!enableUpdate &&
                  (
                    <span className="enable-360-add" onClick={(e)=>setEnableUpdate(true)}>
                      {props.taskDetails.task_360_id!=null ? 'Edit' : 'Add'}
                    </span>
                  )}
                </div>
                <LoadingWrapper isLoading={cardUpdateProgress}>
                {!enableUpdate && props.taskDetails && props.taskDetails.task_360_id!=null  &&
                (
                  <div className="card-360-linked">
                    <div className="d-flex"><span>Card Title:</span> {task360Data.task_name}</div>
                    <div className="d-flex justify-content-between">
                      <div><span>Board:</span> {task360Data.project_name}</div>
                      <span className="card-link-360"><a target="_blank" href={`${urls.rootdomain360}dashboard/my-requests/${findProjectId(task360Data.project_id)}/card/${props.taskDetails.task_360_id}`}>360</a></span>
                    </div>
                  </div>
                )}
                {enableUpdate &&
                (
                  <Input className="card-id-field" placeholder="Enter 360 card id" type="text" value={cardId360} name="cardId360"  onChange={(e) => { setCardId360(e.currentTarget.value);}} />
                )}
                {(project360!='' && enableUpdate) &&
                (
                  <div className="card-360-linked">
                    <div className="d-flex"><span>Card ID:</span>{cardId360}</div>
                    <div className="d-flex"><span>Card Title:</span>{task360}</div>
                    <div className="d-flex"><span>Board:</span> {project360}</div>
                  </div>
                  )}
                {linkedCardData && linkedCardData.bid_cpt!='' &&
                (
                  <div className="link-exist-card card-360-linked"><span>Existing Linked Card: </span>
                    <a target="_blank" href={`${urls.notificationUrl}/task/detail/${linkedCardData.bid_cpt}/${linkedCardData.tid_cpt}`}>{linkedCardData.task_name}</a>
                  </div>
                )}
                <div className="d-flex updation-360-buttons">
                  <div className="d-flex justify-content-between">
                    {(enableUpdate)  &&
                    (
                    <Button className="btn btn-secondary btn-grey" onClick={cancelUpdation}>Cancel</Button>
                    )}
                    {(enableUpdate && cardId360=='' && props.taskDetails.task_360_id!=null)  &&
                    (
                      <span className="unlink-relation" onClick={clearRelation}>Unlink Relation</span>  
                    )}
                  </div>
                  {(cardId360!='' && data360.length<=0)  &&
                  (
                  <Button className="btn btn-secondary btn-green " 
                  onClick={decodeValueandPost}
                  >Verify</Button>
                  )}
                  {project360!=''&& enableUpdate &&(
                    <Button name="save-card-info" className="btn btn-green" onClick={task360CardUpdate}>Confirm</Button>
                  )}
                 </div>
                 </LoadingWrapper>
                </LoadingWrapper>
            </div>
        </div>
        )}
    </>
  );
}
