import React from "react";
import "./DownArrow.css";
import DownArrowImg from "../../../../images/downArrow.png";

export default function DownArrow(props) {
  return (
    <div
      id="downArrow"
      className={props.showclass}
      style={{
        background: `#069482 url(${DownArrowImg}) no-repeat center`
      }}
      onClick={props.onclick}
    ></div>
  );
}
