import React, {Component} from 'react';
import './TicketSideMenu.css';
import { Link} from "react-router-dom";
import MaterialIcon from 'material-icons-react';
export default class TicketSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    render() {  
        return(
            <Link to={`/tickets?uuid=${this.props.logger}`}>
                <div                
                className={"nsmi_home menu-item-home menu-item d-flex"+(this.props.activeitem === 'tickets'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop} >
                <div className="nsmi_menu_wrapper d-flex">
                    <div className="nsmi_menu-icon" >
                       <MaterialIcon icon="confirmation_number"/>
                    </div>                
                    <p className="nsmi_menu_name">Tickets</p>
                </div>
                </div>
            </Link>
        )
    }
}