import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import BasecampIconImg from '../svgicon/BasecampIconImg';
import TrelloIcon from '../svgicon/TrelloIcon';

export default class WhiteLabelBoards extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      initialLoader:true,
      allWhiteLabels:[],
      orginalWhiteLabels:[],
      showActive:false,
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - White Label Boards";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {  
        _this.getAllWhiteLabels();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }



  getAllWhiteLabels = () =>{  
    let opts ={} 
    fetch(urls.findWhiteLabels,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
        this.setState({
            initialLoader:false,
            orginalWhiteLabels :response.whitelabels,
            allWhiteLabels: response.whitelabels     
        });       
     
    })
    .catch(error=>
        {
            this.setState({
                initialLoader:false
            });
        })
  };

  fetchBaseandRedirect = (board_name) =>
  {
    let opts ={    
      board_name: board_name
    } 
    fetch(urls.getBasecampProject,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if(response[0].project_id>0)
      {
        var burl = urls.notificationUrl+"/boardsdetails/"+response[0].project_id+"?uuid="+this.state.myId;
        window.open(burl, '_blank').focus();
      }
    })
  }

  handleInputChange(input, e) {
    console.log("value", input)
  }

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ allWhiteLabels:e});
    }else
    {
        this.setState({ allWhiteLabels:this.state.orginalWhiteLabels});
    }
  };

 toggleActive = async () =>
 {
     this.setState({showActive: !this.state.showActive}, this.filterActiveProjects);
     //await this.filterActiveProjects();
 }

 async filterActiveProjects()
 {
    let temp = this.state.orginalWhiteLabels;
    let list=[];
    if(this.state.showActive)
    {
        temp.forEach(p1=>
        {
            if(p1.status==1 || p1.status==2)
            {
                list.push(p1);
            }
        })
        this.setState({allWhiteLabels: list});
    }else
    {
        this.setState({allWhiteLabels: this.state.orginalWhiteLabels});
    }
 }
  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
      <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="project" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report">
              <div className="section_header">
                <h1 class="page-title her_title">White Label Boards</h1>
                <div className="filter_sort_wlb" id="filter">
                <div className="people d-flex">
                <Typeahead
                    id="user-picker"
                    labelKey="name"
                    onInputChange={this.handleInputChange}
                    onChange={this.onChangePeople}
                    options={this.state.allWhiteLabels}
                    placeholder="Search Client..."
                    selected=''
                    />
                    <span className="d-flex show-active-box">
                      <Input  id="OnlyActiveBoards" type="checkbox" name="active-only" checked={this.state.showActive} onClick={this.toggleActive} />
                      <label for="OnlyActiveBoards">Only Active Boards</label>
                    </span>
                  </div>
                </div>
              </div>
              <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
              <LoadingWrapper isLoading={this.state.initialLoader}>
              <Table responsive className="theme_table">
                <thead>
                  <tr>
                    <th>Client Name</th>
                    <th>Board Url</th>
                  </tr>
                </thead>
               
              <tbody>
              {this.state.allWhiteLabels.map((item, index) =>
              {
                  return(
                    <>
                  <tr>
                    <td>{item.name}</td>
                    <td className="d-flex justify-content-end">  
                     
                      <a target="_blank" href={urls.notificationUrl+"/boardsdetails/"+item.id+"?uuid="+this.state.myId}><BasecampIconImg /></a>
                    
                    {/* <TrelloIcon /> */}
                     
                   </td>
                  </tr>
                  </>
      )
    })}
                </tbody>
              
              </Table>
              </LoadingWrapper>
              
              </motion.div>
            </Card>
            </Col>
        </Row>
        </div>
      </Row>
      
      </Container>
      </div>
    )
  }
}
