import React from "react";

export default function AddTaskPlusIcon(props) {
  return (
    // <div className="add-task-plus-icon" onClick={props.closeaction}>
    <svg viewBox="14.5 5 26.5 26.5" width="26.5px" height="26.5px">
      <path
        style={{ fillRule: "evenodd", fill: "#ffffff" }}
        d="M27.400,4.884 C20.112,4.884 14.150,10.846 14.150,18.133 C14.150,25.421 20.112,31.383 27.400,31.383 C34.687,31.383 40.649,25.421 40.649,18.133 C40.649,10.846 34.687,4.884 27.400,4.884 ZM34.024,19.458 L28.725,19.458 L28.725,24.758 L26.075,24.758 L26.075,19.458 L20.775,19.458 L20.775,16.809 L26.075,16.809 L26.075,11.509 L28.725,11.509 L28.725,16.809 L34.024,16.809 L34.024,19.458 Z"
      />
    </svg>
    // </div>
  );
}
