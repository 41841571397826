import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Input, Button} from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import './predefinedStyle.css'
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";
// import { useCreateStaticContent, STATIC_CONTENT_TYPE } from './static-content.hooks';

export default function PredefinedMessages({
  // initialValues = {},
  // createPredefinedField,
}) {
  const [state, setState] = React.useState({ value: null });
  const handleChange = value => {
    setState({ value });
  };

  const [term, setTerm] = useState("");
 const [myData, setMyData] = useState([]);
 


  const onSubmit = (e) => {
    e.preventDefault();
    setMyData([...myData, term]);
  };

  const onDelete = (myId) => {
    const updates = myData.filter((each, idx) => idx !== myId);
    setMyData(updates);
  };


  return(
      <Container>
        <Row>
          <Col xs={12}>
            <div className="addBox">
            <Form 
            onSubmit={onSubmit}
            className="addPredeM"
            >
              <Input placeholder="Type Predefined Message" value={term} onChange={(e) => setTerm(e.target.value)} />
              <button>Add</button>
            </Form>

            <div className="dataView">
            {myData.map((each, idx) => (
              <div key={idx}>
                {each}
                <button className="removebtn" onClick={() => onDelete(idx)}>remove</button>
              </div>
            ))}
          </div>
            </div>

            <div className="preMesssageWrapper">
             <EditorToolbar 
              predefinedMessages={myData} 
              setPredefinedMessages={ value => {
                handleChange(value)
              } 
             } />
             <ReactQuill
              theme="snow"
              value={state.value}
              onChange={handleChange}
              placeholder={"Write something awesome..."}
              modules={modules}
              formats={formats}
              />
            </div>
          </Col>
        </Row>
      </Container>
  );
}