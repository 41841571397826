
import React, {useState, useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import './basecampmodule.css'
import Bcplusicon from '../../images/bc_Plus.svg';
import axios from 'axios';
import {urls} from "../../services/UrlList";

export default function NewBrand(props) {
    const [name, setName]=useState(props.editname);
    const [mmodal, setMModal]=useState(props.showmodal);
    
    const [fieldChanges, setFieldChanges] = useState([]);
    //this.toggle = this.toggle.bind(this);
    //this.handleAdd = this.handleAdd.bind(this);
   // this.onNameChange = this.onNameChange.bind(this);
  
   useEffect(() => {
    let fields = [];
    if(props.editname != name)
        fields.push('Brand Name: '+props.editname+" -> "+name);
    setFieldChanges(fields);
    }, [name]);

  function toggle () {
    setMModal( !mmodal);
    props.cancelModal();
  }
  
  function handleAdd()
  {
    const data = {
      project_id: props.projectid,
      board_name: props.project.board_name,
      brand_name:name,
      owner: props.myid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.addProjectBrands, data, {
        headers: headers
      })
      .then((response) => {
        props.onReceive(response.data[0].brand, 0);
        setName('');
        toggle();
      })
      .catch((error) => {
        
      })
  }

  function handleEdit()
  {
    const data = {
      project_id: props.projectid,
      board_name: props.project.board_name,
      brand_name:name,
      brand_id:props.editid,
      owner: props.myid,
      field_changes: fieldChanges
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateProjectBrands, data, {
        headers: headers
      })
      .then((response) => {
        props.onReceive(response.data[0].brand, 1);
        setName('');
        toggle();
      })
      .catch((error) => {
        
      })
  }

    return (
      <div>
        <Modal
          isOpen={mmodal}
          toggle={toggle}
          centered={true}
          className="add_barnd_box"
        >
          {/* <ModalHeader className="modale_head" toggle={this.toggle}>Add Brand</ModalHeader> */}
          <ModalBody>
            <div className="brand_popup">
              {/* <label htmlFor="name">name</label> */}
              <input
                type="text"
                className="input_box"
                onChange={(e)=>setName(e.target.value)}
                value={name}
                placeholder="Brand Title"
              />
            </div>
           
          </ModalBody>
          <ModalFooter>
            {props.editid >0 ?
            (
              <Button className="addbrand_btn" color="info" onClick={handleEdit}>
                Update Brand
              </Button>
            ):
            (
              <Button className="addbrand_btn" color="info" onClick={handleAdd}>
                  Add Brand
              </Button>
            )}
            
          </ModalFooter>
        </Modal>
      </div>
    );
}

