import React, { Component, Fragment } from 'react';
import './TaskSingle.css';
import TaskStartIcon from '../../../svgicon/TaskStartIcon';
import TaskEndIcon from '../../../svgicon/TaskEndIcon';
import Pmtimer from "../Pmtimer";
import BasecampIcon from '../../../svgicon/BasecampIcon';
import TrelloIcon from '../../../svgicon/TrelloIcon';
import Reassign from "../../../../images/reassign.png"; 
import {Button} from  'reactstrap'
import MaterialIcon from "material-icons-react";
export default class TaskSingle extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      actionTaskId:'',
      taskId: this.props.task_id,
      showExpandView:false,  
      trelloCard:'',   
      taskLabels:[],
      taskName:'',
    };
  }
  
  componentDidMount = () => {
   this.findTrelloId();
   this.showTags();
  };

  findTrelloId = () =>
  {
      
      var cardid = this.props.task_name.match(new RegExp("## " + "(.*)" + ""));
      if(cardid === null)
      {
        var cardid = this.props.task_name.match(new RegExp("##" + "(.*)" + ""));
      }
      if(cardid !=null)
      {
        this.setState({
          trelloCard:cardid[1]
        })
      }
  }

  handleStartClick = () =>
  {   
    console.log(this.props.mtask.tid_cpt); 
    this.props.myactions(this.props.mtask.tid_cpt, 'start', this.props.type, this.props.task_id, this.props.project_id, this.props.task_name, this.props.project_name);
  }


  handleEndClick = () =>
  {
    this.props.myactions(this.props.mtask.tid_cpt, 'end', this.props.type,this.props.task_id, this.props.project_id, this.props.task_name, this.props.project_name);
    this.setState({showExpandView: false});
  }

  showExpandView = () =>
  {
    this.props.history.push("/home");
    //this.setState({showExpandView: !this.state.showExpandView})
    //this.props.history.push("task/detail/"+this.props.mtask.bid_cpt+"/"+this.props.mtask.tid_cpt);
  };

  showAmendBox = (tid,id, pid, taskname) =>
  {
    this.props.taskamend(tid,id, pid, taskname);
  }


  showTrelloCard = () =>
  {
    this.props.showtrello(this.state.trelloCard);
  };

  showTags = () =>
  {
    var matches = [];
    this.props.task_name.replace(/\[(.*?)\]/g, function(g0,g1){matches.push(g1);})
    var title = this.props.task_name.replace(/(\[.*?\]|\(.*?\)) */g, "");
    this.setState({
      taskLabels:matches,
      taskName:title,
    });
  }

  quickClose = () =>
  {
    this.props.quickclose(this.props.task_id, this.props.mtask.tid_cpt, this.props.mtask.bid_cpt, this.props.type);
  }

  render() {    
    const classes = 'single-task ' + this.props.classname;    
    return (
      <article className={`single-task-container-projects ${(this.props.type =="dsmm" && this.props.mtask && this.props.mtask.task_priority==1) ? 'priority-internal-card': ''} ${classes}`} >
        <div className="single-task-container d-flex">
          {/* <div className="box sl-no">
           {this.props.slno}
          </div> */}
          <a className="single-task-link" href={"/task/detail/"+this.props.mtask.bid_cpt+"/"+this.props.mtask.tid_cpt+"?uuid="+this.props.myId}>
          <div className="box task-details" >
            <p className="task-client-detail">
              {this.props.project_name}
            </p>
            <p className="task-project-name">
              {this.props.task_name}
            </p>

            <div className="d-flex mobile-center">
            {this.props.project_id !==1001 && 
              (
                <p className="task-client-detail task-owner">
                  Due: <span className='due_date_task'>{this.props.mtask.actual_due}</span>
                </p>
              )}
            {this.props.manager!=null?
            (
              <p className="task-client-detail task-owner">
                  Posted By:  <span className='postby_name'> {this.props.manager}</span>
              </p>
            )
            :
            null
            }
            </div>
           
            
            {/* <p className="task-project-name">
              {this.props.task_id}
            </p> */}
            {/* <p className="task-project-name">
              <div>
              {this.state.taskLabels.map((item, index)=>
              {
                return(
                  <span className={item}>
                    {item === 'V' || item === 'v' ? 'VIP' : (item === 'R' || item === 'r' ? 'Revision': (item === 'U' || item === 'u' ? 'Urgent': (item === 'MR' || item === 'mr' ? 'Minor Revision' : (item === 'RS' || item === 'rs' ? 'Resizing' : item))))}
                  </span>
                )
              })}
              </div>
            </p> */}
            <p className="task-project-name task-labels-priorities d-flex">
                {(this.props.mtask.labels && this.props.mtask.labels.length>0) &&
                (
                  
                    <div>
                    {this.props.mtask.labels.map((item, index)=>
                    {
                      return(
                        <span className={item.name.toLowerCase()}>
                          {item.name}
                        </span>
                      )
                    })}
                    </div>
                  
                )}
                {this.props.mtask.task_priority===1 &&
                (
                    <span className="task-priority">Priority</span>
                )}
                {this.props.mtask.approval_waiting !=0 &&
                (
                    <span className="task-priority tba">TBA</span>
                )}
            </p>
          </div>
          </a>
          <div className="d-flex taskcard_footer">
          <div className="d-flex">
          {this.props.running_status === 1 ?
            (
            <div className="d-flex box task-btn">
              <div className="box task-start" >
               <Button activeclass='inactiveicon'>Start Timer</Button>
               {/* <TaskStartIcon activeclass='inactiveicon d-flex d-flex' title="Start" /> */}
              </div>
              <div className={(this.props.running_status === 1) ? "activeicon box task-end" :'box task-end'}>
               <Button activeclass={(this.props.running_status === 1) ? "activeicon d-flex" :''}  onClick={this.handleEndClick.bind(this)}> Stop Task </Button> 
               {/* <TaskEndIcon activeclass={(this.props.running_status === 1) ? "activeicon d-flex" :''} title="End" action={this.handleEndClick.bind(this)} /> */}
              </div>
            </div>
            )
            :
            (
              <div className="d-flex task-btn box">
                <div className="box task-start" >
                <Button activeclass='inactiveicon d-flex'  onClick={this.handleStartClick.bind(this)}>Start Task</Button> 
                 {/* <TaskStartIcon activeclass='inactiveicon d-flex' title="Start"  action={this.handleStartClick.bind(this)}  />  */}
                </div>
                <div className="box task-end">
                   <Button activeclass='inactiveicon d-flex'>Stop Timer</Button> 
                 {/* <TaskEndIcon activeclass='inactiveicon d-flex' title="End" />  */}
                </div>
              </div>
            )
          } 
            <>{(this.props.running_status === 1)?
              (
                <Pmtimer starttimer={this.props.starttimer}
                taskmilli={this.props.mtask.task_total} />
              )
              :
              (<div className="box task-total-time">
                {this.props.running_status !== 1 ? this.props.totaltoday : null }
              </div>)
              }
          </>
          
</div>


          <div className="icon_actions">
          {this.props.running_status !== 1?
          (
            <>
           
            <div className="box task-shot-info" onClick={this.showAmendBox.bind(this, this.props.mtask.tid_cpt, this.props.task_id,  this.props.mtask.bid_cpt, this.props.task_name)}>
              {this.props.project_id !== 1001 && 
              (
                <Button className="reassign_btn"><MaterialIcon icon="person_add"/></Button>
                // <img src={Reassign} className="reassign-my-task" />
              )}
            </div>
            {(this.props.allowclose && this.props.project_id !=1001) ?
            (
              <div className="quick-close-task" onClick={this.quickClose.bind(this)}>
              <MaterialIcon icon="done"/>
             </div>
              // <div className="quick-close-task" onClick={this.quickClose.bind(this)}></div>
            )
            :
            (<div className="empty-icon">&nbsp;</div>)
            }
            </>
          )
          :
          (
            <>
              <div className="box task-shot-info" ></div>
              <div className="empty-icon">&nbsp;</div>
            </>
          )
          }
          
          {/* <BasecampIcon active={this.props.running_status === 1 ? true:false} projectid={this.props.project_id} taskid={this.props.task_id} /> */}
          {this.state.trelloCard!=''?
            (
              <TrelloIcon showtrellocard={this.showTrelloCard} />
            )
            :
            (<div className="empty-icon">&nbsp;</div>)
          }
        </div>

          </div>
           
        
        
         
        
        </div>
        {/* {
          (this.props.running_status === 1 || this.state.showExpandView) &&
          (
            <div className="task-timing d-flex">
              <p className="today time">Today Time: {this.props.totaltoday}</p>
              <p className="total time text-right">Total Time:  {this.props.mytotal}</p>
            </div>
          )
        } */}
      </article>
    )
  }
}
