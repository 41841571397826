const sortByDate = (messages) => {
    let temp = messages;
    temp.sort(descOrder);
    return temp;
    function ascOrder(a, b) {
      return new Date(a.message_time).getTime() - new Date(b.message_time).getTime();
    }
    function descOrder(a, b) {
      return new Date(b.message_time).getTime() - new Date(a.message_time).getTime();
    }
 }

const ReadNotificationMessagesReducer = (state=[], action) =>
{
    switch(action.type)
    {
        case 'AppendReadNotificationMessages':
            let messages = [...state];
            let newmessage = [action.payload];
            //newmessage[0].message_time = action.payload.data['gcm.notification.message_time']
            //newmessage[0].receiver = action.payload.data['gcm.notification.receiver']
            state = sortByDate(newmessage.concat(messages));
            return state;
        case 'SubstractReadNotificationMessages':
            let messages1 = [...state];
            let itemkey = messages1.map(function (item) { return item.receiver; }).indexOf(action.payload.receiver); // (2D) finding the matching key providing value
            messages1.splice(itemkey, 1);
            state = sortByDate(messages1);
            return state;
        case 'ReadNotificationMessages':
                //messages = [...state];
                //messages.push(action.payload);
                state = sortByDate(action.payload);
                return state;
        default:
            return state;
    }
}

export default ReadNotificationMessagesReducer;