import React, { Component } from "react";
import "./log.css";

export default class Log extends Component {
  render() {
    const { late, inTime, lateTime, classname } = this.props;
    const classes = classname ? classname : "";
    return (
      <div className={"log-container " + classes + this.props.late}>
        <p>
          <span className="login-time">
            {this.props.actComment + this.props.actTime}
          </span>
        </p>
      </div>
    );
  }
}
