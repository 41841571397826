import React, { Component, Fragment } from "react";
import "./Profile.css";
import { Container, Row, Col, Input, Button, Label, Form, Card } from "reactstrap";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from "../header/Header";
// import Avatar from "../../images/avatar-icon.png";
import PenIcon from "../svgicon/PenIcon";
import DatePicker from "react-datetime-picker";
import AlertError from './AlertError/AlertError';
import axios from 'axios';
import { urls } from "../../services/UrlList";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import toast, { Toaster } from 'react-hot-toast';
import Profilepichooser from './chooseProfilepic/index'

const notifySuccess = () => toast('Profile details updated successfully.');
const notifyPasswordSuccess = () => toast('Password changed successfully.');
const notifyError = () => toast('Something went wrong. Please try again.');


export default class Profile extends Component {
  constructor(props) {
    super(props);
    const src = "https://dummyimage.com/600x400/000/fff";
    this.state = {
      showMenu:true, 
      uploadprogress:false,
      myPrivileges: [],
      myId: "",
      profile_fname: '',
      profile_lname: '',
      profile_email: '',
      profile_phone: '',
      profile_dob: '',
      profile_doj: '',
      gender:'',
      profile_error: false,
      profile_ch_pass_first: '',
      profile_ch_pass_conf: '',
      password_empty_error:false,
      password_error: false,
      avatarImg: '',
      fileData:'',
      fileTypeError: false,
      myProfile:[],
      profile_shift:'',
      randomNum: Math.floor(Math.random() * 160),
      preview: null,
  
      src
    };
    this.avatarRef = React.createRef();
    this.avatarImgRef = React.createRef();
    this.onCrop = this.onCrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBeforeFileLoad = this.onBeforeFileLoad.bind(this);
  }
  
   componentDidMount = () => {
    document.title = "HUB - My Profile";
    this.checkUserPrivilege();
     const _this = this;
    setTimeout(function() { //Start the timer        
      _this.getProfileDetails();              
    }, 100);
    };

  handleData = (e) => {
    this.setState({
      profile_error: false,
      password_empty_error:false,
      password_error: false
    })
    this.setState({ [e.target.name]: e.target.value })
  }
 
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
      });
    }
  };

  getProfileDetails = () =>
  {
    fetch(urls.getProfileDetails+this.state.myId,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
    {     
          let rnum = Date.now();
          console.log(urls.storageUrl+"storage/profile/image/"+this.state.myId+".jpg?"+rnum);
           this.setState(
             {
                profile_fname: response[0].profile[0].name,
                profile_email: response[0].profile[0].personal_email,
                profile_phone: response[0].profile[0].personal_phone,
                profile_dob: response[0].profile[0].date_of_birth,
                profile_doj: response[0].profile[0].date_of_joining,
                gender: response[0].profile[0].gender,
                avatarImg: urls.storageUrl+"storage/profile/image/"+this.state.myId+".jpg?"+rnum,
                myProfile: response[0].profile[0],
                profile_shift: this.tConvert(response[0].profile[0].slot_start)+" - "+ this.tConvert(response[0].profile[0].slot_end),
             });  
    })
  };

  updateProfile = () => {
    const { 
      profile_fname, 
      profile_email, 
      profile_phone, 
      profile_dob,
      gender
    } = this.state;

    if( profile_fname === ''  || profile_email === '' || profile_phone === '' || profile_dob === '' || gender === '' ) {
      this.setState({profile_error: true});
      return;
    } else {
      let profileData = {
        full_name: profile_fname,      
        email: profile_email,
        phone: profile_phone,
        dob: profile_dob,
        gender:gender,
        user_id: this.state.myId
      };
      
      axios.post(urls.updateAccount, profileData, { 
        headers: {
          'Access-Control-Allow-Origin':'*',
          'content-type': 'application/json'
        } }).then((res) => {
              notifySuccess();
          }).catch((e)=>{
              notifyError();
        })

    }
  }

  updatePassword = () => {
    const {profile_ch_pass_first, profile_ch_pass_conf} = this.state;

    if( profile_ch_pass_first === '' || profile_ch_pass_conf === '' ) {
      this.setState({password_empty_error:true});
      return;
    }
    
    if( profile_ch_pass_first !== profile_ch_pass_conf ) {
      this.setState({password_error: true});
      return;
    } else {
      this.setState({password_error: false});
      let updatePassword = {
        password: this.state.profile_ch_pass_conf,
        user_id:this.state.myId,
      };
      this.updatePassFetch(updatePassword)
    }
  }
  
  updatePassFetch = async ( formData ) => {
    await axios.post(urls.updatePassword, formData, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'content-type': 'application/json'
      } })
      .then((res) => {
        notifyPasswordSuccess();
      })
      .catch((err)=> 
        notifyError()
      )
  }
  
  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  updateShift = () => {
    let shiftUpdateData = {
      currentShift: this.state.profile_shift_current,
      changeShift: this.state.profile_shift_change
    }
    console.log(shiftUpdateData)
  }

  updateAvatar = () => {
    let avatar = this.avatarRef.current;
    console.log(avatar)
  }

  handleAvatar = async (e) => {
    const regex = /(image\/)/g;
      let file = this.avatarRef.current.files[0];
      var fileName = this.avatarRef.current.files[0].name;
      let fileType = this.avatarRef.current.files[0].type;
      let fileExtension = fileName.split('.').pop();
      console.log(fileExtension);
      if(fileExtension.trim() != 'jpg')
      {
        this.setState({fileTypeError:true});
        return false;
      }
      if( fileName.length > 25 ) {
        let s = [fileName.slice(0, 25)]
        let a = Array(8).fill('.')
        fileName = s.concat(a);
        fileName.push( fileType.replace(regex, '') )
      }    
    let files = e.target.files || e.dataTransfer.files;
    if (!files.length)
    return;
    this.createImage(files[0]);
      this.setState({uploadprogress:true});
      const _this = this;
      setTimeout(function() {      
        let opts = {
          file: _this.state.fileData,
          filename: _this.avatarRef.current.files[0].name,
          user_id: _this.state.myId,
          fileext: fileExtension
        };
         axios.post(urls.updateProfilePic, opts, { 
          headers: {
            'Access-Control-Allow-Origin':'*',
            'content-type': 'application/json'
          } }).then((res) => {
            if(res.data.status === 1)
            {
              //let rnum = Math.floor(Math.random() * 160);
              let rnum = Date.now();
              _this.setState({
                avatarImg: urls.storageUrl+"storage/profile/image/"+_this.state.myId+".jpg?"+rnum,
              })
              _this.setState({uploadprogress:false,fileTypeError:false});
              window.location.reload();
            }else
            {
              this.setState({fileTypeError:true});
            }
            }).catch((e)=>{
              _this.setState({uploadprogress:false});
          })
    }, 200);
   
  }

  
    createImage(file) {
    let reader = new FileReader();
    reader.onload = (e) => {
    this.setState({
      fileData: e.target.result
    })
    };
    reader.readAsDataURL(file);
    }


    tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
    }

    toggleSidebar = () =>
    {
      this.setState({showMenu: !this.state.showMenu});
    }

    hideMenu = () =>
    {
      this.setState({showMenu: !this.state.showMenu});
    }
    toggleSidebar = () =>
    {
       this.setState({showMenu: !this.state.showMenu});
    }


/**/
onClose() {
  this.setState({ preview: null });
}

onCrop(preview) {
  this.setState({ preview });
}

onBeforeFileLoad(elem) {
  if (elem.target.files[0].size > 91680) {
    alert("File is too big!");
    elem.target.value = "";
  }
}

  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
        <Row>
        <div className="theme_header">
        <Header {...this.props} 
           logout={this.signOut.bind(this)} 
           onmenuaction={this.toggleSidebar.bind(this)}
           quickactionblock={true} />
        </div>
        <div className="SideNavbar">
         <SideMenu activeitem="" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={4}>
              <Card className="cl_helth_report">
                
                <div className="profilePic_editer">
                <Toaster position="top-right" containerClassName="notifier"
                  toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                      background: '#363636',
                      color: '#fff',
                  },}}
                  />
                  {(this.state.avatarImg && this.state.avatarImg!='') &&
                  (
                    <Profilepichooser imgsrc={this.state.avatarImg} myid={this.state.myId}/>
                  )}
                  <div className="profile-info">
                  <h4>{this.state.myProfile.name}</h4>
                  <p className="pr-desig">{this.state.myProfile.designation_role}</p>
                  <h6 className="pr-sm-title">Official Email</h6>
                    <p className="pr-email-address pr-data">
                     {this.state.myProfile.email}
                    </p>
                    <h6 className="pr-sm-title">Work Shift</h6>
                    <p className="pr-shift-time pr-data">{this.state.profile_shift}</p>
                    <h6 className="pr-sm-title">Time Zone</h6>
                    <p className="pr-shift-time pr-data">{this.state.myProfile.zone_name}</p>
                    <h6 className="pr-sm-title">Date of Joining</h6>
                    <p className="pr-doj pr-data">{this.state.profile_doj}</p>
                    <h6 className="pr-sm-title">Reporting to</h6>
                    <p className="pr-ro pr-data">{this.state.myProfile.rofficer}</p>
                  </div>
                  
                </div>
              </Card>
          </Col>
          <Col xs={12} md={8}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Edit Account Details</h1>
                <div className="profile-edit">
                  <Row>
                    <Col xs={12} sm={12}>
                    <div className="pr-l1 full-name">
                      <Label>Full Name</Label>
                      <Input
                        type="text"
                        name="profile_fname"
                        id="first-name"
                        onChange={this.handleData}
                        value={this.state.profile_fname}
                      />
                    </div>
                    </Col>
                    <Col xs={12} sm={6}>
                    <div className="pr-l2">
                      
                      <Label>Personal Email</Label>
                      <Input
                        type="email"
                        name="profile_email"
                        id="pr-email-address"
                        onChange={this.handleData}
                        value={this.state.profile_email}
                      />
                    </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="pr-l2">
                      <Label>Mobile Number</Label>
                      <Input
                        type="number"
                        name="profile_phone"
                        id="pr-email-address"
                        onChange={this.handleData}
                        value={this.state.profile_phone}
                      />
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                    <div className="pr-l3">
                      <Label>Gender</Label>
                      <Input
                        type="text"
                        name="gender"
                        id="pr-"
                        onChange={this.handleData}
                        value={this.state.gender}
                      />
                    </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="pr-l3">
                      <Label>Date of Birth</Label>
                      <Input
                        type="text"
                        name="profile_dob"
                        id="pr-dob"
                        onChange={this.handleData}
                        value={this.state.profile_dob}
                      />
                      </div>
                    </Col>
                    <Col xs={12} sm={12}>
                    <AlertError center={true} error={this.state.profile_error} msg="Profile fields should not be empty" />
                    <Button className="btn btn-green-outline save-personal-info" onClick={this.updateProfile}>
                      Save
                    </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
          </Col>
        </Row>
        </div>
      </Row>
      </Container>
      </div>
    );
  }
}
