import React, { useState } from "react";
import Avatar from "react-avatar-edit";
import {Button, Container, Row, Col} from  'reactstrap'
const CreateAvatar = ({ getData }) => {
  const [preview, setPreview] = useState("");

  const onCrop = defaultPreview => {
    setPreview(defaultPreview);
  };

  const onClose = () => {
    setPreview("");
  };

  const onBeforeFileLoad = () => {};

  const onSelectPic = () => {
    getData(false, preview);
  };

  const onCancelSelect = () => {
    getData(false, "");
  };

  return (
    <Container>
      <Row>
        <Col xs={6} sm={7}>
          <div
            className="choose-file"
            // style={{ overflow: "scroll" }}
          >
            <Avatar
              imageWidth={270}
              width={"100%"}
              height={180}
              onCrop={onCrop}
              onClose={onClose}
              onBeforeFileLoad={onBeforeFileLoad}
              className="faheemlpfhdfs9[dfghsiu"
            />
          </div>
        </Col>
        <Col xs={6} sm={5}>
          <div className="previewDiv rounded-circle">
            {preview && (
              <img
                alt="preview"
                src={preview}
                width="100%"
                height="100%"
                className="rounded-circle"
              />
            )}
          </div>
          </Col>
      </Row>
<div className="footer_btns">
<button
            type="button"
            className="cancel_btn"
            style={{ minWidth: "100px" }}
            onClick={onCancelSelect}
          >
            Cancel
          </button>
       

          <button
            type="button"
            className="ok_brn"
            onClick={onSelectPic}
            disabled={!preview}
            style={{ minWidth: "100px" }}
          >
            Ok
          </button>
</div>
         
        
    </Container>
  );
};

export default CreateAvatar;
