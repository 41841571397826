import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import './SwitchUser.css'

export default class ConfirmHoliday extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',
      showMessage:false,
      Message:'',
      Message1:'Thank you for confirming the holiday.',
      Message2:'Sorry, you are not authorized to confirm the holiday.',
      Message3:'Please login to Kimphub before confirming the holiday'
     };
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Confirm Team Holiday";
    this.checkUserPrivilege(); 
    this.confirmHoliday();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    if(!login.privileges.includes('am_admin'))
    {
      this.props.history.push("/home");
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  
  confirmHoliday = ()=>
  {
    fetch(urls.confirmHoliday+this.props.match.params.id,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
      .then(response => {   
          this.setState({
            showMessage:true,
            Message: response[0].success == 1? this.state.Message1 :(response[0].success == 2 ? this.state.Message2 : this.state.Message3)
          })   
      })
      .catch(error => {
          console.log("error");
      });
  }

  render() {
    return (
      <Container fluid>
        <Fragment>
          <Row className="team-holiday-approve">
          <Col sm="1" xl="2">
              <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
          </Col>
          <Col sm="10">
              <Header {...this.props}  classname="tick-timer-header"  logout={this.signOut.bind(this)} onmenuaction={this.toggleSidebar.bind(this)} />
              <Container>
                <Row>
                <Col sm="12">
                    <h1 class="page-title text-center">Confirm Team Holiday</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="cfm-message text-center">
                            {this.state.Message}
                        </div>
                    </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Fragment>
      </Container>
    )
  }
}