import React, { Component } from 'react';
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";

export default class ProjectLogFilter extends Component {

  constructor( props ) {
    super( props );
    var e = new Date();
    e.setDate(e.getDate());
    e.setHours(6);
    e.setMinutes(0);
    let momentObje = moment(e);
    let momentStringEnd = momentObje.format("DD-MM-YYYY");

    this.state = {
    myPrivileges:[],     
    myId:'', 
    currentYear:momentObje.format("YYYY"),
    currentMonth:momentObje.format("MMMM"),
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();   
    this.yearsRender(); 
    this.monthsRender(); 
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid});
    }
  };

onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
};
onDateRangeChangeEnd = (end) => {
  let momentObj = moment(end);
  let momentString = momentObj.format("DD-MM-YYYY");
  this.setState({end_date:momentString});
};
searchEntriesnew = () =>
{  
  this.props.searchEntriesone(this.state.currentYear, this.state.currentMonth);
}


yearsRender = () =>
{
  const yearsval = [];
  for(var j=2020;j<=this.state.currentYear;j++)
  {
    yearsval.push(<option selected={this.state.currentYear == j? 'selected':''} value={j}>{j}</option> )
  }
  return yearsval;
};

monthsRender = () =>
{
  var theMonths = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"); 
  const monthsval = [];
  const _this= this;
  var k=1;
  theMonths.forEach(function(element){
    monthsval.push(<option selected={_this.state.currentMonth == element? 'selected':''} value={element}>{element}</option> )
    k++;
  });
  return monthsval;
}

filterByHours=(e)=>
{ 
  this.setState({filterHour:e.target.value}); 
  this.props.filterbyhour(e.target.value);
};

setCurrentYear = (e) =>
{
  this.setState({currentYear: e.target.value});
}
setCurrentMonth = (e) =>
{
  this.setState({currentMonth: e.target.value});
}

  render() {
    return (
      <div id="filter">
        <Container>
          <Row>
            <Col sm="12">
            <div className="filter-row d-flex">
              <div className="years">
                  <p className="">Year</p>
                  <FormGroup>
                  <Input id="yearfilter" type="select" onChange={this.setCurrentYear.bind(this)} >
                      <option value=''>Select</option>
                    {this.yearsRender()}
                    </Input>
                  </FormGroup>
                </div> 

                <div className="months">
                  <p className="">Month</p>
                  <FormGroup>
                  <Input id="yearfilter" type="select" onChange={this.setCurrentMonth.bind(this)} >
                      <option value=''>Select</option>
                    {this.monthsRender()}
                    </Input>
                  </FormGroup>
                </div>
              <div className="search-btn">
                <Button color="success"  onClick={this.searchEntriesnew.bind(this)} ><SearchIcon /></Button>
              </div>             
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}