import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch
} from "react-router-dom";

export default function SwitchIcon(props) {
  const classes = "leave-icon menu-icon " + props.classname;
  return (
    <div className={classes}>
    <Link to={props.to}>
      <div className="cont">        
          <svg width="46px" height="34px">
          <path class="cls-1" d="M0.526,13.169A12.438,12.438,0,0,0,12.943,25.6H30.681a12.43,12.43,0,0,0,0-24.861H12.943A12.438,12.438,0,0,0,.526,13.169Zm23.059,0a7.1,7.1,0,1,1,7.1,7.1A7.106,7.106,0,0,1,23.585,13.169Z"/>
          </svg>
          <p className="mb-0 text-center mt-1">{props.title}</p>       
      </div>
      </Link>
      <span className="arrow"></span>
    </div>
  );
}
