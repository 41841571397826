import React, { Component } from 'react'
import {Card} from 'reactstrap'
import FeedComments from '../FeedComments/FeedComments'

export default class FeedNewJoining extends Component {
  constructor(props) {
    super(props);
    this.state={
      hideJoinCom: true
    }
  }
  render() {
    const {feedData, keyIndex, showComment} = this.props
    return (
      <section id="feed_single" className="">
        <Card 
          className="feed-item d-flex d-flex-col feed-content-banner"
          key={keyIndex}
          >
          <div className="content">
            <p className="text-center feed-post-date mb-auto">{feedData.feed_posted_on}</p>
            <h4 className="text-center text-caps">{feedData.feed_heading}</h4>
            {/* <p className="text-center msg">{feedData.feed_content}</p> */}
            <img className="feed-details-banner" src={"https://Kimphub.com/service/"+feedData.feed_image} width="661" height="280" alt="Feed-banner-img" />
          </div>
          <FeedComments 
            isLike={feedData.like_count}
            likeCount={feedData.like_count}
            likeAction={this.props.likeAction}
            commentCount={feedData.comments.length}
            commentAction={ this.props.updateComment }
            commentValue={this.props.commentValue}
            feedId={feedData.id}
            postFeedComment={this.props.postFeedComment}
            allCommentsLengh={feedData.comments.length}
            allComments={feedData.comments}
            catId={feedData.category_id} 
            fName="anniversarycomment" />
        </Card>
      </section>
    )
  }
}
