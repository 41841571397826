import React, { Component } from 'react';
import {Container, Row, Col} from 'reactstrap';
import './Countdown.css';
import Header from '../header/Header';
import SideMenu from "../NewSideMenu/NewSideMenu";
import monkeyImg from '../../images/ki_countdown_img.png';
import {urls} from "../../services/UrlList";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { isMobile } from "react-device-detect";

export default class Countdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myPrivileges: [],
            myId: "",
            myBaseId: "",
            actualSubscripton:'',
            remainingSubsription:'',
            isLoading: true,
            showMenu:true,
        }
    }

    componentDidMount = () => {
      this.checkUserPrivilege(); 
        this.getCountDown();
        this.renderContentDevice();
    };

    checkUserPrivilege = () =>
    {
      if(localStorage.getItem("user") !== null)
      {
        var login = JSON.parse( localStorage.getItem("user") );
        this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
      }
    };

    getCountDown = () => {
    fetch(urls.getCoutDown, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {           
        this.setState({
          actualSubscripton: response[0].actual_subscriptions,
          remainingSubsription: response[0].balance_required,
          isLoading: false
        });
      })
      .catch(err => {
       this.setState({          
          isLoading: false
        });
      });
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  renderContentDevice = () => {
   
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

render() {
        return (
           <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}  >
        <Row>
        <div className="theme_header">
        <Header {...this.props} 
          logout={this.signOut.bind(this)}
          onmenuaction={this.toggleSidebar.bind(this)} 
          quickactionblock={true} />     
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
          <Row>
            <Col xs={12} sm={7}>
             <div className="monky_img"><img className="ki_countdown_img" src={monkeyImg} width="500" alt="ki_countdown_img" /></div>
            </Col>
            <Col xs={12} sm={5}>
            <LoadingWrapper isLoading={this.state.isLoading}>
                            <section id="ki_countdown">
                                <div className={this.state.actualSubscripton>= 135? "actual_subs greater d-flex" : "actual_subs d-flex" } >
                                  
                                     <h3>{this.state.actualSubscripton}</h3>
                                     <p>Actual Subscriptions</p>
                                </div>
                                {this.state.actualSubscripton < 135? 
                                  (<div className="to_reach_subs d-flex">
                                      <p>{this.state.remainingSubsription} more paid<br/>subscriptions to reach</p>
                                      <h3>135</h3>
                                  </div>)
                                  :
                                  null
                                }
                                
                            </section>
                        </LoadingWrapper>
            </Col>
          </Row>
        </div>
      </Row>
 </Container>
        )
    }
}