import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import ApprovalLeaveCard from "./ApprovalLeaveCard/ApprovalLeaveCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";
import {urls} from "../../../services/UrlList";
import {motion} from 'framer-motion'
import LoadingWrapper from '../../LoadingBox/LoadingWrapper/LoadingWrapper'

export default class ApprovalLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',        
      isMobileView:0,
      leaveRequests:[],
      leaveRequestsOrginal:[],
      requestPending:0,
      showUnApproved:false,
      progressBarLeave:true,
    };
  }

  componentDidMount =() =>
  {        
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(function() {
      _this.getLeaveRequests();
    }, 100);
    this.interval = setInterval(() => _this.getLeaveRequests(),600000); 
  };

  componentWillMount = () =>
  {
    this.getLeaveRequests();
  }
 
  componentWillUnmount = () =>{         
    clearInterval(this.interval);  
  }

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges, myId:login.uid});
    if(!login.privileges.includes('approve_user_requests'))
    {
      this.props.history.push("/tick-report");
    }
   
  };

  getLeaveRequests = () =>
  {
    this.setState({progressBarLeave:true});
    let opts ={       
        approver_id:this.state.myId
    };
    fetch(urls.getLeaveRequests,{
       headers:{
           'Access-Control-Allow-origin':'*',
           'Content-Type':'application/json'
       },
       method:'post',
       body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {        
      this.setState({leaveRequests:response[0].request_list,
        leaveRequestsOrginal:response[0].request_list, 
        progressBarLeave:true});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(response[0].request_list);
          _this.props.onleavefuncall(_this.state.requestPending);
        }, 100);
    })
    .catch(error =>
    {
        this.setState({progressBarLeave:true}); 
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(_this.state.breakRequests);
          _this.props.onleavefuncall(_this.state.requestPending);
        }, 100);
    })    
  };

  countPendingRequest = (response) =>
  {
    let temp = response;
    var list = [];
    var count = 0;
    temp.forEach(p1 =>
      {
        if(p1.status !== 1 && p1.status !==3)
        {
          list.push(p1);
          count++;
        }
      })
    if(this.state.showUnApproved)
    {
      this.setState({requestPending:count,leaveRequests:list, progressBarLeave:false });
    }else
    {
      this.setState({requestPending:count,leaveRequests:this.state.leaveRequestsOrginal, progressBarLeave:false });
    }    
  }

  onManagerialApproval =(myid, leaveid, type, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      manager_id:myid,
      leave_id:leaveid,
      type:type,
      comment:comment
    };
    fetch(urls.postLeaveManagerApproval,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getLeaveRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };

  onManagerialApprovalComment =(myid, leaveid, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      manager_id:myid,
      leave_id:leaveid,     
      comment:comment
    };
    fetch(urls.postLeaveManagerApprovalComment,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getLeaveRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  onAdminApproval =(myid, leaveid, type, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      admin_id:myid,
      leave_id:leaveid,
      type:type,
      comment:comment
    };
    fetch(urls.postLeaveAdminApproval,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getLeaveRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  onAdminMassApproval =() =>
  {
    this.setState({progressBarLeave:true});
    let opts={
      admin_id:4,
    };
    fetch(urls.postAdminMassApproval,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getLeaveRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  onAdminApprovalComment =(myid, leaveid, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      admin_id:myid,
      leave_id:leaveid,     
      comment:comment
    };
    fetch(urls.postLeaveAdminApprovalComment,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getLeaveRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };

  showUnApproved = () =>
  {
    this.setState({showUnApproved: !this.state.showUnApproved});
    const _this = this;
    setTimeout(function() {
      _this.countPendingRequest(_this.state.leaveRequestsOrginal);
    }, 100);
    
  }

  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    };
    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    };

    return (
      <Fragment>
        <ApprovalModules classname="appr-pending" title="Leave" myid={this.state.myId} waiting={this.state.requestPending} id="approve-leave" showonpending={this.showUnApproved} onmassapproval={this.onAdminMassApproval}>
          <LoadingWrapper isLoading={this.state.progressBarLeave}>
            <motion.section initial="hidden" animate="visible" variants={list} >
              <motion.div variants={item}>
                {/* <Slider {...settings}> */}
                {
                  this.state.leaveRequests.map((item, index)=>
                  {return(
                   
                      <ApprovalLeaveCard myid={this.state.myId} approved={item.status === 1? "true": ''} key={index} 
                      item={item} name={item.name} comment={item.leave_reason} 
                      onmanagerialapproval={this.onManagerialApproval}
                      onadminapproval={this.onAdminApproval}
                      onmanagerialapprovalcomment={this.onManagerialApprovalComment}
                      onadminapprovalcomment={this.onAdminApprovalComment} />
                    )
                  })}
                {/* </Slider> */}
              </motion.div>
            </motion.section>
          </LoadingWrapper>
        </ApprovalModules>
      </Fragment>
    );
  }
}
