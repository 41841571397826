import React, { Component } from 'react';
import './single-comment.css';

export default class SingleComment extends Component {
  render() {
    return (
      <div className="single-comment d-flex">
        <div className="author-box">
          <p className="author">Tibin</p>
          <p className="time">2019-11-19 09:50 am</p>
        </div>
        <div className="comment-box">
          <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
        </div>
      </div>
    )
  }
}