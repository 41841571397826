import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Button} from 'reactstrap';
import ExcelReportCalculated from './ExcelReportCalculated';
import ExcelReportVerified from './ExcelReportVerified';

export default function ExcelReport({downloadData, selectedcountry=''}) {
    const ref = useRef()
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    useEffect(() => {
        const checkIfClickedOutside = e => {
           if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
            setIsMenuOpen(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [isMenuOpen])

  return (
    <div className="filter_salary_box2" ref={ref}>
         <Button className="export_btn" onClick={() => setIsMenuOpen(oldState => !oldState)}>Export</Button>
            {isMenuOpen && (
                <ul className="list">
                    <ExcelReportCalculated  downloadData={downloadData} selectedcountry={selectedcountry}/>
                    <ExcelReportVerified  downloadData={downloadData} selectedcountry={selectedcountry}/>
            </ul>
            )}
    </div>
  )

}


