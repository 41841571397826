import React, { Component } from 'react'
import './AddNewFeed.Anchor.css'
import {Input, Label} from 'reactstrap'

export default class AddNewFeedAnchor extends Component {
  showForm = () => {
    this.props.showForm()
  }
  render() {
    return (
      <div className="d-flex addNewFeedAnchor">
        <Input 
          type="text"
          id="addNewFeed_input" 
          placeholder="Write something here.."
          onClick={this.showForm}
          ref={this.mockInputAddFeedRef} />
        <Label 
          for="addNewFeed" 
          className="addNewFeed_label"
          onClick={this.showForm} 
          ref={this.mockButonAddFeedRef} >Add Post</Label>
      </div>
    )
  }
}
