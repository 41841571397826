import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
} from "reactstrap";
import { useGetRevsionFolders, usePublishUnpublishSource, useGetRevsionApproved } from "./useQueryHooks";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
export default function RevisionData360(props) {
  
  useEffect(()=>
  {
    document.getElementById('card-source-management').scrollIntoView();
  },[]);

  const [showRevsionDeletion, SetShowRevsionDeletion] = useState(false);
  const closeDialogue = () => SetShowRevsionDeletion(false);
  const [deletionID, setDeletionID] = useState(0);
  const [publushUnpublishId, setpublishUnpublishId] = useState(0);
  
  const {
    isLoading: approvedLoading,
    data: approvedData,
  } = useGetRevsionApproved(props.taskid);

  const publishUnpblishAction=(folderid, action) =>
  {
    const data3 = {
        revisionid: folderid,
        taskid: props.taskid,
        cardId: props.cardId,
        action: action
    };
    publishUnpublishSource(data3);
  }

  const {isLoading: publishLoading, mutate: publishUnpublishSource} = usePublishUnpublishSource();

  if (approvedLoading) {
    return <>{"Loading"}</>;
  }

  return (
    <Row className={`uploaded-versions d-flex ${props.openPublisher && 'publish360'}`}>
      <div className="revision-files">
        <Table responsive className="theme_table">
            <thead>
                <tr>
                    <th>Revsion</th>
                    <th>Publish</th>
                    <th>Published On / By</th>
                </tr>
            </thead>
            <tbody>
               {approvedData?.data.structure.length>0 &&
               (
                (
                    <tr>
                        <td>
                            <div className="d-flex folder-wrapper-som">
                                    <span className={`folder_name ${approvedData?.data.published.is_published===1 && 'marked-as-final'}`}>
                                        <span>Approved Files</span>
                                </span>
                            </div>
                        </td>
                        <td>
                            <LoadingWrapper isLoading={publishLoading}>
                                {approvedData?.data.published.is_published===1 ?
                                (
                                    <Button value="Publish" 
                                        className={`btn btn-green btn-default publish360active`}
                                        onClick={publishUnpblishAction.bind(this, 0, 0)}
                                    >UnPublish</Button>
                                ):
                                (
                                    <Button value="Publish" 
                                        className={`btn btn-green btn-default publish360active`}
                                        onClick={publishUnpblishAction.bind(this, 0, 1)}
                                    >Publish</Button>
                                )}
                                
                            </LoadingWrapper>
                        </td>
                        <td className="last-item">
                            <span className="published-data">
                            {approvedData?.data.published.is_published===1 &&
                            (
                                <>
                                     <span>{approvedData.data.published.published_on}</span>
                                     <span>{approvedData.data.published.name}</span>
                                </>
                            )}
                            </span>
                        </td>
                    </tr>
                )
               )}
            </tbody>
            </Table>
      </div> 
    </Row>
  );
}
