import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button } from "reactstrap";
import ConfirmAction from "../../admincomment/ConfirmAction";
import { useAddRevisonFolder } from "./useQueryHooks";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";

export default function AddRevisions(props) {
  const [showPopup, setShowPopup] = useState(false);

  const showActionPop = () => setShowPopup(true);
  const closeDialogue = () => setShowPopup(false);

  const {
    isLoading: softLoading,
    isError,
    error,
    data: softdata,
    isFetching,
    refetch: createRevisionFolder,
  } = useAddRevisonFolder(props.taskid);

  const createFolder = () => {
    createRevisionFolder();
    setShowPopup(false);
  };

  return (
    <>
      <div
        className="new-version-upload d-flex justify-content-end"
        style={{ marginLeft: "auto" }}
      >
        <Button
          className={`btn btn-green task_status_change  btn btn-secondary  ${isFetching && ' btn-inactive'}`}
          value="New Version"
          name="New Version"
          onClick={showActionPop}
        >
          {isFetching ? 'Adding' : 'New Version' }
        </Button>
      </div>
      {showPopup && (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeDialogue}
          show={showPopup}
          note="*Are you sure?"
          content="You are about to create a new version folder"
          handlecreation={createFolder}
          resolveid=""
          resolveStatus=""
          adminid=""
          confirmBoxAction="createrevision"
        />
      )}
    </>
  );
}
