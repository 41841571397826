import React, { Component } from "react";
import "./LiveActivityFilter.css";
import { InputGroup, Input, Label } from "reactstrap";

export default class LiveActivityFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterActive: false,
      filtersActive:[],
    };
  }

  componentDidMount = () =>
  {
    //document.getElementById("la-f-logged-in").checked = false;
  }

  filterToggle = (e) => {   
    //this.props.filterupdate(item);
    let filters = this.state.filtersActive;
    if (e.target.checked) {
      filters.push(e.target.value);
      this.setState({ newassignees: filters });
    } else {
      filters = [...this.state.filtersActive]; // make a separate copy of the array
      var index = filters.indexOf(e.target.value);
      if (index !== -1) {
        filters.splice(index, 1);
        this.setState({ filtersActive: filters });
      }
    }
    console.log(filters);
    this.props.filterFunc(filters);
  };

  filterToggleAll = (e) => {   
    //this.props.filterupdate(item);
    document.getElementById("la-f-logged-in").removeAttribute('checked');
    let filters = [];
    if (e.target.checked) {
      filters.push(e.target.value);
      this.setState({ newassignees: filters });
    } else {
      filters = [...this.state.filtersActive]; // make a separate copy of the array
      var index = filters.indexOf(e.target.value);
      if (index !== -1) {
        filters.splice(index, 1);
        this.setState({ filtersActive: filters });
      }
    }   
    console.log(filters);
    this.props.filterFunc(filters);
  };



  render() {
    let classes = this.state.filterActive ? "active" : "";
    return (
      <section id="la-filter">
      
          <div className="row">
            <div className="col-12">
              <div className="la-filter d-flex">
                <InputGroup className={"all " + classes}>
                  <Input id="la-f-all" type="checkbox" value='all'  checked={this.state.filtersActive.includes('all')? "checked": null}  onChange={this.filterToggleAll.bind(this)} />
                  <Label for="la-f-all" >All</Label>
                </InputGroup>
                <InputGroup className="logged-in">
                 <Input id="la-f-logged-in" type="checkbox" value="logged-in" onChange={this.filterToggle.bind(this)}
                  checked={this.state.filtersActive.includes('logged-in')? "checked": null}
                  /> 
                  <Label for="la-f-logged-in" >In</Label>
                </InputGroup>
                <InputGroup className="late-login">
                  <Input id="la-f-late-loginin" type="checkbox" value="late" onChange={this.filterToggle.bind(this)}  />
                  <Label for="la-f-late-loginin" >Late</Label>
                </InputGroup>
                <InputGroup className="on-break">
                  <Input id="la-f-on-break" type="checkbox" value="break-active" onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-on-break" >Break</Label>
                </InputGroup>
                <InputGroup className="not-on-break">
                  <Input id="la-f-on-break" type="checkbox" value="break-not-active" onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-on-break" >No Break</Label>
                </InputGroup>
                <InputGroup className="task-running" >
                  <Input id="la-f-task-running" type="checkbox" value="task-running"  onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-task-running"  >T-Running</Label>
                </InputGroup>
                <InputGroup className="no-task-running">
                  <Input id="la-f-no-task-running" type="checkbox"  value="task-not-running" onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-no-task-running"  >T Not Running</Label>
                </InputGroup>
                <InputGroup className="no-assigned-task">
                  <Input id="la-f-no-assigned-task" type="checkbox" value="task-not-assigned"  onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-no-assigned-task"  >No Tasks</Label>
                </InputGroup>
                <InputGroup className="no-assigned-task">
                  <Input id="la-f-no-assigned-task" type="checkbox" value="running-short"  onChange={this.filterToggle.bind(this)} />
                  <Label for="la-f-no-assigned-task"  >{'<= 1 Task'}</Label>
                </InputGroup>
                <InputGroup className="no-assigned-task">
                 <Input id="la-f-no-assigned-task" type="checkbox" value="designer"  onChange={this.filterToggle.bind(this)} />
                 <Label for="la-f-no-assigned-task"  >Designer</Label>
                </InputGroup>
              </div>
            </div>
          </div>
   
      </section>
    );
  }
}
