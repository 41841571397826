import React from 'react';
import ScriptTag from 'react-script-tag';
export default function TrackingCode()
{
    return (
        <div className=''>
         <ScriptTag isHydrating={true} type="text/javascript" 
          src=
    "https://cdn.pagesense.io/js/dotostudiocanada/232f5c59eca54297860cf7b617d60229.js" />
        </div>
      );
}

