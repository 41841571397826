import React, { Component, Fragment } from 'react';
import moment from "moment";
import {Input} from 'reactstrap';
import { AccessCheckBlock } from './AccessCheck';


export default function TaskItem(props)
{
    function changeStatus()
    {
        props.updatetaskstatus(props.task.id,props.task.tid_cpt, props.action);
    }
    return(
        <div className="task_box" >
        <a href={"/task/detail/"+props.projectId+"/"+props.task.tid_cpt+"?uuid="+props.myId} target={props.searchactive? "_blank" : "_self"} >
          <div className="task_titile">
            <span>{props.task.task_name}</span>
             <div className="d-flex">
              {JSON.parse(props.task.task_label_list).length>0 &&
              (
              <div className="priority_badges">
                {JSON.parse(props.task.task_label_list).map((label, index)=>{
                  return(
                    <span className={`badge_text urgent_badge ${label.name.toLowerCase()} `} title="Urgent">{label.name}</span>
                  )
                })}
              </div>
              )}
              {props.task.task_priority === 1 &&
                (
                <span className="priority-list-task">Priority</span>
                )
              }
              {props.task.task_approval_waiting !==0 &&
                (
                <span className="priority-list-task tba">TBA</span>
                )
              }
              </div>
            </div>
          </a>
        
          <div className="duedate_time">
          <div className="label">Due Date:</div>
          <span className="task-items">
          {(props.task.due_date && props.task.due_date !=='')  &&
          (
          moment((new Date(props.task.due_date)).getTime()).tz(props.myTimezone).format('DD MMM YYYY h:mm A')
          )}
          </span> 
          </div>
          <div className="assign_person">
            <div className="label">Assigned To  :</div>
            {JSON.parse(props.task.task_assignee_list).length>0 &&
            (
              <div >
                {JSON.parse(props.task.task_assignee_list).map((assignee, index)=>{
                  return(
                    <span className="task-items">
                      {assignee.name}
                    </span>
                  )
                })}
              </div>
            )}
          </div>
          <div className="assigned_person">
            <div className="label">Assigned By  :</div>
              <span className="task-items">
                        {props.task.name}
              </span>
          </div>
          {(AccessCheckBlock('update_task_status') && props.projectId !=='gIiAZmVlZoCIgA==') &&
          (
          <div className="task-status">
            <span className="task-items">
              <Input className="update-input" checked="" value={props.task.tid_cpt} type="checkbox" name="TaskStatus" onClick={changeStatus} />     
            </span>
          </div>
          )}
        </div> 
    )
}