import React from "react";

export default function PlusIcon(props) {
  return (
    <div className="plus-icon" onClick={props.closeaction}>
      <svg width="16px" height="16px">
        <path
          style={{ fillRule: "evenodd", fill: "#ffffff" }}
          d="M8.222,0.844 C4.218,0.844 0.942,4.120 0.942,8.124 C0.942,12.128 4.218,15.404 8.222,15.404 C12.226,15.404 15.502,12.128 15.502,8.124 C15.502,4.120 12.226,0.844 8.222,0.844 ZM11.862,8.852 L8.950,8.852 L8.950,11.764 L7.494,11.764 L7.494,8.852 L4.582,8.852 L4.582,7.396 L7.494,7.396 L7.494,4.484 L8.950,4.484 L8.950,7.396 L11.862,7.396 L11.862,8.852 Z"
        />
      </svg>
    </div>
  );
}
