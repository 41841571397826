import React, { Component, Fragment } from "react";
import "./LiveActivity.css";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from "../header/Header";
import LiveActivityTickMark from "../svgicon/LiveActivityTickMark";
import PageTitle from "../page-title/PageTitle";
import { urls } from "../../services/UrlList";
import LoadingBar from "../common/LoadingBar";
import LiveActivityFilter from "./LiveActivityFilter/LiveActivityFilter";
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {Container, Row, Col, Label, Card, Table} from 'reactstrap';

// Framer Motion
import {motion} from 'framer-motion'

export default class LiveActivityDesigners extends Component {
  constructor(props) {
    super(props);
    this.state = {   
      myPrivileges:[], 
      myId:'',    
      rpeople:[],    
      progressBar:true,    
      showMenu:true,  
      showTaskAssigned:false,
      showTaskId:'',
      tasks:'',
      progressBarTask:false,
      isMobileView:0
    }
  }

  componentDidMount = () => {
    document.title = "HUB - Live Activity"; 
    this.checkUserPrivilege();   
    this.renderContentDevice(); 
    this.dataActivity(); 
    // this.taskinterval = setInterval(() => this.getLiveData(), 60000);
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") === null)
    {
      this.setState({isLoggedOut:true})
    }else
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid,myBaseId:login.basecampid, showExtBreak:login.showext });
    }
  };

  renderContentDevice = () => {
    if (isMobile) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  
  dataActivity = () => {   
    fetch(urls.activityDataDesigners, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("Response", response);
        //let list = this.getSortedRecords(response);
        this.setState({
          rpeople: response,
          progressBar: false
        });
      })
      .catch(error => {
        this.setState({
          rpeople: [],
          progressBar: false
        });
      });
  };

  getSortedRecords = response => {
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.uid === p1.uid) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

 

  applyFilter = (activefilters) =>{    
    const users = document.querySelectorAll('.doto-users');
    if(activefilters[0] === 'all')
    {
      users.forEach(user => {
        user.classList.add('item-show');     
      })
    }else
    {
      users.forEach(user => {
        user.classList.remove('item-show');
        var showitem = 1;
        for (var index = 0; index < activefilters.length; index++) { 
          if(!user.classList.contains(activefilters[index]))
          {
             showitem = 0;
             break;
          }         
        } 
        if(showitem === 1)
        {
          user.classList.add('item-show');
        }     
      })
    }
  };

  
  showTasks = (id) =>
  {
   if(this.state.showTaskId != id)
   {
    this.setState({
      showTaskId:id,
      progressBarTask:true,
      tasks: [],
      showTaskAssigned:false,
    });
    fetch(urls.getMyTasksDesigners+id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(response => {
          this.setState({
            tasks: (response['today'] && response['today'][0].project_id)? response['today'] :'',
            loading: false,
            showTaskId:id,
            showTaskAssigned:true,
            progressBarTask:false,
          });
        })
        .catch(error => {
          this.setState({
            tasks: [],
            loading: false,
            progressBarTask:false,
            showTaskAssigned:false
          });
        });
    }else
    {
      this.setState({
        showTaskId:'',
        showTaskAssigned:false,
      });
    }
  }

  closeTaskPopup = ()=>
  {
    this.setState({
        showTaskId:'',
        showTaskAssigned:false,
    })
  }


  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.5,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 },
    }
    return (

      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
           logout={this.signOut.bind(this)} 
           onmenuaction={this.toggleSidebar.bind(this)}
           quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="livedata" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
         <Row>
           <Col sm={12} md={12}>
             <Card className="cl_helth_report">
              <h1 class="page-title her_title">Designers</h1>
               {/* <section id="live-view" className={this.state.isMobileView == 1? "in-mobile": "" }> */}
               {this.state.isMobileView == 0 ?
             (
         
          
          
            <div>
                
                <Table responsive className="theme_table liveactive_desiner">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Assigned</th>
                        <th>Pending Tasks</th>
                        <th>R.Work Hours</th>
                      </tr>
                    </thead>
                  
                    <tbody>
                    {this.state.rpeople && this.state.rpeople.length > 0 ?
                    (
                      
                      <div style={{ display:'contents' }}>

                         { this.state.rpeople.map( (item, index) =>
                                {
                                  return (
                      <tr  key={index}>
                      

                       
                                    
                  
                        
                        <td>{item.name}</td>
                        <td> <LiveActivityTickMark  classname={item.total_pending_tasks >0 ? "active" : '' } /></td>
                        <td>
                        {item.total_pending_tasks > 0?
                            (
                                <p onClick={this.showTasks.bind(this,item.basecamp_user_id)}>{item.total_pending_tasks}</p>
                            )
                            :
                            (
                                <p>{item.total_pending_tasks}</p>
                            )
                        }
                        </td>
                        <td>
                        <p className={["la-data-body la-rm-time-body"] +(item.extra_mile === 1? ' extra-mile ':'') }><span className="r-hours">{item.remaining_work_hours}</span> </p>
                        </td>

                  

                       
                        
                      </tr>
 )
})
}

                      </div>
                     ) : null
                    }
                    </tbody>
                
                </Table>
            
              {/* <div className="row">
                <div className="col-12">
                
                  <div className="mobile_view_active">
                  
                  <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.progressBar}>
                      {this.state.rpeople && this.state.rpeople.length > 0 ?
                        (
                          <div id="la-list-body" className="designer-livedata">
                            <motion.section
                              initial="hidden"
                              animate="visible"
                              variants={list} >
                              { this.state.rpeople.map( (item, index) =>
                                {
                                  return (
                                    <motion.div variants={item}>
                                      <div 
                                        key={index} 
                                        className={["row no-gutters doto-users item-show "]}>
                                        <div className="col-12">
                                          <div className="wrapper d-flex">
                                            <p className="la-data-body la-name-body"></p>
                                            
                                              <div className="la-data-body la-task-body la-in-list d-flex">
                                                   
                                                   
                                                </div>
                                           
                                          </div>
                                        </div>
                                      </div>

                                      <div className="mytasklist-designers">
                                        <div className="showdetail">
                                        {this.state.showTaskId == item.basecamp_user_id?
                                        (
                                          <LoadingWrapper 
                                            LoaderCount={5}
                                            isLoading={this.state.progressBarTask}>
                                              <motion.section
                                              initial="hidden"
                                              animate="visible"
                                              variants={list} >
                                                <motion.div variants={item}>
                                                    
                                                </motion.div>
                                              </motion.section>
                                          </LoadingWrapper>
                                        )
                                        :
                                        null
                                        }
                                        {this.state.showTaskId == item.basecamp_user_id && this.state.showTaskAssigned ?
                                        (
                                          <>
                                             <Row className="bgcolor">
                                                <Col sm="6"><Label>Task / Project Name</Label></Col>
                                                <Col sm="6"><Label>Posted By / Due Date</Label></Col>
                                              </Row>
                                            {this.state.tasks.map((item, index)=>
                                            {
                                                return(
                                                 
                                                 <div className="projectdetail" key={index}>
                                                    
                                                    <Row>
                                                      <Col sm="6">
                                                        <span className="task-name">{item.task_name}</span>
                                                        <span className="project-name">{item.project_name}</span>
                                                        </Col>
                                                      <Col sm="6">
                                                        <span className="manager">{item.manager}</span>
                                                        <span className="due-date">{item.due_date}</span>
                                                      </Col>
                                                    </Row>
                                                    
                                                  </div>

                                                 
                                                )
                                            })}
                                            <div className="small_gap"></div>
                                          </>
                                        )
                                        :
                                        null
                                        }
                                        </div>
                                      </div>

                                    </motion.div>
                                  )
                                })
                              }
                            </motion.section>
                            
                          </div>
                        ) : null
                      }
                  </LoadingWrapper>
                </div>
                </div>
              </div> */}
            </div>  
            ):
            (
              <div>
                <img src="https://image.flaticon.com/icons/svg/136/136355.svg"></img>
                <div className="mobile-view-blocked text-center">You can't access via mobile.</div>
              </div>             
              ) 
          }  
               {/* </section> */}
               <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.progressBar}>
                       
                </LoadingWrapper>
             </Card>
           </Col>
         </Row>
        </div>
      </Row>
      </Container>
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Fragment>
        <Row>
        
        <Col sm="10">
         
          <section id="live-view" className={this.state.isMobileView == 1? "in-mobile": "" }>
                     
        </section>
         </Col>
        </Row>
      </Fragment>
      </Container>
      </div>
    );
  }
}
