import React, { useState } from 'react'
import {Input, Row, Col, Button} from 'reactstrap';
import Select from 'react-select' 
import {urls} from "../../services/UrlList";
import axios from 'axios';
import { useHistory } from "react-router";

const DetailSearch = (props) => {
    const history = useHistory();
    const [textValue, setTextValue] = useState('')
    const [projects, setProjects] = useState([]);
    const [tasks, setTasks] = useState([]);
    // const [showBtn, setShowBtn] = useState(false)
    const [toggle, setToggle] = useState(false)
    const [SearchEverything, setSearchEverything] = useState("");
    const [ByAnyone, setByAnyone] = useState("");
    const [EveryWhere, setEveryWhere] = useState("");

    const searchevery = [
        { value: 'Campfires', label: 'Campfires' },
        { value: 'Check-ins', label: 'Check-ins' },
        { value: 'Client emails', label: 'Client emails' }
    ]
    const byanyone = [
        { value: 'Aanisha B', label: 'Aanisha B' },
        { value: 'Aaron Kane Sriskandarajah', label: 'Aaron Kane Sriskandarajah' },
        { value: 'Abdul Kalam', label: 'Abdul Kalam' }
    ]
    const everywhere = [
        { value: 'Adadspath - Kimp [G]', label: 'FlatFee.io' },
        { value: 'FlatFee.io', label: 'FlatFee.io' },
        { value: 'HVN - Kimp [G]', label: 'HVN - Kimp [G]' }
    ]

    const searchUsingTerm = async (e) =>
    {
        setTextValue(e.target.value);
        if(e.target.value.length>1)
        {
            const data = {
                search_term: e.target.value,
            };
        
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-origin':'*',
            }
            await axios.post(urls.searchProjectsUsingKey, data, {  // searching projects 
                headers: headers
                })
                .then((response) => {
                    setProjects(response.data.projects);
                })
                .catch((error) => {
                })
            await axios.post(urls.searchTasksUsingKey, data, { // searching tasks
                    headers: headers
                    })
                    .then((response) => {
                        setTasks(response.data.other_results);
                    })
                    .catch((error) => {
                    })
        }else
        {
            setProjects([]);
            setTasks([]);
        }
    }

    const showProjectDetails = (id) =>
    {
        //window.location.href = "/boardsdetails/"+id;
        window.open(urls.notificationUrl+"/boardsdetails/"+id+"?uuid="+props.myId, "_blank");
    }

    const showTaskDetails = (bid,tid) =>
    {
        //window.location.href = "/task/detail/"+bid+"/"+tid;
        window.open(urls.notificationUrl+"/task/detail/"+bid+"/"+tid+"?uuid="+props.myId, "_blank");
    }

    const resetSearch = () =>
    {
        setProjects([]);
        setTasks([]);
        setTextValue('');
    }

    return(
      <>
        
        <button onClick={() => setToggle(!toggle)} className="btn-search"></button>
        
        {toggle && (
            <>
        <div className="overlay_search" onClick={() => setToggle(!toggle)} ></div>
        <div className="search_box_bc_module">
           
            <div className="serach_input_bc">
           
             
                <Input value={textValue} type="text" onChange={searchUsingTerm} placeholder="Search for boards and tasks. Add '#' before task number to search a particular task."/>
                {textValue ? <Button className="reset_btn" onClick={resetSearch}>reset</Button> : '' }
               
           
            </div>

           {/* <div className="search_drop_grp">
               <Row>
                   <Col xs="12" md="4">
                   <Select options={searchevery} value={SearchEverything} onChange={setSearchEverything} placeholder="Search Everything" />
                   </Col>
                   <Col xs="12" md="4">
                   <Select options={byanyone} value={ByAnyone} onChange={setByAnyone} placeholder="By Anyone"/>
                   </Col>
                   <Col xs="12" md="4">
                   <Select options={everywhere} value={EveryWhere} onChange={setEveryWhere} placeholder="Every Where"/>
                   </Col>
               </Row>
            </div> */}
           
            <div className="search_result_wrap">
                {projects && projects.length>0 &&
                (
                    <div className="project_result">
                        <h3>Projects</h3>
                            <ul>
                                {projects.map((project,index)=>
                                {
                                    return(
                                        <li className="seach_result" key={project.id} onClick={() => showProjectDetails(project.bid_cpt)}>{project.board_name}</li>
                                    )
                                })}
                            </ul>
                    </div>
                 )}
                 {tasks && tasks.length>0 &&
                 (
                    <div className="task_result project_result">
                        <h3> Other Results </h3>
                            <ul>
                                {tasks.map((task,index)=>
                                {
                                    return(
                                        <li key={task.id} className="seach_result">
                                            <div className="sresult_task_detail_wrap" onClick={()=> showTaskDetails(task.bid_cpt,task.tid_cpt)}>
                                                <h5 className="task-name">{task.board_name}</h5>
                                                <a className="task-link">{task.task_name}</a>
                                                {/*<p className="task_discrption">image that will likely link to adadspath.com/inspiration 4) A link to my podcast 5) Place to advertise my members-only site. Attachments: • Branding: A Dads Path - Adadspath - <span>Kimp</span> [G]</p> */}
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                    </div>
                )}
                {projects.length>10 || tasks.length>10 &&
                (
                    <Button className="all_sr_btn">See all the results…</Button>
                )}
            </div>
            
        </div>
        </>
        )}
      </>
    )
}
export default DetailSearch;