import React, { Component } from 'react';
import './simplecard.css';
import { Row, Col, Card } from 'reactstrap';


export default class SimpleCard extends Component {
  constructor(props) {   
    super(props);
           
  }

  showDetailEntryDate = (date)=>
  {
   this.props.showDetailEntryDate(date);
  };

  showDetailEntry = (id)=>
  {
    this.props.showDetailEntry(id);

  };

  render() {
    const { name, time, rate, projects, children } = this.props;
    const classNames = 'simple-card '+ this.props.cardName;
    return (
      <Row>
        <Col sm="12">
          <div className={classNames}>
            <Card className="d-flex">
              <p className="name" onClick={this.props.showSelectedResult === true? this.showDetailEntryDate.bind(this, this.props.date):this.showDetailEntry.bind(this, this.props.uid) } >{this.props.showSelectedResult === true? this.props.date : this.props.name}</p>
              <p className="time-entered">{this.props.time}</p>              
              <p className="projects text-left">{this.props.projects}</p>
              <p className="total-rate text-center">{this.props.rate}</p>
            </Card>
            {children ? children : ""}
          </div>
        </Col>
      </Row>
    )
  }
}