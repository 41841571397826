import React from "react";

export default function AttachmentIcon(props) {
  const cname = props.classname ? props.classname : "";
  const classes = "attachment-icon " + cname;
  return (
    <div htmlFor={props.htmlFor} className={classes} onClick={props.action}>
      <svg viewbox="0 0 33px 33px" width="17px" height="16px">
        <path
          fill-rule="evenodd"
          fill="rgb(6, 148, 130)"
          d="M12.748,6.158 C12.748,6.158 13.398,5.659 12.903,5.159 C12.408,4.660 11.926,5.304 11.926,5.304 L6.950,10.333 C6.950,10.333 5.784,11.852 4.898,10.933 C4.013,10.014 5.492,8.860 5.492,8.860 L12.018,2.269 C12.018,2.269 13.540,0.574 14.972,2.019 C16.402,3.465 14.719,5.007 14.719,5.007 L7.387,12.413 C7.387,12.413 5.041,15.151 2.869,12.957 C0.698,10.763 3.434,8.420 3.434,8.420 L9.001,2.795 C9.001,2.795 9.624,2.269 9.157,1.796 C8.688,1.323 8.169,1.954 8.169,1.954 L1.781,8.406 C1.781,8.406 -0.630,11.078 2.063,13.797 C4.755,16.518 7.401,14.082 7.401,14.082 L16.181,5.213 C16.181,5.213 17.937,3.256 15.843,1.139 C13.749,-0.978 11.811,0.797 11.811,0.797 L4.257,8.427 C4.257,8.427 2.322,10.017 4.077,11.789 C5.833,13.564 7.378,11.580 7.378,11.580 L12.748,6.158 Z"
        />
      </svg>
      <span className="task-btn-title">{props.title}</span>
    </div>
  );
}
