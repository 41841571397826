import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import './Qadashboard.css'
import Header from '../header/Header';
import {Container, Row, Col, Card, FormGroup} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Qaitems from './QaitemsReport';
import Qaitemsinbetween from './QaitemsinbetweenReport';
import Qaitemslogout from './QaitemslogoutReport';
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import moment from "moment";
import QFilter from "./QFilter";


export default class QaBoardReport extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myQuestionType:'',
      myBaseId:'',
      userTeam:'',  
      initialLoader:false, 
      loginQa1:[], 
      loginQa2:[], 
      inbitweenQa1:[],
      inbitweenQa2:[],
      logoutQa1:[],    
      designers:[],
      managers:[],
      uRole:'',
      activeShift:1,
      selectedUser:'',
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Questionnaire Report";
    this.checkUserPrivilege(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({
        myPrivileges:login.privileges, 
        userTeam:login.user_team, 
        myId:138, 
        myBaseId:login.basecampid,
        myQuestionType:5,
      });
    }
  };
  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getmyQuestions = (id, sdate) =>
  {
    this.setState({
      initialLoader:true
    })
    let opts = {
      user_id: id,
      sdate: sdate
    };
    console.log(opts);
    fetch(urls.getQrReport,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body: JSON.stringify(opts)
       })
      .then(response => response.json())
      .then(response =>
      {  
        this.mergeByProperty(response.login_step1, response.answers, 'question_id', 'loginQa1');              
        this.mergeByProperty(response.login_step2, response.answers, 'question_id', 'loginQa2');              
        this.mergeByProperty(response.between_step1, response.answers, 'question_id', 'inbitweenQa1');              
        this.mergeByProperty(response.between_step2, response.answers, 'question_id', 'inbitweenQa2');              
        this.mergeByProperty(response.logout_step1, response.answers, 'question_id', 'logoutQa1');              
       
        this.setState({
          initialLoader:false,
          uRole: response.urole,
          activeShift: response.active_shift,
          myshift:response.shiftdate,
          designers: response.designers,
          managers: response.managers
        })
      })
      .catch(error =>
        {
          this.setState({
            initialLoader:false
            })    
        });
  };

  mergeByProperty = (target, source, prop, cname) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
    this.setState({[cname]:target});
    console.log(target);
  }
  changeAnswerInFetched = (index, section, collection, answer_opt, answer_text, pms, dsgns)=>
    {
        let temp = collection;
        temp[index].answer_yes_no = answer_opt;
        temp[index].answer_text = answer_text;
        temp[index].project_managers = pms;
        temp[index].designers = dsgns;
        if(section === 1)
        {
          this.setState({loginQa1:temp});
        }else  if(section === 2)
        {
          this.setState({loginQa2:temp});
        } else  if(section === 3)
        {
          this.setState({inbitweenQa1:temp});
        } else  if(section === 4)
        {
          this.setState({inbitweenQa2:temp});
        }else  if(section === 5)
        {
          this.setState({logoutQa1:temp});
        }
    }

  searchQr = (sdate, selecteduser) =>
  {
    this.setState({selectedUser: selecteduser});
    const _this = this;
    setTimeout(function() {     
    _this.getmyQuestions(selecteduser, sdate);
    }, 200);
  }
  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"}> 
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
        classname="tick-timer-header" 
         logout={this.signOut.bind(this)} 
         onmenuaction={this.toggleSidebar.bind(this)} 
         quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
       <div className="themeContentBody">
         <Row>
            <Col xs={12} md={12}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Questionnaire Report</h1>
                <QFilter searchqr ={this.searchQr} />
                <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
        <LoadingWrapper isLoading={this.state.initialLoader}>
          <div className="qa_aire_card">
              <Row>
                <Col sm="12">
                  <>
                    {(this.state.uRole === 1 || this.state.uRole === 2 ) ?
                    (
                    <Tabs className="qa-tab-wrapper">
                      <TabList>
                        {this.state.loginQa1.length>0 &&
                        (
                          <Tab>Login</Tab>
                        )}
                        {this.state.inbitweenQa1.length>0 &&
                        (
                          <Tab>In-between</Tab>
                        )}
                        {this.state.logoutQa1.length>0 &&
                        (
                          <Tab>Logout</Tab>
                        )}
                      </TabList>
                      {this.state.loginQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitems logingquestions={this.state.loginQa1} questions2={this.state.loginQa2} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                      {this.state.inbitweenQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitemsinbetween betweenquestions={this.state.inbitweenQa1} questions2={this.state.inbitweenQa2} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                      {this.state.logoutQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitemslogout logoutquestions={this.state.logoutQa1} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          privileges={this.state.myPrivileges}
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                    </Tabs> 
                    ):
                    (
                      <>
                      {this.state.uRole !=0 &&
                      (
                        <Card className="without-tabs">
                          <Qaitemslogout logoutquestions={this.state.logoutQa1} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          privileges={this.state.myPrivileges}
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </Card>
                      )}
                      </>
                     )
                    }
                    </>
                  
                </Col>
              </Row>
            </div>  
        </LoadingWrapper>
        </motion.div>
              </Card>
            </Col>
        </Row>
       </div>
      </Row>

      </Container>
      </div>
    )
  }
}
