import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup
} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { urls } from "../../../services/UrlList";
import moment from "moment";
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import LightboxPop from "../LightboxPop";
import ClipboardCopyCommentLink from "../ClipboardCopyCommentLink";
import { findMockups,excludeMockupFromOther } from "./HelperFunctions";

export default function HubTaskComments(props) {


  const stripslashes = (str) => {
    if (str != null) {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
    }
    return str;
  }


  const replaceURLsResources = (message) => {
    if(!message) return;
    let newmessage= '';
    let string = message;
    string = string.replace(/,/g, ' ');
    const matches = string.match(/\bhttps?:\/\/\S+/gi);
    if(matches && matches.length>0)
    {
      for(let i = 0; i<matches.length; i++)
      {
          newmessage += '<a href="' + matches[i] + '" target="_blank" rel="noopener noreferrer"> Resource ' + (i+1) + '</a>, '
      }
      newmessage = newmessage.replace(/,\s*$/, "");
    }else
    {
      newmessage=message;
    }
    return newmessage;
   }

   const replaceURLsInspiration = (message) => {
    if(!message) return;
    let newmessage= '';
    let string = message;

    string = string.replace(/,/g, ' ');
    const matches = string.match(/\bhttps?:\/\/\S+/gi);
    if(matches && matches.length>0)
    {
      for(let i = 0; i<matches.length; i++)
      {
          newmessage += '<a href="' + matches[i] + '" target="_blank" rel="noopener noreferrer"> Inspiration ' + (i+1) + '</a>, '
      }
      newmessage = newmessage.replace(/,\s*$/, "");
    }else
    {
      newmessage=message;
    }
    return newmessage;
   }

   const copyContentEditor = (comment)=>
   {
      props.copycontenteditor(comment);
   }

  return (
    <div className="hub-comments-view">
         {props.taskcomments?.map((comment, index)=>
         {
            let commentfield = JSON.parse(comment.comment_fields);
            let commentattachments= JSON.parse(comment.attachments);
            let sourceFiles = comment.source_files !=null ? JSON.parse(comment.source_files) : [];
            let attachingFoldersOutput = (sourceFiles && sourceFiles.output_folders) ? sourceFiles.output_folders : [];
            let attachingFoldersSource = (sourceFiles && sourceFiles.source_folders) ? sourceFiles.source_folders : [];
            let mockupFolders =  findMockups((sourceFiles && sourceFiles.other_folders) ? sourceFiles.other_folders : []);
            let attachingFoldersOthers = excludeMockupFromOther((sourceFiles && sourceFiles.other_folders) ? sourceFiles.other_folders : []);
        
            var stringifiedtextlinks = (comment && comment.comment_attachments_textlinks && comment.comment_attachments_textlinks!=null) && JSON.parse(comment.comment_attachments_textlinks);
            var commentImagesTextArray = (stringifiedtextlinks && stringifiedtextlinks.commentAttachmentFilesTextArray ) ? stringifiedtextlinks.commentAttachmentFilesTextArray:[];
                                                                                                                  
            return(
              <div key={index} className="ticket-posted-comments cmd_viewbox edit-comments-wrap" >
                <div className="d-flex justify-content-between comment-meta">
                  <div className="comd_pop_head">
                    <div className="posted-by user_detail">
                      <div className="user_avatar"><img src={urls.storageUrl+"storage/profile/image/"+comment.comment_by+".jpg?"} alt="back" /></div>
                    <div className="post-date-time flex-box">
                      {comment.name}
                      <div className="daten_time">
                        <span className="posted-on">
                        {moment((new Date(comment.updated_on)).getTime()).tz(props.mytimezone).format('dddd, Do MMMM, YYYY')}
                        </span>
                        <span className="posted-time">
                            {moment((new Date(comment.updated_on)).getTime()).tz(props.mytimezone).format('hh:mm a')}
                        </span>
                      </div>
                    </div>
                    {props.openComposer && comment.comment_type===1 &&
                    (
                      <div style={{marginLeft:'auto', cursor:'pointer'}} onClick={copyContentEditor.bind(this,comment)}>
                        <MaterialIcon icon="content_copy" title="Copy content to editor"  />
                      </div>
                    )}
                  </div>
                </div>
                </div>

                <div className="comment-text">
                      <Linkify  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ ReactHtmlParser( stripslashes(comment.comment) +" ")}</Linkify>
                      {commentattachments && commentattachments.length>0 &&
                      (  <>
                        <div className='d-flex comment-new-sec'>
                          <h6>Designs</h6>
                          {/* <div className='down-all-files'>
                           Download all
                          </div> */}
                        </div>
                        

                          <div class="task-comment-attachements d-flex">
                            <div class="attachment-items d-flex justify-contents-between">
                              <LightboxPop attachments={commentattachments} 
                              attachmenttexts={commentImagesTextArray && commentImagesTextArray.length > 0 ? commentImagesTextArray : []}
                              />
                            </div>
                          </div>
                          </>
                        )}
                      <div className='source-output-other-folder-view'>
                        {mockupFolders?.length>0 &&
                            (
                              <div className='folder-view-items'>
                                  <h6>Mockups</h6>
                                  <div className='view-items-grid'>
                                    {mockupFolders.map((outputfolder, index)=>
                                    {
                                      return(
                                        <span className='folder-view-name' key={outputfolder.folder_name}>
                                          <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                          <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                            <ClipboardCopyCommentLink
                                                copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                                
                                            />
                                          </span>
                                          </a>
                                        </span>
                                      )
                                    })}
                                  </div>
                              </div>
                            )}
                            
                          {attachingFoldersOutput?.length>0 &&
                          (
                            <div className='folder-view-items'>
                                <h6>Output Folders</h6>
                                <div className='view-items-grid'>
                                  {attachingFoldersOutput.map((outputfolder, index)=>
                                  {
                                    return(
                                      <span className='folder-view-name' key={outputfolder.folder_name}>
                                        <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                        <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                          <ClipboardCopyCommentLink
                                              copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                              
                                          />
                                        </span>
                                        </a>
                                      </span>
                                    )
                                  })}
                                </div>
                            </div>
                          )}
                          {attachingFoldersSource?.length>0 &&
                          (
                            <div className='folder-view-items'>
                                <h6>Source Folders</h6>
                                <div className='view-items-grid'>
                                  {attachingFoldersSource.map((outputfolder, index)=>
                                  {
                                    return(
                                      <span className='folder-view-name' key={outputfolder.folder_name}>
                                        <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                        <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                          <ClipboardCopyCommentLink
                                              copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                              
                                          />
                                        </span>
                                        </a>
                                      </span>
                                    )
                                  })}
                                </div>
                            </div>
                          )}
                          {attachingFoldersOthers?.length>0 &&
                          (
                            <div className='folder-view-items'>
                                <h6>Other Folders</h6>
                                <div className='view-items-grid'>
                                  {attachingFoldersOthers.map((outputfolder, index)=>
                                  {
                                    return(
                                      <span className='folder-view-name' key={outputfolder.folder_name}>
                                        <a href={outputfolder.path} target="_blank">{outputfolder.folder_name}
                                        <span className="copy_url_link" onClick={(e)=> e.preventDefault()}>
                                          <ClipboardCopyCommentLink
                                              copyText={`${outputfolder.folder_name} : ${outputfolder.path}`}
                                              
                                          />
                                        </span>
                                        </a>
                                      </span>
                                    )
                                  })}
                                </div>
                            </div>
                          )}
                      </div>
                      {(commentfield && commentfield.inspiration!= null)  &&
                      (
                        <div className="comment-data"><Label>Inspiration:</Label> 
                          <span>
                            {/* <Linkify  options={this.state.options}>{ReactHtmlParser(this.state.inspiration)}</Linkify> */}
                            {ReactHtmlParser(replaceURLsInspiration(commentfield.inspiration))}
                          </span> 
                          </div>
                      )}
                      {(commentfield && commentfield.resourcelinks!= null)  &&
                      (
                        <div className="comment-data"><Label>Resource Links:</Label> 
                          <span>
                            {/* <Linkify  options={this.state.options}>{ReactHtmlParser(this.state.inspiration)}</Linkify> */}
                            {ReactHtmlParser(replaceURLsResources(commentfield.resourcelinks))}
                          </span> </div>
                      )}

                      {(commentfield && commentfield.approval_required)&&
                      (
                        <div className="comment-data approved-person"><Label>Design Approval Required:</Label> 
                        <span>Yes</span> 
                        </div>
                      )}
                      {(commentfield && commentfield.pmnote!='' && commentfield.pmnote!= null ) &&
                      (
                        <div className="comment-data red-data"><Label>Note to PL / PM / TL / COM / Client:</Label> 
                          <span className="red_text"> 
                          <Linkify  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(commentfield.pmnote)}</Linkify>
                          </span> 
                        </div>
                      )}

                      {(commentfield && 
                      (commentfield.packaged == 'true' || commentfield.packaged === true || 
                      commentfield.packaged == 'false' || commentfield.packaged === false ||
                      commentfield.packaged == 'NA') ) &&
                      (
                        <div className="comment-data approved-person">
                          <Label>Packaged Files Correctly: </Label> 
                          {(commentfield.packaged == 'true' || commentfield.packaged === true) ?
                          (
                            <span>Yes</span> 
                          ):
                          (
                            <>
                              {(commentfield.packaged == 'false' || commentfield.packaged === false)?
                              (
                                <span>No</span> 
                              ):
                              (
                                <span>Not Applicable</span> 
                              )}
                            </>
                          )}
                        </div>
                      )}
                      
                      {(commentfield && 
                      (commentfield.spell_check == 'true' || commentfield.spell_check === true || 
                      commentfield.spell_check == 'false' || commentfield.spell_check === false ||
                      commentfield.spell_check == 'NA') ) &&
                      (
                        <div className="comment-data approved-person">
                          <Label>Completed Spell Check: </Label> 
                          {(commentfield.spell_check == 'true' || commentfield.spell_check === true) ?
                          (
                            <span>Yes</span> 
                          ):
                          (
                            <>
                              {(commentfield.spell_check == 'false' || commentfield.spell_check === false)?
                              (
                                <span>No</span> 
                              ):
                              (
                                <span>Not Applicable</span> 
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {commentfield && commentfield.task_approval_status === 1 &&
                      (
                        <div className='d-flex approval-wapper'>
                            <Button name="approve-task" className='btn btn-green' onClick={this.approveAsk}>Approve</Button>
                            <Button name="revise-task" className='btn btn-green' onClick={this.revisionAsk}>Revision Required</Button>
                        </div>
                      )}
                      {(commentfield && commentfield.approver.length>0)&&
                      (
                        <div className="comment-data approved-person" ><Label>Approved By:</Label> 
                          <span>{commentfield.approver[0].name}</span> 
                        </div>
                      )}

                  </div>

                </div>
            )
         })}
    </div>
  );
}
