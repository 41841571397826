import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { useGetRevsionFolderStructure, useMoveRevsionFolder } from "./useQueryHooks";
import ConfirmAction from "../../admincomment/ConfirmAction";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../../services/UrlList";
import ClipboardCopyLink from "./ClipboardCopyLink";

export default function MoveSourcePop(props) {
  
  const [sharePop, setSharePop] = useState(true);
  const [activeLevel, setActiveLevel] = useState(0);
  const [previousLevel, setPreviousLevel] = useState(0);
  const [sameParentError, setSameParentError] = useState(false);
  
  const toggleSharePop = ()=>
  {
    setSharePop(!sharePop);
    props.closepop();
  }

  useEffect(()=>
  {
    if(sameParentError === true)
    {
        setTimeout(() => {
            setSameParentError(false);
        }, 5000);
    }
  },[sameParentError]);

  useEffect(()=>
  {
        console.log(activeLevel);
  },[activeLevel, previousLevel]);

  const {
    isLoading: revisionFolderLoading,
    isError,
    error,
    data: folderData,
  } = useGetRevsionFolderStructure(props.revisionid, props.taskid);

  const findParentID = (id)=>
  {
    var itemkey = folderData?.data.folders.map(function (item) { return item.id; }).indexOf(id);
    setActiveLevel(folderData.data.folders[itemkey].parent_folder_id);
    setPreviousLevel(folderData.data.folders[itemkey].parent_folder_id);
  }

  const setNextLevels = (item)=>
  {
    if(props.movetype === 'Folder' && props.movingid === item.id && item.parent_folder_id === 0)
    {
        setSameParentError(true);
    }else
    {
        setPreviousLevel(item.parent_folder_id); setActiveLevel(item.id);
    }
  }

  const confrimMoveFolder = () => {
    const data2 = {
      revisionid: props.revisionid,
      folderid: props.movingid,
      newparent: activeLevel,
      movementtype: props.movetype
    };
    moveRevsionFolder(data2);
    setTimeout(() => {
         toggleSharePop();
    }, 1000);
   
  };
  const { isLoading: deleteLoading, mutate: moveRevsionFolder } = useMoveRevsionFolder();

  return (
    <Modal
        isOpen={sharePop}
        toggle={toggleSharePop}
        className="addtodo_wrapper"
      >
        <ModalHeader value="1" toggle={toggleSharePop}>
          Move Folder or Files
          <Button className="clase_btn_cp" value="1" onClick={toggleSharePop}>
            <MaterialIcon icon="close" />{" "}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            {sameParentError &&
            (
                <Col xs={12} md={12} className="not-allowed-msg">Sorry you are not allowed to move a root level parent folder to it's own child folders</Col>
            )}
            <Col xs={12} md={12}>
              <FormGroup>
               <div className="copy-share-wrap d-flex folder_box_som">
                    {folderData?.data.folders.map((item, index)=>
                    {
                        return(
                            <>
                                {item.parent_folder_id === activeLevel &&
                                (
                                    <Card key={item.id} onClick={()=>{setNextLevels(item)}}
                                        className="d-flex folder-wrapper-som source-folder-items">
                                        <div className="d-flex">
                                            <MaterialIcon icon={"folder"} />
                                            <span className="folder_name">
                                                {item.folder_name}
                                            </span>
                                        </div>
                                    </Card>
                                )}
                            </>
                        )
                    })}
               </div>
               <div className="move-here-source">
                    <div className="d-flex actions">
                        {activeLevel!=0 &&
                        (
                            <Button className="btn btn-grey" onClick={()=>findParentID(activeLevel)}>Back</Button>
                        )}
                        <Button className="btn btn-green" onClick={()=>confrimMoveFolder()}>Move Here</Button>
                    </div>
               </div>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
  );
}
