import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import ReactDOM from "react-dom";
import Select from "react-select";


export default class SelectMonth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemtitle: "",
      multi: true,
      multiValue: "Choose Month...",
      options: [
        { value: "1", label: "January" },
        { value: "2", label: "February" },
        { value: "3", label: "March" },
        { value: "4", label: "April" },
        { value: "5", label: "May" },
        { value: "6", label: "June" },
        { value: "7", label: "July" },
        { value: "8", label: "August" },
        { value: "9", label: "September" },
        { value: "10", label: "October" },
        { value: "11", label: "November" },
        { value: "12", label: "December" },
      ]
    };
  }
  
  onTitleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ multiValue: e.target.value });
  }
  handleOnChange(value) {
    this.setState({ multiValue: value }); 
    this.props.selectedmonth(value.value);
  }
  render() {
    return (
      <div className="salary_country_choose">
        <Select
          // multi={this.state.multi}
          options={this.state.options}
          onChange={this.handleOnChange.bind(this)}
          value={this.state.multiValue}
          isSearchable={false}
          placeholder="Choose Month..."
        />
      </div>
    );
  }
}


