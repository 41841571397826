import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { Typeahead } from 'react-bootstrap-typeahead';

export default class CopyGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
        progressBar:false,
        progressBarList:false,
        projects:[],
        selectedProject:'',
        selectedList:'',
        boardLists:[]
    };
  }

  componentDidMount = () => {
    this.getInternalBoards();     
  };

  
  getInternalBoards = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      user_id: this.props.myid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getInternalActiveProjects, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          progressBar: false,
          projects: response.data.projects
        })
      })
      .catch((error) => {
        this.setState({
          progressBar: false
        })
      })
  };

  

  handleInputChange(input, e) {
    console.log("value", input)
  }
  
  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedProject:selectedOptions[0].id});
      this.getBoardLists(selectedOptions[0].id);
    }else
    {
      this.setState({ selectedProject:'', boardLists:[]});
    }
  }

  getBoardLists =(id)=>
  {
    this.setState({progressBarList:true});
    const data = {
        board_id: id
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin': '*',
      }
      axios.post(urls.getBoardLists, data, {
        headers: headers
      })
    .then((response) => {
        this.setState({
            boardLists: response.data.lists,
            progressBarList: false
        })
    })
    .catch((error) => {
        this.setState({progressBarList: false})
    })
  }

  handleGroupInputChange(input, e) {
    console.log("value", input)
  }
  
  handleGroupChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedList:selectedOptions[0].id});
    }else
    {
      this.setState({ selectedList:'', boardLists:[]});
    }
  }

  cloneGrouptoBoard = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      group_id: this.props.groupid,
      group_name: this.props.groupname,
      list_id: this.state.selectedList,
      board_id: this.state.selectedProject
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.copyTaskGroup, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          progressBar: false
        })
        this.props.closeanddirect(response.data.bid_cpt);
      })
      .catch((error) => {
        this.setState({
          progressBar: false
        })
      })
  };


  render() {
    return (
      <Fragment>
      <Container fluid className="top-bar-inactive bc_module_container group_copy_wrap">
       <div className="">
       <LoadingWrapper isLoading={this.state.progressBar}>
            <Typeahead
            id="project-picker"
            labelKey="name"
            onInputChange={this.handleInputChange}
            onChange={this.handleChange}
            options={this.state.projects}
            placeholder="Choose Project"
            selected=''
            />
            <LoadingWrapper isLoading={this.state.progressBarList}>
                <Typeahead
                id="list-picker"
                labelKey="name"
                onInputChange={this.handleGroupInputChange}
                onChange={this.handleGroupChange}
                options={this.state.boardLists}
                placeholder="Choose List"
                selected=''
                />
            </LoadingWrapper>
            <div className='d-flex align-items-center justify-content-center save-copy-list'>
                <Button name="Save" className='btn btn-green' onClick={this.cloneGrouptoBoard}>Save</Button>
            </div>
        </LoadingWrapper>  
       </div>      
      </Container>
    </Fragment>
    )
  }
}
