import React, { Component } from 'react';
import DatePickerTick from "./DatePickerTick";
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import StopSearch from "../../images/stop-search.png";

export default class FilterLimit extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {              
      selectedUser:'',
      start_date:momentStringStart,
      end_date:momentStringEnd, 
      myPrivileges:[],     
      myId:'', 
      myBaseId:'',
      userTeam:'',
      selectedCount:'Select',      
      showAllPeople: 0,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
};
onDateRangeChangeEnd = (end) => {
  let momentObj = moment(end);
  let momentString = momentObj.format("DD-MM-YYYY");
  this.setState({end_date:momentString});
};

searchEntriesnew = () =>
{  
  this.props.searchEntriesone(this.state.start_date);
}

stopActiveSearch =()=>
{
  this.props.stopactivesearch();
}
  render() {
    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : 'Search Log';
    return (
      <div id="filter"  className="log-timer-filters">
        <Container>
          <Row>
            <Col sm="12">
            <div className="filter-row d-flex justify-content-between">
              <div className="fliterbox">
              <div className="start-date date-col datepicker-col">
                <p>Date: </p>
                <DatePickerTick startPoint='1' onDateRangeChange={this.onDateRangeChangeStart} />
              </div>
              <div className="search-btn d-flex align-items-center">
                
                {(this.props.searchrunning && this.props.listcount>0) ?
                (
                  <img title="stop currently running search" src={StopSearch} className="stop-active-search" onClick={this.stopActiveSearch} />
                ):
                (<Button className="mgTop20" color="success"  onClick={this.searchEntriesnew.bind(this)} >
                  { searchContent }                  
                </Button>
                )}
              </div>
              </div>
            </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}