import React, { Component, Fragment } from 'react';
import "./momeeting.css"
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Select from 'react-select';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import { 
  Container, 
  Row, 
  Col, 
  Card, 
  FormGroup, 
  Input, 
  Button, 
  Table,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Label } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import NewMeeting from './NewMeeting';
const options = [
  { value: 'AU-A1', label: 'AU-A1' },
  { value: 'CA-A1', label: 'CA-A1' },
  { value: 'MY-A1', label: 'MY-A1' },
];

const supperadminOptions = [
  { value: 'Senthu', label: 'Senthu' },
  { value: 'Prem', label: 'Prem' },
];

const adminOptions = [
  { value: "Tibin", label: "Tibin"},
  { value: "Hasthik", label: "Hasthik"},
  { value: "Suhas", label: "Suhas" }
];
const projectleadOptions = [
  { value: "Dacey - Dechamma", label: "Dacey - Dechamma" },
  { value: "Dan - Dananjaya", label: "Dan - Dananjaya" },
  { value: "Hanan V", label: "Hanan V"},
  { value: "Indhu G", label: "Indhu G" },
  { value: "Jenifer Dela Cruz", label: "Jenifer Dela Cruz" },
  { value: "Kate - Kavya", label: "Kate - Kavya"}
];
const QaOptions = [
  { value: "Vasantharaj Jeremiah", label: "Vasantharaj Jeremiah" },
  { value: "Shiva Ram", label: "Shiva Ram" },
];
const comOptions = [
  { value: "Murali M", label: "Murali M" },
  { value: "Balaji", label: "Balaji" },
  { value: "Anto M", label: "Anto M" },
];
const DevelopmentOptions = [
  { value: "Tibin", label: "Tibin" },
];
const groupedOptions = [
  {
    label: "Supper Admin",
    options: supperadminOptions
  },
  {
    label: "Admin",
    options: adminOptions
  },
  {
    label: "Project Lead",
    options: projectleadOptions
  },
  {
    label: "Qa",
    options: QaOptions
  },
  {
    label: "Creative Operations Manager",
    options: comOptions
  }
  ,
  {
    label: "Development",
    options: DevelopmentOptions
  }
];

export default class CreateMeeting extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      allBoards:[],
      allBoardLastupdate:'',
      firstHalf:[],
      secondHalf:[],
      orginalUsers: [],
      singleCollection:[],
      showSingle:false,
      initialLoader:true,
      activeUserId:'',
      boardUrl:'',
      actionStatus:false,
      showMenu:true,
      modal: false,
      selectedOption: null,
      selectedOptionhost: null,
      meetingModal:false
    };
   // this.keyPress = this._handleKeyDown.bind(this);
   this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      meetingModal: !this.state.meetingModal
    });
  }

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  handleChangehost = (selectedOptionhost) => {
    this.setState({ selectedOptionhost }, () =>
      console.log(`Option selected:`, this.state.selectedOptionhost)
    );
  };

  componentDidMount =() =>
  {   
    document.title = "HUB - Minutes of Meeting";
    this.checkUserPrivilege(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }

  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


 
  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  // handleChange = (selectedOptions) => {
  //   var size = Object.keys(selectedOptions).length;
  //   if(size>0)
  //   {
  //     this.setState({ selectedUser:selectedOptions[0].collection});
  //   }
  // }
  
  // handleInputChange(input, e) {
  //   console.log("value", input)
  // }

  
  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  render() {
    const { selectedOption } = this.state;
    const { selectedOptionhost } = this.state;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
    <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="Minutes of Meeting" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
      <div className="themeContentBody active_trello">
        <Row>
          <Col sm={12} xl={12}>
            <Card className="cl_helth_report">
               <div className="d-flex justify-content-between align-items-center">
               <h1 class="page-title text-center her_title">KIMP Weekly Meetings</h1>
               <Button className="meet_btn" onClick={this.toggle}>New Meeting</Button>
               </div>
               
               <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Meeting Name</th>
                      <th>Teams</th>
                      <th>Meeting Host</th>
                      <th align="right">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Meeting With PL Teams
                      </td>
                      <td>
                        <ul className="meeting_teams">
                          <li>AU-A1</li>
                          <li>CA-A1</li>
                          <li>MY-A1</li>
                          <li>AU-A1</li>
                          <li>CA-A1</li>
                          <li>MY-A1</li>
                          <li>AU-A1</li>
                          <li>CA-A1</li>
                          <li>MY-A1</li>
                          <li>AU-A1</li>
                          <li>CA-A1</li>
                          <li>MY-A1</li>
                        </ul>
                      </td>
                      <td>
                      <ul className="meeting_teams">
                          <li>Prem</li>
                          <li>Tibin</li>
                          <li>Murali M</li>
                          <li>Hasthik</li>
                          <li>Suhas</li>
                          <li>Megha</li>
                        </ul>
                      </td>
                      <td align="right" className="d-flex">
                        <Button className="meeting_edit"><MaterialIcon icon="edit"/> </Button>
                        <Button className="meeting_delete"><MaterialIcon icon="delete"/></Button>
                      </td>
                    </tr>
                  </tbody>
               </Table>
            </Card>
            </Col>
           
        </Row>
      </div>
      </Row>
      {this.state.meetingModal &&
      (
        <NewMeeting addmeeting={this.state.meetingModal} closemodal={this.toggle} />
      )}
      </Container>
      </div> 
    )
  }
}
