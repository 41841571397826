const ProfileImageReducer = (state='', action) =>
{
    switch(action.type)
    {
        case 'UpdateProfileImage':
            state = action.payload;
            return state;
        default:
            state= Math.floor(Math.random() * 160);
            return state;
    }
}

export default ProfileImageReducer;