import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import Docicon from '../../images/doc_icon.svg'
import Taskicon from '../../images/tasks_icon.svg'
import {urls} from "../../services/UrlList";
import axios from 'axios';
import BoardMembers from './BoardMembers';
import {Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import FilterSearch from "./FilterSearch";
import MaterialIcon from "material-icons-react";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import EditBoard from './EditBoard';
import toast, { Toaster } from 'react-hot-toast';
import ConfirmAction from '../admincomment/ConfirmAction';
import SideMenu from "../NewSideMenu/NewSideMenu";
import AllActivities from './AllActivities';

const notifyEditSuccess = () => toast('Board updated successfully.');
const notifyDeleteSuccess = () => toast('Successfully deleted');
const notifyError = () => toast('Something went wrong. Please try again.');

export default class AllBoardActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      addmember:false ,
      orginalUsers:[],
      selectedUser:'', 
      myId:'',
      project:[],
      projectAccess:[],
      noAccess:false,
      accessDenied:'', 
      projectDetails:[],
      progressBar:true,
      editActive:false,
      showDeleteConfirmation:false,
      deletionId:0,
      showMenu:true,
      noAccessMsg:'Sorry, the project you are looking for is either not exist or don\'t have access',
    };
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.togglememberDisplay = this.togglememberDisplay.bind(this);
  }
//faheem code----------------------------
toggleDisplay() {
  this.setState({
    display: !this.state.display
  });
}
togglememberDisplay() {
  this.setState({
    addmember: !this.state.addmember
  });
}


  componentDidMount () {
    document.title = "KIMPHUB - Board Details";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();    
  };


  componentWillUnmount() {  
  }
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name
      });
    }
  };


  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

 

  findInitials = (word) =>
  {
    var matches = word.match(/\b(\w)/g); 
    var acronym = matches.join(''); 
    return acronym;
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl);
  }


onPeopleChange=(id)=>
{
  //this.props.history.push('/boardsdetails/' + this.props.match.params.id+"/"+id+"?uuid="+this.state.myId);
  window.open('/board/allactivities/'+id+"?uuid="+this.state.myId,'_self');
}

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className="top-bar-inactive bc_module_container">
      <Row>
        <div className="theme_header">
        <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
               <SideMenu  
            activeitem="project" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
          <Toaster position="top-right" containerClassName="notifier"
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },}}
            />
          {this.state.accessDenied ?
          (
            <>
              <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
            </>
          ):
          (
          <>
            {/* {( this.state.myId === 1 || this.state.myId === 4) &&
            ( */}
              <Row>
                <Col>
                    <AllActivities boardid={this.props.match.params.id} 
                    userid={this.props.match.params.uid? this.props.match.params.uid : 0}
                    onpeoplechange={this.onPeopleChange} />
                </Col>
              </Row>
            {/* )} */}
           
           </>
          )}
       </div>
      </Row>
       
      </Container>
      </div>
    )
  }
}
