import React, { Component, Fragment } from "react";
import "./LoanContainer.css";
import { Container, Row, Col, Card, Button,CardTitle } from "reactstrap";
import PageTitle from "../../page-title/PageTitle";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import Header from "../../header/Header";
import LoanDetails from "../LoanDetails/LoanDetails";
import LoanApply from "../LoanApply/LoanApply";
import LoanMakePay from "../LoanMakePay/LoanMakePay";
import LoanSummery from "../LoanSummery/LoanSummery";
import {urls} from "../../../services/UrlList";
import UserPicker from "../LoanDetails/UserPicker"
export default class LoanContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showApply: false,
      showRepay: false,
      myPrivileges: [],
      myId: "",
      loading:false,
      summary:[],
      loanSummaryUpdate:false,
      selectedPeople:'',
      progressBar:false,
      loanApplied:0,
      showMenu:true,
    };
  }
  showApply = () => {
    this.props.showApply();
  };

  showRepay = () => {
    this.props.showRepay();
  };

  onPeopleChange = (people) =>
  {
    this.props.peoplechange(people);
    if(people >0)
    {
      this.setState({showApply: true});
    }else
    {
      this.setState({showApply: false});
    }
  }
  componentDidMount =() =>
  {
    this.checkUserPrivilege();
    const _this = this;
     setTimeout(function() {
      _this.getLoanSummary();
    }, 1000);   
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,       
      });
      if(!login.privileges.includes('loan_manager'))
       {
          this.props.history.push("/loan/summary"); 
       }
    }else
    {
          this.props.history.push("/home");       
    }
  };

  componentDidUpdate(prevState) {  
    if (prevState.loanSummaryUpdate !== this.state.loanSummaryUpdate  && this.state.loanSummaryUpdate === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({loanSummaryUpdate:false});
        _this.getLoanSummary();
      }, 100);  
    } 
  }

  updateSummary = () =>
  {
      this.setState({loanSummaryUpdate: !this.state.loanSummaryUpdate });
  }


  showApply = () => {
    this.setState({ showRepay: false, showApply: !this.state.showApply, loanApplied:0 });

  };

  hideApply = () => {
    this.setState({ showRepay: false, showApply: !this.state.showApply });
    this.getLoanSummary();
  };

  showRepay = () => {
    this.setState({ showApply:false, showRepay: !this.state.showRepay });
  };


  toggleSidebar = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () => {
     this.setState({showMenu: !this.state.showMenu});
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };
  
  onPeopleChange = (people) =>
  {
      if(people>0)
      {
        this.setState({selectedPeople:people, loanApplied:0});
        const _this = this;
        setTimeout(function() {           
          _this.getLoanSummary();
        }, 100);
        }else
        {
           window.location.reload(false);
        }
  }

  refreshList = () =>
  {
      this.setState({loanApplied:1});
  }

  getLoanSummary = () =>
  {
    this.setState({progressBar:true});
    if(this.state.selectedPeople > 0)
    {
      console.log("pppp");
       var url = urls.getLoanSummary+this.state.selectedPeople;
    }else
    {
      console.log("ooo");
      var url = urls.getAdminLoanSummary;
    }
   
    fetch(url,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{
          console.log(response);
          this.setState({progressBar:false,
              summary:response.summary[0]
          })
      })
      .catch(error =>
      { 
          this.setState({progressBar:false});
      })   
  }

  refreshAction = () =>
  {
    this.setState({showRepay: false, loanSummaryUpdate: !this.state.loanSummaryUpdate });
    const _this = this;
     setTimeout(function() {
       console.log("mmm");
    }, 500);
  }

  render() {

    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)} 
        quickactionblock={true}/>  
        {/* <SideMenu activeitem="loan" /> */}
        </div>
        <div className="SideNavbar">
         <SideMenu activeitem="loan" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody themefullwidthContentBoday">
        <Row>
        <Col sm={12} lg={8}>
          <Card className="themeCard log_timer_card">
               <div className="d-flex loan_manage_header">
                  <PageTitle title="Manage Loans" /> 
                  <UserPicker myid={this.props.myid} peoplechange = {this.onPeopleChange} /> 
               </div>
               
               
              {/* {this.state.showApply? 
                (<Button
                  id="loan-apply-loan-btn"
                  className={showApplyClass}
                  onClick={() => {
                    this.props.showApply();
                  }}
                >
                  Apply Loan
                </Button>
                )
                :
                null
              } 
               */}

               <LoanDetails
                loansummary={this.state.summary}
                showApply={this.showApply}
                showRepay={this.showRepay}
                activeApply={this.state.showApply}
                activeRepay={this.state.showRepay}
                myid={this.state.myId}
                peoplechange = {this.onPeopleChange}
                progress={this.state.progressBar}
              />
              {this.state.showApply && (
                <LoanApply active={this.state.showApply}  myid={this.state.selectedPeople} hideaction={this.hideApply}  refreshlist={this.refreshList} />
              )}
              {/* {this.state.showRepay && (
                <LoanMakePay active={this.state.showRepay} myid={this.state.myId} refreshaction={this.refreshAction}  />
              )} */}
              {(this.state.myId && !this.state.loanSummaryUpdate) && (
                <LoanSummery myid={this.state.myId} selectedpeople={this.state.selectedPeople} summaryupdate={this.updateSummary} refreshlist={this.state.loanApplied}  />
              )}  
          </Card>
        </Col>
        <Col sm={12} lg={4}>
            <Card className="themeCard log_timer_card apply_loan_col">
                <CardTitle tag="h1" className="themeCard_title"> Make Payment</CardTitle>
                <div className="react-tabs">
               
                <LoanMakePay active={this.state.showRepay} myid={this.state.myId} refreshaction={this.refreshAction}  />
               
                {/* <ApplyLoan active={this.state.showApply}  myid={this.state.myId} hideaction={this.hideApply} /> */}
                </div>
            </Card> 
        </Col>
        </Row>
        </div>
      </Row>
      </Container>


</div>
    );
  }
}
