import React, { useState, useEffect } from 'react'
import { urls } from '../../services/UrlList';
import axios from 'axios';


export const handleFileDownload = async (surl, filename) =>
  {

    let data = {
      url : surl.replace(process.env.REACT_APP_S3URL, ""),
      filename
    };
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-origin': '*',
  }
  await axios.post(urls.gets3presigned, data, {
    headers: headers
  })
  .then((response) => {
    //window.open(response.data, "_blank");

    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = response.data;
    tempLink.setAttribute('download', filename);
    if (typeof tempLink.download === 'undefined') {
      tempLink.setAttribute('target', '_blank');
    }

    document.body.appendChild(tempLink);
    tempLink.click();

  })
  .catch((error) => {
    //notifyError();
  })
  return filename;
  }


export const handleFileDownloadold = async (url, filename) =>
  {
  //e.stopPropagation();
  fetch(url)
    .then(res => res.blob())
    .then(blobData => fileDownload(blobData, filename))
    .catch(error =>
      {
        window.open(url, "_blank");
      });
  return filename;
  }

  const fileDownload = async (data, filename, mime, bom) => {
    const blobData = typeof bom !== 'undefined' ? [bom, data] : [data];
    //const blob = new Blob(blobData, { type: mime || 'application/octet-stream' });
    const blob = new Blob(blobData, { type: 'application/octet-stream' });
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const blobURL =
        window.URL && window.URL.createObjectURL
          ? window.URL.createObjectURL(blob)
          : window.webkitURL.createObjectURL(blob);
  
      const tempLink = document.createElement('a');
  
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
  
      document.body.appendChild(tempLink);
      tempLink.click();
  
      // Fixes "webkit blob resource error 1"
      setTimeout(() => {
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
      }, 200);
    }
  }