import React from 'react';
import { BrowserRouter as Router,Switch,Route,Link,useRouteMatch} from "react-router-dom";

export default function TimerIcon( props ) {
  const classes = "home-icon menu-icon "+ props.classname;
  return (
    <div className={classes}>
      <Link to={props.to}>
        <div className="cont">      
          <svg width="41px" height="47px">
            <path style={{ fillRule:"evenodd", fill: '#dbdbdb' }}
            d="M21.582,46.839 L21.582,41.605 L19.469,41.605 L19.469,46.839 C8.531,46.261 0.073,37.017 0.463,26.062 C0.852,15.108 9.944,6.488 20.896,6.690 C31.847,6.892 40.616,15.841 40.601,26.803 C40.583,37.475 32.232,46.273 21.582,46.839 ZM32.016,39.592 L33.338,38.268 L30.159,35.092 L28.836,36.415 L32.016,39.592 ZM9.002,39.592 L12.181,36.411 L10.858,35.088 L7.684,38.265 L9.002,39.592 ZM7.420,25.630 L3.679,25.630 L3.679,27.502 L7.420,27.502 L7.420,25.630 ZM9.010,13.914 L7.684,15.241 L10.855,18.415 L12.185,17.091 L9.010,13.914 ZM20.513,9.907 L20.513,11.779 C24.201,11.783 27.588,13.142 30.186,15.397 C33.079,17.908 34.995,21.530 35.261,25.630 L22.990,25.630 L22.383,25.630 L22.383,27.034 C22.383,25.742 21.337,24.694 20.045,24.694 C18.754,24.694 17.707,25.742 17.707,27.034 C17.707,28.326 18.754,29.374 20.045,29.374 C21.337,29.374 22.383,28.326 22.383,27.034 L22.383,27.502 L23.271,27.502 C23.038,29.109 21.662,30.303 20.040,30.307 C18.823,30.309 17.742,29.643 17.176,28.633 C17.743,29.646 18.826,30.314 20.045,30.310 C21.668,30.305 23.041,29.109 23.271,27.502 L36.224,27.502 C36.741,27.502 37.160,27.083 37.160,26.566 C37.149,17.370 29.702,9.918 20.513,9.907 ZM16.938,28.103 C16.973,28.202 17.022,28.292 17.065,28.386 C17.022,28.292 16.973,28.202 16.938,28.103 ZM20.045,27.502 C19.787,27.502 19.578,27.292 19.578,27.034 C19.578,26.775 19.787,26.566 20.045,26.566 C20.304,26.566 20.513,26.775 20.513,27.034 C20.513,27.292 20.304,27.502 20.045,27.502 ZM24.224,4.598 L16.828,4.598 C15.660,4.598 14.714,3.651 14.714,2.483 C14.714,1.315 15.660,0.369 16.828,0.369 L24.224,0.369 C25.391,0.369 26.337,1.315 26.337,2.483 C26.337,3.651 25.391,4.598 24.224,4.598 Z"/>
          </svg>
          <p className="mb-0 text-center mt-1">{props.title}</p>       
        </div>
      </Link>
      <span className="arrow"></span>
    </div>
  )
}