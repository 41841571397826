import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import { urls } from "../../services/UrlList";
import { Container, Row, Col, Input, Button, Label, Breadcrumb, FormGroup, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"

let lastlog = -1;
export default class TaskWorkLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskId: this.props.taskid,
      workLogsLoader:false,
      showWorkLogs:false,
      workLogs:[]
      }
    };

  showWorkLogs = async () => {
    this.setState({workLogsLoader:true});
    const data = {
      taskid: this.state.taskId
    };
    console.log(data);
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskLogs, data, {
      headers: headers
    })
    .then((response) => {
      lastlog = -1;
      this.setState({workLogs: response.data.logs, showWorkLogs:true,workLogsLoader:false});
      //let logs = this.groupArrayOfObjects(response.data.logs, 'name');
      // const _this = this;
      // setTimeout(() => {
      // _this.setState({workLogs: logs});
      // }, 2000);

    })
    .catch((error) => {
      this.setState({workLogs: [], showWorkLogs:false,workLogsLoader:false});
    })
  }


  groupArrayOfObjects = (list, key) => {
    let p =0;
    return list.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  convertTime = (time)=>
  {
    if(time!='')
    {
      let timeminutes = parseInt(time.split('.')[0]);
      let timetaken = '';
      var hours = Math.floor(timeminutes / 60);          
      var minutes = timeminutes % 60;
     
      if(parseInt(time.split('.')[1])>0)
      {
        minutes += Math.floor((time.split('.')[1]) / 60);    
      }
      timetaken  = hours>0? hours+" hr : ": '00 hr : ';
      timetaken  += minutes>0? minutes+" mins": '00 mins';
      return timetaken;
    }
  }
  
  hideWorkLog = () =>
  {
    this.setState({showWorkLogs:false});
  }

  nameShort = (name)=>
  {
    if(name!='')
    {
      var names = name.split(/\s+/);
      let customname ='';
      if(names.length>1)
      {
      names[1] = names[1].substr(0, 1);
      customname = names[0]+" "+names[1];
      }else
      {
        customname = names[0];
      }
      return customname;
    }else
    {
      return name;
    }
  }

  render() {
    return (
        <div>
        <LoadingWrapper isLoading={this.state.workLogsLoader}>
          {this.state.showWorkLogs ?
          (
            <span className='hide-worklog' onClick={this.hideWorkLog}>Hide logs</span>
          ):
          (
            <Button className="show-all-work-logs" onClick={this.showWorkLogs.bind(this)}>Work Logs</Button>
          )}
          
          {this.state.showWorkLogs && this.state.workLogs && this.state.workLogs.length>0 &&
          (
            <>
              <Row className='worklog-head'>
                <Col sm="5">Name</Col>
                <Col sm="3">Shift</Col>
                <Col sm="4">Time Taken</Col>
              </Row>
              {this.state.workLogs.map((wlog, item)=>
              {
                lastlog = lastlog+1;
                return(
                  <Row key={item} className={`worklog-item d-flex justify-content-between ${((this.state.workLogs.length-1)>= lastlog && lastlog>0 && this.state.workLogs[lastlog-1].name == this.state.workLogs[lastlog].name)  ? 'sameuser-log' : 'user-first-log' } `}>
                    <Col sm="5" className='worklog-name'>{this.nameShort(wlog.name)}</Col>
                    <Col sm="3">{wlog.shift_data}</Col>
                    <Col sm="4">{this.convertTime(wlog.total)}</Col>
                   
                  </Row>
                  
                )
                
              })}
            </>
          )}
         
        </LoadingWrapper>
      </div>
    )
  }
}