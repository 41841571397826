import React, { Component, Fragment } from "react";
import "./ApprovalCommentForm.css";
import { InputGroup, Input, Label, Button } from "reactstrap";

export default class ApprovalCommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reviewComment:'',
      typeofReview:''
    };
  }

  updateReviewType =(type) =>
  {
    this.setState({typeofReview:type});
  }
  updateComment =(e)=>
  {
    this.setState({reviewComment:e.target.value});
  }
  onHideForm =() =>
  {
    this.props.show();
  }

  onReviewComplete = (e) =>
  {
   
    if(this.props.ownertype == 'Admin')
    {
      this.props.onreviewadmin(this.state.reviewComment);
    }else{
      this.props.onreviewreporter(this.state.reviewComment);
    }
    
  }

  render() {
    let classname = ['apprCommentBox', this.props.classname].join().replace(',', ' ');       
    return (
      <div className={classname}>
        <span className="close-comment-form" onClick={this.onHideForm.bind(this)}>X</span>
        <p className="title text-left">{this.props.ownertype}</p>
        {/* <div className="checkbox-wrapper d-flex">
          <InputGroup>
            <Input id="approve" type="checkbox" onChange={this.updateReviewType.bind(this,1)}  />
            <Label htmlFor="approve">Approve</Label>
          </InputGroup>
          <InputGroup>
            <Input id="reject" type="checkbox" onChange={this.updateReviewType.bind(this,2)}/>
            <Label htmlFor="approve">Reject</Label>
          </InputGroup>
        </div> */}
        <Input placeholder="Enter comments(optional)" type="textarea" name="text" id="exampleText" value={this.state.reviewComment} onChange={this.updateComment.bind(this)} />
        <Button className="btn btn-green" onClick={this.onReviewComplete.bind(this)}>Submit</Button>
      </div>
    );
  }
}
