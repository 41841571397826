import React, { Component, Fragment } from 'react';
import '../tickets.css'
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup} from 'reactstrap';
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import {urls} from "../../../services/UrlList";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../../svgicon/AttachmentIcon";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import AvatarImg from '../../../images/useravatr.png';
import MaterialIcon from "material-icons-react";
import TicketFileUpload from '../TicketFileUpload';
import TicketLightboxPop from '../TicketLightboxPop';


export default class TicketDetails extends Component {
    fileObj = []; 
    fileArray = [];
    fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      randomNum: Math.floor(Math.random() * 160),
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      imgagePreviews:[],
      showProgress: false,
      commentImages:'',
      commentAttachmentsChange:false,
      editCommentText:'',
      showReminderCommentbox:false,
      commentboxTextError:false,
      showReminderCommentBoxId:'',
      showReminderAdd:'',
      reminderTime:'',
      showReminderCommentboxText:'',
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true
      }
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
  }

  splitCommentImages = ()=>
  {
    this.setState({
      commentImages: this.props.comment.attachments != null? this.props.comment.attachments.split(',') : [],
      editCommentText:this.props.comment.comment
    })          
  }

  componentDidMount = () => {
    this.splitCommentImages();
  };

  stripslashes = (str) => {
    str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
}

showEditOption = (id, comment)=>
{
  this.props.showcommentedit(id, comment);
}

removePreviewImg = (url) =>
{
  var index = this.fileArray.indexOf(url); // 1
  this.fileArray.splice(index, 1);
  this.fileNamesArray.splice(index, 1);
  this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
}

uploadMultipleFiles(e) {
  this.setState({
    uploadingFiles:true
  })
  this.fileObj.push(e.target.files)
  for (let i = 0; i < this.fileObj[0].length; i++)
  {
    var ftpe = this.fileObj[0][i].type;
    var fsize = this.fileObj[0][i].size;
    if((ftpe == 'image/jpeg' || ftpe == 'image/jpeg' || ftpe == 'image/png' ||  
    ftpe == 'image/gif' || ftpe == 'application/pdf') &&  fsize < 2050000)
    {
      const data = new FormData();
      data.append('file', this.fileObj[0][i]);
      data.append('filename', this.fileObj[0][i].name);
      data.append('user_id', this.props.myid);
      axios.post(urls.uploadTicketCommentFiles, data, { 
      // receive two    parameter endpoint url ,form data
      })
      .then(res => { 
          this.setState({
            uploadingFiles:false
          })
      })
      this.fileNamesArray.push(urls.storageUrl+"storage/tickets/comments/"+this.state.folderName+"-"+this.state.myId+"/"+this.fileObj[0][i].name);
      this.fileArray.push(URL.createObjectURL(this.fileObj[0][i]));
    }else{
      this.setState({fileTypeError:true});
      break;
    }
  }
  this.fileObj = [];
  this.setState({ files: this.fileNamesArray, imgagePreviews:this.fileArray })
}

updateticketComment = ()=>
{
  let opts ={
    comment_id: this.props.comment.id,
    ticket_id: this.props.comment.ticket_id,
    comment_details: this.state.editCommentText,
    creater_id: this.props.myid,
    comment_attachments: this.state.commentImages
  };

  console.log(opts);
   
  fetch(urls.updateComment,{
    headers:{
      'Access-Control-Allow-origin':'*',
      'Content-Type':'application/json'
    },
    method: "post",
        body: JSON.stringify(opts)
  })
  .then(response => response.json())
  .then(response =>
  {   
    this.props.commentupdate();
  })
  .catch(error =>
  {
    console.log(error);
  })
}

handleChangeEditor = (value) =>
{
  this.setState({
    editCommentText: value
  })
}

cancelCommentUpdate =()=>
{
  this.props.cancelcommentupdate();
}

setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

showReminderBox = (id,value) =>
{
  this.setState({
    showReminderCommentbox:true,
    showReminderCommentBoxId:id,
    showReminderAdd: value
  })
}

setReminderime = (e)=>
{
  this.setState({
    reminderTime:e,
    reminderTimeError:false
  })
}

addReminderUpdate = () =>
  {
    if(this.state.showReminderAdd === 0)
    {
      if(this.state.showReminderCommentboxText=='')
      {
        this.setState({
          commentboxTextError:true
        })
        return false;
      }
      else if(this.state.reminderTime==='')
      {
        this.setState({
          reminderTimeError:true
        })
        return false;
      }
    }
    else
    { 
      if(this.state.showReminderCommentboxText=='')
      {
        this.setState({
          commentboxTextError:true
        })
        return false;
      }
    }
      this.setState({
        showProgress:true
      })
      let opts ={
        ticket_id: this.props.comment.ticket_id,
        reminderTime: this.state.reminderTime,
        user_id: this.props.myid,
        myname:this.props.myname,
        timezone:this.props.mytimezone,
        reminderupdateid: this.props.comment.id,
        reminder_action: this.state.showReminderAdd,
        reminder_note: this.state.showReminderCommentboxText
      }
      fetch(urls.addTicketReminder,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        if(response.comments.length>0)
        {
          this.setState({
            ticketComments:response.comments,
            reminderTime:'',
            showProgress:false,
            reminderTimeError:false,
            reminderAddingProgress:false,
            showReminderCommentbox:false,
            commentboxTextError:false,
            showReminderCommentBoxId:'',
            showReminderAdd:'',
            showReminderCommentboxText:'',
          })

          this.props.afterreminderupdate(response.comments);
        }
      })
      .catch(error =>
      {
        this.setState({
         reminderAddingProgress:false
        })
      })
  }

  handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  storeCommentFileNames = async (arrayname, item) => {
    let temp = [...this.state.commentImages];
    let filename= item.path+item.file_name;
    await this.setState({ commentImages: []});
    temp.push(filename);
    await this.setState({ commentImages: temp});
  }

  deleteCommentFileNames = async (filename, arrayname) => {
    this.setState({ commentAttachmentsChange: true });
    var assetupdate = [...this.state.commentImages];
    this.setState({ commentImages: []});
    
    var itemkey = assetupdate.map(function (item) { return item; }).indexOf(filename);
    assetupdate.splice(itemkey, 1);
    const _this = this;
    setTimeout(() => {
      _this.setState({ commentImages: assetupdate,commentAttachmentsChange: false });
    }, 500);
  }


  render() {
    return (
        <div className="ticket-posted-comments cmd_viewbox">
          {this.props.showeditoption && this.props.editid == this.props.comment.id?
            (
                  <>
                    <div className="new-ticket-details">
                          <ReactQuill value={this.state.editCommentText}
                          onChange={this.handleChangeEditor}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Enter your comments here..." 
                          theme="snow" />
                      </div>
                      {/* <div className="comment-preview">
                          <div className="preview-description" dangerouslySetInnerHTML={{ __html: this.state.comments }} />
                      </div> */}
                      <div id="" className="flex-fill equalHM replay_upload d-flex preview-with-existing">
                          {/* <div className="comment-attachments">
                            {this.state.commentImages.length>0 &&(
                                <>
                                    {(this.state.commentImages || []).map(url => (
                                        <a href={url} target="_blank"><img  src={url} alt="..." /></a>
                                    ))}
                                </>
                            )}
                        </div> */}
                        <div className="form-group multi-preview">
                            {(this.state.imgagePreviews || []).map(url => (
                                <div className="prv_imgs">
                                    <a href={url} target="_blank"><img src={url} alt="..." /></a>
                                    <span className="remove-img" onClick={this.removePreviewImg.bind(this, url)}></span>
                                </div>
                            ))}
                        </div>
                      </div>
                      <div className="">
                          <TicketFileUpload myid={this.props.myid}
                          folderpath="task/commentattachments"
                          arrayname="commentAttachments"
                          storefiles={this.storeCommentFileNames}
                          />

                          {(this.state.commentImages && this.state.commentImages.length > 0 && !this.state.commentAttachmentsChange) &&
                          (
                            <div class="attachment-items d-flex justify-contents-between">
                              <TicketLightboxPop
                                attachments={this.state.commentImages}
                                deleteitem="yes"
                                deletefiles={this.deleteCommentFileNames}
                                arrayname={'commentAttachments'}
                                listtype={1}
                              />
                            </div>
                            )}
                        </div>

                      {/* <div className="file-wrapper upload-btn-wrapper">
                          <Input multiple type="file" name="attachments" id="attachments" onChange={this.uploadMultipleFiles} ></Input>
                          <Button className="btn_uplod">Attach Files</Button>
                      </div> */}
                      {/* {this.state.fileTypeError &&
                      (
                        <div className="file-type-error">
                          Attachments should be under 2 MB size and in image or pdf format. 
                        </div>
                      )} */}
                      <div className="add-ticket update-comment d-flex justify-content-end">
                        <Button className="edit-ticket-save btn btn-grey btn-update-cancel " onClick={this.cancelCommentUpdate}>Cancel</Button>
                        <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.updateticketComment}>Update Comment</Button>
                      </div>
                  </>
              )
              :
              (
                <>
                  <div className="d-flex justify-content-between comment-meta">
                  <div className="comd_pop_head">
                            <div className="owner user_detail">
                              <div className="user_pic">
                                <img src={urls.storageUrl+"storage/profile/image/"+this.props.comment.commented_by+".jpg?"+this.state.randomNum}  alt="user image"/>
                                </div>
                                <div className="date_n_time">
                                 <h1>{this.props.comment.comment_owner}</h1>
                                  
                                  <div className="d-flex">
                                  <span className="posted-on">
                                    {this.props.comment.posted_date}
                                  </span>
                                  <span className="posted-time">
                                    {this.props.comment.posted_time}
                                  </span>
                                  </div>
                                  

                                </div>
                            </div>
                            <div className="post-date-time">
                            
                            {(this.props.comment.edit_allowed && this.props.comment.type!==2) &&
                         (
                          <span className="edit-ticket-comment" onClick={this.showEditOption.bind(this, this.props.comment.id, this.props.comment.comment)}>
                         <MaterialIcon icon="edit" />
                        </span>
                         )}

                            </div>
                          </div>

                      {/* <div className="comd_pop_head">
                      <span className="posted-by user_detail">
                          <div className="user_pic"></div>
                          {this.props.comment.comment_owner}
                      </span>
                      <div className="post-date-time">
                        <span className="posted-on">
                          {this.props.comment.posted_date}
                        </span>
                          <span className="posted-time">
                          {this.props.comment.posted_time}
                          </span>
                         {(this.props.comment.edit_allowed && this.props.comment.type!==2) &&
                         (
                          <span className="edit-ticket-comment" onClick={this.showEditOption.bind(this, this.props.comment.id, this.props.comment.comment)}>
                          Edit
                        </span>
                         )}
                      </div>
                      </div> */}
                  </div>
                  <div className="comment-text">
                      <Linkify tagName="p" options={this.state.options}>{ ReactHtmlParser( this.props.comment.comment +" ")}{this.props.comment.reminder_time!=null && this.props.comment.reminder_time}</Linkify>
                  </div>
                  <LoadingWrapper isLoading={this.state.showProgress}></LoadingWrapper>
                  {(this.props.comment.reminder_status === 1 )&&
                  (
                    <>
                      <div className="reminder-action-question">Have you taken any action on this ticket?</div>
                      {(this.props.comment.reminder_action === null ) ?
                      (
                        <div class="d-flex reminder-actions-wrap align-items-center">
                          <Input className="first-item reminder-actions" type="radio" name="reminder-actions" value="1" onClick={this.showReminderBox.bind(this.props, this.props.comment.id, 1)} /><label>Yes</label>
                          <Input className="reminder-actions" type="radio" name="reminder-actions" value="1" onClick={this.showReminderBox.bind(this.props, this.props.comment.id, 0)}/><label>No</label>
                        </div>
                      )
                      :
                      (
                        <div class="d-flex reminder-actions-wrap align-items-center">
                          <div className="choosen-action">
                            {this.props.comment.reminder_action==1?
                            (
                              <>
                                <div className="action-text">{'Yes'}</div>
                                <div className="action-comment">{this.props.comment.reminder_note}</div>
                              </>
                            ):
                            (
                              <>
                                <div className="action-text">{'No'}</div>
                                <div className="action-comment">{this.props.comment.reminder_note}</div>
                              </>
                            )}
                          </div>
                         </div>
                      )
                      }
                    </>
                  )}
                  {(this.state.showReminderCommentbox && this.props.comment.id === this.state.showReminderCommentBoxId) &&
                  (
                    <div className="reminder-comment-box">
                       {this.state.showReminderAdd === 0 ?
                       (
                          <span className="new-reminder-comment">Please add your comments below and set a new reminder</span>
                       )
                       :
                       (
                        <span className="new-reminder-comment">Please add your comments below</span>
                       )}
                       <>
                          <Input className={this.state.commentboxTextError? 'field-error' : ''} type="textarea" value={this.state.showReminderCommentboxText} name="showReminderCommentboxText" onChange={this.handleChange} />
                       </>
                       {this.state.showReminderAdd === 0 &&
                       (
                          <div className="row-1 post_date reminder-wrapper">
                              <Label htmlFor="ticket-date-field" className="ticket-date-title remind-at">Remind Me About This Ticket</Label>
                              <div className="reminder-options d-flex align-items-center">
                                <Input type="radio" name="reminderOptions" className="first-item" value="1" onChange={this.setReminderTimeOptions.bind(this)}/>1 hour 
                                <Input type="radio" name="reminderOptions" value="2" onChange={this.setReminderTimeOptions.bind(this)} />2 hours
                                <Input type="radio" name="reminderOptions" value="3" onChange={this.setReminderTimeOptions.bind(this)} />3 hours
                                <Input type="radio" name="reminderOptions" value="5" onChange={this.setReminderTimeOptions.bind(this)} />5 hours
                                <Input type="radio" name="reminderOptions" value="24" onChange={this.setReminderTimeOptions.bind(this)} />1 day
                                <Input type="radio" name="reminderOptions" value="48" onChange={this.setReminderTimeOptions.bind(this)} />2 days
                              </div>
                              <DateTimePicker
                                onChange={this.setReminderime.bind(this)}
                                value={this.state.reminderTime}
                                disableClock={true}
                                minDate={new Date()}
                                format={"dd-MM-y h:mm a"}
                                className={this.state.reminderTimeError? 'field-error' : ''}
                              />
                        </div>  
                       )}
                       <Button className="btn btn-green save-ticket add_cmd_btn" onClick={this.addReminderUpdate}>Add Update</Button>
                    </div>
                  )}
                  <div className="comment-attachments">
                    <SimpleReactLightbox>
                          <SRLWrapper>
                            {this.state.commentImages.length>0 &&(
                            <div className="d-flex flex-wrap">
                                {(this.state.commentImages || []).map(url => (
                                    <>
                                        {url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                                        || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'JPG' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || url.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
                                        (
                                          <a href={url} target="_blank">
                                            <img src={url} alt="..." />
                                          </a>
                                        ):
                                        (
                                          <a href={url} target="_blank" className="attachment-bg">
                                            <AttachmentIcon />
                                          </a>
                                        )}
                                        {/* <a href={url} target="_blank">
                                            <img src={url} alt="..." />
                                          </a>
                                      */}
                                    </>
                                ))}
                            </div>
                            )}
                          </SRLWrapper>
                      </SimpleReactLightbox>
                  </div>
                </>
              )}
        </div>
    )
  }
}
