import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import './Healthreport.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import StarRatings from 'react-star-ratings';
import moment from "moment";
import DatePickerTick from "../filter/DatePickerTick";
import ReactQuill from 'react-quill'; 
import Commenticonblack from '../../images/comment_black.svg';
import Commenticongreen from '../../images/comment_green.svg';
import Statuswating from '../../images/statusicon_waiting.svg';
import Closepopup from '../../images/close-icon.png';
import HealthFilter from "../filter/HealthFilter";
import MaterialIcon from "material-icons-react";

export default class ClientHealthReport extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'',  
      myPrivileges:[],
      orginalUsers: [],
      orginalClients:[],
      singleCollection:[],
      orginalReportsData:[],
      ratingFilter:false,
      initialLoader:false,
      reportsData:[],
      showCommentBox:false,
      showMenu:true
    };
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Client Health Report";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {     
     // _this.getMyTrelloCollection();
        _this.getAllCollections();
        _this.getAllClients();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('health_report'))
    {
      this.props.history.push("/home");
    }
  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  searchReport = (startdate, enddate, collection, client, delayed) =>{  
    this.setState({initialLoader:true});
    let opts ={    
      collection: collection,
      board: client,
      sdate:startdate!=''? startdate.format("YYYY-MM-DD"):'',
      edate:enddate !=''? enddate.format("YYYY-MM-DD"):'',
      delayed:delayed
    };
    fetch(urls.searchHealthReport,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
       // this.mergeBoards(boards,response.rated);
       if(this.state.ratingFilter)
       {
         this.filterByRating(response.reports);
         this.setState({
          orginalReportsData: response.reports,
          initialLoader:false
        });
       }else
       {
        this.setState({
          reportsData: response.reports,
          orginalReportsData: response.reports,
          initialLoader:false
        });
       }
       
    })
  };

  filterByRating = (items) =>
  {
    var list = [];
    var temp = items;
    temp.forEach(p1=>
      {   
      if(p1.rating<=4)
       {
         list.push(p1);
       }
      }) 
    this.setState({reportsData: list});
  }

  filterList = (rating) =>
  {
    if(rating)
    {
      var list = [];
      var temp = this.state.orginalReportsData;
      temp.forEach(p1=>
        {   
        if(p1.rating<=4)
        {
          list.push(p1);
        }
        }) 
      this.setState({reportsData: list});
    }else
    {
      this.setState({reportsData: this.state.orginalReportsData});
    }
  }

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getAllClients = () =>{        
    fetch(urls.getTrelloClients,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalClients: response.clients    
        });            
    })
  };


  
  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  
  showCommentBox = (id) =>
  {
    var showindex = this.state.reportsData.map((el) => el.ticket_id).indexOf(id); 
    this.setState({
          showCBox:true,
          showCommentId:showindex
      })
  }

  closeCommentBox = () =>
  {
    this.setState({
      showCBox:false, 
    });
  }

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu clienthealthreport mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
          <Row>
            <Col sm={12} lg={12}>
            <Card className="cl_helth_report">
             <h1 class="page-title text-center her_title">Client Health Report</h1>
            <HealthFilter peoplelist = {this.state.orginalUsers} clientslist ={this.state.orginalClients}
            searchReportAction={this.searchReport} ratingfilter={this.state.ratingFilter} filterlist={this.filterList} />
              <motion.div 
                initial="hidden"
                animate="visible"
                variants={variants}>
          <LoadingWrapper isLoading={this.state.initialLoader}>
                      
                      
          <Table responsive className="theme_table">
                <thead>
                  <tr>
                    <th>Manager</th>
                    <th>shift</th>
                    <th>Client Name</th>
                    <th>Rating</th>
                    <th>Comments</th>
                    <th>Ticket</th>
                  </tr>
                </thead>
                <tbody>
                {this.state.reportsData.map((item, index) =>
                          {
                        return(
                          <tr>
                           <td>{item.name}</td>
                           <td>{item.shift_date}</td>
                           <td><a  className="report-tab-list" href={"https://trello.com/b/"+item.board_id+"/"+item.board_id} target="_blank">{item.client_name}</a></td> 
                           <td>
                              <StarRatings
                                  rating={item.rating}
                                  starRatedColor="orange"
                                  changeRating={this.changeRating}
                                  numberOfStars={5}
                                  name={item.board_id}
                                  starSpacing="3px"
                                  starDimension="30px"
                                  starEmptyColor="#e4dddd"
                                  starHoverColor="orange"
                                  />
                              </td>

                              <td><span className="comd_icon">
                                  {item.comments != null && item.comments !='' ?
                                  (
                                    <MaterialIcon icon="comment" onClick={this.showCommentBox.bind(this,item.ticket_id)} />
                                    // <img src={Commenticongreen} onClick={this.showCommentBox.bind(this,item.board_id)}/>
                                  )
                                  :
                                  (
                                      null
                                  )}
                              </span></td>
                              {item.ticket_id>0 &&
                              (
                                <td><span className="status_icon">
                                  <a target="_blank" href={"/ticket/"+item.ticket_id}>
                                  <MaterialIcon icon="local_offer"/>

                                  </a>
                                </span></td> 
                              )}
                              
                          </tr>
                        )
                    })}
                </tbody>
          </Table>
                      
                      
                      
                      
                      
                      {/* <div className="health_rpot_header">
                        <Row className="inner-row-wraper">
                            <Col sm="2"><span className="report-tab-head">Manager</span></Col> 
                            <Col sm="1"><span className="report-tab-head">shift</span></Col> 
                            <Col sm="3"><span className="report-tab-head">Client Name</span></Col>  
                            <Col sm="3"><span className="report-tab-head">Rating</span></Col> 
                            <Col sm="2"><span className="report-tab-head text-center">Comments</span></Col> 
                            <Col sm="1"><span className="report-tab-head text-center">Ticket</span></Col> 
                        </Row>
                      </div> */}
                
                      
               
        </LoadingWrapper>
        </motion.div>
        </Card>
            </Col>
          </Row>
        </div>
      </Row>
    
      {this.state.showCommentBox &&
            (
                <>
                    <div className="health-overlay" onClick={this.closeCommentBox}></div>
                    <div className="health-comments">
                    <span className="closebtn"><img src={Closepopup} onClick={this.closeCommentBox}/></span>
                        <div className="star-rating-given">
                            <StarRatings
                                rating={this.state.currentEditRating}
                                starRatedColor="orange"
                                changeRating={this.changeRating}
                                numberOfStars={5}
                                name={this.state.currentEditBoard}
                                starSpacing="3px"
                                starDimension="30px"
                                starEmptyColor="#e4dddd"
                                starHoverColor="orange"
                            />
                        </div>
                        <div className="health-comments-edit">
                                <ReactQuill value={this.state.comments}
                                onChange={this.handleChangeEditor}
                                className={this.state.detailError? 'field-error' : ''}
                                placeholder= "Enter your comments here..." 
                                theme="snow" />
                                <div className="health-comment-field">
                                  <Input type="text" name="trelloLink" 
                                  value={this.state.trelloLink !== null? this.state.trelloLink : '' }
                                  placeholder="Trello Card URL"  onChange={this.handleTextChange}/>
                                </div>
                                <div className="health-comment-field">
                                  <Input type="text" name="basecampLink" 
                                  value={this.state.basecampLink !== null? this.state.basecampLink : '' }
                                  placeholder="KimpHub Task URL" onChange={this.handleTextChange} />
                                </div>
                                <div className="health-comment-field">
                                  <Input type="text" name="designerInvolved" 
                                  value={this.state.designerInvolved !== null? this.state.designerInvolved : '' }
                                  placeholder="Designers Involved"  onChange={this.handleTextChange} />
                                </div>
                        <button type="submit" class="add_cmd_btn" onClick={this.updateRatingWithComment.bind(this)}>Add comment</button>
                        </div>
                    </div>
                </>
            )}
            {(this.state.showCBox && this.state.showCommentId>=0) &&
            (
                <>
                    <div className="health-overlay" onClick={this.closeCommentBox}></div>
                    <div className="health-comments">
                    <span className="closebtn"><img src={Closepopup} onClick={this.closeCommentBox}/></span>
                        <div className="star-rating-given">
                            <StarRatings
                                rating={this.state.reportsData[this.state.showCommentId].rating}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name={this.state.currentEditBoard}
                                starSpacing="3px"
                                starDimension="30px"
                                starEmptyColor="#e4dddd"
                                starHoverColor="orange"
                                isSelectable={false}
                            />
                        </div>
                        <div className="health-comments-edit">
                            <div className="description comment_content" dangerouslySetInnerHTML={{ __html: this.state.reportsData[this.state.showCommentId].comments }} />
                            {this.state.reportsData[this.state.showCommentId].trello_card!=null &&
                            (
                              <div className='health-trello'><span>Trello / 360 Card:</span><a target="_blank" href={`${this.state.reportsData[this.state.showCommentId].trello_card}`}>{this.state.reportsData[this.state.showCommentId].trello_card}</a></div>
                            )}
                            {this.state.reportsData[this.state.showCommentId].basecamp_task!=null &&
                            (
                              <div className='health-trello'><span>KimpHub Card:</span><a target="_blank" href={`${this.state.reportsData[this.state.showCommentId].basecamp_task}`}>{this.state.reportsData[this.state.showCommentId].basecamp_task}</a></div>
                            )}
                         </div>
                    </div>
                </>
            )}
      </Container>
      </div>
    )
  }
}
