import React, { Component, Fragment } from "react";
import "./LiveActivity.css";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from "../header/Header";
import LiveActivityTickMark from "../svgicon/LiveActivityTickMark";
import PageTitle from "../page-title/PageTitle";
import { urls } from "../../services/UrlList";
import LoadingBar from "../common/LoadingBar";
import LiveActivityFilter from "./LiveActivityFilter/LiveActivityFilter";
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {Container, Row, Col, Card, CardTitle, Table} from 'reactstrap';
// Framer Motion
import {motion} from 'framer-motion'

export default class LiveActivity extends Component {
  constructor(props) {
    super(props);
    this.state = {   
      myPrivileges:[], 
      myId:'',    
      rpeople:[],    
      progressBar:true,    
      showMenu:true,  
      isMobileView:0
    }
  }

  componentDidMount = () => {
    document.title = "HUB - Live Activity"; 
    this.checkUserPrivilege();   
    this.renderContentDevice(); 
    this.dataActivity(); 
    
    // this.taskinterval = setInterval(() => this.getLiveData(), 60000);
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") === null)
    {
      this.setState({isLoggedOut:true})
    }else
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid,myBaseId:login.basecampid, showExtBreak:login.showext });
    }
  };

  renderContentDevice = () => {
    if (isMobile) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  
  dataActivity = () => {   
    fetch(urls.activityData, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        console.log("Response", response);
        let list = this.getSortedRecords(response);
        this.setState({
          rpeople: list,
          progressBar: false
        });
      })
      .catch(error => {
        this.setState({
          rpeople: [],
          progressBar: false
        });
      });
  };

  getSortedRecords = response => {
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.uid === p1.uid) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

 

  applyFilter = (activefilters) =>{    
    const users = document.querySelectorAll('.doto-users');
    if(activefilters[0] === 'all')
    {
      users.forEach(user => {
        user.classList.add('item-show');     
      })
    }else
    {
      users.forEach(user => {
        user.classList.remove('item-show');
        var showitem = 1;
        for (var index = 0; index < activefilters.length; index++) { 
          if(!user.classList.contains(activefilters[index]))
          {
             showitem = 0;
             break;
          }         
        } 
        if(showitem === 1)
        {
          user.classList.add('item-show');
        }     
      })
    }
  };
  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.5,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      hidden: { opacity: 0, y: -20 },
      visible: { opacity: 1, y: 0 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
       
       <Row>
        <div className="theme_header">
        <Header {...this.props}  
          logout={this.signOut.bind(this)} 
          onmenuaction={this.toggleSidebar.bind(this)}
          quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="livedata" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
           <Row>
             <Col sm={12}>
                  <Card className="live_activity_page">
                     <CardTitle tag="h1">live activity</CardTitle>
                     <section id="live-view" className={this.state.isMobileView == 1? "in-mobile": "" }>
            { this.state.isMobileView == 0 ?
            (
         <>  
         <LiveActivityFilter filterFunc={this.applyFilter} />
                     <Table responsive className="theme_table">
                     <thead>
                      <tr className="la_first_head">
                        <th colSpan="2"></th>
                        <th colSpan="4" className="text-center">Time</th>
                        <th colSpan="3" className="text-center">Task</th>
                        <th colSpan="1"></th>
                        <th colSpan="3"></th>
                      </tr>
                      <tr className="la_se_head">
                        <th colSpan="2">Name</th>
                        <th colSpan="4" style={{padding:0}}>
                          <tr className="inner_table_header">
                          <th colSpan="3">Shift Time</th>
                          <th colSpan="3">Login Time</th>
                          <th colSpan="3">Last Task Time</th>
                          <th colSpan="3" className="text-left">On Brake</th>
                          </tr>
                        </th>
                        <th colSpan="3" style={{padding:0}}>
                          <tr className="inner_table_header">
                            <th colSpan="6">Assigned</th>
                            <th colSpan="6" className="text-left">Running</th>
                          </tr>
                        </th>
                        <th colSpan="1">R.Work Hours</th>
                        <th colSpan="3">Pending Tasks</th>
                      </tr>
                    </thead>
           
       
            
                  {/* {this.state.progressBar ? <LoadingBar /> : null} */}
                  
                  
                  
                      {this.state.rpeople && this.state.rpeople.length > 0 ?
                        (
                          <div id="la-list-body" style={{display:'contents'}}>
                            <motion.section
                              initial="hidden"
                              animate="visible"
                              variants={list}  style={{display:'contents'}}>
                              { this.state.rpeople.map( (item, index) =>
                                {
                                  return (
                                    <motion.div variants={item} style={{display:'contents'}}>

                    <div 
                                        key={index} 
                                        style={{display:'contents'}}
                                        className={["row no-gutters doto-users item-show"] 
                                          + (item.punch_status === 1? " logged-in " : '') 
                                          + (item.latecomment? " late " : '') 
                                          + (item.task_assigned !=0? ' task-assigned ' : ' task-not-assigned') 
                                          + ((item.break_status == 1 || item.lunch_status == 1 || item.extbreak_status == 1)? " break-active" : 'break-not-active')
                                          + (item.task_running === '1'? ' task-running ' : ' task-not-running ')
                                          +(item.total_pending_tasks <=1? ' running-short ' : ' running-high ')
                                          +(item.designer == 1? ' designer ' : ' others ')
                                      }>
                       
                    <tbody>


                      <tr>
                        <td colSpan="2">{item.employee_name}</td>
                        <td colSpan="4" style={{padding:0}}>
                         <tr className="inner_table_header">
                            <td colSpan="3">{item.shift_start}</td>
                            <td colSpan="3">{item.login_time}</td>
                            <td colSpan="3">{item.login_time}</td>
                            <td colSpan="3"> <LiveActivityTickMark classname={(item.break_status == 1 || item.lunch_status == 1 || item.extbreak_status == 1)? "active" : '' } /></td>
                          </tr>
                        </td>
                        <td ColSpan="3" style={{padding:0}}>
                          <tr className="inner_table_header">
                            <td ColSpan="3"><LiveActivityTickMark  classname={item.task_assigned !=0 ? "active" : '' } /></td>
                            <td ColSpan="3"><LiveActivityTickMark classname={item.task_running === '1' ? "active" : '' } /></td>
                          </tr>
                        </td>
                        <p className={["la-data-body la-rm-time-body"] +(item.extra_mile === 1? ' extra-mile ':'') }>
                        <td colSpan="1">{item.remaining_work_hours}</td>
                        <td colSpan="1" className="text-right">{item.total_pending_tasks}</td>
                        </p>
                      </tr>
                      



                      </tbody>
                      
                      </div>
                  
                      </motion.div>
                                  )
                                })
                              }
                            </motion.section>
                          </div>
                        ) : null
                      }
                 
                
           
            
            

                     </Table>
                     </>

                     ):
            (
              <div>
                <img src="https://image.flaticon.com/icons/svg/136/136355.svg"></img>
                <div className="mobile-view-blocked text-center">You can't access via mobile.</div>
              </div>             
              ) 
          }      
        </section>
        <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.progressBar}>
                  </LoadingWrapper>
                  </Card>
             </Col>
           </Row>
        </div>
      </Row>

      </Container>
      </div>
    );
  }
}
