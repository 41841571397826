import React, { useState, useEffect } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { UnreadNotifyCount, UnreadNotifyMessages, ReadNotifyMessages } from '../redux/actions';
import { urls } from '../../services/UrlList';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken} from 'firebase/messaging';
import axios from 'axios';


export default function TokenGenerator (props)  {
  const dispatch = useDispatch();
  var firebaseConfig = {
    apiKey: "AIzaSyAUCeD-bnjNgmaKjgi3e-6M6NmNEdRFkcU",
    authDomain: "kimkim-4b744.firebaseapp.com",
    projectId: "kimkim-4b744",
    storageBucket: "kimkim-4b744.appspot.com",
    messagingSenderId: "1099135278336",
    appId: "1:1099135278336:web:038642b64ad21d772327e5" 
 };
 initializeApp(firebaseConfig);

 const messaging = getMessaging();
  
  useEffect(() => {
    var login = JSON.parse( localStorage.getItem("user") );
    getToken(messaging, { vapidKey: 'BM4OzkefOWzUutcmYok2bFEQSA7ZMrlyLj0VyLfwvq-clAG1umSiM-7Xea6kj3R6n0q3vNqphKD48pJ4rlgUJa0' })
      .then((currentToken) => {
        if (currentToken) {
        // console.log('current token for client: ', currentToken);
          const data = {
            socket_id: currentToken,
            //tokenupdate: (localStorage.getItem("firstlogin") && localStorage.getItem("firstlogin") === 1) ? 1 : 0,
            tokenupdate: 1,
            sid: localStorage.getItem("superadmin"),
            lid:login.uid
          };

          const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin':'*',
          }
          axios.post(urls.updateMySocket, data, {
              headers: headers
            })
            .then((response) => {
                // if(response.data.notifications.length>0)
                // {
                  //console.log(response.data.notifications);
                  //return response.data;
                  dispatch(UnreadNotifyCount(response.data.notifications.length));
                  dispatch(UnreadNotifyMessages(response.data.notifications));
                  dispatch(ReadNotifyMessages(response.data.readnotifications))
                // }
             
              //console.log(BaseValues());
            })
            .catch((error) => {
              console.log(error);
            })
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  },[]);

  return (
      <></>
  );
};