import React, { Component } from "react";
import { BrowserRouter as Router,Route,Link} from "react-router-dom";

export default class DeleteIconLog extends Component {
  constructor(props) {
    super(props);
    
  }

  deleteItem = () =>
  {
    this.props.deletelogentry(this.props.itemid);
  }

  render() 
  {
  const classes = "delete-icon "+ this.props.classname;
  return (
    <div className={classes} onClick={this.deleteItem.bind(this)}>
      <div className="cont">        
          <svg width="22px" height="22px">
            <path id="Path_17" data-name="Path 17" d="M64.444,138.537a1.932,1.932,0,0,0,1.929,1.929h7.718a1.932,1.932,0,0,0,1.929-1.929v-9.648H64.444Z" 
            transform="translate(-63.479 -125.03)" fill="#e0e0e0"/>
            <path id="Path_18" data-name="Path 18" d="M40.9.965V0H37.046V.965H32.222V2.894H45.728V.965Z" 
            transform="translate(-32.222)" fill="#e0e0e0"/>
          </svg>        
        <p className="mb-0 text-center mt-1">{this.props.title}</p>
      </div>
      <span className="arrow"></span>
    </div>
  )
}
}