import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../../services/UrlList";
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';

export default class YearPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sYear: new Date().getFullYear(),
    };  
  }
  componentDidMount = () => {
    this.yearRender();  
  };

  onChangeYear = (e) => {
    this.props.onyearchange(e.target.value);
  };


  yearRender = () =>  {
    const optionsval = [];
    var sYear = new Date().getFullYear();
    for (var i=2020; i <= sYear; i++) {
      optionsval.push(<option selected={this.props.filteryear == i? 'selected':''} value={i}>{i}</option>);
      
    }
    return optionsval;
  };
    render() {
      return (
        <div><Input id="yearfilter" className="dropfilt" type="select" onChange={this.onChangeYear.bind(this)} >
            <option value=''>Select Year</option>
            {this.yearRender()}
            </Input>
        </div>
      );
    }
}
