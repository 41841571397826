import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import './SwitchUser.css'

export default class SwitchUser extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      myPrivileges:[],
      showMenu:true,    
      speed:'', 
      showProgress:true,
      showButtonText:'Test Speed',
      showSpeedTest:false,
      resultSubmitted:false,
      speedCount:0,
      resultUrl:'',
      urlError:false,
    };
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Internet Speed Test";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
      _this.showSpeedTest(); 
    }, 500);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  showSpeedTest = () =>
  {
    let opts ={
      user_id: this.state.myId
    }
    fetch(urls.showSpeedTest,{
      headers:{
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      },
      method: "post",
          body: JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {   
      console.log(response);
      this.setState({showProgress:false, showSpeedTest: response.show == 1? true : false});
    })
    .catch(error =>
    {
      this.setState({showProgress:false, showSpeedTest: false});
    })
  }

  SaveMySpeed = () =>
  {
    if(this.state.resultUrl == '')
    {
        this.setState({urlError:true});
        return false;
    }else
    {
      this.setState({showProgress:true});
      let opts ={
        resulturl: this.state.resultUrl,
        user_id: this.state.myId
      }
      fetch(urls.saveSpeedTest,{
        headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
        },
        method: "post",
            body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {   
        this.setState({showSpeedTest:false, resultSubmitted:true, showProgress:false});
      })
      .catch(error =>
      {
        this.setState({showProgress:false});
      })
    }
  }

  handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value, urlError:false });
	};

  render() {
    return (
      <Container fluid>
        <Fragment>
          <Row>
          <Col sm="2">
              <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
          </Col>
          <Col sm="10">
              <Header {...this.props}  classname="tick-timer-header"  logout={this.signOut.bind(this)} onmenuaction={this.toggleSidebar.bind(this)} />
              <Container>
                <Row>
                <Col sm="12">
                    <h1 class="page-title text-center">Test Internet Speed</h1>
                    <LoadingWrapper isLoading={this.state.showProgress}>
                    {this.state.showSpeedTest ?
                    (
                      <div>
                        <div className="d-flex results-bar">
                          <input placeholder="Run speed test then copy and paste result link here." className={this.state.urlError ? "urlerror sresult" : 'sresult' } type="text" value={this.state.resultUrl} name="resultUrl" onChange={this.handleChange} />
                          <button className="btn btn-secondaray btn-green save-sresult" onClick={this.SaveMySpeed}>Save</button>
                        </div>
                        <div>
                              <div className="speedtest-wrap">
                                  <iframe id="speedframe" width="94%" height="650px" frameborder="0" src="https://kimp.speedtestcustom.com"></iframe>
                              </div>
                        </div>
                      </div>
                    ):
                    (
                      <>
                      {this.state.resultSubmitted ? 
                      (
                          <div className="speed-test-submited">
                            Thank you for taking internet speed test.
                          </div>
                      )
                      :
                      (
                        <div className="no-pending-speed">
                          Sorry, it looks like you don't have an active shift or there is no pending request to take your internet speed test. 
                        </div>
                      )}
                      </>
                    )}
                    </LoadingWrapper>
                 </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Fragment>
      </Container>
    )
  }
}