import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import Homebackicon from '../../images/bc_homeicon.svg'
import Docicon from '../../images/doc_icon.svg'
import Taskicon from '../../images/tasks_icon.svg'
import {urls} from "../../services/UrlList";
import axios from 'axios';
import BoardMembers from './BoardMembers';
import {Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import AccessDenied from "./AccessDenied";
import FilterSearch from "./FilterSearch";
import MaterialIcon from "material-icons-react";
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import ProjectCurrentStatus from './ProjectCurrentStatus';
import EditBoard from './EditBoard';
import toast, { Toaster } from 'react-hot-toast';
import ConfirmAction from '../admincomment/ConfirmAction';
import SideMenu from "../NewSideMenu/NewSideMenu";
import BoardActivities from './BoardActivities';
import MailboxEmail from './MailboxEmail';

const notifyEditSuccess = () => toast('Board updated successfully.');
const notifyDeleteSuccess = () => toast('Successfully deleted');
const notifyError = () => toast('Something went wrong. Please try again.');

export default class BcboardsDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
      addmember:false ,
      orginalUsers:[],
      selectedUser:'', 
      myId:'',
      project:[],
      projectAccess:[],
      noAccess:false,
      accessDenied:'',
      projectDetails:[],
      progressBar:true,
      editActive:false,
      showDeleteConfirmation:false,
      deletionId:0,
      showMenu:true,
      noAccessMsg:'Sorry, the project you are looking for is either not exist or don\'t have access',
    };
    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.togglememberDisplay = this.togglememberDisplay.bind(this);
  }
//faheem code----------------------------
toggleDisplay() {
  this.setState({
    display: !this.state.display
  });
}
togglememberDisplay() {
  this.setState({
    addmember: !this.state.addmember
  });
}

//-----------------------------------------
  // isBottom(el){
  //   return el.getBoundingClientRect().bottom <= window.innerHeight;
  // }

  componentDidMount () {
    document.title = "KIMPHUB - Board Details";
    AccessCheck('access_projects_management_module');
    this.checkUserPrivilege();
    this.getFullUsers();
    const _this = this;
    setTimeout(()=>
    {
      _this.getProjectDetails();
    }, 100);
    
  };


  componentWillUnmount() {  
    this.getFullUsers();
    this.getProjectDetails();
  }


  getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),          
        });            
    })
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name
      });
    }
  };


  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getProjectDetails = () =>
  {
    const data = {
      project_id: this.props.match.params.id,
      owner: this.state.myId,
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getProjectDetails, data, {
        headers: headers
      })
      .then((response) => {
          this.setState({
            accessDenied: response.data.valid === 10 ? true : false,
            project: response.data.valid !== 10 && response.data.project_details[0],
            projectAccess: response.data.valid !== 10 && JSON.parse(response.data.project_details[0].board_access_stringified),
            progressBar:false
          })
      })
      .catch((error) => {
        this.setState({
            accessDenied: true,
            project: [],
            projectAccess: '',
            progressBar:false
        })
      })
  }

  findInitials = (word) =>
  {
    var matches = word.match(/\b(\w)/g); 
    var acronym = matches.join(''); 
    return acronym;
  }

  breadNavigation = (murl)=>
  {
    this.props.history.push(murl);
  }


  updateProjectChange = (value, memberid, action) =>
  {
    this.setState({
      projectAccess:value
    })
    this.updateProjectMembers(this.state.project,this.state.project.board_id, value, memberid, action);
  }

  updateProjectMembers =(project,pid,members, newmember, action)=>
  {
    const data = {
      actmember: newmember,
      owner: this.state.myId,
      members: JSON.stringify(members),
      action: action,
      board_id: pid,
      bid_cpt:project.bid_cpt,
      board_name: project.board_name
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.editBoard, data, {
        headers: headers
      })
      .then((response) => {
      })
      .catch((error) => {
        //notifyError();
      })
  }

  showDocandFile = () =>
  {
    this.props.history.push("/docandfiles/"+this.props.match.params.id+"?uuid="+this.state.myId,);
  }
  showMailbox = () =>
  {
    this.props.history.push("/mailbox/"+this.props.match.params.id+"?uuid="+this.state.myId,);
  }
  showTasks = () =>
  {
    if(this.state.project.board_type == 1)
    {
      this.props.history.push("/task-list/"+this.props.match.params.id+"?uuid="+this.state.myId);
    }else
    {
      if(this.state.project.view_type==0)
      {
        this.props.history.push("/internal-task-list/"+this.props.match.params.id+"?uuid="+this.state.myId);
      }else
      {
        this.props.history.push("/internal-task-grid/"+this.props.match.params.id+"?uuid="+this.state.myId);
      }
    }
  }

  goToHome = () =>
  {
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  goToProjectsHome = () =>
  {
    this.props.history.push("/boards"+"?uuid="+this.state.myId);
  }

  enableForEditing = ()=>
  {
    this.setState({
      editActive:true,
      editProject:this.state.project,
      display:true
    })
  }

  editBoard =(project) =>
  {
    this.setState({project:[]});
    this.setState({project:project,editActive:false});
    notifyEditSuccess();
  }
  toggleDisplay() {
    this.setState({
      editActive:false,
    });
  }

  deleteProjectAsk = async (pid) =>
  {
    this.setState({
      showDeleteConfirmation:true,
      deletionId:pid
    })
  }

  deleteProject = () =>
  {
    const data = {
      owner: this.state.myId,
      project_id: this.state.deletionId
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.deleteKProject, data, {
        headers: headers
      })
      .then((response) => {
          response.data.success === 10 &&  notifyError();
          if(response.data.success === 1)
          {
            notifyDeleteSuccess();
            this.goToProjectsHome();
          }

      })
      .catch((error) => {
        notifyError();
        this.setState({showDeleteConfirmation: false, deletionId:0});
      })
  }

  closeDialogue = () => {
    this.setState({
      showDeleteConfirmation: false,
      hideshowbox:true
    });
  };

onPeopleChange=(id)=>
{
  //this.props.history.push('/boardsdetails/' + this.props.match.params.id+"/"+id+"?uuid="+this.state.myId);
  window.open('/boardsdetails/' + this.props.match.params.id+"/"+id+"?uuid="+this.state.myId,'_self');
}

  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className="top-bar-inactive bc_module_container">
      <Row>
        <div className="theme_header">
        <Header {...this.props}  className="tick-timer-header" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={false} />
               <SideMenu  
            activeitem="project" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
        </div>
       <div className="themeContentBody themefullwidthContentBoday">
          <Toaster position="top-right" containerClassName="notifier"
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },}}
            />
       <LoadingWrapper isLoading={this.state.progressBar}>
          {this.state.accessDenied ?
          (
            <>
              <AccessDenied gotohome={this.goToHome} gotoprojectshome={this.goToProjectsHome} />
            </>
          ):
          (
          <>
            <Row>
              <div className="bc_module">
                <Col sm="12">
                <div className="bc_Board_wrapper">
                  <h2 className="section_title d-flex align-items-center justify-content-center">{this.state.project.board_name}
                    {(this.state.project.board_name && this.state.project.board_name.length>0) &&
                    (
                      <ProjectCurrentStatus 
                      graphics={this.state.project.graphics_pack}
                      video={this.state.project.video_pack}
                      graphics_video={this.state.project.graphics_video_pack}
                      whitelabel={this.state.project.white_label}
                      clientstatus={this.state.project.client_status} />
                    )}
                  </h2>
                  <p className="board_discrption">{this.state.project.short_description}</p>
                  <MailboxEmail canva_access={this.state.project.canva_access} />
                  <div className="page_header_wrap mob_board_det">
                    <Row>
                      <Col sm={10}>
                      <div className="memb_avatarlist mobile_none_member">
                          {this.state.projectAccess.length>0 &&
                          (
                            <ul className='desktop-only-view'>
                              {this.state.projectAccess.slice(0, 20).map((member, index)=>
                              {
                                return(
                                  <li className="member-without-avatar" key={member.id}>
                                    {/*this.findInitials(member.name)*/}
                                    <img src={urls.storageUrl+"storage/profile/image/"+member.id+".jpg"} title={member.name} alt={this.findInitials(member.name)} />
                                  </li>
                                )
                              })}
                              {this.state.projectAccess.length>20 &&
                              (
                                <li className='mem-count'>{"+"+(this.state.projectAccess.length -20)}</li>
                              )}
                            </ul>
                          )}
                          {(AccessCheckBlock('add_remove_member_project') && this.state.project.board_id!=1001) &&
                          (<Button className="add_member_link" onClick={this.togglememberDisplay}>Add/Remove People</Button> )
                          }
                          
                      </div>
                      <div className="mobile_show_member">
                      {this.state.projectAccess.length>20 &&
                          (
                            <div className='mobile-only-view mem-count'>{(this.state.projectAccess.length)}</div>
                          )}
                      </div>
                      </Col>
                      <Col sm={2}>
                        <div className="search-box">
                            {AccessCheckBlock('edit_project') &&
                            (<a title="Edit Board" className="edit_board_detail_icon" onClick={this.enableForEditing}><MaterialIcon icon="edit"  /></a>)
                            }
                            {AccessCheckBlock('delete_project') &&
                            (<a title="Delete Board" className="edit_board_detail_icon" onClick={()=>this.deleteProjectAsk(this.state.project.board_id)}><MaterialIcon icon="delete"  /></a>)
                            }
                            <a title="Back to Boards" className="edit_board_detail_icon" onClick={this.breadNavigation.bind(this,'/boards'+"?uuid="+this.state.myId)} > <MaterialIcon icon="fact_check"  /> </a>
                            <a className="home_button_box" onClick={this.goToHome}><img src={Homebackicon} alt="back" /></a>
                        </div>
                      </Col>
                    </Row>
                   </div>
                  </div>
                  <div className="breadcrumb_boards">
                    <Breadcrumb>
                      <BreadcrumbItem><a href={`/boards?uuid=${this.state.myId}`} >Boards</a></BreadcrumbItem>
                      <BreadcrumbItem active>Board Details</BreadcrumbItem>
                    </Breadcrumb>
                    </div>
              </Col>
                <div className="center-align"> 
                <Col sm="12">
                  <div className="board_detail_wrapper">
                    <div className="boardcard card" onClick={this.showTasks}>
                      <span className="doc_iconbox"><MaterialIcon icon="task"/> </span>
                      <div className="task_card_detail">
                      <h2 className="board_name">Cards</h2>
                      <p className="discption">Make lists of work that needs to get done, assign items, set due dates, and discuss.</p>
                      </div>
                    </div>
                    <div className="boardcard card" onClick={this.showDocandFile}>
                      <span className="doc_iconbox"><MaterialIcon icon="folder" /></span>
                      <div className="task_card_detail">
                      <h2 className="board_name">Docs & Files</h2>
                      <p className="discption">Share docs, files, images, and spreadsheets. Organize in folders so they’re easy to find.</p>
                      </div>
                    </div>
                    {this.state.project?.canva_access!= null && this.state.project?.canva_access!='' &&
                    (
                      <div className="boardcard card" onClick={this.showMailbox}>
                        <span className="doc_iconbox"><MaterialIcon icon="email" /></span>
                        <div className="task_card_detail">
                        <h2 className="board_name">Mailbox</h2>
                        <p className="discption">View the mailbox to get your login codes for Freepik, Canva, Adobe, etc.</p>
                        </div>
                      </div>
                    )}
                    
                  </div>
                </Col>
                </div>
              </div>
            </Row>
            {/* {( this.state.myId === 1 || this.state.myId === 4) &&
            ( */}
              <Row>
                <Col>
                    <BoardActivities boardid={this.props.match.params.id} 
                    userid={this.props.match.params.uid? this.props.match.params.uid : 0}
                    onpeoplechange={this.onPeopleChange} />
                </Col>
              </Row>
            {/* )} */}
           
           </>
          )}
          </LoadingWrapper>
       </div>
      </Row>
       
      </Container>
      {this.state.addmember && 
        <BoardMembers 
          myid={this.state.myId}
          hidememeberpopup={this.togglememberDisplay} 
          project={this.state.project} 
          allmembers={this.state.orginalUsers} 
          updatechange={this.updateProjectChange} 
        />
      }
      {this.state.editActive &&
      (
        <>
          <div className="overlay_bc" onClick={this.toggleDisplay}></div> 
          <EditBoard userid={this.state.myId} editboard={this.editBoard} project={this.state.project} />
        </>
      )}

          {this.state.showDeleteConfirmation ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showDeleteConfirmation}
            note="*This action cannot be undone, are you sure?"
            content="You are about to delete this board."
            handledeletion={this.deleteProject}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deleteproject"
          />
        ) : null}

      </div>
    )
  }
}
