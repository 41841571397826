import React, { Component, Fragment } from "react";
import { Card, Button } from "reactstrap";
import ReactTooltip from "react-tooltip";
import ConfirmAction from "../../../admincomment/ConfirmAction";
import LeaveStatusComment from "./LeaveStatusComment";

export default class LeaveStatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWithdrawalDialogue:false,
    };
  }

  showWithDrawBox = () =>
  {
    this.setState({showWithdrawalDialogue:true});
  }

  closeDialogue = () => {
    this.setState({
      showWithdrawalDialogue: false
    });
  };

  handleWithdraw = () => {
    this.setState({ showWithdrawalDialogue: !this.state.showWithdrawalDialogue });  
    this.props.withdrawaction(this.props.leave);
  };

  render() {
    return (
      <Card className="leave-status-card d-flex">
        <div className="day leave-status-col">
          <p>Day(s)</p>
          <h4>{this.props.leave.mdate}</h4>
        </div>
        <div className="date leave-status-col">
          <p>Applied For</p>
          <h4>{this.props.leave.applied_for}</h4>
        </div>
        <div className="type leave-status-col">
          <p>Type</p>
          <h4>{this.props.leave.leave_type == 'General Leave' ? 'Personal Leave': this.props.leave.leave_type}</h4>
        </div>
        <div className="approved-by leave-status-col">
          <p>Reviewed By</p>
          <h4>{this.props.leave.approved_by}</h4>
        </div>
        <div className="status leave-status-col pending">
          <p>Status</p>
          <h4>{this.props.leave.status}</h4>
        </div>
        <div className="Comments leave-status-col">
          <p>Comments</p>
          <div className="leave-comment-btn-wrapper">
            {this.props.leave.leave_reason != "" ? (
              // <LeaveStatusComment date={this.props.leave} />
              <Fragment>
                <button
                  // className="leave-comment-icon"
                  className="btn btn-orange inline leave-comment-btn"
                  data-tip={this.props.leave.id}
                  data-for={"usercomments" + this.props.leave.id}
                >
                  I
                </button>
                <ReactTooltip
                  id={"usercomments" + this.props.leave.id}
                  type="warning"
                  data-effect="solid"
                  data-multiline="true"
                >
                  <span>{this.props.leave.leave_reason}</span>
                </ReactTooltip>

              </Fragment>
            ) : null}
            {this.props.leave.manager_comments != "" &&
            this.props.leave.manager_comments != null ? (
              // <LeaveStatusComment date={this.props.leave} />
              <Fragment>
                <button
                  // className="leave-comment-icon"
                  className="btn btn-blue leave-comment-btn"
                  data-tip={this.props.leave.id}
                  data-for={"reportercomments" + this.props.leave.id}
                >
                  R
                </button>
                <ReactTooltip
                  id={"reportercomments" + this.props.leave.id}
                  type="info"
                  data-effect="solid"
                  data-multiline="true"
                >
                  <span>{this.props.leave.manager_comments}</span>
                </ReactTooltip>
              </Fragment>
            ) : null}
            {this.props.leave.admin_comments != "" &&
            this.props.leave.admin_comments != null ? (
              // <LeaveStatusComment date={this.props.leave} />
              <Fragment>
                <button
                  // className="leave-comment-icon"
                  className="btn btn-green leave-comment-btn"
                  data-tip={this.props.leave.id}
                  data-for={"admincomments" + this.props.leave.id}
                >
                  A
                </button>
                <ReactTooltip
                  id={"admincomments" + this.props.leave.id}
                  type="success"
                  data-effect="solid"
                  data-multiline="true"
                >
                  <span>{this.props.leave.admin_comments}</span>
                </ReactTooltip>
              </Fragment>
            ) : null}
          </div>
        </div>
        <div className="leave-cancel-btn leave-status-col">
        <p>Action</p>
          {this.props.leave.status === "Pending" ? (
            <Button
              className="btn-green under-line-btn"
              onClick={this.showWithDrawBox.bind(this)}
            >
              Withdraw
            </Button>
          ) : (
            <Button className="btn-disabled under-line-btn" disabled>
              Withdraw
            </Button>
          )}
        </div>
        {this.state.showWithdrawalDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showWithdrawalDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to withdraw your leave request"
            handleCompletionConfirm={this.handleWithdraw}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
      </Card>
    );
  }
}
