import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./ColleaguesTask.css";
import { Input, Container, Row, Col, Card, Button, Label } from "reactstrap";
import Header from "../header/Header";
import { urls } from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import TaksSingle from "./task-single/TaskSingle";
import PageTitle from "../page-title/PageTitle";
import Alertlogmsg from "../messages/Alertlogmsg";
import LogAmend from "./task-single/LogAmend";
import { isMobile } from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { motion } from 'framer-motion';
import TrelloCard from "./task-single/TrelloCard";
import ConfirmAction from "../admincomment/ConfirmAction";
import BaseUserPicker from "../filter/BaseUserPicker";

const card = "carddetails : ";

export default class ColleagueTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTaks: [1, 2, 3],
      selectedUser: '',
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      teamId: '',
      showProgress: false,
      tasksRunning: [],
      process: "",
      runningTaskStatus: 0,
      showRunningMsg: false,
      expandViewId: 0,
      trelloWrong: false,
      showNotLoggedinMsg: false,
      taskStillRunning: false,
      breakStillRunning: false,
      showCompletion: false,
      showMenu: true,
      cardDisplayNum: 0,
      CardDisplayTop: 30,
      taskCloseCount: 0,
      taskCloseWait: false,
      cardDetails1: {},
      cardComments1: [],
      cardAttachments1: [],
      displayTrelloCard1: false,
      userMessage: 'Please select a user from the dropdown',
      taskNotRunning: '',
      showInternalCommentBox: false,
      internalComment: '',
      commenttask_id: '',
      commenttid_cpt: '',
      commentproject_id: '',
      commentproject_name: '',
      commenttask_name: '',
      commentInternalError: false,
      submissionProgress: false,
      showCompletionDialogue: false,
      ctid: '',
      cpid: '',
      ctype: '',
      punchStatus:'',
      breakStatus: '',
      lunchStatus: '',
      extStatus: '',
      lockStatus: '',
      logActivities: [],
      shiftExist: true,
      allowLeaveException:0,
      ownProfile:false,
      hrActive: false,
      timerColumns: 4,
      breakEnabled:false,
      shiftDay:''
    };
  }

  componentDidMount = () => {
    document.title = "HUB - Team Timer";
    this.checkUserPrivilege();
    this.renderContentDevice();
    //this.dotoTracking();
    const _this = this;
    setTimeout(function () {
      //Start the timer
      //_this.showMyTasks();
    }, 1000);
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        teamId: login.user_team,
        myTimezone: login.my_timezone
      });
      if (!login.privileges.includes('kimp_team')) {
        this.props.history.push("/home");
      }
      if (login.privileges.includes("i_am_hradmin") || login.privileges.includes("i_am_admin")) {
        this.setState({ hrActive: true, timerColumns: 3 });
      }
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
      this.setState({ isMobileView: 1 });
    }
    if (isMobile) {
      this.setState({ showMenu: true });
    }
  };

  toggleSidebar = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }

  hideMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  }


  componentWillUnmount = () => {
  }

  componentDidUpdate(prevState) {
    if (
      prevState.showStatusChangeMsg !== this.state.showStatusChangeMsg &&
      this.state.showStatusChangeMsg === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ showStatusChangeMsg: false });
      }, 6000);
    }

    if (
      prevState.showRunningMsg !== this.state.showRunningMsg &&
      this.state.showRunningMsg === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ showRunningMsg: false });
      }, 6000);
    }

    if (
      prevState.showNotLoggedinMsg !== this.state.showNotLoggedinMsg &&
      this.state.showNotLoggedinMsg === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ showNotLoggedinMsg: false });
      }, 6000);
    }

    if (
      prevState.taskStillRunning !== this.state.taskStillRunning &&
      this.state.taskStillRunning === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ taskStillRunning: false });
      }, 6000);
    }

    if (
      prevState.trelloWrong !== this.state.trelloWrong &&
      this.state.trelloWrong === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ trelloWrong: false });
      }, 6000);
    }

    if (
      prevState.notAuthorizedTask !== this.state.notAuthorizedTask &&
      this.state.notAuthorizedTask === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ notAuthorizedTask: false });
      }, 10000);
    }

    if (
      prevState.taskCloseWait !== this.state.taskCloseWait &&
      this.state.taskCloseWait === true
    ) {
      const _this = this;
      setTimeout(function () {
        _this.setState({ taskCloseWait: false, taskCloseCount: 0 });
      }, 10000);
    }

  }

  showMyTasks() {
    this.setState({
      showProgress: true
    });
    fetch(urls.getMyTasksRunning + this.state.selectedUser, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        if (response["task_running"] == 1) {
          this.setState({ taskNotRunning: '' });
        } else {
          this.setState({ taskNotRunning: 'No timer running' });
        }
        this.setState({
          taskRunning: (response['trunning'] && response['trunning'][0].project_id) ? this.sortMyTasks(response['trunning']) : '',
          runningTaskStatus: response["task_running"],
          pauseTaskStatus: response["task_paused"],
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({
          taskRunning: [],
          runningTaskStatus: "",
          pauseTaskStatus: "",
          showProgress: false,
          taskNotRunning: 'No task running'
        });
      });
  }

  sortMyTasks = (response) => {
    let myarr = response;
    myarr.sort(function (a, b) {
      return parseInt(b.running_status) - parseInt(a.running_status);
    });
    return myarr;
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  triggerActions = (tid_cpt, process, taskid, projectid, taskname, projectname) => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    if (projectid == 'gIiAZmVlZoCIgA==') {
      this.triggerActionsInternal(tid_cpt, process, taskid, projectid, taskname, projectname);
    } else {
      this.setState({
        showProgress: true,
        process: process
      });
      let opts = {
        project_id: projectid,
        tid_cpt: tid_cpt,
        task_id: taskid,
        task_name: taskname,
        project_name: projectname,
        user_id: this.state.selectedUser,
        system_user_id: this.state.selectedUser,
        colleagueend:1
      };
      fetch(urls.endProjectTask, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {
          if (response.success == 1) {
            this.setState({ taskNotRunning: 'No task running', taskRunning: [] });
          }
          this.setState({
            showProgress: true,
            showNotLoggedinMsg: response[0].error === 2 ? true : false,
            breakStillRunning: response[0].error === 3 ? true : false,
          });
          if (response[0].error !== 2 && response[0].error !== 3) {
            this.setState({ taskRunning: [], showProgress: false });
          }

        })
        .catch(error => {
          this.setState({ showProgress: false });
        });
    }
  };


  triggerActionsInternal = (tid_cpt, process, taskid, projectid, taskname, projectname) => {
    this.setState({
      process: process
    });
    const _this = this;
    setTimeout(function () {
      if (_this.state.process === "start") {
        var url = urls.startMyTask;
        if (_this.state.runningTaskStatus === 1) {
          _this.setState({
            showRunningMsg: true,
            showProgress: false
          });
          return false;
        }
        _this.setState({
          showProgress: true
        });
        let opts = {
          project_id: projectid,
          task_id: taskid,
          task_name: taskname,
          baseuser_id: _this.state.selectedUser,
          system_user_id: _this.state.myId,
          username: _this.state.myName
        };
        fetch(url, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json"
          },
          method: "post",
          body: JSON.stringify(opts)
        })
          .then(response => response.json())
          .then(response => {
            _this.setState({
              showProgress: true,
              showNotLoggedinMsg: response[0].error === 2 ? true : false,
              breakStillRunning: response[0].error === 3 ? true : false,
            });
            if (response[0].error !== 2 && response[0].error !== 3) {
              //window.location.reload();
              _this.setState({ taskRunning: [], showProgress: false });
            }
            //_this.showMyTasks();
          })
          .catch(error => {
            _this.setState({
              showProgress: false
            });
          });

      } else if (_this.state.process === "end") {
        //this.endInternalTask(taskid, projectid, taskname);
        _this.setState({
          commentproject_id: projectid,
          commenttask_id: taskid,
          commentproject_name: projectname,
          commenttask_name: taskname,
          commenttid_cpt: tid_cpt
        })
        _this.showInternalEndTaskComment();
      }
    }, 1000);
    ReactDOM.findDOMNode(this).scrollIntoView();
  };

  showInternalEndTaskComment = () => {
    this.setState({ showInternalCommentBox: true });
  }

  endInternalTask = () => {
    // ReactDOM.findDOMNode(this).scrollIntoView();
    if (this.state.internalComment.trim().length <= 0) {
      this.setState({ commentInternalError: true });
    } else {
      this.setState({ submissionProgress: true })
      let opts = {
        task_id: this.state.commenttask_id,
        tid_cpt: this.state.commenttid_cpt,
        user_id: this.state.selectedUser,
        mname: this.state.myName,
        project_id: this.state.commentproject_id,
        project_name: this.state.commentproject_name,
        task_name: this.state.commenttask_name,
        internal_comment: this.state.internalComment
      };

      fetch(urls.endProjectInternalTask, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {
          this.setState({
            showProgress: true,
            showNotLoggedinMsg: response[0].error === 2 ? true : false,
            breakStillRunning: response[0].error === 3 ? true : false,
            commentInternalError: false,
            submissionProgress: false,
            showInternalCommentBox: false,
          });
          if (response[0].error !== 2 && response[0].error !== 3) {
            //window.location.reload();
          }
          this.showMyTasks();
        })
        .catch(error => {
          this.setState({
            showProgress: false,
            submissionProgress: false,
          });
        });
    }
  };


  trigerAmendlog = (id, pid, taskname) => {
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.setState({
      logAmendId: id,
      logAmendTask: taskname,
      logAmendProjectId: pid,
      showAssigneefetchStatus: true,
      showProgress: true
    });

    fetch(urls.findprojectAssignees + id + "/" + this.state.myBaseId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        this.setState({
          projectAssignees: response["project_assignees"],
          taskAssignees: response["task_assignees"],
          taskAssigneeArray: response["task_assignees_array"],
          showAssigneefetchStatus: false,
          showLogAmend: !this.state.showLogAmend,
          displayTrelloCard: false,
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({
          showAssigneefetchStatus: false,
          showProgress: false
        });
      });
  };

  handleTextChange = ({ target }) => {
    this.setState({ [target.name]: target.value, commentInternalError: false });
  }

  handleQuickCompletion = (tid, pid, type) => {
    this.setState({
      ctid: tid,
      cpid: pid,
      ctype: type,
      showCompletionDialogue: true
    })
  }


  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false,
      ctid: '',
      cpid: '',
      ctype: ''
    });
  };


  closeInternalPopup = () => {
    this.setState({
      showInternalCommentBox: !this.state.showInternalCommentBox
    });
  }

  /* Trello */

  showTrelloCard = (id) => {
    this.setState({
      cardId: id,
      cardDisplayNum: this.state.cardDisplayNum + 1,
      cardDisplayTop: this.state.cardDisplayTop + 10,
    });
    const _this = this;
    setTimeout(function () {
      _this.getCardDetailsById();
    }, 100);
  }

  getCardDetailsById = () => {
    fetch(urls.getTrelloBoardCardById + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        response[0].showEditCardDescription = false;
        this.setState({
          ['cardDetails' + this.state.cardDisplayNum]: response[0],
          showProgress: false,
          ['displayTrelloCard' + this.state.cardDisplayNum]: true,
        });
        this.getCardComments();
      })
      .catch(error => {
        this.setState({
          showProgress: false,
          trelloWrong: true,
        });
      });
  };

  getCardComments = () => {
    fetch(urls.getTrelloBoardCardComments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardComments' + this.state.cardDisplayNum]: response,
          showProgress: false
        });
        this.getCardAttachments();
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  getCardAttachments = () => {
    fetch(urls.getTrelloBoardCardAttachments + this.state.cardId)
      .then(response => response.json())
      .then(response => {
        this.setState({
          ['cardAttachments' + this.state.cardDisplayNum]: response,
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({
          showProgress: false
        });
      });
  };

  closeCard = () => {
    this.setState({
      ['displayTrelloCard' + this.state.cardDisplayNum]: false,
      cardDisplayNum: this.state.cardDisplayNum - 1,
      cardDisplayTop: this.state.cardDisplayTop - 10,
    });
  }

  onPeopleChange = (userid) => {
    if(userid === this.state.myId)
    {
      this.setState({ownProfile:true, selectedUser:''});
      return false;
    }else
    {
      this.setState({ownProfile:false});
    }
    
    this.setState({showProgress:true, selectedUser: userid });
    const _this = this;
    setTimeout(function () {
      _this.showMyTasks();
      _this.breakEnabler();
      _this.getStatusLogs();
      _this.getUserBreakException();
    }, 1000);
  }

  breakEnabler = () =>
  {
    fetch(urls.breakenabler+this.state.selectedUser,{
      headers:
      {
        'Access-Control-Allow-origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response =>
      {           
        this.setState({breakEnabled:response[0].breakenabler == 1? true : false});        
      })
    .catch(error =>
      {
        this.setState({progressBar:false})
      })
  }


  getStatusLogs =()=>
  {    
    fetch(urls.allMactivities+this.state.selectedUser,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        }
      })
      .then(response =>response.json())
      .then(response =>
        {          
          if(response[0]['session_error'] === 1)
          {
              this.setState({
                sessionNotExist:true,
                initialLoader:false
              });
          }else
          {
            console.log(response[0]['status']);
            this.setState({
              progressBar:false,
              punchStatus:response[0]['status'] != null ? response[0]['status'].punch_status:'',
              breakStatus: response[0]['status'] != null ? response[0]['status'].break_status:'',
              lunchStatus: response[0]['status'] != null ? response[0]['status'].lunch_status:'',
              extStatus: response[0]['status'] != null ? response[0]['status'].extbreak_status:'',
              lockStatus: response[0]['status'] != null ? response[0]['status'].lock_down_status:'',
              logActivities: response[0]['activities'].length>0 ? response[0]['activities']:[],
              shiftExist: response[0]['shiftactive'] === 0? false : true,
              shiftDay: (response[0]['status'] != null && response[0]['status'].punch_status === 1) ? response[0]['status'].sdate : ''
              });
          }
        })
  };

  getUserBreakException = () =>
  {
    fetch(urls.getUserBreakException+this.state.selectedUser,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
       })
      .then(response => response.json())
      .then(response =>
      {                  
        this.setState({
          allowLeaveException:response[0].exception
        });
      })
      .catch(error =>
        {
          this.setState({progressBar:false});         
        });
  };

  shiftActions = async (id) =>
  {   
    this.setState({showProgress:true});
    let opts ={
      user_id: this.state.selectedUser,
      punch_type: id
    };
    fetch(urls.startShift,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {
          const _this = this;
          setTimeout(function () {
            _this.showMyTasks();
            _this.getStatusLogs();
          }, 1000); 
      })
      .catch(error =>
      {
        this.setState({showProgress:false});
      });
      
  };

  lunchActions =(id) =>
  {    
    this.setState({showProgress:true,startTimem:0});
    let opts ={
      user_id: this.state.selectedUser,
      punch_type: id,
      break:this.state.breakStatus,
      lunch:this.state.lunchStatus,
      extbreak: this.state.extStatus,
      colleagueend:1
    };  
    fetch(urls.startLunch,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {                  
        const _this = this;
        setTimeout(function () {
          _this.showMyTasks();
          _this.getStatusLogs();
        }, 1000);       
      })
      .catch(error =>
      {   
        
        const _this = this;
        setTimeout(function () {
          _this.showMyTasks();
          _this.getStatusLogs();
        }, 1000); 
      });      
  };

  updateBreakExceptionStatus = () =>
  {
      if(this.state.allowLeaveException === 1)
      {
          this.setState({allowLeaveException: 0});
      }else
      {
         this.setState({allowLeaveException: 1});
      }
  }

  allowUserBreakException = () => {
   this.setState({showProgress:true});
    let opts = {
      user_id:this.state.selectedUser,
      break_exception: this.state.allowLeaveException,
      approved_by:this.state.myId
    };    
    fetch(urls.allowUserBreakException,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
       })
      .then(response => response.json())
      .then(response =>
      { 
        this.setState({showProgress:false});
      })
      .catch(error =>
      {
        this.setState({showProgress:false});         
      });
  }
  

  extBreakActionsPopup = (id)=>
  {
    this.setState({showExtentedBreakCommentBox:true});
    ReactDOM.findDOMNode(this).scrollIntoView();
  }
 
  extBreakActionsPopupClose = (id)=>
  {
    this.setState({
      showExtentedBreakCommentBox:false,
      commentExtendedError:false,
      extendedBreakComment:''
    });
  }

  startExtendedBreakwithComment = ()=>
  {
      if(this.state.extendedBreakComment.trim() == '')
      {
          this.setState({commentExtendedError:true});
      }else
      {
        this.extBreakActions('7');
        this.setState({showExtentedBreakCommentBox:false});
      }
  }
  extBreakActions =(id) =>
  {    
    this.setState({
      progressBar:true,      
      startTimemx:0});
      let opts ={
        user_id: this.state.selectedUser,
        punch_type: id,
        break:this.state.breakStatus,
        lunch:this.state.lunchStatus,
        extbreak: this.state.extStatus,
        break_reason: this.state.extendedBreakComment,
        colleagueend:1
      };
    fetch(urls.startExtBreak,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>
      {        
        const _this = this;
        setTimeout(function () {
          _this.showMyTasks();
          _this.getStatusLogs();
        }, 1000);
        
      })
      .catch(error =>
      {
        this.setState({progressBar:false,extendedBreakComment:''});           
      });      
  }



  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.11,
        }
      },
      hidden: {
        opacity: 0,
        transition: {
          when: "afterChildren",
        }
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    return (
      <div className="fullwidth_mobilemenu mobileflex colleague-timers">
        <Container fluid className={(this.state.myPrivileges.includes('i_am_designer') ? 'top-bar-active' : 'top-bar-inactive') + " colleague-tasks"}>
          <Row>
            <div className="theme_header">
              <Header {...this.props}
                classname="tick-timer-header"
                logout={this.signOut.bind(this)}
                onmenuaction={this.toggleSidebar.bind(this)}
                quickactionblock={true}
              />
            </div>
            <div className="SideNavbar">
              <SideMenu activeitem="kimpteam" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
            </div>
            <div className="themeContentBody colleagues_task">
              <Row>
                <Col sm={12} lg={12}>
                  <section id="all-tasks">
                    <Card className="switch_user_card">
                      <PageTitle title="Teammate Timers" />
                      <div id="filter" className="switchUser">
                        <div className="switch_user_wrapper">
                          <BaseUserPicker onPeopleChange={this.onPeopleChange.bind(this)} />
                        </div>
                        {this.state.showRunningMsg ? (
                          <Alertlogmsg
                            msgtext={this.state.runningMsgText}
                            boxclass="task-running"
                          />
                        ) : null}
                        {this.state.showStatusChangeMsg ? (
                          <Alertlogmsg
                            msgtext={this.state.statusChangeMsg}
                            boxclass="task-status-changed"
                          />
                        ) : null}
                        {this.state.breakStillRunning ? (
                          <Alertlogmsg
                            msgtext={this.state.breakNotEndedMsg}
                            boxclass="task-running"
                          />
                        ) : null}

                        {this.state.trelloWrong ? (
                          <Alertlogmsg
                            msgtext={this.state.trelloIdWrong}
                            boxclass="task-running"
                          />
                        ) : null}

                        {this.state.notAuthorizedTask ? (
                          <Alertlogmsg
                            msgtext={this.state.notAuthorizedMsg}
                            boxclass="task-running"
                          />
                        ) : null}

                        {this.state.taskCloseWait ? (
                          <Alertlogmsg
                            msgtext={this.state.taskCloseMsg}
                            boxclass="task-running"
                          />
                        ) : null}
                      </div>
                    </Card>

                    <div className="task-wrapper">
                      <section id="due-today" className="task-wrapper" >
                        <Row>
                          {this.state.selectedUser>0 &&
                          (
                            <>
                              <Col sm={this.state.timerColumns} lg={this.state.timerColumns}>
                                <article className={`single-task-container-projects single-task  `} >
                                  <h6>Active Shift</h6>
                                  <LoadingWrapper isLoading={this.state.showProgress}>
                                    {this.state.punchStatus == 1 && this.state.lunchStatus == 0 && this.state.breakStatus == 0 && this.state.extStatus == 0 ?
                                    (
                                      <div className="colleage-shift-break d-flex justify-content-center">
                                        {/* <Button className="white_bg_btn end_shift" onClick={this.shiftActions.bind(this, '2')}>End Shift</Button> */}
                                        
                                        <span className="active-msg">{this.state.shiftDay}</span>
                                      </div>
                                    ):
                                    (
                                      <>
                                        {this.state.punchStatus == 0 ? 
                                        (
                                          <div className="colleage-shift-break d-flex justify-content-center">
                                            <span className="active-msg">No Active Shift</span>
                                          </div>
                                        ):
                                        (
                                          <div className="colleage-shift-break d-flex justify-content-center">
                                            <Button className="white_bg_btn inactive_shift_buttons">End Shift</Button>
                                          </div>
                                        )}
                                      </>
                                     
                                    )}
                                  </LoadingWrapper>
                                </article>
                              </Col>
                              <Col sm={this.state.timerColumns} lg={this.state.timerColumns}>
                                <article className={`single-task-container-projects single-task  `} >
                                  <h6>Regular Break</h6>
                                  <LoadingWrapper isLoading={this.state.showProgress}>
                                      {(this.state.punchStatus == 1 && this.state.breakStatus == 0 && this.state.extStatus == 0 && this.state.breakEnabled ) ?
                                      (
                                        <>
                                          {this.state.lunchStatus == 0 ?
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                <Button className="start_b" onClick={this.lunchActions.bind(this, '5')}>Start Break</Button>
                                              </div>
                                            ):
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                  <span className="active-msg">Break Running</span>
                                              </div>
                                            )
                                          }                      
                                        </>
                                      ):
                                      (
                                        <div className="colleage-shift-break d-flex justify-content-center">
                                            <Button className="white_bg_btn inactive_shift_buttons" >End Break</Button>
                                        </div>
                                      )}
                                  </LoadingWrapper>
                                </article>
                              </Col>
                              <Col sm={this.state.timerColumns} lg={this.state.timerColumns}>
                                <article className={`single-task-container-projects single-task  `} >
                                  <h6>Emergency Break</h6>
                                  <LoadingWrapper isLoading={this.state.showProgress}>
                                      {(this.state.punchStatus == 1 && this.state.lunchStatus == 0 && this.state.breakStatus == 0 && this.state.extStatus == 0 && this.state.breakEnabled ) ?
                                      (
                                        <>
                                           {this.state.extStatus === 0 &&
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                <Button className={`start_b ${this.state.extStatus === 1 && " inactive_break_buttons" } `} onClick={this.extBreakActionsPopup.bind(this, '7')}>Start Break</Button>
                                              </div>
                                            )}

                                            {this.state.extStatus === 1 &&
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                <Button className="start_b active_break_buttons" onClick={this.extBreakActions.bind(this, '8')}>End Break</Button>
                                              </div>
                                            )}                   
                                        </>
                                      ):
                                      (
                                        <>
                                           {this.state.extStatus === 1 ?
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                <span className="active-msg">Break Running</span>
                                              </div>
                                            ):
                                            (
                                              <div className="colleage-shift-break d-flex justify-content-center">
                                                <Button className="white_bg_btn inactive_break_buttons" >End Break</Button>
                                              </div>
                                            )} 
                                        </>
                                        
                                      )}
                                  </LoadingWrapper>
                                </article>
                              </Col>
                              {this.state.hrActive &&
                              (
                                <Col sm={this.state.timerColumns} lg={this.state.timerColumns}>
                                <article className={`single-task-container-projects single-task  `} >
                                  <h6>Override Leave Policy</h6>
                                  <LoadingWrapper isLoading={this.state.showProgress}>
                                      {localStorage.getItem("superadmin") !== null &&
                                      (<>
                                        <div className="override-policy d-flex justify-content-center">
                                          <div className="colleage-shift-break d-flex ">
                                            <Input id="override-policy" type="checkbox" checked={this.state.allowLeaveException === 1? 'checked' :'' } name="allow-break" className="flex-grow-1" onClick={this.updateBreakExceptionStatus.bind(this)} />
                                            <Button className="login_update_btn" onClick={this.allowUserBreakException.bind(this)}>Update</Button>
                                          </div>
                                        </div>
                                        </>
                                        )}
                                    </LoadingWrapper>
                                </article>
                              </Col>
                              )}
                            </>
                          )}
                          
                          <Col sm={12} lg={12}>
                            <LoadingWrapper isLoading={this.state.showProgress}>
                              {this.state.taskRunning &&
                                this.state.taskRunning.length > 0 ? (
                                <>
                                  <motion.ul
                                    initial="hidden"
                                    animate="visible"
                                    variants={list}>
                                    {this.state.taskRunning.map((task, index) => {

                                      return (
                                        <motion.li variants={item}>
                                          <TaksSingle
                                            key={index}
                                            slno={index + 1}
                                            classname={
                                              task.running_status === "1" ||
                                                task.running_status === "2"
                                                ? "active"
                                                : ""
                                            }
                                            mtask={task}
                                            task_id={task.task_id}
                                            project_id={task.project_id}
                                            project_name={task.project_name}
                                            task_name={task.task_name}
                                            manager={task.manager}
                                            overalltime={task.overall_time}
                                            mytotal={task.my_log_total}
                                            totaltoday={task.today_total}
                                            running_status={task.running_status}
                                            starttimer={task.running_status == 1 ? task.timmer : 0}
                                            myactions={this.triggerActions}
                                            taskamend={this.trigerAmendlog}
                                            showtrello={this.showTrelloCard}
                                            quickclose={this.handleQuickCompletion}
                                            type="today"
                                            allowclose={this.state.showCompletion}
                                            mytimezone={this.state.myTimezone}
                                          />
                                        </motion.li>
                                      );
                                    })}
                                  </motion.ul>
                                </>
                              ) :
                                (
                                  <div className="no-task-running">

                                    {this.state.selectedUser > 0 ?
                                      (
                                        <>{this.state.taskNotRunning}</>
                                      ) :
                                      (
                                        <>
                                        {this.state.ownProfile ?
                                        (
                                          <span>Sorry, you can not access your own profile here.</span>
                                        ):
                                        (
                                          <span>{this.state.userMessage}</span>
                                        )}
                                        </>
                                      )}
                                  </div>
                                )
                              }
                            </LoadingWrapper>
                          </Col>
                          
                        </Row>
                      </section>



                      {this.state.showLogAmend ? (
                        <LogAmend
                          myname={this.state.myName}
                          myid={this.state.myBaseId}
                          loading={this.state.showAssigneefetchStatus}
                          passignees={this.state.projectAssignees}
                          tassignees={this.state.taskAssignees}
                          taskassigneearray={this.state.taskAssigneeArray}
                          closeaction={this.closeAmendPopup}
                          onassigneupdate={this.handleassigneeupdate}
                        />
                      ) : null}

                      {this.state.displayTrelloCard1 ? (
                        <TrelloCard
                          carddetails={this.state.cardDetails1}
                          cardcomments={this.state.cardComments1}
                          cardattachments={this.state.cardAttachments1}
                          closecard={this.closeCard}
                          cardclass="cardone"
                          showtrellocard={this.showTrelloCard}
                          cardtop="30px"
                        />
                      ) : null}

                      {this.state.showInternalCommentBox &&
                        (
                          <>
                            <div className="internal-comment container">
                              <div className="internal-comment-overlay" onClick={this.closeInternalPopup}></div>
                              <div className="internal-comment-box">
                                <LoadingWrapper isLoading={this.state.submissionProgress}></LoadingWrapper>
                                <label className="internal-comment-label">Please enter the details about the work done to end this timer.</label>
                                <Input type="textarea" placeholder="Enter you comment" name="internalComment" onChange={this.handleTextChange} className={this.state.commentInternalError && 'field-error'} />
                                <div class="col-sm-12">
                                  <button type="button" class="project-assignee-submit btn-green btn btn-secondary" onClick={this.endInternalTask}>Submit and Close</button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {this.state.showExtentedBreakCommentBox &&
                            (
                              <>
                                <div className="internal-comment container extendedbreakcomment">
                                  <div className="internal-comment-overlay" onClick={this.extBreakActionsPopupClose}></div>
                                    <div className="internal-comment-box">
                                    <label className="internal-comment-label">Please enter the details, why you want to take an emergency break. Take prior approval from your reporting officer before taking this break. This will be notified to the HR Department.</label>
                                        <Input type="textarea" placeholder="Enter you comment" 
                                        name="extendedBreakComment" onChange={this.handleTextChange} 
                                        className={this.state.commentExtendedError && 'field-error'} />
                                        <div class="col-sm-12">
                                          <button type="button" class="project-assignee-submit ext_br_brtn btn-green btn btn-secondary" onClick={this.startExtendedBreakwithComment} >Submit and Start Break</button>
                                        </div>
                                    </div>
                                </div>
                              </>
                            )}

                    </div>


                  </section>
                </Col>
              </Row>
            </div>
          </Row>
        </Container>
      </div>
    );
  }
}
