import React, { useState, useEffect } from "react";
import ConfirmAction from "../admincomment/ConfirmAction";
import axios from "axios";
import Hashids from 'hashids';
import toast, { Toaster } from 'react-hot-toast';
import {
  Input,
  Button,
} from "reactstrap";
import { urls } from "../../services/UrlList";


export default function Quick360Access(props) {
    const [cardId360, setCardId360] = useState('');
    const [task360Data, setTask360Data] = useState(props.taskDetails.task_360_id!=null ? JSON.parse(props.taskDetails.task_360_data):'')
    const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );
    
    const findProjectId = (id)=>
    {
      const projectId = hashids.encode(id);
      if(projectId && projectId.length>0)
      {
        return projectId;
      }

    }
  return (
    <>
       {props.taskDetails && props.taskDetails.task_360_id!=null  &&
        (
            <a target="_blank" href={`${urls.rootdomain360}dashboard/my-requests/${findProjectId(task360Data.project_id)}/card/${props.taskDetails.task_360_id}`}>
                <Button className="btn360">360</Button>
            </a>
        )}
    </>
  );
}
