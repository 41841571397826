import React,{useState, useContext} from 'react'

const MediaContext = React.createContext();
const MediaUpdateContext = React.createContext();

export function useMedia()
{
    return useContext(MediaContext);
}

export function useMediaUpdated()
{
    return useContext(MediaUpdateContext);
}

export function MediaProvider({children})
{
    const [mediaStatus, SetMediaStatus]=useState(0);

    function updateMediaCall(newval)
    {   
        SetMediaStatus(newval);
    }

    return(
        <MediaContext.Provider value={mediaStatus}>
            <MediaUpdateContext.Provider value={updateMediaCall}>
                {children}
            </MediaUpdateContext.Provider>
        </MediaContext.Provider>
    )
}