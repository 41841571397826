import React, { Component, Fragment } from "react";
import "./MonthlyReport.css";
import Header from "../header/Header";
import MFilter from "../filter/MFilter";
import "./list-head.css";
import "./listcard.css";
import { urls } from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import {isMobile} from 'react-device-detect';
import { Container,Row,Col,Card,Table} from "reactstrap";
import BasecampIcon from "../svgicon/BasecampIcon";
import TrelloIcon from '../svgicon/TrelloIcon';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'

export default class MontlyReport extends Component {
  constructor(props) {
    super(props);
    var s = new Date();
    s.setDate(s.getDate());
    s.setHours(6);
    s.setMinutes(0);
    let momentObjs = moment(s);
    let momentStringStart = momentObjs.format("DD-MM-YYYY");

    // var e = new Date();
    // e.setDate(e.getDate());
    // e.setHours(6);
    // e.setMinutes(0);
    // let momentObje = moment(e);
    // let momentStringEnd = momentObje.format("YYYY-MM-DD");

    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      userTeam: "",
      showProgress: false,
      showProgressSearch: false,
      orginalEntries: [],
      fullEntries: [],
      orginalUsers: [],
      selectedUser: "",
      start_date: momentStringStart,
      //end_date:momentStringEnd,
      show_detail: false,
      show_detail_id: 0,
      showCustomRange: true,
      startDate: null,
      endDate: null,
      show_rate: true,
      myPrivileges: [],
      showPeople: false,
      showPeopleType: "",
      showSelectedResult: false,
      show_detail_date: 0,
      showRate: false,
      dailyHours: 12,
      hoursOption: "",
      filterHour: "",
      showAdminComments: false,
      adminCommentId: 0,
      commentUserId: "",
      commentProjectId: "",
      commentTaskId: "",
      running_status: 0,
      presentDays: 0,
      employee: "",
      overTime: 0,
      totalWorkHours:0,
      totalInHours:0,
      totalShiftHours:0,
      showMenu:true,
      shiftDetailId:0,
      showDetailView:false
    };
    // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    document.title = "HUB - Login Timer Reports";
    this.checkUserPrivilege();
    this.renderContentDevice(); 
    const _this = this;
    setTimeout(() => {
      //_this.getMonthlyReport();
    }, 100);
    this.reloadChecker();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        userTeam: login.user_team,
        myId: login.uid,
        myBaseId: login.basecampid
      });

      if (login.privileges.includes("access_all_reports")) {
        this.setState({ showPeople: true, showPeopleType: 1 });
      } else if (login.privileges.includes("access_team_reports")) {
        this.setState({ showPeople: true, showPeopleType: 2 });
      } else {
        this.setState({ showPeople: false, showPeopleType: 3 });
      }
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };


  reloadChecker = () => {
    if (this.props.location.search === "?redirect=1") {
      this.props.history.push("/tick-report");
    }
  };

 
  onStatusChange = event => {
    this.setState({
      status: event.target.value
    });
  };

  onUserNameChange = event => {
    this.setState({
      userName: event.target.value
    });
  };

  _handleKeyDown = e => {
    var code = e.keyCode ? e.keyCode : e.which;
    // console.log(e);
    if (code == 13) {
      this.setState({
        searchTags:
          this.state.searchTags != ""
            ? this.state.newTag + "," + this.state.searchTags
            : this.state.newTag,
        newTag: "",
        newTagArray: [...this.state.newTagArray, this.state.newTag]
      });
    }
  };

  hoursRender = () => {
    const optionsval = [];
    for (var i = 1; i <= 12; i++) {
      optionsval.push(
        <option
          selected={this.state.filterHour == i ? "selected" : ""}
          value={i}
        >
          {"< " + i + " hour"}
        </option>
      );
    }
    return optionsval;
  };

  getSortedRecords = response => {
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.user_id === p1.user_id) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;
  };

  showDetailEntry = id => {
    this.setState({
      show_detail: true,
      show_detail_date: 0,
      show_detail_id: this.state.show_detail_id === id ? 0 : id
    });
  };

  getMonthlyReport = (year, month, selectedUser) => {
    this.setState({
      year: year,
      month: month,
      selectedUser: selectedUser,
      showProgress: true,
      employee:''
    });

    let opts = {
      year: year,
      month: month,
      team_id: this.state.userTeam,
      user_id: this.state.myId,
      basecamp_id: this.state.myBaseId,
      user_type: this.state.showPeopleType,
      selectedpeople: selectedUser
    };

    fetch(urls.getMonthlyReport, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
      .then(response => response.json())
      .then(response => {
        let list = response.sps;
        this.setState({
          orginalEntries: list,
          fullEntries:list,
          presentDays: response.pdays,
          employee: response.employee,
          overTime: response.overtime,
          totalShiftHours: response.total_shift_hours,
          totalInHours: response.total_login_hours,
          totalWorkHours: response.total_working_hours,
          showProgress: false
        });
      })
      .catch(error => {
        this.setState({ showProgress: false });
      });
  };

filterbyhour = (value) =>
{
  this.setState({
    filterHour:value
  });
  this.filterByHours(value);
}

filterByHours=(value)=>
{ 
  this.setState({filterHour:value}); 
  if(value !=''){
    setTimeout(() => {
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      if(this.state.filterTask !='')
      {
        temp.forEach(p1=>
        {   
        //console.log(p1.total_work_hours);
         var work_hours_split = p1.total_work_hours.split(":");
         var filter_hour_split = this.state.filterHour.split(":");
         // p1.total_pending_tasks <= this.state.filterTask && 
         if( (parseInt(work_hours_split[0]) < parseInt(filter_hour_split[0])) 
         || (parseInt(work_hours_split[0]) == parseInt(filter_hour_split[0])  && 
         parseInt(work_hours_split[1]) < parseInt(filter_hour_split[1]) )) 
         {
           list.push(p1);
         }
        })        
      }else
      {
          temp.forEach(p1=>
          {             
            var work_hours_split = p1.total_work_hours.split(":");
            var filter_hour_split = this.state.filterHour.split(":");
            // p1.total_pending_tasks <= this.state.filterTask && 
            if( (parseInt(work_hours_split[0]) < parseInt(filter_hour_split[0])) 
            || (parseInt(work_hours_split[0]) == parseInt(filter_hour_split[0])  && 
            parseInt(work_hours_split[1]) < parseInt(filter_hour_split[1]) ))  
            {
              list.push(p1);
            }
          })
      }
      this.setState({orginalEntries:list});   
    }, 100);
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
};

filterByNonProductive = (value) =>
{
  if(value !=''){
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      temp.forEach(p1=>
        {             
          if(p1.non_productive>=120)  
          {
            list.push(p1);
          }
        })
      this.setState({orginalEntries:list});   
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
}

showShiftDetails = (edate) =>
{
  if(this.state.shiftDetailId != edate)
  {
    this.setState({
      showDetailView:true,
      shiftDetailId: edate
    })
  }else
  {
    this.setState({
      showDetailView:false,
      shiftDetailId: 0
    })
  } 
}

  render() {
    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    //let overTime = (this.state.overTime / 60).toFixed(2);
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
          classname="tick-timer-header"
          logout={this.signOut.bind(this)}
          onmenuaction={this.toggleSidebar.bind(this)}
          quickactionblock={true}
        />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report mor_wrapper">
            <h1 class="page-title text-center her_title">Monthly Overview Report</h1>
                <MFilter
                filterbyhour={this.filterbyhour}
                searchEntriesone={this.getMonthlyReport}
                listcount={this.state.fullEntries.length} 
                filterbynonproductive={this.filterByNonProductive}
              />
              <section id="report-monthly-head" className=" report-monthly-section mb-3">   
          
              {this.state.employee!='' &&
              (
                <>
                <Row className="monthly-ename">
                  <Col sm="6" lg="3">
                    <strong className="d-flex">
                      <span className="monthly-view-emp-name">{this.state.employee}</span>
                    </strong>
                  </Col>
                  <Col sm="6" lg="2">
                    <strong className="d-flex">
                      <span>Present:</span>&nbsp;
                      <span>{this.state.presentDays} Days</span>
                    </strong>
                  </Col>
                </Row>
                <Row>
                  
                  <Col sm="6" lg="3">
                      <strong className="d-flex">
                        <span>Shift Hours:</span>&nbsp;
                        <span>{this.state.totalShiftHours}</span>
                      </strong>
                    </Col>
                    <Col sm="6" lg="3">
                      <strong className="d-flex">
                        <span>In Hours:</span>&nbsp;
                        <span>{this.state.totalInHours}</span>
                      </strong>
                    </Col>
                    <Col sm="6" lg="3">
                      <strong className="d-flex">
                        <span>Work Hours:</span>&nbsp;
                        <span>{this.state.totalWorkHours}</span>
                      </strong>
                    </Col>
                    {this.state.overTime !== '' ? (
                      <Col sm="6" lg="3">
                        <strong className="d-flex">
                          <span>OT:</span>&nbsp;
                          <span>{this.state.overTime}</span>
                        </strong>
                      </Col>
                    ) : null}
                </Row>
                </>
              )}
           
          </section>
         
          <div id="list-head">
          <Table responsive id="date-list-contianer" className="theme_table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Shift Started</th>
                    <th>Login Hours</th>
                    <th>Work Hours</th>
                    <th>Break Time</th>
                    <th>Shift Ended</th>
                    <th>OT</th>
                    {(this.state.fullEntries.length>0) &&
                    (<th className="unproductive">Unproductive</th>)
                    }
                  </tr>
                </thead>
               
                      <motion.section
                        initial="hidden"
                        animate="visible"
                        variants={list}
                        style={{ display: 'contents' }}
                      >
                              
                          {this.state.orginalEntries.map((entries, index) => {
                            return (
                            
                            <motion.div variants={item} style={{ display: 'contents' }}>
                                <div
                                      className={
                                        this.state.show_detail &&
                                        (this.state.show_detail_id ===
                                          entries.user_id ||
                                          this.state.show_detail_date === entries.date)
                                          ? "user-active"
                                          : null
                                      }
                                      style={{ display: 'contents' }}
                                    >
                                <div className={entries.total_login_minitues>960? "shift-day-bold" : "repo_box showbox_expand" } onClick={this.showShiftDetails.bind(this,entries.mdate)}>
                                <tbody>
                                 <tr>
                                   <td>
                                   {entries.mdate}
                                   </td>
                                   <td>
                                   {entries.sign_in} 
                                   </td>
                                   <td>{entries.totaltime}</td>
                                   <td>{entries.total_work_hours}</td>
                                   <td>{entries.totalbreaktime}</td>
                                   <td>{entries.sign_out}</td>
                                   <td>{entries.ot}</td>
                                   {(this.state.fullEntries.length>0  &&  this.state.myPrivileges.includes('admin_level_filters')) &&
                                   (<td className="unproductive"> {entries.non_productive_display}</td>)
                                    }
                                 </tr>
                                 <th colSpan="8" style={{padding:'0 30px'}}>
                                     
                                     {entries.activities.length > 0 ? (
                                        <div className= {this.state.showDetailView && this.state.shiftDetailId == entries.mdate ? "show-active-row project-card shift-card " : "project-card shift-card" }>
                                         
                                           <div className="headings">
                                            <Col sm="6">
                                               <Card className="d-flex headings"><p className="name">Shift Activity</p></Card>
                                            </Col>
                                            <Col sm="6">
                                               <Card className="d-flex headings"><p className="time-entered"> Activity Time </p></Card>
                                            </Col>

                                            </div>
                                     
                                          <Row>
                                            <Col sm="12">
                                              <div className="">
                                                <Card className="list-card d-block">
                                                  <div className="card-body">
                                                    {entries.activities.map(
                                                      (activity, index) => {
                                                        return (
                                                          <div
                                                            className="single-project d-flex"
                                                            key={index}
                                                          >
 <Row>
                                                            <Col sm="6">
                                                              <p>{activity.comment}</p>
                                                            </Col>
                                                            <Col sm="6">
                                                            <p>{activity.time}</p>
                                                            </Col>
                                                            </Row>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Card>
                                              </div>
                                            </Col>
                                          </Row>
                                         
                                        </div>



                                      ) : null}
                                      {entries.work_activities.length > 0 ? (
                                        <div className= {this.state.showDetailView && this.state.shiftDetailId == entries.mdate ? "show-active-row project-card work-card" : "project-card work-card" } >
                                          <Row>
                                            <Col sm="12">
                                             
                                                <Card className="d-flex headings">
                                                 <Col sm="4">
                                                    Project & Task Name
                                                  </Col>
                                                  <Col sm="2">
                                                  <p className="time-entered">
                                                    Start Time
                                                  </p>
                                                  </Col>
                                                  <Col sm="2">
                                                  <p className="time-entered">
                                                    End Time
                                                  </p>
                                                  </Col>
                                                  <Col sm="2">
                                                  <p className="time-entered">
                                                    Time Taken
                                                  </p>
                                                  </Col>
                                                  <Col sm="2">
                                                  <p className="time-entered">&nbsp;</p>
                                                  </Col>
                                                </Card>
                                          
                                            </Col>
                                          </Row>
                                          <Row>
                                            <Col sm="12">
                                              <div className="">
                                                <Card className="list-card d-block">
                                                  <div className="card-body">
                                                    {entries.work_activities.map(
                                                      (activity, index) => {
                                                        var trello = activity.task_name.split("##");
                                                        return (
                                                          <div
                                                            className="single-project d-flex"
                                                            key={index}
                                                          >
                                                             <Col sm="4">
                                                            <p className="project-name">
                                                              <span className="project-name-title">
                                                                {activity.project_name}
                                                              </span>
                                                              <span className="task-name-title">
                                                                {activity.task_name}
                                                              </span>
                                                            </p>
                                                            </Col>
                                                            <Col sm="2">
                                                            <p className="time-entered">
                                                              {activity.start_time}
                                                            </p>
                                                            </Col>
                                                            <Col sm="2">
                                                            <p className="time-entered">
                                                              {activity.end_time}
                                                            </p>
                                                            </Col>
                                                            <Col sm="2">
                                                            <p className="time-entered">
                                                              {activity.total}
                                                            </p>
                                                            </Col>
                                                            <Col sm="2">
                                                            <p className="time-entered d-flex">
                                                              <BasecampIcon
                                                                active={
                                                                  this.state
                                                                    .running_status ===
                                                                  "1"
                                                                    ? true
                                                                    : false
                                                                }
                                                                projectid={
                                                                  activity.project_id
                                                                }
                                                                taskid={
                                                                  activity.task_id
                                                                }
                                                                myId={this.state.myId}
                                                              />
                                                              {trello.length>1 &&
                                                              (
                                                                <a target="_blank" href={"https://trello.com/c/"+trello[1]}><TrelloIcon /></a>
                                                              )}
                                                            </p>
                                                            </Col>

                                                          </div>

                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </Card>
                                              </div>
                                            </Col>
                                          </Row>
                                        </div>
                                      ) : null}

 </th>
                               
 

                              </tbody>
                              </div>
                               </div>
                            </motion.div>
                               
                );
                          })}
                        
                      </motion.section>
                




            </Table>

          </div>
          <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.showProgress} style={{ display: 'contents' }}>   </LoadingWrapper>
            </Card>
            </Col>
          </Row>
        </div>
      </Row>

  </Container>
  </div>
    );
  }
}
