import React, {useState, useEffect} from 'react';
import {Input, Button} from 'reactstrap';
import {urls} from "../../services/UrlList";
import axios from 'axios';
import User1 from '../../images/users/user1.png';
import Closeicon from '../../images/pop_close_icon.svg';
import { Typeahead } from 'react-bootstrap-typeahead';
import ProfileIcon from '../icon/ProfileIcon';
import RemoveMember from "../../images/remove_icon.svg"
import MaterialIcon from "material-icons-react";
export default function BoardMembers(props)
{
    const [projectmembers, setProjectMembers]= useState(JSON.parse(props.project.board_access_stringified));
    const [mmembers, setMembers]= useState(props.allmembers);
    const [orgmembers, setOrgMembers]= useState(props.allmembers);
    const [selectedmember, setSelectedMember]= useState('');
   
    useEffect(() => {
        let tempmembers = [...projectmembers];
            // deleting duplications from main
            var mainarray = [...props.allmembers];
            for( var i=mainarray.length - 1; i>=0; i--){
                for( var j=0; j<tempmembers.length; j++){
                    if(mainarray[i] && (mainarray[i].name === tempmembers[j].name)){
                        mainarray.splice(i, 1);
                    }
                }
            }
            setMembers(mainarray);
    },[]);

    function handleInputChange(input, e) {
        setSelectedMember(e);
    }

    function togglememberDisplay()
    {
        props.hidememeberpopup();
    }

    function removePeople(memid) // removing member = Action 0
    {
        var listToDelete = [];
        listToDelete.push(parseInt(memid));
        var arrayOfObjects = [...projectmembers]
        let result = arrayOfObjects.filter( el => (-1 == listToDelete.indexOf(parseInt(el.id))) );

        setProjectMembers(result);
        props.updatechange(result, memid, 0);

        let tempmembers = [...result];
            // deleting duplications from main
            var mainarray = [...props.allmembers];
            for( var i=mainarray.length - 1; i>=0; i--){
                for( var j=0; j<tempmembers.length; j++){
                    if(mainarray[i] && (mainarray[i].name === tempmembers[j].name)){
                        mainarray.splice(i, 1);
                    }
                }
            }
            setMembers(mainarray);

           //console.log(orgmembers.filter(val => !projectmembers.includes(val)))
    }
 
    function onChangePeople(e)   // adding member = Action 1
    {
        var size = Object.keys(e).length;
        if(size>0)
        {
            let tempmembers = [e[0], ...projectmembers];
            // deleting duplications from main
            var mainarray = [...props.allmembers];
            for( var i=mainarray.length - 1; i>=0; i--){
                for( var j=0; j<tempmembers.length; j++){
                    if(mainarray[i] && (mainarray[i].name === tempmembers[j].name)){
                        mainarray.splice(i, 1);
                    }
                }
            }
            setMembers(mainarray);
            setProjectMembers(tempmembers);
            props.updatechange(tempmembers, e[0].id, 1);
        }
    }

    function findInitials (word)
    {
        var matches = word.match(/\b(\w)/g); 
        var acronym = matches.join(''); 
        return acronym;
    }

    return(
        <>
        <div className="overlay_search" onClick={togglememberDisplay}></div>
        <div className="add_member">
            
            <span className="close_thisbox" onClick={togglememberDisplay}><img src={Closeicon} alt="close" /></span>
            <h4 className="boxtitile">Add New Member</h4>
            <div className="people select_member">
            <Typeahead
            id="user-picker"
            labelKey="name"
            onInputChange={handleInputChange}
            onChange={onChangePeople}
            options={mmembers}
            placeholder="Type New Member"
            />
            </div>
            <div className="member_list_table">
            {projectmembers && projectmembers.length>0 &&
            (
                <>
                    {projectmembers.map((member, index)=>
                    {
                        return(
                            <div key={member.id} className="added_member_prev">
                               <div className="member_detail">
                                    <span className="user_avatar">
                                        <img src={urls.storageUrl+"storage/profile/image/"+member.id+".jpg"} alt={findInitials(member.name)} />
                                    </span>
                                    <div className="user_nj_detail">
                                    <span className="user_bc_name">{member.name}</span>
                                    </div>
                                </div>
                                {member.id !== props.myid &&
                                (
                                    <Button className="remove_this" onClick={() => removePeople(member.id)} >
                                        <MaterialIcon  icon="remove"/> 
                                        {/* <img  src={RemoveMember} alt="back"  onClick={() => removePeople(member.id)} /> */}
                                    </Button>
                                )}
                            </div>
                        )
                    })}
                </>
            )}
                
            </div>
        </div>
        </>
    )
};