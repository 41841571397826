import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import axios from 'axios';
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import moment from "moment";
import Homebackicon from '../../images/bc_homeicon.svg'
import PeopleTags from "./PeopleTag";
import KFileUpload from './kFileUpload';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import toast, { Toaster } from 'react-hot-toast';
import LightboxPop from "./LightboxPop";
import MaterialIcon from "material-icons-react";
import ImageUploader from "quill-image-uploader";

Quill.register("modules/imageUploader", ImageUploader);

const notifySuccess = () => toast('Task updated successfully.');
const notifyDraft = () => toast('Task has been saved as draft.');
const notifyError = () => toast('Something went wrong, please try again.');

const toolbarOptions = [
  ['bold', 'italic', 'underline'],        
  [{ 'color': [] }, { 'background': [] }],           
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
  ['link','image'],
  ['clean']    
];

export default class EditTask extends Component {
  fileObj = []; 
  fileArray = [];
  fileNamesArray = [];
  constructor(props) {
    super(props);
    this.state = {
      showFiles:true,
      availableBrands: this.props.availableBrands,
      brand_id: this.props.taskdetails.brand_id,
      taskName: this.props.taskdetails.task_name,
      ticketDetails: this.props.taskdetails,
      selectedTemplate: this.props.taskdetails.template_id,
      sizeFields: this.props.stringifieddescription.Size,
      FileSizeLimit: this.props.stringifieddescription.FileSizeLimit,
      Purpose: this.props.stringifieddescription.Purpose,
      Guidelines: this.props.stringifieddescription.Guidelines ? this.props.stringifieddescription.Guidelines : '',
      Website: this.props.stringifieddescription.Website,
      brand: this.props.taskdetails.brand_id,
      Facebook:this.props.stringifieddescription.Facebook,
      Twitter: this.props.stringifieddescription.Twitter,
      LinkedIn: this.props.stringifieddescription.LinkedIn,
      Instagram: this.props.stringifieddescription.Instagram,
      Pinterest: this.props.stringifieddescription.Pinterest,
      OtherMedia: this.props.stringifieddescription.OtherMedia,
      Text_Instructions: this.props.stringifieddescription.Text_Instructions ? this.props.stringifieddescription.Text_Instructions :'',
      Style_Info: this.props.stringifieddescription.Style_Info,
      Style_Colors: this.props.stringifieddescription.Style_Colors,
      Style_Colors_Predefined:(this.props.stringifieddescription.Style_color_predefined && this.props.stringifieddescription.Style_color_predefined.length>0) ? this.props.stringifieddescription.Style_color_predefined : [],
      addonColors: this.props.stringifieddescription.Style_color_addon,
      ColorInstructions: this.props.stringifieddescription.color_instructions,
      FontInstructions: this.props.stringifieddescription.font_instructions,
      Style_Fonts: this.props.stringifieddescription.Style_Fonts,
      Target_Audience: this.props.stringifieddescription.Target_Audience,
      Other_Instructions: this.props.stringifieddescription.Other_Instructions? this.props.stringifieddescription.Other_Instructions : '',
      outputFileFormats: this.props.stringifieddescription.File_Formats,
      softwarestoUse: this.props.stringifieddescription.Softwares,
      Watermark: this.props.stringifieddescription.Watermark,
      Mockup: this.props.stringifieddescription.Mockup,
      mokupInstructions: this.props.stringifieddescription.mokupInstructions ? this.props.stringifieddescription.mokupInstructions:'',
      Provided_by_client: this.props.stringifieddescription.Provided_by_client,
      Previous_Approved_work: this.props.stringifieddescription.Previous_Approved_work,
      brandingFiles: this.props.stringifiedattachments.brandingFiles,
      textInstructionFiles: this.props.stringifiedattachments.textInstructionFiles,
      imageryFiles:this.props.stringifiedattachments.imageryFiles,
      referenceFiles:this.props.stringifiedattachments.referenceFiles,
      approvedWorkFiles: this.props.stringifiedattachments.approvedWorkFiles,
      description: this.props.taskdetails.task_description ? this.props.taskdetails.task_description : '',
      taskAttachments: this.props.taskattachments && this.props.taskattachments.length>0 ? this.props.taskattachments : [],
      TaskType: this.props.taskdetails.task_type,
      showBrandColors: (this.props.stringifieddescription.Style_color_predefined && this.props.stringifieddescription.Style_color_predefined.length>0) ? true : false ,
      showAddonColors:(this.props.stringifieddescription.Style_color_addon && this.props.stringifieddescription.Style_color_addon.length>0) ? true : false,
      showColorGuides:(this.props.stringifieddescription.color_instructions && this.props.stringifieddescription.color_instructions.length>0) ? true : false,
      textInstructionFilesText: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.textInstructionFilesText != null) ? this.props.stringifiedtextlinks.textInstructionFilesText:'',
      imageryFilesText: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.imageryFilesText != null) ? this.props.stringifiedtextlinks.imageryFilesText:'',
      referenceFilesText: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.referenceFilesText != null) ? this.props.stringifiedtextlinks.referenceFilesText:'',
      approvedWorkFilesText: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.approvedWorkFilesText != null) ? this.props.stringifiedtextlinks.approvedWorkFilesText:'',
      taskAttachmentsText: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.taskAttachmentsText != null) ? this.props.stringifiedtextlinks.taskAttachmentsText:'',
      fileFormatOther: this.props.stringifieddescription.File_Formats_other,
      fileFormatOtherShow: (this.props.stringifieddescription.File_Formats && this.props.stringifieddescription.File_Formats.includes('other')) ? true :false,
      softwareOther:this.props.stringifieddescription.Software_other,
      softwareOtherShow:(this.props.stringifieddescription.Softwares && this.props.stringifieddescription.Softwares.includes('other')) ? true :false,
      outsoftInstructions: this.props.stringifieddescription.outsoftInstructions ? this.props.stringifieddescription.outsoftInstructions : '',
      resize_crop: ((this.props.stringifieddescription.resize_crop) ? this.props.stringifieddescription.resize_crop : '' ),
      bleedSize: ((this.props.stringifieddescription.bleedSize) ? this.props.stringifieddescription.bleedSize : '' ),
      bleedUnit: ((this.props.stringifieddescription.bleedUnit) ? this.props.stringifieddescription.bleedUnit : '' ),

      taskAttachmentTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.taskAttachmentTextArray && this.props.stringifiedtextlinks.taskAttachmentTextArray != false) ? this.props.stringifiedtextlinks.taskAttachmentTextArray:[],
      taskAttachmentTextOptions: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.taskAttachmentTextArray && this.props.stringifiedtextlinks.taskAttachmentTextArray != false) ? 2 : 1,

      textInstructionFilesTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.textInstructionFilesTextArray && this.props.stringifiedtextlinks.textInstructionFilesTextArray != false) ? this.props.stringifiedtextlinks.textInstructionFilesTextArray:[],
      textInstructionFilesTextOptions: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.textInstructionFilesTextArray && this.props.stringifiedtextlinks.textInstructionFilesTextArray != false) ? 2 : 1,

      imageryFilesTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.imageryFilesTextArray && this.props.stringifiedtextlinks.imageryFilesTextArray != false) ? this.props.stringifiedtextlinks.imageryFilesTextArray:[],
      imageryFilesTextOptions: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.imageryFilesTextArray && this.props.stringifiedtextlinks.imageryFilesTextArray != false) ? 2 : 1,

      referenceFilesTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.referenceFilesTextArray && this.props.stringifiedtextlinks.referenceFilesTextArray != false) ? this.props.stringifiedtextlinks.referenceFilesTextArray:[],
      referenceFilesTextOptions: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.referenceFilesTextArray && this.props.stringifiedtextlinks.referenceFilesTextArray != false) ? 2 : 1,

      approvedWorkFilesTextArray: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.approvedWorkFilesTextArray && this.props.stringifiedtextlinks.approvedWorkFilesTextArray != false) ? this.props.stringifiedtextlinks.approvedWorkFilesTextArray:[],
      approvedWorkFilesTextOptions: (this.props.stringifiedtextlinks && this.props.stringifiedtextlinks.approvedWorkFilesTextArray && this.props.stringifiedtextlinks.approvedWorkFilesTextArray != false) ? 2 : 1,
      
      errors: [],
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      myTimezone:'',
      showProgress: true,
      availableAssignees:[],
      availableNotifiers:[],
      labels:[],
      tassignees:[],
      tnotifiers:[],
      selectedLabels:[],
      tags: [],
      setTags:[],
      templateFields:[],
      brandPredefinedColors:[],
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true,
      }
    };
    
    this.handleChangeEditor = this.handleChangeEditor.bind(this);
    this.reactTags = React.createRef();
  }
  
  modules = {
    toolbar: toolbarOptions,
    imageUploader: {
      upload: file => {
        return new Promise((resolve, reject) => {
          const data = new FormData();
          data.append('file', file);
          data.append('filename', file.name);
          data.append('filesize', file.size);
          data.append('filetype', file.type);
          data.append('folder_path', 'editor')
          data.append('user_id', 1);
          axios.post(urls.uploadProjectMediaEditor, data, { 
          })
          .then(res => {
            console.log(res);
            resolve(res.data.file_name);
          })
          .catch(error => {
              reject("Upload failed");
              console.error("Error:", error);
            });
        });
      }
    }
  };

  minimodules = {
    toolbar:  [
      ['bold', 'italic', 'underline'], 
      [{ 'color': [] }, { 'background': [] }],           
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
      ['link']    
    ]
  };

  componentDidMount = () => {
    document.title = "HUB - Project Management";
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      //_this.renderContentViewOwnership();
      _this.handleChangeBrand(this.props.taskdetails.brand_id);
      _this.getTaskAssets();
      _this.findTemplateFields(this.props.taskdetails.template_id);
    }, 300);
  };


  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));             
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        myTimezone:login.my_timezone
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home");
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('am_admin')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getTaskAssets = ()=>
  {
    const data = {
      project_id: this.props.projectid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.taskAssets, data, {
        headers: headers
      })
      .then((response) => {
        console.log(response.data.assignees);
        this.setState({
          availableAssignees: response.data.assignees,
          availableNotifiers: response.data.notifiers,
          designTypes: response.data.design_types,
          labels: response.data.labels
        })
      })
      .catch((error) => {
        //notifyError();
      })
  }

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
 
  handleChangeEditor(value) {
    this.setState({ ticketDetails: value })
  }

  

showDashboard =() =>
  {
    this.props.history.push("/home");
  }

  showTicketList = () =>
  {
    this.props.history.push("/tickets");
  }


onTdateChange = (date) => 
  {
    this.setState({ ticketDate: date });
  }

  showAssigneeNames = () =>
  {
    var tempassginees = '';
    let temp = this.state.tassignees.map(Number);
    this.state.availableAssignees.forEach(assignee => {
      if(temp.includes(assignee.id))
      {
        tempassginees += assignee.name +', ';
      }
    });
    this.setState({
      tassigneesnames: tempassginees.slice(0, -2)
    })
  }

  

  onDelete (i) {
    const tassignees = this.state.tassignees.slice(0)
    tassignees.splice(i, 1)
    this.setState({ tassignees })
  }
 
  onAddition (tag) {
    const tassignees = [].concat(this.state.tassignees, tag)
    this.setState({ tassignees })
  }

  onDeleteNotifiers (i) {
    const tnotifiers = this.state.tnotifiers.slice(0)
    tnotifiers.splice(i, 1)
    this.setState({ tnotifiers })
  }
 
  onAdditionNotifiers (tag) {
    const tnotifiers = [].concat(this.state.tnotifiers, tag)
    this.setState({ tnotifiers })
  }


  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags })
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
  }

  setAssignees = (tags) =>
  {
    this.setState({tassignees: tags })
  }

  setNotifiers = (tags) =>
  {
    this.setState({tnotifiers: tags })
  }

  setLabels = (tags) =>
  {
    this.setState({selectedLabels: tags })
  }
  removePreviewImg = (url) =>
  {
    var index = this.fileArray.indexOf(url); // 1
    this.fileArray.splice(index, 1);
    this.fileNamesArray.splice(index, 1);
    this.setState({imgagePreviews: this.fileArray, files: this.fileNamesArray});
  }


  

  onPeopleChange = (userid) =>
  {        
   this.setState({responsibleBy: userid});
  }

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("YYYY-MM-DD");
    this.setState({incidentDate:momentString});
  };

  setReminderime = (e)=>
  {
    console.log(e);
    this.setState({
      reminderTime:e
    })
  }

  setDueDate = (e)=>
  {
    this.setState({
      dueDate:e
    })
  }

  setReminderTimeOptions = (e)=>
  {
    if(e.target.value>0)
    {
      this.setState({
        reminderTime:new moment(new Date()).add(e.target.value, 'h').toDate()
      })
    }
  }

  handleChangeBrand = (target) => {
    this.setState({ brand_id: target});
    const data = {
      project_id: this.props.projectid,
      user_id: this.props.myid,
      brand_id: target
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.getBrandInfoTask, data, {
        headers: headers
      })
    .then((response) => {
      if(response.data.success !=10)
      {
      this.setState({
        DefaultGuidelines: response.data.info[0].branding_instructions,
        DefaultbrandPredefinedColors: JSON.parse(response.data.info[0].colors),
        DefaultColorInstructions: response.data.info[0].color_instructions,
        DefaultFontInstructions: response.data.info[0].font_instructions,
        DefaultWebsite: response.data.info[0].Website,
        DefaultFacebook:response.data.info[0].Facebook,
        DefaultTwitter: response.data.info[0].Twitter,
        DefaultLinkedIn: response.data.info[0].Linkedin,
        DefaultInstagram: response.data.info[0].Instagram,
        DefaultPinterest: response.data.info[0].Pinterest,
        DefaultOtherMedia: response.data.info[0].OtherMedia,
      })
      }
    })
    .catch((error) => {
      this.setState({
       progressBar:false
      })
    })
  };
 
  handleOnChange(key, e) {
    console.log(e.target.value);
    let post = this.state.post;
    post[key] = e.target.value; // key variable can be a string
    console.log(this.state);
  }

  handleTemplateChange = async(e) =>
  {
    this.setState({
      selectedTemplate:e.target.value
    });  
    const data = {
        design_type_id: e.target.value
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskTemplateFields, data, {
          headers: headers
        })
        .then((response) => {
          console.log(response.data.fields);
          this.setState({
            templateFields: response.data.fields
          })
        })
        .catch((error) => {
          
        })
  }

  

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  
  storeFileNames = async(arrayname, item)=>
  {   
      this.setState({showFiles:false});
      if(arrayname === 'brandingFiles')  
      {
        let temp =[...this.state.brandingFiles];
        temp.push(item);
        await this.setState({brandingFiles: temp});
      }else if(arrayname === 'textInstructionFiles')  
      {
        let temp =[...this.state.textInstructionFiles];
        temp.push(item);
        await this.setState({textInstructionFiles: temp});
      }else if(arrayname === 'imageryFiles')  
      {
        let temp =[...this.state.imageryFiles];
        temp.push(item);
        await this.setState({imageryFiles: temp});
      }else if(arrayname === 'referenceFiles')  
      {
        let temp =[...this.state.referenceFiles];
        temp.push(item);
        await this.setState({referenceFiles: temp});
      }else if(arrayname === 'approvedWorkFiles')  
      {
        let temp =[...this.state.approvedWorkFiles];
        temp.push(item);
        await this.setState({approvedWorkFiles: temp});
    }
    this.setState({showFiles:true});
  }

  deleteTaskFileNames = async(deletefile, arrayname) =>
  {  
      this.setState({showFiles:false});
      if(arrayname === 'brandingFiles')  
      {
        let temp =[...this.state.brandingFiles];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({brandingFiles: temp});
        this.setState({showAttachments:true});
      }else if(arrayname === 'textInstructionFiles')  
      {
        let temp =[...this.state.textInstructionFiles];
        var assetTextupdate = [...this.state.textInstructionFilesTextArray];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({
          textInstructionFiles: temp,
          textInstructionFilesTextArray:assetTextupdate});
        this.setState({showAttachments:true});

      }else if(arrayname === 'imageryFiles')  
      {
        let temp =[...this.state.imageryFiles];
        var assetTextupdate = [...this.state.imageryFilesTextArray];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({
          imageryFiles: temp,
          imageryFilesTextArray:assetTextupdate
        });
        this.setState({showAttachments:true});

      }else if(arrayname === 'referenceFiles')  
      {
        let temp =[...this.state.referenceFiles];
        var assetTextupdate = [...this.state.referenceFilesTextArray];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({
          referenceFiles: temp,
          referenceFilesTextArray: assetTextupdate
        });
        this.setState({showAttachments:true});
      }else if(arrayname === 'approvedWorkFiles')  
      {
        let temp =[...this.state.approvedWorkFiles];
        var assetTextupdate = [...this.state.approvedWorkFilesTextArray];
        var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
        temp.splice(itemkey, 1);
        assetTextupdate.splice(itemkey, 1);
        this.setState({showAttachments:false});
        await this.setState({
          approvedWorkFiles: temp,
          approvedWorkFilesTextArray: assetTextupdate
        });
        this.setState({showAttachments:true});
    }else if(arrayname === 'taskAttachments')  
    {
      let temp =[...this.state.taskAttachments];
      var assetTextupdate = [...this.state.taskAttachmentTextArray];
      var itemkey = temp.map(function (item) { return item.file_name; }).indexOf(deletefile); // (2D) finding the matching key providing value
      temp.splice(itemkey, 1);
      assetTextupdate.splice(itemkey, 1);
      this.setState({showAttachments:false});
      await this.setState({
        taskAttachments: temp,
        taskAttachmentTextArray:assetTextupdate
      });
      this.setState({showAttachments:true});
  }
    this.setState({showFiles:true});
  }

  storeTaskFileNames = async(arrayname, item)=>
  {
    this.setState({showFiles:false});
    let temp =[...this.state.taskAttachments];
    let tempText = [...this.state.taskAttachmentTextArray];
    await this.setState({taskAttachments:[]});
    temp.push(item);
    tempText.push('');
    await this.setState({taskAttachments: temp, taskAttachmentTextArray: tempText,showFiles:true});
}

updateFileText = (arrayname, values) =>
  {
    if(arrayname == 'taskAttachments')
    {
      this.setState({taskAttachmentTextArray:values});
    }else  if(arrayname == 'textInstructionFiles')
    {
      this.setState({textInstructionFilesTextArray:values});
    }else  if(arrayname == 'imageryFiles')
    {
      this.setState({imageryFilesTextArray:values});
    }else  if(arrayname == 'referenceFiles')
    {
      this.setState({referenceFilesTextArray:values});
    }else  if(arrayname == 'approvedWorkFiles')
    {
      this.setState({approvedWorkFilesTextArray:values});
    }
  }

  handClickUpdate = ({ target }) => {
    this.setState({showFiles:false});
    if(target.name=='taskAttachmentTextOptions')
    {
      this.setState({ [target.name]: target.value});
    }else if(target.name=='textInstructionFilesTextOptions')
    {
      this.setState({ [target.name]: target.value});
    }else if(target.name=='imageryFilesTextOptions')
    {
      this.setState({ [target.name]: target.value});
    }else if(target.name=='referenceFilesTextOptions')
    {
      this.setState({ [target.name]: target.value});
    }else if(target.name=='approvedWorkFilesTextOptions')
    {
      this.setState({ [target.name]: target.value});
    }
    this.setState({showFiles:true});
  };
  
  updateTask = async(type)=>
  {
    let temp = [];
    if(this.state.taskName == '')
    {
      temp.push('taskName');
      await this.setState({errors: temp});
      return false;
    }else if(this.state.dueDate=='' && type === 1)
    {
      temp.push('dueDate');
      await this.setState({errors: temp});
      return false;
    }else
    {
      let todayDate = moment();
      if (todayDate.isAfter(this.state.dueDate)) {
        temp.push('dueDate');
        await this.setState({errors: temp});
        return false;
      }
    }
    this.setState({errors: []});
    const data = {
      savetype: type,
      project_id:this.props.projectid,
      project_name:this.props.projectDetails.board_name,
      task_id: this.props.taskid,
      creator: this.state.myId,
      task_name: this.state.taskName,
      template_id: this.state.selectedTemplate,
      description: this.state.description,
      task_attachments: this.state.taskAttachments,
      task_type:this.state.TaskType,
      brand: this.state.brand_id,
      fieldDataText: {
        Size: this.state.sizeFields,
        FileSizeLimit: this.state.FileSizeLimit,
        Purpose: this.state.Purpose,
        Guidelines: this.state.Guidelines,
        Website: this.state.Website && this.state.Website.replace(/(^\w+:|^)\/\//, ''),
        Facebook:this.state.Facebook && this.state.Facebook.replace(/(^\w+:|^)\/\//, ''),
        Twitter: this.state.Twitter && this.state.Twitter.replace(/(^\w+:|^)\/\//, ''),
        LinkedIn: this.state.LinkedIn && this.state.LinkedIn.replace(/(^\w+:|^)\/\//, ''),
        Instagram: this.state.Instagram && this.state.Instagram.replace(/(^\w+:|^)\/\//, ''),
        Pinterest: this.state.Pinterest && this.state.Pinterest.replace(/(^\w+:|^)\/\//, ''),
        OtherMedia: this.state.OtherMedia && this.state.OtherMedia.replace(/(^\w+:|^)\/\//, ''),
        Text_Instructions: this.state.Text_Instructions,
        Style_Info: this.state.Style_Info,
        Style_Colors: (this.state.showAddonColors && this.state.addonColors && this.state.addonColors.length>0) ? (this.state.showBrandColors ? this.state.Style_Colors_Predefined.concat(this.state.addonColors): this.state.addonColors) : (this.state.showBrandColors ? this.state.Style_Colors_Predefined :[]),
        Style_color_predefined: this.state.showBrandColors ? this.state.Style_Colors_Predefined : '',
        Style_color_addon: this.state.showAddonColors ? this.state.addonColors : [],
        color_instructions: this.state.ColorInstructions,
        font_instructions:this.state.FontInstructions,
        Style_Fonts: this.state.Style_Fonts,
        Target_Audience: this.state.Target_Audience,
        Other_Instructions: this.state.Other_Instructions,
        File_Formats: this.state.outputFileFormats,
        File_Formats_other: this.state.fileFormatOther,
        Softwares: this.state.softwarestoUse,
        Software_other: this.state.softwareOther,
        outsoftInstructions: this.state.outsoftInstructions,
        Watermark: this.state.Watermark,
        Mockup: this.state.Mockup,
        mokupInstructions: this.state.Mockup === 'Yes' ? this.state.mokupInstructions : '',
        Provided_by_client: this.state.Provided_by_client,
        Previous_Approved_work: this.state.Previous_Approved_work,
        resize_crop: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.resize_crop : '' ),
        bleedSize: ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedSize : '' ),
        bleedUnit:  ((this.state.Purpose && this.state.Purpose === 'Print') ? this.state.bleedUnit : '' ),
      },
      fieldDataUploads:
      {
        brandingFiles: this.state.brandingFiles,
        textInstructionFiles: this.state.textInstructionFiles,
        imageryFiles: this.state.imageryFiles,
        referenceFiles: this.state.referenceFiles,
        approvedWorkFiles: this.state.approvedWorkFiles
      },
      fieldDataLinkText:
      {
        textInstructionFilesText: this.state.textInstructionFilesTextOptions == 1? this.state.textInstructionFilesText : '',
        textInstructionFilesTextArray: this.state.textInstructionFilesTextOptions == 2? this.state.textInstructionFilesTextArray : [],
        
        imageryFilesText: this.state.imageryFilesTextOptions == 1? this.state.imageryFilesText : '',
        imageryFilesTextArray: this.state.imageryFilesTextOptions == 2? this.state.imageryFilesTextArray : [],

        referenceFilesText: this.state.referenceFilesTextOptions == 1? this.state.referenceFilesText : '',
        referenceFilesTextArray: this.state.referenceFilesTextOptions == 2? this.state.referenceFilesTextArray : [],

        approvedWorkFilesText: this.state.approvedWorkFilesTextOptions == 1? this.state.approvedWorkFilesText : '',
        approvedWorkFilesTextArray: this.state.approvedWorkFilesTextOptions == 2? this.state.approvedWorkFilesTextArray : [],

        taskAttachmentsText: this.state.taskAttachmentTextOptions == 1 ? this.state.taskAttachmentsText:'',
        taskAttachmentTextArray: this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextArray:[]
      },
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateClientTask, data, {
        headers: headers
      })
      .then((response) => {
        console.log(response);
        //response.data.success === 1? notifySuccess() : (response.data.success === 2 ? notifyDraft() : notifyError() )
        if(response.data.success === 1 || response.data.success === 2)
        {
          window.scrollTo(0, 0);
          response.data.success === 1 ?  notifySuccess() :  notifyDraft();
         
          //this.props.history.push("/task/detail/"+this.props.projectid+"/"+this.props.taskid);
          this.props.closeedit();
        }
      })
      .catch((error) => {
        notifyError();
        this.props.closeedit();
      })
  }

  findTemplateFields = async(tid) =>
  {  
    const data = {
        design_type_id: tid
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.taskTemplateFields, data, {
          headers: headers
        })
        .then((response) => {
          console.log(response.data.fields);
          this.setState({
            templateFields: response.data.fields
          })
        })
        .catch((error) => {
          
        })
  }

  addSizeFields = () =>
  {
    const values = [...this.state.sizeFields];
    values.push({ width: '', height: '' ,unit:'', note:''});
    this.setState({sizeFields:values})
  }
  
 handleSizeInputChange = (index, event) => {
  const values = [...this.state.sizeFields];
    if (event.target.name === "widthsize") {
      values[index].width = event.target.value;
    } else if (event.target.name === "heightsize") {
      values[index].height = event.target.value;
    }else if (event.target.name === "unitsize") {
      values[index].unit = event.target.value;
    }else if (event.target.name === "note") {
      values[index].note = event.target.value;
    }
    this.setState({sizeFields:values})
  };


  removeSizeFields = index => {
    const values = [...this.state.sizeFields];
    values.splice(index, 1);
    this.setState({sizeFields:values})
  }

  handleFileFormats = e => {
    
    let fileFormats = [...this.state.outputFileFormats]; 
    if (e.target.checked) {
      fileFormats.push(e.target.value);
      this.setState({ outputFileFormats: fileFormats });
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:true});
      }
    } else {
      var array = [...this.state.outputFileFormats]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ outputFileFormats: array });
      }
      if(e.target.value=='other')
      {
        this.setState({fileFormatOtherShow:false, fileFormatOther:''});
      }
    }
  };

  handleSoftwares = e => {
    let softwares =  [...this.state.softwarestoUse]; 
    if (e.target.checked) {
      softwares.push(e.target.value);
      this.setState({ softwarestoUse: softwares });
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:true});
      }
    } else {
      var array = [...this.state.softwarestoUse]; 
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ softwarestoUse: array });
      }
      if(e.target.value=='other')
      {
        this.setState({softwareOtherShow:false, softwareOther:''});
      }
    }
  };

  clearSelection = (type) => {
    type == 'Watermark-clear' && this.setState({'Watermark': '' });
    type == 'Mockup-clear' && this.setState({'Mockup': '' });
    type == 'Provided-clear' && this.setState({'Provided_by_client': '' });
    type == 'Approved-clear' && this.setState({'Previous_Approved_work': '' });
    type == 'Reminder-clear' && this.setState({'reminderOptions': '', reminderTime:'' });
  };

  handleChangeUrls = ({ target }) => {
    let url = target.value.replace(/(^\w+:|^)\/\//, '');
    this.setState({ [target.name]: url });
  };

 stripslashes = (str) => {
    if(str!=null && str!=false && str!='')
    {
      str = str.replace(/\\'/g, '\'');
      str = str.replace(/\\"/g, '"');
      str = str.replace(/\\0/g, '\0');
      str = str.replace(/\\\\/g, '\\');
    }
    return str;
 }

 handleColorInputChange =  (index, event) => {
  const values = [...this.state.addonColors];
    if (event.target.name === "hexcode") {
      values[index]= event.target.value;
    }
   this.setState({addonColors: values});
}

addColorFields = ()=>
{
    const values = [...this.state.addonColors];
    values.push('');
    this.setState({addonColors: values});
}

removeColorFields = (index) => {
    const values = [...this.state.addonColors];
    values.splice(index, 1);
    this.setState({addonColors: values});
}

showOptions = ({target}) =>
{
  if(target.name ==='brandcolors')
  {
    if(target.checked)
    {
      let temp =[];
      this.state.DefaultbrandPredefinedColors.forEach(p1 => {
        temp.push(p1.hexcode);
      });
      this.setState({Style_Colors_Predefined:temp});
    }
    this.setState({
      showBrandColors: !this.state.showBrandColors,
    })
  }else if(target.name ==='addoncolors')
  {
    this.setState({
      showAddonColors: !this.state.showAddonColors,
    })
  }else if(target.name ==='brandinstro')
  {
    this.setState({
      showColorGuides: !this.state.showColorGuides,
    })
    }
}

addSelectedColors = (code) =>
{
  let temp =[...this.state.Style_Colors_Predefined];
  if(temp.length>0)
  {
    var index = temp.indexOf(code);
    if (index !== -1) {
      temp.splice(index, 1);
      this.setState({ Style_Colors_Predefined: temp });
    }else
    {
      temp.push(code);
      this.setState({ Style_Colors_Predefined: temp });
    }
  }else
  {
    temp.push(code);
    this.setState({ Style_Colors_Predefined: temp });
  }
}

handleBleedInputChange =  (event) => {
  if (event.target.name === "bleed-size") {
      this.setState({bleedSize:event.target.value});
      } else if (event.target.name === "unitsize") {
        this.setState({bleedUnit:event.target.value});
    }
};
 
  render() {
      return (
        <div className="edit_task_wrapper">
             <div className="form-group">
                <label>Task Name</label>
                <Input autoComplete="off" type="text" 
                    value={this.state.taskName}
                    className={this.state.errors.includes('taskName')? 'field-error text_box' : 'text_box'}
                     name="taskName" onChange={this.handleChange} />
             </div>
             {this.props.projectDetails.board_type == 1 &&
             (
                <div className="form-group choose_temp_edit">
                  <label>Choose Template</label>
                  <select className="choose_temp"  onChange={e => this.handleTemplateChange(e)}>
                      <option value="">Choose Template</option>
                      {(this.state.designTypes && this.state.designTypes.length>0) &&
                      (
                        <>
                          {this.state.designTypes.map((item, index)=>
                          {
                            return(
                              <option value={item.id} selected={this.state.selectedTemplate === item.id && 'selected'} >{item.design_type_name}</option>
                            )
                          })}
                        </>
                      )}
                  </select>
                </div>
             )}
              {this.state.selectedTemplate>0 &&
              (
              <div className="template-group-fields">
                <div className="form-group">
                  <label>Brand</label>
                  <select className="choose_temp" name="brand"  onChange={(e)=>this.handleChangeBrand(e.target.value)}>
                    <option value="">Select</option>
                    {this.state.availableBrands && this.state.availableBrands.length>0 &&
                    (
                      <>  
                        {this.state.availableBrands.map((branditem)=>
                        {
                          return(
                            <option key={branditem.brand_id} 
                            value={branditem.brand_id}
                            selected={this.state.brand_id === branditem.brand_id && 'selected'}
                            >{branditem.brand_name}</option>
                          )
                        })}
                      </>
                    )}
                    </select>
                </div>
                <Row>
                    { this.state.templateFields.indexOf("3") !== -1 &&
                    (
                      <Col sm="4">
                        <Label>Medium</Label>
                        <Input type="select" name="Purpose" onChange={this.handleChange}>
                          <option value="">Select</option>
                          <option value="Digital" selected={this.state.Purpose === 'Digital' && 'selected'}>Digital</option>
                          <option value="Print" selected={this.state.Purpose === 'Print' && 'selected'}>Print</option>
                        </Input>
                      </Col>
                    )}
                    {(this.state.Purpose && this.state.Purpose === 'Print') &&
                    (
                      <>
                      <Col sm="4" className="new-task-crop">
                          <Label>Crop / Trim Required</Label>
                          <div className='d-flex reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2x'>
                            <div className='d-flex crop-items'>
                                <Input type='radio' id="crop-yes" name="crop-resize" value="1"
                                checked={this.state.resize_crop == 1 && 'checked' }
                                onClick={(e)=> this.setState({resize_crop: 1})}  />
                                <Label for="crop-yes">Yes</Label>
                            </div>
                            <div className='d-flex crop-items'>
                                <Input type='radio' id="crop-no" name="crop-resize" value="0"
                                checked={this.state.resizeresize_cropCrop == 0 && 'checked' } 
                                onClick={(e)=> this.setState({resize_crop: 0})} />
                                <Label for="crop-no">No</Label>
                            </div>
                          </div>
                      </Col>
                      <Col sm="4" className="">
                          <div className='d-flex new-task-bleed'>
                          <div className='bleed-size'>
                          <Label>Bleed Size </Label>
                          <Input type='text' name="bleed-size" value={this.state.bleedSize} onChange={(e)=>this.handleBleedInputChange(e)} />
                          </div>
                          <div className='bleed-unit'>
                          <Label>Bleed Unit</Label>
                          <select className="choose_temp" name="unitsize"  onChange={(e)=>this.handleBleedInputChange(e)}>
                            <option selected={this.state.bleedUnit === 'Inch' && 'selected'} value="Inch">Inch</option>
                            <option selected={this.state.bleedUnit === 'PX' && 'selected'} value="PX">PX</option>
                            <option selected={this.state.bleedUnit === 'CM' && 'selected'} value="CM" >CM</option>
                            <option selected={this.state.bleedUnit === 'MM' && 'selected'} value="MM" >MM</option>
                            <option selected={this.state.bleedUnit === 'FT' && 'selected'} value="FT" >FT</option>
                          </select>
                          </div>
                          </div>
                      </Col>
                      </>
                    )}

                    { this.state.templateFields.indexOf("2") !== -1 &&
                    (
                      <Col sm="6">
                        <Label>File Size Limit</Label>
                        <Input  type="text" name="FileSizeLimit" onChange={this.handleChange} value={this.state.FIleSizeLimit} />
                      </Col>
                    )}
                    { this.state.templateFields.indexOf("1") !== -1 &&
                    (
                      <Col sm="12">
                        <Label className="custom_lable">Size</Label>
                        {(this.state.sizeFields && this.state.sizeFields.length>0) &&
                            (
                              <div className='size-group'>
                                {this.state.sizeFields.map((sitem, index)=>
                                {
                                  return(
                                    <div key={index} className="d-flex ">
                                      <div className="flex-itmes small-text-box">
                                        <Label>Width</Label>
                                        <Input autoComplete="off" type="text" name="widthsize" value={sitem.width}  onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      <div className="flex-itmes small-text-box">
                                        <Label>Height</Label>
                                        <Input autoComplete="off" type="text" name="heightsize" value={sitem.height} onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      <div className="flex-itmes unit_dropselect">
                                        <Label>Unit</Label>
                                        <select className="choose_temp" name="unitsize"  onChange={event => this.handleSizeInputChange(index, event)} >
                                          <option selected={sitem.unit === '' && 'selected'} value="">Select</option>
                                          <option selected={sitem.unit === 'PX' && 'selected'} value="PX">PX</option>
                                          <option selected={sitem.unit === 'Inch' && 'selected'} value="Inch">Inch</option>
                                          <option selected={sitem.unit === 'CM' && 'selected'} value="CM" >CM</option>
                                          <option selected={sitem.unit === 'MM' && 'selected'} value="MM" >MM</option>
                                          <option selected={sitem.unit === 'FT' && 'selected'} value="FT" >FT</option>
                                        </select>
                                      </div>
                                      <div className="flex-itmes big-text-box">
                                        <Label>Size Short Note</Label>
                                        <Input autoComplete="off" type="text" name="note" value={sitem.note} onChange={event => this.handleSizeInputChange(index, event)} />
                                      </div>
                                      {index=== 0 ?
                                      (
                                          <div className="flex-itmes flex-items-center plus_btn_taskadd" onClick={this.addSizeFields}><MaterialIcon icon="add"/></div>
                                      ):
                                      (
                                        <>
                                          <div className="flex-itmes flex-items-center plus_btn_taskadd" onClick={this.addSizeFields}><MaterialIcon icon="add"/></div>
                                          <div className="flex-itmes flex-items-center remove_btn_taskadd" onClick={this.removeSizeFields.bind(this.setState,index)}><MaterialIcon icon="remove"/> </div>
                                        </>
                                      )}
                                    </div>
                                  )
                               })}
                              </div>
                            )}
                      </Col>
                    )}
                </Row>
                <Row>
                  { this.state.templateFields.indexOf("4") !== -1 &&
                    (
                      <Col sm="12">
                        <h6 className="template-field-subheads">Branding</h6>
                      </Col>
                  )}
                  { this.state.templateFields.indexOf("5") !== -1 &&
                    (
                      <Col sm="12">
                        <Label>Brand Assets and Guidelines</Label>
                        <ReactQuill 
                          id="Guidelines" 
                          value={this.stripslashes(this.state.Guidelines)}
                          onChange={(e)=>this.setState({Guidelines:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          theme="snow" 
                          modules={this.modules}
                          className="edit_task_barndbox"
                        />
                        {/* <KFileUpload  myid={this.state.myId} folderpath="task/guidelines" arrayname="brandingFiles" storefiles={this.storeFileNames} />
                        {this.state.brandingFiles && this.state.brandingFiles.length>0 &&
                        (
                            <LightboxPop attachments={this.state.brandingFiles} deleteitem={'yes'} />
                        )} */}
                      </Col>
                    )}
                    {this.state.templateFields.indexOf("12") !== -1 &&
                    (
                    <>

                    <Col sm="12">
                      <div className="color_edit_wrap">
                      <h6>Colors</h6>
                      <FormGroup className="edit_brand_color">
                      <Input type="checkbox" name="brandcolors" id="brandcolors" 
                      checked={this.state.showBrandColors && 'checked'}
                      onClick={(e) => this.showOptions(e) } />
                      <Label for="brandcolors">Brand Colors</Label>
                      </FormGroup>

                      {(this.state.showBrandColors && this.state.DefaultbrandPredefinedColors && this.state.DefaultbrandPredefinedColors.length>0) &&
                      (
                        <div className="d-flex">
                        {this.state.DefaultbrandPredefinedColors.map((color, index)=>{
                          return(
                            <div key={color.id} className={`d-flex task-colors align-items-center ${(this.state.Style_Colors_Predefined && this.state.Style_Colors_Predefined.includes(color.hexcode)) && 'selected-color'}`} onClick={this.addSelectedColors.bind(this, color.hexcode)}>
                              <span className="hexcodeview" style={{background: color.hexcode && color.hexcode}}></span>
                              <span>{color.hexcode}</span>
                              {color.primary === 1 && 
                              (
                                <div className="primary_color">🌟</div>
                              )}
                            </div>
                          )
                        })}
                        </div>
                      )}
                      </div>
                     </Col> 

                     <Col sm="12">
                      <div className="color_edit_wrap">
                      <FormGroup className="edit_brand_color">
                      <Input type="checkbox" name="addoncolors" id="addoncolors"
                      checked={this.state.showAddonColors && 'checked'}
                        onClick={(e) => this.showOptions(e) }/>
                      <Label for="addoncolors">Add custom Colors</Label>
                      </FormGroup>

                      <div className="custom_edit_color">
                        <Row>
                          <Col xs={12} md={12}>
                          <Row>
                          {(this.state.showAddonColors && this.state.addonColors && this.state.addonColors.length>0) &&
                         (
                             <>
                              {this.state.addonColors.map((sitem, index)=>
                              {
                              return(
                           
                                  <Col sm={3} key={index}>
                                      <div className="custom_color_addbox">
                                      <span className="hexcodeview" style={{background: sitem ? sitem : '#fff'}}></span>
                                      <span><Input type="text" name="hexcode" value={sitem}  onChange={event => this.handleColorInputChange(index, event)} /></span>
                                      <button className="remove_btn" onClick={event => this.removeColorFields(index)}></button>
                                      </div>
                                  </Col>
                               
                              )
                          })}
                         </>
                      )}

                      {this.state.showAddonColors &&
                      (
                        <Button className="btn-green save_btn add_color_edit_btn" onClick={this.addColorFields.bind(this)} name="add-more-colors" value="add-coolors">+ </Button>
                      )}
                      </Row>
                      </Col>
                        </Row>
                      </div>
                  </div>
                      </Col>

                      <Col sm="12">
                      <div className="color_edit_wrap">
                          <FormGroup className="edit_brand_color">
                          <Input type="checkbox" name="brandinstro" id="brandinstro"  
                          checked={this.state.showColorGuides && 'checked'}
                          onClick={(e) => this.showOptions(e) }/>
                        <Label for="brandinstro">Attach Color and Font Instructions</Label>
                        </FormGroup>
                        {this.state.showColorGuides && 
                        (
                          <>
                          <Label>Color Instructions</Label>
                          <ReactQuill 
                          id="ColorInstructions" 
                          onChange={(e)=>this.setState({ColorInstructions:e})}
                          laceholder= "Add color instructions here....." 
                          theme="snow" 
                          value={this.state.ColorInstructions}
                          modules={this.modules}
                          />
                          </>
                        )} 
                      </div>
                      </Col>
                      </>
                      )} 
                     {(this.state.templateFields.indexOf("12") !== -1 && this.state.showColorGuides)  &&
                      (  
                        <Col sm="12" className="mb-4"> <br/>
                          <Label>Font Instructions</Label>
                          <ReactQuill 
                          id="FontInstructions" 
                          onChange={(e)=>this.setState({FontInstructions:e})}
                          laceholder= "Add font instructions here....." 
                          theme="snow" 
                          value={this.state.FontInstructions}
                          modules={this.modules}
                          />
                        </Col>
                      )}
                  
                </Row>
                <Row >
                    {this.state.templateFields.indexOf("8") !== -1 &&
                    (
                      <Col sm="12">
                        <h6 className="template-field-subheads">Text Copy</h6>
                        <ReactQuill 
                          id="Text_Instructions" 
                          value={this.stripslashes(this.state.Text_Instructions)}
                          onChange={(e)=>this.setState({Text_Instructions:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          theme="snow" 
                          modules={this.modules}
                        />
                        <Row className={`uploader-text ${this.state.textInstructionFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                          <Col sm="6">
                            <KFileUpload myid={this.state.myId} 
                            folderpath="task/text-instructions" 
                            arrayname="textInstructionFiles" 
                            storefiles={this.storeFileNames} />
                          </Col>
                          <Col sm="6">
                              <ReactQuill 
                              value={this.stripslashes(this.state.textInstructionFilesText)}
                              id="taskAttachmentsText" 
                              onChange={(e)=>this.setState({textInstructionFilesText:e})}
                              className={this.state.detailError? 'field-error' : ''}
                              placeholder= "Add the file details or links" 
                              theme="snow" 
                              modules={this.minimodules}
                              />
                          </Col>
                          <Col sm="12" >
                                {(this.state.textInstructionFiles && this.state.textInstructionFiles.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.textInstructionFilesTextOptions == 1 && 'checked'}  id="textInstructionscombined" 
                                      type="radio" name="textInstructionFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="textInstructionscombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.textInstructionFilesTextOptions == 2 && 'checked'} id="textInstructionsseparate" 
                                      type="radio" name="textInstructionFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="textInstructionsseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.textInstructionFiles && this.state.textInstructionFiles.length>0 && this.state.showFiles) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.textInstructionFiles} 
                                  deleteitem="yes"
                                  deletefiles={this.deleteTaskFileNames} 
                                  arrayname={'textInstructionFiles'}
                                  listtype={this.state.textInstructionFilesTextOptions == 2 ? this.state.textInstructionFilesTextOptions : 1}
                                  taskAttachmenttextarray={this.state.textInstructionFilesTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                        </Row>
                        
                      </Col>
                    )}
                </Row>
                {this.state.templateFields.indexOf("9") !== -1 &&
                (
                  <Row className="edit_img_sec"><h6 className="template-field-subheads">Images</h6></Row>
                )}
               {this.state.templateFields.indexOf("9") !== -1 &&
                  (
                    <Row className={`edit_img_sec uploader-text ${this.state.imageryFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                      <Col sm="6">
                        <KFileUpload myid={this.state.myId} 
                        folderpath="task/imagery" 
                        arrayname="imageryFiles" 
                        storefiles={this.storeFileNames}
                        deletefiles ={this.deleteTaskFileNames}  />
                       
                      </Col>
                      <Col sm="6">
                          <ReactQuill 
                            value={this.stripslashes(this.state.imageryFilesText)}
                            id="taskAttachmentsText" 
                            onChange={(e)=>this.setState({imageryFilesText:e})}
                            className={this.state.detailError? 'field-error' : ''}
                            placeholder= "Add the file details or links" 
                            theme="snow" 
                            modules={this.minimodules}
                            />
                      </Col>
                      <Col sm="12" >
                                {(this.state.imageryFiles && this.state.imageryFiles.length>0) &&
                                (
                                  <div className='reminder-options custom_radio options-items-parent d-flex'>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.imageryFilesTextOptions == 1 && 'checked'}  id="imageryFilescombined" 
                                      type="radio" name="imageryFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                      <Label for="imageryFilescombined">Combined instructions for all attachments</Label>
                                    </div>
                                    <div className='d-flex options-items'>
                                      <Input checked={this.state.imageryFilesTextOptions == 2 && 'checked'} id="imageryFilesseparate" 
                                      type="radio" name="imageryFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                      <Label for="imageryFilesseparate">Separate instructions for attachments</Label>
                                    </div>
                                  </div>
                                )}
                                {(this.state.imageryFiles && this.state.imageryFiles.length>0 && this.state.showFiles) &&
                                (
                                  <LightboxPop 
                                  attachments={this.state.imageryFiles} 
                                  deleteitem="yes"
                                  deletefiles ={this.deleteTaskFileNames}
                                  arrayname={'imageryFiles'}
                                  listtype={this.state.imageryFilesTextOptions == 2 ? this.state.imageryFilesTextOptions : 1}
                                  taskAttachmenttextarray={this.state.imageryFilesTextArray}
                                  updatefiletext={this.updateFileText}
                                  />
                                )}
                            </Col>
                    </Row>
                )}
                

                <Row className="edit_img_sec">
                    {this.state.templateFields.indexOf("10") !== -1 &&
                    (
                      <Col sm="12">
                        <h6 className="template-field-subheads">Client Comments</h6>
                        </Col>
                    )}
                    {/* {this.state.templateFields.indexOf("12") !== -1 &&
                    (
                      <Col sm="6">
                        <Label>Colors</Label>
                        <Input type="text" className="taskedit_input_box" name="Style_Colors" onChange={this.handleChange} value={this.state.Style_Colors} />
                      </Col>
                    )} */}
                    {/* {this.state.templateFields.indexOf("12") !== -1 &&
                    (
                      <Col sm="6">
                        <Label>Fonts</Label>
                        <Input type="text" className="taskedit_input_box"  name="Style_Fonts" onChange={this.handleChange} value={this.state.Style_Fonts} />
                      </Col>
                    )} */}
                    {this.state.templateFields.indexOf("14") !== -1 &&
                    (
                      <Col sm="12">
                        <Label>Target Audience</Label>
                        <Input autoComplete="off" type="text" name="Target_Audience" onChange={this.handleChange} value={this.state.Target_Audience}/>
                      </Col>
                    )}
                    
                    {this.state.templateFields.indexOf("15") !== -1 &&
                    (
                      <Col sm="12">
                        <Label>other Instructions</Label>
                        <ReactQuill 
                          id="Other_Instructions" 
                          value={this.stripslashes(this.state.Other_Instructions)}
                          onChange={(e)=>this.setState({Other_Instructions:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          theme="snow" 
                          modules={this.modules}
                        />
                      </Col>
                    )}
                </Row>
                <Row  className="edit_img_sec">
                  {this.state.templateFields.indexOf("16") !== -1 &&
                      (
                        <Col sm="12">
                          <h6 className="template-field-subheads">File Formats</h6>
                        </Col>
                  )}
                  
                  { this.state.templateFields.indexOf("19") !== -1 &&
                    (
                      <Col sm="12" className="mb-3">
                        <Label className="title_lable">Output File Formats</Label>
                        {/* <Input type="select" className="taskedit_input_box"  name="File_Formats" onChange={this.handleChange} >
                          <option value="">Select</option>
                          <option value="jpeg" selected={this.state.File_Formats === 'jpeg' && 'selected'}>jpeg</option>
                          <option value="png" selected={this.state.File_Formats === 'png' && 'selected'}>png</option>
                        </Input> */}
                        <div className="d-flex fileformat_card align-items-center">
                            <div className="d-flex flex-itmes">
                              <Input id="file-jpg" type="checkbox" name="file-formats" value="JPG" 
                               onChange={this.handleFileFormats.bind(this)}
                               checked={this.state.outputFileFormats.includes('JPG') && 'checked'} />
                              <Label for="file-jpg">JPG</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-png" type="checkbox" name="file-formats" value="PNG"
                              onChange={this.handleFileFormats.bind(this)}
                              checked={this.state.outputFileFormats.includes('PNG') && 'checked'}  />
                              <Label for="file-png">PNG</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-gif" type="checkbox" name="file-formats" value="GIF"
                              onChange={this.handleFileFormats.bind(this)} 
                              checked={this.state.outputFileFormats.includes('GIF') && 'checked'} />
                              <Label for="file-gif">GIF</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-pdf" type="checkbox" name="file-formats" value="PDF"
                              onChange={this.handleFileFormats.bind(this)}
                              checked={this.state.outputFileFormats.includes('PDF') && 'checked'}  />
                              <Label for="file-pdf">PDF</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-ppt" type="checkbox" name="file-formats" value="PPT"
                              onChange={this.handleFileFormats.bind(this)}
                              checked={this.state.outputFileFormats.includes('PPT') && 'checked'}  />
                              <Label for="file-ppt">PPT</Label>
                            </div>
                            <div className="d-flex flex-itmes">
                              <Input id="file-other" type="checkbox" name="file-formats" value="other"
                              onChange={this.handleFileFormats.bind(this)}
                              checked={this.state.outputFileFormats.includes('other') && 'checked'}  />
                              <Label for="file-other">Other</Label>
                            </div>
                            {this.state.fileFormatOtherShow &&
                            (
                              <div className="d-flex flex-itmes fileFormatOther">
                                <Input  type="text" name="fileFormatOther" value={this.state.fileFormatOther}
                                onChange={this.handleChange} />
                              </div>
                            )}
                          </div>
                      </Col>
                    )}
                    { this.state.templateFields.indexOf("20") !== -1 &&
                    (
                      <Col sm="12" className="mb-3">
                        <Label className="title_lable">Software to Use</Label>
                        <div className="d-flex align-items-center">
                            <div className="d-flex flex-itmes fileformat_card ">
                              <Input id="soft-psd" type="checkbox" name="Softwares" value="PSD"
                               onChange={this.handleSoftwares.bind(this)}
                               checked={this.state.softwarestoUse.includes('PSD') && 'checked'} />
                              <Label for="soft-psd">PSD</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-ai" type="checkbox" name="Softwares" value="Ai"
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('Ai') && 'checked'}  />
                              <Label for="soft-ai">Ai</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-xd" type="checkbox" name="Softwares" value="XD"
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('XD') && 'checked'}  />
                              <Label for="soft-xd">XD</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-indd" type="checkbox" name="Softwares" value="INDD" 
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('INDD') && 'checked'}  />
                              <Label for="soft-indd">INDD</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-figma" type="checkbox" name="Softwares" value="Figma" 
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('Figma') && 'checked'}  />
                              <Label for="soft-figma">Figma</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-canva" type="checkbox" name="Softwares" value="Canva" 
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('Canva') && 'checked'}  />
                              <Label for="soft-canva">Canva</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-ae" type="checkbox" name="Softwares" value="AE or PP" 
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('AE or PP') && 'checked'}  />
                              <Label for="soft-ae">AE or PP</Label>
                            </div>
                            <div className="d-flex flex-itmes fileformat_card">
                              <Input id="soft-other" type="checkbox" name="Softwares" value="other" 
                              onChange={this.handleSoftwares.bind(this)}
                              checked={this.state.softwarestoUse.includes('other') && 'checked'} />
                              <Label for="soft-other">Other</Label>
                            </div>
                            {this.state.softwareOtherShow &&
                            (
                              <div className="d-flex flex-itmes softwareOther fileformat_card">
                                <Input  type="text" name="softwareOther" value={this.state.softwareOther}
                                onChange={this.handleChange} />
                              </div>
                            )}
                          </div>

                      </Col>
                    )}
                    {(this.state.templateFields.indexOf("20") !== -1  ||  this.state.templateFields.indexOf("19") !== -1) &&
                      (
                        <Col sm="12" className="mb-3">
                            <Label className="title_lable">Instructions for Output file formats & Software to use</Label>
                            <ReactQuill 
                            id="outsoftInstructions" 
                            onChange={(e)=>this.setState({outsoftInstructions:e})}
                            placeholder= "" 
                            theme="snow" 
                            modules={this.minimodules}
                            value={this.stripslashes(this.state.outsoftInstructions)}
                            />
                        </Col>
                      )}
                    {this.state.templateFields.indexOf("17") !== -1 &&
                      (
                        <Col sm="6" className="mb-3">
                           <Label className="title_lable">Watermark</Label>
                           <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card">
                            <div className="d-flex">
                                <Input id="Watermark-Yes" value="Yes" type="radio" name="Watermark"
                                 onChange={this.handleChange} checked={this.state.Watermark === 'Yes' && 'checked' } />
                                <label for="Watermark-Yes">Yes</label>
                            </div>
                            <div className="d-flex">
                                <Input id="Watermark-No" value="No" type="radio" name="Watermark" 
                                onChange={this.handleChange} checked={this.state.Watermark === 'No' && 'checked' } />
                                <label for="Watermark-No">No</label>
                            </div>
                            {(this.state.Watermark && this.state.Watermark !='') &&
                            (
                              <div className="d-flex clear-selection" name="Watermark-clear" onClick={this.clearSelection.bind(this,'Watermark-clear')}>Clear Selection</div>
                            )}
                           </div>
                        </Col>
                  )}
                  {this.state.templateFields.indexOf("18") !== -1 &&
                      (
                        <>
                        <Col sm="6" className="mb-3">
                           <Label className="title_lable">Mockup</Label>
                           <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card">
                            <div className="d-flex">
                                <Input id="Mockup-Yes" value="Yes" type="radio" name="Mockup" 
                                onChange={this.handleChange} checked={this.state.Mockup === 'Yes' && 'checked' }/>
                                <label for="Mockup-Yes">Yes</label>
                            </div>
                            <div className="d-flex">
                                <Input id="Mockup-No" value="No" type="radio" name="Mockup" 
                                onChange={this.handleChange} checked={this.state.Mockup === 'No' && 'checked' }/>
                                <label for="Mockup-No">No</label>
                            </div>
                            {(this.state.Mockup && this.state.Mockup !='') &&
                              (
                                <div className="d-flex clear-selection" name="Mockup-clear" onClick={this.clearSelection.bind(this,'Mockup-clear')}>Clear Selection</div>
                            )}
                           </div>
                        </Col>
                        {this.state.Mockup === 'Yes' &&
                        (
                          <Col sm="12">
                            <Label>Mockup Instruction(optional)</Label>
                            <ReactQuill 
                            id="mokupInstructions" 
                            onChange={(e)=>this.setState({mokupInstructions:e})}
                            placeholder= "Add comments here..." 
                            theme="snow" 
                            modules={this.minimodules}
                            value={this.stripslashes(this.state.mokupInstructions)}
                            />
                          </Col>
                        )}
                        </>
                  )}
                </Row>
                <Row className="edit_img_sec">
                  {(this.state.templateFields.indexOf("22") !== -1 || this.state.templateFields.indexOf("23") !== -1) &&
                      (
                        <Col sm="12">
                        <h6 className="template-field-subheads">References</h6>
                        </Col>
                  )}
                  {this.state.templateFields.indexOf("22") !== -1 &&
                      (
                        <Col sm="12">
                           <Label className="title_lable">Provided By Client</Label>
                           <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2">
                            <div className="d-flex">
                                <Input id="Client-Recreate" value="Recreate" type="radio" name="Provided_by_client" 
                                onChange={this.handleChange} checked={this.state.Provided_by_client === 'Recreate' && 'checked' } />
                                <label for="Client-Recreate">Recreate</label>
                            </div>
                            <div className="d-flex">
                                <Input id="Client-Redesign" value="Redesign" type="radio" name="Provided_by_client" 
                                onChange={this.handleChange} checked={this.state.Provided_by_client === 'Redesign' && 'checked' }/>
                                <label for="Client-Redesign">Redesign</label>
                            </div>
                            <div >
                                  <Input id="Client-reference" value="Designer Reference Only"  type="radio" name="Provided_by_client"
                                   onChange={this.handleChange} checked={this.state.Provided_by_client === 'Designer Reference Only' && 'checked' }/>
                                  <label for="Client-reference">Designer Reference Only</label>
                              </div>
                              {(this.state.Provided_by_client && this.state.Provided_by_client !='') &&
                              (
                                <div className="d-flex clear-selection" name="Provided-clear" onClick={this.clearSelection.bind(this,'Provided-clear')}>Clear Selection</div>
                              )}
                           </div>
                           {(this.state.Provided_by_client!='' && this.state.Provided_by_client!=null)  &&
                           (
                            <Row className={`uploader-text ${this.state.referenceFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                              <Col sm="6">
                              <KFileUpload myid={this.state.myId} 
                              folderpath="task/client-provided" 
                              arrayname="referenceFiles" 
                              storefiles={this.storeFileNames}
                              deletefiles ={this.deleteTaskFileNames} />
                              
                              </Col>
                              <Col sm="6">
                                  <ReactQuill 
                                  value={this.stripslashes(this.state.referenceFilesText)}
                                  id="taskAttachmentsText" 
                                  onChange={(e)=>this.setState({referenceFilesText:e})}
                                  className={this.state.detailError? 'field-error' : ''}
                                  placeholder= "Add the file details or links" 
                                  theme="snow" 
                                  modules={this.minimodules}
                                  />
                              </Col>
                              <Col sm="12" >
                                  {(this.state.referenceFiles && this.state.referenceFiles.length>0) &&
                                  (
                                    <div className='reminder-options custom_radio options-items-parent d-flex'>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.referenceFilesTextOptions == 1 && 'checked'}  id="referenceFilescombined" 
                                        type="radio" name="referenceFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                        <Label for="referenceFilescombined">Combined instructions for all attachments</Label>
                                      </div>
                                      <div className='d-flex options-items'>
                                        <Input checked={this.state.referenceFilesTextOptions == 2 && 'checked'} id="referenceFilesseparate" 
                                        type="radio" name="referenceFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                        <Label for="referenceFilesseparate">Separate instructions for attachments</Label>
                                      </div>
                                    </div>
                                  )}
                                  {(this.state.referenceFiles && this.state.referenceFiles.length>0 && this.state.showFiles) &&
                                  (
                                    <LightboxPop 
                                    attachments={this.state.referenceFiles} 
                                    deleteitem="yes"
                                    deletefiles={this.deleteTaskFileNames} 
                                    arrayname={'referenceFiles'}
                                    listtype={this.state.referenceFilesTextOptions == 2 ? this.state.referenceFilesTextOptions : 1}
                                    taskAttachmenttextarray={this.state.referenceFilesTextArray}
                                    updatefiletext={this.updateFileText}
                                    />
                                  )}
                              </Col>
                            </Row>
                           )}
                        </Col>
                  )}
                  {this.state.templateFields.indexOf("23") !== -1 &&
                      (
                        <Col sm="12" className="taskedit_prevapprove_box">
                           <Label className="title_lable">Previously Approved Work</Label>
                           <div className="reminder-options custom_radio fileformat_card d-flex align-items-center">
                            <div >
                                <Input id="Work-Recreate" value="Recreate" className="form-check-input" type="radio" name="Previous_Approved_work" 
                                onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Recreate' && 'checked' }/>
                                <label for="Work-Recreate">Recreate</label>
                            </div>
                            <div >
                                <Input id="Work-Redesign" value="Redesign" className="form-check-input" type="radio" name="Previous_Approved_work" 
                                onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Redesign' && 'checked' }/>
                                <label for="Work-Redesign">Redesign</label>
                            </div>
                            <div >
                                  <Input id="Work-reference" value="Designer Reference Only" className="form-check-input" type="radio" name="Previous_Approved_work"
                                   onChange={this.handleChange} checked={this.state.Previous_Approved_work === 'Designer Reference Only' && 'checked' }/>
                                  <label for="Work-reference">Designer Reference Only</label>
                            </div>
                            {(this.state.Previous_Approved_work && this.state.Previous_Approved_work !='') &&
                              (
                                <div className="d-flex clear-selection" name="Approved-clear" onClick={this.clearSelection.bind(this,'Approved-clear')}>Clear Selection</div>
                            )}
                           </div>
                           {(this.state.Previous_Approved_work !='' && this.state.Previous_Approved_work != null) &&
                           (
                            <Row className={`uploader-text ${this.state.approvedWorkFilesTextOptions==2 && 'indv-instructions-wrap'}`}>
                                <Col sm="6">
                                  <KFileUpload myid={this.state.myId}
                                    folderpath="task/approved-work" 
                                    arrayname="approvedWorkFiles" 
                                    storefiles={this.storeFileNames}
                                    />
                                  
                                </Col>
                                <Col sm="6">
                                    <ReactQuill 
                                    value={this.stripslashes(this.state.approvedWorkFilesText)}
                                    id="taskAttachmentsText" 
                                    onChange={(e)=>this.setState({approvedWorkFilesText:e})}
                                    className={this.state.detailError? 'field-error' : ''}
                                    placeholder= "Add the file details or links" 
                                    theme="snow" 
                                    modules={this.minimodules}
                                    />
                                </Col>
                                <Col sm="12" >
                                    {(this.state.approvedWorkFiles && this.state.approvedWorkFiles.length>0) &&
                                    (
                                      <div className='reminder-options custom_radio options-items-parent d-flex'>
                                        <div className='d-flex options-items'>
                                          <Input checked={this.state.approvedWorkFilesTextOptions == 1 && 'checked'}  id="approvedWorkFilescombined" 
                                          type="radio" name="approvedWorkFilesTextOptions" value="1" onClick={this.handClickUpdate} />
                                          <Label for="approvedWorkFilescombined">Combined instructions for all attachments</Label>
                                        </div>
                                        <div className='d-flex options-items'>
                                          <Input checked={this.state.approvedWorkFilesTextOptions == 2 && 'checked'} id="approvedWorkFilesseparate" 
                                          type="radio" name="approvedWorkFilesTextOptions" value="2" onClick={this.handClickUpdate}  />
                                          <Label for="approvedWorkFilesseparate">Separate instructions for attachments</Label>
                                        </div>
                                      </div>
                                    )}
                                    {(this.state.approvedWorkFiles && this.state.approvedWorkFiles.length>0 && this.state.showFiles) &&
                                    (
                                      <LightboxPop 
                                      attachments={this.state.approvedWorkFiles} 
                                      deleteitem="yes"
                                      deletefiles={this.deleteTaskFileNames} 
                                      arrayname={'approvedWorkFiles'}
                                      listtype={this.state.approvedWorkFilesTextOptions == 2 ? this.state.approvedWorkFilesTextOptions : 1}
                                      taskAttachmenttextarray={this.state.approvedWorkFilesTextArray}
                                      updatefiletext={this.updateFileText}
                                      />
                                    )}
                                </Col>
                              </Row>
                           )}
                        </Col>
                    )}
                    {(this.state.templateFields.indexOf("6") !== -1 ||  this.state.templateFields.indexOf("7") !== -1) &&
                    (  
                        <>
                            <Col sm="6">
                                <Label>Website</Label>
                                <Input autoComplete="off" type="text" name="Website" onChange={this.handleChange} value={this.state.Website} />
                            </Col>
                            <Col sm="6">
                                <Label>Facebook</Label>
                                <Input autoComplete="off" type="text" name="Facebook" onChange={this.handleChange} value={this.state.Facebook} />
                            </Col>
                            <Col sm="6">
                                <Label>Twitter</Label>
                                <Input autoComplete="off" type="text" name="Twitter" onChange={this.handleChange} value={this.state.Twitter} />
                            </Col>
                            <Col sm="6">
                                <Label>LinkedIn</Label>
                                <Input autoComplete="off" type="text" name="LinkedIn" onChange={this.handleChange} value={this.state.LinkedIn} />
                            </Col>
                            <Col sm="6">
                                <Label>Instagram</Label>
                                <Input autoComplete="off" type="text" name="Instagram" onChange={this.handleChange} value={this.state.Instagram} />
                            </Col>
                            <Col sm="6">
                                <Label>Pinterest</Label>
                                <Input autoComplete="off" type="text" name="Pinterest" onChange={this.handleChange} value={this.state.Pinterest} />
                            </Col>
                            <Col sm="6">
                                <Label>Other</Label>
                                <Input autoComplete="off" type="text" name="OtherMedia" onChange={this.handleChange} value={this.state.OtherMedia} />
                            </Col>
                          </>
                      )}
                  </Row>
              </div>
              )}
             
              <>
                  <div className="form-group manager-comments edit_manager_comments">
                    {this.state.selectedTemplate<=0 ?
                    (<label>Description</label>)
                    :
                    (<label>Other Instructions by Project Manager</label>)
                    }
                    <ReactQuill 
                    id="description" 
                    value={this.stripslashes(this.state.description)}
                    onChange={(e)=>this.setState({description:e})}
                    className={this.state.detailError? 'field-error' : ''}
                    theme="snow" 
                    modules={this.modules}
                    />
                  </div>
                  
                  <Row className={`uploader-text ${this.state.taskAttachmentTextOptions==2 && 'indv-instructions-wrap'}`}>
                    <Col sm="6">
                      <div className="form-group">
                        <KFileUpload myid={this.state.myId} 
                          folderpath="task/attachments" 
                          arrayname="taskAttachments" 
                          storefiles={this.storeTaskFileNames} 
                          deletefiles ={this.deleteTaskFileNames}
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                        <ReactQuill 
                          value={this.stripslashes(this.state.taskAttachmentsText)}
                          id="taskAttachmentsText" 
                          onChange={(e)=>this.setState({taskAttachmentsText:e})}
                          className={this.state.detailError? 'field-error' : ''}
                          placeholder= "Add the file details or links" 
                          theme="snow" 
                          modules={this.minimodules}
                        />
                    </Col>
                    <Col sm="12" >
                        {(this.state.taskAttachments && this.state.taskAttachments.length>0) &&
                        (
                          <div className='reminder-options custom_radio options-items-parent d-flex'>
                            <div className='d-flex options-items'>
                              <Input checked={this.state.taskAttachmentTextOptions == 1 && 'checked'}  id="taskAttachmentscombined" 
                              type="radio" name="taskAttachmentTextOptions" value="1" onClick={this.handClickUpdate} />
                              <Label for="taskAttachmentscombined">Combined instructions for all attachments</Label>
                            </div>
                            <div className='d-flex options-items'>
                              <Input checked={this.state.taskAttachmentTextOptions == 2 && 'checked'} id="taskAttachmentsseparate" 
                              type="radio" name="taskAttachmentTextOptions" value="2" onClick={this.handClickUpdate}  />
                              <Label for="taskAttachmentsseparate">Separate instructions for attachments</Label>
                            </div>
                          </div>
                        )}
                        {(this.state.taskAttachments && this.state.taskAttachments.length>0 && this.state.showFiles) &&
                        (
                          <LightboxPop 
                          attachments={this.state.taskAttachments} 
                          deleteitem="yes"
                          deletefiles={this.deleteTaskFileNames} 
                          arrayname={'taskAttachments'}
                          listtype={this.state.taskAttachmentTextOptions == 2 ? this.state.taskAttachmentTextOptions : 1}
                          taskAttachmenttextarray={this.state.taskAttachmentTextArray}
                          updatefiletext={this.updateFileText}
                          />
                        )}
                    </Col>     
                  </Row>
              </>

              {((this.props.projectDetails.graphics_pack>0 && this.props.projectDetails.video_pack >0) ||
                (this.props.projectDetails.graphics_video_pack >0) || 
                (this.props.projectDetails.video_pack>0 && this.props.projectDetails.graphics_video_pack>0) ||
                (this.props.projectDetails.graphics_pack>0 && this.props.projectDetails.graphics_video_pack>0)) &&
                (
                  <>
                    <label>Task Type</label>
                    <div className="reminder-options custom_radio  d-flex align-items-center fileformat_card mb-2">
                        <FormGroup>
                            <Input name="TaskType" type="radio" value='1' id="graphics" 
                            checked={this.state.TaskType == '1' && 'checked'} onClick={this.handleChange}/>
                            <label for="graphics">Graphics</label>
                          </FormGroup>
                          <FormGroup>
                            <Input name="TaskType" type="radio" value='2' id="video" 
                            checked={this.state.TaskType == '2' && 'checked'} onClick={this.handleChange}/>
                            <label for="video">Video</label>
                        </FormGroup>
                    </div>
                  </>
                )}
               {this.props.taskdetails.status === 0 ?
                (
                <div className="form-group btn_sec">
                    <div className="d-flex"><Button className="cancel_btn" onClick={this.props.closeedit}>Cancel</Button></div>
                    <div className="d-flex">  <Button className="sd_btn" onClick={this.updateTask.bind(this, 0)}>Save as Draft</Button>
                  <Button type="submit" className="save_btn task_save_btn" onClick={this.updateTask.bind(this, 1)}>Publish</Button></div>
                </div>
              ):
              (
                <div className="form-group btn_sec">
                   <div className="d-flex"><Button className="cancel_btn" onClick={this.props.closeedit}>Cancel</Button></div>
                   <div className="d-flex"> 
                  <Button className="sd_btn" onClick={this.updateTask.bind(this, 0)} >Save as Draft</Button>
                  <Button type="submit" className="save_btn task_save_btn" onClick={this.updateTask.bind(this, 1)}>Update</Button>
                  </div>
                </div>
              )}
            </div>
    )
  }
} 