import React, { Component } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

export default class ClientReportFilter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {              
      selectedUser:'',
      startDate:'',
      endDate:'', 
      myPrivileges:[],     
      myId:'',      
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
        
  };


  handleInputChange(input, e) {
    
  }

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].id});
    }else
    {
      this.setState({ selectedUser:''});
    }
  }

searchEntries = () =>
{  
  console.log(this.state.startDate);
  this.props.searchentries(this.state.selectedUser, this.state.startDate, this.state.endDate);
}



  render() {
    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : 'Search Log';
    return (
      <div id="filter" className="Project_Work_Log">
         <div className="filter-row d-flex project-log-filter">   
             <Row>
               <Col xs={12} sm={3}>
               <div className="people date-col">
                <p className="filter-label">Project Manager: </p>
                <Typeahead
                id="user-picker"
                labelKey="name"
                onInputChange={this.handleInputChange}
                onChange={this.handleChange}
                options={this.props.pmpls}
                placeholder="Choose user..."
                selected=''
                />
              </div>
              </Col> 
               <Col xs={12} sm={4}>
               <div className="start-date datepicker-col date_picker_filter">
                  <p className="filter-label">Date Range: </p>               
                 <DateRangePicker
                    displayFormat={() => "DD/MM/YYYY"}
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
              </div>  
              </Col>
              <Col xs={12} sm={2}>
              <div className="search-btn">
                <Button color="success"  onClick={this.searchEntries.bind(this)} >
                  { searchContent }                  
                </Button>
              </div>
              </Col>
             </Row>
                

                         
              



            </div>
          
      </div>
    )
  }
}