import React,{useState, useEffect} from "react"; 
import {Button} from "reactstrap";

export default function ClipboardCopyLink({ copyText, copyaction }) {
    const [isCopied, setIsCopied] = useState(false);
  
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          copyaction(true);
          setTimeout(() => {
            setIsCopied(false);
            copyaction(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
      <div className="copytoclipboard">
          <span onClick={handleCopyClick}>
            <Button className="btn btn-copy-link btn-green">{isCopied ? 'Copied':'Copy Link'}</Button>
          </span>
      </div>
    );
  }