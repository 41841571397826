import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Dropicon from '../../images/drop_board_icon.png';
import MaterialIcon from "material-icons-react";

export default class TaskGroupActions extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      dropdownOpen: false
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  deleteList = ()=>
  {
    this.props.deletetoggle(this.props.editid);
  }

  render() {
    return (
    <div className="dropdownboard grup_task_dropicon internal_taskdropicon">
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle className="d-flex" caret>
            <MaterialIcon icon="more_vert"/>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={this.props.copytoggle}>Move a To-Do</DropdownItem> 
        </DropdownMenu>
      </Dropdown>
      </div>
    );
  }
}
