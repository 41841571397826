import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import ListBox from 'react-listbox';
import 'react-listbox/dist/react-listbox.css';
import { Scrollbars } from 'react-custom-scrollbars';
class FullUserListBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selected: [],
    };  
  }

  componentDidMount = () => {
    this.getFullUsers();
  };

   getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsersList;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        console.log(response[0].users);    
        this.setState({
          orginalUsers: response[0].users,          
        });            
    })
  };


  onChange = (selected) => {
    this.setState({ selected });
    const _this = this;
    setTimeout(() => {
      _this.props.holidaypeople(_this.state.selected);
    }, 100);
  };
  
    render() {
      const options = [
        { value: '1', label: 'Option One' },
        { value: '2', label: 'Option Two' },
      ];

      const { selected } = this.state;
      
      return (
        <div className={this.props.holidaypeople == 'true'? 'people date-col peopleerror': 'people date-col'}>
        <Scrollbars style={{ height: 400 }} className="xhide">
          {(this.state.orginalUsers && this.state.orginalUsers.length>0) &&
          (
            <ListBox options={this.state.orginalUsers}
            selected={selected}
            onChange={this.onChange} />
          )}
         </Scrollbars>
        </div>
      );
    }
}
export default FullUserListBox;
