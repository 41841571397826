import React from "react";
import basecampColor from "../../images/basecampColor.svg";
import basecampWhite from "../../images/basecampWhite.svg";
import { Link } from "react-router-dom";

export default function BasecampIcon(props) {
  const classes = "basecamp-icon " + props.classname;
  let BscIcon = props.active ? basecampWhite : basecampColor;
  return (
    <div className={classes}>
        {/* <a href={'https://3.basecamp.com/3148328/buckets/'+props.projectid+'/todos/'+props.taskid} target="_blank"> */}
        <a href={'/task/detail/'+props.projectid+'/'+props.taskid+"?uuid="+props.myId} target="_blank">
            <div style={{
            width: "33px",
            height: "33px",
            background: `url(${BscIcon}) no-repeat center`,
            marginRight: '19px',
            backgroundSize: '100% auto'
            }}>                
            </div>
        </a>
    </div>
  )
}