import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup, Breadcrumb, BreadcrumbItem} from 'reactstrap';
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { Typeahead } from 'react-bootstrap-typeahead';

export default class CopyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        progressBar:false,
        projects:[],
        selectedProject:''
    };
  }

  componentDidMount = () => {
    this.getInternalBoards();     
  };
  

  
  getInternalBoards = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      user_id: this.props.myid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getInternalActiveProjects, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          progressBar: false,
          projects: response.data.projects
        })
      })
      .catch((error) => {
        this.setState({
          progressBar: false
        })
      })
  };

  cloneListtoBoard = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      list_id: this.props.listid,
      list_name: this.props.listname,
      board_id: this.state.selectedProject
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.copyTaskList, data, {
      headers: headers
    })
      .then((response) => {
        this.setState({
          progressBar: false
        })
        this.props.closeanddirect(response.data.bid_cpt);
      })
      .catch((error) => {
        this.setState({
          progressBar: false
        })
      })
  };




  handleInputChange(input, e) {
    console.log("value", input)
  }
  
  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedProject:selectedOptions[0].id});
    }else
    {
      this.setState({ selectedProject:''});
    }
  }


  render() {
    return (
      <Fragment>
      <Container fluid className="top-bar-inactive bc_module_container">
       <div className="">
       <LoadingWrapper isLoading={this.state.progressBar}>
            <Typeahead
            id="project-picker"
            labelKey="name"
            onInputChange={this.handleInputChange}
            onChange={this.handleChange}
            options={this.state.projects}
            placeholder="Choose Board"
            selected=''
            />
            <div className='d-flex align-items-center justify-content-center save-copy-list'>
                <Button name="Save" className='btn btn-green' onClick={this.cloneListtoBoard}>Save</Button>
            </div>
        </LoadingWrapper>  
       </div>      
      </Container>
    </Fragment>
    )
  }
}
