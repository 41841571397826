import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';

class FullUserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[{'id':'-999', 'name': 'Client'}, {'id':'-998', 'name': 'None'}],
      orginalUsersReminder:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getFullUsers();
  };

   getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),
          orginalUsersReminder: response[0].users,          
        });            
    })
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
        this.props.onPeopleChange(selectedOptions);
    }else
    {
      this.props.onPeopleChange(selectedOptions);
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }
    render() {
      return (
        <div className="people date-col">
          <Typeahead
          id="user-picker"
          labelKey="name"
          multiple
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          options={this.state.orginalUsers}
          placeholder="Choose Member..."
          selected={this.props.selected}
        />
         
        </div>
      );
    }
}

export default FullUserPicker;
