import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody, Input, Collapse } from 'reactstrap';
import './basecampmodule.css'
import MaterialIcon from "material-icons-react";
export default class EditSubFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: true,
      nameError:false,
      editId: this.props.editid,
      editName: this.props.editname,
      fieldChanges:[]
    };
    this.toggle = this.toggle.bind(this);
    
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  addFolder = ()=>
  {
    if(this.state.editName==='')
    {
      this.setState({nameError:true});
      return false;
    }else
    {
      this.toggle();
      this.props.editfolder(this.state.editName, this.state.editId, this.props.editname);
    }
  }

  

  render() {
    return (
      <>
        
        <Collapse
     
        className="folder_rename_box"
        isOpen={this.state.popoverOpen}  toggle={this.toggle}
      >
      <div className="subfolder_overlay" onClick={this.toggle}></div>  
      <Button className="subfolder_closer" onClick={this.toggle}><MaterialIcon icon="do_not_disturb_on"/> </Button>
      
      <Input type="text" className="add_subfolder_text" placeholder="Folder Name"
      value={this.state.editName}
      onChange={(e)=>this.setState({editName:e.target.value})} />
      <Button type="submit" className="cf_btn" onClick={this.addFolder}>Rename folder</Button>

      </Collapse>
        {/* <Popover style={{ width:'270px', padding:"10px" }} placement="bottom-start" className="add_subfolder_popover" isOpen={this.state.popoverOpen} target="Popover6" toggle={this.toggle}>
          <PopoverBody>
              <Input type="text" className="add_subfolder_text" placeholder="Folder Name"
               value={this.state.editName}
               onChange={(e)=>this.setState({editName:e.target.value})} />
              <Button type="submit" className="cf_btn" onClick={this.addFolder}>Rename folder</Button>
          </PopoverBody>
        </Popover> */}
      </> 
    
    );
  }
}