import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import ClientReportFilter from "../filter/ClientReportFilter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import BasecampIcon from '../svgicon/BasecampIcon';
import TrelloIcon from '../svgicon/TrelloIcon';

export default class ClientWorkReport extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',    
      pmPls:[], 
      showProgress: false,    
      showProgressSearch: false,
      orginalEntries:[],   
      detailEntries:[],
      project_id:'',
      showTaskPeopleId:'',
      showTaskPeople:'',
      selectedPm:'',     
      show_detail:false,
      show_detail_id:0,     
      startDate: null,
      endDate: null,                         
      showMenu:true,     
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Client Work Report";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    this.reloadChecker(); 
    this.getPmPls();
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  reloadChecker = () =>
  {
    if(this.props.location.search === '?redirect=1')
    {
      this.props.history.push("/tick-report");
    }
  }  

  getPmPls = () => {
    fetch(urls.getPmPls, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
    .then(response => response.json())
    .then(response => {
      this.setState({pmPls: response.result})
    })
    .catch(error => {
      this.setState({ showProgress: false });
    });
  };

  getClientWorkReport = (selectedpm, start_date, end_date) => {
    this.setState({
        orginalEntries: [],
        showProgress:true,
        startDate:start_date,
        endDate:end_date,
        selectedPm:selectedpm
    })
    let opts = {
      pm: selectedpm,
      start_date: start_date,
      end_date: end_date,
    };
    fetch(urls.clientWorkReport, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
      .then(response => response.json())
      .then(response => {
        console.log(response.result);
        this.setState({
         orginalEntries: response.result,
         showProgress: false
        });
      })
      .catch(error => {
        this.setState({ showProgress: false });
      });
  };
  
  searchEntriesProjectDetails = (project) =>
  {  
   if(this.state.project_id != project)
   {
    this.setState({
        detailEntries:[],
        showDetailProgress:true,
        showTaskPeopleId: 0,
        showTaskPeople: false
      });
      let opts = {
        project_id:project,
        start_date: this.state.startDate,
        end_date: this.state.endDate,
        pm: this.state.selectedPm
      };
      this.setState({
         DetailView:false,
         project_id:project
      });
      fetch(urls.postBaseProjectReportsForLogsClient,{
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {
        this.setState({
          detailEntries:response['results'],
          DetailView:true,
          showDetailProgress:false
         });
      })
      .catch(error =>{
        this.setState({showDetailProgress:false});
      })
    }else
    {
        this.setState({
            DetailView:false,
            project_id:''
         });
    }
  }

  showTaskPeople = (id)=>
  {
      if(id === this.state.showTaskPeopleId)
      {
          this.setState({
              showTaskPeopleId: id,
              showTaskPeople: !this.state.showTaskPeople
          })
      }else
      {
        this.setState({
            showTaskPeopleId: id,
            showTaskPeople: true
        })
      }
  }

  timeConvert = (n) => {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours + " hrs and " + rminutes + " mnts";
    }

  render() {

    const list = {
      visible: {
        opacity: 1,
        transition: {
          when: "beforeChildren",
          staggerChildren: 0.03,
        } 
      },
      hidden: { 
        opacity: 0,
        transition: {
          when: "afterChildren",
        } 
      },
    }

    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    let deviceWidth = window.innerWidth;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " project-report"}>
        <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col xs={12} md={12}>
              <Card className="cl_helth_report">
                <h1 class="page-title text-center her_title">Project Work Log</h1>
                <ClientReportFilter pmpls={this.state.pmPls}  searchentries={this.getClientWorkReport} /> 
                <div id="list-head" className="project-work-log client-work-log">
                
                <Table responsive className="theme_table">

                  <thead>
                    <tr> 
                      <th>Project Name</th>
                      <th>Time Spent</th>
                    </tr>
                  </thead>
                  

                  <tbody id="date-list-contianer" className="project-work-log">
                
                      <motion.section
                        style={{ display: 'contents' }}
                        initial="hidden"
                        animate="visible"
                        variants={list}
                       
                      >
                          {this.state.orginalEntries.map((entries, index) => {
                            return (
                              <motion.div variants={item} style={{ display:'contents' }}>
                              
                                   
                                      <tr onClick={this.searchEntriesProjectDetails.bind(this, entries.pmt_project_id)} className={this.state.project_id === entries.pmt_project_id  ? 'clickable-item active_expand_table':'clickable-item'}>
                                        <td>{entries.project_name}</td>
                                        <td>{this.timeConvert(entries.total)}</td>
                                      </tr> 

                                      {(this.state.project_id === entries.pmt_project_id && this.state.showDetailProgress) &&
                                      (
                                        <LoadingWrapper  LoaderCount={5} isLoading={this.state.showDetailProgress}></LoadingWrapper>
                                      )}


                                      {this.state.detailEntries.length>0 && this.state.project_id === entries.pmt_project_id  &&
                                        (   
                                            <>
                                               <td colSpan="2" className="expand_th">
                                                       
                                                        <Table responsive className="theme_table expand_table">
                                                         <thead>
                                                           <tr>
                                                         
                                                             <th className="text-center text-color">Shift Day</th>
                                                             <th className="text-center text-color">Total Time</th>
                                                           
                                                           </tr>
                                                         </thead>

                                                         <tbody>
                                                         {this.state.detailEntries?
                                                    (
                                                    <>
                                                    { this.state.detailEntries.map( ( entries, index ) => {
                                                    return (
                                                    <motion.div variants={item} style={{ display:'contents' }}>

                                                            <tr onClick={this.showTaskPeople.bind(this, entries.shift_day)}>
                                                              <td className="text-center">{entries.shift_day}</td>
                                                              <td className="text-center">{entries.day_total}</td>
                                                            </tr>

                                                          <td colSpan="2" className="expand_th">
                                                          {this.state.showTaskPeople && this.state.showTaskPeopleId === entries.shift_day &&
                                                            (
                                                                <>
                                                                    {entries.items.map((people, index)=>
                                                                    {
                                                                      var trello = people.task_name.split("##");
                                                                      return(
                                                                        <>
                                                                        <Table responsive className="theme_table inner_table">
                                                                         
                                                                          <tbody className="inner_expand_table">
                                                                            <tr key={index}>
                                                                              <td></td>
                                                                              
                                                                              <td className="threezero_width">{people.task_name}</td>
                                                                              <td className="threezero_width">{people.user_total}</td>
                                                                              <td className="threezero_width">
                                                                              <div className="flex_box">
                                                                              <BasecampIcon projectid={people.project_id} taskid={people.task_id} />
                                                                              {trello.length>1 &&
                                                                              (
                                                                                <a target="_blank" href={"https://trello.com/c/"+trello[1]}><TrelloIcon /></a>
                                                                              )}
                                                                              </div>
                                                                              </td>
                                                                             
                                                                              <td></td>

                                                                            </tr>
                                                                          </tbody>
                       
                                                                        </Table>
                                                                       
                                                                        </>
                                                                    )}
                                                                    )}
                                                                    
                                                                </>
                                                            )}
                                                          </td>
                                                           </motion.div>
                                                    )
                                                })
                                                }
                                                    </>
                                                    )
                                                    :
                                                    null
                                                }

                                                         </tbody>
                                                       </Table>

                                                      
                                                
                                                



                                              </td>

                                            </>
                                        )}
                                    
                                  
                              </motion.div>
                            );
                          })}
                      </motion.section>
                
                  </tbody>






                  

                </Table>
           
                <LoadingWrapper 
                    LoaderCount={5} style={{ display: 'contents' }}
                    isLoading={this.state.showProgress}></LoadingWrapper> 

          </div>
                </Card>
            </Col>
          </Row>
       
        </div>
       
      </Row>
     
      </Container>
      </div>
    )
  }
}
