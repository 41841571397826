import React, { Component } from 'react';
import DatePickerTick from "./DatePickerTick";
import UserPicker from "./UserPicker";
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import { Label } from '@material-ui/icons';

export default class MFilter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {
      selectedUser:'',
      start_date:momentStringStart,
      end_date:momentStringEnd, 
      myPrivileges:[],     
      myId:'', 
      myBaseId:'',
      userTeam:'',  
      currentYear:momentObje.format("YYYY"),
      currentMonth:momentObje.format("M"),
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();   
    this.yearsRender(); 
    this.monthsRender(); 
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onDateRangeChangeStart = (start) => {
    let momentObj = moment(start);
    let momentString = momentObj.format("DD-MM-YYYY");
    this.setState({start_date:momentString});
};
onDateRangeChangeEnd = (end) => {
  let momentObj = moment(end);
  let momentString = momentObj.format("DD-MM-YYYY");
  this.setState({end_date:momentString});
};

onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}

searchEntriesnew = () =>
{  
  this.props.searchEntriesone(this.state.currentYear, this.state.currentMonth, this.state.selectedUser);
}

hoursRender = () =>  {
  const optionsval = [];
  for (var i=1; i <=  12; i++) {
    optionsval.push(<option selected={this.state.filterHour == i? 'selected':''} value={i}>{"< "+i+" hour"}</option>);
  }
  return optionsval;
};

yearsRender = () =>
{
  const yearsval = [];
  for(var j=2020;j<=this.state.currentYear;j++)
  {
    yearsval.push(<option selected={this.state.currentYear == j? 'selected':''} value={j}>{j}</option> )
  }
  return yearsval;
};

monthsRender = () =>
{
  var theMonths = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"); 
  const monthsval = [];
  const _this= this;
  var k=1;
  theMonths.forEach(function(element){
    monthsval.push(<option selected={_this.state.currentMonth == k? 'selected':''} value={k}>{element}</option> )
    k++;
  });
  return monthsval;
}

filterByHours=(e)=>
{ 
  this.setState({filterHour:e.target.value}); 
  if (e.target.checked) {
  this.props.filterbyhour(e.target.value);
  }else
  {
    this.props.filterbyhour('');
  }
};

setCurrentYear = (e) =>
{
  this.setState({currentYear: e.target.value});
}
setCurrentMonth = (e) =>
{
  this.setState({currentMonth: e.target.value});
}

filterByNonProductive = (e)=>
{
  if (e.target.checked) {
    this.props.filterbynonproductive(e.target.value);
  }else
  {
    this.props.filterbynonproductive('');
  }
}

  render() {
    return (
      <div id="filter" className="overview_filter">
         <Row>
            <Col xs={12} md={8} lg={8}>
              <Row>
                <Col xs={12} md={6} lg={3}>
                  <div className="years">
                    <p className="">Year</p>
                    <FormGroup>
                    <Input id="yearfilter" type="select" onChange={this.setCurrentYear.bind(this)} >
                        <option value=''>Select</option>
                      {this.yearsRender()}
                      </Input>
                    </FormGroup>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={3}>
                <div className="months">
                  <p className="">Month</p>
                  <FormGroup>
                  <Input id="yearfilter" type="select" onChange={this.setCurrentMonth.bind(this)} >
                      <option value=''>Select</option>
                    {this.monthsRender()}
                    </Input>
                  </FormGroup>
                </div>
                </Col>
                <Col xs={12} md={5} lg={5}>
                {this.state.myPrivileges.includes('access_others_report')?
                  (<UserPicker myid={this.state.myId} baseid={this.state.myBaseId} teamid={this.state.userTeam} usertype={this.state.showPeopleType} onPeopleChange={this.onPeopleChange.bind(this)} />)
                  :
                  null
                  }
                </Col>
                <Col xs={12} md={1} lg={1}>
                  <div className="search-btn">
                  <Button color="success"  onClick={this.searchEntriesnew.bind(this)} ><SearchIcon /></Button>
                </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={4} lg={4}>
            <div className="filter-row d-flex checkboxs">
              
               
              {this.state.myPrivileges.includes('access_others_report')? null
                   :
                   (<div className="end-date date-col datepicker-col"></div>)
                   }  
                   <div className="d-flex daily-log-adv-filter">
                   
                   {(this.props.listcount>0  ) &&
                    (
                     <div className="hours d-flex ml-auto p-2 min-work-hour">
                      {/* <p className="">{'< 07:30 Work Hours'}</p> */}
                       <FormGroup className="">
                         <Input id="hourfilter" type="checkbox" value="7:30" onChange={this.filterByHours.bind(this)}  />
                         <label for="hourfilter">{'< 07:30 Work Hours'}</label>
                       </FormGroup>
                     </div>
                    )
                   }
                   {(this.props.listcount>0  ) && 
                    (
                     <div className="hours d-flex ml-auto p-2  min-work-hour">
                        {/* <p className=""></p>  */}
                       <FormGroup className="">
                        
                       <Input id="nonproductive" type="checkbox" value="2" onChange={this.filterByNonProductive.bind(this)}  />
                       <label for="nonproductive">{'> 2 hrs Unproductive'}</label>
                       </FormGroup>
                     </div>
                    )
                   }
                   </div>            
                 </div>
            </Col>
         </Row>
            
      
      </div>
    )
  }
}