import React, { Component, Fragment } from "react";
import "./ApprovalAvatarHead.css";
import AvtarApproval from "../../Avatars/AvatarApproval/AvatarApproval";

export default class ApprovalAvatarHead extends Component {
  render() {
    return (
      <Fragment>
        <div className="appr-head d-flex">
          <div className="appr-avatar-head d-flex">
            <AvtarApproval />
            <h5>{this.props.name}</h5>
          </div>
          <p className="appr-brk-time-took ml-auto">{this.props.exceededtime !='' && this.props.exceededtime !=null ? this.props.exceededtime : null }</p>
        </div>
        <p className="appr-msg">
         {this.props.comment}
        </p>
      </Fragment>
    );
  }
}
