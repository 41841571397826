import React, { Component, Fragment } from "react";
import { Card, Button } from "reactstrap";
import "../LeaveStatus.css"
import ConfirmAction from "../../../../admincomment/ConfirmAction";

export default class LeaveStatusCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWithdrawalDialogue:false,
    };
  }
  showWithDrawBox = () =>
  {
    this.setState({showWithdrawalDialogue:true});
  }

  closeDialogue = () => {
    this.setState({
      showWithdrawalDialogue: false
    });
  };

  handleWithdraw = () => {
    this.setState({ showWithdrawalDialogue: !this.state.showWithdrawalDialogue });  
    this.props.withdrawaction(this.props.leave);
  };

  render() {
    return (
      <Card className="leave-status-card d-flex">
        <div className="day leave-status-col leave-days-col">
          <p>Day(s)</p>
          <h4>{this.props.leave.mdate}</h4>
        </div>
        <div className="date leave-status-col">
          <p>Applied For</p>
          <h4>{this.props.leave.applied_for}</h4>
        </div>
        <div className="type leave-status-col">
          <p>Type</p>
          <h4>{this.props.leave.leave_type == 'General Leave' ? 'Personal': this.props.leave.leave_type.replace(/ .*/,'')}</h4>
        </div>
        {/* <div className="approved-by leave-status-col">
          <p>Reviewed By</p>
          <h4>{this.props.leave.approved_by}</h4>
        </div> */}
        <div className="status leave-status-col pending">
          <p>Status</p>
          <h4>{this.props.leave.status}</h4>
          {/* {((this.props.leave.status === "Pending" || this.props.leave.status === "Approved") && this.props.myid != this.props.leave.user_id) && (
            <Button className="btn-green btn-small" onClick={this.showWithDrawBox.bind(this)} >
              Withdraw
            </Button>
          )} */}

          {((this.props.leave.status === "Pending") && (this.props.myid != this.props.leave.user_id) &&
          (this.props.myprivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) ) && (
            <Button className="btn-green btn-small" onClick={this.showWithDrawBox.bind(this)} >
              Withdraw
            </Button>
          )}

        </div>
        {this.state.showWithdrawalDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showWithdrawalDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to withdraw a leave request"
            handleCompletionConfirm={this.handleWithdraw}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
      </Card>
    );
  }
}
