import React, { Component, Fragment } from "react";

export default function ApprovedMsg(props) {
  return (
    <div className="appr-approved-msg d-flex">
      <div className="left">
        <p className="title">{props.label}</p>
        <p className="appr-approver">{props.approvedby}</p>
      </div>
      <p className="right ml-auto">{props.approvedon}</p>
    </div>
  );
}
