import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';
// import { Scrollbars } from 'react-custom-scrollbars';
class ProjectPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalProjects:[],
      selectedProject:'',
    };  
  }

  componentDidMount = () => {
    this.getAllBaseProjects();
  };

   getAllBaseProjects = () =>{  
    var fUrl = urls.getReportBoards;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {  
        console.log(response);      
        this.setState({
          orginalProjects: response,          
        });       
      } 
    })
  };


  onChangeProject = (e) => {
    this.setState({ selectedProject:e.target.value});
    setTimeout(() => {
      this.props.onProjectChange(this.state.selectedProject);
    }, 100);     
  };
  

  handleInputChange(input, e) {
    console.log("value", input)
  }
  
  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedProject:selectedOptions[0].id});
      const _this = this;
      setTimeout(() => {
        _this.props.onProjectChange(_this.state.selectedProject);
      }, 100);
    }else
    {
      this.setState({ selectedProject:''});
      const _this = this;
      setTimeout(() => {
        _this.props.onProjectChange(_this.state.selectedProject);
      }, 100);
    }
    
  }

    render() {
      return (
        <div className="people">
          <p className="filter-label">Project: </p>
          {/* <Scrollbars style={{ height: 170 }}> */}
          <Typeahead
          id="user-picker"
          labelKey="name"
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          options={this.state.orginalProjects}
          placeholder="Choose Project..."
          selected=''
        />
        {/* </Scrollbars> */}
        </div>
      );
    }
}

export default ProjectPicker;
