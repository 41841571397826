import React, { useState, useEffect } from 'react'
import { FilePond, File, registerPlugin } from 'react-filepond'
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import {urls} from "../../services/UrlList";
import axios from 'axios';
import { FileUploader } from "react-drag-drop-files";
import {Progress } from 'reactstrap';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType
);

export default function KFileUpload(props)
{
    const [files, setFiles] = useState([])
    const [uploadCount, setuploadCount] = useState(0);
    const [uploadingProgress, setuploadingProgress] = useState(false);
    const [uploadedCount, setuploadedCount] = useState(0);
    const [uploadPercentage, setUploadPercentage] = useState(0);

    useEffect(()=>{
      setTimeout(() => {
          if(uploadPercentage === 100)
          setUploadPercentage(0)
      }, 1000);
  },[uploadPercentage]);

  const fileTypes = ["ai", "bmp", "gif",'ico','jpg','png','ps','psd','svg','tif','tiff','jpeg','webp','eps','jfif','heic','heif',
                  'fnt','fon','otf','ttf','epub', 'indd',
                  'zip', 'fig','mp4','xd','mov',
                  'aif', 'cda','mid','midi','mp3','mpa','ogg','wav','wma','wpl',
                  'doc', 'docx','odt','pdf','rtf','txt','wpd',
                  'ods','xls','xlsm','xlsx',
                  'key','odp','pps','ppt','pptx', 'raw','webm'];
                  
    function handleInit() {
        console.log("FilePond instance has initialised");
    }
    
    function handleDelete(file, arrayname)
    {
      var assetupdate = [...files];
      var itemkey = assetupdate.map(function (item) { return item.serverId; }).indexOf(file); // (2D) finding the matching key providing value
      var deletefilename = assetupdate[itemkey].filename;
      assetupdate.splice(itemkey, 1);
      setFiles(assetupdate);
      props.deletefiles(deletefilename,arrayname);
    }


    function handleChangeFile(files) {
      setuploadCount(files.length);
      setuploadingProgress(true);
      
      const headers = {
       'Content-Type': 'application/json',
       'Access-Control-Allow-origin':'*',
     }
      for (let i = 0; i < files.length; i++) {

        let options = {
          onUploadProgress: (progressEvent) => {
              const {loaded, total} = progressEvent;
              let percent = Math.floor( (loaded * 100) / total )
             // console.log( `${loaded}kb of ${total}kb | ${percent}%` );
              if( percent < 100 ){
                  console.log(i,percent);
                  setUploadPercentage(percent);
              }
          }   
          }

       const data = new FormData();
       data.append('file', files[i]);
       data.append('filename', files[i].name);
       data.append('filesize', files[i].size);
       data.append('filetype', files[i].type);
       data.append('folder_path', props.folderpath)
       data.append('user_id', props.myid);
      
       axios.post(urls.uploadProjectMedia, data, options, { 
         headers: headers
       })
       .then(res => {
        setUploadPercentage( 100 );
        setuploadedCount(i+1);
        props.storefiles(props.arrayname, res.data);

        if(files.length == i+1)
        {
         setuploadCount(0);
         setuploadingProgress(false);
         setuploadedCount(0);
        }
       })
     }
   };


    return(
          <div className="attchfiles_wrap">
            <FileUploader 
             maxSize={1024}
            handleChange={handleChangeFile} 
            name="files" 
            types={fileTypes}
            multiple={true}
            chunkUploads ={true}
            chunkSize = {1024 * 1024 * 10} // 10MB
            />
            {(uploadingProgress) &&
            (
              <div className="upload-progress">
                Please wait, uploading in progress ({uploadedCount+"/"+uploadCount})
                <div className='uploading-bar'>
                <Progress striped  color="success" value={uploadPercentage} />
                </div>
              </div>
            )} 
          </div>
    )
}