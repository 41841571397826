import React from 'react';
import '../LoadingBox.css'
import LoadingBox from '../LoadingBoxSmall/LoadingBoxSmall'

export default function LoadingBoxSmall(props) {
  return (
    <div className="LoadingBox LoadingBoxSmall">
      {props.loading ? <LoadingBox /> : props.children}
    </div>
  )
}
