import React, { Component } from 'react';
// import './simplecard.css';
import { Row, Col, Card } from 'reactstrap';


export default class ProjectCompanyCard extends Component {
  constructor( props ) {
    super( props );         
    this.state = {              
      myPrivileges:[],
      showRate:false,    
    };   
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };


  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges });

    if(login.privileges.includes('access_all_reports'))
    {
      this.setState({showPeople:true, showPeopleType:1});
    }else if(login.privileges.includes('access_team_reports'))
    {
      this.setState({showPeople:true, showPeopleType:2});
    }else
    {
      this.setState({showPeople:false, showPeopleType:3});
    }
  };

  render() {
    const { name, time, rate, commentStatus, children } = this.props;
    const classNames = 'simple-card '+ this.props.cardName;
    const commentClass = commentStatus ? 'has-comment' : 'no-comment';
    return (
      <Row>
        <Col sm="12">
          <div className={classNames}>
            <Card className="d-flex">
              <p className="name">{name}</p>
              <p className="time-entered">{time}</p>
              <p className="admin-comment ">{ this.state.myPrivileges.includes('show_admin_comments')? 'Admin Comments': null } </p>
              <p className="total-rate text-right">
              { this.state.myPrivileges.includes('show_rates')? rate: null }            
              </p>              
            </Card>
            {children ? children : ""}
          </div>
        </Col>
      </Row>
    )
  }
}