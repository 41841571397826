import React, { Component, Fragment } from 'react';
import './TaskSingle.css';
import TaskStartIcon from '../../svgicon/TaskStartIcon';
import TaskEndIcon from '../../svgicon/TaskEndIcon';
import TaskInfoIcon from '../../svgicon/TaskInfoIcon';
import PauseIcon from '../../svgicon/PauseIcon';
import Pmtimer from "../Pmtimer";
import BasecampIcon from '../../svgicon/BasecampIcon';
import TrelloIcon from '../../svgicon/TrelloIcon';
import Reassign from "../../../images/reassign.png";
import {Button} from  'reactstrap'
import MaterialIcon from "material-icons-react";
export default class TaskSingle extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      actionTaskId:'',
      taskId: this.props.task_id,
      showExpandView:false,  
      trelloCard:'',   
      taskLabels:[],
      taskName:'',
    };
  }
  
  componentDidMount = () => {
   this.findTrelloId();
   this.showTags();
  };

  findTrelloId = () =>
  {
      
      var cardid = this.props.task_name.match(new RegExp("## " + "(.*)" + ""));
      if(cardid === null)
      {
        var cardid = this.props.task_name.match(new RegExp("##" + "(.*)" + ""));
      }
      if(cardid !=null)
      {
        this.setState({
          trelloCard:cardid[1]
        })
      }
  }

  handleStartClick = () =>
  {    
    this.props.myactions('start', this.props.type, this.props.task_id, this.props.project_id, this.props.task_name);
  }

  handlePauseClick = () =>
  {
    this.props.myactions('pause', this.props.task_id);
  }

  handleRestartClick = () =>
  {
    this.props.myactions('restart', this.props.task_id);
  }

  handleEndClick = () =>
  {
    this.props.myactions('end', this.props.type,this.props.task_id, this.props.project_id, this.props.task_name, this.props.project_name);
    this.setState({showExpandView: false});
  }

  showExpandView = () =>
  {
    this.setState({showExpandView: !this.state.showExpandView})
  };

  showAmendBox = (id, pid, taskname, managerid) =>
  {
    this.props.taskamend(id, pid, taskname, managerid);
  }


  showTrelloCard = () =>
  {
    this.props.showtrello(this.state.trelloCard);
  };

  showTags = () =>
  {
    var matches = [];
    this.props.task_name.replace(/\[(.*?)\]/g, function(g0,g1){matches.push(g1);})
    var title = this.props.task_name.replace(/(\[.*?\]|\(.*?\)) */g, "");
    this.setState({
      taskLabels:matches,
      taskName:title,
    });
  }

  quickClose = () =>
  {
    this.props.quickclose(this.props.task_id, this.props.project_id, this.props.type);
  }

  render() {    
    const classes = 'single-task ' + this.props.classname;    
    return (
      <article className={classes} >
        <div className="single-task-container">
          {/* <div className="box sl-no">
           {this.props.slno}
          </div> */}
          <div className="box task-details" onClick={this.showExpandView.bind(this)}>
            <p className="task-client-detail">
              {this.props.project_name}
            </p>
            <p className="task-project-name">
              {this.props.task_name}
            </p>
            {this.props.manager!=null?
            (
              <p className="task-client-detail task-owner">
                  Task Posted By: {this.props.manager}
              </p>
            )
            :
            null
            }
            
            {/* <p className="task-project-name">
              {this.props.task_id}
            </p> */}
            <p className="task-project-name">
              <div>
              {this.state.taskLabels.map((item, index)=>
              {
                return(
                  <span className={item}>
                    {item === 'V' || item === 'v' ? 'VIP' : (item === 'R' || item === 'r' ? 'Revision': (item === 'U' || item === 'u' ? 'Urgent': (item === 'MR' || item === 'mr' ? 'Minor Revision' : (item === 'RS' || item === 'rs' ? 'Resizing' : item))))}
                  </span>
                )
              })}
              </div>
            </p>
          </div>
          <div className="d-flex taskcard_footer">
          <div className="d-flex">
          {this.props.running_status === '1' ?
            (
            <div className="d-flex box task-btn">
              <div className="box task-start">
                <Button activeclass='inactiveicon'>Start Task</Button>
                {/* <TaskStartIcon activeclass='inactiveicon' title="Start" /> */}
              </div>
              <div className={(this.props.running_status === '2' || this.props.running_status === '1') ? "activeicon box task-end" :'box task-end'}>
                <Button activeclass={(this.props.running_status === '2' || this.props.running_status === '1') ? "activeicon d-flex" :''} onClick={this.handleEndClick}>Stop Task</Button>
                {/* <TaskEndIcon activeclass={(this.props.running_status === '2' || this.props.running_status === '1') ? "activeicon d-flex" :''} title="End" action={this.handleEndClick} /> */}
              </div>
            </div>
            )
            :
            (
              <div className="d-flex task-btn box">
                <div className="box task-start" >
                  <Button activeclass='inactiveicon' onClick={this.handleStartClick}   >Start Task</Button>
                  {/* <TaskStartIcon activeclass='inactiveicon ' title="Start"  action={this.handleStartClick}  /> */}
                </div>
                <div className="box task-end">
                  <Button activeclass='inactiveicon' >Stop Task</Button> 
                  {/* <TaskEndIcon activeclass='inactiveicon' title="End" /> */}
                </div>
              </div>
            )
          }  
           <>
              {(this.props.starttimer && this.props.running_status === '1')?
              (
                <Pmtimer starttimer={this.props.starttimer} />
              )
              :
              (<div className="box task-total-time">
                {this.props.running_status !== '1' || this.props.running_status !== '2' ? this.props.totaltoday : null }
              </div>)
              }
          </>
        </div>
        
          <div className="icon_actions">
          {this.props.running_status !== '1'?
          (
            <>
           
            <div className="box task-shot-info" onClick={this.showAmendBox.bind(this, this.props.task_id, this.props.project_id, this.props.task_name, this.props.mtask.manager_id)}>
              {this.props.project_id !=21196075 && 
              (
                <>
                <Button className="reassign_btn"><MaterialIcon icon="reply"/></Button>
                {/* <img src={Reassign} className="reassign-my-task" /> */}
                </>
              )}
            </div>
            {(this.props.allowclose && this.props.project_id !=21196075) ?
            (
              <div className="quick-close-task" onClick={this.quickClose.bind(this)}>
                <MaterialIcon icon="done"/>
              </div>
            )
            :
            (<div className="empty-icon">&nbsp;</div>)
            }
            </>
          )
          :
          (
            <>
              <div className="box task-shot-info" ></div>
              <div className="empty-icon">&nbsp;</div>
            </>
          )
          }
          
          <BasecampIcon active={this.props.running_status === '1'? true:false} projectid={this.props.project_id} taskid={this.props.task_id} /> 
          {this.state.trelloCard!=''?
            (
              <TrelloIcon showtrellocard={this.showTrelloCard} />
            )
            :
            (<div className="empty-icon">&nbsp;</div>)
          }
        </div>
        
</div>


        </div>
        {/* {
          (this.props.running_status === '1' || this.props.running_status === '2' || this.state.showExpandView) &&
          (
            <div className="task-timing d-flex">
              <p className="today time">Today Time: {this.props.totaltoday}</p>
              <p className="total time text-center">Total Time:  {this.props.mytotal}</p>
              <p className="overall time text-right">Overall Time:  {this.props.overalltime}</p>
            </div>
          )
        } */}
      </article>
    )
  }
}