import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import axios from 'axios';
import { urls } from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { motion } from 'framer-motion'

export default class NonBrandedBoards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            myPrivileges: [],
            myId: '',
            myPrivileges: [],
            allBoards: [],
            progressBar:true
        };
    }

    componentDidMount = () => {
        document.title = "HUB - Non Branded Boards";
        this.checkUserPrivilege();
        this.getBoards();
    };

    signOut = () => {
        fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
                localStorage.clear();
                this.props.history.push("/");
            })
            .catch(error => {
                localStorage.clear();
                this.props.history.push("/");
                fetch(urls.logmeout)
                    .then(response => response.json())
                    .then(response => {
                        localStorage.clear();
                        this.props.history.push("/");
                    })
                    .catch(error => {
                        localStorage.clear();
                        this.props.history.push("/");
                    });

            });
    };

    checkUserPrivilege = () => {
        if (localStorage.getItem("user") !== null) {
            var login = JSON.parse(localStorage.getItem("user"));
            this.setState({ myPrivileges: login.privileges, userTeam: login.user_team, myId: login.uid, myBaseId: login.basecampid });
        }
    };

    getBoards = () =>
    {
        const data = {};
        const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
        }
        axios.post(urls.nonBrandedBoards, data, {
            headers: headers
        })
        .then((response) => {
            this.setState({
                allBoards: response.data.boards,
                progressBar:false
            })
        })
        .catch((error) => {
            this.setState({progressBar:false})
        })
    }

    toggleSidebar = () => {
        this.setState({ showMenu: !this.state.showMenu });
    }

    hideMenu = () => {
        this.setState({ showMenu: !this.state.showMenu });
    }

    render() {
        const variants = {
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
        }
        return (
            <Container fluid className={(this.state.myPrivileges.includes('i_am_designer') ? 'top-bar-active' : 'top-bar-inactive') + " log-timer trello"} >
                <Row>
                    <div className="theme_header">
                        <Header {...this.props} classname="tick-timer-header"
                            logout={this.signOut.bind(this)}
                            onmenuaction={this.toggleSidebar.bind(this)}
                            quickactionblock={true} />
                    </div>
                    <div className="SideNavbar">
                        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
                    </div>
                    <div className="themeContentBody active_trello">
                        <Row>
                            <Col sm={12} lg={12}>
                                <Card className="cl_helth_report">
                                    <h1 class="page-title text-center her_title">Non Branded Boards</h1>
                                    <motion.div
                                        initial="hidden"
                                        animate="visible"
                                        variants={variants}>
                                        <LoadingWrapper isLoading={this.state.progressBar}>
                                            {this.state.allBoards && this.state.allBoards.length>0 &&
                                            (
                                                <div className='non-branded-boards'>
                                                    <Row>
                                                        {this.state.allBoards.map((board, index)=>
                                                        {
                                                            return(
                                                                <Col sm="6" className='non-branded-items'>
                                                                    <a target="_blank" href={"/boardsdetails/"+board.bid_cpt} className="d-flex justify-content-between">
                                                                        {board.board_name}
                                                                        {board.client_status && board.client_status!='' &&
                                                                        (
                                                                        <>
                                                                        {board.client_status == '1' ? 
                                                                            <span className='client_status trial'>Trial</span> : (board.client_status == '2' ? <span className='client_status active'>Active</span> :
                                                                            (board.client_status == '3' ? <span className='client_status hold'>Hold</span> :
                                                                            (board.client_status == '4' ? <span className='client_status paused'>Paused</span> : <span className='client_status cancelled'>Cancelled</span>
                                                                            ))) 
                                                                        }
                                                                        </>
                                                                        )}
                                                                    </a>
                                                                </Col>
                                                            )
                                                        })}
                                                    </Row>
                                                </div>
                                            )}
                                        </LoadingWrapper>
                                    </motion.div>

                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Row>
            </Container>
        )
    }
}