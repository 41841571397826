import React, { Component, Fragment } from 'react';
import Header from '../../header/Header';
import '../list-head.css';
import '../listcard.css';
import '../LogTimer.css'
import "../employeeleavebalance.css"
import DataList from '../../datalist/DataList';
import {urls} from "../../../services/UrlList";
import LoadingBar from '../../common/LoadingBar';
import moment from "moment";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper"
import LoadingBox from '../../LoadingBox/LoadingBox'
import {motion} from 'framer-motion'
import '../SwitchUser.css'
import UserPickerId from "../EmoployeeLeaveBalance/UserPickerId";
import YearPicker from "../EmoployeeLeaveBalance/YearPicker";
import LeaveStatusCard from "../EmoployeeLeaveBalance/LeaveStatus/LeaveStatusCard/LeaveStatusCard";
import mining from "../../../images/mining2.gif";
import { Scrollbars } from 'react-custom-scrollbars';

export default class EmployeeLeaveBalanceDetails extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      myPrivileges:[],
      showMenu:true,  
      allEmployees:[], 
      allOriginalEmployess:[],  
      initialLoader:true,
      issueLoader:false,
      sYear: new Date().getFullYear(),
      selectedId: this.props.match.params.id,
      myLeaves:[],
      leaveIssues:[],
      checkIssue:true,
      checkSpan:0,
      checkHide:false,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Employee Leave Balance";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
        _this.getMyLeaves(_this.props.match.params.id); 
      }, 100);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({selectedId: this.props.match.params.id});
    if(!login.privileges.includes('am_admin') && !login.privileges.includes('leave_book_access'))
    {
      this.props.history.push("/home");
    }
    if(localStorage.getItem("user") !== null)
    {
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getMyLeaves = (id) => { 
    this.setState({
     initialLoader:true
    });
    let opts={
      leave_year:this.state.sYear,
      user_id: id
    }
    fetch(urls.getMyLeaves, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>{
      console.log(response[0].my_leaves);
      this.getAllEmpLeavesSingle();
      this.setState({
        myLeaves:response[0].my_leaves, 
        initialLoader:false
      });
    })
    .catch(err => 
    { 
      this.getAllEmpLeavesSingle();
      this.setState({
        myLeaves:[],
        initialLoader:false
      });
    })
  };


  getAllEmpLeavesSingle = () =>{  
    let opts={
      leave_year:this.state.sYear,
      uid: this.state.selectedId
    }
    fetch(urls.getAllEmpLeavesSingle, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response => {      
      
      if (response && response.length > 0) {        
        this.setState({
          allEmployees: response[0].leaves,  
          allOriginalEmployess:response[0].leaves,    
        });       
      } 
    })
    .catch(error=>
    {
      this.setState({
        showProgress:false      
      });  
    })
  };
  
  onPeopleChange = (value)=>
  {
    let temp=[];
    let list=[];
    if(value !='')
      {
        this.setState({
          selectedId:value,
          leaveIssues:[],
          checkIssue:true,
          checkSpan:0,
          checkHide:false,
        })
        this.getMyLeaves(value); 
      }
  }

  onYearChange = (year) =>
  {
    this.setState({
      sYear:year
    });
    if(year !='')
    {
      const _this= this;
      setTimeout(() => {
          _this.getMyLeaves(_this.state.selectedId);
        }, 500);
       
    }
  }

  getMyLeaveIssues = () => { 
    // this.setState({progressBar:true});
    let opts ={
      userid: this.state.selectedId,
      issuespan: this.state.checkSpan + 90
    };
    this.setState({
      issueLoader:true,
      checkHide:true,
      checkIssue:false,
      checkSpan: this.state.checkSpan + 90
    });
    fetch(urls.getEmployeeLeaveIssues, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body: JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>{
      this.setState({
        leaveIssues:this.state.leaveIssues.concat(response), 
        issueLoader:false,
        checkHide:false
      });
    })
    .catch(err => 
    { 
      this.setState({
        leaveIssues:[],
        issueLoader:false,
        checkIssue:false,
        checkHide:false
      });
    })
  };

  onLeaveWithdraw = (leave)=> {
    let opts ={
      leave:leave,
      user_id: this.state.myId,
      superadmin: this.state.myId
    };
    fetch(urls.withdrawLeave, {
      headers: {
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response =>response.json())
    .then(response =>{
      this.setState({
        logMsg: response[0]['msg'],
        errorMsg:response[0]['status'] === 2? true : false,
      });
      this.getMyLeaves(this.state.selectedId);
    })
    .catch(error =>{
      this.setState({progressBar:false});
      this.getMyLeaves(this.state.selectedId);
    })
  };

  directBacktoBook = () =>
  {
    this.props.history.push("/admin/employee-leave-balance?uuid=1");
  }
  render() {

    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className="leave-balance-details top-bar-inactive">
         <Row>
        <div className="theme_header">
        <Header {...this.props}   classname="tick-timer-header"  logout={this.signOut.bind(this)} onmenuaction={this.toggleSidebar.bind(this)} />
        </div>
        <div className="SideNavbar">
            <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody themefullwidthContentBoday">
        <Row>
          <Col xs={12} md={12}>
            <Card className="cl_helth_report">
            <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
              <h1 class="page-title text-center her_title">Employee Leave Details</h1>
              
              <div className="filter-box Project_Work_Log eld_header_wrap" id="filter">
                <Row> 
                  <Col sm="12" lg="3">
                    <div className="people">
                    <p class="filter-label">User </p>
                    <UserPickerId onPeopleChange={this.onPeopleChange} />
                    </div>
                  </Col>
                  <Col sm="12" lg="3">
                    <p class="filter-label">Year </p>
                    <YearPicker  filteryear={this.state.sYear} onyearchange={this.onYearChange}/>
                  </Col> 
                  <Col sm="12" lg="6" className='back-leavebook'>
                    <button class="btn-green btn btn-secondary show_btn btn btn-secondary"
                    onClick={this.directBacktoBook.bind(this)}>Back </button>
                  </Col> 
                </Row>
              </div>
              
              <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th title="Leave Profile">Profile</th>
                      <th title="Team Holiday Country">Country</th>
                      <th title="Earned Leave">EL</th>
                      <th title="Casual Leave">CL</th>
                      <th title="Sick Leave">SL</th>
                      <th title="Personal Leave">PL</th>
                      <th title="Bereavement Leave">BL</th>
                      <th title="Compensatory off">COF</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.allEmployees.length>0?
                    (
                        <>
                          {this.state.allEmployees.map((item, index) =>
                          {
                            return(
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>{item.employee_type}</td>
                      <td>{item.country_name}</td>
                      <td>
                      {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                          (
                            <>
                              {parseFloat(item.earned_leave)} / {parseFloat(item.aloc_earned_leave)}
                            </>
                          ):
                          (
                            "-"
                          )
                        }
                      </td>
                      <td>
                      {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                          (
                            <>
                              {parseFloat(item.casual_leave)} / {parseFloat(item.aloc_casual_leave)}
                            </>
                          ):
                          (
                            "-"
                          )
                        }
                      </td>
                      <td>
                      {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                          (
                            <>
                              {parseFloat(item.sick_leave)} / {parseFloat(item.aloc_sick_leave)}
                            </>
                          ):
                          (
                            "-"
                          )
                        }
                      </td>
                      <td>
                      {item.employee_type === 'Doto Studio' || item.employee_type === 'Kimp ex doto' ?
                            (
                                "-"
                            ):
                            (
                              <>
                              {parseFloat(item.general_leave)} / {parseFloat(item.general_aloc)}
                              </>
                            )
                          }
                      </td>
                      <td>
                      {parseFloat(item.bereavement_leave)} / {parseFloat(item.aloc_bereavement_leave)}
                      </td>
                      <td className="text-right"> 
                      {parseFloat(item.compensatory_off)} / {parseFloat(item.aloc_compensatory_off)}
                      </td>
                    </tr>

)
})}
</>
):
null
}
                  </tbody>
              </Table>

              <LoadingWrapper isLoading={this.state.initialLoader}></LoadingWrapper>
            </motion.div>
            </Card>
          </Col>
          <Col xs={12} sm={(this.state.myPrivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) ? 8 : 12}>
           <Card className="cl_helth_report">
              <h1 class="page-title text-center her_title">Leave History</h1>
              <LoadingWrapper isLoading={this.state.initialLoader}>
                    <LoadingWrapper isLoading={ this.state.progressBar }>
                      {this.state.myLeaves.length>0 ? 
                      (<>
                          {this.state.myLeaves.map((leave, index) =>
                          {
                            return(
                              <LeaveStatusCard myprivileges={this.state.myPrivileges}  key={index} leave={leave} myid={this.state.myId} withdrawaction={this.onLeaveWithdraw} />
                            )
                          })}
                      </>)
                      :
                      (
                        <Card className="leave-status-card d-flex">
                          <p>No leaves posted</p>
                        </Card>
                      )
                      }
                      </LoadingWrapper> 
                    </LoadingWrapper>
           </Card>
          </Col>
          {(this.state.myPrivileges.includes('am_admin') || localStorage.getItem("superadmin") !== null) &&
          (
            <Col xs={12} sm={4}>
              <Card className="cl_helth_report leave_issue_wrap">
                  <div className="day d-flex justify-content-between">
                    <h6>Possible Leave Issues</h6>
                                {this.state.checkIssue &&
                                (
                                  <Button className="btn-green btn btn-secondary show_btn" onClick={this.getMyLeaveIssues.bind(this)}>Show</Button>
                                )
                                }
                              </div>
                              {this.state.issueLoader?
                          (
                            (<img className="" src={mining}  />)
                          )
                          :
                          (
                            <>
                            <Scrollbars style={{ height: '250px' }} className="xhide" >
                                {this.state.leaveIssues.map((item, index)=>
                                {
                                  return(
                                    
                                      <Card className="leave-status-card d-flex issue-card" key={index}>
                                        <div>
                                          {item.date}
                                        </div>
                                        <div>
                                          {item.intime === 0?
                                          (
                                            <span className="absent">Absent</span>
                                          ):
                                          (
                                            '< 6 hours' 
                                          )
                                          }
                                        </div>
                                      </Card>
                                  
                                  )
                                })}
                                </Scrollbars>
                            </>
                          ) 
                          }
                            
                        
                        {!this.state.checkHide &&
                        (
                          <div className="d-flex text-center">
                            
                          </div>
                        )}

              </Card>
            </Col>
          )}
        </Row>
        </div>
      </Row>
        
      </Container>
      </div>
    )
  }
}
