import React, {useState} from 'react';
import toast, { Toaster } from 'react-hot-toast';
export default function MailboxEmail(props)
{
    const [isCopied, setIsCopied] = useState(false);
  
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
        console.log("jhello");
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(props.canva_access)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
          toast("Email address copied to clipboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return(
        <h6 onClick={handleCopyClick} className="d-flex justify-content-center board_email_address">{props.canva_access}</h6>
    )
}