import React, { useState } from "react";
import ReactDOM from "react-dom";
import ProfilePhoto from "./ProfilePhoto";
import "../Profile.css";
import CreateAvatar from "./CreateAvatar";
import Portal from "./Portal";
import { urls } from "../../../services/UrlList";
import axios from 'axios';

const Profilepichooser = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState(props.imgsrc);

  const getData = (isOpened, imageSrc) => {
    setIsOpen(isOpened);
    setImageSrc(imageSrc);
    console.log(imageSrc);
    let opts = {
      file: imageSrc,
      user_id: props.myid
    };
     axios.post(urls.updateProfilePic, opts, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'content-type': 'application/json'
      } }).then((res) => {
        console.log(res);
        if(res.data.status === 1)
        {
          //let rnum = Math.floor(Math.random() * 160);
          // let rnum = Date.now();
          // _this.setState({
          //   avatarImg: urls.storageUrl+"storage/profile/image/"+_this.state.myId+".jpg?"+rnum,
          // })
          // _this.setState({uploadprogress:false,fileTypeError:false});
          window.location.reload();
        }else
        {
          //this.setState({fileTypeError:true});
        }
        }).catch((e)=>{
          //_this.setState({uploadprogress:false});
      })

  };

  return (
    <React.Fragment>
      <ProfilePhoto getData={getData} imageSrc={imageSrc} />
      <div id="createAvatarDiv" />
      {isOpen && !imageSrc && (
        <Portal portalDiv="createAvatarDiv">
          <div className="createAvatarDiv_content m-auto">
            <CreateAvatar getData={getData} />
          </div>
        </Portal>
      )}
      <br />
    </React.Fragment>  
  );
};

export default Profilepichooser;
