import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { urls } from "../../../services/UrlList";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { saveAs } from "file-saver";
import Hashids from 'hashids';

const notifyPublishSuccess = () => toast('Version published successfully.');
const notifyUnpublishSuccess = () => toast('Version unpublished successfully.');
const notifyFinalSuccess = () => toast('Version successfully marked as final design files.');
const notifyError = () => toast('Something went wrong, please try again.');
const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );


// fetchings
const addRevsionFolder = async(taskid) => {
    return await axios.post(urls.addSourceTaskRevsions, {'taskid': taskid});
}

const getRevsionFolder = async(taskid) => {
    return await axios.post(urls.getAllTaskRevisions, {'taskid': taskid});
}

const getRevsionSubFolders =  async(revisionID,parentfolder)=> {
    return await axios.post(urls.getRevsionSubFolders, {'revsionid': revisionID, 'parentfolder': parentfolder});
}


const getRevsionSubFolderMaster = async(revisionID, taskID) =>
{
    return await axios.post(urls.getFolderMaster,{'revisionid': revisionID, 'taskid': taskID});
}

const addRevisonSubFolder = async(data)=>{
    return await axios.post(urls.createRevisionFolder, data);
}

const addOtherFolder = async(data)=>{
    return await axios.post(urls.createRevisionOtherFolder, data);
}

const getRevsionFiles = async(revisionID, taskid, folderid)=>{
    return await axios.post(urls.getTaskSourceFiles, {'revisionid': revisionID, 'taskid': taskid, 'folderid':folderid});
}

const removeSourceFile = async(data2)=>{
    return await axios.post(urls.removeSourceFileData, data2);
}


const getBreadData =  async(revisionID,parentfolder)=> {
    return await axios.post(urls.getSourceRevsionBread, {'revisionid': revisionID, 'parentid': parentfolder});
}

const removeSourceRevsionandAssets = async(data) =>{
    return await axios.post(urls.deleteSourceRevsion,data);
}

const removeSourceVersionFolders = async(data) =>{
    return await axios.post(urls.deleteSourceVersionFolder,data);
}

const publishUnplishSource = async(data)=>{
    return await axios.post(urls.publishUnpublishtaskSourceRevsion, data);
}

const getRevisionShareLink = async(taskid, revisionID)=>
{
    return await axios.post(urls.getRevisionShareLink, {'taskid':taskid,'revsionid': revisionID});
}

const revsionShareRegenerate = async(data) =>
{
    return await axios.post(urls.generateRevisionShareLink, data);
}


const getFolderShareLink = async(folderid, revisionID)=>
{
    return await axios.post(urls.getFolderShareLink, {'folderid':folderid,'revisionid': revisionID});
}

const folderShareRegenerate = async(data) =>
{
    return await axios.post(urls.generateFolderShareLink, data);
}

const getRevsionSubFoldersView = async(revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType)=>{
    return await axios.post(urls.customerSharedRevision,
    {
        'revisionid': revisionID,
        'taskid': taskId,
        'ukey': uniqueFolderKey,
        'uvalid': keyExpiry,
        'parentfolder': parentFolder,
        'masterfolder': masterFolder,
        'viewtype': viewType
    })
}

const getRevsionFilesView = async(revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType)=>{
    return await axios.post(urls.customerSharedRevisionFiles,
    {
        'revisionid': revisionID,
        'taskid': taskId,
        'ukey': uniqueFolderKey,
        'uvalid': keyExpiry,
        'parentfolder': parentFolder,
        'masterfolder': masterFolder,
        'viewtype': viewType
    })
}

const downloasAsZip = async(data)=>{
    return await axios.post(urls.sourceDownloadAsZip, data);
}

const getRevsionFolderStructure = async(revisionID, taskID) =>
{
    return await axios.post(urls.revisonFolderList,{'revisionid': revisionID, 'taskid': taskID});

}


const moveRevsionFolder = async(data)=>{
    return await axios.post(urls.revisonFolderMove,data);
}

const fileApprovalRequest = async(data) =>
{
    return await axios.post(urls.approveUnapproveFiles, data);
}

const fileApprovalRequestAll = async(data) =>
{
    return await axios.post(urls.approveUnapproveAllFiles, data);
}


const fileRejectRequest = async(data) =>
{
    return await axios.post(urls.rejectUnrejectFiles, data);
}



const getRevisionApproved = async(taskId) =>
{
    return await axios.post(urls.approvedSourceFiles, {'taskid': taskId});
}


const cloneRevisionFolder = async(data)=>{
    return await axios.post(urls.revisionandcopy, data);
}

const getFindShift = async()=>
{
    return await axios.get(urls.findmyactiveshift);
}

const getFetchemails = async(emailid) =>
{
    return await axios.post(urls.getCanvaToken, {'emailid': emailid});
}

const getFetchMailboxEmailsList = async(projectid) =>
{
    return await axios.post(urls.fetchmailboxemails, {'projectid': projectid});
}


//hooks
export const useAddRevisonFolder = (taskid) => {
    const queryClient = useQueryClient();
    return useQuery(['new-revision-folder'], () =>  addRevsionFolder(taskid),
    {
        enabled:false,
        refetchOnMount:false,
        refetchOnWindowFocus:false,
        onSuccess: (taskid) => {
            queryClient.invalidateQueries(['task-revisions']);
        }
    })
}

export const useGetRevsionFolders = (taskid) => {
    return useQuery(['task-revisions'], () => getRevsionFolder(taskid),
    {
        staleTime: 120000
    })
}

export const useGetRevsionSubFolders = (revsionID, parentfolder) => {
    return useQuery(['revision-subfolders', revsionID], () =>  getRevsionSubFolders(revsionID, parentfolder),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
    })
}


export const useGetBreadData = (revsionID, parentfolder) => {
    return useQuery(['revision-breaddata', parentfolder], () =>  getBreadData(revsionID, parentfolder),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
    })
}


export const useGetRevsionSubFolderMaster = (revsionID, taskID) => {
    return useQuery(['revision-subfolder-master'], () =>  getRevsionSubFolderMaster(revsionID, taskID),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
        refetchOnMount:true
    })
}




export const useAddRevisonSubFolder = () => {
    const queryClient = useQueryClient();
    return useMutation(addRevisonSubFolder, {
        onSuccess: (data) => {
            queryClient.invalidateQueries(['revision-subfolders']);
        }
    });
}

export const useAddOtherFolder = () => {
    const queryClient = useQueryClient();
    return useMutation(addOtherFolder, {
        onSuccess: (response) => {
            console.log(response.data.revisionID)
            queryClient.invalidateQueries(['revision-subfolder-master']);
        }
    });
}


export const useGetRevsionFiles = (revisionID, taskid, folderid) => {
    return useQuery(['revision-folder-files', revisionID], () =>  getRevsionFiles(revisionID, taskid, folderid),
    {
        refetchOnMount:false,
        refetchOnWindowFocus:false,
    })
}


export const useRemoveSourceFile = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(removeSourceFile, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['revision-folder-files', response.data.revisionID]);
        }
    });
}


export const useRemoveSourceRevsionandAssets = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(removeSourceRevsionandAssets, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['task-revisions']);
        }
    });
}

export const useRemoveSourceVersionFolders = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(removeSourceVersionFolders, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['revision-subfolders']);
        }
    });
}

export const usePublishUnpublishSource = (data)=>{
    const queryClient = useQueryClient();
    return useMutation(publishUnplishSource,{
        onSuccess:(response)=>{
            queryClient.invalidateQueries(['task-revision-approved', response.data.taskId]);
            if(response.data.success==1 && response.data.action==1)
            {
                notifyPublishSuccess();
                const actualId = hashids.decode(response.data.cardId);
                axios.post(`${urls.domain360}api/v1/task/${actualId[0]}`, {'is_source_published': 1});
            }
            else  if(response.data.success==1 && response.data.action==0)
            {
                notifyUnpublishSuccess();
                //const actualId = hashids.decode(response.data.cardId);
                //axios.post(`https://kstage.kimp.kim/api/v1/task/${actualId[0]}`, {'is_source_published': 0});
            }
            else  if(response.data.success==1 && response.data.action==3)
                notifyFinalSuccess();
            else 
                notifyError();
        }
    })
}

export const useGetRevisionShareLink = (taskid, revisionID) => {
    return useQuery(['revision-share-link', revisionID], () =>  getRevisionShareLink(taskid, revisionID),
    {
        staleTime: 60000,
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useRevsionShareRegenerate = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(revsionShareRegenerate, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['revision-share-link', response.data.sharedata.revisionid]);
        }
    });
}

export const useGetFolderShareLink = (folderid, revisionID) => {
    return useQuery(['folder-share-link', folderid], () =>  getFolderShareLink(folderid, revisionID),
    {
        staleTime: 60000,
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useFolderShareRegenerate = (data2) => {
    const queryClient = useQueryClient();
    return useMutation(folderShareRegenerate, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['folder-share-link', response.data.sharedata.folderid]);
        }
    });
}

export const useGetRevsionSubFoldersView = (revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType)=>{
    return useQuery(['revision-subfolders-view', revisionID], () =>  getRevsionSubFoldersView(revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType),
    {
        staleTime: 60000,
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useGetRevsionFilesView = (revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType)=>{
    return useQuery(['revision-files-view', revisionID], () =>  getRevsionFilesView(revisionID,taskId, parentFolder,uniqueFolderKey,keyExpiry, masterFolder, viewType),
    {
        staleTime: 60000,
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useDownloadasZip=(data)=>{
    return useMutation(downloasAsZip, {
        enabled:false,
        onSuccess: (response) => {
           // saveAs(urls.storageUrl+"storage/source/".response.data.filename, response.data.filename);
           saveAs(urls.storageUrl+"storage/projects/downloads/"+response.data.filename,response.data.filename);
        },
        onError: () => {
            toast('Something went wrong, please try again.');
        }
    }); 
}

export const useGetRevsionFolderStructure = (revisionID, taskID) => {
    return useQuery(['folder-structure', revisionID], () =>  getRevsionFolderStructure(revisionID, taskID),
    {
        staleTime: 60000,
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useMoveRevsionFolder = (data) => {
    const queryClient = useQueryClient();
    return useMutation(moveRevsionFolder, {
        onSuccess: (response) => {
            //console.log(response.data.revisionid);
           // queryClient.invalidateQueries(['revision-subfolders-view', response.data.revisionid]);
           // queryClient.invalidateQueries(['revision-folder-files', response.data.revisionid]);
            
        }
    });
}

export const useFileApprovalRequest = (data) => {
    const queryClient = useQueryClient();
    return useMutation(fileApprovalRequest, {
        onSuccess: (response) => {
            if(response.data.success == '1')
            {
                toast('File successfully marked as approved.');
            }else if(response.data.success == '0')
            {
                toast('File successfully marked as unapproved.');
            }else if(response.data.success == '8')
            {
                toast('something went wrong please try again.');
            }
            queryClient.invalidateQueries(['revision-folder-files', response.data.revisionID]);
            queryClient.invalidateQueries(['task-revision-approved', response.data.taskID]);
        }
    });
}


export const useFileApprovalRequestAll = (data) => {
    const queryClient = useQueryClient();
    return useMutation(fileApprovalRequestAll, {
        onSuccess: (response) => {
            if(response.data.success == '1')
            {
                toast('Files successfully marked as approved.');
            }else if(response.data.success == '0')
            {
                toast('Files successfully marked as unapproved.');
            }else if(response.data.success == '8')
            {
                toast('something went wrong please try again.');
            }
            queryClient.invalidateQueries(['revision-folder-files', response.data.revisionID]);
            queryClient.invalidateQueries(['task-revision-approved', response.data.taskID]);
        }
    });
}


export const useFileRejectRequest = (data) => {
    const queryClient = useQueryClient();
    return useMutation(fileRejectRequest, {
        onSuccess: (response) => {
            if(response.data.success == '1')
            {
                toast('File successfully marked as rejected.');
            }else if(response.data.success == '0')
            {
                toast('File successfully marked as unrejected.');
            }else if(response.data.success == '8')
            {
                toast('something went wrong please try again.');
            }
            queryClient.invalidateQueries(['revision-folder-files', response.data.revisionID]);
            queryClient.invalidateQueries(['task-revision-approved', response.data.taskID]);
        }
    });
}





export const useGetRevsionApproved = (taskid) => {
    return useQuery(['task-revision-approved', taskid], () => getRevisionApproved(taskid),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useCloneRevisionFolder = () => {
    const queryClient = useQueryClient();
    return useMutation(cloneRevisionFolder, {
        onSuccess: (response) => {
            queryClient.invalidateQueries(['task-revisions']);
        }
    });
}

export const useFindShift = () => {
    return useQuery(['user-active-shift'], () => getFindShift(),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useFetchEmails = (taskid, emailid) => {
    return useQuery(['canvas-access', taskid], () => getFetchemails(emailid),
    {
        refetchOnMount:true,
        refetchOnWindowFocus:false,
    })
}

export const useFetchMailboxEmailsList = (taskid, projectid) => {
    return useQuery(['emaillist', taskid], () => getFetchMailboxEmailsList(projectid),
    {
       
    })
}