import React, { Component } from 'react'

export default class MainContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sideMenu: false
    }
  }

  toggleSidebar = () => {
    this.state({sideMenu: !this.state.sideMenu});
    return;
  }

  render() {
    return (
      <main>
        {this.props.children}
      </main>
    )
  }
}
