import React, { Component, Fragment } from "react";
import "./ShiftChange.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import ShiftChangeCard from "./ShiftChangeCard/ShiftChangeCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";

export default class ShiftChange extends Component {
  render() {
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Fragment>
        <ApprovalModules classname="appr-pending" title="Shift Change">
          <Slider {...settings}>
            <ShiftChangeCard approved="true" />
            <ShiftChangeCard />
            <ShiftChangeCard approved="true" />
            <ShiftChangeCard />
          </Slider>
        </ApprovalModules>
      </Fragment>
    );
  }
}
