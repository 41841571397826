import React, { Component } from 'react'
import {Card} from 'reactstrap'
import FeedComments from '../FeedComments/FeedComments'

export default class FeedWish extends Component {
  constructor(props) {
    super(props);
    this.state={
      hideWishCom: true,
    }
  }
  render() {  
  const {feedData, keyIndex, showComment} = this.props;
    return (
      <section id="feed_single">
        <Card 
          className="feed-item feed-item-wish d-flex d-flex-col"
          key={keyIndex}
          >
          <div className="content"
            style={{
              background: "url(https://Kimphub.com/service/" + feedData.feed_image + ")",
              backgroundPosition: 'top center',
              backgroundSize: 'cover'
            }} >
            <p className="text-center feed-post-date mb-auto">{feedData.feed_posted_on}</p>
            <h3 className="text-center text-caps">{feedData.feed_heading}</h3>
            <h2 className="text-center">{feedData.feed_content}</h2>
          </div>
          <FeedComments 
            isLike={feedData.like_count}
            likeCount={feedData.like_count}
            likeAction={this.props.likeAction}
            commentCount={feedData.comments.length}
            commentAction={ this.props.updateComment }
            commentValue={this.props.commentValue}
            feedId={feedData.id}
            postFeedComment={this.props.postFeedComment}
            allCommentsLengh={feedData.comments.length}
            allComments={feedData.comments}
            catId={feedData.category_id} 
            fName="wishComment" />
        </Card>
      </section>
    )
  }
}
