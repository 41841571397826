import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import GreenBell from "../svgicon/GreenBell";
import RedBell from "../svgicon/RedBell";
import RefreshIcon from "../svgicon/RefreshIcon";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import BasecampIconImg from '../svgicon/BasecampIconImg';
import TrelloIcon from '../svgicon/TrelloIcon';

export default class TrelloBoards extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      allBoards:[],
      allBoardLastupdate:'',
      firstHalf:[],
      secondHalf:[],
      orginalUsers: [],
      singleCollection:[],
      showSingle:false,
      initialLoader:true,
      activeUserId:'',
      boardUrl:'',
      actionStatus:false,
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Trello Active Boards";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {  
      if(_this.state.myPrivileges.includes('i_am_pl'))
      {
          _this.getAllCollections();
          _this.getAllBoards();
      }else
      {
        _this.getMyTrelloCollection();
      }
    }, 200);
  };

  componentDidUpdate(prevState) {  
    if (prevState.actionStatus !== this.state.actionStatus  && this.state.actionStatus === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({actionStatus:false})
      }, 6000);  
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }

  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  getMyTrelloCollection = () =>{        
    fetch(urls.getMyTrelloCollection+this.state.myId,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {  
        if(response[0].collection.length>0) 
        {
          console.log(response);
          this.setState({
            selectedUser: response[0].collection[0].trello_name
          }); 
          const _this = this;
          setTimeout(function() {     
            _this.getSingleBoard();
          }, 200);
        }else
        {
          this.getAllCollections();
          this.getAllBoards();
        }  

    })
    .catch(error =>
      {
        this.getAllCollections();
        this.getAllBoards();
      })
  };

  getAllBoards = () =>{        
    this.setState({initialLoader:true});
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getAllTrelloBoardsQa;
    }else
    {
      var furl = urls.getAllTrelloBoards;
    }
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.splitArray(response[0].members); 
        this.setState({
            allBoards: response[0].members,  
            allBoardLastupdate: response[0].last_sync,
            initialLoader:false  
        });            
    })
  };

  splitArray = (allboards)=>
  {
    const list = allboards;
    const middleIndex = Math.ceil(list.length / 2);

    this.setState({firstHalf: list.splice(0, middleIndex), secondHalf:list.splice(-middleIndex)});
  
  }

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getSingleBoard = () =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      datte: 7
    } 
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleQa;
    }else
    {
      var furl = urls.getTrelloSingle;
    }

    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        console.log(response);
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          activeUserId: response[0].member_uid,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  showSingleboard = (member) =>
  {
    this.setState({
      selectedUser: member
    }); 
    const _this = this;
    setTimeout(function() {     
      _this.getSingleBoard();
    }, 200);
  }

  getSingleBoardBell = (board_name) =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      board_name: board_name,
      datte: 7
    } 

    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleBellQa;
    }else
    {
      var furl = urls.getTrelloSingleBell;
    }
    
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  sortBoards(order, response) {
		let temp = response;
		  if (order === "asc") {
			temp.sort(ascOrder);
			return temp;
		  } else if (order === "desc") {
      temp.sort(descOrder);
      return temp;
	  }
	
	
		function ascOrder(a, b) {
      if(a.bell_active=== 1)
			return new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.last_activity).getTime() - new Date(a.last_activity).getTime();
		}
	}

  fetchBaseandRedirect = (board_name) =>
  {
    const boarddata = board_name.split("Kimp");
    let opts ={    
      board_name: boarddata[0]
    } 
    fetch(urls.findBoardByName,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if(response.bid_cpt !=='')
      {
        var burl = urls.notificationUrl+"/task-list/"+response.bid_cpt+"?uuid="+this.state.myId;
        window.open(burl, '_blank').focus();
      }
    })
  }


  addRemoveTrelloWatch = (id) =>
  {
    let opts ={    
      boardurl: this.state.boardUrl,
      stype: id,
      user_id: this.state.myId
    } 
    console.log(opts);
    fetch(urls.addRemoveTrelloWatch,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      
      if(response[0]['success']==1 || response[0]['success']==2)
      {
        this.setState({
          boardUrl:'',
          actionStatus:true
        })
      }
    })
  }

  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  showAllTrello = ()=>
  {
    this.setState({showSingle:false});
  }
  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
    <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
      <div className="themeContentBody active_trello">
      <Row>
          <Col sm={12} lg={12}>
            <Card className="cl_helth_report">
               <h1 class="page-title text-center her_title">
               {this.state.myPrivileges.includes('i_am_qa') ?
                  (
                    'Active Trello Boards - QA'
                  )
                  :
                  (
                    'Active Trello Boards - PM'
                  )}
               </h1>

               <div id="filter" className="filter-part">
            <Row>
                {this.state.orginalUsers.length>0 &&
                (
                  <Col sm="4" className="people">

                   
                    <p>Choose User</p>
                      <Typeahead
                      id="user-picker"
                      labelKey="collection"
                      onInputChange={this.handleInputChange}
                      onChange={this.onChangePeople}
                      options={this.state.orginalUsers}
                      placeholder="Choose user..."
                      selected=''
                      />
                  
                  </Col>
                )
                }
                
                {this.state.showSingle?
                (
                  <>
                      <Col  sm={this.state.orginalUsers.length>0 ? "2" : "7"} className="ml-auto p-2 board-count">
                        {this.state.singleCollection.length} Boards 
                      </Col>
                      <Col sm="5" className="ml-auto p-2">
                          {this.state.allBoardLastupdate!='' &&
                              <div className="d-flex justify-content-end">
                                <p className="text-right data-sync">Last Data Sync: {this.state.allBoardLastupdate}</p>
                                {this.state.orginalUsers.length>0 &&
                                (<p> |  <span className="text-right data-sync show-all-trello" onClick={this.showAllTrello.bind(this)} >Show All</span></p>)
                                }
                                 <span className="refresh-my-boards" onClick={this.getSingleBoard}><RefreshIcon /></span>
                              </div>
                          }
                          
                      </Col>
                  </>
                )
                :
                (
                  <Col sm="5" className="ml-auto p-2">
                      {this.state.allBoardLastupdate!='' &&
                          <p className="text-right data-sync">Last Data Sync: {this.state.allBoardLastupdate}</p>
                      }
                     
                  </Col>
                )
                }
                
            </Row>
        </div>
        {/*this.state.showSingle &&
        (
          <>
            <div className="action-msg">{this.state.actionStatus && 'Action Completed'}</div>
            <div className="trello_add_remove">
              <input placeholder="Add or remove boards to your watchlist" type="text" value={this.state.boardUrl} className="inputbox" name="boardUrl" onChange={this.handleTextChange} />
              <div className="button_action">
              <Button className="plus_btn" onClick={this.addRemoveTrelloWatch.bind(this, 1)}>+</Button>
              <Button className="min_btn" onClick={this.addRemoveTrelloWatch.bind(this, 0)} >-</Button>
              </div>
            </div>
          </>
        )*/}
        <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
        <LoadingWrapper isLoading={this.state.initialLoader}>
        {this.state.showSingle?
        (
          <div id="list-head" className="trello-boards">
            <Row>
              {this.state.singleCollection.map((item, index) =>
              {
                  return(
                      <Col sm="6">
                          <Card className="list-card d-block">
                              <Row>
                                  <Col sm="9" className="board-name">{item.board_name}</Col>
                                  <Col sm="3">
                                    <div className="board-icons d-flex justify-content-end">
                                      <a href="javascript:;" onClick={this.fetchBaseandRedirect.bind(this, item.board_name)} ><BasecampIconImg /></a>
                                      <a href={"https://trello.com/b/"+item.board_id+"/"+item.board_name} target="_blank"><TrelloIcon /></a>
                                    </div>
                                  </Col>
                              </Row>
                              <Row className="activity-row">
                                  <Col sm="10">{item.last_activity}</Col>
                                  <Col sm="2">
                                  {(this.state.myPrivileges.includes('i_am_qa') || this.state.myId === this.state.activeUserId ) ?
                                  (
                                      <>
                                           {item.bell_active === 1?
                                              (
                                                <span className="bell-active" onClick={this.getSingleBoardBell.bind(this,item.board_name)}><RedBell /></span>
                                              )
                                              :
                                              (
                                                <span className="bell-active" onClick={this.getSingleBoardBell.bind(this,item.board_name)}><GreenBell /></span>
                                              )
                                            }
                                      </>
                                  ):
                                  (
                                      <>
                                          {item.bell_active === 1?
                                            (
                                              <span className="bell-active with-disable" ><RedBell /></span>
                                            )
                                            :
                                            (
                                              <span className="bell-active with-disable" ><GreenBell /></span>
                                            )
                                          }
                                      </>
                                  )}
                                  </Col>
                              </Row>
                          </Card>
                      </Col>
                  )
              })}
            </Row>
          </div>
        )
        :
        (
          <div id="list-head" className="trello-boards">
              <Row>
              <Col xs={12} sm={6}>
                  <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Boards</th>
                      <th align="right"></th>
                    </tr>
                  </thead>
                     <tbody>
                     {this.state.firstHalf.map((item, index) =>
              {
                  return(
                    <>
                    {item.name !== 'Cancelled' &&
                    (

                       <tr>
                         <td> <div className="collection-name" onClick={this.showSingleboard.bind(this, item.name)}>{item.name.replace("-", " ")}</div></td>
                         <td>{item.active_boards}</td>
                         <td>
                         {item.bell_active === 1?
                                  (
                                    <span className="bell-active inactive"><RedBell /></span>
                                  )
                                  :
                                  (
                                    <span className="bell-active inactive"><GreenBell /></span>
                                  )
                                  }
                         </td>
                       </tr>
                           )}
                           </>
                         )
                     })}
                     </tbody>
                     
                


               </Table>
                      </Col>
                  <Col xs={12} sm={6}>
                  <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Boards</th>
                      <th align="right"></th>
                    </tr>
                  </thead>
                     <tbody>
                     {this.state.secondHalf.map((item, index) =>
              {
                  return(
                    <>
                    {item.name !== 'Cancelled' &&
                    (

                       <tr>
                         <td> <div className="collection-name" onClick={this.showSingleboard.bind(this, item.name)}>{item.name.replace("-", " ")}</div></td>
                         <td>{item.active_boards}</td>
                         <td>
                         {item.bell_active === 1?
                                  (
                                    <span className="bell-active inactive"><RedBell /></span>
                                  )
                                  :
                                  (
                                    <span className="bell-active inactive"><GreenBell /></span>
                                  )
                                  }
                         </td>
                       </tr>
                           )}
                           </>
                         )
                     })}
                     </tbody>
                     
                


               </Table>
                      </Col>
            </Row>
          </div>
        )
        }
        </LoadingWrapper>
        </motion.div>

            </Card>
          </Col>
      </Row>
      </div>
      </Row>

      
      </Container>
      </div>
    )
  }
}
