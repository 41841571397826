import React from 'react';

export default function RefreshIcon( props ) {
  const classes = "refresh-icon "+ props.classname;
  return (
    <div className={classes}>
      <div className="cont">
        <svg width="32px" height="33px">
          <path style={{ fillRule:"evenodd", fill: '#b2b2b2' }}
          d="M16.154,0.818 C7.417,0.818 0.308,7.927 0.308,16.665 C0.308,25.402 7.417,32.511 16.154,32.511 C24.891,32.511 32.000,25.402 32.000,16.665 C32.000,7.927 24.891,0.818 16.154,0.818 ZM6.663,17.168 C6.549,17.261 6.409,17.325 6.250,17.325 C5.885,17.325 5.590,17.029 5.590,16.664 C5.590,15.900 5.694,15.146 5.856,14.406 C5.856,14.406 5.986,13.821 5.991,13.806 C7.258,9.307 11.432,6.100 16.154,6.100 C19.983,6.100 23.536,8.210 25.388,11.529 C25.571,11.857 25.421,12.276 25.082,12.436 L23.886,13.004 C23.568,13.155 23.201,13.021 23.025,12.716 C21.621,10.283 18.988,8.741 16.154,8.741 C13.402,8.741 10.900,10.194 9.473,12.421 L10.421,12.737 C10.648,12.813 10.816,13.005 10.860,13.241 C10.905,13.476 10.818,13.717 10.635,13.871 L6.673,17.172 C6.670,17.175 6.666,17.166 6.663,17.168 ZM26.521,18.923 C26.521,18.923 26.391,19.508 26.386,19.523 C25.119,24.022 20.945,27.229 16.223,27.229 C12.394,27.229 8.841,25.119 6.989,21.800 C6.806,21.472 6.956,21.053 7.295,20.893 L8.491,20.325 C8.809,20.174 9.176,20.308 9.352,20.613 C10.756,23.047 13.388,24.588 16.223,24.588 C18.974,24.588 21.477,23.135 22.904,20.908 L21.956,20.592 C21.729,20.516 21.561,20.324 21.516,20.088 C21.472,19.853 21.558,19.612 21.742,19.458 L25.704,16.157 C25.707,16.154 25.711,16.163 25.714,16.161 C25.828,16.068 25.968,16.004 26.127,16.004 C26.492,16.004 26.787,16.300 26.787,16.665 C26.787,17.429 26.683,18.183 26.521,18.923 Z"/>
        </svg>
      </div>
    </div>
  )
}