import React, {Component} from 'react';
import './TaskSideMenu.css';
import Lottie  from 'react-lottie'
// import Icon from '../63-home-solid.json'
import IconOutline from './47-to-do-list-document-outline.json'
import { Link} from "react-router-dom";
import MaterialIcon, {colorPalette} from 'material-icons-react';


export default class TaskSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    render() {        

        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: IconOutline,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }

        return(
             <a href={`/boards/my-tasks?uuid=${this.props.logger}`}><div                
                className={"nsmi_home menu-item-home menu-item d-flex"+(this.props.activeitem === 'tasks'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className="nsmi_menu_wrapper d-flex">
                    <div className="nsmi_menu-icon" >
                        <MaterialIcon icon="task_alt" />
                    </div>                
                    <p className="nsmi_menu_name">My Assignments</p>
                    {/* <div className="menu_icon_arrow ml-auto"></div> */}
                </div>
            </div></a>
        )
    }
}