import React, {useState, useEffect, Component, Fragment} from 'react';
import MaterialIcon from "material-icons-react";

export default function ProjectCurrentStatus(props)
{
const [projectpacks, setProjectPacks] = useState('');
 
useEffect(() => {
  let packs ='';
  console.log(props.graphics);
  if(props.graphics>0)
  {
    packs += props.graphics>1 ? props.graphics+"G" : 'G';
  }
  if(props.video>0)
  {
    if(packs!='')
    {
        packs +=',';
    }
    packs += props.video>1 ? props.video+"V" : 'V';
  }
  if(props.graphics_video>0)
  {
    if(packs!='')
    {
        packs +=',';
    }
    packs += props.graphics_video>1 ? props.graphics_video+"G+V" : 'G+V';
  }
  setProjectPacks(packs);
  
},[]);

  return(
    <>
     {projectpacks &&
     (
      <div className="packs_count d-flex align-items-center">
          {/* {projectpacks !='' && ' - ['+projectpacks+']'} */}
          {(props.graphics>0) &&
          (
             <div className="packs_count_inner">
                {props.graphics>0 &&
                (<><MaterialIcon icon="palette"  /> <sup>{props.graphics}</sup></>)}
              </div>
          )}
          {(props.video>0) &&
          (
              <div className="packs_count_inner">
                {props.video>0 &&
                (<><MaterialIcon icon="play_circle"  /> <sup>{props.video}</sup></>)}
              </div>
          )}
          {(props.graphics_video>0) &&
          (
            <div className="packs_count_inner">
                {props.graphics_video>0 &&
                (<><MaterialIcon icon="burst_mode"  /> <sup>{props.graphics_video}</sup></>)}
              </div>
          )}
      </div>
     )}
      {props.whitelabel === 1 &&
      (
        <span> - WhiteLabel</span>
      )}
      {props.clientstatus != 0 &&
      (
        <span>{props.clientstatus === 1? ' - Trial' :(props.clientstatus === 2 ? ' - Active' : (props.clientstatus === 3 ? ' - Hold ' :( props.clientstatus === 4 ? ' - Paused' : ( props.clientstatus === 5 ? ' - Cancelled' : ''))))}</span>
      )}
  </>
  )
  }