import React from 'react';
import './FeedCommentCount.css'

export default function FeedCommentCount(props) {
  const count = `${props.count} Comment${props.count > 1 ? 's' : ''}`;
  return (
    <div className="feed_comment_action d-flex">
      <div className={"feed_comment_action_icon feed_comment_action_icon_comment"}></div>
      <p>{count}</p>
    </div>
  )
}