import React,{useState, useEffect} from 'react';
import './basecampmodule.css';
import {Button} from "reactstrap";
import { AccessCheckBlock } from './AccessCheck';
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'linkifyjs/react';
import ConfirmAction from '../admincomment/ConfirmAction';
import moment from "moment";

export default function BrandNote(props)
{
    const [brnote, setBrNote] = useState(props.brandnote);
    const [showDeleteConfirmation, setshowDeleteConfirmation]= useState(false);
    const options = {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: false,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true
      };
    function findInitials(word)
    {
        var matches = word.match(/\b(\w)/g); 
        var acronym = matches.join(''); 
        return acronym;
    }

    function deleteNoteAsk()
    {
        setshowDeleteConfirmation(true);
    }

    function deleteNote()
    {
        props.deletenote(props.indexkey, props.brandnote.note_id);
    }

    function closeDialogue(){
        setshowDeleteConfirmation(false);
    }

    function editNote()
    {
        props.editnote(props.brandnote.note_id, props.brandnote.notes, props.indexkey);
    }
    function convertTimeFormat (time)
	{
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}

    function stripslashes(str){
        if(str.length>0)
        {
            str = str.replace(/\\'/g, '\'');
            str = str.replace(/\\"/g, '"');
            str = str.replace(/\\0/g, '\0');
            str = str.replace(/\\\\/g, '\\');
        }
        return str;
    }

    return(        
            <div className="cmd_inner_box" id={brnote.note_id}>
                <div className="user_details_bc">
                <div className="user_avatar">{findInitials(brnote.full_name)}</div>
                <div className="user_bc_name">{brnote.full_name}</div>
                </div>
                <Linkify  options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ ReactHtmlParser(stripslashes(brnote.notes) +" ")}</Linkify>
                <div className="note_datentime">
                <span className="time_note">
                {(brnote.updated_on !=='')  &&
                (
                    moment((new Date(brnote.updated_on)).getTime()).tz(props.mytimezone).format('MMM, DD YYYY h:mm a')
                )}
                </span>
                </div>
                <div className="qucik_button">
                {AccessCheckBlock('add_edit_delete_pin_notes') &&
                (
                    <>
                        <Button className="remove_btn_prev" onClick={deleteNoteAsk}></Button>
                        <Button className="edit_btn_prev" onClick={editNote}></Button>
                    </>
                )
                }
                
                </div>
                {showDeleteConfirmation && (
                <ConfirmAction
                    purpose="change"
                    closeDialogue={closeDialogue}
                    show={showDeleteConfirmation}
                    note="*This action cannot be undone, are you sure?"
                    content="You are about to delete this note."
                    handledeletion={deleteNote}
                    resolveid=""
                    resolveStatus=""
                    adminid=""
                    confirmBoxAction="deletbrandnote"
                />
                )}
            </div>
    )
}