import React, { useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  EmptyNotifyMessage, MinusNotifyCount,
  SubstractNotifyMessages,
  AppendUnReadNotifyMessages,
  AdditionNotifyCount,
  AppendNotifyMessage, AppendReadNotifyMessages,
  SubstractReadNotifyMessages, AppendNotifyMessages, EmptyNotifyCount
} from '../redux/actions';
import { useHistory } from "react-router";
import ReactHtmlParser from 'react-html-parser';
import Header from '../header/Header';
import { urls } from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, CardTitle, FormGroup, Label, Input } from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './notification.css'
import axios from 'axios';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import ConfirmAction from '../admincomment/ConfirmAction';
import { useGetUserNotifications, useMarkNotificationRead, useMarkNotificationUnRead, useMarkAllNotiRead } from './useQueryHooks'

export default function Notificationdetail(props) {
  const dispatch = useDispatch();
  const [opencount, setOpenCount] = useState(0);
  const [showMenu, setshowMenu] = useState(true);
  const [myId, setmyId] = useState(0);
  const [progressbar, setProgressBar] = useState(true);
  const [askForRead, setAskForRead] = useState(false);
  const [notiFilterActive, setNotiFilterActive] = useState('all');
  const [filterActive, setFilterActive] = useState(false);

  const { isLoading, isError, error, isFetching, data: notices } = useGetUserNotifications();

  const sortByDate = (messages) => {
    let temp = messages;
    temp.sort(descOrder);
    return temp;
    function ascOrder(a, b) {
      return new Date(a.message_time).getTime() - new Date(b.message_time).getTime();
    }
    function descOrder(a, b) {
      return new Date(b.message_time).getTime() - new Date(a.message_time).getTime();
    }
  }

  //const [unReadNotifications, setunReadNotifications] = useState([]);
  const unReadNotifications = useSelector(state => sortByDate(state.NotificationMessagesReducer));
  const ReadNotifications = useSelector(state => sortByDate(state.ReadNotificationMessagesReducer));

  const history = useHistory();



  useEffect(() => {
    checkUserPrivilege();
    setTimeout(function () {
      setProgressBar(false);
    }, 2000);
  }, []);

  useEffect(() => {
    // getUnreadNotifications();
  }, [myId]);

  const checkUserPrivilege = () => {

    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      setmyId(login.uid);
    }
  }

  function getUnreadNotifications() {
    fetch(urls.getUnreadNotifications + myId, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {
        //setunReadNotifications(response);
      })
      .catch(error => {
      });
  }


  const markasRead = (item, type) => {
    if (item && item.receiver && item.receiver != '') {
      const urlspart = item.receiver.split("@");
      const data1 = {
        access_id: urlspart[1],
        user_id: myId
      };
      markNotificationRead(data1);
      dispatch(MinusNotifyCount());
    }
  }
  const { mutate: markNotificationRead } = useMarkNotificationRead();


  const markasUnRead = (item, type) => {
    if (item && item.receiver && item.receiver != '') {
      const urlspart = item.receiver.split("@");
      const data2 = {
        access_id: urlspart[1],
        user_id: myId
      };
      markNotificationUnRead(data2);
      dispatch(AdditionNotifyCount());
    }
  }
  const { mutate: markNotificationUnRead } = useMarkNotificationUnRead();


  const markAllNotiRead = (item, type) => {
    const data3 = {
      user_id: myId,
      filter: notiFilterActive
    };
    markAllRead(data3);
    closeDialogue();
    dispatch(EmptyNotifyCount());
  }
  const { mutate: markAllRead } = useMarkAllNotiRead();


  function askMarkAllRead() {
    setAskForRead(true);
  }



  const closeDialogue = () => {
    setAskForRead(false);
  };


  const goToNotification = (item, type) => {
    if (item && item.receiver && item.receiver != '') {
      const urlspart = item.receiver.split("@");
      if (type === 1) {
        // window.location.href = urls.notificationUrl+urlspart[0];
        window.open(
          urls.notificationUrl + urlspart[0],
          '_blank'
        );
      }
    }
  }


  function toggleSidebar() {
    setshowMenu(!showMenu);
  }

  function hideMenu() {
    setshowMenu(!showMenu);
  }

  function goToHome() {
    //this.props.history.push("/home");
    history.push({ pathname: "/home" });
  }



  const setFilter = (type) => {
    setFilterActive(true);
    setNotiFilterActive(type);
  }



  return (
    <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className=''>
        <Row>
          <div className="theme_header">
            <Header
              ncount={opencount}
              {...props} className="tick-timer-header"
              quickactionblock={false} />
          </div>
          <div className="SideNavbar">
            <SideMenu activeitem="admin" showmenu={showMenu} hidemenu={hideMenu} />
          </div>
          <div className="themeContentBody">
            <Row>
              <Col sm={12} lg={12}>
                <Card className="switch_user_card allnotifiction">
                  <CardTitle tag="h1">Notifications</CardTitle>
                  <Tabs>
                    <TabList>
                      <Tab>Unread Notifications</Tab>
                      <Tab>Read Notifications</Tab>
                    </TabList>
                    <TabPanel>
                      <LoadingWrapper isLoading={progressbar}>
                        <div className='notification-filters'>
                          <span className={notiFilterActive === 'all' && 'active'} onClick={(e) => setFilter('all')}>All</span>
                          <span className={notiFilterActive === 'task-assigned' && 'active'} onClick={(e) => setFilter('task-assigned')} >Card Assigned</span>
                          <span className={notiFilterActive === 'commented' && 'active'} onClick={(e) => setFilter('commented')} >Commented</span>
                          <span className={notiFilterActive === 'mentioned' && 'active'} onClick={(e) => setFilter('mentioned')} >Mentioned</span>
                          <span className={notiFilterActive === 'task-completed' && 'active'} onClick={(e) => setFilter('task-completed')} >Completed</span>
                        </div>
                        {(!isLoading && notices.data.notifications.length > 0) ?
                          (
                            <>
                              <div className="text-right all-notifications-read" onClick={askMarkAllRead}>Mark all read</div>
                              {notices.data.notifications.map((notification, index) => {
                                return (
                                  <div className={`notification_list ${filterActive ? 'filtered' : ''} ${notification.title.replace(/\s+/g, '-').toLowerCase() === notiFilterActive ? notiFilterActive : (notiFilterActive === 'all' ? 'showall' : '')}`} key={notification.message_time}>
                                    <div className="notifi_data" onClick={goToNotification.bind(this, notification, 1)}>
                                      <h3>{notification.title === 'Task Assigned' ? 'Card Assigned' : (notification.title === 'Task Completed' ? 'Card Completed' : notification.title)}</h3>
                                      {/* {notification.receiver !=='' || notification.receiver !== null ?
                                (
                                  <a className="noti-click" onClick={goToNotification.bind(this,notification, 1)}><p>{ ReactHtmlParser( notification.body +" ")}</p></a>
                                ):
                                (
                                  <p>{ ReactHtmlParser( notification.body +" ")}</p>
                                )} */}
                                      <p>{ReactHtmlParser(notification.body + " ")}</p>
                                      {notification.body_title && notification.body_title != '' &&
                                        (
                                          <p><strong>{ReactHtmlParser(notification.body_title + " ")}</strong></p>
                                        )}
                                      <span className="daten_time_notify">{notification.message_time}</span>
                                    </div>
                                    <div className="notifi_checkbox">
                                      <FormGroup check>
                                        <Label >
                                          <Input type="checkbox" checked="" onClick={markasRead.bind(this, notification, 0)} />{' '}
                                        </Label>
                                      </FormGroup>
                                    </div>
                                  </div>
                                )
                              }
                              )}
                            </>
                          ) :
                          (
                            'No new notifications'
                          )}
                      </LoadingWrapper>
                    </TabPanel>

                    <TabPanel>
                      {(!isLoading && notices.data.readnotifications.length > 0) &&
                        (
                          <>
                            <div className='notification-filters read'>
                              <span className={notiFilterActive === 'all' && 'active'} onClick={(e) => setFilter('all')}>All</span>
                              <span className={notiFilterActive === 'task-assigned' && 'active'} onClick={(e) => setFilter('task-assigned')} >Card Assigned</span>
                              <span className={notiFilterActive === 'commented' && 'active'} onClick={(e) => setFilter('commented')} >Commented</span>
                              <span className={notiFilterActive === 'mentioned' && 'active'} onClick={(e) => setFilter('mentioned')} >Mentioned</span>
                              <span className={notiFilterActive === 'task-completed' && 'active'} onClick={(e) => setFilter('task-completed')} >Completed</span>
                            </div>
                            {notices.data.readnotifications.map((notification, index) => {
                              return (
                                <div className={`notification_list ${filterActive ? 'filtered' : ''} ${notification.title.replace(/\s+/g, '-').toLowerCase() === notiFilterActive ? notiFilterActive : (notiFilterActive === 'all' ? 'showall' : '')}`} key={notification.message_time}>
                                  <div className="notifi_data" onClick={goToNotification.bind(this, notification, 1)}>
                                    <h3>{notification.title === 'Task Assigned' ? 'Card Assigned' : (notification.title === 'Task Completed' ? 'Card Completed' : notification.title)}</h3>
                                    {/* {notification.receiver !=='' || notification.receiver !== null ?
                                (
                                  <a className="noti-click" onClick={goToNotification.bind(this,notification, 1)}><p>{ ReactHtmlParser( notification.body +" ")}</p></a>
                                ):
                                (
                                  <p>{ ReactHtmlParser( notification.body +" ")}</p>
                                )} */}
                                    <p>{ReactHtmlParser(notification.body + " ")}</p>
                                    <span className="daten_time_notify">{notification.message_time}</span>
                                  </div>
                                  <div className="notifi_checkbox">
                                    <FormGroup check>
                                      <Label >
                                        <Input type="checkbox" checked="checked" onClick={markasUnRead.bind(this, notification, 0)} />{' '}
                                      </Label>
                                    </FormGroup>
                                  </div>
                                </div>
                              )
                            }
                            )}
                          </>
                        )}

                    </TabPanel>
                  </Tabs>

                </Card>
              </Col>
            </Row>
          </div>
        </Row>
        {askForRead ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={closeDialogue}
            show={askForRead}
            note="*Are you sure?"
            content="You are about to mark all notifications as read"
            handledeletion={markAllNotiRead}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="deletetask"
          />
        ) : null}
      </Container>
    </div>
  )
}
