import React, { Component, Fragment } from "react";
import "./ApprovalTimeRow.css";

export default function ApprovalTimeRow(props) {
  return (
    <div className="shift-current d-flex">
      <p className="appr-date-title">{props.label}</p>
      <p className="appr-data-data ml-auto">{props.value}</p>
    </div>
  );
}
