import React, { useState, useEffect } from "react";
import {
    Input,
    Button,
    Container,
    Row,
    Col,
    Table,
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
} from "reactstrap";
import "./SourceManagement.css";
import MaterialIcon from "material-icons-react";
import {
    useGetRevsionFiles,
    useGetRevsionSubFolders,
    useAddRevisonSubFolder,
    useRemoveSourceFile,
    useGetBreadData,
    useRemoveSourceVersionFolders,
    useGetRevsionSubFoldersView,
    useGetRevsionFilesView,
    useDownloadasZip
} from "./useQueryHooks";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../../services/UrlList";
import ClipboardCopy from "./ClipboardCopy";


export default function SourceViewFolder(props) {

    const [taskId, setTaskID] = useState((props.match.params.taskid && props.match.params.taskid > 0) ? props.match.params.taskid : 0);
    const [revisionID, setRevsionID] = useState((props.match.params.revisionid && props.match.params.revisionid > 0) ? props.match.params.revisionid : 0);
    const [uniqueFolderKey, setUniqueFolderKey] = useState((props.match.params.ukey && props.match.params.ukey != '') ? props.match.params.ukey : '');
    const [keyExpiry, setKeyExpiry] = useState((props.match.params.keyexp && props.match.params.keyexp != '') ? props.match.params.keyexp : '');
    const [parentFolder, setParentFolder] = useState((props.match.params.folderid && props.match.params.folderid > 0) ? props.match.params.folderid : 0);
    const [masterFolder, setMasterFolder] = useState((props.match.params.folderid && props.match.params.folderid > 0) ? props.match.params.folderid : 0);


    useEffect(() => {
        setTimeout(function () {
            fetchSubFolders();
            fetchNewFiles();
        }, 500);
    }, [parentFolder]);

    const setRevisionNum = (folderid) => {
        setParentFolder(folderid);
    };

    const {
        isLoading: subFolderLoading,
        isError,
        error,
        isFetching,
        data: subFolderData,
        refetch: fetchSubFolders,
    } = useGetRevsionSubFoldersView(revisionID, taskId, parentFolder, uniqueFolderKey, keyExpiry, masterFolder, 2);

    const {
        isLoading: filesLoading,
        isFetching: filesFetching,
        data: filesData,
        refetch: fetchNewFiles,
    } = useGetRevsionFilesView(revisionID, taskId, parentFolder, uniqueFolderKey, keyExpiry, masterFolder, 2);

    const {
        isLoading: breadLoading,
        isFetching: breadFetching,
        data: breadData,
        refetch: fetchBread,
    } = useGetBreadData(revisionID, parentFolder);


    const downloadZip = (sfolder) => {
        const data2 = {
          revisionid: revisionID,
          taskid: taskId,
          parentFolder: sfolder.id,
          parentFolderName: sfolder.folder_name
        };
        downloadasZip(data2);
    };
    const { isLoading: downloadLoading, mutate: downloadasZip } = useDownloadasZip();

    const downloadAllZip = () => {
        const data2 = {
          revisionid: revisionID,
          taskid: taskId,
          parentFolder: masterFolder,
          parentFolderName: 'Folder-files'
        };
        downloadallasZip(data2);
      };
      const { isLoading: downloadallLoading, mutate: downloadallasZip } = useDownloadasZip();

      
    return (
        <Container className="source-management-wrap public-view-source">
            <Card className="d-flex justify-content-center public-source-head">
                <h4>Source Files</h4>
            </Card>
            <Row>
                <div className="d-flex view-source-bread">
                    {breadData?.data.length > 0 &&
                        (
                            <span onClick={setRevisionNum.bind(this, masterFolder)}
                                className={breadData?.data.length > 0 ? "bread-link" : "bread-item"} >
                                Home
                            </span>
                        )}
                    {breadData?.data.map((breadcrumb, index) => {
                        return (
                            <>
                                <span className="bread-seperator">
                                    <MaterialIcon icon="chevron_right" />
                                </span>
                                <span
                                    onClick={setRevisionNum.bind(this, breadcrumb.id)}
                                    className={
                                        index + 1 === breadData?.data.length
                                            ? "bread-item"
                                            : "bread-link"
                                    }
                                >
                                    {breadcrumb.folder_name}
                                </span>
                            </>
                        );
                    })}
                </div>
            </Row>
            <>
                <Row className="download-all-source">
                    {(subFolderData?.data.success == 1) &&
                        (<div className="download-data" onClick={downloadAllZip}>Download All Data</div>)
                    }
                </Row>
                <Row className="uploaded-versions subfolder d-flex">
                    
                    {subFolderData?.data.success === 0 && (
                        <div className="key-expired d-flex justify-content-center ">The resource you are looking for is either expired or invalid.</div>
                    )}
                    {subFolderData?.data.revisionsubs.map((folder, index) => {
                        return (
                            <div key={folder.id} className="folder_box_som">
                                <Card className="d-flex folder-wrapper-som source-folder-items">
                                    <div className="d-flex"
                                        onClick={setRevisionNum.bind(this, folder.id)}
                                    >
                                        <MaterialIcon icon={"folder"} />
                                        <span className="folder_name">
                                            <span className="revision-name">{folder.folder_name}</span>
                                            <span className="revision-date">{folder.updated_on}</span>
                                        </span>
                                    </div>
                                    <div className="d-flex source-folder-actions">
                                        <div className="quick_actions_som quick_actions_som_inner">
                                            <Button className="quick_action_btn" title="download" onClick={()=>downloadZip(folder)}>
                                                <MaterialIcon icon="download" />
                                            </Button>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        );
                    })}
                </Row>

                {(downloadLoading || downloadallLoading) &&
                (<Row>
                    <div className="d-flex justify-content-center align-items-center preparing-files">
                    Preparing your files for download, please wait....
                    </div>
                </Row>)
                }

                {filesData?.data.revisionfiles.length > 0 &&
                    (
                        <Row>
                            <div className="revision-files">
                                <Table responsive className="theme_table">
                                    <thead>
                                        <tr>
                                            <th>File</th>
                                            <th>Uploaded</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <LoadingWrapper isLoading={filesLoading || filesFetching}>
                                            {filesData?.data.revisionfiles.map((sfile, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            <span className="d-flex file-name">
                                                                <span className="fileicon-size">
                                                                    <a
                                                                        href=""
                                                                        target="_blank"
                                                                        className="attachment-bg bg-small"
                                                                    >
                                                                        <span className="attachment-type">
                                                                            {sfile.filename.split(".").pop()}
                                                                        </span>
                                                                    </a>
                                                                    <span className="fileiconsize">
                                                                        {sfile.file_size}
                                                                    </span>
                                                                </span>
                                                                <span className="filename">{sfile.filename}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            {sfile.updated_on}
                                                            <br />
                                                            {sfile.name}
                                                        </td>
                                                        <td className="last-item">
                                                            <span className="source-file-actions d-flex justify-content-between ">
                                                                <ClipboardCopy
                                                                    copyText={`${urls.s3Url}${sfile.file_location}/${sfile.filename}`}
                                                                />
                                                                <a target="_blank"
                                                                    href={`${urls.s3Url}${sfile.file_location}/${sfile.filename}`}>
                                                                    <MaterialIcon icon="file_download" />
                                                                </a>

                                                            </span>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </LoadingWrapper>
                                    </tbody>
                                </Table>
                            </div>
                        </Row>
                    )}
                <Row>
                    {(subFolderData?.data.success === 1 && filesData?.data.revisionfiles.length === 0) &&
                        (
                            <div className="revision-files">No files uploaded to this folder.</div>
                        )}
                </Row>
            </>
        </Container>
    );
}
