import React, { Component, Fragment } from 'react';
import './tickets.css'
import Header from '../header/Header';
import DateTimePicker from "react-datetime-picker";
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import CalenderIcon from "../../images/date-icon.png";
import Searchtickets from  "../svgicon/Searchtickets";
import { Typeahead } from 'react-bootstrap-typeahead';
import ReactTags from 'react-tag-autocomplete'
import ClearIcon from '../../images/clear.png'
import MaterialIcon from "material-icons-react";
import { Scrollbars } from 'react-custom-scrollbars';
import SideMenu from "../NewSideMenu/NewSideMenu";

export default class NewTicket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      showProgress: true,
      originalTickets:[],
      allTickets:[],
      ticketDate: 'Created On',
      filterAssignees:[],
      filterCreatedFor:[],
      filterTopics:[],
      filterStatus:[],
      filterTags:[],
      filterClients:[],
      pageNumber:0,
      assignedTo:'',
      createdBy:'',
      relatedTo:'',
      ticketStat:'',
      setTags:[],
      selectedClient:'',
      minDate: new Date(),
      DueAt:false,
      DueToday:false,
      showDueDateChange:false,
      dueDate:'',
      massDueError:false,

      selectedClientArray:'',
      selectedClientID:'',
      selectedClientName:'',

      selectedAssigneeArray:'',
      selectedAssigneeID:'',
      selectedAssigneeName:'',

      selectedResponsibleArray:'',
      selectedResponsibleID:'',
      selectedResponsibleName:'',

      dueonMeToday:0,
      dueonMe:0,
      moreLoadingProgress:false,
      ownership:'',
      showFilters:false,
      noRecordMsg: 'Fetching records...',
      createdDate: '',
      updatedDate: '',
      resolvedDate: '',
      sortBy:'',
      orginalUsers:[{'id':'-999', 'name': 'Client'}, {'id':'-998', 'name': 'None'}],
      ticketsArray:[],
      tassignees:[],
      showAssigneeChange:false,
      showStatusChange:false,
      massticketStat:'',
      assigmentProgress:false,
      statusChangeProgress:false,
      incemetor:0,
      massAssigneeError:false,
      massStatusError:false,
      assingedOnlyMe:0,
      pendingAtMyEnd:0,
      ticketID:'',
      showMenu:true,
      allSelected:false
    };
    this.reactTags = React.createRef();
  }

  isBottom(el){
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount () {
    document.title = "HUB - Ticket System";
    this.setStateUsingStorage();
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.renderContentViewOwnership();
      _this.getFilters();
      _this.getAllTickets();
      _this.getFullUsers();
      document.addEventListener("scroll", _this.trackScrolling);
    }, 1000);
  };

  componentWillUnmount() {         
    this.setStateUsingStorage();
    this.checkUserPrivilege();
    this.renderContentViewOwnership();
    this.getFilters();
    this.getAllTickets();
    this.getFullUsers();
    document.removeEventListener("scroll", this.trackScrolling);
  }

  setStateUsingStorage = () =>
  {
    
    this.setState({
      createdBy: localStorage.getItem("createdBy"),   
      relatedTo: localStorage.getItem("relatedTo") ,
      ticketStat: localStorage.getItem("ticketStat"), 
      sortBy: localStorage.getItem("sortBy"),
      assingedOnlyMe: localStorage.getItem("assignedOnlyMe"),
      pendingAtMyEnd: localStorage.getItem("pendingAtMyEnd"),
      ticketID: localStorage.getItem('ticketID'),
      dueonMeToday: localStorage.getItem('dueonMeToday'),
      dueonMe: localStorage.getItem('dueonMe')
    })
    this.setClientfromStorage();
    this.setAssigneefromStorage();
    this.setResponsiblefromStorage();
  }

  getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),          
        });            
    })
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        originalTickets:[],
        allTickets:[],
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('team_tickets'))
      {
        this.setState({ownership:'team'});
      }else if(this.state.myPrivileges.includes('my_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home"+"?uuid="+this.state.myId);
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  showTicketCreation =() =>
  {
    document.removeEventListener("scroll", this.trackScrolling);
    this.props.history.push("/new-ticket"+"?uuid="+this.state.myId);
  }
  showDashboard =() =>
  {
    document.removeEventListener("scroll", this.trackScrolling);
    this.props.history.push("/home"+"?uuid="+this.state.myId);
  }

  showTicketDetail = (id) =>
  {
    document.removeEventListener("scroll", this.trackScrolling);
    window.open("/ticket/"+id+"?uuid="+this.state.myId,'_blank');
    //this.props.history.push("/ticket/"+id);
  }

  getAllTickets = () =>
  {
    this.setState({noRecordMsg: 'Fetching Records...'});
    let opts ={
      user_id:this.state.myId,
      assigned_to: this.state.assignedTo,  
      created_by: this.state.createdBy,   
      related_to: this.state.relatedTo,  
      page_number: this.state.pageNumber,
      owner:this.state.ownership,
      status: this.state.ticketStat, 
      tags: this.state.setTags,
      client:this.state.selectedClient,
      created_on: this.state.createdDate,
      updated_on: this.state.updatedDate,
      resolved_on: this.state.resolvedDate,
      responsible_by: this.state.responsibleBy,
      sort_by: this.state.sortBy,
      assingedOnlyMe:this.state.assingedOnlyMe,
      pendingAtMyEnd: this.state.pendingAtMyEnd,
      dueonMe: this.state.dueonMe,
      dueonMeToday: this.state.dueonMeToday,
      ticketID: this.state.ticketID
    }

    console.log(opts);
    //return false;
    fetch(urls.getAllHTickets,{
			headers:{
				'Access-Control-Allow-origin':'*',
				'Content-Type':'application/json'
			},
			method: "post",
        	body: JSON.stringify(opts)
		})
		.then(response => response.json())
		.then(response =>
		{    
      let list = this.elliminateDuplicates(response.tickets);
            this.setState({
                originalTickets:response.tickets,
                allTickets:list,
                showProgress:false,
                moreLoadingProgress:false,
            });
            if(list.length<=0)
            {
              this.setState({noRecordMsg: 'No Records Found'});
            }
            if (response.tickets.length >= 20) {
              document.addEventListener("scroll", this.trackScrolling);
            }
		})
		.catch(error =>
		{
			this.setState({
               showProgress:false,
               moreLoadingProgress:false,
            });
		})
  }

  elliminateDuplicates = response => {    
    let temp_list = this.state.allTickets.concat(response);
    let master_list = this.state.allTickets;
    let list = [];
    var activeticket = 0;
    temp_list.forEach(p1 => {
        if(activeticket != p1.id)
        {
            list.push(p1);
            activeticket = p1.id;
        }
    });
    if(this.state.ticketStat == 8)
    {
      list.sort(descOrder);
    }
    return list;

    function descOrder(a, b) {
      return new Date(b.resolved_on).getTime() - new Date(a.resolved_on).getTime();
    }

  };

  

  onTdateChange = (date) => 
  {
    this.setState({ ticketDate: date });
  }

  filterTickets = () =>
  {
    this.setState({pageNumber:0, originalTickets:[], allTickets:[]});
    const _this = this;
    setTimeout(()=>{
      _this.getAllTickets();
    }, 200);
    this.setState({ showFilters: false})
  }

  getFilters()
  {
    fetch(urls.getTicketFilters,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      }
    })
    .then(response => response.json())
    .then(response=>
      {   
          this.setState({
            filterAssignees: response.assigned_to,
            filterCreatedFor:response.created_by,
            filterTopics: response.topics,
            filterStatus: response.status,
            filterTags:response.tags,
            filterClients:response.clients
          })
      })
      .catch(error=>
        {
          console.log(error);
        })
  }

  handleChange = ({target}) =>
  {
    this.setState({ [target.name] : target.value})
    localStorage.setItem([target.name], target.value);
    if(target.name == 'ticketStat' && target.value == 8)
    {
      this.setState({
        resolvedDate:'',
        sortBy:'',
      })
    }
  }
 
  trackScrolling = () =>
  {
    
    const wrappedElement = document.getElementById("list");
    if(this.isBottom(wrappedElement))
    {
      document.removeEventListener("scroll", this.trackScrolling);
    //  console.log("bottom reached");
      this.setState(
        {
          pageNumber: this.state.pageNumber +1,
          moreLoadingProgress: true
        });
        const _this = this;
        setTimeout(()=>{
          _this.getAllTickets();
          //document.removeEventListener("scroll", _this.trackScrolling);
        }, 200);
      
    }
  };

  onDeleteTag (i) {
    const setTags = this.state.setTags.slice(0)
    setTags.splice(i, 1)
    this.setState({ setTags })
  }
 
  onAdditionTag (tag) {
    const setTags = [].concat(this.state.setTags, tag)
    this.setState({ setTags })
  }

  onChangeClient = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
      let opts ={
        client_name:e[0].client_name,
        id:e[0].id
      }
      var result = [];
      result.push(opts);
      this.setState({
        selectedClientArray:result,
        selectedClient: e[0].client_name,
      });
      localStorage.setItem('selectedClientID',e[0].id);
      localStorage.setItem('selectedClientName',e[0].client_name);
    }else
    {
      this.setState({ 
        selectedClientArray:'',
        selectedClient: ''
      });
      localStorage.setItem('selectedClientID','');
      localStorage.setItem('selectedClientName','');
    }
  };

  setClientfromStorage = () =>
  {
    if(localStorage.getItem("selectedClientName") !=null)
    {
      let sArray=[];
      let opts ={
        client_name: localStorage.getItem("selectedClientName"), 
        id: localStorage.getItem("selectedClientID")
      }
      sArray.push(opts);
      this.setState({
        selectedClientArray:sArray,
        selectedClient: localStorage.getItem("selectedClientName")
      });
    }else
    {
      this.setState({
        selectedClientArray:'',
        selectedClient:'' });
    }
  }

  onChangeAssigneeTo = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
      let opts ={
        name:e[0].name,
        id:e[0].id
      }
      var result = [];
      result.push(opts);
      this.setState({
        selectedAssigneeArray:result,
        assignedTo: e[0].id
      });
      localStorage.setItem('selectedAssigneeID',e[0].id);
      localStorage.setItem('selectedAssigneeName',e[0].name);
    }else
    {
      this.setState({ 
        selectedAssigneeArray:'',
        assignedTo: ''
      });
      localStorage.setItem('selectedAssigneeID','');
      localStorage.setItem('selectedAssigneeName','');
    }
  };

  setAssigneefromStorage = () =>
  {
    if(localStorage.getItem("selectedAssigneeName") != null)
    {
      let sArray=[];
      let opts ={
        name: localStorage.getItem("selectedAssigneeName"), 
        id: localStorage.getItem("selectedAssigneeID")
      }
      sArray.push(opts);
      this.setState({
        selectedAssigneeArray:sArray,
        assignedTo: localStorage.getItem("selectedAssigneeID")});
    }else
    {
      this.setState({
        selectedAssigneeArray:'',
        assignedTo:''
      });
    }
  }

  onChangeResponsible = (e) => {

    var size = Object.keys(e).length;
    if(size>0)
    {
      let opts ={
        name:e[0].name,
        id:e[0].id
      }
      var result = [];
      result.push(opts);
      this.setState({
        selectedResponsibleArray:result,
        responsibleBy: e[0].name
      });
      localStorage.setItem('selectedResponsibleID',e[0].id);
      localStorage.setItem('selectedResponsibleName',e[0].name);
    }else
    {
      console.log("hello");
      this.setState({ 
        selectedResponsibleArray:'',
        responsibleBy:''
      });
      localStorage.setItem('selectedResponsibleID','');
      localStorage.setItem('selectedResponsibleName','');
    }
  };

  setResponsiblefromStorage = () =>
  {
    if(localStorage.getItem("selectedResponsibleName") != null)
    {
      let sArray=[];
      let opts ={
        name: localStorage.getItem("selectedResponsibleName"), 
        id: localStorage.getItem("selectedResponsibleID")
      }
      sArray.push(opts);
      this.setState({
        selectedResponsibleArray:sArray,
        responsibleBy: localStorage.getItem("selectedResponsibleName")
       });
    }else
    {
      this.setState({
        selectedResponsibleArray:'',
        responsibleBy:''
       });
    }
  }


  handleInputChange(input, e) {
    this.setState({ selectedClient :e});
  }

  showAdvancedFilter = ()=>
  {
    this.setState({ showFilters: true})
  }

  closeAdvancedFilter = ()=>
  {
    this.setState({ showFilters: false})
  }

  onCdateChange = (cdate) => 
  {
    this.setState({ createdDate: cdate });
  }

  onUdateChange = (date) => 
  {
    this.setState({ updatedDate: date });
  }

  onRdateChange = (date) => 
  {
    this.setState({ resolvedDate: date });
  }

  onTDuedateChange = (date) => 
  {
    this.setState({ dueDate: date });
  }

  resetFilters=()=>
  {
    this.setState({
      assignedTo:'',
      createdBy:'',
      relatedTo:'',
      ticketStat:'',
      setTags:[],
      selectedClient:'',
      createdDate: '',
      updatedDate: '',
      resolvedDate: '',
      assingedOnlyMe:'',
      pendingAtMyEnd:'',
      ticketID:'',
      dueonMe:0,
      dueonMeToday:0
    })

    localStorage.setItem('selectedClientID','');
    localStorage.setItem('selectedClientName','');
    localStorage.setItem('selectedAssigneeID','');
    localStorage.setItem('selectedAssigneeName','');
    localStorage.setItem('selectedResponsibleID','');
    localStorage.setItem('selectedResponsibleName','');
    localStorage.setItem('assignedOnlyMe', 0);
    localStorage.setItem('assignedTo', 0);
    localStorage.setItem('pendingAtMyEnd', 0);
    localStorage.setItem('createdBy', '');
    localStorage.setItem('relatedTo', '');
    localStorage.setItem('ticketStat', '');
    localStorage.setItem('setTags', '');
    localStorage.setItem('selectedClient', '');
    localStorage.setItem('createdDate', '');
    localStorage.setItem('updatedDate', '');
    localStorage.setItem('resolvedDate', '');
    localStorage.setItem('ticketID', '');
    localStorage.setItem('dueonMeToday',0);
    localStorage.setItem('dueonMe',0);

  }

  handleClick = e => {
    let members = this.state.ticketsArray;
    if (e.target.checked) {
      console.log("checked");
      members.push(parseInt(e.target.value));
      this.setState({ ticketsArray: members, allSelected:false });
    } else {
      console.log("unchecked");
      var array = [...this.state.ticketsArray]; // make a separate copy of the array
      var index = array.indexOf(parseInt(e.target.value));
      console.log(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      this.setState({ ticketsArray: array, allSelected:false });
      console.log(array);
    }
  };


  handleAllClick = (e) =>
  {
    let selecteditems = [...this.state.ticketsArray];
    let mtickets = [...this.state.allTickets];
    if (e.target.checked) {
      mtickets.forEach(p1 => {
        selecteditems.push(p1.id);
      })
      this.setState({ticketsArray:selecteditems, allSelected:true})
    }else
    {
      this.setState({ ticketsArray:[],allSelected:false})
    }
  }


  onDeleteAssignees (i) {
      const tassignees = this.state.tassignees.slice(0)
      tassignees.splice(i, 1)
      this.setState({ tassignees });
  }
 
  onAdditionAssignees (tag) {
      const tassignees = [].concat(this.state.tassignees, tag)
      this.setState({ tassignees });
      this.setState({massAssigneeError:false});
  }

  handleChangeStatus = ({target}) =>
  {
    this.setState({ [target.name] : target.value, massStatusError:false})
    
  }
  
  showAssigneePopup = ()=>
  {
    this.setState({showAssigneeChange: !this.state.showAssigneeChange})
  }

  showDueDatePopup = ()=>
  {
    this.setState({showDueDateChange: !this.state.showDueDateChange})
  }


  showStatusPopup = () =>
  {
    this.setState({showStatusChange: !this.state.showStatusChange})
  }

  addMassTicketAssignee = () =>
  {
    if(this.state.tassignees.length===0)
    {
      this.setState({massAssigneeError:true});
      return false;
    }else
    {
    this.setState({assigmentProgress:true});
    let tickets = this.state.ticketsArray;
      let assigneess = this.state.tassignees;
      var ticketscount = this.state.ticketsArray.length;
      var ti=1;
      tickets.forEach(p1 => {
        let opts ={
          ticket_id: p1,
          assginees:assigneess
        }
        fetch(urls.updateTicketAssignees,{
          headers:{
            'Access-Control-Allow-origin':'*',
            'Content-Type':'application/json'
          },
          method: "post",
              body: JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        { 
          ti++;
          if(ti>= ticketscount)
          {
            var x = document.getElementsByClassName("mass-checkbox");
              for(var i=0; i<x.length; i++) {
                x[i].checked = false;
              }   
            this.setState({
              assigmentProgress:false, 
              showAssigneeChange:false,
              ticketsArray:[],
              tassignees:[]
            })
            window.location.reload();
          }
        })
        .catch(error =>
        {
          ti++;
        })
      });
    }
  }

  updateMassTicketStatus = () =>
  {
    if(this.state.massticketStat =='')
    {
      this.setState({massStatusError:true});
      return false;
    }else
    {
    this.setState({assigmentProgress:true});
    let tickets = this.state.ticketsArray;
      var ticketscount = this.state.ticketsArray.length;
      var ti=1;
      tickets.forEach(p1 => {
        let opts ={
          ticket_id: p1,
          status: this.state.massticketStat,
          user_id: this.state.myId
        }
        fetch(urls.updateTicketStatus,{
          headers:{
            'Access-Control-Allow-origin':'*',
            'Content-Type':'application/json'
          },
          method: "post",
              body: JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        { 
          ti++;
          if(ti>= ticketscount)
          {
            var x = document.getElementsByClassName("mass-checkbox");
              for(var i=0; i<x.length; i++) {
                x[i].checked = false;
              }   
            this.setState({
              assigmentProgress:false, 
              showAssigneeChange:false,
              ticketsArray:[],
              tassignees:[]
            })
            window.location.reload();
          }
        })
        .catch(error =>
        {
          ti++;
          
        })
      });
    }
  }


  updateMassTicketDueDate = () =>
  {
    if(this.state.dueDate =='')
    {
      this.setState({massDueError:true});
      return false;
    }else
    {
    this.setState({assigmentProgress:true});
    let tickets = this.state.ticketsArray;
      var ticketscount = this.state.ticketsArray.length;
      var ti=1;
      tickets.forEach(p1 => {
        let opts ={
          ticket_id: p1,
          due_date: this.state.dueDate,
          user_id: this.state.myId
        }
        fetch(urls.updateTicketDueDate,{
          headers:{
            'Access-Control-Allow-origin':'*',
            'Content-Type':'application/json'
          },
          method: "post",
              body: JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        { 
          ti++;
          if(ti>= ticketscount)
          {
            var x = document.getElementsByClassName("mass-checkbox");
              for(var i=0; i<x.length; i++) {
                x[i].checked = false;
              }   
            this.setState({
              assigmentProgress:false, 
              showAssigneeChange:false,
              ticketsArray:[],
              tassignees:[]
            })
           window.location.reload();
          }
        })
        .catch(error =>
        {
          ti++;
          
        })
      });
    }
  }




  settingAssignee = ()=>
  {
      localStorage.setItem('assignedOnlyMe', 1);
      localStorage.setItem('assignedTo', this.state.myId);
      localStorage.setItem('selectedAssigneeID',this.state.myId);
      localStorage.setItem('selectedAssigneeName',this.state.myName);

      let sArray=[];
      let opts ={
        name: this.state.myName, 
        id: this.state.myId
      }
      sArray.push(opts);
      this.setState({
        selectedAssigneeArray:sArray,
        assignedTo: this.state.myId,
        assingedOnlyMe:1
      });
  }

  assignedOnlyMe = (e)=>
  {
    if(e.target.checked)
    {
      this.settingAssignee();
    }else
    {
      if(this.state.pendingAtMyEnd == 0)
      {
        this.setState({assignedTo:'', assingedOnlyMe:'', selectedAssigneeArray:[]});
        localStorage.setItem('assignedOnlyMe', 0);
        localStorage.setItem('assignedTo', '');
        localStorage.setItem('selectedAssigneeID','');
        localStorage.setItem('selectedAssigneeName','');
      }else
      {
        this.setState({assignedTo:'',assingedOnlyMe:'',  selectedAssigneeArray:[]});
        localStorage.setItem('assignedOnlyMe', 0);
        localStorage.setItem('assignedTo', '');
        localStorage.setItem('selectedAssigneeID','');
        localStorage.setItem('selectedAssigneeName','');
      } 
    }
  }

  onMeDueToday = () =>
  {
    this.setState({
      dueonMeToday: this.state.dueonMeToday == 1 ? 0 : 1,
      dueonMe: 0,
    })
    const _this = this;
    setTimeout(()=>
    {
      if(_this.state.dueonMeToday == 1)
      {
        _this.setState({
          assingedOnlyMe:1
        });
        _this.settingAssignee();
        localStorage.setItem('dueonMeToday',1);
        localStorage.setItem('dueonMe',0);
      }else{
        localStorage.setItem('dueonMeToday',0);
      }
    }, 200);
  }

  onMeDue = () =>
  {
    this.setState({
      dueonMe: this.state.dueonMe == 1 ? 0 : 1,
      dueonMeToday:0
    })
    const _this = this;
    setTimeout(()=>
    {
      if(_this.state.dueonMe === 1)
      {
        _this.setState({
          assingedOnlyMe:1
        })
        _this.settingAssignee();
        localStorage.setItem('dueonMeToday',0);
        localStorage.setItem('dueonMe',1);
      }else
      {
        localStorage.setItem('dueonMe',0);
      }
     
    }, 200);
    
  }

  pendingMyEnd = (e)=>
  {
    if(e.target.checked)
    {
      this.setState({assignedTo:this.state.myId, pendingAtMyEnd:1});
      localStorage.setItem('pendingAtMyEnd', 1);
      localStorage.setItem('assignedTo', this.state.myId);
    }else
    {
      
      if(this.state.assingedOnlyMe == '')
      {
        this.setState({assignedTo:'', pendingAtMyEnd:0});
        localStorage.setItem('pendingAtMyEnd', 0);
        localStorage.setItem('assignedTo', '');
      }else
      {
        this.setState({pendingAtMyEnd:0});
        localStorage.setItem('pendingAtMyEnd', 0);
      }
    }
  }
  


  render() {
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active tickets-lists': 'top-bar-inactive tickets-lists')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props}  className="tick-timer-header" 
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
         <div className="SideNavbar">
           <SideMenu  
            activeitem="tickets" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
        </div>
       <div className="themeContentBody themefullwidthContentBoday theme_active_header">
          <Row> 
            <Col sm={12} md={12}>
            <Card className="themeCard log_timer_card">
                <div className="ticket_section_header">
                    <h1>Tickets</h1>
                    <div className="back_btns">
                      <Button onClick={this.showAdvancedFilter} className="filter-icon"><MaterialIcon icon="filter_alt"/></Button>
                      <Button className="btn btn-green add_ticket_btn" onClick={this.showTicketCreation}><span>Create New Ticket </span><MaterialIcon icon="add"/></Button>
                      <Button className="backbtn" onClick={this.showDashboard}><MaterialIcon icon="home"/></Button>
                    </div>
                </div>
              <section id="ticket_details" className="tickets-listing">
                <Card>

                      <Row className="ticket_filter_header">
                         <Col xs={12} sm={11} md={12}>
                         {this.state.ticketsArray.length > 0 ? 
                              (
                              <div className="d-flex ticket-action-buttons">
                                <span className="btn btn-secondary btn-grey" onClick={this.showAssigneePopup}>Change Assignee</span>
                                <span className="btn btn-secondary btn-grey" onClick={this.showDueDatePopup}>Change Due Date</span>
                                <span className="btn btn-secondary btn-grey" onClick={this.showStatusPopup}>Change Status</span>
                              </div>
                              ):
                            (
                              <>
                              <Row>
                                <Col xs={12} sm={3} md={2}>
                                  <Input type="text" name="ticketID" value={this.state.ticketID !== null? this.state.ticketID : '' }
																		onChange={this.handleChange} className="ticket-id-field"  placeholder="Ticket Number"
																		/>
                                  </Col>
                                  <Col xs={12} sm={3} md={3}>
                                    {this.state.filterClients.length>0 &&
                                      (
                                        <Typeahead
                                            id="user-picker"
                                            labelKey="client_name"
                                            onChange={this.onChangeClient}
                                            options={this.state.filterClients}
                                            placeholder="Client name"
                                            selected={this.state.selectedClientArray}
                                            />
                                      )
                                    }
                                  </Col>
                                  <Col xs={12} sm={3} md={2}>
                                    <Input type="select" className="dropfilt" name="relatedTo" onChange={this.handleChange}>
                                        <option value="">Related To</option>
                                        {(this.state.filterTopics || []).map(topics => (
                                          <option selected={this.state.relatedTo == topics.id? 'selected': ''} value={topics.id}>{topics.topic_name}</option>
                                        ))}
                                    </Input>
                                  </Col>
                                  <Col xs={12} sm={3} md={2}>
                                    <Input type="select" className="dropfilt" name="ticketStat" onChange={this.handleChange}>
                                        <option value="">Status</option>
                                        {(this.state.filterStatus || []).map(stat => (
                                          <option selected={this.state.ticketStat == stat.id? 'selected': ''}  value={stat.id}>{stat.status}</option>
                                        ))}
                                    </Input>
                                  </Col>
                                  <Col xs={12} sm={3} md={2}>
                                  <Input type="select" className="dropfilt" name="sortBy" onChange={this.handleChange}>
                                      <option value="">Sort By</option>
                                      <option value="due_date asc" selected={this.state.sortBy == 'due_date asc' ? 'selected': ''}>Due Date Ascending</option>
                                      <option value="due_date desc" selected={this.state.sortBy == 'due_date desc' ? 'selected': ''}>Due Date Descending</option>
                                      <option value="created_on asc" selected={this.state.sortBy == 'created_on asc' ? 'selected': ''}>Created Date Ascending</option>
                                      <option value="created_on desc" selected={this.state.sortBy == 'created_on desc' ? 'selected': ''}>Created Date Descending</option>
                                      <option value="updated_on asc" selected={this.state.sortBy == 'updated_on asc' ? 'selected': ''}>Updated Date Ascending</option>
                                      <option value="updated_on desc" selected={this.state.sortBy == 'updated_on desc' ? 'selected': ''}>Updated Date Descending</option>
                                      {this.state.ticketStat == 8 &&
                                      (
                                        <>
                                          <option value="resolved_on asc" selected={this.state.sortBy == 'resolved_on asc' ? 'selected': ''}>Resolved Date Ascending</option>
                                          <option value="resolved_on desc" selected={this.state.sortBy == 'resolved_on desc' ? 'selected': ''}>Resolved Date Descending</option>
                                        </>
                                      )}
                                      <option value="client_name asc" selected={this.state.sortBy == 'client_name asc' ? 'selected': ''}>Client Name Ascending</option>
                                      <option value="client_name desc" selected={this.state.sortBy == 'client_name desc' ? 'selected': ''} >Client Name Descending</option>
                                  </Input>
                                  </Col>
                                  <Col xs={12} sm={3} md={1}><Searchtickets classprop="search_but_filt" searchaction={this.filterTickets} /></Col>
                              </Row>
                              
                                  
                              </>
                            )
                          }
                         </Col>
                       </Row>

                       



                        <div className="cmd_viewbox_ticket_list">
                            <div className="list_head ticket_lits_head ">
                                <Row>
                                    <Col sm="4" ><div className="head_text">
                                    {this.state.myPrivileges.includes('multiple_ticket_assignment') &&
                                    (
                                      <Input  type="checkbox" className="mass-checkbox"  
                                      checked={this.state.allSelected && 'checked'}
                                      onClick={this.handleAllClick.bind(this)} />
                                    )}
                                    <span className='tick-subject'>Subject</span></div></Col>
                                    <Col sm="3"><div className="head_text">Client</div></Col>
                                    <Col sm="5">
                                      <Row>
                                        <Col sm="3"><div className="head_text">Created By</div></Col>
                                        <Col sm="3"><div className="head_text">Assigned To</div></Col>
                                        <Col sm="3"><div className="head_text">Due On</div></Col>
                                        <Col sm="3"><div className="head_text">Status</div></Col>
                                      </Row>
                                    </Col>
                                   
                                </Row>
                            </div>

                            <Scrollbars style={{ height: '100vh' }} >
                            <LoadingWrapper isLoading={this.state.showProgress}>
                            {this.state.allTickets.length>0 ?
                            (
                                <div id="list" className="ticket-items">
                                    {this.state.allTickets.map((ticket, index)=>
                                    {
                                        return(
                                          <div className="list_row">
                                            <Row className="ticket-list-row">
                                                <Col sm="4">
                                                  <div className="d-flex">
                                                    {this.state.myPrivileges.includes('multiple_ticket_assignment') &&
                                                    (
                                                      <Input  type="checkbox" className="mass-checkbox" value={ticket.id} 
                                                      checked={this.state.ticketsArray.includes(ticket.id) ? 'checked' : ''}
                                                      onClick={this.handleClick.bind(this)} />
                                                    )}
                                                    <div className="tlist_text subject_text" onClick={this.showTicketDetail.bind(this, ticket.id)}>{ticket.posted_date+" - "+ticket.subject}</div>
                                                  </div>
                                                  </Col>
                                                <Col sm="3"><div className="tlist_text" onClick={this.showTicketDetail.bind(this, ticket.id)}>{ticket.client_name}</div></Col>
                                                <Col sm="5">
                                                  <Row>
                                                    <Col sm="3"><div className="tlist_text" onClick={this.showTicketDetail.bind(this, ticket.id)}>{ticket.created_by}</div></Col>
                                                    <Col sm="3"><div className="tlist_text" onClick={this.showTicketDetail.bind(this, ticket.id)}>{ticket.assigned_to}</div></Col>
                                                    <Col sm="3"><div className="tlist_text" >{ticket.due_date}</div></Col>
                                                    <Col sm="3"><div className={ticket.tickstatus.replace(/ +/g, "-").toLowerCase()+" tlist_text"} onClick={this.showTicketDetail.bind(this, ticket.id)}>{ticket.tickstatus}</div></Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </div>
                                        )
                                    })}
                                </div>
                            )
                            :
                            (
                              <Row>
                                  <Col sm="12" className="no-records-found text-center">{this.state.noRecordMsg}</Col>
                              </Row>
                            )
                            }
                            <LoadingWrapper isLoading={this.state.moreLoadingProgress}></LoadingWrapper>
                            </LoadingWrapper>
                            </Scrollbars>
                        </div>
                  <div id="st1" onClick={this.scrollUp}></div> 
                </Card>
                <div className="ticket_result">
                {this.state.showFilters && 
                  (
                   
                    <>
                    <div className="filter_sidebar_overlay" onClick={this.closeAdvancedFilter}></div>
                    <Scrollbars style={{ height: '100vh' }}>
                    <div className="filter_drop_wrap_sidebar">
                    
                    <Button className="close_btn" onClick={this.closeAdvancedFilter}><MaterialIcon icon="close"/></Button>
                  
                    <span className="seperator-text">Filter & Sort Options</span>
                    <Row>
                      <Col sm="6">
                          <Input type="select" className="dropfilt" name="sortBy" onChange={this.handleChange}>
                              <option value="">Sort By</option>
                              <option value="due_date asc" selected={this.state.sortBy == 'due_date asc' ? 'selected': ''}>Due Date Ascending</option>
                              <option value="due_date desc" selected={this.state.sortBy == 'due_date desc' ? 'selected': ''}>Due Date Descending</option>
                              <option value="created_on asc" selected={this.state.sortBy == 'created_on asc' ? 'selected': ''}>Created Date Ascending</option>
                              <option value="created_on desc" selected={this.state.sortBy == 'created_on desc' ? 'selected': ''}>Created Date Descending</option>
                              <option value="updated_on asc" selected={this.state.sortBy == 'updated_on asc' ? 'selected': ''}>Updated Date Ascending</option>
                              <option value="updated_on desc" selected={this.state.sortBy == 'updated_on desc' ? 'selected': ''}>Updated Date Descending</option>
                              {this.state.ticketStat == 8 &&
                              (
                                <>
                                  <option value="resolved_on asc" selected={this.state.sortBy == 'resolved_on asc' ? 'selected': ''}>Resolved Date Ascending</option>
                                  <option value="resolved_on desc" selected={this.state.sortBy == 'resolved_on desc' ? 'selected': ''}>Resolved Date Descending</option>
                                </>
                              )}
                              <option value="client_name asc" selected={this.state.sortBy == 'client_name asc' ? 'selected': ''}>Client Name Ascending</option>
                              <option value="client_name desc" selected={this.state.sortBy == 'client_name desc' ? 'selected': ''} >Client Name Descending</option>
                          </Input>
                          
                          {(this.state.myPrivileges.includes('co_founder_only') || this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('unassigned_ticket_access')) &&
                          (
                          <Typeahead
                            id="assignedTo"
                            labelKey="name"
                            onChange={this.onChangeAssigneeTo}
                            options={this.state.filterAssignees}
                            placeholder="Assigned To"
                            selected={this.state.selectedAssigneeArray}
                            />

                          )}
                           <Input type="select" className="dropfilt" name="relatedTo" onChange={this.handleChange}>
                          <option value="">Related To</option>
                          {(this.state.filterTopics || []).map(topics => (
                            <option selected={this.state.relatedTo == topics.id? 'selected': ''} value={topics.id}>{topics.topic_name}</option>
                          ))}
                          </Input>
                         
                          
                        <Input type="select" className="dropfilt" name="createdBy" onChange={this.handleChange}>
                            <option value="">Created By</option>
                            {(this.state.filterCreatedFor || []).map(creator => (
                              <option selected={this.state.createdBy == creator.id? 'selected': ''} value={creator.id}>{creator.name}</option>
                            ))}
                        </Input>

                      </Col>
                      <Col sm="6">
                        <Input type="select" className="dropfilt" name="ticketStat" onChange={this.handleChange}>
                            <option value="">Status</option>
                            {(this.state.filterStatus || []).map(stat => (
                              <option selected={this.state.ticketStat == stat.id? 'selected': ''}  value={stat.id}>{stat.status}</option>
                            ))}
                        </Input>
                        {this.state.filterClients.length>0 &&
                            (
                              <Typeahead
                                id="user-picker"
                                labelKey="client_name"
                                onChange={this.onChangeClient}
                                options={this.state.filterClients}
                                placeholder="Client name"
                                selected={this.state.selectedClientArray}
                                />
                              )
                          }
                         
                          
                          <Typeahead
                            id="responsibleBy"
                            labelKey="name"
                            
                            onChange={this.onChangeResponsible}
                            options={this.state.orginalUsers}
                            placeholder="Responsible By"
                            selected={this.state.selectedResponsibleArray}
                          />

                          <ReactTags
                            ref={this.reactTags}
                            tags={this.state.setTags}
                            suggestions={this.state.filterTags}
                            onDelete={this.onDeleteTag.bind(this)}
                            onAddition={this.onAdditionTag.bind(this)}
                            placeholderText="Labels" />

                      </Col>


                   

                        <div className="date_choosefilter">


 
                        <div className="label-field picker-field">
                            <Label>Created On</Label>
                            <DateTimePicker calendarIcon={ <img className="calender-icon"
                              src={CalenderIcon} width="12" height="12" alt="CalenderIcon" /> }
                              value={this.state.createdDate} onChange={this.onCdateChange}
                              format="dd-MM-y"
                            />
                          </div>
                        <div className="label-field picker-field">
                          <Label>Updated On</Label>
                          <DateTimePicker calendarIcon={ <img className="calender-icon"
                            src={CalenderIcon} width="12" height="12" alt="CalenderIcon" /> }
                            value={this.state.updatedDate} onChange={this.onUdateChange}
                            format="dd-MM-y"
                          />
                        </div>
                        {this.state.ticketStat == 8 &&
                        (
                          <div className="label-field picker-field">
                          <Label>Resolved On</Label>
                          <DateTimePicker calendarIcon={ <img className="calender-icon"
                            src={CalenderIcon} width="12" height="12" alt="CalenderIcon" /> }
                            value={this.state.resolvedDate} onChange={this.onRdateChange}
                            format="dd-MM-y"
                          />
                        </div>
                      )}

                        </div>

                      <Col xs={12} md={12}>
                          <div className="assigned-only d-flex">
                              <Input type="checkbox" id="assigned" value="1" onClick={this.assignedOnlyMe} checked={this.state.assingedOnlyMe == 1? 'checked':''} />
                              <Label for="assigned">Assigned Only To Me</Label>
                          </div>
                          <div className="assigned-only d-flex ">
                              <Input type="checkbox" id="duetoday" value="1" onClick={this.onMeDueToday} checked={this.state.dueonMeToday == 1? 'checked':''} />
                              <Label for="duetoday">Due Today At My End</Label>
                          </div>
                          <div className="assigned-only d-flex ">
                              <Input type="checkbox" id="duemyend" value="1" onClick={this.onMeDue} checked={this.state.dueonMe == 1? 'checked':''} />
                              <Label for="duemyend">Due At My End</Label>
                          </div>
                          <div className="assigned-only d-flex ">
                              <Input type="checkbox" id="ticketpendingreminder" value="1" onClick={this.pendingMyEnd} checked={this.state.pendingAtMyEnd == 1? 'checked':''} />
                              <Label for="ticketpendingreminder">Tickets Pending With Reminder</Label>
                          </div>
                          
                      </Col>
                     
                    </Row>
                    
                      <div className="advance-actions">
                        <Button className="btn-grey btn btn-secondary reset_btn" onClick={this.resetFilters}>Reset</Button>
                        <Button className="btn-green btn btn-secondary search_btn_adv" onClick={this.filterTickets.bind(this)} >Search</Button>
                      </div>
                      
                    </div>
                    </Scrollbars>
                    </>
                   
                  )}
                  {this.state.showAssigneeChange &&
                  ( 
                    <div class="assignee-change-popup">
                    <div class="assignee-change-overlay" onClick={this.showAssigneePopup}></div>
                    <div class="assigne-change-wrapper">
                      <h4>Change Assignee</h4>
                      {this.state.assigmentProgress &&
                      (
                        <LoadingWrapper isLoading={this.state.assigmentProgress}></LoadingWrapper>
                      )}
                      {this.state.massAssigneeError &&
                      (
                        <span className="error-text">Please add atleast one assignee</span>
                      )}
                      <ReactTags
                        ref={this.reactTags}
                        tags={this.state.tassignees}
                        suggestions={this.state.filterAssignees}
                        onDelete={this.onDeleteAssignees.bind(this)}
                        onAddition={this.onAdditionAssignees.bind(this)}
                        placeholderText="Add Assignee" />
                        <Button class="btn btn-green" onClick={this.addMassTicketAssignee.bind(this)}>Update</Button>
                      </div>
                  </div>
                  )}
                  {this.state.showDueDateChange &&
                  ( 
                    <div class="assignee-change-popup">
                    <div class="assignee-change-overlay" onClick={this.showDueDatePopup}></div>
                    <div class="assigne-change-wrapper">
                      <h4>Change Due Date</h4>
                      {this.state.massDueError &&
                      (
                        <span className="error-text">Please choose the Date</span>
                      )}
                      {this.state.assigmentProgress &&
                      (
                        <LoadingWrapper isLoading={this.state.assigmentProgress}></LoadingWrapper>
                      )}
                        <DateTimePicker
                            minDate ={this.state.minDate}
                            calendarIcon={
                            <img
                            className="calender-icon"
                            src={CalenderIcon}
                            width="12"
                            height="12"
                            alt="CalenderIcon"
                            />
                            }
                            clearIcon={
                              <img
                              className="calender-icon"
                              src={ClearIcon}
                              width="20"
                              height="20"
                              alt="CalenderIcon"
                              />
                              }
                            name='ticketDate'
                            onChange={this.onTDuedateChange}
                            value={this.state.dueDate}
                            format="dd-MM-y"
                          />
                        <Button class="btn btn-green" onClick={this.updateMassTicketDueDate.bind(this)}>Update</Button>
                      </div>
                  </div>
                  )}
                  {this.state.showStatusChange &&
                  ( 
                    <div class="assignee-change-popup">
                    <div class="assignee-change-overlay" onClick={this.showStatusPopup}></div>
                    <div class="assigne-change-wrapper">
                      <h4>Change Status</h4>
                      {this.state.massStatusError &&
                      (
                        <span className="error-text">Please choose the status</span>
                      )}
                      {this.state.assigmentProgress &&
                      (
                        <LoadingWrapper isLoading={this.state.assigmentProgress}></LoadingWrapper>
                      )}
                        <Input type="select" className="dropfilt" name="massticketStat" onChange={this.handleChange}>
                            <option value="">Status</option>
                            {(this.state.filterStatus || []).map(stat => (
                              <option selected={this.state.massticketStat == stat.id? 'selected': ''}  value={stat.id}>{stat.status}</option>
                            ))}
                        </Input>
                        <Button class="btn btn-green" onClick={this.updateMassTicketStatus.bind(this)}>Update</Button>
                      </div>
                  </div>
                  )}
                </div>
              </section>
          </Card>

            </Col>
          </Row>
       </div>
      </Row>
      </Container>
    
      </div>
    )
  }
}
