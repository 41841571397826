import React, { Component, Fragment } from "react";
import "./LoanApply.css";
import { InputGroup, Input, Label, Card, Button } from "reactstrap";
import DateTimePicker from "react-datetime-picker";
import CalanderIcon from "../../../images/date-icon.png";
import {urls} from "../../../services/UrlList";
import LoadingBox from "../../LoadingBox/LoadingBox"
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper"

export default class LoanApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOnePay: new Date(),
      dateRepayS: new Date(),
      dateRepayE: new Date(),
      paymentType:1,
      installment:'',
      amount:'',
      reason:'',
      loanApplied:false,
      loanAppliedMsg: 'Loan Applied successfully. Please wait for Admin approval.',
      progressBar:false,
      errors:[],
    };
  }

   componentDidUpdate(prevState) {  
    if (prevState.loanApplied !== this.state.loanApplied  && this.state.loanApplied === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({loanApplied:false});
        _this.props.hideaction();
      }, 3000);  
    } 
  }
  onChangeOnePay = e => {
    this.setState({ dateOnePay: e });
  };

  onChangeRepayS = e => {
    this.setState({ dateRepayS: e });
  };

   onChangeRepayE = e => {
    this.setState({ dateRepayE: e });
  };

  setPaymentType = (e) =>{
    this.setState({
      paymentType: e.target.value
    });
  }

  postApplyLoan = () =>
  { 
    if(this.state.amount < 0 || this.state.amount === '')
    {
       this.setState(previousState => ({
            errors: [...previousState.errors, 'amount']
        }));
        return false;
    }else if( this.state.reason ==='')
    {
        this.setState(previousState => ({
            errors: [...previousState.errors, 'reason']
        }));
        return false;
    }else
    {
        this.setState({progressBar:true});
        let opts = {
          user_id: this.props.myid,
          loan_amount: this.state.amount,
          repay_type: this.state.paymentType,
          one_time_pay: this.state.dateOnePay,
          installments: this.state.installment,
          ins_start: this.state.dateRepayS,
          ins_end: this.state.dateRepayE,
          loan_reason: this.state.reason,
        };    
        fetch(urls.postApplyLoan,
        {
          headers:
          {
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {
           this.setState({
              progressBar:true,
              loanApplied:true,
              amount:'',
              paymentType:'',
              dateOnePay:'',
              installment:'',
              dateRepayS:'',
              dateRepayE:'',
              reason:'',
           });
        })
        .catch((error) =>
        {
            this.setState({        
                progressBar:false,
              });
        })
    }
  }

  render() {
    return (
      <Card id="apply-loan" className="loan-module">
        {/* <h5 className="title text-center">Apply Loan</h5> */}
        {this.state.loanApplied && (<div className="loan-applied">{this.state.loanAppliedMsg}</div>) }
        <LoadingWrapper isLoading={this.state.progressBar}></LoadingWrapper>
        <div className="form-body">
          <div className="row-1 d-flex">
            <InputGroup className="loan-amount">
              <Label htmlFor="loan-apply-amount">Loan Amount</Label>
              <Input id="loan-apply-amount" type="text" placeholder="" value={this.state.amount} onChange={(e) => this.setState({amount:e.target.value, errors:[]})}  className={this.state.errors.includes('amount')? 'error-input':''} />
            </InputGroup>
          </div>                  
          <div className="loan-apply-reason">
            <Label htmlFor="loan-apply-reason">Reason</Label>
            <Input
              id="loan-apply-reason"
              type="textarea"
              placeholder="Purpose of taking loan.."
              onChange={(e) => this.setState({reason:e.target.value, errors:[]})}
              className={this.state.errors.includes('reason')? 'error-input':''}
            />
          </div>
          <div className="loan-apply-button">
            <Button id="leave-apply-btn" className="btn btn-green" onClick={this.postApplyLoan.bind(this)} >
              Apply
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}
