import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import BreakCard from "./BreakCard/BreakCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";
import {urls} from "../../../services/UrlList";

export default class Break extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',        
      isMobileView:0,
      breakRequests:[],
      requestPending:0,
      progressBar:true,
    };
  }

  componentDidMount =() =>
  {    
    this.checkUserPrivilege();
    this.getBreakRequest();
    const _this = this;
    this.interval = setInterval(() => _this.getBreakRequest(),60000); 
  };

  componentWillMount = () =>
  {
    this.getBreakRequest();
  }

  componentWillUnmount = () =>{         
    clearInterval(this.interval);  
  }

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges, myId:login.uid});
    if(!login.privileges.includes('approve_user_requests'))
    {
      this.props.history.push("/tick-report");
    }
   
  };

  async getBreakRequest()
  {  
    console.log("qiota");  
    fetch(urls.breakApprovalRequest,{
        headers:
        {
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
        }
    })
    .then(response => response.json())
    .then(response =>
    {   
      console.log(response);        
        this.setState({breakRequests: response[0].request_list,progressBar:false});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(response[0].request_list);
          _this.props.onbreakfuncall(_this.state.requestPending);
        }, 100); 
    })
    .catch(error =>
    {
        this.setState({progressBar:false});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(_this.state.breakRequests);
          _this.props.onbreakfuncall(_this.state.requestPending);
        }, 100); 
    })
  };

  countPendingRequest = (response) =>
  {
    let temp = response;
    var count = 0;
    temp.forEach(p1 =>
      {
        if(p1.status === 1)
        {
          count++;
        }
      })
    this.setState({requestPending:count});
  }
 
  breakApproveAction = (id) =>
  {
    this.setState({progressBar:true});
    let opts ={
        user_id:id,
        approver_id:this.state.myId
    };
    fetch(urls.breakApprove,{
       headers:{
           'Access-Control-Allow-origin':'*',
           'Content-Type':'application/json'
       },
       method:'post',
       body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {
        this.getBreakRequest();
        this.setState({progressBar:false});
    })
    .catch(error =>
    {
        this.setState({progressBar:false}); 
    })    
  };

  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <Fragment>
        <ApprovalModules classname="appr-pending" id="break-module" title="Break Over Quota">
          {/*<Slider {...settings}>*/}
          {
          this.state.breakRequests.map((item, index)=>
          {return(
              <BreakCard approved={item.status === 0? "true": ''} key={index} breakitem={item} onbreakapproval={this.breakApproveAction} />
            )
          })}            
          {/*</Slider>*/}
        </ApprovalModules>
      </Fragment>
    );
  }
}
