import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import { urls } from "../../services/UrlList";
import { Container, Row, Col, Input, Button, Label, Breadcrumb, FormGroup, BreadcrumbItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import axios from 'axios';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import PeopleTags from "./PeopleTag";
import toast, { Toaster } from 'react-hot-toast';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import Linkify from 'linkifyjs/react';
import ReactHtmlParser from 'react-html-parser';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import TextareaAutosize from 'react-autosize-textarea';
import MaterialIcon from "material-icons-react";

const notifyIssueClear=()=> toast('You can not unmark an issues unless you remove all responsible team members');
const notifyIssueCreated=()=> toast('Issue has been reported successfully.');

export default class TaskIssueReporting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            taskUpdateProgress: false,
            taskIssueTypes: [],
            taskIssueLogs: [],
            issueLogLoader: false,
            showIssueLog: false,
            issueComment:'',
            activeItemSet:false,
            activeItem:'',
            selectedTags:[],
            responsibleError:false,
            commentError:false,
            postProgress:false,
            issueLogLoaderIndividual:false,
            commentRequired:false,
            showIssueReporting:false,
            logCounts:[]
        }
    }

    componentDidMount = () => {
        AccessCheck('access_projects_management_module');
        //this.getTaskIssueTypes(this.props.taskid);
    };

    getTaskIssueTypes = (taskid) => {
        const data = {
            task_id: taskid
        };
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.taskIssueTypes, data, {
            headers: headers
        })
            .then((response) => {

                this.issueCounter(response.data.issue_items, response.data.logcount);
                this.setState({
                    issueLogLoader:false,
                    logCounts: response.data.success == 1 ? response.data.logcount : [],
                    showIssueReporting:!this.state.showIssueReporting,
                    //taskIssueTypes: response.data.success == 1 ? response.data.issue_items : [],
                   
                })
            })
            .catch((error) => {
                //notifyError();
            })
    }


    issueCounter = (issues, counts) =>
    {
      let temp=[];
      issues.forEach(p1 => {
           var itemkey = counts.map(function (item) { return item.issue_id; }).indexOf(p1.id);
           if(itemkey != -1)
           {
            p1.logcount = counts[itemkey].count;
           }else
           {
            p1.logcount = 0;
           }
           temp.push(p1);
        });
        this.setState({taskIssueTypes: temp});
      return;
    }


    getTaskIssueLogs = () => {
        this.setState({
            issueLogLoader: true
        })
        const data = {
            task_id: this.props.taskid
        };
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.getTaskIssueLogs, data, {
            headers: headers
        })
        .then((response) => {
            this.setState({
                taskIssueLogs: response.data.logs,
                taskIssueLogsold: response.data.old_logs,
                issueLogLoader: false,
                showIssueLog: true
            })
        })
        .catch((error) => {
            this.setState({
                issueLogLoader: false
            })
        })
    }

    closeIssueLogs = () => {
        this.setState({
            showIssueLog: false
        })
    }

    handleIssueChangeold = (e, issueitem) => {
        // let issueItems = [...this.state.taskIssueTypes];
        // let difference = [];
        // var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueitem.id);
        if (e.target.checked) {
            // issueItems[itemkey].issue_id = issueitem.id;
            // this.setState({ taskIssueTypes: issueItems });
            // this.updateTaskIssues(1, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, 0);
            this.setState({ activeItem:  issueitem.id, activeItemSet:true });
        } else {
            // if (issueItems[itemkey].responsible_persons != null && issueItems[itemkey].responsible_persons.length == 0) {
            //     issueItems[itemkey].issue_id = null;
            //     this.setState({ taskIssueTypes: issueItems });
            //     this.updateTaskIssues(0, issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, 0);
            // } else {
            //     notifyIssueClear();
            // }
            this.setState({ activeItem:  '', activeItemSet:false});
        }
    }

    handleIssueChange = (e, issueitem) => {
        if (e.target.checked) {
            this.setState({ 
                activeItem:  issueitem.id, 
                activeItemSet:true, 
                issueLogLoaderIndividual: true,
                commentRequired: (issueitem.comment_needed === 1? true: false)
            });
           
            const data = {
                task_id: this.props.taskid,
                issue_id:issueitem.id
            };
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-origin': '*',
            }
            axios.post(urls.getTaskIssueLogsIndividual, data, {
                headers: headers
            })
            .then((response) => {
                this.setState({
                    taskIssueLogsInd: response.data.logs,
                    taskIssueLogsoldInd: response.data.old_logs,
                    issueLogLoaderIndividual: false
                })
            })
            .catch((error) => {
                this.setState({issueLogLoaderIndividual: false})
            })

        } else {
           this.setState({ activeItem:  '', 
            taskIssueLogsInd: [],
            taskIssueLogsoldInd: [],
            issueLogLoaderIndividual: false,
            activeItemSet:false,
            commentRequired:false});
        }
    }

    updateTaskIssues = (type, issueid, responsible, difference, mthd) => {
        const data = {
            task_id: this.props.taskid,
            issue_id: issueid,
            responsible_persons: (responsible === null || responsible == '') ? [] : responsible,
            difference: difference,
            type: type,
            mthd: mthd
        };
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.addTaskIssues, data, {
            headers: headers
        })
            .then((response) => {
                //console.log(response);
            })
            .catch((error) => {
                //notifyError();
            })
    };

    updateTaskIssuesNew = async () => {
    
        if(this.state.selectedTags.length==0)
        {
            this.setState({responsibleError:true});
            return false;
        }
        if(this.state.commentRequired && this.state.issueComment =='' )
        {
            this.setState({commentError:true});
            return false;
        }
        this.setState({postProgress:true});
        const data = {
            task_id: this.props.taskid,
            issue_id: this.state.activeItem,
            responsible_persons: this.state.selectedTags,
            difference: '',
            comment: this.state.issueComment,
            type: 1,
            mthd: 0
        };
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        axios.post(urls.addTaskIssues, data, {
            headers: headers
        })
        .then((response) => {
            this.issueCountUpdater(this.state.activeItem);
            this.setState({ activeItem:'', activeItemSet:false, selectedTags:[],issueComment:'', postProgress:false });
            notifyIssueCreated();
        })
        .catch((error) => {
            this.setState({postProgress:false});
        })
    };


    issueCountUpdater =  async (issueid) =>
    {
      let temp=[];
      this.state.taskIssueTypes.forEach(p1 => {
           if(p1.id === issueid)
           {
            p1.logcount++;
           }
           temp.push(p1);
        });
        this.setState({taskIssueTypes: temp});
      return;
    }

    getDifference = (array1, array2) => {
        return array1.filter(object1 => {
            return !array2.some(object2 => {
                return object1.id === object2.id;
            });
        });
    }

    setIssueResponsibles = (tags, issueid) =>
    {
        let issueItems = [...this.state.taskIssueTypes];
        let difference=[];
        let adding = 1;
        if(typeof(tags) === 'object' && tags.length>0)
        {
            var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueid); 
            try {
                var arrr1 = JSON.parse(issueItems[itemkey].responsible_persons);
            } catch (e) {
                var arrr1 = issueItems[itemkey].responsible_persons;
            }
            difference = [
                ...this.getDifference(tags, arrr1),
                ...this.getDifference(arrr1, tags)
            ];
            if(issueItems[itemkey].responsible_persons.length > tags.length)
            {
                adding = 0;
            }
            issueItems[itemkey].responsible_persons = tags;
            this.setState({taskIssueTypes: issueItems});
            this.updateTaskIssues(1,issueItems[itemkey].id, issueItems[itemkey].responsible_persons, difference, adding);
        }else
        {
            var itemkey = issueItems.map(function (item) { return item.id; }).indexOf(issueid); 
            try {
                var arrr1 = JSON.parse(issueItems[itemkey].responsible_persons);
            } catch (e) {
                var arrr1 = issueItems[itemkey].responsible_persons;
            }
            if(arrr1 &&  arrr1!=null && arrr1.length>0)
            {
                difference = [
                ...this.getDifference(tags, arrr1),
                ...this.getDifference(arrr1, tags)
                ];
            }
            issueItems[itemkey].responsible_persons = [];
            this.setState({taskIssueTypes: issueItems});
            this.updateTaskIssues(1,issueItems[itemkey].id, issueItems[itemkey].responsible_persons,difference,0);
        }
    }


    setIssueResponsiblesNew = (tags, issueid) =>
    {
        this.setState({selectedTags:tags, responsibleError:false})
    }
    
    cancelIssueReporting = ()=>
    {
        this.setState({ activeItem:'', activeItemSet:false, selectedTags:[],issueComment:'', commentError:false, responsibleError:false });
    }

    responsibleText = (jsondata)=>
    {
        const _this = this;
        var result = jsondata.map(function(val) {
        if(val.id !== _this.props.myid)  // this condition was added newly
        {
            return val.name;
        }
        }).join(', ');
        return result;
    }

    responsibleCheck = (jsondata)=>
    {
        let result = jsondata.find(item => item.id === this.props.myid);
        
        if(typeof(jsondata.find(item => item.id === this.props.myid)) !== 'undefined')
        {
            return true;  // this was false before 
        }else
        {
            return true;
        }
    }

    stripslashes = (str) => {
        if (str != null) {
          str = str.replace(/\\'/g, '\'');
          str = str.replace(/\\"/g, '"');
          str = str.replace(/\\0/g, '\0');
          str = str.replace(/\\\\/g, '\\');
        }
        return str;
      }

      showIssueReporting = ()=>
      {
        this.setState({issueLogLoader:true});
        this.getTaskIssueTypes(this.props.taskid);
      }



    render() {
        let logindex = -1;
        let oldlogindex = -1;
        let logtitle = '';
        return (
            <Fragment>
                <div className="card task_leftbox qa_checkblock issue-log-outer-wrap">
                    <div className="brand_cmd_sec">
                        <LoadingWrapper isLoading={this.state.taskUpdateProgress}>
                            <div className='d-flex justify-content-between issue-report-head'>
                                <h6>Issue Reporting</h6>
                                {!this.state.showIssueReporting &&
                                (
                                    <>
                                    <Button className='btn btn-task-ticket plus btn-green' onClick={this.showIssueReporting}><MaterialIcon icon="add" /> </Button>
                                    </>
                                )}
                                {(((AccessCheckBlock('i_am_admin')) || (AccessCheckBlock('create_new_project'))) && this.state.showIssueReporting) &&
                                    (
                                        <>
                                            {this.state.showIssueLog ?
                                                (
                                                    <span className='log-icon' onClick={this.closeIssueLogs}>Hide Logs</span>
                                                ) :
                                                (
                                                    <span className='log-icon' onClick={this.getTaskIssueLogs}>Logs</span>
                                                )}
                                        </>
                                    )}
                            </div>
                            <LoadingWrapper isLoading={this.state.issueLogLoader}></LoadingWrapper>
                            {this.state.showIssueLog && this.state.taskIssueLogs && this.state.taskIssueLogs.length > 0 ?
                                (
                                    <>
                                        {this.state.taskIssueLogs.map((logitem, index) => {
                                            logindex = logindex + 1;
                                            return (
                                                <>
                                                {this.responsibleCheck(JSON.parse(logitem.responsible_persons)) &&
                                                (
                                                    <div key={index} className="issuelogs">
                                                        {this.state.taskIssueLogs[logindex].issue_name != logtitle &&
                                                            (
                                                                <div className='log-issue-name'>
                                                                    {logitem.issue_name}
                                                                </div>
                                                            )}
                                                        <div className='dnone'>{logtitle = this.state.taskIssueLogs[logindex].issue_name}</div>
                                                        <div className='d-flex justify-content-between item-log'>
                                                            <span>
                                                                <Linkify className="cmd_editer_preview" options={{ attributes: (href, type) => type === 'url' ? { rel: 'nofollow noopener noreferrer' } : {} }}>{ReactHtmlParser(this.stripslashes(logitem.issue_log) + " ")}</Linkify>
                                                                {logitem.responsible_persons && JSON.parse(logitem.responsible_persons).length>0 &&
                                                                (
                                                                    <span className='responsible-log'><Label>Responsible Persons: </Label>{this.responsibleText(JSON.parse(logitem.responsible_persons))}</span>
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div className='issue-owner d-flex justify-content-between' title={logitem.log_time_full}>
                                                            <span>Added By: {logitem.name}</span>
                                                            <span>{logitem.log_time}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                </>
                                            )

                                        })}

                                        {this.state.taskIssueLogsold && this.state.taskIssueLogsold.length>0 && this.state.taskIssueLogsold.map((logitem, index)=>
                                        {
                                        oldlogindex = oldlogindex+1;
                                        return(
                                            <div key={index} className="issuelogs">
                                            {this.state.taskIssueLogsold[oldlogindex].issue_name != logtitle &&
                                            (
                                                <div className='log-issue-name'>
                                                    {logitem.issue_name}
                                                </div>
                                            )}
                                            <div className='dnone'>{logtitle = this.state.taskIssueLogsold[oldlogindex].issue_name}</div>
                                            <div className='d-flex justify-content-between item-log'>
                                                <span className='old-issue-log-text'>{logitem.issue_log}</span>
                                                <span title={logitem.log_time_full}>{logitem.log_time}</span>
                                            </div>
                                            </div>
                                        )
                                        
                                        })}
                                    </>
                                ) :
                                (
                                    <>
                                        {this.state.taskIssueTypes && this.state.taskIssueTypes.length > 0 &&
                                            (
                                                <>
                                                    {this.state.taskIssueTypes.map((issueitem, index) => {
                                                        let showitem = 0;
                                                        if (issueitem.field_related === 0) {
                                                            showitem = 1;
                                                        } else {
                                                            if (issueitem && issueitem.field_related === 20 &&
                                                                (this.props.stringifiedDescription.Softwares && (this.props.stringifiedDescription.Softwares.includes('Ai') ||
                                                                    this.props.stringifiedDescription.Softwares.includes('INDD')))) {
                                                                showitem = 1;
                                                            } else {
                                                                showitem = 0;
                                                            }
                                                        }
                                                        if (showitem == 1) {
                                                            let responsibles = [];
                                                            if (issueitem.responsible_persons != null) {
                                                                try {
                                                                    responsibles = JSON.parse(issueitem.responsible_persons);
                                                                } catch (e) {
                                                                    responsibles = issueitem.responsible_persons;
                                                                }
                                                            } else {
                                                                responsibles = [];
                                                            }
                                                            return (
                                                                <>

                                                                    <div key={issueitem.id} 
                                                                    className={`qa-check-items d-flex ${this.state.activeItemSet ? (this.state.activeItem === issueitem.id ? 'active-issue': 'inactive-issue-item') : ''}`}>
                                                                        <Input name={'issuetype' + issueitem.id} type="checkbox" id={'issuetype' + issueitem.id}
                                                                            value={issueitem.id}
                                                                            checked={this.state.activeItem === issueitem.id ? 'checked':''}
                                                                            onClick={(e) => this.handleIssueChange(e, issueitem)} />
                                                                        <Label for={'issuetype' + issueitem.id} className="task-issue-type-items d-flex justify-content-between">
                                                                            <span>{issueitem.issue_name}</span>
                                                                            <span>{issueitem.logcount!==0 && "("+issueitem.logcount+")"}</span>
                                                                        </Label>
                                                                        
                                                                    </div>
                                                                    {this.state.activeItem === issueitem.id &&
                                                                        (
                                                                            <>
                                                                            <div className='issue-report-loging'>
                                                                                <div className={`report-people ${this.state.responsibleError?'reportfield-error': ''}`}>
                                                                                <PeopleTags
                                                                                    tagslist={this.props.availableAssignees}
                                                                                    settags={this.setIssueResponsiblesNew}
                                                                                    tags={responsibles}
                                                                                    placeholder="Responsible Team Members"
                                                                                    issueitemid={issueitem.id}
                                                                                    type="issues"
                                                                                />
                                                                                </div>
                                                                                <div className={`report-issue-comment ${this.state.commentError? 'textarea-field-error': ''}`}>
                                                                                    <TextareaAutosize
                                                                                    value={this.state.inspiration} 
                                                                                    placeholder={this.state.commentRequired ? "Comments" :'Comments (Optional)'}
                                                                                    onChange={(e) => this.setState({ issueComment: e.currentTarget.value, commentError:false })}/>
                                                                                </div>
                                                                                {/* <div className='d-flex align-items-center issue-ticket-wrap'>
                                                                                    <Input id="ticket-issue" type="checkbox" name="ticketNeeded" />
                                                                                    <Label for="ticket-issue">Add a ticket for this issue</Label>
                                                                                </div> */}
                                                                                <div className={`d-flex issue-buttons ${this.state.postProgress && ' disable-further'}`}>
                                                                                    <Button className='btn btn-grey add-issue-report' type="button" name="cancel" value="Cancel" onClick={this.cancelIssueReporting}>Cancel</Button>
                                                                                    <Button className='btn btn-green add-issue-report' type="button" name="submit" value="Submit" onClick={this.updateTaskIssuesNew}>Submit</Button>
                                                                                </div>
                                                                            </div>
                                                                            <LoadingWrapper isLoading={this.state.issueLogLoaderIndividual}>
                                                                                {((this.state.taskIssueLogsInd && this.state.taskIssueLogsInd.length>0) ||
                                                                                (this.state.taskIssueLogsoldInd && this.state.taskIssueLogsoldInd.length>0)) &&
                                                                                (
                                                                                    <span className='previous-issue-logs'>Previous Logs</span>
                                                                                )}
                                                                                {this.state.taskIssueLogsInd && this.state.taskIssueLogsInd.length>0 && this.state.taskIssueLogsInd.map((logitem, index) => {
                                                                                    return (
                                                                                        <>
                                                                                        {this.responsibleCheck(JSON.parse(logitem.responsible_persons)) &&
                                                                                        (<div key={index} className="issuelogs">
                                                                                            <div className='d-flex justify-content-between item-log'>
                                                                                                <span>
                                                                                                    {logitem.issue_log}
                                                                                                    {logitem.responsible_persons && JSON.parse(logitem.responsible_persons).length>0 &&
                                                                                                    (
                                                                                                        <span className='responsible-log'><Label>Responsible Persons: </Label>{this.responsibleText(JSON.parse(logitem.responsible_persons))}</span>
                                                                                                    )}
                                                                                                </span>
                                                                                            </div>
                                                                                            <div className='issue-owner d-flex justify-content-between' title={logitem.log_time_full}>
                                                                                                <span>Added By: {logitem.name}</span>
                                                                                                <span>{logitem.log_time}</span>
                                                                                            </div>
                                                                                        </div>)}
                                                                                        </>
                                                                                    )

                                                                                })}

                                                                                {this.state.taskIssueLogsoldInd && this.state.taskIssueLogsoldInd.length>0 && this.state.taskIssueLogsoldInd.map((logitem, index)=>
                                                                                {
                                                                                return(
                                                                                    <div key={index} className="issuelogs">
                                                                                    <div className='d-flex justify-content-between item-log'>
                                                                                        <span className='old-issue-log-text'>{logitem.issue_log}</span>
                                                                                        <span title={logitem.log_time_full}>{logitem.log_time}</span>
                                                                                    </div>
                                                                                    </div>
                                                                                    )
                                                                                })}
                                                                                </LoadingWrapper>
                                                                            </>
                                                                        )}
                                                                </>
                                                            )
                                                        }
                                                    })}
                                                </>
                                            )}
                                    </>
                                )}

                        </LoadingWrapper>
                    </div>
                </div>
            </Fragment>
        )
    }
}