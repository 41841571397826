import React from 'react';

export default function CloseIcon( props ) {
  return (
    <div className="close-icon" onClick={props.closeaction}>
      <svg width="21px" height="21px">
        <path style={{ fillRule:"evenodd", fill: '#434343' }} 
        d="M17.087,2.945 C15.199,1.057 12.687,0.016 10.016,0.016 C7.345,0.016 4.834,1.057 2.945,2.945 C1.057,4.834 0.016,7.345 0.016,10.016 C0.016,12.687 1.057,15.199 2.945,17.087 C4.834,18.976 7.345,20.016 10.016,20.016 C12.687,20.016 15.199,18.976 17.087,17.087 C18.976,15.199 20.016,12.687 20.016,10.016 C20.016,7.345 18.976,4.834 17.087,2.945 ZM12.244,10.200 L14.736,12.692 C14.938,12.893 14.938,13.220 14.736,13.421 L13.297,14.861 C13.200,14.958 13.070,15.011 12.932,15.011 C12.794,15.011 12.665,14.958 12.568,14.861 L10.075,12.368 C10.027,12.320 9.941,12.320 9.893,12.368 L7.400,14.861 C7.303,14.958 7.174,15.011 7.036,15.011 C6.898,15.011 6.768,14.958 6.671,14.861 L5.232,13.421 C5.134,13.324 5.081,13.195 5.081,13.057 C5.081,12.919 5.134,12.789 5.232,12.692 L7.724,10.200 C7.773,10.150 7.773,10.067 7.724,10.017 L5.126,7.419 C5.029,7.322 4.976,7.193 4.976,7.055 C4.976,6.917 5.029,6.787 5.126,6.690 L6.566,5.251 C6.663,5.154 6.792,5.100 6.930,5.100 C7.068,5.100 7.198,5.154 7.295,5.251 L9.893,7.849 C9.941,7.897 10.027,7.897 10.075,7.849 L12.673,5.251 C12.770,5.154 12.899,5.100 13.037,5.100 C13.175,5.100 13.305,5.154 13.402,5.251 L14.842,6.690 C14.939,6.787 14.992,6.917 14.992,7.055 C14.992,7.193 14.939,7.322 14.842,7.420 L12.244,10.017 C12.195,10.067 12.195,10.150 12.244,10.200 Z"/>
        </svg>
    </div>
  )
}