import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';

class UserPickerName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getFullUsers();
  };

   getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: response[0].users,          
        });            
    })
  };


  onChangePeople = (e) => {
    this.setState({ selectedUser:e[0].name});
    setTimeout(() => {
      this.props.onPeopleChange(this.state.selectedUser);
    }, 100);  
  };

  
  
  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].name});
      const _this = this;
      setTimeout(() => {
        _this.props.onPeopleChange(_this.state.selectedUser);
      }, 100);
    }else
    {
      this.setState({ selectedUser:''});
      const _this = this;
      setTimeout(() => {
        _this.props.onPeopleChange(_this.state.selectedUser);
      }, 100);
    }
    
  }

  
  handleInputChange(input, e) {
    console.log("value", input)
  }
  
    render() {
      return (
        <div className="people date-col">
          <Typeahead
          id="user-picker"
          labelKey="name"
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          options={this.state.orginalUsers}
          placeholder="Enter name to filter"
          selected=''
        />
         
        </div>
      );
    }
}

export default UserPickerName;
