import React, { Component } from 'react';
import UserPicker from "./UserPickerDailyLog";
import ProjectPicker from "./ProjectPicker";
import './filter.css';
import { Container, Row, Col, FormGroup, Input, Button } from 'reactstrap';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import 'react-dates/initialize';
import { DateRangePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Typeahead } from 'react-bootstrap-typeahead';
export default class ProjectFilter extends Component {

  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("DD-MM-YYYY");
      
    this.state = {              
      selectedUser:'',
      selectedProject:'',
      startDate:'',
      endDate:'', 
      myPrivileges:[],     
      myId:'', 
      myBaseId:'',
      userTeam:'',
      selectedCount:'Select',      
      showPeopleType:'',
      showAllPeople:0
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

 
onPeopleChange = (userid) =>
{
 this.setState({selectedUser:userid});
}

onProjectChange = (projectid) =>
{
 this.setState({selectedProject:projectid});
}

searchEntriesnew = () =>
{  
  this.setState({filterHour:''}); 
  this.props.searchEntriesone(this.state.selectedProject, this.state.selectedUser, this.state.startDate, this.state.endDate);
}

hoursRender = () =>  {
  const optionsval = [];
  var j = 30;
  for (var i=1; i <=  15; i++) {
    optionsval.push(<option selected={this.state.filterHour == j? 'selected':''} value={j}>{"> "+j+" hours"}</option>);
    j = j +30;
  }
  return optionsval;
};

filterByHours=(e)=>
{ 
  this.setState({filterHour:e.target.value}); 
  this.props.filterbyhour(e.target.value);
};

handleInputChange(input, e) {
  console.log("value", input)
}

handleChange(selectedOptions) {
  console.log(selectedOptions);
}

  render() {

   

    let searchContent = window.innerWidth >= 600 ? <SearchIcon /> : 'Search Log';
    return (
      <div id="filter">
        <Row>
          <Col xs={12} sm={10} lg={11}>
          <Row>
              <Col xs={12} sm={6} lg={3}>
              {this.state.myId!='' &&
              (
                <UserPicker myid={this.state.myId} baseid={this.state.myBaseId} teamid={this.state.userTeam} usertype={this.state.showPeopleType} onPeopleChange={this.onPeopleChange.bind(this)}  showall={this.state.showAllPeople}/>
              )} 
              </Col>
              <Col xs={12} sm={6} lg={3}>
                <ProjectPicker onProjectChange={this.onProjectChange.bind(this)} />   
              </Col>
              <Col xs={12} sm={6} lg={4}>
              <div className="start-date datepicker-col date_picker_filter">
                  <p>Date: </p>               
                 <DateRangePicker
                    isOutsideRange={() => false}
                    startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                    focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                  />
              </div> 
              </Col>
              <Col xs={12} sm={6} lg={2}>
              <div className="filter-row d-flex project-log-filter">  
              
              {this.state.selectedUser ===''  && this.state.selectedProject === '' ?

              



                (

                  
                  <div className="project-log-hour">
                     <p>Hours: </p>  

                        <Input id="hourfilter" type="select" onChange={this.filterByHours.bind(this)} >
                        <option value=''>Select</option>
                       {this.hoursRender()}
                      </Input>
                  </div>
                )
                :
                null
              }                        
            </div>
            </Col>
              </Row>
          </Col>
          <Col xs={12} sm={2} lg={1}>
              <div className="search-btn">
                <Button color="success"  onClick={this.searchEntriesnew.bind(this)} >
                  { searchContent }                  
                </Button>
              </div>
          </Col>
        </Row>
   
      </div>
    )
  }
}