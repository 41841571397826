export const AccessCheck = (access) =>
{
    if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        if (login.privileges.includes(access)) {
          return true;
        }else
        {
            //return false;
            window.location.href = "/maintenance";
        }
    }else
    {
        window.location.href = "/maintenance";
    }
}

export const AccessCheckBlock = (access) =>
{
    if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        if (login.privileges.includes(access)) {
          return true;
        }else
        {
            return false;
        }
    }else
    {
        return false;
    }
}