import React, {Component} from 'react';
import './HomeSideMenu.css';
import Lottie  from 'react-lottie'
// import Icon from '../63-home-solid.json'
import IconOutline from './63-home-outline.json'
import { Link} from "react-router-dom";
import MaterialIcon, {colorPalette} from 'material-icons-react';

export default class HomeSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    render() {        

        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: IconOutline,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }

        return(
            <Link to={`/home?uuid=${this.props.logger}`}><div
                className={"nsmi_home nsmi_menu_wrapper menu-item-home menu-item d-flex"+(this.props.activeitem === 'home'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                <div className="nsmi_menu-icon" >
                   <MaterialIcon icon="home"/>
                    {/* <Lottie 
                        options={defaultOptions}
                        height={35}
                        width={35}
                        isStopped={this.state.isStopped}
                        isPaused={this.state.isPaused}
                    /> */}
                </div>                
                <p className="nsmi_menu_name">Home</p>
            </div></Link>
        )
    }
}