import React, { Component, Fragment } from 'react';
import { urls } from "../../services/UrlList.js";
import { Container, Row, Col, Card, Button, Progress, Input, Label, CardTitle, Table } from 'reactstrap';
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from '../header/Header';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { motion } from 'framer-motion'
import "./teamview.css";
import EditIconTeam from '../svgicon/EditIconTeam';
import DeleteIcon from '../svgicon/DeleteIcon';
import DeleteIconWhite from '../svgicon/DeleteIconWhite';
import ConfirmAction from "../admincomment/ConfirmAction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import SaveIcon from "../../images/save_small_png.png";
import MaterialIcon from "material-icons-react";
export default class TeamView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMenu: true,
			initialLoader: true,
			pageNum: 0,
			searchKey: '',
			userProfiles: [],
			userProfilesOriginal: [],
			myPrivileges: [],
			myId: '',
			subscribedOnInAscOrder: false,
			nameInAscOrder: false,
			positionInAscOrder: false,
			freshSearch: false,
			dotoTeams: [],
			pmTeamLeads: [],
			projectManagers: [],
			teamLeads: [],
			team: '',
			holiday:'',
			pmTeamLead: '',
			projectManager: '',
			teamLead: '',
			optionDisabled: false,
			editEnabled: false,
			editEnableId: '',
			teamEdit: '',
			teamEditName: '',
			pmTeamLeadEdit: '',
			pmTeamLeadEditName: '',
			projectManagerEdit: '',
			projectManagerEditName: '',
			teamLeadEdit: '',
			teamLeadEditName: '',
			shiftEdit: '',
			shiftStartEdit: '',
			shiftEndEdit: '',
			allDotoShifts: [],
		};
		this.keyPress = this._handleKeyDown.bind(this);
	}

	componentDidMount = () => {
		document.title = "HUB - Team Management";
		this.checkUserPrivilege();
		document.addEventListener("scroll", this.trackScrolling);
		this.getUserProfiles();
		this.getTeamCountries();
	}

	componentWillUnmount() {
		document.removeEventListener("scroll", this.trackScrolling);
	}

	checkUserPrivilege = () => {
		if (localStorage.getItem("user") !== null) {
			var login = JSON.parse(localStorage.getItem("user"));
			this.setState({
				myPrivileges: login.privileges,
				myId: login.uid
			});
			if (!login.privileges.includes('kimp_team')) {
				this.props.history.push("/home");
			}
		}
	};

	_handleKeyDown = (e) => {
		var code = (e.keyCode ? e.keyCode : e.which);
		if (code == 13) {
			/*this.setState({
				userProfiles: [],
				pageNum:0,
				freshSearch:true,
				initialLoader: true,
			});  */
			const _this = this;
			setTimeout(function () {
				_this.filterList();
			}, 200);

		}
	}


	signOut = () => {
		fetch(urls.logmeout)
			.then(response => response.json())
			.then(response => {
				localStorage.clear();
				this.props.history.push("/");
			})
			.catch(error => {
				localStorage.clear();
				this.props.history.push("/");
				fetch(urls.logmeout)
					.then(response => response.json())
					.then(response => {
						localStorage.clear();
						this.props.history.push("/");
					})
					.catch(error => {
						localStorage.clear();
						this.props.history.push("/");
					});

			});
	};

	toggleSidebar = () => {
		this.setState({ showMenu: !this.state.showMenu });
	}

	hideMenu = () => {
		this.setState({ showMenu: !this.state.showMenu });
	}

	searchProfile = () => {
		this.setState({
			userProfiles: [],
			pageNum: 0,
			freshSearch: true,
			initialLoader: true,
		});
		const _this = this;
		setTimeout(function () {
			_this.getUserProfiles();
		}, 500);

	}

	getUserProfiles = () => {

		let opts = {
			pagenum: this.state.pageNum,
			search_key: this.state.searchKey
		};
		console.log(opts);
		fetch(urls.getUserProfilesTeam, {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {
				let list = this.getSortedRecords(response[0].profiles);
				this.setState({
					userProfiles: list,
					userProfilesOriginal: list,
					dotoTeams: response[0].teams,
					pmTeamLeads: response[0].pmtls,
					projectManagers: response[0].pms,
					allDotoShifts: response[0].shifts,
					teamLeads: response[0].tls,
					initialLoader: false
				});
				if (response[0].profiles.length === 300) {
					document.addEventListener("scroll", this.trackScrolling);
				}
			})
			.catch(error => {
				console.log(error);
				this.setState({
					initialLoader: false
				});
			});
	};

	getTeamCountries = () => {
		fetch(urls.getTeamCountries,
			{
				headers: {
					'Access-Control-Allow-origin': '*',
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(response => {
				this.setState({
					teamCountries: response[0].countries,
				})
			})
			.catch((error) => {
				console.log(error);
			})
	}

	isBottom(el) {
		return el.getBoundingClientRect().bottom <= window.innerHeight;
	}

	trackScrolling = () => {
		const wrappedElement = document.getElementById("user-list-management-section");
		if (this.isBottom(wrappedElement)) {
			this.setState(
				{
					pageNum: this.state.pageNum + 1,
					showProgress: true,
					freshSearch: false,
				});
			this.getUserProfiles();
			document.removeEventListener("scroll", this.trackScrolling);
		}
	};


	getSortedRecords = response => {
		if (this.state.freshSearch) {
			return response;
		}
		else {
			let temp_list = this.state.userProfiles.concat(response);
			return temp_list;

		}

	};

	convertTimeFormat = (time) => {
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}

	closeDialogue = () => {
		this.setState({
			showDeletionDialogue: false
		});
	};

	handleDeletionConfirm = () => {
		this.setState({ showDeletionDialogue: !this.state.showDeletionDialogue });
		const _this = this;
		setTimeout(function () {
			_this.handleProfileDeletion();
		}, 1000);
	};

	handleProfileDeletion = () => {
		this.setState({
			initialLoader: true
		});
		let opts = {
			deleteid: this.state.deleteUserid
		};
		fetch(urls.deleteUserProfile, {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {
				this.setState(
					{
						userProfiles: [],
						pageNum: this.state.pageNum + 1,
						initialLoader: true,
						searchKey: ''
					});
				this.getUserProfiles();
			})
			.catch(error => {
				console.log(error);
				this.setState({
					initialLoader: false
				});
			});
	}

	triggerDelete = (id) => {
		console.log(id);
		this.setState(
			{
				showDeletionDialogue: true,
				deleteUserid: id
			});
	}

	sortSubscribers(order, column) {
		let temp = this.state.userProfiles;
		if (column !== "date_of_joining") {
			if (order === "asc") {
				temp = temp.sort(function (a, b) {
					if (a[column] < b[column]) {
						return -1;
					}
					if (a[column] > b[column]) {
						return 1;
					}
					return 0;
				});
			} else if (order === "desc") {
				temp = temp.sort(function (a, b) {
					if (a[column] > b[column]) {
						return -1;
					}
					if (a[column] < b[column]) {
						return 1;
					}
					return 0;
				});
			}
			switch (column) {
				case "name":
					if (order === "asc") {
						this.setState({
							nameInAscOrder: true,
							userProfiles: temp
						});
					} else if (order === "desc") {
						this.setState({
							nameInAscOrder: false,
							userProfiles: temp,
							nameInAscOrder: false
						});
					}
					break;
				case "position":
					if (order === "asc") {
						this.setState({
							emailInAscOrder: true,
							userProfiles: temp,
							positionInAscOrder: true
						});
					} else if (order === "desc") {
						this.setState({
							emailInAscOrder: false,
							userProfiles: temp,
							positionInAscOrder: false
						});
					}
					break;
				default:
					return null;
			}
		} else {
			if (order === "asc") {
				temp.sort(ascOrder);
				this.setState({
					dojInAscOrder: true,
					userProfiles: temp
				});
			} else if (order === "desc") {
				temp.sort(descOrder);
				this.setState({
					dojInAscOrder: false,
					userProfiles: temp
				});
			}
		}

		function ascOrder(a, b) {
			return new Date(a.date_of_joining_sort).getTime() - new Date(b.date_of_joining_sort).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.date_of_joining_sort).getTime() - new Date(a.date_of_joining_sort).getTime();
		}
	}

	handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
		var index = target.selectedIndex;
		var optionElement = target.childNodes[index]
		var option = optionElement.getAttribute('data-id');
		var option2 = optionElement.getAttribute('data-name');
		if (target.name == 'pmTeamLeadEdit') {
			this.setState({ 'pmTeamLeadEditName': option });
		} else if (target.name == 'teamLeadEdit') {
			this.setState({ 'teamLeadEditName': option });
		} else if (target.name == 'projectManagerEdit') {
			this.setState({ 'projectManagerEditName': option });
		} else if (target.name == 'teamEdit') {
			this.setState({ 'teamEditName': option });
		} else if (target.name == 'shiftEdit') {
			this.setState({ 'shiftEdit': option });
		}else if(target.name == 'teamCountryEdit'){
			this.setState({ 'teamCountryEdit': option });
			this.setState({ 'teamCountryNameEdit': option2 });
		}
		const _this = this;
		setTimeout(function () {
			_this.filterList();
		}, 1000);
	};

	filterList = () => {
		let temp = this.state.userProfilesOriginal;
		let list = [];
		if (this.state.searchKey != '') {
			this.setState({
				optionDisabled: true,
				pmTeamLead: '',
				projectManager: '',
				teamLead: '',
				team: '',
				holiday:''
			});
			temp.forEach(p1 => {

				if (p1.name.toLowerCase().includes(this.state.searchKey.toLowerCase())) {
					list.push(p1);
				}
			});
			this.setState({ userProfiles: list });
		} else if (this.state.team > 0) {
			this.setState({
				optionDisabled: true,
				pmTeamLead: '',
				projectManager: '',
				teamLead: '',
				holiday:''
			});
			temp.forEach(p1 => {
				if (this.state.team == p1.user_team_id) {
					list.push(p1);
				}
			});
			this.setState({ userProfiles: list });
		}else if (this.state.holiday > 0) {
			this.setState({
				optionDisabled: true,
				pmTeamLead: '',
				projectManager: '',
				teamLead: '',
				team:''
			});
			temp.forEach(p1 => {
				if (this.state.holiday == p1.team_holiday_country) {
					list.push(p1);
				}
			});
			this.setState({ userProfiles: list });
		} else {
			this.setState({
				optionDisabled: false,
				team: '',
				holiday:''
			});
			if (this.state.pmTeamLead > 0 && !this.state.projectManager > 0 && !this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.pmTeamLead == p1.pm_tl_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (!this.state.pmTeamLead > 0 && this.state.projectManager > 0 && !this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.projectManager == p1.project_manager_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (!this.state.pmTeamLead > 0 && !this.state.projectManager > 0 && this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.teamLead == p1.tl_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (this.state.pmTeamLead > 0 && this.state.projectManager > 0 && !this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.pmTeamLead == p1.pm_tl_id && this.state.projectManager == p1.project_manager_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (this.state.pmTeamLead > 0 && !this.state.projectManager > 0 && this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.pmTeamLead == p1.pm_tl_id && this.state.teamLead == p1.tl_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (!this.state.pmTeamLead > 0 && this.state.projectManager > 0 && this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.projectManager == p1.project_manager_id && this.state.teamLead == p1.tl_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else if (this.state.pmTeamLead > 0 && this.state.projectManager > 0 && this.state.teamLead > 0) {
				temp.forEach(p1 => {
					if (this.state.pmTeamLead == p1.pm_tl_id && this.state.projectManager == p1.project_manager_id && this.state.teamLead == p1.tl_id) {
						list.push(p1);
					}
				});
				this.setState({ userProfiles: list });
			} else {
				this.setState({ userProfiles: this.state.userProfilesOriginal });
			}
		}
	}

	enableForEdit = (item) => {
		this.setState({
			editEnabled: true,
			editEnableId: item.id,
			teamEdit: item.user_team_id,
			pmTeamLeadEdit: item.pm_tl_id,
			projectManagerEdit: item.project_manager_id,
			teamLeadEdit: item.tl_id,
			teamLeadEditName: item.tl,
			projectManagerEditName: item.pm,
			pmTeamLeadEditName: item.pmtl,
			teamEditName: item.team_name,
			shiftEdit: item.user_shift,
			teamCountryEdit: item.team_holiday_country,
			teamCountryNameEdit:item.country_name,
			shiftStartEdit:item.slot_start,
			shiftEndEdit:item.slot_end
		});
	}

	saveEditChanges() {

		let opts = {
			userid: this.state.editEnableId,
			team: this.state.teamEdit,
			workShift: this.state.shiftEdit,
			projectManager: this.state.projectManagerEdit,
			pmTeamLead: this.state.pmTeamLeadEdit,
			teamLead: this.state.teamLeadEdit,
			holiday: this.state.teamCountryEdit
		};

		fetch(urls.updateuserprofileteam, {
			headers: {
				"Access-Control-Allow-Origin": "*",
				"Content-Type": "application/json"
			},
			method: "post",
			body: JSON.stringify(opts)
		})
			.then(response => response.json())
			.then(response => {

				//this.getUserProfiles();
			})
			.catch(error => {
				console.log(error);
				this.setState({
					initialLoader: false
				});
			});

		let temp = this.state.userProfiles;
		let list = [];
		temp.forEach(p1 => {
			if (this.state.editEnableId == p1.id) {
				p1.user_team_id = this.state.teamEdit;
				p1.team_name = this.state.teamEditName;
				p1.pm_tl_id = this.state.pmTeamLeadEdit;
				p1.project_manager_id = this.state.projectManagerEdit;
				p1.tl_id = this.state.teamLeadEdit;
				p1.pmtl = this.state.pmTeamLeadEditName;
				p1.pm = this.state.projectManagerEditName;
				p1.tl = this.state.teamLeadEditName;
				p1.user_shift = this.state.shiftEdit;
				p1.slot_start = this.state.shiftStartEdit;
				p1.slot_end = this.state.shiftEndEdit;
				p1.team_holiday_country = this.state.teamCountryEdit;
				p1.country_name = this.state.teamCountryNameEdit;
				list.push(p1);
				console.log(p1);
			} else {
				list.push(p1);
			}
		});
		this.setState({ userProfiles: list });

		let temp1 = this.state.userProfilesOriginal;
		let list1 = [];
		temp1.forEach(p1 => {
			if (this.state.editEnableId == p1.id) {
				p1.user_team_id = this.state.teamEdit;
				p1.team_name = this.state.teamEditName;
				p1.pm_tl_id = this.state.pmTeamLeadEdit;
				p1.project_manager_id = this.state.projectManagerEdit;
				p1.tl_id = this.state.teamLeadEdit;
				p1.pmtl = this.state.pmTeamLeadEditName;
				p1.pm = this.state.projectManagerEditName;
				p1.tl = this.state.teamLeadEditName;
				p1.user_shift = this.state.shiftEdit;
				p1.slot_start = this.state.shiftStartEdit;
				p1.slot_end = this.state.shiftEndEdit;
				p1.team_holiday_country = this.state.teamCountryEdit;
				p1.country_name = this.state.teamCountryNameEdit;
				list1.push(p1);
			} else {
				list1.push(p1);
			}
		});
		this.setState({ userProfilesOriginal: list1 });
		this.setState({
			editEnabled: false,
			editEnableId: '',
		});


	}

	handleShiftChange = ({ target }) => {
		var index = target.selectedIndex;
		var optionElement = target.childNodes[index]
		var optionid = optionElement.getAttribute('data-id');
		var optionstart = optionElement.getAttribute('data-start');
		var optionend = optionElement.getAttribute('data-end');
		this.setState({
			'shiftEdit': optionid,
			'shiftStartEdit': optionstart,
			'shiftEndEdit': optionend
		});
	}
	render() {
		const variants = {
			hidden: { opacity: 0 },
			visible: { opacity: 1 },
		}
		return (
			<div className="fullwidth_mobilemenu mobileflex">
				<Container fluid className={(this.state.myPrivileges.includes('i_am_designer') ? 'top-bar-active' : 'top-bar-inactive')}>
					<Row>
						<div className="theme_header">
							<Header {...this.props}
								classname="login-portal-page"
								logout={this.signOut.bind(this)}
								onmenuaction={this.toggleSidebar.bind(this)}
								quickactionblock={true} />
						</div>
						<div className="SideNavbar">
							<SideMenu
								activeitem="kimpteam"
								showmenu={this.state.showMenu}
								hidemenu={this.hideMenu.bind(this)} />
						</div>
						<div className="themeContentBody">
							<Card className="members_user_card">
								<CardTitle tag="h1">Team Management</CardTitle>
								<motion.div initial="hidden" animate="visible" variants={variants}>

									<div id="user-list-management-section">

										<div className="filter-bar filter-team-management">
											<Row>
												<Col xl="2" className="plzro">
													<Input type="text" name="search" className="serchinput" placeholder="Search"
														onKeyDown={this.keyPress}
														onChange={(e) => this.setState({ searchKey: e.target.value })}
													/>
													<button className="search_btn" type="submit" onClick={this.filterList}> <MaterialIcon icon="search" /> </button>
												</Col>
												<Col xl="2">
													<select disabled={this.state.optionDisabled && (!this.state.holiday) ? 'disabled' : ''} name="holiday" className="form-control theme_selectbox" onChange={this.handleChange} >
														<option value="">Holidays</option>
														{this.state.teamCountries && this.state.teamCountries.map((holidayitem, index) => {
															return (
																<option value={holidayitem.id}
																	selected={this.state.holiday == holidayitem.id ? 'selected' : ''}
																>{holidayitem.country_name}</option>
															)
														})}
													</select>
												</Col>
												<Col xl="2">
													<select disabled={this.state.optionDisabled && (!this.state.team) ? 'disabled' : ''}  name="team" className="form-control theme_selectbox" onChange={this.handleChange} >
														<option value="">Team</option>
														{this.state.dotoTeams.map((team, index) => {
															return (
																<option value={team.id}
																	selected={this.state.team == team.id ? 'selected' : ''}
																>{team.name}</option>
															)
														})}
													</select>
												</Col>
												<Col xl="2">
													<select disabled={this.state.optionDisabled ? 'disabled' : ''} name="pmTeamLead" className="form-control theme_selectbox"
														onChange={this.handleChange} >
														<option value="">PMTL</option>
														{this.state.pmTeamLeads.map((item, index) => {
															return (
																<option key={index} value={item.id}
																	selected={this.state.pmTeamLead == item.id ? 'selected' : ''}
																>{item.name}</option>
															)
														})}
													</select>
												</Col>
												<Col xl="2">
													<select disabled={this.state.optionDisabled ? 'disabled' : ''} name="projectManager" className="form-control theme_selectbox"
														onChange={this.handleChange} >
														<option value="">PM</option>
														{this.state.projectManagers.map((item, index) => {
															return (
																<option key={index} value={item.id}
																	selected={this.state.projectManager == item.id ? 'selected' : ''}
																>{item.name}</option>
															)
														})}
													</select>
												</Col>
												<Col xl="2">
													<select disabled={this.state.optionDisabled ? 'disabled' : ''} name="teamLead" className="form-control theme_selectbox"
														onChange={this.handleChange} >
														<option value="">TL</option>
														{this.state.teamLeads.map((item, index) => {
															return (
																<option key={index} value={item.id}
																	selected={this.state.teamLead == item.id ? 'selected' : ''}
																>{item.name}</option>
															)
														})}
													</select>
												</Col>
											</Row>
										</div>



										<LoadingWrapper isLoading={this.state.initialLoader}>
											<div className="User-Profiles">
												<Table responsive id="date-list-contianer" className="theme_table">
													<thead>
														<tr>
															<th>Name</th>
															<th>Team</th>
															<th>PL</th>
															<th>PM</th>
															<th>TL</th>
															<th>Work Shift</th>
															<th>Holidays</th>
															<th></th>
														</tr>
													</thead>


													<div id="list-body" className="team-view-list" style={{ display: 'contents' }}>
														{this.state.userProfiles.length > 0 ?
															(
																<>


																	{this.state.userProfiles.map((item, index) => {
																		return (
																			<>
																				{this.state.editEnabled && this.state.editEnableId === item.id ?
																					(
																						<tbody>
																							<tr>
																								<td>
																									<div className="font-weight-bold name">{item.name}</div>
																								</td>

																								{/* <td>{item.position}</td> */}

																								<td>
																									<select name="teamEdit" className="form-control theme_selectbox" onChange={this.handleChange} >
																										<option value="">Select Team</option>
																										{this.state.dotoTeams.map((team, index) => {
																											return (
																												<option data-id={team.name} value={team.id}
																													selected={item.user_team_id == team.id ? 'selected' : ''}
																												>{team.name}</option>
																											)
																										})}
																									</select>
																								</td>
																								<td>
																									<select name="pmTeamLeadEdit" className="form-control theme_selectbox"
																										onChange={this.handleChange} >
																										<option value="">PMTL</option>
																										{this.state.pmTeamLeads.map((itempmtl, index) => {
																											return (
																												<option data-id={itempmtl.name} key={index} value={itempmtl.id}
																													selected={item.pm_tl_id == itempmtl.id ? 'selected' : ''}
																												>{itempmtl.name}</option>
																											)
																										})}
																									</select>
																								</td>
																								<td>
																									<select name="projectManagerEdit" className="form-control theme_selectbox"
																										onChange={this.handleChange} >
																										<option value="">PM</option>
																										{this.state.projectManagers.map((itempm, index) => {
																											return (
																												<option data-id={itempm.name} key={index} value={itempm.id}
																													selected={item.project_manager_id == itempm.id ? 'selected' : ''}
																												>{itempm.name}</option>
																											)
																										})}
																									</select>
																								</td>
																								<td>
																									<select name="teamLeadEdit" className="form-control theme_selectbox"
																										onChange={this.handleChange} >
																										<option value="">TL</option>
																										{this.state.teamLeads.map((itemtl, index) => {
																											return (
																												<option data-id={itemtl.name} key={index} value={itemtl.id}
																													selected={item.tl_id == itemtl.id ? 'selected' : ''}
																												>{itemtl.name}</option>
																											)
																										})}
																									</select>
																								</td>
																								<td>
																									{this.state.allDotoShifts && this.state.allDotoShifts.length > 0 &&
																										(
																											<select name="shiftEdit" className="form-control theme_selectbox"
																												onChange={this.handleShiftChange} >
																												<option value="">Work Shift</option>
																												{this.state.allDotoShifts.map((itemshift, index) => {
																													return (
																														<option data-id={itemshift.id} data-start={itemshift.slot_start} data-end={itemshift.slot_end} key={index} value={itemshift.id}
																															selected={item.user_shift == itemshift.id ? 'selected' : ''}
																														>{this.convertTimeFormat(itemshift.slot_start)} - {this.convertTimeFormat(itemshift.slot_end)}</option>
																													)
																												})}
																											</select>
																										)}
																								</td>
																								<td>
																										<select name="teamCountryEdit" defaultValue={item.team_holiday_country} className="form-control"
																											onChange={this.handleChange} >
																											<option value="">Holidays</option>
																											{this.state.teamCountries.map((holiday, index) => {
																												return (
																													<option data-name={holiday.country_name} data-id={holiday.id} key={index} value={holiday.id} selected={item.team_holiday_country == holiday.id ? 'selected' : ''} >{holiday.country_name}</option>
																												)
																											})}
																										</select>
																								</td>
																								<td>
																									<div className="edit_save_member_box">
																										<div className="save-team-member" onClick={this.saveEditChanges.bind(this, item)}>
																											<img className="save-icon" src={SaveIcon} />
																										</div>
																									</div>

																								</td>
																							</tr>
																						</tbody>
																					)

																					:
																					(
																						<tbody>
																							<tr>
																								<td className="font-weight-bold">
																									{item.name}
																								</td>
																								<td>{item.team_name}</td>

																								<td>
																									<span>{item.pmtl != null ? item.pmtl.split(" ")[0] : '-'}</span>
																								</td>
																								<td>
																									<span>{item.pm != null ? item.pm.split(" ")[0] : '-'}</span>
																								</td>
																								<td>
																									<span>{item.tl != null ? item.tl.split(" ")[0] : '-'}</span>
																								</td>

																								<td>
																									{item.slot_start && item.slot_start.length > 0 &&
																										(
																											<span>{this.convertTimeFormat(item.slot_start)} - {this.convertTimeFormat(item.slot_end)}</span>
																										)}
																								</td>
																								<td>
																									<span>{item.country_name != '' ? item.country_name : '-'}</span>
																								</td>
																								<td>
																									{this.state.myPrivileges.includes('kimp_team_edit') &&
																										(<div className="edit-team-member" onClick={this.enableForEdit.bind(this, item)}><MaterialIcon icon="edit" />  </div>)
																									}
																								</td>
																							</tr>
																						</tbody>
																					)}
																			</>
																		)
																	})}



																</>
															) :
															(
																<Row className="list-body-cont d-flex">
																	<Col> <span className="norecords">No matching records found</span></Col>
																</Row>
															)
														}

													</div>
												</Table>
											</div>
										</LoadingWrapper>
									</div>
									<div className="member-edit-popup">
										<div className="member-edit-overlay"></div>
										<div className="member-edit-wrapper">
											<div></div>
										</div>
									</div>

								</motion.div>
								{this.state.showDeletionDialogue ? (
									<ConfirmAction
										purpose="change"
										closeDialogue={this.closeDialogue}
										show={this.state.showDeletionDialogue}
										note="*This action cannot be undone, are you sure?"
										content="You are about to delete a user profile permanently."
										handleCompletionConfirm={this.handleDeletionConfirm}
										resolveid=""
										resolveStatus=""
										adminid=""
										confirmBoxAction="markasdeleted"
									/>
								) : null}
							</Card>
						</div>
					</Row>


				</Container >
			</div>
		);
	}
}
