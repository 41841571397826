import React, { useState } from 'react'
import {Button} from 'reactstrap';
export default function AccessDenied(props) {
    function goToHome()
    {
        props.gotohome();
    }
    function goToProjectsHome()
    {
        props.gotoprojectshome();
    }

    return (
        <div className="access_denied_page">
            <div className="error_message">
            <h2>Access Denied</h2>
            <p>You don’t have access  to this project. Kindly check your permissions with administrator.</p>
            </div>
           <div className="botton_grp">
             <Button className="project_btn" onClick={goToProjectsHome}>Projects</Button>
             <Button className="backhome_btn" onClick={goToHome}>Back to Home</Button>
            </div> 
        </div>
    )
}