import React, { useState, useEffect, Fragment } from 'react';
import Header from '../header/Header';
import './clientprofile.css';
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Modal, CardTitle, ModalBody, Table, InputGroup } from 'reactstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import MaterialIcon from "material-icons-react";
import { useHistory } from "react-router";
import options from './boards';
import Propic from '../../images/users/user1.png'


export default function ClientprofileDetails(props) {
    const [opencount, setOpenCount] = useState(0);
    const [myId, setmyId] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const [showHelth, setshowHelth] = useState(true);
    const buttonText = showHelth ? 'Show': 'Hide';


    const history = useHistory();
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);

    const [modalboard, setmodalboard] = React.useState(false);
    const toggleboard = () => setmodalboard(!modalboard);

    const [selected, setSelected] = useState([]);

    const checkUserPrivilege = () => {
      if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        setmyId(login.uid);
        setMyPrivileges(login.privileges);
        if (!login.privileges.includes('i_am_hradmin')) {
          history.push('/home');
              }
      }else
      {
        history.push('/home');
      }
    }
    
  
    function toggleSidebar() {
      setshowMenu(!showMenu);
    }
  
    function hideMenu() {
      setshowMenu(!showMenu);
    }
    function goToHome() {
      history.push({ pathname: "/home" });
    }
  
    return (
      <div className="fullwidth_mobilemenu detailpage_view">
        <Container fluid className='bc_module_container'>
          <Row>
            <div className="theme_header">
              <Header
                ncount={opencount}
                {...props} className="tick-timer-header"
                quickactionblock={false} />
            </div>
            {/* <div className="SideNavbar">
                  <SideMenu activeitem="admin" onClick={toggleSidebar} />
            </div>  */}
           
            <div className="themeContentBody themefullwidthContentBoday">
              {/* <Row>
                <Col xs={12} md={12}>
                  <Card className="themeCard log_timer_card mb-4">
                    <div className="with_filter">
                      <div className="d-flex align-items-center frist_div client_filter">
                        <CardTitle tag="h1" className="themeCard_title">Client Profile Details</CardTitle> 
                      
                      </div>
                      
                    </div>
                   
                  </Card> */}

                  <Row>
                    <Col xs={12} md={4}>
                    <div className="client_list_wrapper deatil-leftbox">
                        <div className="client_list_box">
                            <div className="d-flex flex-column">
                                <div className="client_name">
                                    <div className="profile_pic">FA</div>
                                    <h3>Faheeem</h3>
                                    <h6><MaterialIcon icon="location_on" /> Croatia </h6>
                                </div>
                                 <div className="board_status">
                                    <span className="client_status multi">Multi Boards</span> 
                                    <span className="client_status trial">Trial</span> 
                                </div>
                                <div className="packages">
                                    <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                    <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                    <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                </div>
                             </div>
                        </div>
                       <div className="client_helth_board">
                         {showHelth ?<Button onClick={() => setshowHelth(!showHelth)} className="health_btn">Client Health</Button>: null }
                          {/* <Button onClick={() => setshowHelth(!showHelth)} className="health_btn">Client Health</Button> */}
                          {showHelth === false ? (
                            <>
                            <span className="hide_clienthelth" onClick={() => setshowHelth(!showHelth)}>Hide</span>
                            <div className="client_data_card">
                              <div className="staf_name">
                                  <div className="detail">
                                   <span className="pro_pic"><img src={Propic}/></span>
                                   <div>
                                      <h4>Meghan</h4>
                                      <span>August 31</span>
                                   </div>
                                  
                                  </div>
                                  <div className="stars">
                                     <MaterialIcon icon="star"/>
                                     <MaterialIcon icon="star"/>
                                     <MaterialIcon icon="star"/>
                                     <MaterialIcon icon="star"/>
                                     <MaterialIcon icon="star"/>
                                  </div>
                              </div>
                              <p>Lorem ipsum dolor sit amet, qui minim labore  consectetur cupidatat.</p>
                          </div>
                          </>
                          ) : null}
                       </div>
                    </div>
                    </Col>
                    <Col xs={12} md={8}>
                        <div className="linked_boards_wraper">
                            <div className="section_title">
                              <h2>Linked boards</h2>
                              <Button className="add_btn">Add Board</Button>
                            </div>
                           

                            <div className="board_list">

                                <div className="boardcard">
                                    <Button onClick={toggle} title="delete" className="edit_btn_client"><MaterialIcon icon="delete"/></Button>
                                    <h2 className="board_name">Faheem.co.pvt.ltd Facebook Postertt</h2>
                                    <p className="board_discription">Lorem ipsum dolor sit amet, officia excepteur ex fugiat.</p>
                                    <ul>
                                      <div className="memb_avatarlist">
                                          <span className="member_count">15</span>
                                          <Button className="add_member_btn "><MaterialIcon icon="add"/> </Button>
                                      </div>
                                      <span className="client_status active">Active</span>
                                    </ul>
                                    <div className="packages">
                                        <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                        <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                        <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                    </div>
                                    <div className="board_footer">
                                        <div className="board-actions">
                                            <span>Cards</span>
                                            <span>Docs & Files</span>
                                            <span onClick={toggleboard} >Time log</span>
                                        </div>
                                    </div>
                                </div>
                           

                            </div>
                         
                            {/* <div className="choose_board">
                                <Typeahead
                                    id="basic-example"
                                    onChange={setSelected}
                                    options={options}
                                    placeholder="Choose a board..."
                                    selected={selected}
                                 />
                                 
                            </div> */}
                            
                        </div>
                        <div className="linked_boards_wraper mt-4">
                           <h2>Client Note</h2>
                           <div className="add_client_not">
                               <Input type="textarea" className="add_notinput" />
                               <Button className="health_btn ml-0">Add Note</Button>
                           </div>
                           <div className="clientnote_view">
                                <p>Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit enim
                                labore culpa sint ad nisi Lorem pariatur mollit ex esse exercitation amet. Nisi
                                animcupidatat excepteur officia. Reprehenderit nostrud nostrud ipsum Lorem est
                                aliquip amet voluptate voluptate dolor minim nulla est proident. Nostrud officia
                                pariatur ut officia. Sit irure elit esse ea nulla sunt ex occaecat reprehenderit
                                commodo officia dolor Lorem duis laboris cupidatat officia voluptate. Culpa
                                proident adipisicing id nulla nisi laboris ex in Lorem sunt duis officia
                                eiusmod. Aliqua reprehenderit commodo ex non excepteur duis sunt velit enim.
                                Voluptate laboris sint cupidatat ullamco ut ea consectetur et est culpa et
                                culpa duis.</p>
                           </div>
                        </div>
                    </Col>
                    </Row>
                
                {/* </Col>
                
              </Row> */}
            </div>
          </Row>
  
        </Container>
  
        <Modal isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 100 }}>
                <ModalBody>
                <div className="modal_headercli" style={{ fontSize:'20px' }}>Are You Sure</div>
                 <div className="model_footercli">
                    <Button type="submit" className="close_btn"  onClick={toggle}> No</Button>
                    <Button type="submit" className="viewpro_btn mr-2">Yes</Button>
                </div>
                </ModalBody>
        </Modal>
   
        <Modal isOpen={modalboard}
                toggle={toggleboard}
                modalTransition={{ timeout: 100 }} className="board_work_detail">
                <ModalBody>
                <div className="board_innerdetail">
                                 <div className="board_detail_header">
                                     <h3>Faheem.co.pvt.ltd Facebook Postertt</h3>
                                     <div className="board_status">
                                         <span className="client_status active">Active</span>
                                     </div>
                                 </div>
                                 <div className="button_grp_cli">
                                     <div className="d-flex gap10">
                                      <Button className="month_btton active">This week</Button>
                                      <Button className="month_btton">Last Week</Button>
                                      <Button className="month_btton">Current Month</Button>
                                      <Button className="month_btton">Last Month</Button>
                                     </div>
                                     <div className="d-flex total_time"><span>Over all time</span> 5 Hour 40 Minute</div>
                                 </div>
                                 <Table responsive className="theme_table clientwork_table">
                                     <thead>
                                         <th>Task Name</th>
                                         <th>Total Hours</th>
                                      
                                     </thead>
                                     <tbody>
                                         <tr>
                                             <td>Facebook Poster</td>
                                             <td>1 Hour 40 Minute</td>
                                         </tr>
                                     </tbody>
                                 </Table>
                            </div>
               
                </ModalBody>
        </Modal>
            
      </div>
    )
  
  }
