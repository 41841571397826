import React, { useState, useEffect } from 'react';
import './notification.css';
import { Input,PopoverBody } from 'reactstrap';
import {useSelector, useDispatch} from 'react-redux';
import Toast from 'react-toast-component';
import {  MinusNotifyCount,UnreadNotifyMessages } from '../redux/actions';
import ReactHtmlParser from 'react-html-parser';
import { urls } from '../../services/UrlList';
import { useGetUserNotifications, useMarkNotificationRead} from './useQueryHooks'
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";

export default function BellNotifications (props)  {
  const dispatch = useDispatch();
  const notificationcount = useSelector(state => state.NotificationCountReducer);
  const notificationmessages = useSelector(state => state.NotificationMessagesReducer);
  const [messagestoshow, setMessagesToShow] = useState([]);
  const [deleteitem, setDeleteItem] = useState('');
  const [showNotifications, setShowNotifications] =  useState(false);
  
  const { isLoading, isError, error, isFetching, data:notices, refetch } = useGetUserNotifications();
  
  useEffect(() => {
    if(!isLoading)
    {
      dispatch(UnreadNotifyMessages(notices.data.notifications));
    }
  },[notices]);


  const sortByDate = (messages) => {
    let temp = messages;
    temp.sort(descOrder);
    return temp;
    function ascOrder(a, b) {
      return new Date(a.message_time).getTime() - new Date(b.message_time).getTime();
    }
    function descOrder(a, b) {
      return new Date(b.message_time).getTime() - new Date(a.message_time).getTime();
    }
 }

  const findNotiUrl = (message) =>
  {
    if(message && message.receiver && message.receiver !='')
    {
      const notipart = message.receiver.split("@");
      return (urls.notificationUrl+notipart[0]);
    }
  }

  const markasRead = (item, type) => {
    if(item && item.receiver && item.receiver !='')
    {
      setDeleteItem(item.receiver);
      setTimeout(()=>
      {
        const urlspart = item.receiver.split("@");
        const data1 = {
        access_id: urlspart[1],
        user_id:props.myid
        };
        markingNotificationRead(data1);
        setDeleteItem('');
        dispatch(MinusNotifyCount());
      },300);
    }
 }
 const { mutate: markingNotificationRead } = useMarkNotificationRead();




  const markNotificationRead = (item) =>
  {
    if(item && item.receiver && item.receiver !='')
    {
      const urlspart = item.receiver.split("@");
      //window.location.href = urls.notificationUrl+urlspart[0];
      window.open(urls.notificationUrl+urlspart[0], "_blank");
    }
  }

  if(isLoading)
  {
    return(
        <LoadingWrapper isLoading={true} />
    )
  }

  return ( 
    <> {(notices) &&
        (
        <PopoverBody >
           {notices.data.notificationsshort.length>0 &&
            (
              <>
                  {notices.data.notificationsshort.map((notification, index)=>
                  {
                    return(
                      <div key={index} className={`quick_noti_list_item ${deleteitem == notification.receiver ? 'blinkit' :''}`}>
                        <h4>{notification.title}</h4>
                        <div className="noti_detail">
                            {notification.receiver !=='' || notification.receiver !== null ?
                            (
                              <a onClick={markNotificationRead.bind(this,notification)}><p>{ ReactHtmlParser( notification.body +" ")}</p></a>
                              ):
                            (
                              <p>{ ReactHtmlParser( notification.body +" ")}</p>
                            )}
                            {notification.body_title && notification.body_title!='' &&
                            (
                              <a onClick={markNotificationRead.bind(this,notification)}><p><strong>{ ReactHtmlParser( notification.body_title +" ")}</strong></p></a>
                            )}
                             <div className='d-flex justify-content-between'>
                             <a onClick={markNotificationRead.bind(this,notification)}><span className="date_n_time">{notification.message_time}</span></a>
                              <Input checked="" type="checkbox" onClick={markasRead.bind(this,notification,0)} />
                             </div>
                            
                        </div>
                      </div>
                      )
                  })}
                 
              </>
            )
            }
         </PopoverBody >
        )}
    </>
    
  );
};