import React, { Component, Fragment } from "react";
import "./ApprovalModules.css";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import { InputGroup, Input, Label, Button } from "reactstrap";

export default class ApprovalModules extends Component {
  constructor(props) {
    super(props);
  }
  onShowLeavePending = () =>
  {
    this.props.showonpending();
  }

  massApproval = () =>
  {
    console.log("test");
    this.props.onmassapproval();
  }

  render() {
    let classname = ["ApprovalModules", this.props.classname]
      .toString()
      .replace(",", " ");
    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

    return (
      <section className={classname} id={this.props.id}>
        <InputGroup className="appr-show-unapproved d-flex section-titile-leave">
          <h2 className={"caros-title"+(this.props.waiting>0? "": '')} >
            {this.props.title}
          </h2>
          <div className="filer-leave-right">
          <div className="appr-ckbox-unapproved ml-auto d-flex">
          {this.props.waiting>0?
          (     
            <Fragment>      
                <div className="d-flex">
                  <div>
                    <Input
                      id="unapproved"
                      type="checkbox"
                      name="unapproved"
                      value="unapprove"
                      className="mass-approval"
                      onClick={this.onShowLeavePending.bind(this)}
                    />
                    <Label htmlfor="unapproved" style={{paddingTop:'4px'}}>Show Unapproved</Label>
                  </div>
                  
                </div>
            </Fragment> 
          )
          :
          null
          }  
          
          </div>
          {(this.props.id === 'approve-leave' && this.props.waiting>0 && this.props.myid === 4) && 
          (
            <Button className="btn btn-green btn-mass-approve" onClick={this.massApproval.bind(this)}>Mass Approve</Button>
          )}
          </div>
          </InputGroup>
        {this.props.children}
      </section>
    );
  }
}
