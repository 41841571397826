import React, { Component, Fragment } from "react";
import "./WorkHoursCard.css";
import ApprovalAvatarHead from "../../ApprovalAvatarHead/ApprovalAvatarHead";
import ApprovalTimeRow from "../../ApprovalTimeRow/ApprovalTimeRow";
import { Button } from "reactstrap";
import ApprovedMsg from "../../ApprovedMsg/ApprovedMsg";

export default class WorkHoursCard extends Component {
  
  onBreakApproval = () =>
  {
    this.props.onresolveaction(this.props.workhours.id);
  }
  
  render() {
    let classname = [
      "caro-box boq",
      this.props.approved ? "approved" : "appr-pending"
    ]
      .join()
      .replace(",", " ");
    return (
      <div className={classname}>
        <ApprovalAvatarHead  name={this.props.workhours.uname} comment={this.props.workhours.notification_message_admin} exceededtime={this.props.workhours.notified_on} />
        {this.props.workhours.notification_admin_resolve_status === 1 ? (
          <ApprovedMsg label="Reviewed By" approvedby={this.props.workhours.aname}  approvedon={this.props.workhours.notification_admin_resolved_on} />
        ) : (
          <Fragment>
            <Button className="appr-btn-approve btn-green txt-upper" onClick={this.onBreakApproval.bind(this)}>
              Resolve
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}
