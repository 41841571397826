import React from 'react';
import { BrowserRouter as Router,Switch,Route,Link,useRouteMatch} from "react-router-dom";


export default function FeedsIcon( props ) {
  const classes = "home-icon menu-icon "+ props.classname;
  return (
    <div className={classes} onclick={props.onclick}>
      <Link to={props.to}>
        <div className="cont">
          <svg viewbox="0 0 50% 50%" width="41px" height="41px">
            <path style={{ fillRule:"evenodd", fill: '#dbdbdb' }} width="50%" height="50%"
            d="M35.879,40.899 L4.333,40.899 C1.944,40.899 -0.000,38.955 -0.000,36.566 L-0.000,13.139 L40.212,13.139 L40.212,36.566 C40.212,38.955 38.268,40.899 35.879,40.899 ZM12.451,18.270 C12.451,17.619 11.924,17.092 11.273,17.092 L6.226,17.092 C5.575,17.092 5.048,17.619 5.048,18.270 L5.048,23.317 C5.048,23.968 5.575,24.495 6.226,24.495 L11.273,24.495 C11.924,24.495 12.451,23.968 12.451,23.317 L12.451,18.270 ZM33.986,17.092 L16.320,17.092 C15.670,17.092 15.142,17.619 15.142,18.270 C15.142,18.920 15.670,19.448 16.320,19.448 L33.986,19.448 C34.637,19.448 35.164,18.920 35.164,18.270 C35.164,17.619 34.637,17.092 33.986,17.092 ZM33.986,22.139 L16.320,22.139 C15.670,22.139 15.142,22.666 15.142,23.317 C15.142,23.968 15.670,24.495 16.320,24.495 L33.986,24.495 C34.637,24.495 35.164,23.968 35.164,23.317 C35.164,22.666 34.637,22.139 33.986,22.139 ZM33.986,27.186 L6.226,27.186 C5.575,27.186 5.048,27.714 5.048,28.364 C5.048,29.015 5.575,29.543 6.226,29.543 L33.986,29.543 C34.637,29.543 35.164,29.015 35.164,28.364 C35.164,27.714 34.637,27.186 33.986,27.186 ZM33.986,32.234 L6.226,32.234 C5.575,32.234 5.048,32.761 5.048,33.412 C5.048,34.062 5.575,34.590 6.226,34.590 L33.986,34.590 C34.637,34.590 35.164,34.062 35.164,33.412 C35.164,32.761 34.637,32.234 33.986,32.234 ZM7.404,19.448 L10.095,19.448 L10.095,22.139 L7.404,22.139 L7.404,19.448 ZM-0.000,5.020 C-0.000,2.631 1.944,0.688 4.333,0.688 L35.879,0.688 C38.268,0.688 40.212,2.631 40.212,5.020 L40.212,10.782 L-0.000,10.782 L-0.000,5.020 ZM11.273,8.091 L28.939,8.091 C29.589,8.091 30.117,7.564 30.117,6.913 C30.117,6.262 29.589,5.735 28.939,5.735 L11.273,5.735 C10.622,5.735 10.095,6.262 10.095,6.913 C10.095,7.564 10.622,8.091 11.273,8.091 Z"/>
          </svg>
          <p className="mb-0 text-center mt-1">{props.title}</p>
        </div>
      </Link>     
      <span className="arrow"></span>
    </div>
  )
}