import React, { Component } from 'react';
import './comment-form.css';
import { Input } from 'reactstrap';

export default class CommentForm extends Component {
  render() {
    return (
      <div id="comment-form" className="d-flex">
        <div className="textarea">
          <Input type="textarea" name="text" id="comment-text-box" placeholder="Type your comment....." />
        </div>
        <Input className="btn-green" type="submit" name="submit" id="submit" value="submit" />
      </div>
    )
  }
}