export const findMockups = (otherfolders)=>
{
    let Mockups = [];
    if(otherfolders && otherfolders.length>0)
    {
        otherfolders.forEach(folder => {
            if(folder.folder_name === 'Mockups')
            {
            Mockups.push(folder);
            }
        });
    }
    return Mockups;
}

export const excludeMockupFromOther = (otherfolders)=>
{
    let excludeMockup = [];
    if(otherfolders && otherfolders.length>0)
    {
        otherfolders.forEach(folder => {
            if(folder.folder_name !== 'Mockups')
            {
                excludeMockup.push(folder);
            }
        });
    }
    return excludeMockup;
}

export const excludeMockupFromOther360 = (otherfolders)=>
{
    let excludeMockupandRef = [];
    if(otherfolders && otherfolders.length>0)
    {
        otherfolders.forEach(folder => {
            if(folder.folder_name !== 'Mockups' && folder.folder_name !== 'Reference' && folder.folder_name !== 'Print Check')
            {
                excludeMockupandRef.push(folder);
            }
        });
    }
    return excludeMockupandRef;
}