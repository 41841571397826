import React from 'react'
import './FeedCommentItem.css'
import {urls} from '../../../../services/UrlList'

export default function FeedCommentItem( props ) {
  const { name, msg, cmDate, userid } = props;
  return (
    <div className="feed_comment_item feed_comment_item_1">
      <div className="user_detail_feed_cmd">
        <span className="user_pic">
          <img src={urls.storageUrl+"storage/profile/image/"+userid+".jpg"} alt="profile Picture"/>
          </span>
        <h6>{name} </h6>
      </div>
      <p>{msg}</p>
      
     <div className="feed_cmd_add_date">{cmDate}</div>
    </div>
  )
}
