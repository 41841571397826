import React,{useState, useEffect} from "react";
import {urls} from "../../services/UrlList";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../svgicon/AttachmentIcon";
import MaterialIcon from "material-icons-react";
import { handleFileDownload } from "./HandleFileDownload";
import { Spinner } from "reactstrap";

import {Input,UncontrolledTooltip} from 'reactstrap';

export default function LightboxPop(props)
{
    const [attachments, setAttachments] = useState(props.attachments);
    const [attachmentstext, setAttachmentsText] = useState(props.taskAttachmenttextarray);
    const [downloadProgress, setDownloadProgress] = useState(false);
    const [downloadId, setDownloadId] = useState([]);

     useEffect(() => {
        setAttachments(attachments);
    },[attachments]);
    
    const handleUpdate = ({target}) =>
    {
        let temp = [...attachmentstext];
        temp[target.name]=target.value;
        setAttachmentsText(temp);
        props.updatefiletext(props.arrayname, temp);
    }

    const trigerHandleFileDownload = async (path, filename) =>
    {
        setDownloadProgress(true);
        let tempdowmloadids = [...downloadId];
        tempdowmloadids.push(filename);
        setDownloadId(tempdowmloadids);
        let downloadedfile = await  handleFileDownload(path, filename);
        let itemkey = tempdowmloadids.map(function (item) { return item; }).indexOf(downloadedfile); 
        tempdowmloadids.splice(itemkey, 1);
        setDownloadId(tempdowmloadids);
        if(tempdowmloadids.length<=0)
        {
            setDownloadProgress(false);
        }
    }

    const toolValueGenerate = (filename)=>
    {
        return filename.replace(/[^a-z0-9]+/gi, "");
    }

    return(
        <div class={`attachment-items d-flex ${((props.listtype && props.listtype == 2) || (props.attachmenttexts && props.attachmenttexts.length>0)) && 'split-instructions' }`}>
            <SimpleReactLightbox className="test">
                <SRLWrapper className="test">
                {(attachments || []).map((attachitem, index) => (
                <div className="attch-items" key={index}>
             <div className="d-flex flex-nowrap">
                {attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'JPG' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'JPEG' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'PNG' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
            
                (
                <div className="attach_img_close">
                <a className="bg-medium-attachment"  href={attachitem.path.includes('amazonaws') ? (attachitem.path.includes(attachitem.file_name.split('.').pop()) ? attachitem.path : attachitem.path+"/"+attachitem.file_name) : urls.s3Url+'projects/'+attachitem.path+"/"+attachitem.file_name} target="_blank">
                    <img src={attachitem.path.includes('amazonaws') ? (attachitem.path.includes(attachitem.file_name.split('.').pop()) ? attachitem.path : attachitem.path+"/"+attachitem.file_name)  :   urls.s3Url+'projects/'+attachitem.path+"/"+attachitem.file_name} alt={attachitem.file_name}   />
                 </a>
                {props.deleteitem &&  props.deleteitem === 'yes' &&
                (
                    <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem.file_name, props.arrayname)}></span>
                )}
                </div>
                ):
                (
                <div className="attach_img_close">
                    <a href={attachitem.path.includes('amazonaws') ? (attachitem.path.includes(attachitem.file_name.split('.').pop()) ? attachitem.path : attachitem.path+"/"+attachitem.file_name) :  urls.s3Url+'projects/'+attachitem.path+"/"+attachitem.file_name} target="_blank" className="attachment-bg bg-small attach_other">
                        <span className="attachment-type">
                            <AttachmentIcon />
                            {attachitem.file_name.split('.').pop()}
                        </span>
                    </a>
                   {props.deleteitem &&  props.deleteitem === 'yes' &&
                    (
                        <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem.file_name, props.arrayname)}></span>
                    )}
                </div>
                )}
                <div className="attach_file_detail justify-content-between">
                    <span>
                        <h3 className="file-title-view"  id={"tools"+toolValueGenerate(attachitem.file_name)} href="#">
                            {attachitem.file_name}
                        </h3>
                        <UncontrolledTooltip  placement="top-start" style={{'borderRadius': 0, maxWidth:'600px',width:'auto'}}  target={"tools"+toolValueGenerate(attachitem.file_name)} >
                            {attachitem.file_name} 
                        </UncontrolledTooltip >

                        {attachitem.file_size && attachitem.file_size!='' &&
                        ( <p className="filesize">{attachitem.file_size}</p> )}
                    </span>
                    <div className="d-flex align-items-center" style={{gap:10 }}>
                    <a href={attachitem.path.includes('amazonaws') ? (attachitem.path.includes(attachitem.file_name.split('.').pop()) ? attachitem.path : attachitem.path+"/"+attachitem.file_name) :  urls.s3Url+'projects/'+attachitem.path+"/"+attachitem.file_name} target="_blank" className="mp4-view" >
                        <MaterialIcon icon="open_in_new" size={18} />
                    </a>
                    <span className="download-file">
                        <a onClick={(e) =>{ e.preventDefault(); trigerHandleFileDownload(attachitem.path.includes('amazonaws') ? (attachitem.path.includes(attachitem.file_name.split('.').pop()) ? attachitem.path : attachitem.path+"/"+attachitem.file_name) :  urls.s3Url+'projects/'+attachitem.path+"/"+attachitem.file_name, attachitem.file_name) }} target="_blank" className="attachment-download" >
                           {downloadProgress && downloadId.includes(attachitem.file_name) ? 
                            <Spinner color="secondary" style={{ width: "20px", height: "20px" }}/>
                           :
                           <MaterialIcon icon="save_alt" size={18} />
                           }
                        </a>
                    </span>
                    </div>
                </div>

                </div>
              
                <div className="attach_img_discription">
                    {props.listtype && props.listtype == 2 &&
                    (
                        <Input className="attachment-notes" type="textarea" name={index} onChange={handleUpdate} value={attachmentstext[index]} />
                    )}    
                    {props.attachmenttexts && props.attachmenttexts.length>0 &&
                    (
                        <span>{props.attachmenttexts[index]}</span>
                    )}
                </div>
                </div>
                ))}
                </SRLWrapper>
            </SimpleReactLightbox>
    </div>
    )
}
