import React, { Component, Fragment } from 'react';
import './teamcontrol.css'
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label, InputGroup, FormGroup} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { Typeahead } from 'react-bootstrap-typeahead';
import moment from "moment";
import isThisHour from 'date-fns/esm/isThisHour/index';

export default class TeamControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      showProgress: true,
      updatingNow:'',
      teams:[],
      pls:[],
      shifts:[],
      pms:[],
      members:[],
      loopsArray:[1,2,3,4,5,6,7],
      teamPool:[]
    };
    this.reactTags = React.createRef();
  }

  // isBottom(el){
  //   return el.getBoundingClientRect().bottom <= window.innerHeight;
  // }

  componentDidMount () {
    document.title = "HUB - Ticket System";
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(()=>
    {
      _this.renderContentViewOwnership();
      _this.getTeamResources();
      _this.getTeamPool();
      document.addEventListener("scroll", _this.trackScrolling);
    }, 1000);
  };


  getFullUsers = () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),          
        });            
    })
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name
      });
    }
  };

  renderContentViewOwnership = () =>
  { 
    if (this.state.myPrivileges.includes('all_tickets') || this.state.myPrivileges.includes('my_tickets') ) {
      if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'full'});
      }else if(this.state.myPrivileges.includes('all_tickets'))
      {
        this.setState({ownership:'partial'});
      }
    }else
    {
      this.props.history.push("/home");
    }
  }

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

 

  handleChange = ({target}) =>
  {
    this.setState({ [target.name] : target.value})
  }


handleInputChange(teamid, e) {
  console.log(e.target.value);
  /* this.setState({ selectedClient :e}); */
  this.checkAndReplace(teamid,e.target.value, 'country');
}

handleInputChangeTrial(teamid, e) {
  this.checkAndReplace(teamid,e.target.value, 'trial');
}

handleInputChangeCom(teamid, e) {
  this.checkAndReplace(teamid,e.target.value, 'com');
}

checkAndReplace(tid, edata, type)
{
  let temp = this.state.teamPool;
  let list =[];
  temp.forEach(p1 => {
    console.log(p1);
    if(p1.teams.length>0)
    {
      p1.teams.forEach(p2 => {
        if(p2.team_id === tid && type === 'country')
        {
          p2.countries = edata;
          
        }else if(p2.team_id === tid && type === 'trial')
        {
          p2.gd_trial = edata;
          
        } else if(p2.team_id === tid && type === 'com')
        {
          p2.gd_com = edata;
         
        }
    })
    }
    list.push(p1);
  })
  this.setState({teamPool: list});
}



  getTeamResources = () =>{        
    var fUrl = urls.teamresources;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {     
        this.setState({
          teams:response.teams,  
          pls: response.pls,  
          pms: response.pms,
          members: response.members,
          shifts:response.shifts   
        });            
    })
  };


  getTeamPool = () =>{        
   fetch(urls.teamPool,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        console.log(response);  
        this.setState({
         teamPool:response,
         showProgress:false 
        });            
    })
    .catch(error=>
      {
        this.setState({
          showProgress:false 
         }); 
      })
  };
  
  
  convertTimeFormat = (time) =>
	{
		var timeString = time;
		var H = +timeString.substr(0, 2);
		var h = (H % 12) || 12;
		var ampm = H < 12 ? "AM" : "PM";
		timeString = h + timeString.substr(2, 3) + ampm;
		return timeString;
	}

  updateTeamColumn = (teamid, cid) =>
  {
    this.setState({
      showProgress:true,
      updatingNow:teamid+"-"+cid
     });
    let opts ={
      team_id: document.getElementById('team-name-'+teamid+"-"+cid).value,
      shift_id: document.getElementById('shift-'+teamid+"-"+cid).value,
      countries: document.getElementById('team-countries-'+teamid+"-"+cid).value,
      project_lead: document.getElementById('project-lead-'+teamid+"-"+cid).value,
      project_manager: document.getElementById('project-manager-'+teamid+"-"+cid).value,
      designer1: document.getElementById('designer-one-'+teamid+"-"+cid).value,
      designer2: document.getElementById('designer-two-'+teamid+"-"+cid).value,
      designer3: document.getElementById('designer-three-'+teamid+"-"+cid).value,
      designer4: document.getElementById('designer-four-'+teamid+"-"+cid).value,
      designer5: document.getElementById('designer-five-'+teamid+"-"+cid).value,
      designer6: document.getElementById('designer-six-'+teamid+"-"+cid).value,
      designer7: document.getElementById('designer-seven-'+teamid+"-"+cid).value,
      designer8: document.getElementById('designer-eight-'+teamid+"-"+cid).value,
    }
    fetch(urls.teamFileSave,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
      .then(response => {      

        this.setState({
          showProgress:false,
          updatingNow:''
         });          
      })
      .catch(error => {
        this.setState({
          showProgress:false,
          updatingNow:''
         }); 
      });
      
  }

  render() {
    return (
      <Container fluid className="top-bar-inactive">
      <Row>
      <Col sm="12">
        <Header {...this.props}  className="tick-timer-header" 
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={false} />
      <div className="ticket-wrapper container-fluid">


        {this.state.teamPool.map((item, itemindex)=>
        {
          return(
            <div className="team_control_wrapper">
            <div className="box_relative">
             <div className="team_fixed_box">
               <span className="team_frist_box">KIMP TEAM</span>
               <span className="team_frist_box">SHIFT TIMING</span>
                <span className="team_frist_box">COUNTRIES</span>
               <span className="team_frist_box">PMTL</span>
               <span className="team_frist_box">PM</span>
               <span className="team_frist_box design_bg">TRIAL GD</span>
               <span className="team_frist_box design_bg">COM</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
               <span className="team_frist_box design_bg">GD</span>
             </div>
            </div>
            <div className="team_result_box">
             {this.state.loopsArray.map((litem, tindex)=>
             { 
               return(
                  <div className={this.state.showProgress && this.state.updatingNow == itemindex+"-"+tindex? "team-updating team_inner_box": "team_inner_box"} >
                    <span className="team_name_box">
                      <Input name={"team-name-"+itemindex+"-"+tindex} id={"team-name-"+itemindex+"-"+tindex} type="select" className="dropfilt"  onChange={this.handleChange}>
                        <option value="">Team</option>
                        {(this.state.teams || []).map(team => (
                          <option selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].team_id === team.id) && 'selected' }  value={team.id}>{team.name}</option>
                        ))}
                      </Input>
                    </span>
                    <span className="shift_time_box">
                    <Input  type="select" className="dropfilt"
                    name={"shift-"+itemindex+"-"+tindex} id={"shift-"+itemindex+"-"+tindex}
                    >
                      <option value="">Shift</option>
                      {this.state.shifts.map((shift, index) =>
                      {
                        return(
                          <option value={shift.id}
                          selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].shift_id === shift.id) && 'selected' }
                          >{this.convertTimeFormat(shift.slot_start)} - {this.convertTimeFormat(shift.slot_end)}</option>
                        )
                      })}
                    </Input>
                    </span>
                    <span className="countries_box">
                      <Input type="text" className="team-country" value={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].countries) && this.state.teamPool[itemindex].teams[tindex].countries }
                       name={"team-countries-"+itemindex+"-"+tindex} id={"team-countries-"+itemindex+"-"+tindex}
                      onChange={this.handleInputChange.bind(this, this.state.teamPool[itemindex].teams[tindex]? this.state.teamPool[itemindex].teams[tindex].team_id : '')}
                      /> 
                    </span>
                    <span className="pmtl_name">
                      <Input type="select" className="dropfilt" 
                      name={"project-lead-"+itemindex+"-"+tindex} id={"project-lead-"+itemindex+"-"+tindex}
                      onChange={this.handleChange}>
                          <option value="">PL</option>
                          {(this.state.pls || []).map(pl => (
                            <option 
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].pl_id === pl.id) && 'selected' } 
                            value={pl.id}>{pl.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="pm_name">
                        <Input type="select" className="dropfilt" 
                          name={"project-manager-"+itemindex+"-"+tindex} id={"project-manager-"+itemindex+"-"+tindex}
                        onChange={this.handleChange}>
                          <option value="">PM</option>
                          {(this.state.pms || []).map(pm => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].pm_id === pm.id) && 'selected' }
                            value={pm.id}>{pm.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="countries_box">
                      <Input type="text" className="team-trial-gd" value={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd_trial) && this.state.teamPool[itemindex].teams[tindex].gd_trial }
                       name={"team-trial-gd-"+itemindex+"-"+tindex} id={"team-trial-gd-"+itemindex+"-"+tindex}
                       onChange={this.handleInputChangeTrial.bind(this, this.state.teamPool[itemindex].teams[tindex]? this.state.teamPool[itemindex].teams[tindex].team_id : '')}
                      /> 
                    </span>
                    <span className="countries_box">
                      <Input type="text" className="team-com" value={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd_com) && this.state.teamPool[itemindex].teams[tindex].gd_com }
                       name={"team-com-"+itemindex+"-"+tindex} id={"team-com-"+itemindex+"-"+tindex}
                       onChange={this.handleInputChangeCom.bind(this, this.state.teamPool[itemindex].teams[tindex]? this.state.teamPool[itemindex].teams[tindex].team_id : '')}
                      /> 
                    </span>
                    <span className="desiner_name">
                      <Input type="select" className="dropfilt" 
                      name={"designer-one-"+itemindex+"-"+tindex} id={"designer-one-"+itemindex+"-"+tindex}
                      onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd1_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                        <div className="note_text"></div>
                        <div className="note_add_here"></div>
                    </span> 
                    <span className="desiner_name">
                      <Input type="select" className="dropfilt" 
                      name={"designer-two-"+itemindex+"-"+tindex} id={"designer-two-"+itemindex+"-"+tindex}
                      onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd2_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="desiner_name">
                      <Input type="select" className="dropfilt" 
                      name={"designer-three-"+itemindex+"-"+tindex} id={"designer-three-"+itemindex+"-"+tindex}
                      onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd3_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="desiner_name">
                        <Input type="select" className="dropfilt" 
                        name={"designer-four-"+itemindex+"-"+tindex} id={"designer-four-"+itemindex+"-"+tindex}
                        onChange={this.handleChange}>
                            <option value=""></option>
                            {(this.state.members || []).map(member => (
                              <option  
                              selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd4_id === member.id) && 'selected' }
                              value={member.id}>{member.name}</option>
                            ))}
                          </Input>
                    </span>
                    <span className="desiner_name">
                          <Input type="select" className="dropfilt" 
                          name={"designer-five-"+itemindex+"-"+tindex} id={"designer-five-"+itemindex+"-"+tindex}
                          onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd5_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="desiner_name">
                        <Input type="select" className="dropfilt" 
                        name={"designer-six-"+itemindex+"-"+tindex} id={"designer-six-"+itemindex+"-"+tindex}
                        onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd6_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="desiner_name">
                        <Input type="select" className="dropfilt" 
                        name={"designer-seven-"+itemindex+"-"+tindex} id={"designer-seven-"+itemindex+"-"+tindex}
                        onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd7_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <span className="desiner_name">
                        <Input type="select" className="dropfilt" 
                        name={"designer-eight-"+itemindex+"-"+tindex} id={"designer-eight-"+itemindex+"-"+tindex}
                        onChange={this.handleChange}>
                          <option value=""></option>
                          {(this.state.members || []).map(member => (
                            <option  
                            selected={(this.state.teamPool[itemindex].teams[tindex] && this.state.teamPool[itemindex].teams[tindex].gd8_id === member.id) && 'selected' }
                            value={member.id}>{member.name}</option>
                          ))}
                        </Input>
                    </span>
                    <button className="save_btn" onClick={this.updateTeamColumn.bind(this,itemindex, tindex )}>Save</button>
                </div>
              )
             
             })}
             
            
       
             
           {/*
            <div className="team_inner_box">
               <span className="team_name_box">AU-A1</span>
               <span className="shift_time_box">7am - 4pm</span>
               <span className="countries_box">AUS/NX</span>
               <span className="pmtl_name">Sanday</span>
               <span className="pm_name">Ishan(Ivan) : Act-18</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <button className="save_btn">Save</button>
            </div>
            <div className="team_inner_box">
               <span className="team_name_box">AU-A1</span>
               <span className="shift_time_box">7am - 4pm</span>
               <span className="countries_box">AUS/NX</span>
               <span className="pmtl_name">Sanday</span>
               <span className="pm_name">Ishan(Ivan) : Act-18</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <span className="desiner_name">Sarath Nair</span>
               <button className="save_btn">Save</button>
            </div>
           */}
 
           </div>
         </div> 
          )
        })}       
        </div>
      </Col>
      </Row>
      </Container>
    )
  }
}
