import React, { Component, Fragment } from 'react';
import "./momeeting.css"
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import Select from 'react-select';
import MaterialIcon, {colorPalette} from 'material-icons-react';
import ReactQuill from 'react-quill'; 


import { 
  Container, 
  Row, 
  Col, 
  Card, 
  FormGroup, 
  Input, 
  Button, 
  Table,
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter,
  Form,
  Label } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';




export default class  MinutesofMeeting extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      allBoards:[],
      allBoardLastupdate:'',
      firstHalf:[],
      secondHalf:[],
      orginalUsers: [],
      singleCollection:[],
      showSingle:false,
      initialLoader:true,
      activeUserId:'',
      boardUrl:'',
      actionStatus:false,
      showMenu:true,
      text: '' ,
      newItem: "",
      list: []
    };
   // this.keyPress = this._handleKeyDown.bind(this);
   this.handleChange = this.handleChange.bind(this)
  }
  handleChange(value) {
    this.setState({ text: value })
  }
  updateInput(key, value) {
    this.setState({
      [key]: value
    });
  }

  addItem() {
    //create the new item with unique id
    //so a state object itself can be assigned new objects to it
    const newItem = {
      id: 1 + Math.random(),
      value: this.state.newItem
    };

    //copy current list of items
    const list = [...this.state.list];

    //add new item to the list
    list.push(newItem);

    //update state with new list and reset new item
    this.setState({
      list,
      newItem: ""
    });
  }

  deleteItem(id) {
    console.log(id);
    //copy list
    const list = [...this.state.list];

    //filter out deleted object
    //remember that the list (each object) gets assigned a value and id when its added to list
    const updatedList = list.filter((item) => item.id !== id);

    //update state
    this.setState({ list: updatedList });
  }



  componentDidMount =() =>
  {   
    document.title = "HUB - Trello Active Boards";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {  
      if(_this.state.myPrivileges.includes('i_am_pl'))
      {
          _this.getAllCollections();
          _this.getAllBoards();
      }else
      {
        _this.getMyTrelloCollection();
      }
    }, 200);
  };

  componentDidUpdate(prevState) {  
    if (prevState.actionStatus !== this.state.actionStatus  && this.state.actionStatus === true) {      
      const _this = this;
      setTimeout(function() {           
        _this.setState({actionStatus:false})
      }, 6000);  
    } 
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }

  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  getMyTrelloCollection = () =>{        
    fetch(urls.getMyTrelloCollection+this.state.myId,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {  
        if(response[0].collection.length>0) 
        {
          console.log(response);
          this.setState({
            selectedUser: response[0].collection[0].trello_name
          }); 
          const _this = this;
          setTimeout(function() {     
            _this.getSingleBoard();
          }, 200);
        }else
        {
          this.getAllCollections();
          this.getAllBoards();
        }  

    })
    .catch(error =>
      {
        this.getAllCollections();
        this.getAllBoards();
      })
  };

  getAllBoards = () =>{        
    this.setState({initialLoader:true});
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getAllTrelloBoardsQa;
    }else
    {
      var furl = urls.getAllTrelloBoards;
    }
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.splitArray(response[0].members); 
        this.setState({
            allBoards: response[0].members,  
            allBoardLastupdate: response[0].last_sync,
            initialLoader:false  
        });            
    })
  };

  splitArray = (allboards)=>
  {
    const list = allboards;
    const middleIndex = Math.ceil(list.length / 2);

    this.setState({firstHalf: list.splice(0, middleIndex), secondHalf:list.splice(-middleIndex)});
  
  }

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getSingleBoard = () =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      datte: 7
    } 
    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleQa;
    }else
    {
      var furl = urls.getTrelloSingle;
    }

    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        console.log(response);
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          activeUserId: response[0].member_uid,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  showSingleboard = (member) =>
  {
    this.setState({
      selectedUser: member
    }); 
    const _this = this;
    setTimeout(function() {     
      _this.getSingleBoard();
    }, 200);
  }

  getSingleBoardBell = (board_name) =>{  
    this.setState({initialLoader:true});
    let opts ={    
      member: this.state.selectedUser,
      board_name: board_name,
      datte: 7
    } 

    if(this.state.myPrivileges.includes('i_am_qa'))
    {
      var furl = urls.getTrelloSingleBellQa;
    }else
    {
      var furl = urls.getTrelloSingleBell;
    }
    
    fetch(furl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {    
        this.setState({
          singleCollection: this.sortBoards('asc', response[0].boards),
          allBoardLastupdate: response[0].last_sync,
          showSingle:true,  
          initialLoader:false       
        });       
      } 
    })
  };

  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  // handleChange = (selectedOptions) => {
  //   var size = Object.keys(selectedOptions).length;
  //   if(size>0)
  //   {
  //     this.setState({ selectedUser:selectedOptions[0].collection});
  //   }
  // }
  
  // handleInputChange(input, e) {
  //   console.log("value", input)
  // }

  sortBoards(order, response) {
		let temp = response;
		  if (order === "asc") {
			temp.sort(ascOrder);
			return temp;
		  } else if (order === "desc") {
      temp.sort(descOrder);
      return temp;
	  }
	
	
		function ascOrder(a, b) {
      if(a.bell_active=== 1)
			return new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.last_activity).getTime() - new Date(a.last_activity).getTime();
		}
	}

  fetchBaseandRedirect = (board_name) =>
  {
    const boarddata = board_name.split("Kimp");
    let opts ={    
      board_name: boarddata[0]
    } 
    fetch(urls.findBoardByName,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if(response.bid_cpt !=='')
      {
        var burl = urls.notificationUrl+"/task-list/"+response.bid_cpt;
        window.open(burl, '_blank').focus();
      }
    })
  }


  addRemoveTrelloWatch = (id) =>
  {
    let opts ={    
      boardurl: this.state.boardUrl,
      stype: id,
      user_id: this.state.myId
    } 
    console.log(opts);
    fetch(urls.addRemoveTrelloWatch,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      
      if(response[0]['success']==1 || response[0]['success']==2)
      {
        this.setState({
          boardUrl:'',
          actionStatus:true
        })
      }
    })
  }

  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  showAllTrello = ()=>
  {
    this.setState({showSingle:false});
  }
  render() {
    const { selectedOption } = this.state;
    const { selectedOptionhost } = this.state;
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} > 
    <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="Minutes of Meeting" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
      <div className="themeContentBody active_trello momdetailpage">
        <Row>
          <Col sm={12} xl={12}>
            <Card className="cl_helth_report">
               <div className="d-flex justify-content-between align-items-center">
                 <h1 class="page-title text-center her_title">Minutes of Meeting</h1>
               </div>
               <div className="meeting_details">
                  <div>
                    <span>Meeting Name</span>
                    <h3>Meeting With PL Teams</h3>
                  </div>
                  <div>
                    <span>Meeting Date</span>
                    <h3>Wed 6 Jul 12.05 PM</h3>
                  </div>
                  <div>
                    <span>Invited By</span>
                    <h3>Murali</h3>
                  </div>
                  <div>
                    <span>Invited To</span>
                    <h3>Balaji</h3>
                  </div>
                  <div>
                    <span>Invited Time</span>
                    <h3>12.15 PM</h3>
                  </div>
               </div>
               <div className="add_meeting_deatil">
               <ReactQuill value={this.state.text}
                  onChange={this.handleChange} />
                    <h3 className="section_tittile">Check list</h3>
                    <div className="add_checklist">
                    <input
                      type="text"
                      placeholder="type the item here"
                      //once the input is entered, the object will be assigned a value = user's input
                      //we also need this value later for the list also
                      value={this.state.newItem}
                      onChange={(e) => this.updateInput("newItem", e.target.value)}
                    />
                    <Button className="add_btn" onClick={() => this.addItem()}><MaterialIcon icon="add"/></Button>
                    </div>
                    <div>
                    <ul className="check_list_prev">
                      {this.state.list.map((item) => {
                        return (
                          <li key={item.id}>
                            {item.value}
                            <Button className="close_icon" onClick={() => this.deleteItem(item.id)}><MaterialIcon icon="close"/> </Button>
                          </li>
                        );
                      })}
                    </ul>
                    <Button className="sub_btn_meet mt-5">Save</Button>
                    </div>
                </div>

              </Card>
                   <div className="preview_meeting_details">
                       <Button className="edit_btn">Edit</Button>
                       <p>Lorem ipsum dolor sit amet, officia excepteur ex fugiat reprehenderit enim
                       labore culpa sint ad nisi Lorem pariatur mollit ex esse exercitation amet. Nisi
                       animcupidatat excepteur officia. Reprehenderit nostrud nostrud ipsum Lorem est
                       aliquip amet voluptate voluptate dolor minim nulla est proident. Nostrud officia
                       pariatur ut officia. Sit irure elit esse ea nulla sunt ex occaecat reprehenderit
                       commodo officia dolor Lorem duis laboris cupidatat officia voluptate. Culpa
                       proident adipisicing id nulla nisi laboris ex in Lorem sunt duis officia
                       eiusmod. Aliqua reprehenderit commodo ex non excepteur duis sunt velit enim.
                       Voluptate laboris sint cupidatat ullamco ut ea consectetur et est culpa et
                       culpa duis.</p>
                       <div className="check_list_previw_box">
                         <div className="d-flex align-content-center"> 
                          <input id="1"  type="checkbox" class="form-check-input" value="point" />
                          <p>Aliqua reprehenderit commodo  sunt velit enim</p>
                         </div>
                         <div className="d-flex align-content-center"> 
                         <input id="2"  type="checkbox" class="form-check-input" value="point" />
                          <p>Aliqua reprehenderit</p>
                         </div>
                         <div className="d-flex align-content-center"> 
                          <input id="3"  type="checkbox" class="form-check-input" value="point" />
                          <p>Aliqua reprehenderit commodo ex non excepteur duis sunt velit enim</p>
                         </div>
                       </div>
                       
                    </div>

                    <div className="add_comment_meeting add_meeting_deatil">
                            <ReactQuill value={this.state.text}
                        onChange={this.handleChange} />
                          <Button className="sub_btn_meet mt-3">Add Comment</Button>

                          <div className="comment_previw">
                          <Button className="edit_btn">Edit</Button>
                              <div className="user_detial d-flex">
                                <span className="user_pic"></span>
                                <div className="user_details">
                                   <h3>faheem</h3>
                                   <h6>Tue 5 Jul 4.34 pm</h6>
                                </div>
                               
                              </div>
                              <div className="comment_contnet_preview">
                                  <p>Dear invite member you missed  “bijfhepwfjew [fjfpfj” this point 
                                     <br/>
                                     <br/>
                                     Thank you 
                                  </p>
                                </div>
                          </div>
                       </div>
            </Col>
           
        </Row>
      </div>
      </Row>

     
      </Container>
      </div>
    )
  }
}
