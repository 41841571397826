import React, { Component } from 'react';
import './admin-comment.css';
import SingleComment from './singlecomment/SingleComment';
import CommentForm from './comment-form/CommentForm';
import ConfirmAction from "./ConfirmAction";
import {urls} from "../../services/UrlList";
import './singlecomment/single-comment.css';
import { Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';




export default class AdminComment extends Component {
  constructor(props)
  {
      super(props);
      this.state ={
          newComment:'',
          originalComments:[],
          resolved:0,
          showResolveDialogue:false,
          myPrivileges:[],           
      }      
  }

  componentDidMount()
  {
    this.checkUserPrivilege();
    this.getAdminComments(this.props.entryId);
  }

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges });

    if(login.privileges.includes('access_all_reports'))
    {
      this.setState({showPeople:true, showPeopleType:1});
    }else if(login.privileges.includes('access_team_reports'))
    {
      this.setState({showPeople:true, showPeopleType:2});
    }else
    {
      this.setState({showPeople:false, showPeopleType:3});
    }
  };  

  onEditorStateChange =(e)=>
  {
      this.setState({
          newComment:e.target.value
      });
  }

  getAdminComments = (id)=>
  {
    fetch(urls.getAdminComments+id,
      {
          headers:
          {
              'Access-Control-Allow-Origin':'*',
              'Content-Type':'application/json'
          }
      })
      .then(response => response.json())
      .then(response =>
      {
          this.setState({
              originalComments: response[0]['existing_comment_array'],
              resolved: response[0]['resolved'],
          });
      })
      .catch(error =>
      {
          this.setState({
              progressBar:false,
          }); 
      })
  };

  commentSave=()=>
  {
      var login = JSON.parse( localStorage.getItem("user") );
      let opts ={
          newcomment:this.state.newComment,
          adminid: login.uid,
          adminname:login.name,
          entryid:this.props.entryId
      };
      this.setState({
          progressBar:true,
      }); 
      fetch(urls.postAdminComments,
      {
          headers:
          {
              'Access-Control-Allow-Origin':'*',
              'Content-Type':'application/json'
          },
          method: 'post',
          body: JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {
          console.log(response);
          this.setState({
              originalComments: response,
              newComment:'',
              resolved: 0,
          });
          const _this = this;
          setTimeout(function() { //Start the timer
              _this.props.onActionEdit(_this.state.resolved); //After 1 second, set render to true
          }, 1000);
         
      })
      .catch(error =>
      {
         this.setState({
             progressBar:false,
         });        
      });
  }

handleCommentAction = (item) =>
{
 this.setState({
   showRefundAction:item
 });
};

closeDialogue = () => {
  this.setState({
      showResolveDialogue: false,
  });
  this.getAdminComments(this.props.entryId);
};


handleResolveConfirm = () =>
{
 
 this.setState({
   showResolveDialogue: true,     
 });
};

onAction = () => {
  this.setState({
    progressBar: true,      
    showResolveAction:false,
    showResolveDialogue:false,       
  });
  this.getAdminComments(this.props.entryId); 
  const _this = this;
  setTimeout(function() { //Start the timer
      _this.props.onActionEdit(_this.state.resolved); //After 1 second, set render to true
  }, 1000);
};

closeCommentBox = () =>
{
    this.props.onClose();
};

// onEditorStateChange = (editorState) => {
//   this.setState({
//     editorState,
//   });
// };

  render() {
    const { showComment } = this.props;
    return (
      <div id="admin-comment" className={ showComment ? 'show' : 'hide' }>
         <div onClick={this.closeCommentBox.bind(this)} class="close-comment-box">
           <FontAwesomeIcon icon={faTimesCircle} />
         </div>
         <div className="comment-header d-flex">
            <p className="title">Admin Comment</p> 
            {this.state.myPrivileges.includes('show_resolve_option') && this.state.originalComments && this.state.originalComments.length>0?
              (
                <Button 
                  className={this.state.resolved == 1 ? "resolved btn btn-gray-outline" : 'unresolved btn btn-green' }
                  onClick= {this.handleResolveConfirm.bind(this)}
                >
                  { this.state.resolved ? 'Unresolve' : 'Resolve' }
                </Button>
              )
              : null
            }
         </div>
        {this.state.originalComments && this.state.originalComments.length>0?
        (  
          <div>   
          {this.state.originalComments.map((item, id)=> {
           return ( <div className="single-comment d-flex">
              <div className="author-box">
                <p className="author">{item.adminname}</p>
                <p className="time">{item.comment_time}</p>
              </div>
              <div className="comment-box">
                <p>{item.comment}</p>
              </div>
          </div>)
          }
          )}
          </div>
        )
        :
        null
        }
        {this.state.resolved != 1?
        (
          <div id="comment-form" className="d-flex">
            <div className="textarea">
            <Input type="textarea" name="admin-comment-new" value={this.state.newComment}  onChange={this.onEditorStateChange.bind(this)}/>
            </div>
            <Button onClick={this.commentSave.bind(this)} >Add Comment</Button>
        </div>
        )
        :
        null
        } 
        {this.state.showResolveDialogue ? (
        <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showResolveDialogue}
            note="*This action cannot be undone"
            content={this.state.resolved === 1? 'Are you sure you are about to change the status to unresolved?':'Are you sure you are about to change the status to resolved?'}
            handleResolveConfirm={this.onAction}
            resolveid={this.props.entryId}
            resolveStatus={this.state.resolved == 1? '0':'1'}
            adminid={this.state.adminid}
            confirmBoxAction="resolvecomment"
        />
        ) : null}
      </div>
    )
  }
}