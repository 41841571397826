import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card} from 'reactstrap';
import {isMobile} from 'react-device-detect';
import FullUserPicker from "./FullUserPicker";
import './SwitchUser.css'
import DeleteIcon from '../svgicon/DeleteIconLog';
import Alertlogmsg from "../messages/Alertlogmsg";

export default class SwitchUser extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      logs:[],
      showMenu:true,     
      selectedPeople:'',
      success:'',
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Switch User";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    const _this= this;
    setTimeout(() => {
     // _this.getlogTimerReport(); 
    }, 100);
    
  //  this.reloadChecker(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("superadmin") === null)
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  onPeopleChange = (userid) =>
  {        
   this.setState({selectedPeople: userid});
      fetch(urls.usertimelog+userid, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(response => {     
            console.log(response);       
            this.setState({logs: response.log});
        })
        .catch(error => {
            console.log(error);
          this.setState({
            progressBar: false
          });
        });
  }
  
  deleteLogEntry = (id) =>
  {
    let opts={
      userid: this.state.selectedPeople,
      entryid: id
    };
    console.log(opts);
    this.setState({
      logs:[],
      showProgress:true
    });
    fetch(urls.deleteLogEntry,
    {
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
      {
        this.setState({
          logs: response.log,
          success: response.success
        });
      })
    .catch(error => {
      this.setState({
        progressBar: false
      });
    });
  }

  render() {

    
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid>
        <Fragment>
          <Row>
            <div className="theme_header">
                <Header {...this.props}  classname="tick-timer-header"  logout={this.signOut.bind(this)} onmenuaction={this.toggleSidebar.bind(this)} />
            </div>
            <div className="SideNavbar">
            <SideMenu  
            activeitem="admin"
              showmenu={this.state.showMenu} 
              hidemenu={this.hideMenu.bind(this)}  /> 
            </div>
              <div className="themeContentBody">
              <Row>
                <Col sm={12} lg={12}>
                  <Card className="cl_helth_report">
                     <h1 class="page-title her_title">Log Editor</h1>
                        <div id="filter" className="switchUser log-editer">                  
                            <div className="switch_user_wrapper">
                              <FullUserPicker 
                                myid={this.state.myId} 
                                baseid={this.state.myBaseId} 
                                teamid={this.state.userTeam} 
                                usertype={this.state.showPeopleType} 
                                onPeopleChange={this.onPeopleChange.bind(this)} />
                            </div>                     
                    </div>
                    {this.state.success === 0 && 
                      ( <Row>
                          <Col>
                            <Alertlogmsg 
                              msgtext={'Sorry you don\'t have permission to delete a log entry'} 
                              boxclass="task-running" />
                          </Col>
                      </Row>
                    )} 
                    {this.state.logs.map((item, key)=>
                 {
                     return(
                        <div className="log-container log-editor">
                            <p className="d-flex align-items-center">
                                <div className="delete-entry"  >
                                    <DeleteIcon deletelogentry={this.deleteLogEntry} itemid={item.id} />
                                </div>
                                <span className="s-date">
                                    {item.sdate}
                                </span>
                                <span className="login-time">
                                {item.time+": "+item.comment}
                                </span>
                            </p>
                        </div>
                     )
                 })}
                  </Card>
                </Col>
              </Row>
              
            </div>
          </Row>
        </Fragment>
      </Container>
      </div>
    )
  }
}
