const sortByDate = (messages) => {
    let temp = messages;
    temp.sort(descOrder);
    return temp;
    function ascOrder(a, b) {
      return new Date(a.message_time).getTime() - new Date(b.message_time).getTime();
    }
    function descOrder(a, b) {
      return new Date(b.message_time).getTime() - new Date(a.message_time).getTime();
    }
 }

const NotificationMessagesReducer = (state=[], action) =>
{
    switch(action.type)
    {
        case 'AppendNotificationMessages':
            let messages = [...state];
            let newmessage = [action.payload.notification];
            newmessage[0].body_title = action.payload.data['gcm.notification.body_title'];
            newmessage[0].message_time = action.payload.data['gcm.notification.message_time'];
            newmessage[0].receiver = action.payload.data['gcm.notification.receiver'];
            state = sortByDate(newmessage.concat(messages));
            return state;
        case 'SubstractNotificationMessages':
            let messages1 = [...state];
            let itemkey = messages1.map(function (item) { return item.receiver; }).indexOf(action.payload.receiver); 
            console.log(itemkey);
            messages1.splice(itemkey, 1);
            state = sortByDate(messages1);
            return state;
        case 'UnreadNotificationMessages':
                //messages = [...state];
                //messages.push(action.payload);
                state = action.payload;
                return state;
        case 'AppendUnNotificationMessages':
                let messages2 = [...state];
                let newmessage2 = [action.payload];
                //newmessage[0].message_time = action.payload.data['gcm.notification.message_time']
                //newmessage[0].receiver = action.payload.data['gcm.notification.receiver']
                state = sortByDate(newmessage2.concat(messages2));
                return state;
        default:
            return state;
    }
}

export default NotificationMessagesReducer;