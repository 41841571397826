import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';

export default class AvailabilityUsers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getAllUsers();
  };

   getAllUsers = () =>{  
    fetch(urls.reportOnlineCheckUsers,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {      
      
      if (response && response.users.length > 0) {        
        this.setState({
          orginalUsers: response.users,          
        });       
      } 
    })
  };
  onChangePeople = (e) => {
    this.setState({ selectedUser:e[0].id});
    setTimeout(() => {
      this.props.onPeopleChange(this.state.selectedUser);
    }, 100);  
  };

  handleInputChange(input, e) {
    console.log("value", input)
  }

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].id});
      const _this = this;
      setTimeout(() => {
        _this.props.onPeopleChange(_this.state.selectedUser);
      }, 100);
    }else
    {
      this.setState({ selectedUser:''});
      const _this = this;
      setTimeout(() => {
        _this.props.onPeopleChange(_this.state.selectedUser);
      }, 100);
    }
    
  }

    render() {
      
      return (
        <div className="people date-col">
          <Typeahead
          id="user-picker"
          labelKey="name"
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          options={this.state.orginalUsers}
          placeholder="Choose user..."
          selected=''
        />
        </div>
      );
    }
}
