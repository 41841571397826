import React, { Component, Fragment } from 'react';
import './OnlineCheck.css'
import {urls} from "../../services/UrlList";
import { Container, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"

export default class CheckOnline extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      myPrivileges:[],
      initialLoader:false,
      showMsg:'',
      showMsgOne:'Thank You, your response has been recorded.',
      showMsgOneMobile:'Thank You, your response has been recorded. You attempted to verify availability via a mobile device.',
      showMsgTwo:'This link either expired on not exist.',
      showMsgZero: 'Sorry, looks like you are not authorized to record this online check response. Kindly check the user logged in Kimphub.'
    };
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Check User Online";
    this.recordOnlineResponse();
  };


  recordOnlineResponse = () =>{  
    this.setState({initialLoader:true});
    let opts ={    
      user_id: this.props.match.params.uid,
      check_id: this.props.match.params.id
    } 
    console.log(opts);
    fetch(urls.recordOnlineCheck,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {  
          console.log(response[0].success);  
        this.setState({
          showMsg: response[0].success == 1? this.state.showMsgOne : (response[0].success == 2? this.state.showMsgOneMobile: (response[0].success == 3? this.state.showMsgTwo : this.state.showMsgZero) ),
          initialLoader:false       
        });       
      } 
    })
    .catch(error =>
    {
        this.setState({
            initialLoader:false       
          }); 
    })
  };

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <Container fluid className="log-timer trello thanku_msgs"> 
      <Fragment>
       
      
        <Container>
            <h1 class="page-title trello-title text-center">Online Availability Verification</h1>
            <LoadingWrapper isLoading={this.state.initialLoader}>
                <div className="online-response-confirmation text-center">
                    {this.state.showMsg}
                </div>
            </LoadingWrapper>
        </Container>
      
      
      </Fragment>
      </Container>
    )
  }
}