import React,{useState, Fragment} from 'react';
import {Button,Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Starfill from '../../images/star_fav_fill.svg';
import Staroutline from '../../images/star_outline.svg';
import Dropicon from '../../images/drop_board_icon.png';
import BoardMembers from './BoardMembers';
import {AccessCheckBlock } from './AccessCheck';
import MaterialIcon from "material-icons-react";
import ProjectCurrentStatusShort from './ProjectCurrentStatusShort';

export default function SingleBoard(props)
{
    const [showaddpopup, setShowAddPopup] = useState(false);
    const [singleproject, setSingleProject]=useState(props.project);
    const [singleprojectaccess, setSingleProjectAccess]=useState(props.project.board_access_stringified);
    const [dropdownopen, setDropDownOpwn]= useState(false);
    function showhidePopup()
    {
      setShowAddPopup(!showaddpopup);
    }
    function hideMemberPopup()
    {
      setShowAddPopup(false);
    }
    function updateProjectChange(value, memberid, action)
    {
      setSingleProjectAccess(JSON.stringify(value));
      props.updatemembers(singleproject,singleproject.board_id, props.type, value, memberid, action);
    }

    function updateMyFavourites()
    {
      props.updatemyfavourites(singleproject.board_id, singleproject.is_favourite);
    }

    function toggle (){
      setDropDownOpwn(!dropdownopen);
    }

    function deleteMe()
    {
      props.deleteproject(singleproject.board_id);
    }

    function enableForEditing ()
    {
      props.enableforediting(singleproject.board_id, singleproject);
    }

    function showProject ()
    {
      props.showproject(singleproject.bid_cpt);
    }

    function showProjectDocs ()
    {
      props.showdocs(singleproject.bid_cpt);
    }

    function showProjectTasks ()
    {
      props.showtasks(singleproject.bid_cpt, singleproject.board_type, singleproject.view_type);
    }
    
    function updateWhiteLabels()
    {
      props.updatewhitelabels(singleproject.board_id, singleproject.white_label);
    }

    function updateTrialLabels(type)
    {
      props.updatetriallabels(singleproject.board_id, singleproject.client_status, type);
    }

    return(
           <>
            <div className="boardcard card">
               <span className="favourit_icon">
                 <img onClick={updateMyFavourites} src={singleproject.is_favourite===1? Starfill: Staroutline} alt="Favourites" />
                </span>
                {((AccessCheckBlock('edit_project') || AccessCheckBlock('delete_project')) && singleproject.board_id !=1001 ) &&
                (
                  <div className="dropdownboard">
                    <Dropdown  className='ticket-module-selector'
                    isOpen={dropdownopen} 
                    toggle={toggle} >
                      <DropdownToggle color='' caret>
                      <img src={Dropicon} alt="back" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {AccessCheckBlock('edit_project') &&
                        (<DropdownItem onClick={enableForEditing} dropDownValue="Allotments"><i className="fa fa-plane fa-fw"></i>Edit</DropdownItem>)
                        }
                        {AccessCheckBlock('delete_project') &&
                        (<DropdownItem  onClick={deleteMe} dropDownValue="GeoAMS"><i className="fa fa-envelope fa-fw"></i>Delete</DropdownItem>)
                        }
                    </DropdownMenu>
                    </Dropdown>
                  </div>
                )}
              <h2 className="board_name" onClick={showProject}>{singleproject.board_name}
                
              </h2>
               <p className="board_discription" onClick={showProject}>{singleproject.short_description}</p>
               <ul>
                 <div className="memb_avatarlist">
                 <span className="member_count">{JSON.parse(singleprojectaccess).length}</span>
                 {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<Button className="add_member_btn" id={singleproject.board_id} onClick={showhidePopup} ><MaterialIcon icon="add"/></Button>)
                 }
                 </div>
                 {singleproject.client_status && singleproject.client_status!='' &&
                 (
                   <>
                   {singleproject.client_status == '1' ? 
                    <span className='client_status trial'>Trial</span> : (singleproject.client_status == '2' ? <span className='client_status active'>Active</span> :
                    (singleproject.client_status == '3' ? <span className='client_status hold'>Hold</span> :
                    (singleproject.client_status == '4' ? <span className='client_status paused'>Paused</span> : <span className='client_status cancelled'>Cancelled</span>
                    ))) 
                   }
                   </>
                 )}
                 {/* {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<span onClick={updateTrialLabels.bind(this,1)} className={`trial-labeling on-trial ${singleproject.client_status === 1 && 'active'}`}>T</span>)
                 }
                 {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<span onClick={updateTrialLabels.bind(this,2)} className={`trial-labeling on-hold ${singleproject.client_status === 2 && 'active'}`}>H</span>)
                 }
                 {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<span onClick={updateTrialLabels.bind(this,3)} className={`trial-labeling paused ${singleproject.client_status === 3 && 'active'}`}>P</span>)
                 }
                 {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<span onClick={updateTrialLabels.bind(this,4)} className={`trial-labeling cancelled ${singleproject.client_status === 4 && 'active'}`}>C</span>)
                 }
                 {(AccessCheckBlock('add_remove_member_project')  && singleproject.board_id !=1001) &&
                 (<span onClick={updateWhiteLabels} className={`white-labeling ${singleproject.white_label === 1 && 'active'}`}>WL</span>)
                 } */}
                 {singleproject.white_label === 1 &&  
                 (
                  <span  className={`white-labeling ${singleproject.white_label === 1 && 'active'}`}>WL</span>
                 )}
                </ul>
                <div className="board_footer">
                {(singleproject.graphics_pack ||singleproject.video_pack || singleproject.graphics_video_pack) &&
                (
                  <ProjectCurrentStatusShort 
                  graphics={singleproject.graphics_pack}
                  video={singleproject.video_pack}
                  graphics_video={singleproject.graphics_video_pack}
                  />
                )}
                <div className="board-actions d-flex justify-content-between">
                  <span onClick={showProjectTasks}>Cards</span>
                  <span onClick={showProjectDocs}>{'Docs & Files'}</span>
                </div>
               </div>
             </div>
             {showaddpopup &&
             (
              <BoardMembers hidememeberpopup={hideMemberPopup} 
              myid={props.myid} 
              project={singleproject} 
              allmembers={props.fullusers} 
              updatechange={updateProjectChange} />
             )}
            </>
    )};