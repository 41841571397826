import React, { Component, Fragment } from "react";
import { Input, Button, Container, Row, Col } from "reactstrap";
import PageSubTitle from "../../page-title/PageSubTitle";
import "./Logamend.css";
import "./AddmyTask.css";
import LoadingBar from "../../common/LoadingBar";
import ConfirmAction from "../../admincomment/ConfirmAction";
import CloseIcon from "../../svgicon/CloseIcon";
import { urls } from "../../../services/UrlList";
import AddTaskPlusIcon from "../../svgicon/AddTaskPlusIcon";

export default class AddToProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tassignees: this.props.tassignees,
      newassignees: ["" + this.props.myid + ""],
      newassignees: this.props.taskassigneearray,
      myid: this.props.myid,
      activeme: true,
      completionStatus: false,
      showCompletionDialogue: false,
      myPrivileges: [],
      showCompletion: false,
      myburl: "",
      progressBar: false,
      isRequired: false
    };
    this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount() {
    //this.checkUserPrivilege();
  }
 
  _handleKeyDown = (e) => {
    console.log('p');
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) {
       this.handleTaskUpdate();
    }
  }


  handleAccessUpdate = () => {
    if (this.state.myburl != "") {
      this.setState({ progressBar: true, isRequired: false });
      let opts = {
        burl: this.state.myburl,
        myid: this.props.myid,
        mybaseid:this.props.baseid
      };
      fetch(urls.addToProject, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {
          this.setState({ progressBar: false, myburl:'' });
         this.props.ontaskupdate(response.success);
        })
        .catch(error => {
          this.setState({ progressBar: false, myburl:'' });
          this.props.ontaskupdate(0);
        });
    } else {
      this.setState({ isRequired: true });
    }
  };

  handleUrlChange = e => {
    this.setState({ myburl: e.target.value });
  };

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false
    });
  };

  handleCompletionConfirm = () => {
    this.setState({ completionStatus: !this.state.completionStatus });
    // this.setState({completionStatus: false});
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.handleAssigneeUpdate();
    }, 1000);
  };

  closePopup = () => {
    this.props.ontaskupdate();
  };

  render() {
    return (
      <Fragment>
        {/* <div className="add-task-box"> */}
        <div className={this.props.active? "logamend1 add-new-task add-new-project kimptem_pa_addproject active mt-0": 'logamend1 add-new-task' }>
          {this.state.progressBar == true ? <LoadingBar /> : null}
          {/* <CloseIcon closeaction={this.closePopup} /> */}
          {this.props.loading == true ? (
            <LoadingBar />
          ) : (
            <div className="add-new-task-box d-flex">
              <Input
                required
                type="text"
                className={this.state.isRequired ? "required-field" : ""}
                placeholder="Copy and paste basecamp task URL. Example: https://3.basecamp.com/3148328/buckets/10276778/todos/2169514181"
                name="basecamp_url"
                value={this.state.myburl}
                onChange={this.handleUrlChange.bind(this)}
                onKeyDown={this.keyPress}
              />
              <Button
                className="project-assignee-submit btn-green"
                onClick={this.handleAccessUpdate.bind(this)}
              >
                <AddTaskPlusIcon />
              </Button>
            </div>
          )}
        </div>
        {this.state.showCompletionDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showCompletionDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to change the status of this task to completed. This will mark the task status in basecamp as completed."
            handleCompletionConfirm={this.handleCompletionConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
        {/* </div> */}
      </Fragment>
    );
  }
}
