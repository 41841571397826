import React, { Component, Fragment } from "react";
import "./LeaveHeader.css";
import { Link } from "react-router-dom";

export default class LeaveHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apply: "",
      status: "",
      balence: "",
      calender: "",
      myPrivileges: [],
      myId: "",
    };
  }

  componentDidMount() {
    this.checkUserPrivilege(); 
    switch (this.props.active) {
      case "status":
        this.setState({ status: "menuActive" });
        break;
      case "balence":
        this.setState({ balence: "menuActive" });
        break;
      case "calender":
        this.setState({ calender: "menuActive" });
        break;
      default:
        this.setState({ apply: "menuActive" });
        break;
    }
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      console.log(login);
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,   
        employeeType: login.employee_type, 
      });
    }
  };

  render() {
    let { apply, status, balence, calender } = this.state;
    let deviceMobile = window.innerWidth;
    return (
      <Fragment>
        <div id="leave_header" className="d-flex text-upper">
         <p className={balence}>
            {this.state.employeeType === '2' ?
            (
                <Link className="leave-menu-anchor text-caps" to="/leave/balance">
                  { deviceMobile >= 600 ? 'leave balance' : 'leave balance'}
                </Link>
            )
            :
            (
              <Link className="leave-menu-anchor text-caps" to="/leave/balance">
                { deviceMobile >= 600 ? 'leave balance' : 'leave'}
              </Link>
            )
            }
            
          </p>
          <p className={apply}>
            <Link className="leave-menu-anchor" to="/leave/apply">
              Apply
            </Link>
          </p>
          <p className={status}>
            <Link className="leave-menu-anchor" to="/leave/status">
              Status
            </Link>
          </p>
          {/* <p className={calender}>
            <Link className="leave-menu-anchor" to="/leave/calendar">
             Calendar
            </Link>
          </p> */}
        </div>
      </Fragment>
    );
  }
}
