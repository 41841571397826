import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import {urls} from "../../services/UrlList";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, CardTitle, Table } from 'reactstrap';
import BasecampIcon from '../svgicon/BasecampIcon';
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
// Framer Motion
import {motion} from 'framer-motion'
import TrelloIcon from '../svgicon/TrelloIcon';
import { Divider } from '@material-ui/core';

export default class LogTimer extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate());
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("DD-MM-YYYY");
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',    
      selectedTeam:0, 
      showProgress: false,    
      showProgressSearch: false,
      showOnebyOneProgress:false,
      orginalEntries:[],
      fullEntries:[],
      orginalUsers:[],
      pmTeamLeads:[],
      selectedUser:'',
      start_date:momentStringStart,
      //end_date:momentStringEnd,
      show_detail:false,
      show_detail_id:0,
      showCustomRange: true,
      currentDate: momentObjs,
      startDate: null,
      endDate: null,
      show_rate:true,
      myPrivileges:[],
      showPeople:false,
      showPeopleType:'',
      showSelectedResult:false,
      show_detail_date:0,
      showRate:false,
      dailyHours:12,
      hoursOption:'',
      filterHour:'',
      filterTask:'',
      showAdminComments:false,
      adminCommentId:0,
      commentUserId:'',
      commentProjectId:'',
      commentTaskId:'', 
      running_status:0,
      showMenu:true,    
      showAll:false, 
      usersCount:0,
      stopRunningSearch:false,
      rangeReport:false,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Login Timer Reports";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
    this.getPMTeamLeads();
    const _this= this;
   /* setTimeout(() => {
      _this.getlogTimerReport(); 
    }, 100); */
    
    this.reloadChecker(); 
  };
 
  componentWillUnmount = () =>
  {
    this.getPMTeamLeads();
    this.getlogTimerReport();

  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  reloadChecker = () =>
  {
    if(this.props.location.search === '?redirect=1')
    {
      this.props.history.push("/tick-report");
    }
  }  

  onStatusChange = event => {
    this.setState({
      status: event.target.value
    });
  };

  onUserNameChange = event => {
    this.setState({
      userName: event.target.value
    });
  };

  _handleKeyDown = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
    if(code == 13) {           
       this.setState({
        searchTags: this.state.searchTags!="" ? this.state.newTag +","+this.state.searchTags : this.state.newTag,
        newTag:'',
        newTagArray:[...this.state.newTagArray, this.state.newTag]
       });
       
    }
  }


 hoursRender = () =>  {
  const optionsval = [];
  for (var i=1; i <=  12; i++) {
    optionsval.push(<option selected={this.state.filterHour == i? 'selected':''} value={i}>{"< "+i+" hour"}</option>);
  }
  return optionsval;
};

getPMTeamLeads = ()=>
	{
		fetch(urls.getPMTeamLeads,
			{
				headers:{
					'Access-Control-Allow-origin':'*',
					'Content-Type':'application/json'
				}
			})
			.then(response => response.json())
			.then(response =>{
				this.setState({
					pmTeamLeads: response[0].pmtls,
				})
			})
			.catch((error) =>
			{
				console.log(error);
			})
	}

getlogTimerReport = () =>
{
  let opts = {
    date: this.state.start_date,
    team_id: this.state.userTeam,
    user_id: this.state.myId,
    basecamp_id:this.state.myBaseId,
    user_type: this.state.showPeopleType
  }; 
  fetch(urls.getlogTimerReport,{
    headers:{
      'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json'
    },
    method:'post',
    body:JSON.stringify(opts)
  })
  .then(response => response.json())
  .then(response =>
  {
     let list = this.getSortedRecords(response);        
    this.setState({fullEntries:list, orginalEntries:list, showProgress:false});
  })
  .catch(error =>{
    this.setState({showProgress:false});
  })
};

filterByHours=(value)=>
{ 
  this.setState({filterHour:value}); 
  if(value !=''){
    setTimeout(() => {
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      if(this.state.filterTask !='')
      {
        temp.forEach(p1=>
        {   
         var work_hours_split = p1.total_work_hours.split(":");
         var filter_hour_split = this.state.filterHour.split(":");
         // p1.total_pending_tasks <= this.state.filterTask && 
         if( (parseInt(work_hours_split[0]) < parseInt(filter_hour_split[0])) 
         || (parseInt(work_hours_split[0]) == parseInt(filter_hour_split[0])  && 
         parseInt(work_hours_split[1]) < parseInt(filter_hour_split[1]) )) 
         {
           list.push(p1);
         }
        })        
      }else
      {
          temp.forEach(p1=>
          {             
            var work_hours_split = p1.total_work_hours.split(":");
            var filter_hour_split = this.state.filterHour.split(":");
            // p1.total_pending_tasks <= this.state.filterTask && 
            if( (parseInt(work_hours_split[0]) < parseInt(filter_hour_split[0])) 
            || (parseInt(work_hours_split[0]) == parseInt(filter_hour_split[0])  && 
            parseInt(work_hours_split[1]) < parseInt(filter_hour_split[1]) ))  
            {
              list.push(p1);
            }
          })
      }
      this.setState({orginalEntries:list});   
    }, 100);
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
};

filterByTasks=(value)=>
{ 
  this.setState({filterTask:value}); 
  if(value !=''){
    setTimeout(() => {
      let temp=[];
      temp = this.state.fullEntries;
      //console.log(temp);
      let list=[];
      if(this.state.filterHour !='')
      {
        temp.forEach(p1=>
        {   
         var work_hours_split = p1.total_work_hours.split(":");
         if(p1.total_pending_tasks <= this.state.filterTask && parseInt(work_hours_split[0]) < parseInt(this.state.filterHour)) 
         {
           list.push(p1);
         }
        })        
      }else
      {
          temp.forEach(p1=>
          {   
          
           if(p1.total_pending_tasks <= this.state.filterTask) 
           {
             list.push(p1);
           }
          })
      }    
      this.setState({orginalEntries:list});   
    }, 100);
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
};



filterbyhour = (value) =>
{
  this.setState({
    filterHour:value
  });
  this.filterByHours(value);
}

filterbytask = (value) =>
{
  this.setState({
    filterTask:value
  });
  this.filterByTasks(value);
}

filterByNonProductive = (value) =>
{
  if(value !=''){
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      temp.forEach(p1=>
        {             
          if(p1.non_productive>=120)  
          {
            list.push(p1);
          }
        })
      this.setState({orginalEntries:list});   
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
}
getSortedRecords = response => {    
    let temp_list = response;
    let list = [];
    temp_list.forEach(p1 => {
      if (list.length > 0) {
        let found = false;
        list.forEach(p2 => {
          if (p2.user_id === p1.user_id) {
            found = true;
          } else {
            found = false;
          }
        });
        if (found === false) {
          list.push(p1);
        }
      } else {
        list.push(p1);
      }
    });
    return list;       
  };

showDetailEntry = (entry)=>
  {
    this.setState({
      show_detail:true,
      show_detail_date:'',
      show_detail_id: this.state.show_detail_id === entry.user_id? 0:entry.user_id
    });   
  };

  showDetailEntryDate = (entry)=>
  {
    this.setState({
      show_detail:true,
      show_detail_date:this.state.show_detail_date === entry.sdate? '':entry.sdate,
      show_detail_id: 0
    });   
  };

  searchEntriestwo = (start_date, end_date, selectedUser, selectedTeam, selectedPL) =>
  {   
    //return false;
    if(this.state.showPeopleType ==3)
    {
        this.setState({
          start_date:start_date!=null ? start_date : this.state.currentDate,
          end_date:end_date,
          selectedUser:selectedUser,
          showProgress:true,
          rangeReport:false
        });

        let opts = {
          date:start_date!=null ? start_date : this.state.currentDate,
          team_id: this.state.userTeam,
          pl_id:selectedPL,
          user_id: this.state.myId,
          basecamp_id:this.state.myBaseId,
          user_type: this.state.showPeopleType,
          selectedpeople: selectedUser
        };
        
        fetch(urls.getlogTimerReport,{
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {
          let list = this.getSortedRecords(response);  
          this.setState({fullEntries:list, orginalEntries:list, showProgress:false});
        })
        .catch(error =>{
          this.setState({showProgress:false});
        })
    }
    else if(selectedUser>0)
    {
      if(start_date!='' && end_date!='' && start_date!= null && end_date!=null)
      {
        this.setState({
          fullEntries:[], 
          orginalEntries:[],
          showOnebyOneProgress:true,
          rangeReport:true
        });
        let datecount = this.findDateDifference(start_date, end_date);
        this.setState({dateCount:datecount});
        this.loopThroughDates(0,start_date,selectedUser);
      }else
      {
        this.setState({
          start_date: start_date!=null ? start_date : this.state.currentDate,
          end_date:end_date,
          selectedUser:selectedUser,
          showProgress:true,
          rangeReport:false
        });

       

        let opts = {
          date: start_date!=null ? start_date : this.state.currentDate,
          team_id: this.state.userTeam,
          pl_id:selectedPL,
          user_id: this.state.myId,
          basecamp_id:this.state.myBaseId,
          user_type: this.state.showPeopleType,
          selectedpeople: selectedUser
        };
        
        fetch(urls.getlogTimerReport,{
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          },
          method:'post',
          body:JSON.stringify(opts)
        })
        .then(response => response.json())
        .then(response =>
        {
          let list = this.getSortedRecords(response);  
          this.setState({fullEntries:list, orginalEntries:list, showProgress:false});
        })
        .catch(error =>{
          this.setState({showProgress:false});
        })

      }

    }else
    {
      this.setState({
        orginalEntries:[],
        fullEntries:[],
        showOnebyOneProgress:true,
        selectedTeam:selectedTeam,
        rangeReport:false
      }); 
      const _this = this;
      setTimeout(()=>{
        _this.getAllUsers(start_date, selectedTeam, selectedPL);
      }, 100);
    }
  }


  getAllUsers = (start_date, selectedTeam, selectedPL) =>{  
    let opts = {
      team_id: selectedTeam>0? selectedTeam : 0,
      pl_id:selectedPL>0 && selectedPL,
      user_id: this.state.myId,
      basecamp_id:this.state.myBaseId,
      user_type: this.state.showPeopleType,
    };
    var fUrl = urls.getDotoPeopleDailyLog;  
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {     
        this.setState({
          orginalUsers: response,  
          usersCount: response.length        
        });   
        //return false;
        const _this = this;
        setTimeout(()=>{
          var lcount =   _this.loopThroughUsers(0, start_date);
        }, 100);
      } 
    })
  };

  findDateDifference = (start, end) =>
  {
    const startDate  = start;
    const endDate    = end;

    const diffInMs   = new Date(endDate) - new Date(startDate)
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    return diffInDays+1;
  }

  loopThroughDates = (count, start_date, selecteduser) =>
  {
    
    const datee = new Date(start_date);
    datee.setDate(datee.getDate() + count);

    let newdate = datee;
    var lcount = count;
    const _this = this;
    this.setState({
      stopRunningSearch:false
    })
    setTimeout(()=>{
      if(lcount === _this.state.dateCount || this.state.stopRunningSearch)
      {
        this.setState({
          showOnebyOneProgress:false,
          stopRunningSearch:false
        })
        return;
      }
      _this.searchEntriesOnebyOne(newdate, selecteduser);
      lcount++;
      _this.loopThroughDates(lcount, start_date, selecteduser);
    }, 1000);
  }


  loopThroughUsers = (count, start_date) =>
  {
    var lcount = count;
    const _this = this;
    this.setState({
      stopRunningSearch:false
    })
    setTimeout(()=>{
      if(lcount === _this.state.usersCount || this.state.stopRunningSearch)
      {
        this.setState({
          showOnebyOneProgress:false,
          stopRunningSearch:false
        })
        return;
      }
      _this.searchEntriesOnebyOne(start_date, _this.state.orginalUsers[lcount].id);
      lcount++;
      _this.loopThroughUsers(lcount, start_date);
    }, 2000);
  }

  getAllUsersDoto = () =>{  
    let opts ={    
      team_id: this.props.teamid,
      user_id: this.props.myid,
      basecamp_id:this.props.baseid,
      user_type: this.props.usertype
    } 
    var fUrl = urls.getDotoPeople;   
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response && response.length > 0) {        
        this.setState({
          orginalUsersFull: response,          
        });       
      } 
    })
  };
  searchEntriesOnebyOne = (start_date, id) =>
  {   
    let opts = {
      date: start_date,
      selectedpeople: id
    };
    
    fetch(urls.getlogTimerReport,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {
      if(response.length>0 && response[0].totalbreak)
      {
        this.setState({
          fullEntries: this.state.fullEntries.concat(response), 
          orginalEntries:this.state.orginalEntries.concat(response)
        });
      }
    })
    .catch(error =>{
      this.setState({showProgress:false});
    })
  }

  stopActiveSearch = () =>
  {
    this.setState({
      stopRunningSearch:true
    })
  }


  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer"} >
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
        classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)} 
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
           <SideMenu  
            activeitem="report" 
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
        <div className="themeContentBody">
       
         <Card className="themeCard log_timer_card">
            <CardTitle tag="h1" className="themeCard_title">Daily Logs</CardTitle>
            <div className="filter_header_wrap">
                <Filter listcount={this.state.fullEntries.length} 
                  pmTeamLeads = {this.state.pmTeamLeads}
                  filterbytask={this.filterbytask} 
                  filterbyhour={this.filterbyhour} 
                  searchEntriesone={this.searchEntriestwo}
                  filterbynonproductive={this.filterByNonProductive}
                  searchrunning={this.state.showOnebyOneProgress}
                  stopactivesearch={this.stopActiveSearch}
                />
              </div>

              <Table responsive className="theme_table">
                <thead>
                    <tr>
                      
                      <th>
                        {this.state.rangeReport ? 'Date' : 'Name'}
                      </th>
                      <th>Shift Started</th>
                      <th>Login Hours</th>
                      <th>Work Hours</th>
                      <th>Break Time</th>
                      <th>Shift Ended</th>
                      {this.state.myPrivileges.includes('admin_level_filters') &&
                            (
                         <th>Unproductive</th> 
                      )}
                    </tr>
                  </thead>
                  <div id="date-list-contianer" className={'fullwidth_table' }>
                  

                  <LoadingWrapper 
                    LoaderCount={5}
                    isLoading={this.state.showProgress}>
                   { this.state.orginalEntries.map( ( entries, index ) => {
                  return (
                    <tbody className={(this.state.show_detail && (this.state.show_detail_id === entries.user_id || this.state.show_detail_date === entries.sdate)) 
                      ? "user-active"
                      : '' }>
                     <tr onClick={this.state.rangeReport? this.showDetailEntryDate.bind(this, entries) : this.showDetailEntry.bind(this, entries)}>
                      
                      <td scope="row">
                        {this.state.rangeReport ? 
                        entries.sdate
                        : 
                        entries.username
                       }
                      </td>
                      <td scope="row">{entries.sign_in}</td>
                      <td scope="row">{entries.totaltime}</td>
                      <td scope="row">{entries.total_work_hours}</td>
                      <td scope="row">{entries.totalbreaktime}</td>
                      <td scope="row">{entries.sign_out}</td>
                      {this.state.myPrivileges.includes('admin_level_filters') &&
                                  (
                      <td scope="row">{entries.non_productive_display}</td>
                                  )}
                     </tr>
                     
                    <th colSpan="7" className="collapseBox">
                      <div className="collapseBoxdetails">
                    { entries.activities.length > 0 ?
                            (<div className="project-card shift-card daily-log-activities-head new-log">
                              
                              <Table responsive className="theme_table">
                               <thead>
                                 <tr>
                                   <th>Shift Activity</th>
                                   <th>Activity Time</th>
                                   {this.state.myPrivileges.includes('show_user_ip')?
                                  (
                                  <>
                                   <th>User IP</th>
                                   <th>Device</th>
                                  </>)
                                  :
                                  null
                                 }
                                 </tr>
                               </thead>
                               <tbody className="time_details">
                               {entries.activities.map(( activity, index ) => {
                                    return(
                                  <tr key={index}>
                                    <td>{activity.comment}</td>
                                    <td>{activity.time}</td>
                                    {this.state.myPrivileges.includes('show_user_ip')?
                                        (
                                          <>
                                          <td>{activity.user_ip}</td>
                                          <td>{activity.user_device}</td>
                                          </>
                                        )
                                      :
                                      null
                                    }
                                  </tr>
                                 )
                                })
                                } 
                               </tbody>
                              </Table>
                          </div>) : null }

                          {entries.work_activities.length > 0 ? (
                            <div className="project-card work-card new-log">

                            <Table responsive className="theme_table">
                              <thead>
                                <tr>
                                  <th>Board & Card Name</th>
                                  <th>Start Time</th>
                                  <th>End Time</th>
                                  <th>Time Taken</th>
                                  <th>&nbsp;</th>
                                </tr>
                              </thead>
                              <tbody>
                              {entries.work_activities.map((activity, index) => {
                                    var trello = activity.task_name && activity.task_name!='' ? activity.task_name.split("##") : '';
                                    return(
                             
                                <tr key={index}>
                                  <td>
                                    <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                      <span className="project_name">{activity.project_name} </span>
                                      <span className="task_name">{activity.task_name}</span> 
                                    </a>
                                  </td>
                                  <td>
                                    <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                      {activity.start_time}
                                    </a>
                                  </td>
                                  <td>
                                    <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                      {activity.end_time}
                                    </a>
                                  </td>
                                  <td>
                                    <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                      {activity.total}  
                                    </a>
                                  </td>
                                 <td>
                                   <span className='d-flex'>
                                    <BasecampIcon active={this.state.running_status === '1'? true:false} 
                                    projectid={activity.project_id} taskid={activity.task_id}
                                    myId={this.state.myId}  />
                                            {trello.length>1 &&
                                            (
                                              <a target="_blank" href={"https://trello.com/c/"+trello[1]}><TrelloIcon /></a>
                                      )}
                                    </span>
                                  </td>
                                </tr>
                             
                                )
                                }) }
                               </tbody>
                            </Table>

                            </div>

                                ) : null}

                          {this.state.myPrivileges.includes('show_log_summary') &&  entries.work_activities_task.length > 0 ? (
                           
                            <div className="project-card work-card new-log">
                                <Table responsive className="theme_table">
                                  <thead>
                                    <tr>
                                     
                                      <th>&nbsp;</th>
                                      <th>Board & Card Name</th>
                                      <th>Total Time Taken</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {entries.work_activities_task.map((activity, index) => {
                                     var trellosub = activity.task_name && activity.task_name!='' ? activity.task_name.split("##") : '';
                                    
                                    return(
                                    <tr key={index}>
                                      
                                        <th>&nbsp;</th>
                                        <td>
                                          <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                            <span className="project_name">{activity.project_name} </span>
                                            <span className="task_name">{activity.task_name}</span> 
                                          </a>
                                        </td>
                                        <td>
                                          <a href={'/task/detail/'+activity.project_id+'/'+activity.task_id+"?uuid="+this.state.myId} target="_blank">
                                            {activity.total}
                                          </a>
                                        </td>
                                     
                                      <td>
                                      <span className='d-flex'>
                                        <BasecampIcon active={this.state.running_status === '1'? true:false} 
                                        projectid={activity.project_id} taskid={activity.task_id} 
                                        myId={this.state.myId} />
                                            {trellosub.length>1 &&
                                            (
                                              <a target="_blank" href={"https://trello.com/c/"+trellosub[1]}><TrelloIcon /></a>
                                            )}
                                      </span>
                                      </td>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                      <th>&nbsp;</th>
                                    </tr>
                                      )
                                    }) }
                                  </tbody>
                                </Table>
                            
                            </div>
                          ) : null }
                          </div>
                    </th>
                     
                    </tbody>

                   )
                  })
                  }
                 
                   <LoadingWrapper  isLoading={this.state.showOnebyOneProgress}></LoadingWrapper>
                  </LoadingWrapper>
                 
                  </div>

              
              </Table>
              
         </Card>
        
          
          
        </div>
      </Row>
      </Container>

      {/* <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer"} > 
      <Fragment>
        <Row>
        
        <Col sm="10">
        
        
        
        <div id="list-head" className="daily-log">
        <Container>
        
        <Row>
          <Col sm="12">
            <div id="date-list-contianer" className={this.state.showProgress && 'loading'}>
              <LoadingWrapper 
                LoaderCount={5}
                isLoading={this.state.showProgress}>
              {/* {this.state.showProgress && <LoadingBox /> } 
              <motion.section
                initial="hidden"
                animate="visible"
                variants={list}
              >
                { this.state.orginalEntries.map( ( entries, index ) => {
                  return (
                    <motion.div variants={item}>
                      <div 
                        className={(this.state.show_detail && (this.state.show_detail_id === entries.user_id || this.state.show_detail_date === entries.date)) 
                          ? "user-active"
                          : '' }>
                          
                          <Card
                            className="d-flex"
                            onClick={this.showDetailEntry.bind(this, entries.user_id)} >
                            <div className="fullwidth">
                              <Col sm="2">{entries.username}</Col>
                              <Col sm="10">
                                <Row>
                                  <Col sm="2">{entries.sign_in}</Col>
                                  <Col sm="2">{entries.totaltime}</Col>
                                  <Col sm="2">{entries.total_work_hours}</Col>
                                  <Col sm="2">{entries.totalbreaktime}</Col>
                                  <Col sm="2" >{entries.sign_out}</Col>
                                  {this.state.myPrivileges.includes('admin_level_filters') &&
                                  (
                                    <Col sm="2" >{entries.non_productive_display}</Col>
                                  )}
                                 </Row>
                              </Col>
                            
                            </div>
                            
                          </Card>

                          

                      </div>
                    </motion.div>
                    )
                })
                }
               
              </motion.section>
              </LoadingWrapper>
            </div>
          </Col>
        </Row>
      </Container>
      </div>
      </Col>
      </Row>
      </Fragment>
      </Container> */}
      </div>
    )
  }
}
