import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment";
import CalanderIcon from '../../images/date-icon.png';

class DatePickerTick extends Component {
  constructor(props) {
    super(props);
    var d = new Date();
    // if(this.props.startPoint>=1)
    // {
    //   d.setDate(d.getDate() - this.props.startPoint ); 
    // }else
    // {
    //   d.setDate(d.getDate()); 
    // }
   // d.setDate(d.getDate() - 1);      
  //  d.setDate(d.getDate() - 1);  // yesterday date
   d.setDate(d.getDate());  // today date
    this.state = {
      date: d,
      sdate:d,
    };
  }

  onChange = (date) => {
    this.setState({ date:date});
    setTimeout(() => {
      this.props.onDateRangeChange(this.state.date);      
    }, 100);    
  };

    render() {
      return (
        <div className="tick_pickers">         
         <DateTimePicker         
          format="dd-MM-y"
          onChange={this.onChange.bind(this)}
          value={this.state.date}
          disableClock="true"
          clearIcon
          calendarIcon={<img className="calander-icon" src={CalanderIcon} width="18" height="19" alt="calander-icon" />} />
        </div>
      );
    }
}

export default DatePickerTick;
