import React from 'react';
import './PageTitle.css';

export default function PageSubTitle( props ) {
  return (
    <h4 className="page-sub-title text-center">
      { props.title }
    </h4>
  )
}
