import React, { Component, Fragment } from 'react';
import './TaskSingle.css';
import TaskStartIcon from '../../svgicon/TaskStartIcon';
import TaskEndIcon from '../../svgicon/TaskEndIcon';
import Pmtimer from "../Pmtimer";
import BasecampIcon from '../../svgicon/BasecampIcon';
import TrelloIcon from '../../svgicon/TrelloIcon';
import Reassign from "../../../images/reassign.png";
import { Button } from 'reactstrap'
import MaterialIcon from "material-icons-react";
import moment from "moment";

export default class TaskSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionTaskId: '',
      taskId: this.props.task_id,
      showExpandView: false,
      trelloCard: '',
      taskLabels: [],
      taskName: '',
    };
  }

  componentDidMount = () => {
    this.findTrelloId();
    this.showTags();
  };

  findTrelloId = () => {

    var cardid = this.props.task_name.match(new RegExp("## " + "(.*)" + ""));
    if (cardid === null) {
      var cardid = this.props.task_name.match(new RegExp("##" + "(.*)" + ""));
    }
    if (cardid != null) {
      this.setState({
        trelloCard: cardid[1]
      })
    }
  }

  handleStartClick = () => {
    console.log(this.props.mtask.tid_cpt);
    this.props.myactions(this.props.mtask.tid_cpt, 'start', this.props.type, this.props.task_id, this.props.project_id, this.props.task_name, this.props.project_name);
  }


  handleEndClick = () => {
    this.props.myactions(this.props.mtask.tid_cpt, 'end', this.props.task_id, this.props.project_id, this.props.task_name, this.props.project_name);
    this.setState({ showExpandView: false });
  }

  showExpandView = () => {
    this.props.history.push("/home");
    //this.setState({showExpandView: !this.state.showExpandView})
    //this.props.history.push("task/detail/"+this.props.mtask.bid_cpt+"/"+this.props.mtask.tid_cpt);
  };

  showAmendBox = (tid, id, pid, taskname) => {
    this.props.taskamend(tid, id, pid, taskname);
  }


  showTrelloCard = () => {
    this.props.showtrello(this.state.trelloCard);
  };

  showTags = () => {
    var matches = [];
    this.props.task_name.replace(/\[(.*?)\]/g, function (g0, g1) { matches.push(g1); })
    var title = this.props.task_name.replace(/(\[.*?\]|\(.*?\)) */g, "");
    this.setState({
      taskLabels: matches,
      taskName: title,
    });
  }

  quickClose = () => {
    this.props.quickclose(this.props.task_id, this.props.mtask.tid_cpt, this.props.mtask.bid_cpt, this.props.type);
  }

  render() {
    const classes = 'single-task ' + this.props.classname;
    return (
      <article className={`single-task-container-projects ${classes}`} >
        <h6>Task Running</h6>
        <div className="single-task-container d-flex">
          <a className="single-task-link" href={"/task/detail/" + this.props.mtask.bid_cpt + "/" + this.props.mtask.tid_cpt}>
            <div className="box task-details" >
              <p className="task-client-detail">
                {this.props.project_name}
              </p>
              <p className="task-project-name">
                {this.props.task_name}
              </p>

              <div className="d-flex">
                {this.props.project_id !== 'gIiAZmVlZoCIgA==' &&
                  (
                    <p className="task-client-detail task-owner">
                      Due: <span className='due_date_task'>
                        {(this.props.mtask.due_date && this.props.mtask.due_date !=='')  &&
                        (
                          moment((new Date(this.props.mtask.due_date)).getTime()).tz(this.props.mytimezone).format('DD MMM, h:mm a')
                        )}
                        </span>
                    </p>
                  )}
                {this.props.manager != null ?
                  (
                    <p className="task-client-detail task-owner">
                      Posted By:  <span className='postby_name'> {this.props.manager}</span>
                    </p>
                  )
                  :
                  null
                }
              </div>
              <p className="task-project-name task-labels-priorities d-flex">
                {(this.props.mtask.labels && this.props.mtask.labels.length > 0) &&
                  (
                    <div>
                      {this.props.mtask.labels.map((item, index) => {
                        return (
                          <span className={item.name.toLowerCase()}>
                            {item.name}
                          </span>
                        )
                      })}
                    </div>

                  )}
                {this.props.mtask.task_priority === 1 &&
                  (
                    <span className="task-priority">Priority</span>
                  )}
                {this.props.mtask.approval_waiting != 0 &&
                  (
                    <span className="task-priority tba">TBA</span>
                  )}
              </p>
            </div>
          </a>
          <div className="d-flex taskcard_footer">
            <div className="d-flex">
              {this.props.running_status == 1 &&
                (
                  <div className="d-flex box task-btn">
                    <div className={(this.props.running_status === 1) ? "activeicon box task-end" : 'box task-end'}>
                      <Button activeclass={(this.props.running_status === 1) ? "activeicon d-flex" : ''} onClick={this.handleEndClick.bind(this)}> Stop Task </Button>
                    </div>
                  </div>
                )}
              <>{(this.props.running_status === 1) ?
                (
                  <Pmtimer starttimer={this.props.starttimer}
                    taskmilli={this.props.mtask.task_total} />
                )
                :
                (<div className="box task-total-time">
                  {this.props.running_status !== 1 ? this.props.totaltoday : null}
                </div>)
              }
              </>
            </div>
            <div className="icon_actions">
              {this.props.running_status !== 1 ?
                (
                  <>
                    <div className="box task-shot-info" onClick={this.showAmendBox.bind(this, this.props.mtask.tid_cpt, this.props.task_id, this.props.mtask.bid_cpt, this.props.task_name)}>
                      {this.props.project_id !== 1001 &&
                        (
                          <Button className="reassign_btn"><MaterialIcon icon="person_add" /></Button>
                        )}
                    </div>
                    {(this.props.allowclose && this.props.project_id != 1001) ?
                      (
                        <div className="quick-close-task" onClick={this.quickClose.bind(this)}>
                          <MaterialIcon icon="done" />
                        </div>
                      )
                      :
                      (<div className="empty-icon">&nbsp;</div>)
                    }
                  </>
                )
                :
                (
                  <>
                    <div className="box task-shot-info" ></div>
                    <div className="empty-icon">&nbsp;</div>
                  </>
                )
              }
              {this.state.trelloCard != '' ?
                (
                  <TrelloIcon showtrellocard={this.showTrelloCard} />
                )
                :
                (<div className="empty-icon">&nbsp;</div>)
              }
            </div>
          </div>
        </div>
      </article>
    )
  }
}