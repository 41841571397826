import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Header from '../header/Header';
import Filter from "../filter/Filter";
import DataList from '../datalist/DataList';
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import SearchIcon from "../svgicon/SearchIcon";
import moment from "moment";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, CardTitle } from 'reactstrap';
import BasecampIcon from '../svgicon/BasecampIcon';
import {isMobile} from 'react-device-detect';
import FullUserListBox from "./FullUserListBox";
import HolidayPicker from "./HolidayPicker";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import Alertlogmsg from "../messages/Alertlogmsg";
// LoadingBox
import LoadingBox from '../LoadingBox/LoadingBox'
// Framer Motion
import {motion} from 'framer-motion'
import './SwitchUser.css'

export default class TeamHolidays extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      showProgress: true,    
      showProgressSearch: true,
      selectedUser:'',
      myPrivileges:[],
      showMenu:true, 
      fullHolidays:[],
      selectedHoliday:'',   
      selectedHolidayName:'',
      selectedPeoples:'',
      holidayNotSet:false,
      peopleNotSet:false,
      showNotice:false,
      showSuccessMsg:false,
      successMsg:"Team holiday successfully applied and auto approved for selected peoples.",
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Apply & Approve Team Holiday";
    this.checkUserPrivilege(); 
    this.renderContentDevice(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    if(!login.privileges.includes('am_admin'))
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  componentDidUpdate(prevState) {
    if (prevState.showSuccessMsg !== this.state.showSuccessMsg && this.state.showSuccessMsg === true)
    {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showSuccessMsg: false });
        window.location.reload();
      }, 6000);
    }
  }
  

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  holidayChange = (holiday, holidayname)=>
  {
    console.log(holiday);
    this.setState({
      selectedHoliday:holiday,
      selectedHolidayName:holidayname,
      holidayNotSet: false
    })
  }

  holidayPeople = (peoples)=>
  {
    console.log(peoples);
    this.setState({
      selectedPeoples:peoples
    })
  }


  trigerHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      console.log("ddd");
      this.setState({peopleNotSet: true});
      return false;
    }
    this.setState({
      showNotice:true
    });
  }

  cancelHolidayLeave = ()=>
  {
    this.setState({
      showNotice:false
    });
  }
  
  applyHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      this.setState({peopleNotSet: true});
      return false;
    }
   

    fetch(urls.applyLeaveHoliday,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
      .then(response => {      
          console.log(response);
          ReactDOM.findDOMNode(this).scrollIntoView();
          this.setState({showSuccessMsg:true, showNotice:false});
      })
      .catch(error => {
          console.log("error");
      });
      

  }

  render() {

    
    return (
      <div className="fullwidth_mobilemenu">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
               classname="tick-timer-header"  
               logout={this.signOut.bind(this)} 
               onmenuaction={this.toggleSidebar.bind(this)} 
               quickactionblock={true} />
        </div>
        <div className="SideNavbar">
          <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody themefullwidthContentBoday">
        <Row>
          <Col sm={12} lg={12}>
            <Card className="switch_user_card">
              <CardTitle tag="h1">Apply & Approve Team Holiday</CardTitle>
              {this.state.showSuccessMsg ? (
                    <Alertlogmsg
                      msgtext={this.state.successMsg}
                      boxclass="task-running"
                    />
                  ) 
                  : null}
                 <div id="filter" className="switchUser teamholidays">                  
                            <div className="switch_user_wrapper">
                                <HolidayPicker
                                onholidaychange={this.holidayChange}
                                holidaynotset={this.state.holidayNotSet}
                                />
                            </div>   
                    </div>
                    <div id="filter" className="switchUser teamholidays">                  
                            <div className="switch_user_wrapper">
                                <FullUserListBox 
                                myid={this.state.myId} 
                                baseid={this.state.myBaseId} 
                                teamid={this.state.userTeam} 
                                usertype={this.state.showPeopleType} 
                                peoplenotset={this.state.peopleNotSet}
                                holidaypeople={this.holidayPeople} />
                                
                            </div>   
                    </div>
                    <div className="action-box">
                      {this.state.showNotice?
                      (
                        <>
                          <div className="confirm-nortice">{"You are about to apply for a team Holiday (" +this.state.selectedHolidayName+") for the above selected peoples. This action is irrevocable."}</div>
                          <div className="d-flex justify-content-center">
                            <Button className="btn-green mr-20" name="submit" value="Submit" onClick={this.applyHolidayLeave.bind(this)}>Confirm</Button>
                            <Button className="btn-gray" name="submit" value="Submit" onClick={this.cancelHolidayLeave.bind(this)}>Cancel</Button>
                          </div>
                        </>
                      )
                      :
                      (
                        <div className="d-flex justify-content-center">
                            <Button className="btn-green" name="submit" value="Submit" onClick={this.trigerHolidayLeave.bind(this)}>Submit</Button>
                        </div>
                      )
                      }
                    </div>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
</Container>
      </div>
    )
  }
}
