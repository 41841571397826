import React, {Component} from 'react';
import './SwithUserSideMenu.css';
import Lottie  from 'react-lottie'
// import Icon from '../63-home-solid.json'
import IconOutline from './397-toggle-radio-button-1-morph-outline.json'
import { Link} from "react-router-dom";

export default class LoanSideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isStopped: true,
            isPaused: true,
            menuActive: false
        }
    }
    
    animationStart = () => {
        this.setState({
            isStopped: false,
            isPaused: false
        });
    }
    
    animationStop = () => {
        this.setState({
            isStopped: true,
            isPaused: true
        });        
    }

    render() {        

        let defaultOptions = {
            loop: false,
            autoplay: false,
            animationData: IconOutline,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }

        return(
            <div
                className={"nsmi_home menu-item-home menu-item d-flex"+(this.props.activeitem === 'switch'? ' active' : '') }
                onMouseEnter={this.animationStart}
                onMouseLeave={this.animationStop}>
                 <Link to="/switch-user"><div className="nsmi_menu_wrapper d-flex">
                    <div className="nsmi_menu-icon" >
                        <Lottie 
                            options={defaultOptions}
                            height={35}
                            width={35}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}
                        />
                    </div>                
                    <p className="nsmi_menu_name">Switch User</p>
                    {/* <div className="menu_icon_arrow ml-auto"></div> */}
                </div></Link>
            </div>
        )
    }
}