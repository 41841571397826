import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from "react-select";

class UserTeams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullTeams:[],
      selectedTeam:'',
      value: []
    };  
   
   
  }

  componentDidMount = () => {
    this.getAllTeams();
  };

  getAllTeams = () =>{  
    var fUrl = urls.getFullTeams;   
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {      
      if (response ) {        
        this.setState({
            fullTeams: response,          
        });       
      } 
    })
  };


  onChangePeople = (e) => {
    this.setState({ selectedTeam:e[0].id});
    setTimeout(() => {
      this.props.onteamchange(this.state.selectedTeam);
    }, 100);  
  };
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  handleChange = (e) => {
    this.setState({ selectedTeam:e.target.value});
    this.props.onteamchange(e.target.value);
  }

  // handleChange = (selectedOption) => {
  //   this.setState({ selectedOption }, () =>
  //     console.log(`Option selected:`, this.state.selectedOption)
  //   );
  // };


    render() {

      return (
<>
   

        <div className="people pl_list date-col">
          <p className="filter-label">Team: </p>


            <select name="team"  className="form-control"
                onChange={this.handleChange.bind(this)} >
                <option value={0}>Select</option>
                {this.state.fullTeams.map((team, index) =>
                {
                return(
                    <option value={team.id}
                    selected={this.state.selectedTeam == team.id? 'selected':''}
                    >{team.name}</option>
                )
                })}
            </select>

        </div>
        </>
      );
    }
}

export default UserTeams;
