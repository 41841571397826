import React, { Component, Fragment } from 'react';
import ReactDOM from "react-dom";
import Header from '../../header/Header';
import {urls} from "../../../services/UrlList";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import {Container, Row, Col, Card, CardTitle, FormGroup, Label, Input, Button} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './InternaltimerStyle.css'
import FollowActivites from './followActivites' 
import UrgentMessageModal from './urgentMessage'
import MaterialIcon from "material-icons-react";
export default class Yourplate extends Component {
  constructor( props ) {
    super( props );      
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      isActive: false,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }
  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });  };

  componentDidMount =() =>
  {   
    document.title = "HUB - Apply & Approve Team Holiday";
    this.checkUserPrivilege(); 
    // this.renderContentDevice(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    if(!login.privileges.includes('am_admin'))
    {
      this.props.history.push("/home");
    }
    
    if(localStorage.getItem("user") !== null)
    {
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });

      // if(!login.privileges.includes('allow_user_switching'))
      // {
      //   this.props.history.push("/home");
      // }
    }
  };

  componentDidUpdate(prevState) {
    if (prevState.showSuccessMsg !== this.state.showSuccessMsg && this.state.showSuccessMsg === true)
    {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showSuccessMsg: false });
        window.location.reload();
      }, 6000);
    }
  }
  

  

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  holidayChange = (holiday, holidayname)=>
  {
    console.log(holiday);
    this.setState({
      selectedHoliday:holiday,
      selectedHolidayName:holidayname,
      holidayNotSet: false
    })
  }

  holidayPeople = (peoples)=>
  {
    console.log(peoples);
    this.setState({
      selectedPeoples:peoples
    })
  }


  trigerHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      console.log("ddd");
      this.setState({peopleNotSet: true});
      return false;
    }
    this.setState({
      showNotice:true
    });
  }

  cancelHolidayLeave = ()=>
  {
    this.setState({
      showNotice:false
    });
  }
  
  applyHolidayLeave = ()=>
  {
    let opts = {
      holiday: this.state.selectedHoliday,
      peoples: this.state.selectedPeoples
    };

    if(this.state.selectedHoliday =='')
    {
      this.setState({holidayNotSet: true});
      return false;
    }else if(this.state.selectedPeoples.length===0)
    {
      this.setState({peopleNotSet: true});
      return false;
    }
   

    fetch(urls.applyLeaveHoliday,{
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      },
      method: "post",
      body: JSON.stringify(opts)
    })
    .then(response => response.json())
      .then(response => {      
          console.log(response);
          ReactDOM.findDOMNode(this).scrollIntoView();
          this.setState({showSuccessMsg:true, showNotice:false});
      })
      .catch(error => {
          console.log("error");
      });
      

  }

  render() 
  
  {
    const isActive = this.state.isActive;
    
    return (
      <>
     
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
               classname="tick-timer-header"  
               logout={this.signOut.bind(this)} 
               onmenuaction={this.toggleSidebar.bind(this)} 
               quickactionblock={true} />
        </div>
        <div className="SideNavbar">
          <SideMenu activeitem="admin" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
        </div>
        <div className="themeContentBody">
        <Row>
          <Col sm={12} lg={12}>
            <Card className="switch_user_card your_plate_card">
              <CardTitle tag="h1">Here’s what’s on your plate</CardTitle>
              <Row>
                <Col xs={12} sm={6} md={4}>
                  <div className="plate_card">
                    <span className="task-client-detail-plate">DESIGN COLLECTIONS - UI/UX {'{INTERNAL}'}</span>
                    <span className="task-project-name-palate">Designers Name : Team Ui/Ux</span>
                    <span className="date_and_time_platecard"><MaterialIcon icon="event"/> Fri, Jan 14</span>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="plate_card">
                    <span className="task-client-detail-plate">DESIGN COLLECTIONS - UI/UX {'{INTERNAL}'}</span>
                    <span className="task-project-name-palate">Designers Name : Team Ui/Ux</span>
                    <span className="date_and_time_platecard"><MaterialIcon icon="event"/> Fri, Jan 14</span>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="plate_card">
                    <span className="task-client-detail-plate">DESIGN COLLECTIONS - UI/UX {'{INTERNAL}'}</span>
                    <span className="task-project-name-palate">Designers Name : Team Ui/Ux</span>
                    <span className="date_and_time_platecard"><MaterialIcon icon="event"/> Fri, Jan 14</span>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="plate_card">
                    <span className="task-client-detail-plate">DESIGN COLLECTIONS - UI/UX {'{INTERNAL}'}</span>
                    <span className="task-project-name-palate">Designers Name : Team Ui/Ux</span>
                    <span className="date_and_time_platecard"><MaterialIcon icon="event"/> Fri, Jan 14</span>
                  </div>
                </Col>
                <Col xs={12} sm={6} md={4}>
                  <div className="plate_card">
                    <span className="task-client-detail-plate">DESIGN COLLECTIONS - UI/UX {'{INTERNAL}'}</span>
                    <span className="task-project-name-palate">Designers Name : Team Ui/Ux</span>
                    <span className="date_and_time_platecard"><MaterialIcon icon="event"/> Fri, Jan 14</span>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        </div>
      </Row>
</Container>
      </>
    )
  }
}

