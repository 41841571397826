import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";
import { Typeahead } from 'react-bootstrap-typeahead';
import { Label} from 'reactstrap';

class UserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[{'id':'0', 'name': 'All'}],
      orginalUsersReminder:[],
      selectedUser:[]
    };  
  }

  componentDidMount = () => {
    this.getFullUsers();
  };

   getFullUsers = async () =>{        
    var fUrl = urls.getFullDotoUsers;    
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.findSelectedUser(this.state.orginalUsers.concat(response[0].users));    
        this.setState({
          orginalUsers: this.state.orginalUsers.concat(response[0].users),
          orginalUsersReminder: response[0].users,          
        });            
    })
  };

  findSelectedUser=  async (originalusers) =>
  {
    let findex = originalusers.findIndex(e => e.id == this.props.selecteduser);
    this.setState({ selectedUser:[{'id':originalusers[findex].id, 'name': originalusers[findex].name}] })
  }

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
        this.setState({ selectedUser:[selectedOptions[0]]});
        this.props.onPeopleChange(selectedOptions);
    }else
    {
      this.props.onPeopleChange(selectedOptions);
    }
  }
  
  handleInputChange = (input, e) => {
    this.setState({ selectedUser:[{'id':'1', 'name': input}]});
    console.log("value", input)
  }
    render() {
      return (
        <div className="someone-activity people date-col d-flex align-items-center">
          <Label>Someone's Activity:</Label>
          <Typeahead
          id="user-picker"
          labelKey="name"
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          options={this.state.orginalUsers}
          placeholder="Choose User"
          selected={this.state.selectedUser}
        />
         
        </div>
      );
    }
}

export default UserPicker; 
