import React, {Component} from 'react';
import {Input, Label, Form, FormGroup, Button, Card } from 'reactstrap'
import './Feed.General.css'
import FeedComments from '../FeedComments/FeedComments';
import {urls} from "../../../services/UrlList";

export default class FeedGeneral extends Component  {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     feed_position: 'img-bottom'
  //   }
  // }

  render() {
    const { name, body, image, postion, feedData } = this.props;
    const { feed_image_position, feed_image } = feedData;
    let cls = this.props.classname ? this.props.classname : null;
    let imgBg, position = 'bottom', style;

    if( feed_image_position === 1 ) {
      position = 'top';
    } else if ( feed_image_position === 2 ) {
      position = 'right';
    } else if ( feed_image_position === 3 ) {
      position = 'bottom';
    } else if ( feed_image_position === 4 ) {
      position = 'left';
    } else if ( feed_image_position === 5 ) {
      position = 'background';
      style = {
          background: `url(${urls.storageUrl+feed_image})`,
          backgroundSize:'cover',
          backgroundPosition: 'top center'
        }
    }

    let cardCls = "feed-item feed-item-general d-flex d-flex-col img-"+ position;

    let isBgImg = position === 'background' ? 'feedGen_bg_img_card' : '';

    return (
      <div>
          {
            feed_image_position === 5 && <p class="text-center feed-post-date mb-auto feed_general_has_bg">{feedData.feed_posted_on}</p>
          }
        <section id="feed_general" className={cls} style={style}>
          <Card className={isBgImg}>
            {
              feed_image_position !== 5 && <p class="text-center feed-post-date mb-auto">{feedData.feed_posted_on}</p>
            }
            <h2 className="text-caps feed-heading mb-auto">{feedData.feed_heading}</h2>
            <div className={cardCls}>
                { ( feedData.feed_image && position !== 'background' ) ? (
                    <div className="feed_image">
                      <img src={urls.storageUrl+feedData.feed_image} alt='feed_image' />
                    </div>
                ) : null }
              <div className="content">
                <div id="editor_wrapper" dangerouslySetInnerHTML={ {__html: feedData.feed_content} } ></div>
              </div>
            </div>        
            {this.props.iscomment === '1' ?
                (<FeedComments 
                isLike={feedData.like_count}
                likeCount={feedData.like_count}
                likeAction={this.props.likeAction}
                commentCount={feedData.comments.length}
                commentAction={ this.props.updateComment }
                commentValue={this.props.commentValue}
                feedId={feedData.id}
                postFeedComment={this.props.postFeedComment}
                allCommentsLengh={feedData.comments.length}
                allComments={feedData.comments}
                catId={feedData.category_id} 
                fName="anniversarycomment" />)
                : null
              }
              </Card>
        </section>
      </div>
    )
  }
}