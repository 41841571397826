import React, { Component, Fragment } from "react";
import "./LoanSummery.css";
import { InputGroup, Input, Label, Card, Table } from "reactstrap";
import LoanSummeryList from "./LoanSummeryList/LoanSummeryList";
import {urls} from "../../../services/UrlList";

export default class LoanSummery extends Component {
  constructor(props) {
    super(props);
    this.state = {
    summarylist:[],
    summarylistFull:[],
    typeFilter:'',
    pendingLoans:[],
    refresh: this.props.refresh,
    };
  }

  componentDidMount =() =>
  {
   this.getPendingLoans();
   this.getLoanSummaryList();   
  }


  getLoanSummaryList = () =>
  {
    fetch(urls.getLoanSummaryList+this.props.myid,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{
          
          this.setState({
              summarylist:response.summary,
              summarylistFull: response.summary,
          })
         
      })
      .catch(error =>
      { 
          this.setState({loading:false});
      })   
  }

  getPendingLoans = () =>
  {    
    fetch(urls.getMyLoansWaiting+this.props.myid,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type': 'application/json'
      }
      })
      .then(response => response.json())
      .then(response =>{        
          this.setState({
              pendingLoans:response.summary,             
          })
         
      })
      .catch(error =>
      { 
          this.setState({loading:false});
      })   
  }


filterByType=(e)=>
{  
  this.setState({typeFilter: e.target.value}); 
  if(e.target.value !=''){    
      let temp=[];
      temp = this.state.summarylistFull;     
      let list=[];

      if(e.target.value == 1)
      {
        temp.forEach(p1=>
        {            
         if(p1.credit>0) 
         {
           list.push(p1);
         }
        }) 
      }else
      {
        temp.forEach(p1=>
        {           
         if(p1.debit>0) 
         {
           list.push(p1);
         }
        }) 
      }

      this.setState({summarylist:list});   
   
  }else
  {
    this.setState({summarylist:this.state.summarylistFull});  
  }
};


  render() {
    return (
      <Card id="loan-summery-wrapper">
        <h3 className="title">Transactions</h3>
         <Table responsive className="theme_table">
           <thead>
             <tr>
               <th>Date</th>
               <th>Description</th>
               <th>Amount</th>
               <th>
               <Input type="select" className="amount" onChange={this.filterByType.bind(this)} >
                <option value=''>Type</option>
                <option value='1'>Loan</option>
                <option value='2'>Repay</option>
              </Input>
               </th>
               <th>
               Status
               </th>
             </tr>
           </thead>
      
          
        {this.state.typeFilter === '' && this.state.pendingLoans.length>0?
        (
          <>
            {this.state.pendingLoans.map((pending, index) =>
            {
              return(
                  <LoanSummeryList item={pending} key={index} pending='1' />
              )
            }
            )}
          </>
        )
        :
        null
        }


        {this.state.summarylist.length>0?
        (
          <>
          { this.state.summarylist.map((item, index) =>
          {
            return(
              <LoanSummeryList item={item} key={index} />
            )
          })}
          </>
        ):
        null
        }  


        </Table>
      </Card>

    );
  }
}
