import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import './Qadashboard.css'
import Header from '../header/Header';
import {Container, Row, Col, Card, FormGroup} from 'reactstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Qaitems from './Qaitems'
import Qaitemsinbetween from './Qaitemsinbetween'
import Qaitemslogout from './Qaitemslogout'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import moment from "moment";
import ReactQuill from 'react-quill'; 


export default class QaBoard extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myQuestionType:'',
      myBaseId:'',
      userTeam:'',  
      initialLoader:true, 
      loginQa1:[], 
      loginQa2:[], 
      inbitweenQa1:[],
      inbitweenQa2:[],
      logoutQa1:[],    
      designers:[],
      managers:[],
      uRole:'',
      activeShift:1,
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Dashboard";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {     
    _this.getmyQuestions();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({
        myPrivileges:login.privileges, 
        userTeam:login.user_team, 
        myId:login.uid, 
        myBaseId:login.basecampid,
        myQuestionType:5,
      });
    }
  };
  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getmyQuestions = () =>
  {
    let opts = {
      user_id: this.state.myId,
      qtype: this.state.myQuestionType
    };
    fetch(urls.getMyQuestions,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body: JSON.stringify(opts)
       })
      .then(response => response.json())
      .then(response =>
      {  
        this.mergeByProperty(response.login_step1, response.answers, 'question_id', 'loginQa1');              
        this.mergeByProperty(response.login_step2, response.answers, 'question_id', 'loginQa2');              
        this.mergeByProperty(response.between_step1, response.answers, 'question_id', 'inbitweenQa1');              
        this.mergeByProperty(response.between_step2, response.answers, 'question_id', 'inbitweenQa2');              
        this.mergeByProperty(response.logout_step1, response.answers, 'question_id', 'logoutQa1');              
       
        this.setState({
          initialLoader:false,
          uRole: response.urole,
          activeShift: response.active_shift,
          myshift:response.shiftdate,
          designers: response.designers,
          managers: response.managers
        })
      })
      .catch(error =>
        {
          this.setState({
            initialLoader:false
            })    
        });
  };

  mergeByProperty = (target, source, prop, cname) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
    this.setState({[cname]:target});
    console.log(target);
  }

  updateQrAnswer = (qstype, qid, answer_opt, answer_text, pms, dsgns) =>
  {
    if(qstype === 1) // login part 1
    {
      var changeindex = this.state.loginQa1.map((el) => el.question_id).indexOf(qid);
      this.changeAnswerInFetched(changeindex, 1, this.state.loginQa1, answer_opt, answer_text, pms, dsgns);
    } else if(qstype === 2) // login part 2
    {
      var changeindex = this.state.loginQa2.map((el) => el.question_id).indexOf(qid);
      this.changeAnswerInFetched(changeindex, 2, this.state.loginQa2, answer_opt, answer_text, pms, dsgns);
    }else if(qstype == 3) // inbetween part 1
    {
      var changeindex = this.state.inbitweenQa1.map((el) => el.question_id).indexOf(qid);
      this.changeAnswerInFetched(changeindex, 3, this.state.inbitweenQa1, answer_opt, answer_text, pms, dsgns);
    } else if(qstype == 4) // inbetween part 2
    {
      var changeindex = this.state.inbitweenQa2.map((el) => el.question_id).indexOf(qid);
      this.changeAnswerInFetched(changeindex, 4, this.state.inbitweenQa2, answer_opt, answer_text, pms, dsgns);
    }else if(qstype == 5) // logout part 1
    {
      var changeindex = this.state.logoutQa1.map((el) => el.question_id).indexOf(qid);
      this.changeAnswerInFetched(changeindex, 5, this.state.logoutQa1, answer_opt, answer_text, pms, dsgns);
    }
    this.saveAnswerToDB(qid, answer_opt, answer_text, pms, dsgns);
  }


  changeAnswerInFetched = (index, section, collection, answer_opt, answer_text, pms, dsgns)=>
    {
        let temp = collection;
        temp[index].answer_yes_no = answer_opt;
        temp[index].answer_text = answer_text;
        temp[index].project_managers = pms;
        temp[index].designers = dsgns;
        if(section === 1)
        {
          this.setState({loginQa1:temp});
        }else  if(section === 2)
        {
          this.setState({loginQa2:temp});
        } else  if(section === 3)
        {
          this.setState({inbitweenQa1:temp});
        } else  if(section === 4)
        {
          this.setState({inbitweenQa2:temp});
        }else  if(section === 5)
        {
          this.setState({logoutQa1:temp});
        }
    }

    updateQTagsDesigners = (section, k, tag)=>
    {
      if(section == 1)
      {
        let temp = this.state.loginQa1;
        if(typeof this.state.loginQa1[k].designers != 'undefined') {
          temp[k].designers = tag
        }else
        {
          temp[k].designers = [];
          temp[k].designers = tag;
        }
        this.setState({loginQa1:temp});
      }else if(section == 2)
      {
        let temp = this.state.loginQa2;
        if(typeof this.state.loginQa2[k].designers != 'undefined') {
          temp[k].designers = tag
        }else
        {
          temp[k].designers = [];
          temp[k].designers = tag;
        }
        this.setState({loginQa2:temp});
      }else if(section == 3)
      {
        let temp = this.state.inbitweenQa1;
        if(typeof this.state.inbitweenQa1[k].designers != 'undefined') {
          temp[k].designers = tag
        }else
        {
          temp[k].designers = [];
          temp[k].designers = tag;
        }
        this.setState({inbitweenQa1:temp});
      }else if(section == 4)
      {
        let temp = this.state.inbitweenQa2;
        if(typeof this.state.inbitweenQa2[k].designers != 'undefined') {
          temp[k].designers = tag
        }else
        {
          temp[k].designers = [];
          temp[k].designers = tag;
        }
        this.setState({inbitweenQa2:temp});
      }else if(section == 5)
      {
        let temp = this.state.logoutQa1;
        if(typeof this.state.logoutQa1[k].designers != 'undefined') {
          temp[k].designers = tag
        }else
        {
          temp[k].designers = [];
          temp[k].designers = tag;
        }
        this.setState({logoutQa1:temp});
      }
    }

    updateQTagsPms = (section, k, tag)=>
    {
      if(section == 1)
      {
        let temp = this.state.loginQa1;
        if(typeof this.state.loginQa1[k].project_managers != 'undefined') {
          temp[k].project_managers = tag
        }else
        {
          temp[k].project_managers = [];
          temp[k].project_managers = tag;
        }
        this.setState({loginQa1:temp});
      }else if(section == 2)
      {
        let temp = this.state.loginQa2;
        if(typeof this.state.loginQa2[k].project_managers != 'undefined') {
          temp[k].project_managers = tag
        }else
        {
          temp[k].project_managers = [];
          temp[k].project_managers = tag;
        }
        this.setState({loginQa2:temp});
      }else if(section == 3)
      {
        let temp = this.state.inbitweenQa1;
        if(typeof this.state.inbitweenQa1[k].project_managers != 'undefined') {
          temp[k].project_managers = tag
        }else
        {
          temp[k].project_managers = [];
          temp[k].project_managers = tag;
        }
        this.setState({inbitweenQa1:temp});
      }else if(section == 4)
      {
        let temp = this.state.inbitweenQa2;
        if(typeof this.state.inbitweenQa2[k].project_managers != 'undefined') {
          temp[k].project_managers = tag
        }else
        {
          temp[k].project_managers = [];
          temp[k].project_managers = tag;
        }
        this.setState({inbitweenQa2:temp});
      }else if(section == 5)
      {
        let temp = this.state.logoutQa1;
        if(typeof this.state.logoutQa1[k].project_managers != 'undefined') {
          temp[k].project_managers = tag
        }else
        {
          temp[k].project_managers = [];
          temp[k].project_managers = tag;
        }
        this.setState({logoutQa1:temp});
      }
    }

    saveAnswerToDB = (qid, answer_opt, answer_text, pms, dsgns) => {
      let opts = {
        qid: qid,
        answer_opt: answer_opt,
        answer_text: answer_text,
        pms: pms,
        dsgns: dsgns,
        user_id: this.state.myId,
        myshift: this.state.myshift
      };
      console.log(opts);
      fetch(urls.saveAnswerToDB, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method: "post",
        body: JSON.stringify(opts)
      })
        .then(response => response.json())
        .then(response => {        
          console.log(response);
        })
        .catch(error => {
        }); 
     };
  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"} >
      <Row>
        <div className="theme_header">
        <Header {...this.props}  
            ncount={this.state.openNCount}
            classname="login-portal-page" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={this.state.quickActionBlock}
            />
        </div>
        <div className="SideNavbar">
           <SideMenu  
            activeitem="daytoday"  
            showmenu={this.state.showMenu} 
            hidemenu={this.hideMenu.bind(this)}  /> 
       </div>
        <div className="themeContentBody">
          
          <Row>
          <Col sm={12} lg={12}>
          <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
        <LoadingWrapper isLoading={this.state.initialLoader}>
          <div>
              <Row>
              
                <Col sm="12">
                <div className="qa_aire_card">
                <h2 class="section_title">Questionnaire</h2> 
                  {this.state.activeShift === 0?
                  (
                    <Card className="without-tabs no-questions">
                      <span>You don't have an active shift to answer the questionnaire.</span>
                    </Card>
                  ):
                  (
                    <>
                    {(this.state.uRole === 1 || this.state.uRole === 2 ) ?
                    (
                    <Tabs className="qa-tab-wrapper">
                      <TabList>
                        {this.state.loginQa1.length>0 &&
                        (
                          <Tab>Login</Tab>
                        )}
                        {this.state.inbitweenQa1.length>0 &&
                        (
                          <Tab>In-between</Tab>
                        )}
                        {this.state.logoutQa1.length>0 &&
                        (
                          <Tab>Logout</Tab>
                        )}
                      </TabList>
                      {this.state.loginQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitems logingquestions={this.state.loginQa1} questions2={this.state.loginQa2} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                      {this.state.inbitweenQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitemsinbetween betweenquestions={this.state.inbitweenQa1} questions2={this.state.inbitweenQa2} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                      {this.state.logoutQa1.length>0 &&
                      (
                        <TabPanel>
                          <Qaitemslogout logoutquestions={this.state.logoutQa1} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </TabPanel>
                      )}
                    </Tabs> 
                    ):
                    (
                      <>
                      {this.state.uRole !=0 &&
                      (
                        <Card className="without-tabs">
                          <Qaitemslogout logoutquestions={this.state.logoutQa1} 
                          managers={this.state.managers} designers={this.state.designers} saveanswer={this.updateQrAnswer}
                          updatetagsdesigners={this.updateQTagsDesigners} updatetagspms={this.updateQTagsPms} 
                          />
                          {/* <div className="outof">4 of 6</div> */}
                        </Card>
                      )}
                      </>
                     )
                    }
                    {this.state.uRole == 0 &&
                    (
                      <Card className="without-tabs no-questions">
                        <span>You don't have any questions to answer related to your role!</span>
                      </Card>
                    )}
                    </>
                  )}
                  </div>
                </Col>
                
              </Row>
            </div>  
        </LoadingWrapper>
        </motion.div>
        </Col>
          </Row>

        </div>
        </Row>
      </Container>
    
      </div>
    )
  }
}
