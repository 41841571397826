import React, { Component, Fragment } from "react";
import "./UnblockShiftCard.css";
import ApprovalAvatarHead from "../../ApprovalAvatarHead/ApprovalAvatarHead";
import ApprovalTimeRow from "../../ApprovalTimeRow/ApprovalTimeRow";
import { Button } from "reactstrap";
import ApprovedMsg from "../../ApprovedMsg/ApprovedMsg";

export default class UnblockShiftCard extends Component {
  onShiftUnblock = () =>
  {
    this.props.onunblockshift(this.props.shiftitem.user_id);
  }
  
  render() {
    let classname = [
      "caro-box boq",
      this.props.approved ? "approved" : "appr-pending"
    ]
      .join()
      .replace(",", " ");
    return (
      <div className={classname}>
        <ApprovalAvatarHead  name={this.props.shiftitem.name} comment={this.props.shiftitem.comment} />
        {this.props.shiftitem.status === 0 ? (
          <ApprovedMsg label="Approved By" approvedby={this.props.shiftitem.approver}  approvedon={this.props.shiftitem.time_approved} />
        ) : (
          <Fragment>
            <Button className="appr-btn-approve btn-green txt-upper" onClick={this.onShiftUnblock.bind(this)}>
              Approve
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}
