import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { urls } from "../../services/UrlList";
import axios from 'axios';

// fetchings
const userNotifications = async () => {
    return await axios.get(urls.getUserNotifications);
}

const markNotificationRead = async (data) => {
    return await  axios.post(urls.markNotificationasRead, data);
}

const markNotificationUnRead = async (data) => {
    return await axios.post(urls.markNotificationasUnRead, data);
}

const markAllNotiRead = async (data) => {
    return await axios.post(urls.markNotificationAllRead, data);
}


//hooks

export const useGetUserNotifications = () => {
    return useQuery(['user-notifications'], userNotifications)
}

export const useMarkNotificationRead = () => {
    const queryClient = useQueryClient();
    return useMutation(markNotificationRead, {
        onSuccess: () => {
            queryClient.invalidateQueries('user-notifications');
        }
    });
}

export const useMarkNotificationUnRead = () => {
    const queryClient = useQueryClient();
    return useMutation(markNotificationUnRead, {
        onSuccess: () => {
            queryClient.invalidateQueries('user-notifications');
        }
    });
}

export const useMarkAllNotiRead = () => {
    const queryClient = useQueryClient();
    return useMutation(markAllNotiRead, {
        onSuccess: () => {
            queryClient.invalidateQueries('user-notifications');
        }
    });
}