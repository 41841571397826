import React from 'react';
import './FeedCommentLike.css'

export default function FeedCommentLike(props) {
  const likeClass = props.isliked ? 'liked' : 'not_liked';
  const count = props.likeCount > 1 ? `${props.likeCount} Likes` : `${props.likeCount} Like`;

  // const likeAction = () => {
  //   props.likeAction(props.feedId)
  // }
  return (
    <div className="feed_comment_action d-flex">
      <div 
        className={"feed_comment_action_icon feed_comment_action_icon_"+likeClass  }
        onClick={ () => props.likeAction(props.feedId) }></div>
      <p>{count}</p>
    </div>
  )
}
