import React, { Component, Fragment } from 'react';
import Header from '../header/Header';
import Filter from '../filter/Filter';
import ListHead from '../listhead/ListHead';
import DataList from '../datalist/DataList';
import {urls} from "../../services/UrlList";
import LoadingBar from '../common/LoadingBar';
import moment from "moment";
import SideMenu from "../sidemenu/SideMenu";
export default class TickTimer extends Component {
  constructor( props ) {
    super( props );
    var s = new Date();
      s.setDate(s.getDate() - 1);
      s.setHours(6);
      s.setMinutes(0);
      let momentObjs = moment(s);
      let momentStringStart = momentObjs.format("YYYY-MM-DD H:mm");

      var e = new Date();
      e.setDate(e.getDate());
      e.setHours(6);
      e.setMinutes(0);
      let momentObje = moment(e);
      let momentStringEnd = momentObje.format("YYYY-MM-DD H:mm");
      
    this.state = {         
      myPrivileges:[], 
      myId:'',      
      showProgress: true,    
      showProgressSearch: true,
      orginalEntries:[],
      fullEntries:[],
      orginalUsers:[],
      selectedUser:'',
      start_date:momentStringStart,
      end_date:momentStringEnd,
      show_detail:false,
      show_detail_id:0,
      showCustomRange: true,
      startDate: null,
      endDate: null,
      show_rate:true,
      myPrivileges:[],
      showPeople:false,
      showPeopleType:'',
      showSelectedResult:false,
      show_detail_date:0,
      showRate:false,
      dailyHours:12,
      hoursOption:'',
      filterHour:'',
      showAdminComments:false,
      adminCommentId:0,
      commentUserId:'',
      commentProjectId:'',
      commentTaskId:'',      
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Tick Timer Reports";
    this.checkUserPrivilege();  
    this.reloadChecker(); 
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  reloadChecker = () =>
  {
    if(this.props.location.search === '?redirect=1')
    {
      this.props.history.push("/tick-report");
    }
  }

  // checkUserPrivilege = () =>
  // {
  //   var login = JSON.parse( localStorage.getItem("user") );
  //   this.setState({myPrivileges:login.privileges, myId:login.uid });
   
  // };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges });

      if(login.privileges.includes('access_all_reports'))
      {
        this.setState({showPeople:true, showPeopleType:1});
      }else if(login.privileges.includes('access_team_reports'))
      {
        this.setState({showPeople:true, showPeopleType:2});
      }else
      {
        this.setState({showPeople:false, showPeopleType:3});
      }
    }
  };

  onStatusChange = event => {
    this.setState({
      status: event.target.value
    });
  };

  onUserNameChange = event => {
    this.setState({
      userName: event.target.value
    });
  };

  showProjectDetails = id => {
   // console.log(id);
    this.setState({
      projectId: id
    });
    this.props.history.push(`/tick/projectdetails/${id}`);
  };

  goTo = url => {
    this.props.history.push(url);
  };

  showMeRate = ()=>
  {
    this.setState({showRate: !this.state.showRate});
  };

  _handleKeyDown = (e) => {
    var code = (e.keyCode ? e.keyCode : e.which);
   // console.log(e);
    if(code == 13) {           
       this.setState({
        searchTags: this.state.searchTags!="" ? this.state.newTag +","+this.state.searchTags : this.state.newTag,
        newTag:'',
        newTagArray:[...this.state.newTagArray, this.state.newTag]
       });
       
    }
  }

  

  // showDetailEntryDate = (date)=>
  // {
  //   this.setState({
  //     show_detail:true,
  //     show_detail_id:0,
  //     show_detail_date: this.state.show_detail_date === date? 0:date
  //   });
  // };

 hoursRender = () =>  {
  const optionsval = [];
  for (var i=1; i <=  12; i++) {
    optionsval.push(<option selected={this.state.filterHour == i? 'selected':''} value={i}>{"< "+i+" hour"}</option>);
  }
  return optionsval;
};

filterByHours=(e)=>
{ 
  this.setState({filterHour:e.target.value}); 
  if(e.target.value !=''){
    setTimeout(() => {
      let temp=[];
      temp = this.state.fullEntries;
      let list=[];
      temp.forEach(p1=>
      {
        console.log(this.state.filterHour);
       if(parseInt(p1.total_hours) < parseInt(this.state.filterHour)) 
       {
         list.push(p1);
       }
      })
      this.setState({orginalEntries:list});   
    }, 100);
  }else
  {
    this.setState({orginalEntries:this.state.fullEntries});  
  }
};



showMeRate = ()=>
{
  this.setState({showRate: !this.state.showRate});
};



searchEntriestwo = (start_date, end_date, selectedUser) =>
{
  console.log("people"+selectedUser);
  this.setState({
    start_date:start_date,
    end_date:end_date,
    selectedUser:selectedUser
  });
}

filterbyhour = (value) =>
{
  this.setState({
    filterHour:value
  });
}

  render() {
    const { showComment } = this.props;
    return (
      <Fragment>
        <SideMenu activeitem="report" />
        <Header {...this.props}  classname="tick-timer-header"  logout={this.signOut.bind(this)}/>
        <Filter filterbyhour={this.filterbyhour} searchEntriesone={this.searchEntriestwo} />
        <ListHead showRate={this.state.showRate} selectedpeople={this.state.selectedUser} showMeRate={this.showMeRate} />
        <DataList filterhour={this.state.filterHour} start_date={this.state.start_date} end_date={this.state.end_date} selectedpeople={this.state.selectedUser} showRate={this.state.showRate} />
        {/* {this.state.showAdminComments?
        ( <AdminComment onActionEdit={} onClose={this.AdminCommentboxClose} entryId={this.state.adminCommentId}  showComment={this.state.showAdminComments}/>)
        :
        null
        }        */}
      </Fragment>
    )
  }
}