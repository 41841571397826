import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { useGetRevsionFolders, useRemoveSourceRevsionandAssets, useGetRevsionApproved, useCloneRevisionFolder } from "./useQueryHooks";
import ConfirmAction from "../../admincomment/ConfirmAction";
import RevisonShareLink from "./RevisonShareLink";
import ApprovedFiles from "./ApprovedFiles";
import moment from "moment";

export default function RevisonList(props) {
  const [sharePop, setSharePop] = useState(false);
  const [sharingId, setSharingId] = useState(0);
  const [showRevsionDeletion, SetShowRevsionDeletion] = useState(false);
  const closeDialogue = () => SetShowRevsionDeletion(false);
  const [deletionID, setDeletionID] = useState(0);
  const [showApprovedData, setShowApprovedData] = useState(false);


  // useEffect(()=>
  // {
  //   if(!props.approveddata)
  //   {
  //     setShowApprovedData(false);
  //   }
  // },[props.approveddata]);

  const toggleSharePop = ()=>
  {
    setSharePop(!sharePop);
  }

  const {
    isLoading: approvedLoading,
    data: approvedData,
  } = useGetRevsionApproved(props.taskid);

  const {
    isLoading: revisionLoading,
    isError,
    error,
    data: revsionData,
  } = useGetRevsionFolders(props.taskid);

  


  const setRevisionNum = (value) => {
    props.setRevisionNum(value, 0);
  };

  const setApprovedView = ()=>
  {
    setShowApprovedData(true);
    props.setApprovedView();
  }
  const handleDeleteConfirm = () => {
    const data2 = {
      revisionid: deletionID,
      taskid: props.taskid
    };
    removeSourceRevison(data2);
    closeDialogue();
  };
  const { isLoading: deleteLoading, mutate: removeSourceRevison } = useRemoveSourceRevsionandAssets();


  const cloneTheRevision = async (revisionid, taskid) => {
    const data1 = {
      revisionid: revisionid,
      taskid: taskid
    };
    await cloneRevisionFolder(data1);
  };
  const { mutate: cloneRevisionFolder, isLoading:copyProgress  } = useCloneRevisionFolder();


  const enableforEditing = (uploadedtime)=>
  {
    if((moment((new Date()).getTime()).tz(props.mytimezone).format('X')) - (moment((new Date(uploadedtime)).getTime()).tz(props.mytimezone).format('X'))<300)
    {
      return true;
    }else
    {
      return false;
    }
  }


  if (revisionLoading || approvedLoading || copyProgress) {
    return <>{"Loading"}</>;
  } 

  
  if (copyProgress) {
    return <>{"Cloaning and Loading"}</>;
  } 

  return (
    <Row className="uploaded-versions fms_fristbox d-flex">
      {revsionData?.data.revsions.length === 0 && (
        <Col className="initial-design">Please upload your initial design</Col>
      )}
      {(props.publisherstatus || props.approveddata)  &&
      (
        <ApprovedFiles taskid={props.taskid} canseeapproved={props.canseeapproved} />
      )}
      
      {(props.canseeapproved && !props.publisherstatus && approvedData?.data.structure && approvedData?.data.success === 1) && (
        <div  className="folder_box_som1 approved">
            <Card className="d-flex folder-wrapper-som source-folder-items">
              <div
                className="d-flex"
                onClick={setApprovedView}
                >
                <MaterialIcon icon={"folder"} />
                <span className="folder_name">
                  <span className="revision-name">Client Approved</span>
                </span>
              </div>
              {/* <div className="d-flex source-folder-actions">
                <div className="quick_actions_som quick_actions_som_inner">
                  <Button title="share" className="quick_action_btn" >
                    <MaterialIcon icon="share" />
                  </Button>
                  <Button title="download" className="quick_action_btn">
                    <MaterialIcon icon="file_download" />
                  </Button>
                </div>
              </div> */}
            </Card>
        </div>
      )}
      {!props.publisherstatus && revsionData?.data.revsions.map((folder, index) => {
        return (
          <div key={folder.revision} className="folder_box_som">
            <Card className="d-flex folder-wrapper-som source-folder-items">
              <div
                className="d-flex"
                onClick={setRevisionNum.bind(this, folder)}
              >
                <MaterialIcon icon={"folder"} />
                <span className="folder_name">
                  <span className="revision-name">{folder.revision}</span>
                  {/* <span className="revision-date">{folder.updated_on}</span> */}
                  <span className="revision-date">{folder.name}</span>
                </span>
              </div>
              <div className="d-flex source-folder-actions">
                <div className="quick_actions_som quick_actions_som_inner">
                  {(enableforEditing(folder.created_on)) &&(
                    <Button title="delete" className="quick_action_btn" 
                      onClick={()=>{SetShowRevsionDeletion(true); setDeletionID(folder.id) } }>
                      <MaterialIcon icon="delete" />
                    </Button>
                  )}
                  {/* <Button title="share" className="quick_action_btn" onClick={()=>{ setSharingId(folder.id); setSharePop(true);}}>
                    <MaterialIcon icon="share" />
                  </Button> */}
                  {/* <Button title="Clone" className="quick_action_btn" onClick={()=>{ cloneTheRevision(folder.id, props.taskid);}}>
                    <MaterialIcon icon="content_copy" />
                  </Button> */}
                  {/* <Button title="download" className="quick_action_btn">
                    <MaterialIcon icon="download" />
                  </Button> */}
                  {/* <Button className="quick_action_btn">
                    <MaterialIcon icon="edit" />
                  </Button> */}
                </div>
              </div>
            </Card>
          </div>
        );
      })}

      {showRevsionDeletion ? (
        <ConfirmAction
          purpose="change"
          closeDialogue={closeDialogue}
          show={showRevsionDeletion}
          note="*This action cannot be undone, are you sure?"
          content="You are about to delete this version and files"
          handleversiondeletion={handleDeleteConfirm}
          confirmBoxAction="deleteversion"
        />
      ) : null}
      {sharePop &&(
          <RevisonShareLink sharepop={sharePop} taskid={props.taskid} revsionid={sharingId} closepop={toggleSharePop} />
      )}  
    </Row>
  );
}
