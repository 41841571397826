import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { Container, Row, Col, Card, Button, Progress, Input } from 'reactstrap';
import { urls } from "../../services/UrlList";
import "../../components/common/Routes";
import Dtimer from "../loginportal/Dtimer";
import startLunchImgActive from "../../images/start-lunch_active.png";
import startLunchImgInactive from "../../images/start-lunch_disable.png";
import endLunchImgActive from "../../images/end-lunch_active.png";
import endLunchImgInactive from "../../images/end-lunch_disable.png";
import requestEndLunch from "../../images/request-end-lunch_disable.png";
import waitingEndLunch from "../../images/waitng-end-lunch_disable.png";
import Alertlogmsg from "../messages/Alertlogmsg";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { motion } from 'framer-motion'

export default class ExtendedBreakPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRequestPopup: false,
            isrequired_field: false,
            requestComment: '',
            waitingStatus: this.props.waitingStatus,
            errorMsg: this.props.errorMsg
        }

    }
    requestActionApproval = () => {
        this.setState({
            showRequestPopup: true,
            errorMsg: false,
        });
    };
    hideRequestPopup = () => {
        this.setState({
            showRequestPopup: false,
        });
    };

    sendApprovalBreakRequest = (id) => {
        if (this.state.requestComment != '') {
            let opts = {
                user_id: this.props.myId,
                puch_type: id,
                comment: this.state.requestComment,
                username: this.props.username
            };

            fetch(urls.sendBreakApproval,
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    body: JSON.stringify(opts)
                })
                .then(response => response.json())
                .then(response => {
                    this.setState({ showRequestPopup: false });
                    this.waitingApprovalRequest();
                })
                .catch(error => {
                    this.setState({ showRequestPopup: false });
                    this.waitingApprovalRequest();
                })
        } else {
            this.setState({ isrequired_field: true });
            return false;
        }
    };

    updateComment = (e) => {
        this.setState({ requestComment: e.target.value, isrequired_field: false });
    }

    waitingApprovalRequest = () => {
        fetch(urls.waitingBreakApproval + this.props.myId, {
            headers:
            {
                'Access-Control-Allow-origin': '*',
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(response => {
                this.setState({ waitingStatus: response[0].waiting_status });
                console.log(response[0].waiting_status);
                if (response[0].waiting_status == '1') {
                    const _this = this;
                    setTimeout(function () { //Start the timer     
                        _this.props.intevalModifier();
                    }, 1000);
                }
            })
            .catch(error => {
                this.setState({ ProgressBarBreak: false })
            })
    }

    intevalModifier = () => {

    };

    render() {
        const variants = {
            hidden: { opacity: 0 },
            visible: { opacity: 1 },
        }
        return (
            <Container>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}>
                    <div className="lunch-box">
                        <LoadingWrapper isLoading={this.props.initialLoader}>
                            {this.props.mobileview == 0 ?
                            (
                            <>
                                {this.props.errorMsg === true ?
                                    (<Row>
                                        <Col>
                                            <Alertlogmsg
                                                msgtext={this.props.logMsg}
                                                boxclass="task-running" />
                                        </Col>
                                    </Row>)
                                    :
                                    null
                                }
                                {this.props.punchStatus === 1 && this.props.breakStatus === 0 && this.props.extStatus === 0 && this.props.isLocked === 0 && this.props.breakEnabled ?
                                    (<div className="login-btns d-flex justify-content-around align-items-center">
                                        <p className="time-exhausted ">Extended Break</p>
                                        <p className="time-exhausted time_txt">{this.props.extTime}</p>
                                        {this.props.lunchStatus === 0 ?
                                            (<img className="start-break-img" onClick={this.props.lunchActions.bind(this, '5')} src={startLunchImgActive} />)
                                            :
                                            (<img className="start-break-img" src={startLunchImgInactive} />)
                                        }

                                        {this.props.lunchStatus === 1 ?
                                            (<img className="end-break-img" onClick={this.props.lunchActions.bind(this, '6')} src={endLunchImgActive} />)
                                            :
                                            (<img className="end-break-img" src={endLunchImgInactive} />)
                                        }

                                        {this.props.lunchStatus == 1 ?
                                            (<div className="timer ">
                                                <Dtimer startTimem={this.props.startTimem} />
                                            </div>)
                                            :
                                            null
                                        }
                                    </div>

                                    )
                                    :
                                    (
                                        <div className="login-btns text-center">
                                            <p className="time-exhausted ">Extended Break</p>
                                            {/*<p className="time-exhausted time_txt">Overall Time: {this.props.extTime}</p>*/}
                                            <div className="text-center ext-action-wrap">
                                                {this.props.extStatus == 1 ?
                                                    (<div className="timer "><Dtimer startTimem={this.props.startTimem} />
                                                    </div>)
                                                    :
                                                    null
                                                }
                                                <Button className="start-shift btn-green login-page-btn" onClick={this.props.extActions.bind(this, '8')} >End Ext.Break</Button>
                                            </div>
                                        </div>
                                    )
                                }
                                {this.state.showRequestPopup &&
                                    (<div className="request-approval">
                                        <p class="text-center sub-title">Unlock Request</p>
                                        <div className="request-form-comment">
                                            <Input className={this.state.isrequired_field ? 'is_required_field' : ''} placeholder="Enter the reason..." type="textarea" value={this.state.comment} onChange={this.updateComment.bind(this)}></Input>
                                        </div>
                                        <div className="submit-request">
                                            <Button className="btn btn-secondary btn-gray" onClick={this.hideRequestPopup.bind(this)} >Cancel</Button>
                                            <Button className="btn btn-secondary btn-green" onClick={this.sendApprovalBreakRequest.bind(this, this.state.lunchStatus == 1 ? '6' : '4')}>Send</Button>
                                        </div>
                                    </div>
                                    )
                                }
                            </>
                            ):
                            (
                                <span className='mobile-block-msg'>Emergency break timer running.<br/>You can not end your break via mobile.</span>
                            )}
                        </LoadingWrapper>
                    </div>
                </motion.div>
            </Container>
        )
    }
}