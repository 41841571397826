import React, { Component, Fragment } from "react";
import "./UpcomingCompanyHoliday.css";
import { Card } from "reactstrap";
import LoadingWrapper from '../../../LoadingBox/LoadingWrapper/LoadingWrapper'

export default class UpcomingCompanyHoliday extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card className="upcoming-holiday-card">
        {/* <LoadingWrapper isLoading={this.props.loading} > */}
          { this.props.fulllist === '1' ?
            (
              <div className="upcoming_company_holiday d-flex">
                <div className="date flex-fill equalHM">
                <h4>{this.props.occasion}</h4>
                <h4>{this.props.holidaydate}</h4>
                </div>
                
                
                {/*<div className="type">
                <p>Type</p>
                <h4>{this.props.holidaytype === 1? "Mandatory Off": "Optional Holiday"}</h4>
                </div>*/}
              </div>
            )
          : null }
        {/* </LoadingWrapper> */}
      </Card>
    );
  }
}
