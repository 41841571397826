
import React, { Component } from 'react';
import Timer from "react-compound-timer";

export default class Timmer extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      startTime: this.props.starttimer
    }    
  }

  render() {    
    return (     
      <div className="box task-total-time">
        {this.state.startTime !==''?
        (<Timer formatValue={(value) => `${(value < 10 ? `0${value}`:value)}`} 
        initialTime={this.state.startTime}   lastUnit="h"   timeToUpdate="1000"        
           direction="forward">                       
                     <Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />                         
        </Timer>)
        :
        null
        }
          
      </div>                            
    )
  }
}


