import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody, Input, Collapse } from 'reactstrap';
import './basecampmodule.css'
import MaterialIcon from "material-icons-react";
export default class Addsubfolders extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      popoverOpen: false,
      newFolder:'',
      nameError:false,
    };
  }

  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  addFolder = ()=>
  {
    if(this.state.newFolder==='')
    {
      this.setState({nameError:true});
      return false;
    }else
    {
      this.toggle();
      this.props.addfolder(this.state.newFolder);
    }
  }
  render() {
    return (
      <div>
        <Button className="edit-links" onClick={this.toggle}><MaterialIcon icon="add"/></Button>
         <Collapse
       
        className="add_new_subfolder"
        isOpen={this.state.popoverOpen} toggle={this.toggle}
      >
        <div className="subfolder_overlay" onClick={this.toggle}></div>
        <Input type="text" className="add_subfolder_text" placeholder="Folder Name" onChange={(e)=>this.setState({newFolder:e.target.value})} />
        <Button type="submit" className="cf_btn" onClick={this.addFolder}>Create folder</Button>
      </Collapse>
        {/* <Button id="Popover5" className="edit-links" onClick={this.toggle}><MaterialIcon icon="add"/></Button>
        
        <Popover  style={{ width:'270px', padding:"10px" }} placement="bottom-start" className="add_subfolder_popover" isOpen={this.state.popoverOpen} target="Popover5" toggle={this.toggle}>
          <PopoverBody>
              <Input type="text" className="add_subfolder_text" placeholder="Folder Name" onChange={(e)=>this.setState({newFolder:e.target.value})} />
              <Button type="submit" className="cf_btn" onClick={this.addFolder}>Create folder</Button>
          </PopoverBody>
        </Popover> */}
       
      </div>
    );
  }
}