import React from "react";

export default function GreenBell(props) {
  return (
    <div className="green-bell" onClick={props.closeaction}>
      <svg width="20px" height="24px" viewBox="0 0 40 48" >
      <path id="Path_5" data-name="Path 5" d="M38.758,33.826A13.4,13.4,0,0,1,34,23.576V18A14.011,14.011,0,0,0,22,4.16V2a2,2,0,0,0-4,0V4.16A14.009,14.009,0,0,0,6,18v5.576A13.41,13.41,0,0,1,1.224,33.842,3.5,3.5,0,0,0,3.5,40h33a3.5,3.5,0,0,0,2.258-6.174Zm0,0" transform="translate(0)" fill="#007969"/>
      <path id="Path_6" data-name="Path 6" d="M142.3,454a7.511,7.511,0,0,0,7.348-6h-14.7A7.512,7.512,0,0,0,142.3,454Zm0,0" transform="translate(-122.301 -406)" fill="#007969"/>
      </svg>
    </div>
  );
}
