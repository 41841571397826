import React, { Component, Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarousalCard from "../CarousalCard/CarousalCard";
import WfhCard from "./WfhCard/WfhCard";
import ApprovalModules from ".././ApprovalModules/ApprovalModules";
import {urls} from "../../../services/UrlList";

export default class Wfh extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',        
      isMobileView:0,
      wfhRequests:[],
      wfhRequestsOriginal:[],
      requestPending:0,
    };
  }

  componentDidMount =() =>
  {        
    this.checkUserPrivilege();
    const _this = this;
    setTimeout(function() {
      _this.getWfhRequests();
    }, 100);   
    setInterval(() => _this.getWfhRequests(),600000); 
  };

  componentWillMount = () =>
  {
    this.getWfhRequests();
  }
 
  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges, myId:login.uid});
    if(!login.privileges.includes('approve_user_requests'))
    {
      this.props.history.push("/tick-report");
    }
   
  };

  getWfhRequests = () =>
  {
    this.setState({progressBar:true});
    let opts ={       
        approver_id:this.state.myId
    };
    fetch(urls.getWfhRequests,{
       headers:{
           'Access-Control-Allow-origin':'*',
           'Content-Type':'application/json'
       },
       method:'post',
       body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response =>
    {        
      console.log(response);
      this.setState({wfhRequests:response[0].request_list, wfhRequestsOriginal:response[0].request_list, progressBar:false});
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(response[0].request_list);
          _this.props.onwfhfuncall(_this.state.requestPending);
        }, 100);
    })
    .catch(error =>
    {
        this.setState({progressBar:false}); 
        const _this = this;
        setTimeout(function() {
          _this.countPendingRequest(_this.state.breakRequests);
          _this.props.onwfhfuncall(_this.state.requestPending);
        }, 100);
    })    
  };

  countPendingRequest = (response) =>
  {
    let temp = response;
    var list = [];
    var count = 0;
    temp.forEach(p1 =>
      {
        if(p1.status !== 1 && p1.status !==3)
        {
          list.push(p1);
          count++;
        }
      })
    if(this.state.showUnApproved)
    {
      this.setState({requestPending:count,wfhRequests:list });
    }else
    {
      this.setState({requestPending:count,wfhRequests:this.state.wfhRequestsOriginal });
    }    
  }


  onManagerialApproval =(myid, leaveid, type, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      manager_id:myid,
      leave_id:leaveid,
      type:type,
      comment:comment
    };
    fetch(urls.postManagerApproval,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getWfhRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  onManagerialApprovalComment =(myid, leaveid, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      manager_id:myid,
      leave_id:leaveid,      
      comment:comment
    };
    fetch(urls.postManagerApprovalComment,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getWfhRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  onAdminApproval =(myid, leaveid, type, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      admin_id:myid,
      leave_id:leaveid,
      type:type,
      comment:comment
    };
    fetch(urls.postAdminApproval,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getWfhRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };



  onAdminApprovalComment =(myid, leaveid, comment) =>
  {
    this.setState({progressBar:true});
    let opts={
      admin_id:myid,
      leave_id:leaveid,      
      comment:comment
    };
    fetch(urls.postAdminApprovalComment,{
      headers:{
          'Access-Control-Allow-origin':'*',
          'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
   })
   .then(response => response.json())
   .then(response =>
   {
       this.getWfhRequests();
       this.setState({progressBar:false});
   })
   .catch(error =>
   {
       this.setState({progressBar:false}); 
   })
  };


  showUnApproved = () =>
  {
    this.setState({showUnApproved: !this.state.showUnApproved});
    const _this = this;
    setTimeout(function() {
      _this.countPendingRequest(_this.state.wfhRequestsOriginal);
    }, 100);
    
  }
    
  render() {
    let settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      arrows: true
    };

    return (
      <Fragment>
        <ApprovalModules classname="appr-pending" title="WFH" id="wfh" waiting={this.state.requestPending} showonpending={this.showUnApproved}>
          <Slider {...settings}>            
            {
            this.state.wfhRequests.map((item, index)=>
            {return(
                <WfhCard myid={this.state.myId} approved={item.status === 1? "true": ''} key={index} 
                item={item} name={item.name} comment={item.leave_reason} 
                onmanagerialapproval={this.onManagerialApproval}
                onadminapproval={this.onAdminApproval}
                onmanagerialapprovalcomment={this.onManagerialApprovalComment}
                onadminapprovalcomment={this.onAdminApprovalComment} />
              )
            })} 

          </Slider>
        </ApprovalModules>
      </Fragment>
    );
  }
}
