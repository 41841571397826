import React, { Component, Fragment } from "react";
import "./LeaveCalender.css";
import Header from "../../header/Header";
import SideMenu from "../../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Input, Button } from "reactstrap";
import LeaveHeader from "../LeaveHeader/LeaveHeader";
import Calender from "react-calendar";
import {urls} from "../../../services/UrlList";
import LoadingBar from "../../common/LoadingBar";
import { isMobile } from "react-device-detect";
import LoadingWrapper from '../../LoadingBox/LoadingWrapper/LoadingWrapper'
import DesignersIcon from "../../../images/leave_design_icon.svg"
import ProjectManagerIcon from "../../../images/leave_pm_icon.svg"
import OthersIcon from "../../../images/leave_other_icon.svg"

export default class LeaveApply extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sDate: new Date(),
      leaveDesigners:[],
      leavePMs:[],
      leaveQA:[],
      leaveOthers:[],
      wfh:[],
      progressBar:false,
      showMenu:true,
      myPrivileges:[],
    };
  }

  componentDidMount =() =>
  {
    this.checkUserPrivilege();  
    this.renderContentDevice();     
    const _this = this;
    setTimeout(function() {     
      _this.getLeaveCalendar();
    }, 100);       
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,       
      });
    }
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };
  
  getLeaveCalendar = () =>
  {
    this.setState({        
      progressBar:true,
    })
    let opts ={
      selected_date: this.state.sDate,
    };   
    fetch(urls.getLeaveCalendar,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      { 
        console.log(response[0].leave_designers);             
        this.setState({
          leaveDesigners:response[0].leave_designers, 
          leavePMs:response[0].leave_pm,
          leaveQA:response[0].leave_qa,
          leaveOthers:response[0].leave_others,
          wfh:response[0].leave_wfh,            
          progressBar:false,
        })
      })
      .catch((error) =>
      {
        this.setState({        
          progressBar:false,
        })
      })
  }

  onLdateChange = (date) => 
  {
    this.setState({ sDate:date });
    const _this = this;
    setTimeout(function() {     
      _this.getLeaveCalendar();
    }, 100);
  }

  

  render() {
    return (
      <>
                  <section id="leave_calender">
                  <div id="leave-react-calender">
                          <Calender defaultView="month" onChange={this.onLdateChange}
                          value={this.state.sDate} />
                    </div>
                    <div className="leave_calender_details">
                          
                          <div className="leave_status_wrapper">
                            <LoadingWrapper isLoading={this.state.progressBar}>
                           
                              {this.state.leaveDesigners.length>0?
                             
                              (
                                <div className="category designer">
                                    <h4>Leave Status</h4>
                                    <h5><img src={DesignersIcon} alt="Designers Icon"/> Designer</h5>
                                    {this.state.leaveDesigners.map((leave, index)=>
                                      {
                                        return(
                                          <p>{leave.name} - {leave.session === 3? 'Full Day' : (leave.session === 1? "First Half" : "Second Half")} </p>
                                        )
                                      })}
                                </div>
                              ) :null } 
                              {this.state.leavePMs.length>0?
                              (
                                <div className="category project-manager">
                                  <h5><img src={ProjectManagerIcon} alt="Project Manager Icon"/> Project Manager</h5>
                                    {this.state.leavePMs.map((leave, index)=>
                                      {
                                        return(
                                          <p>{leave.name} - {leave.session === 3? 'Full Day' : (leave.session === 1? "First Half" : "Second Half")} </p>
                                        )
                                      })}                       
                                </div>
                              ) :null }
                              {this.state.leaveQA.length>0?
                              (
                                <div className="category QA">
                                    <h5><img src={OthersIcon} alt="Others Icon"/>  QA</h5>
                                    {this.state.leaveQA.map((leave, index)=>
                                      {
                                        return(
                                          <p>{leave.name} - {leave.session === 3? 'Full Day' : (leave.session === 1? "First Half" : "Second Half")} </p>
                                        )
                                      })}                       
                                </div>
                              ) :null }
                              {this.state.leaveOthers.length>0?
                              (
                                <div className="category Others">
                                    <h5><img src={OthersIcon} alt="Others Icon"/>  Others</h5>
                                    {this.state.leaveOthers.map((leave, index)=>
                                      {
                                        return(
                                          <p>{leave.name} - {leave.session === 3? 'Full Day' : (leave.session === 1? "First Half" : "Second Half")} </p>
                                        )
                                      })}                       
                                </div>
                              ) :null }
                              {this.state.wfh.length>0?
                              (
                                <Fragment>
                                  <h4>WFH Status</h4>
                                  <div className="whf-details">
                                      {this.state.wfh.map((leave, index)=>
                                        {
                                          return(
                                            <p>{leave.name} - {leave.session === 3? 'Full Day' : (leave.session === 1? "First Half" : "Second Half")} </p>
                                          )
                                        })}
                                  </div>
                                </Fragment>
                              ) :null }
                              { 
                                ( this.state.leaveDesigners.length <= 0 && this.state.leavePMs.length <= 0 
                                  && this.state.leaveQA.length <= 0 && this.state.leaveOthers.length <= 0 && this.state.wfh.length <= 0 ) ?
                                ( <div className="full-present"><span>🥳</span> Woohoo! No leaves taken on this day</div> ): null 
                              }
                            </LoadingWrapper>
                          </div>
                        </div>
                  </section>
                </>
    );
  }
}
