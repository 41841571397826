import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import {Progress } from 'reactstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Close } from '@material-ui/icons';
import {urls} from "../../../services/UrlList";
import axios, {CancelToken, isCancel} from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import {useMediaUpdated } from "../../../MediaContext";

export default function UploadProgress(props) {
  const [showProgress, setShowProgress]= useState(true);
  const [uploadingItems, setUploadingItems] = useState([]);
  const [fileUploadProgressing, setFileUploadProgressing] = useState(props.fileUploadProgressing);
  const [uplaodingProgressing, setUplaodingProgressing] = useState([]);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [cancelId, setCancelId] = useState(0);

  const mediaFilesUpdated = useMediaUpdated();

//   useEffect(()=>
//   {
//     setUploadingItems([...uploadingItems, ...props.filesuploading]);
//   },[props.filesuploading]);


useEffect(() => {
    const newItems = props.filesuploading.filter(
      (file) => !uploadingItems.some((item) => item.uniqueid === file.uniqueid)
    );
    setUplaodingProgressing(false);
    if (newItems.length > 0) {
      setUploadingItems((prevItems) => [...prevItems, ...newItems]);
    }
    setUplaodingProgressing(true);
  },[props.filesuploading]);


  useEffect(() => {
    setUploadingItems((prevItems) => prevItems.filter((item) => item.uniqueid !== cancelId));
  },[cancelId]);


  const cancelFileUpload = useRef(new Array());

  const checkUploadingCompleted = async () =>
  {
    let uploadingcount=0;
    uploadingItems.forEach((p1)=>
    {
        if(p1.uploadedpercentage<100 && p1.cancelstatus!==1)
        {
            uploadingcount++;
        }
    })
    console.log(uploadingcount);
    if(uploadingcount<=0)
    {
        setUploadCompleted(true);
        setUploadingItems([]);
        props.fileUploadProgressing(0);
    }
  }

  function randomStringGenerate()
  {
    let bytes = '';
    for (let i = 0; i < 5; i++) {
    const randomByte = Math.floor(Math.random() * 256);
    bytes += randomByte.toString(16).padStart(2, '0');
    }
    return bytes;
  }

  const ToastMessage = (message)=>
  {
    toast(message, {
        duration: 5000,
        position: 'top-right',
      
        // Styling
        style: {
         
        },
        className: 'toast-Cancel-theme',
      
        // Custom Icon
        icon: '⚠️',
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },
      
        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        }
     });
  }

//   useEffect(()=>
//   {
//     const headers = {
//         'Content-Type': 'application/json',
//         'Access-Control-Allow-origin':'*',
//     }

//     for (let i = 0; i < uploadingItems.length; i++)
//     {
//         if(uploadingItems[i].uploadedpercentage<1 )
//         {
        
//         let uid = uploadingItems[i].uniqueid;
//         let options = {
//             onUploadProgress: (progressEvent) => {
//                 const {loaded, total} = progressEvent;
//                 let percent = Math.floor( (loaded * 100) / total )
//                 if( percent <= 100 ){
//                     setUplaodingProgressing(false);
//                     uploadingItems[i].uploadedpercentage= percent;
//                     setUplaodingProgressing(true);
//                 }
//             },
//             cancelToken: new CancelToken(cancel => cancelFileUpload.current[uid] = cancel)
//             }
//         const data = new FormData();
//         data.append('file', uploadingItems[i]);
//         data.append('filename', uploadingItems[i].name);
//         data.append('filesize', uploadingItems[i].size);
//         data.append('filetype', uploadingItems[i].type);
//         data.append('folder_path', uploadingItems[i].folder_path);
//         data.append('revision_id', uploadingItems[i].revision_id);
//         data.append('folder_id', uploadingItems[i].folder_id);
//         data.append('task_id', uploadingItems[i].task_id);
//         axios.post(urls.uploadTaskSource, data, options, { 
//                 headers: headers
//             })
//         .then(res => {

//             //props.fileUploadProgressing(res.data.uniqueid);

//             setTimeout(() => {
//                 //props.fileUploadProgressing(res.data.uniqueid);
//                 mediaFilesUpdated(Math.random() * 10);
//                 checkUploadingCompleted();
//             },1000);

//             })
//         .catch(error=>
//             {
//                 if(isCancel(error))
//                 {
//                     ToastMessage(error.message);
//                 }
//             })
//         }
//     }


//   },[uploadingItems])





useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    };
  
    const promises = [];
  
    for (let i = 0; i < uploadingItems.length; i++) {
      if (
        uploadingItems[i].uploadedpercentage < 1 &&
        uploadingItems[i].cancelstatus === 0
      ) {
        let tempname = uploadingItems[i].name;
        tempname = tempname.replace(/[#+\s]/g, '');
        let namefile = tempname.replace(/[^A-Za-z0-9.\-]+/g, '-');
  
        let data1 = {
          file: uploadingItems[i],
          filename: namefile,
          filesize: uploadingItems[i].size,
          filetype: uploadingItems[i].type,
          folder_path: uploadingItems[i].folder_path,
          revision_id: uploadingItems[i].revision_id,
          folder_id: uploadingItems[i].folder_id,
          task_id: uploadingItems[i].task_id,
          randomstring: randomStringGenerate(),
          timenow: Math.floor(Date.now() / 1000),
        };
        let uid = uploadingItems[i].uniqueid;
  
        const url = process.env.REACT_APP_LAMBADA_ENDPOINT;
  
        const promise = fetch(url, {
          method: 'POST',
          mode: 'cors',
          body: JSON.stringify({
            key:
              'tasksource/' +
              uploadingItems[i].revision_id +
              '/' +
              uploadingItems[i].folder_id +
              '/' +
              data1.timenow +
              '/' +
              uploadingItems[i].folder_path +
              '/' +
              data1.randomstring +
              '/' +
              namefile,
            ftype: uploadingItems[i].type,
          }),
        })
          .then((res) => res.json())
          .then((response) => {
            axios
              .put(response.uploadURL, uploadingItems[i], {
                headers: {
                  'Access-Control-Allow-Headers': '*',
                  'Access-Control-Allow-Methods': 'OPTIONS,POST,GET,PUT',
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': uploadingItems[i].type,
                },
                onUploadProgress: (progressEvent) => {
                  const { loaded, total } = progressEvent;
                  let percent = Math.floor((loaded * 100) / total);
                  if (percent <= 100) {
                        setUplaodingProgressing(false);
                        uploadingItems[i].uploadedpercentage = percent;
                        setUplaodingProgressing(true);
                  }
                },
                cancelToken: new CancelToken((cancel) => {
                  cancelFileUpload.current[uid] = cancel;
                }),
                mode: 'cors',
              })
              .then((res) => {
                axios
                  .post(urls.uploadTaskSource, data1, {
                    headers: headers,
                  })
                  .then((res) => {
                    mediaFilesUpdated(Math.random() * 10);
                    checkUploadingCompleted();
                  });
              })
              .catch((error) => {
                if (isCancel(error)) {
                  ToastMessage(error.message);
                  checkUploadingCompleted();
                }
              });
          });
        promises.push(promise);
      }
    }
  
    Promise.all(promises).then(() => {
        setUplaodingProgressing(true);
    });
  }, [uploadingItems]);







  const cancelUpload = async (id, file)=>
  {
    if(cancelFileUpload.current[id])
    {
        setUplaodingProgressing(false);
        cancelFileUpload.current[id](`Cancelled upload of ${file.name}`); 
        await props.fileUploadCancel(file.uniqueid);
        setUplaodingProgressing(true);
        setCancelId(id);
    }
  }

 const renderProgressBar = (item) => {
    return (
        <CircularProgressbar value={item.uploadedpercentage} text={`${item.uploadedpercentage}`} />
    );
  };


  return (
         <div className="sticky-parent">
            {uploadingItems?.length>0 && uploadingItems.map((item, index) => {
            return (
                <div key={item.name}>
                   {(item.uploadedpercentage<100 && item.cancelstatus === 0) && 
                     ( 
                        <div key={item.name} className={`upload-progress-sticky show-sticky `}>
                            <div style={{ width: 30, height: 30 }} className="upload-progess-circle">
                                {renderProgressBar(item)}
                            </div>
                            <span className="uploading-item-name">{item.name}</span>
                            {item.uploadedpercentage>0 && item.uploadedpercentage<98 && 
                             ( 
                                 <div className="fileuploader-cancel" onClick={()=>cancelUpload(item.uniqueid, item)}>X</div>
                              ) 
                           } 
                        </div>
                    )} 
                </div>
                )
            })}
        </div>       
  );
}
