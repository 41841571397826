import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {urls} from "../../services/UrlList";

class FullUserPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orginalUsers:[],
      selectedUser:'',
    };  
  }

  componentDidMount = () => {
    this.getFullUsers();
  };

   getFullUsers = () =>{       
    var fUrl = urls.getFullDotoUsers; 
    fetch(fUrl,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {      
        this.setState({
          orginalUsers: response[0].users,          
        });            
    })
    .catch((error)=>
    {
      console.log(error);
    })
  };


  onChangePeople = (e) => {
    this.setState({ selectedUser:e.target.value});
    setTimeout(() => {
      this.props.onPeopleChange(this.state.selectedUser);
    }, 100);     
  };
  

    render() {
      return (
        <div className="people date-col">
          <p className="filter-label">Select User: </p>
          <select className="form-control" onChange={this.onChangePeople.bind(this)}>
          <option value="">Select</option>
          {this.state.orginalUsers.map((user, index) =>
          {
            return(
              <option value={user.id}>{user.name}</option>
            )
          })}
          </select>
        </div>
      );
    }
}

export default FullUserPicker;
