import React, {useState, useEffect, Fragment} from 'react';
import {Input, Button, Row, Col, Label} from 'reactstrap';
import {urls} from "../../services/UrlList";
import axios from 'axios';
import { useForm } from "react-hook-form";

export default function EditBoard(props)
{
    const [boardname, setBoardName] = useState(props.project.board_name);
    const [boarddescription, setBoardDescription] = useState(props.project.short_description);
    const [project, setProject] = useState(props.project);
    const [boarderrors, setBoardErrors] = useState([]);
    const [graphicspack, setGraphicsPack] = useState(props.project.graphics_pack);
    const [videopack, setVideoPack] = useState(props.project.video_pack);
    const [graphicsvideopack, setGraphicsVideoPack] = useState(props.project.graphics_video_pack);
    const [clientstatus, setClientStatus] = useState(props.project.client_status);
    const [whitelabel, setWhiteLabel] = useState(props.project.white_label);
    const [onboarded360, setOnboarded360] = useState(props.project.onboard_360);
    const [viewType, setViewType] = useState(props.project.view_type);
    const { register,  handleSubmit } = useForm();
    const onSubmit = data => saveNewBoard(data);
    const [boardtype, setBoardType] = useState(props.project.board_type);
    const [boardEmail, setBoardEmail] = useState(props.project.canva_access);

    /* Changes to record change log */
    const [fieldChanges, setFieldChanges] = useState([]);
    const statusarray = ['Not Set', 'Trial','Active', 'Hold','Paused', 'Cancelled'];
    useEffect(() => {
        let fields = [];
        if(props.project.board_name != boardname)
            fields.push('Board Name: '+props.project.board_name+" -> "+boardname);
        if(props.project.short_description != boarddescription)
            fields.push('Board Description: '+props.project.short_description+" -> "+boarddescription);
        if(props.project.graphics_pack != graphicspack)
            fields.push('Graphics Pack: '+props.project.graphics_pack+" -> "+graphicspack);
        if(props.project.video_pack != videopack)
            fields.push('Video Pack: '+props.project.video_pack+" -> "+videopack);
        if(props.project.graphics_video_pack != graphicsvideopack)
            fields.push('Graphics & Video Pack: '+props.project.graphics_video_pack+" -> "+graphicsvideopack);
        if(props.project.client_status != clientstatus)
            fields.push('Status: '+statusarray[props.project.client_status]+" -> "+statusarray[clientstatus]);
        if(props.project.white_label != whitelabel)
            fields.push('White Label');
        if(props.project.board_type != boardtype)
            fields.push('Board Type:'+ props.project.board_type+ " -> "+boardtype);
        if(props.project.onboard_360 != onboarded360)
            fields.push('On Boarded to 360:'+ props.project.onboard_360+ " -> "+onboarded360);
        if(props.project.board_access != boardEmail)
            fields.push('Mailbox:'+ props.project.board_access+ " -> "+boardEmail);
        setFieldChanges(fields);
    }, [boardname, boarddescription,graphicspack,videopack,graphicsvideopack,clientstatus,whitelabel,boardtype, onboarded360]);

    /* Change End*/

    function saveNewBoard(mdata)
    {
        if(boardname.length <=0 )
        {
            setBoardErrors(boarderrors => [...boarderrors, 'boardname'])
            return false;
        }else if(boarddescription.length <=0)
        {
            setBoardErrors([]);
            setBoardErrors(boarderrors => [...boarderrors, 'boarddescription'])
            return false;
        }
        

        var boardupdated = project;
        boardupdated.board_name = boardname;
        boardupdated.board_type = boardtype;
        boardupdated.short_description = boarddescription;
        boardupdated.client_status = parseInt(clientstatus);
        boardupdated.white_label = whitelabel;
        boardupdated.graphics_pack = parseInt(graphicspack);
        boardupdated.video_pack = parseInt(videopack);
        boardupdated.graphics_video_pack = parseInt(graphicsvideopack);
        boardupdated.view_type = parseInt(viewType);
        boardupdated.onboard_360 = parseInt(onboarded360);
        boardupdated.canva_access = boardEmail;


        const data = {
            board_name: boardname,
            board_desc: boarddescription,
            board_id: props.project.board_id,
            owner: props.userid,
            board_type: boardtype,
            graphicscount: parseInt(graphicspack),
            videoscount: parseInt(videopack),
            graphicsvideocount:parseInt(graphicsvideopack),
            client_status: clientstatus,
            white_label:whitelabel,
            onboard_360: onboarded360,
            view_type:viewType,
            field_changes: fieldChanges,
            bid_cpt: props.project.bid_cpt,
            canva_access: boardEmail
          };
          const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin':'*',
          }
          axios.post(urls.editBoard, data, {
              headers: headers
            })
            .then((response) => {
                props.editboard(boardupdated);
            })
            .catch((error) => {
                console.log(error);
            })

    }

    function updateWhiteLabel(e)
    {
        if(e.target.checked === true) {
            setWhiteLabel(1);
        }else
        {
            setWhiteLabel(0);
        }
    }

    function update360Onboard(e)
    {
        if(e.target.checked === true) {
            setOnboarded360(1);
        }else
        {
            setOnboarded360(0);
        }
    }

    function updateViewType(e)
    {
        if(e.target.value == 1) {
            setViewType(1);
        }else
        {
            setViewType(0);
        }
    }

    return(
        
        <div className="boardadd_form">
            <form onSubmit={handleSubmit(onSubmit)}>
                
                <Row>
                  
                  <Col xs={12} sm={4}> 
                  <div className="form-group">
                    <label>Board Name</label>
                    <Input {...register("boardname", { required: true })} value={boardname} onChange={e => setBoardName(e.target.value)} 
                     className={'text_box' + (boarderrors.includes('boardname') ? " error-field" : "")} />
                    </div>
                    <div className="form-group">
                        <label>Board Description</label>
                        <Input {...register("boarddescription", { required: true })}  type="textarea"  value={boarddescription} onChange={e => setBoardDescription(e.target.value)} 
                        className={'textarea_box' + (boarderrors.includes('boarddescription') ? " error-field" : "")} 
                        />
                    </div>
                  </Col>

                  <Col xs={12} sm={4}>
                  <div className="search_drop_grp whitelable_chooser">
                    <Row>
                        <Col xs={12} sm={12}>
                        <div className="form-group choose_all_package">
                            <Label>Graphics</Label>
                            <Input type="select" name="graphicsPack" onChange={e=>
                              setGraphicsPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                  <option selected={graphicspack == i+1 && 'selected'}  key={i+1} value={i+1}>{i+1}</option>
                                )}
                            </Input>
                        </div>
                        </Col>
                        <Col xs={12} sm={12}>
                        <div className="form-group choose_all_package">
                            <Label>Video</Label>
                            <Input type="select"  name="videoPack" onChange={e=>
                              setVideoPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                  <option selected={videopack == i+1 && 'selected'} key={i+1} value={i+1}>{i+1}</option>
                                )}
                            </Input>
                        </div>
                        </Col>
                        <Col xs={12} sm={12}>
                        <div className="form-group choose_all_package">
                            <Label>Graphics + VIDEO</Label>
                            <Input type="select" name="graphicsvideoPack" onChange={e=>
                              setGraphicsVideoPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                 <option selected={graphicsvideopack == i+1 && 'selected'} key={i+1} value={i+1}>{i+1}</option>
                                )}
                            </Input>
                        </div>
                        </Col>
                    </Row>
                </div>
                  </Col>

                  <Col xs={12} sm={4}>
                    <Label>Board Type</Label>
                    <div className="form-group choose_project">
                        <div className="d-flex reminder-options custom_radio ">
                            <div className="d-flex">
                                <Input type="radio" name="projectType" value="1" checked={boardtype=='1'
                                    && "checked" } id="1" onChange={e=> setBoardType(e.target.value)} />
                                <Label for="1" className="pro_type_lable">Client Board</Label>
                            </div>
                            <div className="d-flex">
                                <Input type="radio" name="projectType" value="2" id="2" checked={boardtype=='2'
                                    && "checked" } onChange={e=> setBoardType(e.target.value)} />
                                <Label for="2" className="pro_type_lable">Internal Board</Label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group choose_all_package">
                        <Label>Status</Label>
                        <Input type="select" name="clientstatus" onChange={e=>
                            setClientStatus(e.target.value)}>
                            <option value="">Select</option>
                            <option value="1" selected={clientstatus == '1' && 'selected'}>Trial</option>
                            <option value="2" selected={clientstatus == '2' && 'selected'} >Active</option>
                            <option value="3" selected={clientstatus == '3' && 'selected'} >Hold</option>
                            <option value="4" selected={clientstatus == '4' && 'selected'} >Paused</option>
                            <option value="5" selected={clientstatus == '5' && 'selected'}>Cancelled</option>
                        </Input>
                    </div>
                    <div className="form-group choose_all_package">
                        <Label>Labels</Label>
                        <Input type="text" className="text_box" /> 
                    </div>
                    </Col>
                    <Col xs={12} sm={4}>
                     <div className="d-flex flex-itmes white_lab_checkbox">
                      <Input id="white-label" type="checkbox" name="Softwares" value={whitelabel} 
                      checked={whitelabel === 1 && 'selected'} onChange={e=>
                        updateWhiteLabel(e)} />
                      <Label for="white-label">White label</Label>
                     </div>
                   </Col>
                   <Col xs={12} sm={4}>
                     <div className="d-flex flex-itmes white_lab_checkbox onboarded_check">
                      <Input id="on-boarded" type="checkbox" name="onBoarded360" value={onboarded360} 
                      checked={onboarded360 === 1 && 'selected'} onChange={e=>
                        update360Onboard(e)} />
                      <Label for="on-boarded">360 Client</Label>
                     </div>
                   </Col>

                   {boardtype == 2 &&
                   (
                    <Col xs={12} sm={4}>
                        <div className="form-group choose_project card-list-view">
                            <div className="d-flex align-items-center reminder-options custom_radio ">
                                <Label className='view-type-label'>View Type</Label>
                                <div className="d-flex">
                                       <Input id="view-type-0" type="radio" name="viewType" value={0} 
                                        checked={viewType === 0 && 'checked'} onChange={e=>
                                        updateViewType(e)} />
                                        <Label for="view-type-0" className="pro_type_lable" >List View</Label>
                                </div>
                                <div className="d-flex">
                                    <Input id="view-type-1" type="radio" name="viewType" value={1} 
                                    checked={viewType === 1 && 'checked'} onChange={e=>
                                    updateViewType(e)} />
                                    <Label for="view-type-1" className="pro_type_lable" >Kanban View</Label>
                                </div>
                            </div>
                        </div>
                    </Col>

                   )}
                    <Col xs={12} sm={12}>
                        <div className="form-group canva-mailbox">
                            <Label className='view-type-label'>Mailbox</Label>
                            <Input type='text' className='typetext' placeholder="Enter mailbox email address"
                            value={boardEmail}
                            onChange={e => setBoardEmail(e.target.value)}   />
                        </div>
                    </Col>
                    <Col xs={12} sm={12}>
                        <div className="form-group">
                            <Button type="submit" className="save_btn" onClick={saveNewBoard}>Save</Button>
                        </div>
                    </Col>
                </Row>

            </form>
        </div>
    )
}
