import React, { Component, Fragment } from "react";
import "./ApprovalHead.css";
import { Container, Col, Row } from "reactstrap";
import { Tab, TabList,} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class ApprovalHead extends Component {
  constructor(props) {
    super();
    this.state = {
      isSticky: false,
      breakActive: false,
      unblockShiftActive: false,
      wfhActive: false,
      leaveActive: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.sticky);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.sticky);
  }

  sticky = () => {
    // this.buttonActiveState();
    const _this = this;
    let approveHead = _this.ApprovalHeadRef;    
    if (window.scrollY > 200) {
      _this.setState({ isSticky: true });
    } else if (window.scrollY < 200) {
      _this.setState({ isSticky: false });
    }
  };

  scrollToElem = id => {
    this.props.scrollToElem(id);
  };

  // buttonActiveState = () => {
  //   let select = document.querySelector;
  //   document.querySelectorAll("#approval-head button").forEach(function(item) {
  //     let hasClass = Array.from(item.classList).includes("active");
  //   });
  // };

  render() {
    let apprClass = ["d-flex", this.state.isSticky && "header_is_sticky"].join(
      " "
    );
    return (
      
      <section
      id="approval-head"
      className={apprClass}
      ref={this.ApprovalHeadRef}
    >
      <button
        className={
          "appr-break " +
          (this.props.weeklylate > 0 ? "appr-tab-has-msg break-module" : "") +
          (this.state.weeklylate && "active")
        }
        data-right={this.props.weeklylate}         
        onClick={this.scrollToElem.bind(this, "late-weekly")}
      >
        Late Weekly
      </button>
      <button
        className={
          "appr-unblock-shift " +
          (this.props.monthlylate > 0
            ? "appr-tab-has-msg shift-unblock-module"
            : "") +
          (this.state.unblockShiftActive && "active")
        }         
        data-right={this.props.monthlylate}
        onClick={this.scrollToElem.bind(this, "late-monthly")}
      >
        Late Monthly
      </button>
      <button
        className={
          "appr-leave " +
          (this.props.weeklyquota > 0
            ? "appr-tab-has-msg approve-leave"
            : "") +
          (this.state.leave && "active")
        }         
        data-right={this.props.weeklyquota}
        onClick={this.scrollToElem.bind(this, "weekly-quota")}
      >
        O.Q Weekly
      </button>
      <button
        className={
          "appr-wfh " +
          (this.props.monthlyquota > 0 ? "appr-tab-has-msg wfh" : "") +
          (this.state.wfh && "active")
        }
        data-right={this.props.monthlyquota}
        onClick={this.scrollToElem.bind(this, "monthly-quota")}
      >
        O.Q Monthly
      </button>
      <button
        className={
          "appr-wfh " +
          (this.props.dailyworkhours > 0 ? "appr-tab-has-msg wfh" : "") +
          (this.state.wfh && "active")
        }
        data-right={this.props.dailyworkhours}
        onClick={this.scrollToElem.bind(this, "work-hours")}
      >
        Work Hours
      </button>
      <button className="appr-loan">Others</button>
    </section>
    );
  }
}
