import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import './Healthreport.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button, Table } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import StarRatings from 'react-star-ratings';
import moment from "moment";
import DatePickerTick from "../filter/DatePickerTick";
import ReactQuill from 'react-quill'; 
import Commenticonblack from '../../images/comment_black.svg';
import Commenticongreen from '../../images/comment_green.svg';
import Statuswating from '../../images/statusicon_waiting.svg';
import Closepopup from '../../images/close-icon.png';
import ReactHtmlParser from 'react-html-parser';

export default class HealthReport extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'', 
      myBaseId:'',
      userTeam:'',     
      myPrivileges:[],
      selectedUser:'',
      allBoards:[],
      allBoardLastupdate:'',
      orginalUsers: [],
      singleCollection:[],
      showSingle:false,
      initialLoader:true,
      newRating:0,
      showCommentBox:false,
      comments:'',
      detailError:false,
      currentEditRating:0,
      currentEditClientName:'',
      currentEditBoard:'',
      showCBox:false,
      showCommentId:'',
      activeShift:1,
      shiftDate:'',
      trelloLink:'',
      basecampLink:'',
      designerInvolved:'',
      currentCheckedId:'',
      showMenu:true,
    };
   // this.keyPress = this._handleKeyDown.bind(this);
   this.handleChangeEditor = this.handleChangeEditor.bind(this);
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Client Health Report";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {     
      _this.getMyTrelloCollection();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('show_active_trello_boards'))
    {
      this.props.history.push("/home");
    }
  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  getClientRating = (boards) =>{  
    let opts ={    
      user_id: this.state.myId,
    } 
    fetch(urls.getClientRating,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      console.log(response.rated);
       // this.mergeBoards(boards,response.rated);
        this.setState({
            activeShift:response.loggedin,
            shiftDate:response.shiftdate
        })
        this.mergeByProperty(boards,response.rated, 'board_id');
    })
  };

  mergeByProperty = (target, source, prop) => {
    source.forEach(sourceElement => {
      let targetElement = target.find(targetElement => {
        return sourceElement[prop] === targetElement[prop];
      })
      targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement);
    })
    this.setState({singleCollection:target});
  }

  mergeBoards = (boards, rated) =>
  {
    let arr3 = boards.map((item, i) => Object.assign({}, item, rated[i]));
    this.setState({singleCollection:arr3});
  }

  

  getMyTrelloCollection = () =>{        
    fetch(urls.getMyTrelloCollection+this.state.myId,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {  
        if(response[0].collection.length>0) 
        {
          this.setState({
            selectedUser: response[0].collection[0].trello_name
          }); 
          this.getAllCollections();
          const _this = this;
          setTimeout(function() {     
            _this.getSingleBoard();
          }, 200);
        }else
        {
          this.getAllCollections();
          this.getAllBoards();
        }  

    })
    .catch(error =>
      {
        this.getAllCollections();
        this.getAllBoards();
      })
  };

  getAllBoards = () =>{        
    this.setState({initialLoader:true});
    fetch(urls.getAllTrelloBoards,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {       
        this.setState({
            allBoards: response[0].members,  
            allBoardLastupdate: response[0].last_sync,
            initialLoader:false  
        });            
    })
  };

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getSingleBoard = () =>{  
    let opts ={    
      member: this.state.selectedUser,
      datte: 7
    } 
    fetch(urls.getTrelloSingleHealth,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        },
        method:'post',
        body:JSON.stringify(opts)
      }
    )
    .then(response => response.json())
    .then(response => {
      if (response && response.length > 0) {
        console.log(response);
        this.getClientRating(response[0].boards);  
        this.setState({
          singleCollection: response[0].boards, 
          allBoardLastupdate: response[0].last_sync,
          showSingle:true,  
          initialLoader:false       
        });   
            
      } 
    })
  };


  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  handleTextChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
	};

  handleChangeEditor(value) {
    this.setState({ comments: value, detailError:false })
  }
  sortBoards(order, response) {
		let temp = response;
		  if (order === "asc") {
			temp.sort(ascOrder);
			return temp;
		  } else if (order === "desc") {
      temp.sort(descOrder);
      return temp;
	  }
		function ascOrder(a, b) {
			return new Date(a.last_activity).getTime() - new Date(b.last_activity).getTime();
		}
		function descOrder(a, b) {
			return new Date(b.last_activity).getTime() - new Date(a.last_activity).getTime();
		}
    }

    onDateRangeChangeStart = (start) => {
        let momentObj = moment(start);
        let momentString = momentObj.format("DD-MM-YYYY");
        this.setState({start_date:momentString});
    };

    changeRatingInFetched = (index, newrating, comment)=>
    {
        let temp = this.state.singleCollection;
        temp[index].rating = newrating;
        if(comment !='')
        {
            temp[index].comments = comment;
        }
        this.setState({
            singleCollection:temp
        });
    }
    
    changeRating = ( newRating, name ) => {
        if(newRating === 5)
        {  
            var changeindex = this.state.singleCollection.map((el) => el.board_id).indexOf(name);
            this.changeRatingInFetched(changeindex, newRating, this.state.singleCollection[changeindex].comments);
            let opts={
                rating:newRating,
                board_id:name,
                user_id: this.state.myId,
                shift_date:this.state.shiftDate,
                client_name: this.state.singleCollection[changeindex].board_name
            };
            fetch(urls.updateClientRating,
                {
                  headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json"
                  },
                  method:'post',
                  body:JSON.stringify(opts)
                }
              )
              .then(response => response.json())
              .then(response => {
                
              })
        }else
        {
            var changeindex = this.state.singleCollection.map((el) => el.board_id).indexOf(name);
            this.setState({
                currentEditRating:newRating,
                currentEditBoard:name,
                comments:this.state.singleCollection[changeindex].comments != null ? this.state.singleCollection[changeindex].comments : '<p></p>',
                showCommentBox:true, 
            });
        }
      }

      updateDelayedReply = (e) => {
        this.setState({filterHour:e.target.value}); 
        if (e.target.checked) {
          var delayed=1;
          this.setState({currentCheckedId: e.target.value});
        }else
        {
          this.setState({currentCheckedId:''})
          var delayed=0;
        }
        var changeindex = this.state.singleCollection.map((el) => el.board_id).indexOf(e.target.value);
        let opts={
          board_id:e.target.value,
          user_id: this.state.myId,
          shift_date:this.state.shiftDate,
          client_name: this.state.singleCollection[changeindex].board_name,
          delayed: delayed
        };
        fetch(urls.updateClientRatingDelayed,
            {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json"
              },
              method:'post',
              body:JSON.stringify(opts)
            }
          )
          .then(response => response.json())
          .then(response => {
            this.getSingleBoard();
          })
      }

      closeCommentBox = () =>
      {
        this.setState({
            showCommentBox:false, 
            currentEditRating:0,
            currentEditBoard:'',
            showCBox:false,
            showCommentId:''
        });
      }

      updateRatingWithComment = () =>
      {
            var newcomment = this.state.comments.replace( /(<([^>]+)>)/ig, '');
            if(this.state.currentEditRating<5 && newcomment.length<=0)
            {
                this.setState({
                    detailError:true
                })
                return false;
            }
            var changeindex = this.state.singleCollection.map((el) => el.board_id).indexOf(this.state.currentEditBoard);
            let opts={
                rating:this.state.currentEditRating,
                board_id:this.state.currentEditBoard,
                user_id: this.state.myId,
                shift_date:this.state.shiftDate,
                comment:newcomment.length>0 ? this.state.comments : null,
                client_name: this.state.singleCollection[changeindex].board_name,
                trello_card:this.state.trelloLink,
                basecamp_task:this.state.basecampLink,
                designers:this.state.designerInvolved,
            };
            
            this.changeRatingInFetched(changeindex, this.state.currentEditRating, newcomment.length>0 ? this.state.comments : null);
            fetch(urls.updateClientRating,
                {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json"
                },
                method:'post',
                body:JSON.stringify(opts)
                }
            )
            .then(response => response.json())
            .then(response => {
                this.setState({
                    currentEditRating:'',
                    currentEditBoard:'',
                    comments:'',
                    basecampLink:'',
                    trelloLink:'',
                    designerInvolved:'',
                    showCommentBox:false
                })
            })
      } 
      
      showCommentBox = (id) =>
      {
        
        var showindex = this.state.singleCollection.map((el) => el.board_id).indexOf(id);  
        this.setState({
              showCBox:true,
              showCommentId:showindex
          })
      }

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"}> 
      <Row>
        <div className="theme_header">
          <Header {...this.props}  classname="tick-timer-header"  
          logout={this.signOut.bind(this)} 
          onmenuaction={this.toggleSidebar.bind(this)}
          quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="daytoday" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
          <Row>
            <Col xs={12} md={12}>
            <Card className="cl_helth_report">
        <h1 class="page-title text-center her_title">Client Health Report 
        {this.state.shiftDate!='' && (' ('+this.state.shiftDate+')')}
        </h1>
        
        <motion.div 
              initial="hidden"
              animate="visible"
              variants={variants}>
                  <div id="resposive_reports">
        <LoadingWrapper isLoading={this.state.initialLoader}>
               {this.state.activeShift === 1?
               (
                  <>
                <Table responsive className="theme_table">
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Rating</th>
                      <th>Comments</th>
                      <th align="right">Delayed Reply</th>
                    </tr>
                  </thead>
                  {this.state.singleCollection.map((item, index) =>
                          {
                        return(
                  <tbody>
                    <tr className="clearfix">
                      <td scope="row"><a  className="report-tab-list" href={"https://trello.com/b/"+item.board_id+"/"+item.board_name} target="_blank">{item.board_name}</a></td>
                      <td>
                        <StarRatings
                        rating={item.rating}
                        starRatedColor="orange"
                        changeRating={this.changeRating}
                        numberOfStars={5}
                        name={item.board_id}
                        starSpacing="3px"
                        starDimension="30px"
                        starEmptyColor="#e4dddd"
                        starHoverColor="orange"
                        />
                      </td>
                      <td>
                        <span className="comd_icon">
                          {item.comments != null && item.comments !='' ?
                          (
                              <img src={Commenticongreen} onClick={this.showCommentBox.bind(this,item.board_id)}/>
                          )
                          :
                          (
                              null
                          )}
                        </span>
                      </td>
                      <td className="float-right">
                        <span className="status_icon">
                            <Input className="delayed-message" type="checkbox" checked={(item.delayed_message === 1 || this.state.currentCheckedId === item.board_id) && 'checked'} name="delayedMessage" value={item.board_id} onClick={this.updateDelayedReply.bind(this)} />
                         </span>
                      </td>
                    </tr>
                  </tbody>
                     )
                    })}
                </Table>
                  </>
               )
              :
              (
                <div className="no-active-shift text-center ">Sorry you don't have an active shift to rate the clients</div>
              )
              }
         
        </LoadingWrapper>
        </div>
        </motion.div>
        </Card>
            </Col>
          </Row>

        </div>
        </Row>
      {this.state.showCommentBox &&
            (
                <>
                    <div className="health-overlay" onClick={this.closeCommentBox}></div>
                    <div className="health-comments">
                    <span className="closebtn" onClick={this.closeCommentBox}><img src={Closepopup}/></span>
                        <div className="star-rating-given">
                            <StarRatings
                                rating={this.state.currentEditRating}
                                starRatedColor="orange"
                                changeRating={this.changeRating}
                                numberOfStars={5}
                                name={this.state.currentEditBoard}
                                starSpacing="3px"
                                starDimension="30px"
                                starEmptyColor="#e4dddd"
                                starHoverColor="orange"
                            />
                        </div>
                        <div className="health-comments-edit">
                                <ReactQuill value={this.state.comments}
                                onChange={this.handleChangeEditor}
                                className={this.state.detailError? 'field-error' : ''}
                                placeholder= "Enter your comments here..." 
                                theme="snow" />
                                <div className="health-comment-field">
                                  <Input type="text" name="trelloLink" 
                                  value={this.state.trelloLink !== null? this.state.trelloLink : '' }
                                  placeholder="Trello Card URL"  onChange={this.handleTextChange}/>
                                </div>
                                <div className="health-comment-field">
                                  <Input type="text" name="basecampLink" 
                                  value={this.state.basecampLink !== null? this.state.basecampLink : '' }
                                  placeholder="Basecamp Task URL" onChange={this.handleTextChange} />
                                </div>
                                <div className="health-comment-field">
                                  <Input type="text" name="designerInvolved" 
                                  value={this.state.designerInvolved !== null? this.state.designerInvolved : '' }
                                  placeholder="Designers Involved"  onChange={this.handleTextChange} />
                                </div>
                        <button type="submit" class="add_cmd_btn" onClick={this.updateRatingWithComment.bind(this)}>Add comment</button>
                        </div>
                    </div>
                </>
            )}
            {(this.state.showCBox && this.state.showCommentId>=0) &&
            (
                <>
                    <div className="health-overlay" onClick={this.closeCommentBox}></div>
                    <div className="health-comments">
                    <span className="closebtn"><img src={Closepopup} onClick={this.closeCommentBox}/></span>
                        <div className="star-rating-given">
                            <StarRatings
                                rating={this.state.singleCollection[this.state.showCommentId].rating}
                                starRatedColor="orange"
                                numberOfStars={5}
                                name={this.state.currentEditBoard}
                                starSpacing="3px"
                                starDimension="30px"
                                starEmptyColor="#e4dddd"
                                starHoverColor="orange"
                                isSelectable={false}
                            />
                        </div>
                        <div className="health-comments-edit">
                            <div className="description comment_content">
                            {ReactHtmlParser(this.stripslashes(this.state.singleCollection[this.state.showCommentId].comments) + " ")}
                            </div>
                         </div>
                    </div>
                </>
            )}
      </Container>
      </div>
    )
  }
}
