import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import ReactDOM from "react-dom";
import Select from "react-select";


export default class CountryFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemtitle: "",
      multi: true,
      multiValue: "Filter by Country",
      options: [
        {value:'All', label: 'All'},
        { value: "IN", label: "India" },
        { value: "LK", label: "Srilanka" },
        { value: "ID", label: "Indonesia" },
        { value: "Others", label: "Others" }
      ]
    };
  }

  onTitleChange(e, value) {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ multiValue: e.target.value });
  }
  handleOnChange(value) {
    this.setState({ multiValue: value });
    this.props.setCountry(value);
  }

  render() {
    return (
      <div className="salary_country_choose">
        <Select
          // multi={this.state.multi}
          options={this.state.options}
          onChange={this.handleOnChange.bind(this)}
          value={this.state.multiValue}
          isSearchable={false}
          placeholder="Filter by Country" 
        />
      </div>
    );
  }
}


