import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import {Button, Container, Row, Col} from  'reactstrap'
const ProfilePhoto = ({ getData, imageSrc }) => {
  const [toggle, setToggle] = useState(false);
  const [existImage, setexistImage] = useState(imageSrc);

  const handleToggleClick = () => {
    setToggle(true);
    if(existImage !=imageSrc)
    {
     getData(true, imageSrc);
    }
  };

  const deletePic = () => {
    console.log('entered');
    setToggle(false);
    getData(false, "");
  };

  return (
    <div className="choose_imag_btns">
      <Button
        type="button"
        onClick={handleToggleClick}
        className="btn btn-primary rounded-circle mt-2 opaque profile-pic"
        disabled={toggle && imageSrc}
      >
        {(!imageSrc) && (
          <FontAwesomeIcon icon={faUserAlt} color="white" size="3x" />
        )}
        {imageSrc && (
          <img
            alt="profile"
            src={imageSrc}
            className="rounded-circle"
            width="100%"
          />
        )}
      </Button>
      {toggle && imageSrc && (
        <Button
          type="button"
          className="btn btn-danger rounded-circle position-relative delete-button"
          onClick={deletePic}
        >
          <FontAwesomeIcon icon={faTrashAlt} color="white" size="xs" />
        </Button>
      )}
    </div>
  );
};

export default ProfilePhoto;
