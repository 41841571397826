import React, { Component } from "react";
import { Input, Button, Container, Row, Col } from "reactstrap";
import PageSubTitle from "../../page-title/PageSubTitle";
import "./Logamend.css";
import LoadingBar from "../../common/LoadingBar";
import ConfirmAction from "../../admincomment/ConfirmAction";
import CloseIcon from "../../svgicon/CloseIcon";
import { Scrollbars } from 'react-custom-scrollbars';
import MaterialIcon from "material-icons-react";
export default class LogAmend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tassignees: this.props.tassignees,
      //newassignees: ["" + this.props.myid + "", "" +this.props.managerid+ ""],
      newassignees: ["" + this.props.myid + ""],
      newassignees: this.props.taskassigneearray,
      myid: this.props.myid,
      activeme: true,
      completionStatus: false,
      showCompletionDialogue: false,
      myPrivileges: [],
      showCompletion: false,
      hideshowbox:true,
      showMinimumMsg:false,
      passigneesOriginal: this.props.passignees,
      passignees: this.props.passignees,
      searchKey:'',
      MinimumMsg:"Please select at least one person"
    };
  }

  componentDidMount() {
    this.checkUserPrivilege();
  }
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({ myPrivileges: login.privileges });
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
   
  };

 

  filterItems = (key) => {
    if(key.length>0)
    {
      let temp = this.props.passignees;
      let list =[];
      temp.forEach((p1)=>
      {
        if(p1.name.toLowerCase().includes(key.toLowerCase()))
        {
          list.push(p1);
        }
      })
      this.setState({
        passignees:list
      })
    }else
    {
      this.setState({
        passignees:this.props.passignees
      })
    }
  }

  handleClick = e => {
    let members = this.state.newassignees;
    this.setState({ myid: "", showMinimumMsg:false});
    var ret = this.state.tassignees.replace(e.target.value, "");
    this.setState({ tassignees: ret });
    if (e.target.checked) {
      members.push(e.target.value);
      this.setState({
        tassignees: this.state.tassignees + "," + e.target.value
      });
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      this.setState({ newassignees: array });
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handleunassignme = e => {
    this.setState({ activeme: !this.state.activeme, showMinimumMsg:false });
    let members = this.state.newassignees;
    if (e.target.checked) {
      members.push(e.target.value);
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ newassignees: array });
      }
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handlecompletion = () => {
    this.setState({ showCompletionDialogue: true, hideshowbox:false });
  };

  handleAssigneeUpdate = () => {
    if(this.state.newassignees.length<=0)
    {
      this.setState({
        showMinimumMsg:true
      });
      return false;
    }else
    {
      this.props.onassigneupdate(
        this.state.newassignees,
        this.state.completionStatus
      );
    }
   
  };

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false,
      hideshowbox:true
    });
  };

  handleCompletionConfirm = () => {
    this.setState({ completionStatus: !this.state.completionStatus });
    // this.setState({completionStatus: false});
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.handleAssigneeUpdate();
    }, 1000);
  };

  closePopup = () => {
    this.props.closeaction();
  };

  handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
    this.filterItems(target.value);
	};

  render() {
    return (
      <Container className="logamend-modal old_task_pageassignee">
        
        <div className="overlay-log" onClick={this.closePopup}></div>
        {this.state.hideshowbox && 
        (
          
          <div className="logamend">
            <Button className="close-icon" onClick={this.closePopup}> <MaterialIcon icon="close"/></Button>
           {this.props.loading === true ? (
            <LoadingBar />
          ) : (
            <Row>
              <Col className="has-border-right">
                <div className="change-assignee">
                  <PageSubTitle title="Change Status" />
                  <span className="member-not-found text-center ">
                      Can't see the Team Member you wish to reassign to? Please navigate to "Task" in the menu and select "Add New Task" to add your task once more. This will refresh the list of Team Members.
                    </span>
            
                 
                    <Row>
                      {this.state.showMinimumMsg &&
                      (
                        <Col sm="12"><span className="minimum-text text-center">{this.state.MinimumMsg}</span></Col>
                      )}
                      
                      <Col sm="12">
                        <div className="search-filter-assignees">
                          <Input type="text" name="searchKey" placeholder="Type here to filter members below" value={this.state.searchKey} onChange={this.handleChange} />
                        </div>
                        <div className="d-flex choose_assiner_name">
                          <Input
                            type="checkbox"
                            value={this.props.myid}
                            checked={this.state.activeme ? "checked" : null}
                            onChange={this.handleunassignme.bind(this)}
                          />
                          <span className="assigni-name">
                            {this.props.myname}
                          </span>
                        </div>
                        {this.state.passignees &&
                        this.state.passignees.length > 0 &&
                        this.state.tassignees !== "" ? (
                          <div className="all-project-users">
                            <Row>
                            {this.state.passignees.map((user, index) => {
                              return (
                                <Col xs="12" sm="6" md="4" lg="4">
                                <div className="d-flex assigning_checkbox" key={index}>
                                  <Input
                                    type="checkbox"
                                    value={user.basecamp_user_id}
                                    checked={
                                      this.state.tassignees.includes(
                                        user.basecamp_user_id
                                      )
                                        ? "checked"
                                        : null
                                    }
                                    onChange={this.handleClick.bind(this)}
                                  />
                                  <span className="assigni-name">
                                    {user.name}
                                  </span>
                                </div>
                                </Col>
                              );
                            })}
                            </Row>
                          </div>
                        ) : null}
                      </Col>
                      <Col sm="12" className="d-flex">
                        {/* Mark Complete
                          <div>
                            <Input type="checkbox"  onChange={this.handlecompletion.bind(this)} /></div> */}
                        <Button
                          className="project-assignee-submit btn-green"
                          onClick={this.handleAssigneeUpdate.bind(this)}
                        >
                          Reassign
                        </Button>

                        {this.state.showCompletion ? (
                          <Button
                            className="project-assignee-submit btn-green"
                            onClick={this.handlecompletion.bind(this)}
                          >
                            Mark as Completed
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  
                
                </div>
              </Col>
            </Row>
          )}
        
        </div>
       
        )
        }
        {this.state.showCompletionDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showCompletionDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to change the status of this task to completed. This will mark the task status in basecamp as completed."
            handleCompletionConfirm={this.handleCompletionConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
      </Container>
    );
  }
}
