import React, { Component, Fragment } from "react";
import { Input, Button, Container, Row, Col } from "reactstrap";
import PageSubTitle from "../../page-title/PageSubTitle";
import "./Logamend.css";
import LoadingBar from "../../common/LoadingBar";
import ConfirmAction from "../../admincomment/ConfirmAction";
import CloseIcon from "../../svgicon/CloseIcon";
import AttachmentIcon from "../../svgicon/AttachmentIcon";
import Linkify from 'linkifyjs/react';
import trelloRefrsh from "../../../images/trello-activity.png";
import MaterialIcon from "material-icons-react";

export default class LogAmend extends Component {
  constructor(props) {
    super(props);
    this.state = {     
      
      myPrivileges: [],
      showCompletion: false,
      cardNewComments:[],
      cardOrginalComments:this.props.cardcomments,
      options: {
        attributes: null,
        className: 'linkified',
        defaultProtocol: 'http',
        events: null,
        format: function (value, type) {
          return value;
        },
        formatHref: function (href, type) {
          return href;
        },
        ignoreTags: [],
        nl2br: true,
        tagName: 'a',
        target: {
          url: '_blank'
        },
        validate: true
      }
    };
  }

  componentDidMount() {
    this.checkUserPrivilege();
    const _this = this;
     setTimeout(() => {
    _this.filterComments(_this.props.cardcomments);
    }, 700);
  }
 

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({ myPrivileges: login.privileges });
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
  };

  filterComments = (items) =>
  {
      let temp=[];
      temp = items;
      let list=[];
      console.log(temp);
      temp.forEach(p1=>
      {   
           if(p1.data.text && p1.data.text.includes("trello-attachments.s3.amazonaws.com"))
           {
              var squarebc = p1.data.text.indexOf("[");        
              var remtext = p1.data.text.substring(0, squarebc);

              //var regExpsq = /\[([^)]+)\]/;
             // var matchessq = regExpsq.exec(p1.data.text);
              var regExpcc =/(https?:\/\/[^\s]+)/;
              var matchescc = regExpcc.exec(p1.data.text);           
              var final = remtext;

              
              matchescc.forEach(s1=>
              {
                  s1 = s1.replace(" [", ""); 
                  s1 = s1.replace(")", "");
                  final = final + s1.replace("(", "")+" ";
                       
              })

              p1.data.text = final;
              p1.type ="commentattachment";
              list.push(p1);
           }else
           {
              list.push(p1);
           }

           this.setState({
            cardNewComments:list
           })
      })  
  }


 

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false
    });
  };

 
  closeCard = () => {
    this.props.closecard();
  };


  fetchCardid = (url) =>
  {
      var urlparts = url.split("/");
      this.props.showtrellocard(urlparts[4]);
  }

   getFormattedDate = date => {
    var today = new Date(date);
    var week = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var day = week[today.getDay()];
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
  
    var hours = today.getHours();
    var minutes = today.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;

    var month_names = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
   

    return day + " " + month_names[today.getMonth()] + " " + dd + ", " + yyyy +" "+ strTime;
  };

 

  render() {
    return (
      <Container>
         <div className="overlay-log" onClick={this.closeCard}></div>
        <div className="trello-card" style={{marginTop: this.props.cardtop}}>
          <Button className="close-icon" onClick={this.closeCard}><MaterialIcon icon="close"/></Button>
          {/* <CloseIcon closeaction={this.closeCard} /> */}
          {this.props.loading === true ? (
            <LoadingBar />
          ) : (
            <>
                <div className="task_trllo_frist_sec">
                 <h3>{this.props.carddetails.name}</h3>
                  {this.props.carddetails.due !== null?
                    (<h4>Due: {this.getFormattedDate(this.props.carddetails.due)}</h4>)
                    :
                    null
                  }
                  </div>
                <h6 className="card-desc">Description</h6>               
                <div>
                  <p className="card-detail-description">                    
                    <Linkify  options={this.state.options}>{this.props.carddetails.desc}</Linkify>
                  </p>
                </div>
                 <div>
                    <h6>
                      <b>Attachments</b>
                    </h6>
                    {this.props.cardattachments.length > 0 ? (
                      <div>
                        {this.props.cardattachments.map((attachment, index) => {
                          return (
                            <div style={{ marginBottom: "20px" }}>
                              <div className="card-detail-label-container">
                                <div className="attachment-block">
                                  {attachment.mimeType === '' && attachment.url.includes("https://trello.com/")?
                                    (
                                      <>
                                          <a className="trello-attachment" onClick={this.fetchCardid.bind(this, attachment.url)} ><span className="attachmet-icon">
                                              <AttachmentIcon />
                                            </span></a>
                                            <span className="attachmet-label">
                                               <a className="trello-attachment" onClick={this.fetchCardid.bind(this, attachment.url)} >{attachment.name}</a>
                                              <br />
                                              Added{" "} {this.getFormattedDate(attachment.date)}
                                            </span>
                                      </>
                                    ):
                                    (
                                        <>
                                          <a href={attachment.url} target="_blank"><span className="attachmet-icon">
                                              <AttachmentIcon />
                                            </span></a>
                                            <span className="attachmet-label">
                                               <a href={attachment.url} target="_blank">{attachment.name}</a>
                                              <br />
                                              Added{" "} {this.getFormattedDate(attachment.date)}
                                            </span>
                                        </>
                                    )
                                  }

                                  

                                </div>                                
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : null}

                 </div>
                 <div>
                    <h6 className="d-flex">
                      <b>Activity</b>
                      {/* <span className="trello-activity-refresh" onClick={this.filterComments.bind(this, this.state.cardOrginalComments)}>
                        <img src={trelloRefrsh} />
                      </span> */}
                    </h6>

                    {this.state.cardNewComments.map((comment, index) => {
                        return (
                          <>
                           {comment.data.text ?
                            (
                              <div
                              className="card-detail-label-container">                              
                              <div className="card-detail">
                                <div>
                                  <p className="author">
                                    <b>{comment.memberCreator.fullName}</b>
                                    <span className="comment-date" >
                                      {this.getFormattedDate(comment.date)}
                                    </span>
                                  </p>
                                  <p className={comment.type ==='commentattachment'? "comment-atach card-detail-description card card-body" : "card-detail-description card card-body"}>
                                 
                                  <Linkify options={this.state.options}>{comment.data.text}</Linkify>  
                                  </p>                                
                                </div>
                              </div>
                            </div>
                            )
                            :
                            null
                           }                            
                          </>
                        );
                      })}
                 </div>
            </>
          )}
        </div>
       
      </Container>
    );
  }
}
