import React, { Component, Fragment } from 'react';
import './Feeds.css'
import SideMenu from "../NewSideMenu/NewSideMenu";
import Header from '../header/Header';
import {urls} from "../../services/UrlList";
import {Container, Row, Col, Card, Input, Button, Label} from 'reactstrap';
import FeedImg from '../../images/feeds-content-banner-img.png'
import {isMobile} from 'react-device-detect';
import FeedComments from "../Feeds/FeedComments/FeedComments";
import FeedWish from "./FeedWish/FeedWish";
import FeedNewJoining from "./FeedNewJoining/FeedNewJoining";
import FeedWorkAnniversary from "./FeedWorkAnniversary/FeedWorkAnniversary";
// import FeedAppreciation from "./FeedAppreciation/FeedAppreciation";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import FeedGeneral from "./Feed.General/Feed.General"
import AddNewFeed from "./AddNewFeed/AddNewFeed";
import axios from 'axios';
import AddNewFeedForm from './AddNewFeed/AddNewFeedForm/AddNewFeedForm'
import AddNewFeedAnchor from './AddNewFeed.Anchor/AddNewFeed.Anchor'
import { Scrollbars } from 'react-custom-scrollbars';
import DesignersIcon from "../../images/leave_design_icon.svg"
import ProjectManagerIcon from "../../images/leave_pm_icon.svg"
import OthersIcon from "../../images/leave_other_icon.svg"



export default class Feeds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      showProgress: true,
      showFeedProgress: true,
      dailyQuoteLoader: true,
      birthdayLoader: true,
      customersLoader: true,
      leaveCalendarLoader: true,
      commentLoader: true,
      sDate: new Date(),
      leaveDesigners:[],
      leavePMs:[],
      leaveQA:[],
      leaveOthers:[],
      wfh:[],
      upcomingBirthdays:[],
      allFeeds:[],
      allFeedsOriginal:[],
      pageNum:0,
      offset:10,
      noMoreFeeds:0,
      kimpPaid:0,
      kimpTrial:0,
      kimpTotal:0,
      kimpPaused:0,
      comment:'',
      wishcomment:'',
      anniversarycomment:'',
      joiningcomment:'',
      appreciationcomment:'',
      generalComment: '',
      feedComment:[],
      likeCount:0,
      showForm:false,
      feedpostedmereview:[],
      feedpostedothersreview:[],
      showMenu:true,
    };
    
    this.feedRef = React.createRef();
    this.feedSidebarRef = React.createRef();
  }

  isBottom(el){
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  componentDidMount = () => {
    document.title = "HUB - News Feed";
    //window.addEventListener('scroll', this.scrollHandle);
    this.checkUserPrivilege();
    this.getDailyQuote();
    this.getKimpCustomers();
    this.renderContentDevice();
    const _this = this;
    setTimeout(function() {
      _this.getLeaveCalendar();
      _this.getUpcomingBirthdays();
      _this.getMyfeedsReview();
      _this.getOthersfeedsReview();
      _this.getAllfeeds();

    }, 100);
  };


  

  scrollHandle = e => {
    let feed = this.feedRef.current    
    let allFeedsOffset = document.getElementById('all_feeds').offsetHeight;
    const _this = this;
    let sidebar = this.feedSidebarRef.current;
    let sidebarClass = sidebar.classList;
    if( window.scrollY >= 250 ){
      // console.log('set');
      document.getElementById('st1').classList.add("showtop");
    } else {
      document.getElementById('st1').classList.remove('showtop')
    }
  }

  scrollUp = () =>
  {
    window.scrollTo(0, 0)
  }

  componentWillUnmount() {
    //window.removeEventListener('scroll', this.scrollHandle)
  }

  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        dailyQuote:[],
      });
    }
  };

  // renderContentDevice = () => {
  //   if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
  //       this.setState({isMobileView:1});
  //   } 
  //   if (isMobile) {
  //     this.setState({showMenu:false});
  //   }  
  // };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:true});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }
  // toggleSidebar = () => {
  //    this.setState({showMenu: !this.state.showMenu});
  // }

  // hideMenu = () =>{
  //    this.setState({showMenu: !this.state.showMenu});
  // }

  getDailyQuote = () => {
    fetch(urls.getDailyQuote, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {           
        this.setState({
          dailyQuote: response[0].quote,
          dailyQuoteLoader: false
        });
      })
      .catch(err => {
        console.error("Get Daily Quotes: ", err)
      });
  };

  getUpcomingBirthdays = () => {
    fetch(urls.getUpcomingBirthdays, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {           
        this.setState({
          upcomingBirthdays: response[0].birthdays,
          birthdayLoader: false,
        });
      })
      .catch(error => {        
        this.setState({         
          showProgress: false
        });
      });

  }

  getKimpCustomers = () => {    
    fetch(urls.getKimpCustomers, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {        
        this.setState({
          kimpPaid: response[0].paid,
          kimpTrial:response[0].trial,
          kimpTotal:response[0].total,
          kimpPaused:response[0].paused,
          customersLoader: false,
        });
      })
      .catch(error => {          
        this.setState({         
          customersLoader: false
        });
      });
  }

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  getLeaveCalendar = () => {
    this.setState({        
      progressBar:true,
    })
    let opts ={
      selected_date: this.state.sDate,
    };   
    fetch(urls.getLeaveCalendar,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {                  
        this.setState({
          leaveDesigners:response[0].leave_designers, 
          leavePMs:response[0].leave_pm,
          leaveQA:response[0].leave_qa,
          leaveOthers:response[0].leave_others,
          wfh:response[0].leave_wfh,            
          leaveCalendarLoader:false,
        })
      })
      .catch((error) =>
      {
        this.setState({
          leaveCalendarLoader:false,
        })
      })
  };

  getAllfeeds = () => {
    if(this.state.noMoreFeeds === 0)
    {
      this.setState({
        progressBar:true,
      })      
      fetch(urls.getAllfeeds+this.state.pageNum+'/'+this.state.offset, {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          }
        })
        .then(response => response.json())
        .then(response => {
          if( this.state.allFeeds.length > 5 ) {
            this.setState({
              allFeeds:this.state.allFeeds.concat(response[0].feeds),
            });
          } else {
            this.setState({
              allFeeds:response[0].feeds,
            });
          }
          if(response[0].feeds.length>=10) {         
            this.setState({pageNum: this.state.pageNum+1});
          }else {
            this.setState({noMoreFeeds: 1});
          }
          this.setState({
            showFeedProgress:false,
          });
        })
        .catch((error) =>
        {       
          this.setState({        
            progressBar:false,
          });
        })  
    }           
  };



  getMyfeedsReview = () => {
         
      fetch(urls.getFeedReview+this.state.myId, {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          }
        })
        .then(response => response.json())
        .then(response => {
          console.log(response);
          this.setState({feedpostedmereview:response[0].feeds});
        })
        .catch((error) =>
        {       
          this.setState({        
            progressBar:false,
          });
        })           
  };

  getOthersfeedsReview = () => {
         
      fetch(urls.getOthersFeedReview+this.state.myId, {
          headers:{
            'Access-Control-Allow-Origin':'*',
            'Content-Type':'application/json'
          }
        })
        .then(response => response.json())
        .then(response => {
          console.log(response);
          this.setState({feedpostedothersreview:response[0].feeds});
        })
        .catch((error) =>
        {       
          this.setState({        
            progressBar:false,
          });
        })           
  };



  updateComment = (name, val) => {
    this.setState({[name]: val});
  }

  postFeedComment = (id, comment, commentType ) => {
    let opts = {
      post_id:id,
      commenter_id:this.state.myId,
      comment: comment
    };
    fetch(urls.postFeedComment,{
      headers:{
        'Access-Control-Allow-Origin':'*',
        'Content-Type':'application/json'
      },
      method:'post',
      body:JSON.stringify(opts)
    })
    .then(response => response.json())
    .then(response => { 
      this.setState({[commentType]:''});
      this.updateComments(id);
    })
    .catch(error => {
      this.setState({[commentType]:''});
    });
  }

  getFeedComment = (id) => {
    fetch(urls.getFeedComment+id, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(response => {           
        this.setState({
          feedComment: response[0].comments,
        });
        this.updateComments(id);
      })
      .catch(error => {        
        this.setState({         
          showProgress: false
        });
      });
  };

updateComments=(id)=> {
  let newAllFeeds = [...this.state.allFeeds];
  fetch(urls.getFeedComment+id, {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json"
    }
  })
    .then(response => response.json())
    .then(response => {
      
      newAllFeeds.map( feed => {
        if( feed.id === id ) {
          feed.comments = response[0].comments;
          return;
        }
      } );

      this.setState({allFeeds: newAllFeeds})
    })
    .catch(error => {
      this.setState({
        showProgress: false
      });
    });
};



likeDislikeFeed = (id) => {
  let opts = {
    feed_id:id,
    user_id:this.state.myId 
  };    
  fetch(urls.likeDislikeFeed,{
    headers:{
      'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json'
    },
    method:'post',
    body:JSON.stringify(opts)
     })
    .then(response => response.json())
    .then(response =>
    {      
      this.setState({likeCount: response[0].count});
      this.updateLikes(id);       
    })
    .catch(error =>
    {       
    });    
};

  updateLikes=(id)=> { 
    let temp=[];
    temp = this.state.allFeeds;
    let list=[];
    temp.forEach(p1=>
    {
      if(p1.id === id ) 
      {      
        p1.like_count = this.state.likeCount;
      }
      list.push(p1);
    })
    this.setState({allFeeds:list});
  };

  postNewFeed = async ( formData ) => { 
    this.setState({showFeedProgress:true});
  console.log(formData);   
    await axios.post(urls.addnewfeed, formData, { 
      headers: {
        'Access-Control-Allow-Origin':'*',
        'content-type': 'application/json'
      } }).then((res) => {
          this.getAllfeeds();
          this.getMyfeedsReview();
          this.getOthersfeedsReview();
          this.setState({showForm:false, showFeedProgress:false});
        }).catch((e)=>{
          this.setState({showForm:false, showFeedProgress:false});
      })
  }

  showForm = () => {
    this.setState({showForm:true});
  }

  onCancelForm = () => {
    this.setState({showForm:false});
  }

  approveFeed = (id, type) => {
    
    this.setState({showFeedProgress:true});

    let opts ={
      user_id: this.state.myId,
      feed_id: id,
      action:type
    };   
    fetch(urls.approveFeed,
      {
        headers:{
          'Access-Control-Allow-Origin':'*',
          'Content-Type':'application/json'
        },
        method:'post',
        body:JSON.stringify(opts)
      })
      .then(response => response.json())
      .then(response =>
      {                  
        this.getMyfeedsReview();
        this.getOthersfeedsReview();
        this.getAllfeeds();
        this.setState({showFeedProgress:false});
      })
      .catch((error) =>
      {
        this.setState({
          leaveCalendarLoader:false,
        })
      })

  }

  // toggleSidebar = () =>
  // {
  //    this.setState({showMenu: !this.state.showMenu});
  // }

  // hideMenu = () =>
  // {
  //    this.setState({showMenu: !this.state.showMenu});
  // }

 
  render() {
    return (
      <div className="fullwidth_mobilemenu mobileflex">
     <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')} >
      <div ref={this.feedRef}>
        <Row>
        <div className="theme_header">
        <Header {...this.props}  
            ncount={this.state.openNCount}
            classname="login-portal-page" 
            logout={this.signOut.bind(this)} 
            onmenuaction={this.toggleSidebar.bind(this)}
            quickactionblock={true}
            />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="feeds" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
       <div className="themeContentBody">
          <Row>
            <Col xs={12} sm={12} md={7} lg={7}>
            <Scrollbars style={{ height: '100vh' }} className="xhide">
              <section id="all_feeds">
                
                <LoadingWrapper isLoading={this.state.showFeedProgress}>
                {this.state.myPrivileges.includes('user_can_post_feed')? 
                (
                  <section 
                    id="addNewFeedWrapper"
                    className={this.props.showForm} >
                    {
                      this.state.showForm
                        ? <AddNewFeedForm 
                            display={this.state.showForm}
                            onCancelForm={this.onCancelForm.bind(this)}
                            postNewFeed={this.postNewFeed}
                            myid={this.state.myId}
                          />
                        : (
                          <AddNewFeedAnchor showForm={this.showForm.bind(this)} />
                        )
                    }
                  </section>
                ):
                null
                }

                {this.state.feedpostedothersreview && this.state.feedpostedothersreview.length >0 && this.state.myPrivileges.includes('user_can_approve_feed')?
                (
                    <Fragment>
                      {this.state.feedpostedothersreview.map((feed, index) => {
                          return (
                            <Fragment>
                              <div className="waiting_for_approve_wrapper">
                                <FeedGeneral 
                                  likeAction={this.likeDislikeFeed.bind(this)}
                                  feedData={feed}
                                  keyIndex={index}
                                  updateComment={this.updateComment.bind(this)}
                                  postFeedComment={this.postFeedComment.bind(this)}
                                  commentValue={this.state.wishComment} 
                                   iscomment='0'  />
                                  <div className="feed_appr_wrapper d-flex">
                                    <h6 className="feed-waiting-for-review">Waiting for Approval</h6>
                                    <div className="feed_appr_btns d-flex">
                                      <button className="btn text-left feed_approve text-upper" onClick={this.approveFeed.bind(this, feed.id, 1)} >Approve</button>
                                      <button className="btn text-left feed_reject text-upper" onClick={this.approveFeed.bind(this, feed.id, 2)} >Reject</button>
                                    </div>
                                  </div>
                              </div>
                            </Fragment>
                          )
                        })
                      }
                      </Fragment>
                  ): null
                }

                {this.state.feedpostedmereview && this.state.feedpostedmereview.length > 0 ?
                  (
                      <Fragment>
                        {this.state.feedpostedmereview.map((feed, index) => {
                            return (
                              <>                             
                              <div className="waiting_for_review_wrapper">
                                <FeedGeneral 
                                  wfapp={true}
                                  likeAction={this.likeDislikeFeed.bind(this)}
                                  feedData={feed}
                                  keyIndex={index}
                                  updateComment={this.updateComment.bind(this)}
                                  postFeedComment={this.postFeedComment.bind(this)}
                                  commentValue={this.state.generalComment}
                                  hideComment={true} 
                                   iscomment='0' />
                                <div className="feed_appr_wrapper d-flex">
                                  <h6 className="feed-waiting-for-review">Waiting for Review</h6>
                                   <div className="feed_appr_btns d-flex">
                                      {this.state.myPrivileges.includes('user_can_approve_feed')?
                                      (<button className="btn text-left feed_approve text-upper" onClick={this.approveFeed.bind(this, feed.id, 1)} >Approve</button>)
                                      :
                                      (<button className="btn text-left  text-upper" ></button>)
                                      }                                    
                                      <button className="btn text-left feed_reject text-upper" onClick={this.approveFeed.bind(this, feed.id, 2)} >Delete</button>
                                    </div>
                                </div>
                              </div>
                            </>
                            )
                          })
                        }
                        </Fragment>
                    ): null
                }  
                                                
                       
                {this.state.allFeeds && this.state.allFeeds.length>0?
                (
                    <Fragment>
                      {this.state.allFeeds.map((feed, index)=> {
                        return(
                          <Fragment>
                            {feed.category_id === 1 ?  // Birthday Wishes
                              (                                
                                <FeedWish 
                                  likeAction={this.likeDislikeFeed.bind(this)}
                                  feedData={feed}
                                  keyIndex={index}
                                  updateComment={this.updateComment.bind(this)}
                                  postFeedComment={this.postFeedComment.bind(this)}
                                  commentValue={this.state.wishComment} />
                              ):(
                                <Fragment>
                                  {feed.category_id === 2 ? // Work Anniversary
                                    ( 
                                      <FeedWorkAnniversary 
                                        likeAction={this.likeDislikeFeed.bind(this)}
                                        feedData={feed}
                                        keyIndex={index}
                                        updateComment={this.updateComment.bind(this)}
                                        postFeedComment={this.postFeedComment.bind(this)}
                                        commentValue={this.state.anniversarycomment} />
                                    ):(
                                      <Fragment>
                                        {feed.category_id === 3 ? // New Joining 
                                          (
                                            <FeedNewJoining
                                              likeAction={this.likeDislikeFeed.bind(this)}
                                              feedData={feed}
                                              keyIndex={index}
                                              updateComment={this.updateComment.bind(this)}
                                              postFeedComment={this.postFeedComment.bind(this)}
                                              commentValue={this.state.anniversarycomment} />
                                          ):(
                                            <Fragment>
                                              {feed.category_id === 4 ? // Appreciation
                                                (
                                                  <Card className="feed-item feed-item-appriciation feed-content-banner" key={index}>
                                                    <p className="text-center feed-post-date">{feed.feed_posted_on}</p>
                                                    <h4 className="feed-details-title text-caps text-underline text-center">Welcome Aboard! Person One</h4>
                                                    <p className=""><em>Lorem ipsum dolor</em>, sit amet consectetur adipisicing elit. Pariatur, delectus. Iste explicabo cum, maiores quas quo magni ullam enim, temporibus impedit cupiditate illum atque eos. Optio officiis non iure nisi.</p>
                                                    <img className="feed-details-banner" src={FeedImg} width="661" height="280" alt="Feed-banner-img" />
                                                  </Card>
                                                ):( 
                                                  <Fragment> 
                                                  {feed.category_id === 5 ? // General
                                                    (
                                                      <>
                                                      <FeedGeneral 
                                                        likeAction={this.likeDislikeFeed.bind(this)}
                                                        feedData={feed}
                                                        keyIndex={index}
                                                        updateComment={this.updateComment.bind(this)}
                                                        postFeedComment={this.postFeedComment.bind(this)}
                                                        commentValue={this.state.anniversarycomment}
                                                         iscomment='1'
                                                      />                                                    
                                                      </>
                                                    ):null
                                                  }
                                                  </Fragment>
                                                )
                                              }
                                            </Fragment>
                                          )
                                        }
                                      </Fragment>
                                    )
                                  }
                                </Fragment>
                              )
                            }
                          </Fragment>
                        )
                      })}
                    </Fragment>
                ):
                null
                }
                {
                  this.state.noMoreFeeds === 0 && <button className="loadmore_post btn btn-green" onClick={this.getAllfeeds}>Load More Feeds</button>
                }
                
              </LoadingWrapper>
              
              </section>
              </Scrollbars>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
            <section id="feed-sidebar" ref={this.feedSidebarRef}>
                  {/* <Card className="feed-sidebar-quote feed-sidebar-title">
                    <LoadingWrapper isLoading={this.state.dailyQuoteLoader}>
                    {this.state.dailyQuote && this.state.dailyQuote.length>0?
                      ( 
                        <>
                          <h5 className="feed-sidebar-title text-center">Quote of The Day </h5>
                          <p className="message text-center">{this.state.dailyQuote[0].quote_text}</p>
                          <p className="author text-center">{this.state.dailyQuote[0].quote_author}</p>
                        </>
                      ): null
                    }
                    </LoadingWrapper>
                  </Card> */}
                {this.state.myPrivileges.includes('kimp_subscription_count_access') &&
                (
                <>
                <Row>
                  <Col xs={12} sm={6} lg={6}>
                  <LoadingWrapper isLoading={this.state.customersLoader}>
                    <div className="small_card_box">
                      <h6>{this.state.kimpTotal}</h6>
                      <h4>Active Subscriptions</h4>
                    </div>
                  </LoadingWrapper>
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                  <LoadingWrapper isLoading={this.state.customersLoader}>
                    <div className="small_card_box">
                      <h6>{this.state.kimpPaid}</h6>
                      <h4>Paid Subscriptions</h4>
                    </div>
                  </LoadingWrapper>
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                  <LoadingWrapper isLoading={this.state.customersLoader}>
                    <div className="small_card_box">
                      <h6>{this.state.kimpTrial}</h6>
                      <h4>On Trial Subscriptions</h4>
                    </div>
                  </LoadingWrapper>
                  </Col>
                  <Col xs={12} sm={6} lg={6}>
                  <LoadingWrapper isLoading={this.state.customersLoader}>
                    <div className="small_card_box">
                     <h6>{this.state.kimpPaused}</h6>
                      <h4>Paused Subscriptions</h4>
                    </div>
                  </LoadingWrapper>
                  </Col>
                </Row>
                 
                  </>
                )}

                {((this.state.leaveDesigners && this.state.leaveDesigners.length>0) || (this.state.leavePMs && this.state.leavePMs.length>0) 
                || (this.state.leaveQA && this.state.leaveQA.length>0) || (this.state.leaveOthers && this.state.leaveOthers.length>0)) ?
                  (
                    <Card className="feed-sidebar-up-levs-cont">
                      <LoadingWrapper isLoading={this.state.leaveCalendarLoader}>
                      <Scrollbars style={{ height: 170 }} className="xhide">
                          <h5 className="feed-sidebar-title text-center">Today's Leave</h5>
                          <div className="cont d-flex mb-3">
                            {this.state.leaveDesigners.length>0?
                            (<div className="feed-sidebar-up-levs feed-sidebar-up-levs-desi">
                                <div className="feed-sidebar-title box_titile"><img src={DesignersIcon} alt="Designers Icon"/> Designer</div>
                                {this.state.leaveDesigners.map((leave, index)=>
                                  {
                                    return(
                                      <p>{leave.name} - {leave.session === 3? 'F' : (leave.session === 1? "FH" : "SH")} </p>
                                    )
                                  })}                       
                              </div>)
                              :null
                            }
                            {this.state.leavePMs.length>0?
                              (
                                <div className="feed-sidebar-up-levs feed-sidebar-up-levs-pm">
                                  <div className="feed-sidebar-title box_titile"><img src={ProjectManagerIcon} alt="Project Manager Icon"/>Project Manager</div>
                                  {this.state.leavePMs.map((leave, index)=>
                                  {
                                    return(
                                      <p>{leave.name} - {leave.session === 3? 'F' : (leave.session === 1? "FH" : "SH")} </p>
                                    )
                                  })} 
                                </div>
                              ):
                              null
                            }                      
                            {this.state.leaveQA.length>0 || this.state.leaveOthers.length>0?
                            (<div className="feed-sidebar-up-levs feed-sidebar-up-levs-ots">
                                <div className="feed-sidebar-title box_titile"><img src={OthersIcon} alt="Others Icon"/> Others</div>
                                {this.state.leaveQA.map((leave, index)=>
                                  {
                                    return(
                                      <p>{leave.name} - {leave.session === 3? 'F' : (leave.session === 1? "FH" : "SH")} </p>
                                    )
                                  })}
                                {this.state.leaveOthers.map((leave, index)=>
                                  {
                                    return(
                                      <p>{leave.name} - {leave.session === 3? 'F' : (leave.session === 1? "FH" : "SH")} </p>
                                    )
                                  })}
                              </div>
                            )
                            : null
                            }
        
                            {this.state.wfh.length>0?
                            (<div className="feed-sidebar-up-levs feed-sidebar-up-levs-wfh">
                                <h6 className="feed-sidebar-title">WFH</h6>
                                {this.state.wfh.map((leave, index)=>
                                  {
                                    return(
                                      <p>{leave.name} - {leave.session === 3? 'F' : (leave.session === 1? "FH" : "SH")} </p>
                                    )
                                  })}
                              </div>
                            )
                            : (
                              <div className="feed-sidebar-paid-subs"></div>
                              )
                            }
                          </div>
                          </Scrollbars>
                      </LoadingWrapper>
                    </Card>
                  )
                  : null
                }
                {this.state.upcomingBirthdays && this.state.upcomingBirthdays.length>0 ?
                (
                  <Card className="feed-sidebar-up-bds-cont bday_card">
                     <Scrollbars style={{ height: 170 }} className="xhide">
                    <LoadingWrapper isLoading={this.state.birthdayLoader}>
                      <h6 className="feed-sidebar-title feed-sidebar-up-bds-title text-center mb-3">Upcoming Birthdays</h6>
                      <div className="bds-person-cont d-flex">
                          {this.state.upcomingBirthdays.map((birthday, index) =>
                            {
                              return(
                                <div className="bds-person" key={index}>
                                  <div className="d-flex">
                                    <div className="user_pic">
                                      <img src={urls.storageUrl+"storage/profile/image/"+birthday.id+".jpg"}  alt="AvatarImg" />
                                      </div>
                                    <div><p>{birthday.name}</p>
                                    <p className="bds-person-date text-center">{birthday.date_of_birth}</p>
                                    </div>
                                    
                                  </div>
                                  
                                </div>
                              )
                            })}
                      </div>
                    </LoadingWrapper>
                    </Scrollbars>
                  </Card>
                ):
                null
                }
              </section>
            </Col>
          </Row>
       </div>
        </Row>
      </div>
      </Container>
      
      </div>
    )
  }
}
