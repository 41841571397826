import React, { useState, useEffect, Fragment } from 'react';
import Header from '../header/Header';
import './clientprofile.css';
import SideMenu from "../NewSideMenu/NewSideMenu";
import {BrowserRouter as Router, Link} from 'react-router-dom';
import { Container, Row, Col, Card, FormGroup, Input, Button, Modal, CardTitle, ModalBody, Label, InputGroup } from 'reactstrap';
import MaterialIcon from "material-icons-react";
import { useHistory } from "react-router";

export default function ClientprofileList(props) {
    const [opencount, setOpenCount] = useState(0);
    const [myId, setmyId] = useState(0);
    const [showMenu, setshowMenu] = useState(true);
    const history = useHistory();
    const [myPrivileges, setMyPrivileges] = useState([]);
    const [modal, setModal] = React.useState(false);
    const toggle = () => setModal(!modal);


    const checkUserPrivilege = () => {
      if (localStorage.getItem("user") !== null) {
        var login = JSON.parse(localStorage.getItem("user"));
        setmyId(login.uid);
        setMyPrivileges(login.privileges);
        if (!login.privileges.includes('i_am_hradmin')) {
          history.push('/home');
              }
      }else
      {
        history.push('/home');
      }
    }
    
  
    function toggleSidebar() {
      setshowMenu(!showMenu);
    }
  
    function hideMenu() {
      setshowMenu(!showMenu);
    }
    function goToHome() {
      history.push({ pathname: "/home" });
    }
  
    return (
      <div className="fullwidth_mobilemenu client_list">
        <Container fluid className='bc_module_container'>
          <Row>
            <div className="theme_header">
              <Header
                ncount={opencount}
                {...props} className="tick-timer-header"
                quickactionblock={false} />
            </div>
            {/* <div className="SideNavbar">
                  <SideMenu activeitem="admin" onClick={toggleSidebar} />
            </div>  */}
           
            <div className="themeContentBody themefullwidthContentBoday">
              <Row>
                <Col xs={12} md={12}>
                  <Card className="themeCard log_timer_card">
                    <div className="with_filter">
                      <div className="d-flex align-items-center frist_div client_filter">
                        <CardTitle tag="h1" className="themeCard_title">Client Profiles</CardTitle> 
                         <div className="search_client">
                             <Input type="text" placeholder="Search client Name Here" className="search_input" />
                             <Button className="search_btn"><MaterialIcon icon="search"/> </Button>
                         </div>
                      </div>
                      
                    </div>
                    <div className="client_list_wrapper">
                        <div className="client_list_box">
                            <Button onClick={toggle} title="edit" className="edit_btn_client"><MaterialIcon icon="draw"/></Button>
                            <div className="d-flex flex-column">
                                <div className="client_name">
                                    <div className="profile_pic">FA</div>
                                    <h3>Faheeem</h3>
                                    <h6><MaterialIcon icon="location_on" /> Croatia </h6>
                                </div>
                                <div className="board_status">
                                    <span className="client_status multi">Multi Boards</span> 
                                    <span className="client_status hold">Hold</span> 
                                </div>
                                <div className="packages">
                                    <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                    <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                    <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                </div>
                                <Link to="/client-details"><Button className="viewpro_btn">View Profile</Button></Link>
                            </div>
                        </div>
                        <div className="client_list_box">
                            <Button onClick={toggle} title="edit" className="edit_btn_client"><MaterialIcon icon="draw"/></Button>
                            <div className="d-flex flex-column">
                                <div className="client_name">
                                    <div className="profile_pic">TI</div>
                                    <h3>Tibin</h3>
                                    <h6><MaterialIcon icon="location_on" /> China </h6>
                                </div>
                                <div className="board_status">
                                    <span className="client_status active">Active</span> 
                                </div>
                                <div className="packages">
                                    <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                    <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                    <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                </div>
                                <Link to="/client-details"><Button className="viewpro_btn">View Profile</Button></Link>
                            </div>
                        </div>
                        <div className="client_list_box">
                            <Button onClick={toggle} title="edit" className="edit_btn_client"><MaterialIcon icon="draw"/></Button>
                            <div className="d-flex flex-column">
                                <div className="client_name">
                                    <div className="profile_pic">SE</div>
                                    <h3>Senthu</h3>
                                    <h6><MaterialIcon icon="location_on" /> Canada </h6>
                                </div>
                                <div className="board_status">
                                    <span className="client_status trial">Trial</span> 
                                </div>
                                <div className="packages">
                                    <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                    <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                    <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                </div>
                                <Link to="/client-details"><Button className="viewpro_btn">View Profile</Button></Link>
                            </div>
                        </div>
                        <div className="client_list_box">
                            <Button onClick={toggle} title="edit" className="edit_btn_client"><MaterialIcon icon="draw"/></Button>
                            <div className="d-flex flex-column">
                                <div className="client_name">
                                    <div className="profile_pic">PR</div>
                                    <h3>Prem</h3>
                                    <h6><MaterialIcon icon="location_on" /> Brazil </h6>
                                </div>
                                <div className="board_status">
                                    <span className="client_status cancelled">Cancelled</span> 
                                </div>
                                <div className="packages">
                                    <div title="Graphics"><MaterialIcon icon="palette" /><span>2</span></div>
                                    <div title="Video"><MaterialIcon icon="play_circle" /><span>2</span></div>
                                    <div title="Graphics + VIDEO"><MaterialIcon icon="burst_mode" /><span>2</span></div>
                                </div>
                                <Link to="/client-details"><Button className="viewpro_btn">View Profile</Button></Link>
                            </div>
                        </div>
                     
                        
                        
                    </div>
                  </Card>
                
                </Col>
                
              </Row>
            </div>
          </Row>
  
        </Container>

             <Modal isOpen={modal}
                toggle={toggle}
                modalTransition={{ timeout: 100 }}>
                <ModalBody>
                    <div className="modal_headercli">Edit Client Profile</div>
                     <div className="from_editclient">
                         <InputGroup>
                          <Input id="clientname" type="text" className="input_box" />
                          <Label for="clientname" >Client Name</Label>
                        </InputGroup>
                        <div className="form-group choose_all_package">
                         <Label>country</Label>
                         <Input type="select" id="country" name="country">
                   
    <option>select country</option>
    <option value="AF">Afghanistan</option>
    <option value="AX">Aland Islands</option>
    <option value="AL">Albania</option>
    <option value="DZ">Algeria</option>
    <option value="AS">American Samoa</option>
    <option value="AD">Andorra</option>
    <option value="AO">Angola</option>
    <option value="AI">Anguilla</option>
    <option value="AQ">Antarctica</option>
    <option value="AG">Antigua and Barbuda</option>
    <option value="AR">Argentina</option>
    <option value="AM">Armenia</option>
    <option value="AW">Aruba</option>
    <option value="AU">Australia</option>
    <option value="AT">Austria</option>
    <option value="AZ">Azerbaijan</option>
    <option value="BS">Bahamas</option>
    <option value="BH">Bahrain</option>
    <option value="BD">Bangladesh</option>
    <option value="BB">Barbados</option>
    <option value="BY">Belarus</option>
    <option value="BE">Belgium</option>
    <option value="BZ">Belize</option>
    <option value="BJ">Benin</option>
    <option value="BM">Bermuda</option>
    <option value="BT">Bhutan</option>
    <option value="BO">Bolivia</option>
    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
    <option value="BA">Bosnia and Herzegovina</option>
    <option value="BW">Botswana</option>
    <option value="BV">Bouvet Island</option>
    <option value="BR">Brazil</option>
    <option value="IO">British Indian Ocean Territory</option>
    <option value="BN">Brunei Darussalam</option>
    <option value="BG">Bulgaria</option>
    <option value="BF">Burkina Faso</option>
    <option value="BI">Burundi</option>
    <option value="KH">Cambodia</option>
    <option value="CM">Cameroon</option>
    <option value="CA">Canada</option>
    <option value="CV">Cape Verde</option>
    <option value="KY">Cayman Islands</option>
    <option value="CF">Central African Republic</option>
    <option value="TD">Chad</option>
    <option value="CL">Chile</option>
    <option value="CN">China</option>
    <option value="CX">Christmas Island</option>
    <option value="CC">Cocos (Keeling) Islands</option>
    <option value="CO">Colombia</option>
    <option value="KM">Comoros</option>
    <option value="CG">Congo</option>
    <option value="CD">Congo, Democratic Republic of the Congo</option>
    <option value="CK">Cook Islands</option>
    <option value="CR">Costa Rica</option>
    <option value="CI">Cote D'Ivoire</option>
    <option value="HR">Croatia</option>
    <option value="CU">Cuba</option>
    <option value="CW">Curacao</option>
    <option value="CY">Cyprus</option>
    <option value="CZ">Czech Republic</option>
    <option value="DK">Denmark</option>
    <option value="DJ">Djibouti</option>
    <option value="DM">Dominica</option>
    <option value="DO">Dominican Republic</option>
    <option value="EC">Ecuador</option>
    <option value="EG">Egypt</option>
    <option value="SV">El Salvador</option>
    <option value="GQ">Equatorial Guinea</option>
    <option value="ER">Eritrea</option>
    <option value="EE">Estonia</option>
    <option value="ET">Ethiopia</option>
    <option value="FK">Falkland Islands (Malvinas)</option>
    <option value="FO">Faroe Islands</option>
    <option value="FJ">Fiji</option>
    <option value="FI">Finland</option>
    <option value="FR">France</option>
    <option value="GF">French Guiana</option>
    <option value="PF">French Polynesia</option>
    <option value="TF">French Southern Territories</option>
    <option value="GA">Gabon</option>
    <option value="GM">Gambia</option>
    <option value="GE">Georgia</option>
    <option value="DE">Germany</option>
    <option value="GH">Ghana</option>
    <option value="GI">Gibraltar</option>
    <option value="GR">Greece</option>
    <option value="GL">Greenland</option>
    <option value="GD">Grenada</option>
    <option value="GP">Guadeloupe</option>
    <option value="GU">Guam</option>
    <option value="GT">Guatemala</option>
    <option value="GG">Guernsey</option>
    <option value="GN">Guinea</option>
    <option value="GW">Guinea-Bissau</option>
    <option value="GY">Guyana</option>
    <option value="HT">Haiti</option>
    <option value="HM">Heard Island and Mcdonald Islands</option>
    <option value="VA">Holy See (Vatican City State)</option>
    <option value="HN">Honduras</option>
    <option value="HK">Hong Kong</option>
    <option value="HU">Hungary</option>
    <option value="IS">Iceland</option>
    <option value="IN">India</option>
    <option value="ID">Indonesia</option>
    <option value="IR">Iran, Islamic Republic of</option>
    <option value="IQ">Iraq</option>
    <option value="IE">Ireland</option>
    <option value="IM">Isle of Man</option>
    <option value="IL">Israel</option>
    <option value="IT">Italy</option>
    <option value="JM">Jamaica</option>
    <option value="JP">Japan</option>
    <option value="JE">Jersey</option>
    <option value="JO">Jordan</option>
    <option value="KZ">Kazakhstan</option>
    <option value="KE">Kenya</option>
    <option value="KI">Kiribati</option>
    <option value="KP">Korea, Democratic People's Republic of</option>
    <option value="KR">Korea, Republic of</option>
    <option value="XK">Kosovo</option>
    <option value="KW">Kuwait</option>
    <option value="KG">Kyrgyzstan</option>
    <option value="LA">Lao People's Democratic Republic</option>
    <option value="LV">Latvia</option>
    <option value="LB">Lebanon</option>
    <option value="LS">Lesotho</option>
    <option value="LR">Liberia</option>
    <option value="LY">Libyan Arab Jamahiriya</option>
    <option value="LI">Liechtenstein</option>
    <option value="LT">Lithuania</option>
    <option value="LU">Luxembourg</option>
    <option value="MO">Macao</option>
    <option value="MK">Macedonia, the Former Yugoslav Republic of</option>
    <option value="MG">Madagascar</option>
    <option value="MW">Malawi</option>
    <option value="MY">Malaysia</option>
    <option value="MV">Maldives</option>
    <option value="ML">Mali</option>
    <option value="MT">Malta</option>
    <option value="MH">Marshall Islands</option>
    <option value="MQ">Martinique</option>
    <option value="MR">Mauritania</option>
    <option value="MU">Mauritius</option>
    <option value="YT">Mayotte</option>
    <option value="MX">Mexico</option>
    <option value="FM">Micronesia, Federated States of</option>
    <option value="MD">Moldova, Republic of</option>
    <option value="MC">Monaco</option>
    <option value="MN">Mongolia</option>
    <option value="ME">Montenegro</option>
    <option value="MS">Montserrat</option>
    <option value="MA">Morocco</option>
    <option value="MZ">Mozambique</option>
    <option value="MM">Myanmar</option>
    <option value="NA">Namibia</option>
    <option value="NR">Nauru</option>
    <option value="NP">Nepal</option>
    <option value="NL">Netherlands</option>
    <option value="AN">Netherlands Antilles</option>
    <option value="NC">New Caledonia</option>
    <option value="NZ">New Zealand</option>
    <option value="NI">Nicaragua</option>
    <option value="NE">Niger</option>
    <option value="NG">Nigeria</option>
    <option value="NU">Niue</option>
    <option value="NF">Norfolk Island</option>
    <option value="MP">Northern Mariana Islands</option>
    <option value="NO">Norway</option>
    <option value="OM">Oman</option>
    <option value="PK">Pakistan</option>
    <option value="PW">Palau</option>
    <option value="PS">Palestinian Territory, Occupied</option>
    <option value="PA">Panama</option>
    <option value="PG">Papua New Guinea</option>
    <option value="PY">Paraguay</option>
    <option value="PE">Peru</option>
    <option value="PH">Philippines</option>
    <option value="PN">Pitcairn</option>
    <option value="PL">Poland</option>
    <option value="PT">Portugal</option>
    <option value="PR">Puerto Rico</option>
    <option value="QA">Qatar</option>
    <option value="RE">Reunion</option>
    <option value="RO">Romania</option>
    <option value="RU">Russian Federation</option>
    <option value="RW">Rwanda</option>
    <option value="BL">Saint Barthelemy</option>
    <option value="SH">Saint Helena</option>
    <option value="KN">Saint Kitts and Nevis</option>
    <option value="LC">Saint Lucia</option>
    <option value="MF">Saint Martin</option>
    <option value="PM">Saint Pierre and Miquelon</option>
    <option value="VC">Saint Vincent and the Grenadines</option>
    <option value="WS">Samoa</option>
    <option value="SM">San Marino</option>
    <option value="ST">Sao Tome and Principe</option>
    <option value="SA">Saudi Arabia</option>
    <option value="SN">Senegal</option>
    <option value="RS">Serbia</option>
    <option value="CS">Serbia and Montenegro</option>
    <option value="SC">Seychelles</option>
    <option value="SL">Sierra Leone</option>
    <option value="SG">Singapore</option>
    <option value="SX">Sint Maarten</option>
    <option value="SK">Slovakia</option>
    <option value="SI">Slovenia</option>
    <option value="SB">Solomon Islands</option>
    <option value="SO">Somalia</option>
    <option value="ZA">South Africa</option>
    <option value="GS">South Georgia and the South Sandwich Islands</option>
    <option value="SS">South Sudan</option>
    <option value="ES">Spain</option>
    <option value="LK">Sri Lanka</option>
    <option value="SD">Sudan</option>
    <option value="SR">Suriname</option>
    <option value="SJ">Svalbard and Jan Mayen</option>
    <option value="SZ">Swaziland</option>
    <option value="SE">Sweden</option>
    <option value="CH">Switzerland</option>
    <option value="SY">Syrian Arab Republic</option>
    <option value="TW">Taiwan, Province of China</option>
    <option value="TJ">Tajikistan</option>
    <option value="TZ">Tanzania, United Republic of</option>
    <option value="TH">Thailand</option>
    <option value="TL">Timor-Leste</option>
    <option value="TG">Togo</option>
    <option value="TK">Tokelau</option>
    <option value="TO">Tonga</option>
    <option value="TT">Trinidad and Tobago</option>
    <option value="TN">Tunisia</option>
    <option value="TR">Turkey</option>
    <option value="TM">Turkmenistan</option>
    <option value="TC">Turks and Caicos Islands</option>
    <option value="TV">Tuvalu</option>
    <option value="UG">Uganda</option>
    <option value="UA">Ukraine</option>
    <option value="AE">United Arab Emirates</option>
    <option value="GB">United Kingdom</option>
    <option value="US">United States</option>
    <option value="UM">United States Minor Outlying Islands</option>
    <option value="UY">Uruguay</option>
    <option value="UZ">Uzbekistan</option>
    <option value="VU">Vanuatu</option>
    <option value="VE">Venezuela</option>
    <option value="VN">Viet Nam</option>
    <option value="VG">Virgin Islands, British</option>
    <option value="VI">Virgin Islands, U.s.</option>
    <option value="WF">Wallis and Futuna</option>
    <option value="EH">Western Sahara</option>
    <option value="YE">Yemen</option>
    <option value="ZM">Zambia</option>
    <option value="ZW">Zimbabwe</option>

                         </Input>
                        </div>
                        <div className="form-group choose_all_package">
                         <Label>Status</Label>
                         <Input type="select" name="graphicsPack">
                             <option>Select</option>
                             <option>Trial</option>
                             <option>Active</option>
                             <option>Hold</option>
                             <option>Paused</option>
                             <option>Cancelled</option>
                        </Input>
                        </div>
                        <div className="form-group choose_all_package">
                         <Label>Graphics</Label>
                         <Input type="select" name="graphicsPack">
                             <option>Select</option>
                             <option>2</option>
                             <option>3</option>
                             <option>4</option>
                             <option>5</option>
                             <option>6</option>
                             <option>7</option>
                             <option>8</option>
                             <option>9</option>
                             <option>10</option>
                         </Input>
                        </div>
                        <div className="form-group choose_all_package">
                         <Label>Video</Label>
                         <Input type="select" name="videoPack">
                             <option>Select</option>
                             <option>2</option>
                             <option>3</option>
                             <option>4</option>
                             <option>5</option>
                             <option>6</option>
                             <option>7</option>
                             <option>8</option>
                             <option>9</option>
                             <option>10</option>
                         </Input>
                        </div>
                        <div className="form-group choose_all_package">
                         <Label>Graphics + VIDEO</Label>
                         <Input type="select" name="graphicsvideoPack">
                             <option>Select</option>
                             <option>2</option>
                             <option>3</option>
                             <option>4</option>
                             <option>5</option>
                             <option>6</option>
                             <option>7</option>
                             <option>8</option>
                             <option>9</option>
                             <option>10</option>
                         </Input>
                        </div>
                        <div className="model_footercli">
                        <Button type="submit" className="viewpro_btn mr-2">Update</Button>
                        <Button type="submit" className="close_btn"  onClick={toggle}> <MaterialIcon icon="close" /> </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
      </div>
    )
  
  }
