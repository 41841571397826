import React,{useState, useEffect} from "react";
import {urls} from "../../services/UrlList";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../svgicon/AttachmentIcon";
import {Input} from 'reactstrap';

export default function TicketLightboxPop(props)
{
    const [attachments, setAttachments] = useState(props.attachments);
     useEffect(() => {
        setAttachments(attachments);
    },[attachments]);
    
  

    return(
        <div class={`attachment-items d-flex ${((props.listtype && props.listtype == 2) || (props.attachmenttexts && props.attachmenttexts.length>0)) && 'split-instructions' }`}>
            <SimpleReactLightbox className="test">
                <SRLWrapper className="test">
                {(attachments || []).map((attachitem, index) => (
                <div className="attch-items" key={index}>
             <div className="d-flex flex-nowrap">
                {attachitem.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || attachitem.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg'
                || attachitem.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || attachitem.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
            
                (
                <div className="attach_img_close">
                <a className="bg-medium-attachment"  href={attachitem} target="_blank">
                    <img src={attachitem} alt={attachitem}   />
                 </a>
                {props.deleteitem &&  props.deleteitem === 'yes' &&
                (
                    <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem, props.arrayname)}></span>
                )}
                </div>
                ):
                (
                <div className="attach_img_close">
                    <a href={attachitem} target="_blank" className="attachment-bg bg-small attach_other" >
                        <span className="attachment-type">
                            <AttachmentIcon />
                            {attachitem.split('.').pop()}
                        </span>
                    </a>
                   {props.deleteitem &&  props.deleteitem === 'yes' &&
                    (
                        <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem, props.arrayname)}></span>
                    )}
                </div>
                )}
                <div className="attach_file_detail">
                    <span>
                        <h6>File Name</h6>
                        <p>{attachitem.split('/').pop()} </p>
                    </span>
                </div>
                </div>
                </div>
                ))}
                </SRLWrapper>
            </SimpleReactLightbox>
    </div>
    )
}
