import React from "react";

export default function LiveActivityTickMark(props) {
  const classes = "activity-indicator " + props.classname;
  return (
    <div className={classes}>
       <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path
            style={{ fillRule:"evenodd", fill: '#dbdbdb' }}
            stroke="null"
            id="svg_1"
            d="m14.956345,0c-8.247665,0 -14.956345,6.69339 -14.956345,14.922259c0,8.227886 6.708679,14.921276 14.956345,14.921276c8.24668,0 14.95536,-6.69339 14.95536,-14.921276c0,-8.228869 -6.708679,-14.922259 -14.95536,-14.922259zm7.579655,11.75988l-8.100861,8.082399c-0.243361,0.242806 -0.561602,0.364701 -0.880828,0.364701c-0.319226,0 -0.638453,-0.121895 -0.881813,-0.364701l-4.05043,-4.041199c-0.486722,-0.486595 -0.486722,-1.272029 0,-1.758625c0.487707,-0.485612 1.274935,-0.485612 1.762642,0l3.169602,3.162379l7.220033,-7.203578c0.486722,-0.485612 1.274935,-0.485612 1.761656,0c0.487707,0.486595 0.487707,1.272029 0,1.758625z"            
          />
        </g>
      </svg>
    </div>
  );
}
