import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router';
import { BrowserRouter as Router,Route,Link} from "react-router-dom";
import './sidemenu.css';
import HomeIcon from '../svgicon/HomeIcon';
import TimerIcon from '../svgicon/TimerIcon';
import FeedsIcon from '../svgicon/FeedsIcon';
import TaskIcon from '../svgicon/TaskIcon';
import ApprovalIcon from '../svgicon/ApprovalIcon';
import LiveActivityIcon from "../svgicon/LiveActivityIcon";
import LeaveIcon from "../svgicon/LeaveIcon";
import SwitchIcon from "../svgicon/SwitchIcon";


export default class SideMenu extends Component {
  constructor( props ) {
    super( props );
    this.state = {
      myPrivileges:[], 
      isLoggedOut:false,
      isAdmin:false,
    };
  }

  componentDidMount =() =>
  {    
    this.checkUserPrivilege();    
  };
  
  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {      
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, myId:login.uid, showExtBreak:login.showext });
    }            
  };

  hideMenu = () =>
  {
    this.props.hidemenu();
  }

  render() {    
    return (
      <nav id="side-menu" className={this.props.showmenu? 'mobile-active': ''} >
        <div className="side-menu-wrapper">
          <HomeIcon classname={this.props.activeitem === 'home'? "active" : '' } title="Home" to="/home"  onclick={this.hideMenu.bind(this)} />
          <TaskIcon classname={this.props.activeitem === 'tasks'? "active" : '' } title="Tasks" to="/mytasks" />
          { this.state.myPrivileges.includes('access_monthly_overview') ?
            (<div id="sidemenu-reports" className="">
              <TimerIcon title="Reports"  classname={this.props.activeitem === 'report'? "active" : '' } />
                <ul id="reports-submenu" className="sidemenu-submenu-item">
                  <li>
                    <Link to="/reports/log-timer">Daily Logs</Link>
                  </li>
                  <li>
                    <Link to="/reports/projectwise">Project Report</Link>
                  </li>
                  <li>
                    <Link to="/reports/monthly-overview">Monthly Overview</Link>
                  </li>
                  <li>
                    <Link to="/countdown">Countdown</Link>
                  </li>
                </ul>
            </div>)
          :
          (
            <div id="sidemenu-reports" className="">
              {/* <TimerIcon title="Reports" to="/reports/log-timer" classname={this.props.activeitem === 'report'? "active" : '' } /> */}
              <TimerIcon title="Reports"  classname={this.props.activeitem === 'report'? "active" : '' } />
                <ul id="reports-submenu" className="sidemenu-submenu-item">
                  <li>
                    <Link to="/reports/log-timer">Daily Logs</Link>
                  </li>                 
                  <li>
                    <Link to="/countdown">Countdown</Link>
                  </li>
                </ul>                              
            </div>
          )
          }
          <FeedsIcon classname={this.props.activeitem === 'feeds'? "active" : '' } title="Feed" to="/news-feed"  onclick={this.hideMenu.bind(this)} />
          { (this.state.myPrivileges.includes('access_monthly_overview') && this.state.myPrivileges.includes('approve_user_requests') ) ?
            (<div id="sidemenu-reports" className="">
              <ApprovalIcon title="Approve"  classname={this.props.activeitem === 'approval'? "active" : '' } /> 
                <ul id="reports-submenu" className="sidemenu-submenu-item">
                  <li>
                    <Link to="/approve-request">Activity</Link>
                  </li>
                  <li>
                    <Link to="/admin-notifications">Notifications</Link>
                  </li>
                </ul>
            </div>)
          :
          (
            <Fragment>
              {this.state.myPrivileges.includes('approve_user_requests')?
              (<div id="sidemenu-reports" className="">
              <ApprovalIcon title="Approve" to="/approve-request" classname={this.props.activeitem === 'approval'? "active" : '' } />              
              </div>)
              :null
              }             
            </Fragment>
          )
          }
          { this.state.myPrivileges.includes('live_data') ? 
          <LiveActivityIcon classname={this.props.activeitem === 'livedata'? "active" : '' } title="Updates" to="/liveactivity" />
          : null
          }
          <LeaveIcon classname={this.props.activeitem === 'leave'? "active" : '' } title="My Leave" to="/leave/balance" />
          {localStorage.getItem("superadmin") !== null?
            (<SwitchIcon classname={this.props.activeitem === 'switch'? "active" : '' } title="Switch User" to="/switch-user" />)
            :
            null
          }          
        </div>
      </nav>
    )
  }
}
