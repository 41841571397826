import React, { Component, Fragment } from "react";
import "./CarousalCard.css";
import ApprovalAvatarHead from "../ApprovalAvatarHead/ApprovalAvatarHead";
import ApprovedMsg from ".././ApprovedMsg/ApprovedMsg";

export default class CarousalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approved: true
    };
  }
  render() {
    return (
      <div className="caro-box appr-pending">
        <ApprovalAvatarHead />
        {this.props.children}
        <button className="appr-btn-approve btn btn-green txt-upper">
          approve
        </button>
        <ApprovedMsg />
        {/* <div className="appr-approved-msg d-flex">
          <div className="left">
            <p className="title">Approved By</p>
            <p className="appr-approver">Tibin Mathew</p>
          </div>
          <p className="right ml-auto">29th Jan, 02:47 PM</p>
        </div> */}
      </div>
    );
  }
}
