import React, { Component, Fragment } from 'react';
import './listcard.css';
import { Row, Col, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';


export default class ListCard extends Component {
  constructor( props ) {
    super( props );         
    this.state = {              
      myPrivileges:[],
      showRate:false,    
    };
   // this.keyPress = this._handleKeyDown.bind(this);
  }

  componentDidMount = () => {
    this.checkUserPrivilege();     
  };

  componentWillUnmount()
  {
    
  }

  checkUserPrivilege = () =>
  {
    var login = JSON.parse( localStorage.getItem("user") );
    this.setState({myPrivileges:login.privileges });

    if(login.privileges.includes('access_all_reports'))
    {
      this.setState({showPeople:true, showPeopleType:1});
    }else if(login.privileges.includes('access_team_reports'))
    {
      this.setState({showPeople:true, showPeopleType:2});
    }else
    {
      this.setState({showPeople:false, showPeopleType:3});
    }
  };

  showAdminCommentsBlock = (entry_id, user_id, project_id, task_id) =>
  {
    
    this.props.showAdminCommentsBlock(entry_id, user_id, project_id, task_id);
  }

  render() {
    const { commentStatus, cardName } = this.props;
    const commentClass = commentStatus ? 'has-comment' : 'no-comment';
    return (
      <Row>
        <Col sm="12">
          <div className={ "listcard-single " + cardName } >
            <Card className="list-card d-block">
              <div className="card-head d-flex">
                <div className="name">
                  <p className="brand-date">
                    <span className="brand">{this.props.projects.name !='-' ? this.props.projects.name : this.props.projects.id}</span>
                  </p>                
                </div>
                <p className="total-rate text-center">{this.props.showRate === true? this.props.projects.rate:null }</p>
              </div>
              <div className="card-body">
                {this.props.projects.tasks.map((tasks, tkey) =>
                {
                  return(
                    <div className="single-project d-flex" key={tkey}   onClick={this.state.myPrivileges.includes('show_admin_comments')?  this.showAdminCommentsBlock.bind(this,tasks.entry_id, this.props.userkey, this.props.projectkey, tkey)  : ''}>
                      <p className="project-name">{tasks.task_name}</p>
                      <p className="time-entered">{tasks.total_hours}</p>
                      {(this.state.myPrivileges.includes('show_admin_comments') && (tasks.comment_resolved ==0 || tasks.comment_resolved ==1))  ?
                        (<p className={ "admin-comment-checkbox text-right" + commentClass }>
                        {tasks.comment_resolved === 1?
                          (<FontAwesomeIcon className="comment-resolved" icon={faCheckSquare} />)
                          :
                          (<FontAwesomeIcon className="comment-unresolved" icon={faCheckSquare} />)
                        }
                      </p>)
                      :
                      null
                      }
                    </div>
                  )
                }
                )}
              </div>
            </Card>
          </div>
        </Col>
      </Row>
    )
  }
}