import React, { Component, Fragment } from "react";
import "./WfhCard.css";
import ApprovalAvatarHead from "../../ApprovalAvatarHead/ApprovalAvatarHead";
import ApprovalTimeRow from "../../ApprovalTimeRow/ApprovalTimeRow";
import { Button } from "reactstrap";
import ApprovedMsg from "../../ApprovedMsg/ApprovedMsg";
import ApprovalCommentForm from "../../ApprovalLeave/ApprovalCommentForm/ApprovalCommentForm";
import ApprovalLeaveComment from "../../ApprovalLeave/ApprovalLeaveComment/ApprovalLeaveComment";

export default class WfhCard extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      myPrivileges:[], 
      myId:'',             
      showPopup:false,
      ownerType:'',
      actionType: 2,
      comment:'',
    };
  }

  onManagerialApproval = () =>
  {
    this.setState({showPopup: !this.state.showPopup, ownerType:'Reporting Officer'});
  }

  onAdminApproval = () =>
  {
    this.setState({showPopup: !this.state.showPopup, ownerType:'Admin'});
  }

  onManagerialApprovalAllow = (type) =>
  { 
    
    this.props.onmanagerialapproval(this.props.myid, this.props.item.leave_id, type,this.state.comment);
  }

  onAdminApprovalAllow = (type) =>
  {    
    this.props.onadminapproval(this.props.myid, this.props.item.leave_id, type, this.state.comment);
  }

  onManagerialApprovalComment = (comment) =>
  { 
    this.setState({showPopup: !this.state.showPopup});
    this.props.onmanagerialapprovalcomment(this.props.myid, this.props.item.leave_id, comment);
  }

  onAdminApprovalComment = ( comment) =>
  {
    this.setState({showPopup: !this.state.showPopup});
    this.props.onadminapprovalcomment(this.props.myid, this.props.item.leave_id, comment);
  }
  
  render() {
    let commentFormClass = this.state.showPopup && 'show-form';

    let classname = [
      "caro-box boq",
      (this.props.item.status === 1 || this.props.item.status === 3)  ? "approved" : "appr-pending"
    ]
      .join()
      .replace(",", " ");  

      var ccstring = JSON.stringify(this.props.item.apply_cc_array);
      ccstring.replace (/"/g,"");        
    return (
      <div className={classname}>
        <ApprovalAvatarHead name={this.props.item.name} comment={this.props.item.leave_reason} />
        <ApprovalTimeRow  label="From Date" value={this.props.item.from_date}/>
        <ApprovalTimeRow label="To Date" value={this.props.item.to_date} />        
        <ApprovalTimeRow label={this.props.item.status=== 1? "Applied For" : 'Applying For'}  value={this.props.item.applied_for} />
        <ApprovalTimeRow label="Applied On" value={this.props.item.applied_on} />

        {(this.props.myid == this.props.item.apply_to && this.props.item.manager_approval_status === 1 && (this.props.item.status !=3 && this.props.item.status !=1))?
        (
          <Fragment>
              <ApprovalLeaveComment manager={this.props.item.manager} reporter='1' reportingofficers={this.props.item.apply_cc_persons} label="Reporting officer approval" value={this.props.item.manager_approval_status} onaction='0' comment={this.props.item.manager_comments} />
              <ApprovalLeaveComment approver={this.props.item.approver} reporter='0' adminperson={this.props.item.apply_to_person} label="Admin approval" value={this.props.item.admin_approval_status} comment={this.props.item.admin_comments} onaction='1' onclick={this.onAdminApproval} />
          </Fragment>
        ):
        (
          <Fragment>
            {(ccstring.includes(this.props.myid)  && this.props.item.manager_approval_status !== 1 && (this.props.item.status !=3 && this.props.item.status !=1)) ?
            (
              <Fragment>
                <ApprovalLeaveComment manager={this.props.item.manager} reporter='1' reportingofficers={this.props.item.apply_cc_persons} label="Reporting officer approval" value={this.props.item.manager_approval_status} comment={this.props.item.manager_comments} onaction='1' onclick={this.onManagerialApproval} />
                <ApprovalLeaveComment approver={this.props.item.approver} reporter='0' adminperson={this.props.item.apply_to_person} label="Admin approval" value={this.props.item.admin_approval_status} onaction='0' comment={this.props.item.admin_comments}  />
              </Fragment>
            )
            :
            (
              <Fragment>
                <ApprovalLeaveComment manager={this.props.item.manager} reporter='1' reportingofficers={this.props.item.apply_cc_persons} label="Reporting officer approval" onaction='0' value={this.props.item.manager_approval_status} comment={this.props.item.manager_comments}  />
                <ApprovalLeaveComment approver={this.props.item.approver} reporter='0' adminperson={this.props.item.apply_to_person} label="Admin approval"  onaction='0' value={this.props.item.admin_approval_status} comment={this.props.item.admin_comments}  />
              </Fragment>
            )
            }
          </Fragment>
        )        
        }        
        {this.props.item.status === 3?
        (
          <ApprovedMsg label="Rejected By" approvedby={this.props.item.approver!= null ? this.props.item.approver : this.props.item.manager}  approvedon={this.props.item.rejected_date} />
        )
        :
        (
          <Fragment>
        {this.props.approved ? (
          <ApprovedMsg label="Approved By"  approvedby={this.props.item.approver}  approvedon={this.props.item.admin_approval_date} />
        ) : (
          <Fragment>
            {(this.props.myid == this.props.item.apply_to && this.props.item.manager_approval_status === 1)?
              (                           
                <div className="leave-appr-buttons d-flex">
                  <Button className="appr-btn-approve btn-green txt-upper" onClick={this.onAdminApprovalAllow.bind(this, 1)} >
                    Approve
                  </Button>
                  <Button className="appr-btn-reject btn-gray-outline txt-upper" onClick={this.onAdminApprovalAllow.bind(this, 2)}>
                    Reject
                  </Button>
                </div>
              )
              :
              (
                <Fragment>
                  {(ccstring.includes(this.props.myid)  && this.props.item.manager_approval_status !== 1) ?
                    (                     
                      <div className="leave-appr-buttons d-flex">
                        <Button className="appr-btn-approve btn-green txt-upper" onClick={this.onManagerialApprovalAllow.bind(this, 1)} >
                          Approve
                        </Button>
                        <Button className="appr-btn-reject btn-gray-outline txt-upper" onClick={this.onManagerialApprovalAllow.bind(this, 2)}>
                          Reject
                        </Button>
                      </div>
                    ):
                    (
                      <Fragment>
                         {(this.props.myid == this.props.item.apply_to)?
                            (
                              <Button className="appr-btn-approve btn-green txt-upper">
                               Waiting Manager Review
                              </Button>
                            ):
                           (
                            <Button className="appr-btn-approve btn-green txt-upper">
                               Waiting Admin Approval
                            </Button>
                           )
                         }
                      </Fragment>                      
                    )
                  }
                </Fragment>                
              )
            }
          </Fragment>         
        )}
        </Fragment>
        )
        }
        
        {/* {this.state.showPopup? <ApprovalCommentForm /> : null } */}
        <ApprovalCommentForm classname={commentFormClass} show={this.onAdminApproval} action={this.state.actionType} ownertype={this.state.ownerType}
         onreviewreporter={this.onManagerialApprovalComment}
         onreviewadmin={this.onAdminApprovalComment} />
      </div>
    );
  }
}
