import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import {urls} from "../../services/UrlList";
import {
    Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, Tooltip, Breadcrumb, BreadcrumbItem,
    Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
  } from 'reactstrap';
  
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import MaterialIcon from "material-icons-react";
const notifySuccess = () => toast('Task title updated successfully.');
const notifyError = () => toast('Something went wrong, please try again.');

export default class EditTaskTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      taskName: this.props.taskdetails.task_name,
      modal: false,
      openTitleModal:this.props.showTitleModal,
      errors:[]
    };
  }
  
  componentDidMount = () => {
    console.log("hoi");
    document.title = "HUB - Project Management";
  };

  handleChange = ({ target }) => {
    this.setState({ [target.name]: target.value });
  };
  
  updateTask = async(type)=>
  {
    let temp = [];
    if(this.state.taskName == '')
    {
      temp.push('taskName');
      await this.setState({errors: temp});
      return false;
    }
    this.setState({errors: []});
    const data = {
      savetype: type,
      project_id:this.props.projectid,
      project_name:this.props.projectDetails.board_name,
      task_id: this.props.taskid,
      creator: this.state.myId,
      task_name: this.state.taskName,
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateClientTaskTitle, data, {
        headers: headers
      })
      .then((response) => {
       if(response.data.success === 1 || response.data.success === 2)
        {
          notifySuccess();
          this.setState({openTitleModal:false});
          this.props.closeedit(this.state.taskName);
        }
      })
      .catch((error) => {
        notifyError();
        this.props.closeedit(this.state.taskName);
      })
  }

  closePopup = () =>
  {
    this.setState({openTitleModal:false});
    this.props.closeedit();
  }

  render() {
      return (
        <div className="fullwidth_mobilemenu edit_task_wrapper titleeditor">
        <Modal isOpen={this.state.openTitleModal}  className="addtodo_wrapper movetodo_pop copy_list_wrapper titleeditor">
          <ModalHeader >Update Task Title
            <Button className="clase_btn_cp" onClick={this.closePopup}>
                <MaterialIcon icon="close" /> 
            </Button>
          </ModalHeader>
          <ModalBody>
            <div className="form-group">
                    <label>Task Title</label>
                    <Input autoComplete="off" type="text" 
                        value={this.state.taskName}
                        className={this.state.errors.includes('taskName')? 'field-error text_box' : 'text_box'}
                        name="taskName" onChange={this.handleChange} />
                </div>
                <div className="form-group btn_sec">
                    <div className="d-flex title_save_btn">
                        <Button className="sd_btn" onClick={this.updateTask.bind(this, 1)}>Save</Button>
                    </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    )
  }
} 
