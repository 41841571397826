import React, {useState, Component, Fragment} from 'react';
import {Input, Button, Label, Row, Col} from 'reactstrap';
import {urls} from "../../services/UrlList";
import axios from 'axios';
import { useForm } from "react-hook-form";
import MaterialIcon from "material-icons-react";

export default function NewBoard(props)
{
const [boardname, setBoardName] = useState('');
const [boarddescription, setBoardDescription] = useState('');
const [boardtype, setBoardType] = useState(1);
const [boarderrors, setBoardErrors] = useState([]);
const [graphicspack, setGraphicsPack] = useState('');
const [videopack, setVideoPack] = useState('');
const [graphicsvideopack, setGraphicsVideoPack] = useState('');
const [clientstatus, setClientStatus] = useState(0);
const [whitelabel, setWhiteLabel] = useState(0);
const [onboarded360, setOnboarded360] = useState(0);
const { register, handleSubmit } = useForm();
const onSubmit = data => saveNewBoard(data);

function saveNewBoard(mdata)
{
  if(boardname.length <=0 ) { setBoardErrors(boarderrors=> [...boarderrors, 'boardname'])
      return false;
  }else if(boarddescription.length <=0) {
    setBoardErrors([]); setBoardErrors(boarderrors=> [...boarderrors,
          'boarddescription'])
          return false;
  }

  const data = {
  board_name: boardname,
  board_desc: boarddescription,
  user_id: props.userid,
  board_type: boardtype,
  graphicscount: graphicspack,
  videoscount: videopack,
  graphicsvideocount:graphicsvideopack,
  client_status: clientstatus,
  white_label:whitelabel,
  onboard_360: onboarded360
  };

 

  const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-origin':'*',
  }
  axios.post(urls.addNewKBoard, data, {
  headers: headers
  })
  .then((response) => {
  console.log(response);
  props.addnewboard(1);
  })
  .catch((error) => {
  console.log("hi");
  })
}

function toggleDisplay()
{
  props.closepopup();
}

function focusinfo()
{
    console.log('hello');
}

function updateWhiteLabel(e)
{
    if(e.target.checked === true) {
        setWhiteLabel(1);
    }else
    {
        setWhiteLabel(0);
    }
}

function update360Onboard(e)
{
    if(e.target.checked === true) {
        setOnboarded360(1);
    }else
    {
        setOnboarded360(0);
    }
}

  return(
  <>
      {/* <div className="overlay_bc"></div> */}
      <div className="boardadd_form">
          <Button className="clase_btn_cp" onClick={toggleDisplay}>
              <MaterialIcon icon="close" onClick={toggleDisplay} /></Button>
          <form onSubmit={handleSubmit(onSubmit)}>
              <Row>
              <Col xs={12} sm={9}>
                <h1>Create New Board</h1>
              </Col>
              </Row>
              <Row>
                  <Col xs={12} sm={4}>
                  <div className="form-group">
                      <label>Board Name</label>
                      <Input {...register("boardname", { required: true })} value={boardname} onChange={e=>
                      setBoardName(e.target.value)}
                      className={'text_box' + (boarderrors.includes('boardname') ? " error-field" : "")} />
                  </div>
                  <div className="form-group">
                      <label>Board Description</label>
                      <Input {...register("boarddescription", { required: true })} type="textarea"
                          value={boarddescription} onChange={e=> setBoardDescription(e.target.value)}
                      className={'textarea_box' + (boarderrors.includes('boarddescription') ? " error-field" :
                      "")}
                      />
                  </div>
                  </Col>
                  <Col xs={12} sm={4}>
                  <div className="search_drop_grp whitelable_chooser">
                      
                      <Row>
                      
                          <Col xs={12} sm={12}>
                          <div className="form-group choose_all_package">
                              <Label>Graphics</Label>
                              <Input type="select" name="graphicsPack" onChange={e=>
                              setGraphicsPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                  <option key={i+1} value={i+1}>{i+1}</option>
                                )}
                              </Input>
                          </div>
                          </Col>
                          <Col xs={12} sm={12}>
                          <div className="form-group choose_all_package">
                              <Label>Video</Label>
                              <Input type="select"  name="videoPack" onChange={e=>
                              setVideoPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                  <option key={i+1} value={i+1}>{i+1}</option>
                                )}
                              </Input>
                          </div>
                          </Col>
                          <Col xs={12} sm={12}>
                          <div className="form-group choose_all_package">
                              <Label>Graphics + VIDEO</Label>
                              <Input type="select" name="graphicsvideoPack" onChange={e=>
                              setGraphicsVideoPack(e.target.value)}>
                                <option value="">Select</option>
                                {[...Array(20)].map((x, i) =>
                                 <option key={i+1} value={i+1}>{i+1}</option>
                                )}
                              </Input>
                          </div>
                          </Col>
                      </Row>
                  </div>
                  </Col>
                  <Col xs={12} sm={4}>
                     <Label>Board Type</Label>
                  <div className="form-group choose_project">
                <div className="d-flex reminder-options custom_radio ">

                    <div className="d-flex">
                        <Input type="radio" name="projectType" value="1" checked={boardtype=='1'
                            && "checked" } id="1" onChange={e=> setBoardType(e.target.value)} />
                        <Label for="1" className="pro_type_lable">Client Board</Label>
                    </div>
                    <div className="d-flex">
                        <Input type="radio" name="projectType" value="2" id="2" checked={boardtype=='2'
                            && "checked" } onChange={e=> setBoardType(e.target.value)} />
                        <Label for="2" className="pro_type_lable">Internal Board</Label>
                    </div>
                </div>
                </div>

                  <div className="form-group choose_all_package">
                        <Label>Status</Label>
                        <Input type="select" name="clientstatus" onChange={e=>
                            setClientStatus(e.target.value)}>
                            <option value="">Select</option>
                            <option value="1" >Trial</option>
                            <option value="2"  >Active</option>
                            <option value="3"  >Hold</option>
                            <option value="4"  >Paused</option>
                            <option value="5" >Cancelled</option>
                        </Input>
                    </div>
                    <div className="form-group choose_all_package">
                        <Label>Labels</Label>
                        <Input type="text" className="text_box" onFocus={focusinfo} /> 
                    </div>
                  </Col>
                 
                  <Col xs={12} sm={4}>
                    <div className="d-flex flex-itmes white_lab_checkbox">
                    <Input id="white-label" type="checkbox" name="Softwares"
                     value="whitelabel" onChange={e=>
                      updateWhiteLabel(e)} />
                     <Label for="white-label">WhiteLabel</Label>
                   </div>
                   </Col>

                   <Col xs={12} sm={4}>
                     <div className="d-flex flex-itmes white_lab_checkbox onboarded_check">
                      <Input id="on-boarded" type="checkbox" name="onBoarded360" value={onboarded360} 
                      checked={onboarded360 === 1 && 'selected'} onChange={e=>
                        update360Onboard(e)} />
                      <Label for="on-boarded">360 Client</Label>
                     </div>
                   </Col>


                  <Col xs={12} sm={12}>
                  <div className="form-group">
                      <Button type="submit" className="save_btn" onClick={saveNewBoard}>Save</Button>
                  </div>
                  </Col>
                
                  

              </Row>
          </form>
      </div>
  </>
  )
  }