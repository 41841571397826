import React, { useState, useEffect } from 'react';
export default function BreakRunningVoice (props)  {
    //const msg = new SpeechSynthesisUtterance()
    //msg.text = "Hello World"

    const synth = window.speechSynthesis;
    const voices = synth.getVoices();
    const crossmsg = "Your break time has exceeded the daily limit (1hr, 30 minutes)! Please request to end your break!";
    const crossingmsg = "Your break time is almost over! You will exceed the daily limit (1hr, 30 minutes) soon!";
    const utterThis = new SpeechSynthesisUtterance( props.crosslimit === 1 ? crossmsg : crossingmsg);
    utterThis.pitch = 1.5;
    utterThis.rate = 1;
    utterThis.voice = voices[1];
    
    useEffect(() => {
        if(props.speech == 1)
        {
        //window.speechSynthesis.speak(msg);
        synth.speak(utterThis);
        props.beakMessage();
        }
      },[props.speech]);
    return(
        <></>
    )
};