import React, { useState, useEffect, Component, Fragment } from 'react';
import "./momeeting.css"
import { urls } from "../../services/UrlList";
import Select from 'react-select';
import axios from 'axios';
import {
    Container,
    Row,
    Col,
    Card,
    FormGroup,
    Input,
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    Label
} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import { motion } from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';


export default function NewMeeting(props) {

    const [modal, setModal] = useState(props.addmeeting);
    const [selectedOptionhost, setSelectedOptionhost] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [meetingName, setMeetingName] = useState('');
    const [groupedOptions, setGroupedOptions] = useState([]);
    const [teamOptions, setTeamOptions] = useState([]);

    function toggle() {
        setModal(!modal);
        props.closemodal();
    }

    function handleChange(selectedOption) {
        setSelectedOption(selectedOption);
    };

    function handleChangehost(selectedOptionhost) {
        setSelectedOptionhost(selectedOptionhost);
        console.log(selectedOptionhost);
    };

    function handleTextChange({ target }) {
        setMeetingName(target.value);
    };

    useEffect(() => {
        getMomResources();
    }, []);

    function getMomResources() {
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        axios.get(urls.getMomResource, {
            headers: headers
        })
            .then((response) => {
                setGroupedOptions(response.data.groups);
                setTeamOptions(response.data.teams);
            })
            .catch((error) => {

            })
    }

    function addMeeting() {
        const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-origin': '*',
        }
        const data={
                meeting_name: meetingName,
                meeting_teams: selectedOption,
                meeting_hosts: selectedOptionhost
        };

        axios.post(urls.addMeetingTeams, data, {
            headers: headers
        })
        .then((response) => {
            
        })
        .catch((error) => {

        })
    }

    return (
        <Modal isOpen={modal} toggle={toggle} >
            <ModalHeader toggle={toggle}>Create Meeting</ModalHeader>
            <ModalBody>
                <Form>

                    <FormGroup>
                        <Label for="MeetingName">Meeting Name</Label>
                        <Input type="text" name="text" value={meetingName} id="MeetingName"
                            placeholder="example: PL Meeting"
                            onChange={handleTextChange}
                            autoComplete="off" />
                    </FormGroup>
                    <FormGroup>
                        <Label>Choose Teams</Label>
                        <Select
                            closeMenuOnSelect={false}
                            value={selectedOption}
                            onChange={handleChange}
                            options={teamOptions}
                            isMulti
                            className="select_custom_meetiing"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Choose Meeting Host</Label>
                        <Select
                            isMulti
                            closeMenuOnSelect={false}
                            value={selectedOptionhost}
                            onChange={handleChangehost}
                            options={groupedOptions}
                            className="select_custom_meetiing"
                        />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button className="sub_btn_meet" color="primary" onClick={addMeeting}>Create New Meeting</Button>{' '}
                <Button color="can_btn_meet" onClick={toggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    )
}
