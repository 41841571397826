import React, { Component } from 'react'
import './FeedWorkAnniversary.css'
import {Card} from 'reactstrap'
import FeedComments from '../FeedComments/FeedComments'
import LeftLeft from '../assets/feeds-banner-leaf-left.png'
import LeftRight from '../assets/feeds-banner-leaf-right.png'

export default class FeedWorkAnniversary extends Component {
  constructor(props) {
    super(props);
    this.state={
      hideanniversayCom: true
    }
  }
  render() {
    const {feedData, keyIndex, showComment} = this.props;
    console.log()
    return (
      <section id="feed_single" className="feed_work_anniversary_wrapper">
        <Card className="feed-item feed-item-anniversary feed-banner" key={keyIndex}>
          
          <div className="feed-anniversary-cont d-flex">
            <div className="leaf-left feed-banner-leaf">
              <img src={LeftLeft} width="101" height="218" alt="leaf-left" />
            </div>
            <div className="content">
                <p className="text-center feed-post-date mb-auto">{feedData.feed_posted_on}</p>
                <h2 className="text-center">{feedData.feed_heading}</h2>
                <h1 className="text-center text-caps">{feedData.feed_content}</h1>
                <h3 className="text-center text-caps">Work Anniversary</h3>
              </div>
            <div className="leaf-right feed-banner-leaf">
              <img src={LeftRight} width="101" height="218" alt="leaf-left" />
            </div>
          </div>
          <FeedComments 
            isLike={feedData.like_count}
            likeCount={feedData.like_count}
            likeAction={this.props.likeAction}
            commentCount={feedData.comments.length}
            commentAction={ this.props.updateComment }
            commentValue={this.props.commentValue}
            feedId={feedData.id}
            postFeedComment={this.props.postFeedComment}
            allCommentsLengh={feedData.comments.length}
            allComments={feedData.comments}
            catId={feedData.category_id} 
            fName="anniversarycomment"  />
          </Card> 
      </section>
    )
  }
}
