import React, { Component, Fragment } from "react";
import "./CarousalContainer.css";
import ApprovalModules from "../ApprovalModules/ApprovalModules";

export default class CarousalContainer extends Component {
  render() {
    return (
      <section className="caros-wrapper">
        <ApprovalModules classname="break-over-quata" />
        <ApprovalModules classname="break-over-quata" />
        <ApprovalModules classname="break-over-quata" />
        <ApprovalModules classname="break-over-quata" />
      </section>
    );
  }
}
