import React, { useState, useEffect, useRef } from "react";
import {Row, Button, Input, Label, Col, UncontrolledTooltip} from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import MaterialIcon from "material-icons-react";
import axios from 'axios';
import Hashids from 'hashids';
import { urls } from "../../../services/UrlList";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import PeopleTags from "../PeopleTag";
import TextareaAutosize from 'react-autosize-textarea';
import { AccessCheck, AccessCheckBlock } from '../AccessCheck';
import ImageUploader from "quill-image-uploader";
import { findMockups,excludeMockupFromOther } from "./HelperFunctions";

const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );
Quill.register("modules/imageUploader", ImageUploader);

export default function ComposeMessageHubEdit(props) { 
  const [newMessage, setNewMessage] = useState(props.comment.comment);
  const [detailError, setDetailError]=useState(false);
  const [needDesignApproval, setNeedDesignApproval]=useState((props.commentfields && props.commentfields.approval_required) ? true : false);
  const [approverAssginee, setApproverAssginee] =useState(props.commentfields ? props.commentfields.approver : []);
  const [commentApprover, setCommentApprover] = useState(props.comment.task_approver? props.comment.task_approver: '');
 
  const [inspiration, setInspiration] = useState(props.commentfields.inspiration);
  const [resourcelinks, setResourcelinks]=useState(props.commentfields.resourcelinks);
  const [pmnote, setPmnote]=useState(props.commentfields.pmnote);
  const [iPackaged, setiPackaged] = useState((props.commentfields && props.commentfields.packaged ) ? props.commentfields.packaged : '');
  const [iVerified, setiVerified]=useState((props.commentfields && props.commentfields.spell_check ) ? props.commentfields.spell_check : '');
  
  const [commentImagesTextOptions, setCommentImagesTextOptions]=useState(false);
  const [attachmentstext, setAttachmentsText] = useState([]);

  const [packageError, setPackageError]= useState(false);
  const [verificationError, setVerificationError]=useState(false);
  const [saveupdateProgress, setSaveupdateProgress] = useState(false);

  const [mockupFolders, setMockupFolders] = useState([]);
  const [otherFoldersFiltered, setOtherFoldersFiltered] = useState([]);

  const inputElement = useRef();
  const actualId = hashids.decode(props.cardId);
  
  const [atValues, setAtvalues] = useState(props.atValues);
 
  var selectedMentions = [];
  const toolbarOptions = [
    ['bold', 'italic', 'underline'],        
    [{ 'color': [] }, { 'background': [] }],           
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
    ['link','image'],
    ['clean']    
  ];

const modules = React.useMemo(
    () => ({
        toolbar: toolbarOptions,
        mention: {
            allowedChars: /^[A-Za-z\s]*$/,
            mentionDenotationChars: ["@"],
            mentionContainerClass: 'ql-mention-list-container-bottom',
            listItemClass:'ql-mention-list-item',
            mentionListClass:'ql-mention-list',
            minChars: 2,
            source: function (searchTerm, renderList, mentionChar) {
              let values;
        
              if (mentionChar === "@") {
                values = atValues;
              }
        
              if(searchTerm.length >0) {
                const matches = [];
                for (var i = 0; i < values.length; i++)
                  if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                renderList(matches, searchTerm);
              }
            },
            onSelect: function (item, insertItem) {
              selectedMentions.push(item['id']);
              insertItem(item);
            }
        },
        imageUploader: {
          upload: file => {
            return new Promise((resolve, reject) => {
              const data = new FormData();
              data.append('file', file);
              data.append('filename', file.name);
              data.append('filesize', file.size);
              data.append('filetype', file.type);
              data.append('folder_path', 'editor')
              data.append('user_id', 1);
              axios.post(urls.uploadProjectMediaEditor, data, { 
              })
              .then(res => {
                  resolve(res.data.file_name);
              })
              .catch(error => {
                  reject("Upload failed");
                  console.error("Error:", error);
                });
            });
          }
        }
      }),
    []
 )

 useEffect(()=>
  {
    setMockupFolders(findMockups(props.attachingFoldersOthers));
    setOtherFoldersFiltered(excludeMockupFromOther(props.attachingFoldersOthers));
  },[props.attachingFoldersOthers])

  // useEffect(()=>
  // {
  //   if(localStorage.getItem('composedMessageID') && localStorage.getItem('composedMessageID') == props.taskid)
  //       setNewMessage(localStorage.getItem("composedMessage") && JSON.parse(localStorage.getItem("composedMessage")));
  // },[])
  
  const handleChangeEditor = (value) =>  {
    setNewMessage(value);
    //localStorage.setItem("composedMessage", JSON.stringify(value));
    //localStorage.setItem("composedMessageID", props.taskid);
    
  }


  const attachComposerFiles = (file)=>
  {
    let temp = [...props.attachingFiles];
    let index = temp.findIndex(x => x.fileid == file.fileid);
    if(index>=0)
    {
      temp.splice(index, 1);
    }
    props.updateattachfiles(temp);
  }

 const setApprover = (tags) => {
    if (typeof (tags) === 'object' && tags.length > 0) {
      setApproverAssginee(tags);
      setCommentApprover(tags[0].id);
    }else
    {
      setApproverAssginee([]);
      setCommentApprover('');
    }
  }

  const needDesignApprovalClick = () => {
    setNeedDesignApproval(!needDesignApproval);
  }

  const iVerifiedClick = (e) => {
    console.log(e.target.value);
    setiVerified(e.target.value);
    setVerificationError(false);
  }

  const iPackagedClick = (e) => {
    setiPackaged(e.target.value);
    setPackageError(false);
  }
  

  const handClickUpdate = (e) => {
    if(e.target.checked === true)
      setCommentImagesTextOptions(true);
    else
      setCommentImagesTextOptions(false);
  };

  const handleUpdate = ({target}) =>
  {
      let temp = [...attachmentstext];
      temp[target.name]=target.value;
      setAttachmentsText(temp);
      console.log(temp);
      //props.updatefiletext(props.arrayname, temp);
  }



  const createTaskComment = () => {
    
    // this.setState({
    //   comments: false,
    //   verificationError: false,
    //   detailError: false
    // });
    // if (this.state.taskNewComment === '') {
    //   this.setState({ detailError: true });
    //   return false;
    // }
    // if (AccessCheckBlock('i_am_designer') && !this.state.iPackaged && this.state.stringifiedDescription && this.state.stringifiedDescription.Softwares && (this.state.stringifiedDescription.Softwares.includes('Ai') || this.state.stringifiedDescription.Softwares.includes('INDD'))) {
    //   this.setState({ packageError: true });
    //   return false;
    // }
    // if (AccessCheckBlock('i_am_designer') && !this.state.iVerified) {
    //   this.setState({ verificationError: true });
    //   return false;
    // }

    //this.setState({ commentAddingLoader: true, saveupdateProgress: true });
    setSaveupdateProgress(true);
    const data = {
      comment_id: props.editcommentid,
      task_id: props.taskDetails.id,
      comment_by: props.myid,
      comments: newMessage,
      task_name: props.taskDetails.task_name,
      project_id: props.project_id,
      tid_cpt: props.tid_cpt,
      creator: props.myid,
      mname: props.myname,
      comment_fields: {
        inspiration: inspiration,
        resourcelinks: resourcelinks,
        pmnote: pmnote,
        approver: needDesignApproval ? approverAssginee : [],
        approval_required: needDesignApproval ? true : false,
        spell_check: iVerified,
        packaged: iPackaged
      },
      source_files:{
        'output_folders': props.attachingFoldersOutput,
        'source_folders': props.attachingFoldersSource,
        'other_folders': props.attachingFoldersOthers
      },
      task_approval_needed: needDesignApproval ? 1 : 0,
      task_approver: commentApprover,
      comment_attachments: props.attachingFiles ? props.attachingFiles :[],
      fieldDataLinkText:
      {
        commentAttachmentFilesText: commentImagesTextOptions ? '' : '',
        commentAttachmentFilesTextArray: commentImagesTextOptions ? attachmentstext : [],
      },
      mentions: selectedMentions,
      assignees: props.assignees,
      notifiers: props.notifiers,
      project_name: props.boardname
    };

    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.updateTaskComment, data, {
      headers: headers
    })
      .then((response) => {
        setSaveupdateProgress(false);
        selectedMentions = [];
        props.addHubCommentAndClose();
      })
      .catch((error) => {
        setSaveupdateProgress(false);
      })

  }

  const storeCommentFileNames = async (arrayname, item) => {
    // let temp = [...this.state.commentAttachments];
    // await this.setState({ commentAttachments: [] });
    // temp.push(item);
    // await this.setState({ commentAttachments: temp });
  }

  const deleteSourceViewFolder = (foldertype, folderpath) =>
  {
    let attachingFoldersOutput = [...props.attachingFoldersOutput];
    let attachingFoldersSource = [...props.attachingFoldersSource];
    let attachingFoldersOthers = [...props.attachingFoldersOthers];

    if(foldertype === 1)
    {
      var itemkey = attachingFoldersOutput.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersOutput.splice(itemkey, 1);
    }else if(foldertype === 2)
    {
      var itemkey = attachingFoldersSource.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersSource.splice(itemkey, 1);
    }else if(foldertype === 3)
    {
      var itemkey = attachingFoldersOthers.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersOthers.splice(itemkey, 1);
    }
    props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);

  }

  return (
    <div className="d-flex">
    {AccessCheckBlock('add_edit_task_comments') &&
      (
        <LoadingWrapper isLoading={false}>
        <div className="compose_message-container with-hub">
          <ReactQuill value={newMessage}
              onChange={handleChangeEditor}
              className={detailError ? 'field-error' : ''}
              placeholder="Add Comments Here....."
              theme="snow"
              modules={modules}
              id="new-comment"
              ref={inputElement}
              bounds={'.app'}
             
            />

            <div className="source-view-folders d-flex">
                {mockupFolders?.length>0 &&(
                      <div className="other-folders">
                        <span className="folder-type-name">Mockups</span>
                        <div className="d-flex folder-items">
                          {mockupFolders?.map((outputfolder, index) =>
                          {
                            return(
                              <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                                <span className="folder_remove_btn" onClick={()=>deleteSourceViewFolder(3,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/> </span>
                                <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                  )}

                  {props.attachingFoldersOutput?.length>0 &&
                  (
                    <div className="output-folders">
                      <span className="folder-type-name">Output Folders</span>
                      <div className="d-flex folder-items">
                        {props.attachingFoldersOutput?.map((outputfolder, index) =>
                        {
                          return(
                            <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                              <span className="folder_remove_btn"  onClick={()=>deleteSourceViewFolder(1,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/></span>
                              <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {props.attachingFoldersSource?.length>0 &&(
                  <div className="source-folders">
                    <span className="folder-type-name">Source  Folders</span>
                    <div className="d-flex folder-items">
                      {props.attachingFoldersSource?.map((outputfolder, index) =>
                      {
                        return(
                           <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                              <span className="folder_remove_btn"  onClick={()=>deleteSourceViewFolder(2,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/></span>
                              <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                           </div>
                        )
                      })}
                    </div>
                  </div>
                  )}
                  {otherFoldersFiltered?.length>0 &&(
                  <div className="other-folders">
                    <span className="folder-type-name">Other Folders</span>
                    <div className="d-flex folder-items">
                      {otherFoldersFiltered?.map((outputfolder, index) =>
                      {
                        return(
                          <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                            <span className="folder_remove_btn" onClick={()=>deleteSourceViewFolder(3,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/> </span>
                            <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  )}
            </div>

            <div className="comment-fields-wrap d-flex comment-fields-wrap-hub">
              {AccessCheckBlock('i_am_designer') &&
              (
                <Row>
                  
                    <Col sm={12}>
                      <span className="area_label">Inspiration Links</span>
                      <TextareaAutosize
                        value={inspiration}
                        style={{ minHeight: 45, padding:10, }} 
                        placeholder="Inspiration - Add inspiration links only seperated by comma. Do not add images."
                        onChange={(e) => { setInspiration(e.currentTarget.value); }} />
                    </Col>
                    <Col sm={12}>
                    <span className="area_label">Resource Links</span>
                      <TextareaAutosize
                        value={resourcelinks}
                        style={{ minHeight: 45, padding:10, }} 
                        placeholder="Resource Links - Add resource links only seperated by comma. Do not add images."
                        onChange={(e) => { setResourcelinks(e.currentTarget.value);  }} />
                    </Col>
                    <Col sm={12}>
                    <span className="area_label">Notes</span>
                      <TextareaAutosize
                        value={pmnote}
                        style={{ minHeight: 45, padding:10, }} 
                        placeholder="Do not add images."
                        onChange={(e) => { setPmnote(e.currentTarget.value);}} />
                    </Col>
                    <Col sm="6" className="need_approval_box">
                    <span className="label_font">Need Design Approval</span>
                    <Input type="checkbox" id="need-approval-edit" onClick={needDesignApprovalClick}
                      checked={needDesignApproval && 'checked'} />
                    <Label className="need-approval" for="need-approval-edit">Yes</Label>
                  </Col>
                  <Col sm="6" style={{ margin:'12px 0px' }}>
                    {!needDesignApproval &&
                    (
                    
                        <PeopleTags tagslist={props.availableAssignees}
                            settags={setApprover.bind(this)}
                            tags={approverAssginee}
                            placeholder="Approved By"
                          />
                    
                    )}
                  </Col>
                    {props.stringifiedDescription &&
                      (
                        <Col sm={12} className="confirm-box d-flex flex-column mb-0">
                        <Label className={`spell-check mb-0 ${verificationError && 'verification-required'}`} for="spell-check">I have completed the spell check: </Label>
                        <div className='d-flex verify-options'>
                        <div className="theme-radio">
                            <Input type="radio" name="spell-check-edit" value="true" id="spell-hub-check-yes-edit" checked={iVerified == 'true' && 'checked'}
                              onClick={(e) => {console.log("clicked"); iVerifiedClick(e) }} />
                            <Label for="spell-hub-check-yes-edit">Yes</Label>
                          </div>
                          <div className="theme-radio">
                            <Input type="radio" name="spell-check-edit" value="false" id="spell-hub-check-no-edit" checked={iVerified == 'false' && 'checked'}
                              onClick={(e) => iVerifiedClick(e)} />
                            <Label for="spell-hub-check-no-edit">No</Label>
                          </div>
                          <div className="theme-radio">
                            <Input type="radio" name="spell-check-edit" value="NA" id="spell-hub-check-na-edit" checked={iVerified == 'NA' && 'checked'}
                              onClick={(e) => iVerifiedClick(e)} />
                            <Label for="spell-hub-check-na-edit">Not Applicable</Label>
                          </div>
                        </div>
                      </Col>
                      )}
                      {(props.stringifiedDescription && props.stringifiedDescription.Softwares && (props.stringifiedDescription.Softwares.includes('Ai') || props.stringifiedDescription.Softwares.includes('INDD'))) &&
                      (
                      <Col sm={12} className="confirm-box flex-column d-flex">
                            <Label className={`package-correct ${packageError && 'verification-required'}`} >I packaged files correctly: </Label>
                            <div className='d-flex verify-options'>
                            <div className="theme-radio">
                                <Input type="radio" name="package-correct2" value="true" id="package-yes-edit" checked={iPackaged === 'true' && 'checked'}
                                  onClick={(e) => iPackagedClick(e)} />
                                <Label for="package-yes-edit">Yes</Label>
                              </div>
                              <div className="theme-radio">
                                <Input type="radio" name="package-correct2" value="false" id="package-no-edit" checked={iPackaged === 'false' && 'checked'}
                                  onClick={(e) => iPackagedClick(e)} />
                                <Label for="package-no-edit">No</Label>
                              </div>
                              <div className="theme-radio">
                                <Input type="radio" name="package-correct2" value="NA" id="package-na-edit" checked={iPackaged === 'NA' && 'checked'}
                                  onClick={(e) => iPackagedClick(e)} />
                                <Label for="package-na-edit">Not Applicable</Label>
                              </div>
                            </div>
                        </Col>
                      )}
                      
                  </Row>
                  )}
                  <Row>
                  <Col sm={12}>
                  <div className="compose-attachments hub-compose-attachments">
                    {props.attachingFiles?.length>0 &&
                    (
                      <>
                        <h3>Attachments</h3>
                        <div className='reminder-options custom_radio options-items-parent d-flex comment_image_text'>
                          <div className='d-flex options-items'>
                            <Input  id="textInstructionsseparate" 
                            type="checkbox" name="commentImagesTextOptions" value="2" onClick={handClickUpdate}  />
                            <Label for="textInstructionsseparate">Want to add instructions for attachments</Label>
                          </div>
                        </div>
                      </>
                    )}
                    {props.attachingFiles?.map((file, index)=>
                    {
                      return(
                        <div key={index} className="compose-attach-items">
                          {['jpg', 'jpeg', 'png'].includes(file.file_type) ?
                          (
                            <div>
                            <div className="d-flex items">
                              <div>
                                <div className="d-flex">
                                  <span className="items" style={{ width:'auto' }}><img src={file.path} className="compose-attaches" /></span>
                                  <span id={"catttach"+index}  className="compose-file-name"><p>{file.file_name}</p></span>
                                  <UncontrolledTooltip  placement="top"  target={"catttach"+index} style={{maxWidth:'600px',width:'auto'}} >
                                      {file.file_name}
                                    </UncontrolledTooltip >
                                </div>
                               
                              </div>
                              <span onClick={()=>attachComposerFiles(file)} className="unlink-attach"><MaterialIcon icon="remove_circle"/> </span>
                              </div>
                              {commentImagesTextOptions &&
                                (
                                  <div className="attach_img_discription">
                                    <Input className="attachment-notes" type="textarea" name={index} onChange={handleUpdate} value={attachmentstext[index]} />
                                  </div>
                                )}
                            
                          
                          </div>
                          ):
                          (
                            <div>
                            <div className="d-flex items">
                                <div>
                                <div className="d-flex">
                                  <span style={{ width:'auto' }} className="compose-file-ext">{file.file_type}</span>
                                  <span id={"catttach"+index} className="compose-file-name"><p>{file.file_name}</p></span>
                                  <UncontrolledTooltip  placement="top"  target={"catttach"+index} style={{maxWidth:'600px',width:'auto'}} >
                                      {file.file_name}
                                    </UncontrolledTooltip >
                                </div>
                              
                                </div>
                                <span onClick={()=>attachComposerFiles(file)}  className="unlink-attach" ><MaterialIcon icon="remove_circle"/></span>
                                </div>

                                {commentImagesTextOptions &&
                                (
                                  <div className="attach_img_discription">
                                    <Input className="attachment-notes" type="textarea" name={index} onChange={handleUpdate} value={attachmentstext[index]} />
                                  </div>
                                )}
                               
                               
                            </div>
                          )}
                        </div>
                      )
                    })}
                  </div>
                  </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div id="" className="flex-fill equalHM ">
                        {(detailError || verificationError) &&
                          (
                            <div className='required-field-text-error'>Please check the required field data</div>
                          )}
                        <div className="file-wrapper upload-btn-wrapper task_detail_footer">
                          <div className="form-group btn_sec">
                            <Button type="submit" className={`save_btn add_cmd_btn ${saveupdateProgress && 'disable-button'}`}
                              onClick={createTaskComment}>
                                {saveupdateProgress ? 'Updating Comment...': 'Update Comment'}
                              </Button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  </div>
          </div> 
        </LoadingWrapper>
      )}
  </div>
  );
}
