export const AddNotifyCount = (data) =>
{
    return {
        type:'AddNotificationCount',
        payload: data
    };
}

export const AdditionNotifyCount = (data) =>
{
    return {
        type:'AdditionNotificationCount',
        payload: data
    };
}

export const MinusNotifyCount = () =>
{
    return {
        type:'MinusNotificationCount'
    };
}

export const UnreadNotifyCount = (data) =>
{
    return {
        type:'UnreadNotificationCount',
        payload:data
    };
}

export const EmptyNotifyCount = (data) =>
{
    return {
        type:'EmptyNotificationCount',
        payload: data
    };
}


export const AppendNotifyMessage = (data) =>
{   
    console.log(data);
    return {
        type:'AppendNotificationMessage',
        payload:data
    };
}

export const SubstractNotifyMessage = (data) =>
{
    return {
        type:'SubstractNotificationMessage',
        payload:data
    };
}

export const EmptyNotifyMessage = (data) =>
{
    return {
        type:'EmptyNotificationMessage',
        payload:data
    };
}

export const AppendNotifyMessages = (data) =>
{
    return {
        type:'AppendNotificationMessages',
        payload:data
    };
}

export const SubstractNotifyMessages = (data) =>
{
    return {
        type:'SubstractNotificationMessages',
        payload:data
    };
}

export const AppendUnReadNotifyMessages = (data) =>
{
    return {
        type:'AppendUnNotificationMessages',
        payload:data
    };
}

export const UnreadNotifyMessages = (data) =>
{
    return {
        type:'UnreadNotificationMessages',
        payload:data
    };
}



export const AppendReadNotifyMessages = (data) =>
{
    return {
        type:'AppendReadNotificationMessages',
        payload:data
    };
}

export const SubstractReadNotifyMessages = (data) =>
{
    return {
        type:'SubstractReadNotificationMessages',
        payload:data
    };
}
export const ReadNotifyMessages = (data) =>
{
    return {
        type:'ReadNotificationMessages',
        payload:data
    };
}


export const TaskRunningCount = (data) =>
{
    return {
        type:'TaskStarting',
        payload: 2
    };
}

export const TaskEndingCount = () =>
{
    return {
        type:'TaskEnding'
    };
}




export const UpdateProfileImage = (data) =>
{
    return {
        type:'UpdateProfileImage',
        payload: data
    };
}