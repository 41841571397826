import React, { Component } from "react";
import "./CcPersonSingle.css";
import { Input, Label } from "reactstrap";

export default class CcPersonSingle extends Component{
  constructor(props) {
    super(props);
    this.state = {
      checked:false,
      allPeoples: this.props.peoples,
      selectedPeoples:[],
      newCcPeople:[],
      checkedChange:false,
      checkedPeoples:this.props.selectedpeoples,
    };
  }

  componentWillUpdate(prevState) {
    
  }

  onStatusChange =(e) => {
    var selectedpeoples = this.state.selectedPeoples;
    //console.log("selected"+selectedpeoples);
    this.setState({checked: !this.state.checked});
    const _this = this;
    //setTimeout(function() {     
     
      if (e.target.checked) {
       // console.log("checked"+e.target.value);
        selectedpeoples.push(e.target.value);
        this.setState({ newCcPeople: selectedpeoples });
      } else {
        //console.log("unchecked"+e.target.value);
        var array = [...this.state.selectedPeoples]; // make a separate copy of the array
        var index = array.indexOf(e.target.value);
        if (index !== -1) {
         // console.log("removing");
          array.splice(index, 1);
        } 
        var index = array.indexOf(e.target.value);
        if (index !== -1) {
          array.splice(index, 1);
        }       
        this.setState({ newCcPeople: array, selectedPeoples:array });
      }
      setTimeout(function() { 
       _this.props.onccchange(_this.state.newCcPeople);       
       }, 100);       
  }


  render() {    
    return (
      <div>
      {this.props.peoples.map((people, index) =>{
        return(
          <div className="d-flex cc-person-single" key={index}>
  


            
            <Input
              id="cc-vedha"
              type="checkbox"
              name="people"
              className="cc-peroson"
              value={people.id}
              onChange={this.onStatusChange.bind(this)}
              defaultChecked={this.state.checkedPeoples.includes(people.id)? 'checked':''}
            />
           <Label htmlFor="cc-vedha">{people.name}</Label>
          </div>
        )
      })}
      </div>
    );
  }
}
