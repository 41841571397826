import React, { useState, useEffect } from "react";
import {
  Input,
  Button,
  Container,
  Row,
  Col,
  Table,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
} from "reactstrap";
import MaterialIcon from "material-icons-react";
import { useGetRevisionShareLink, useRevsionShareRegenerate } from "./useQueryHooks";
import ConfirmAction from "../../admincomment/ConfirmAction";
import LoadingWrapper from "../../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../../services/UrlList";
import ClipboardCopyLink from "./ClipboardCopyLink";

export default function RevisonShareLink(props) {
  
  const [sharePop, setSharePop] = useState(true);
  const [regenerateActive, setRegenerateActive] = useState(false);
  const [copyAction, setCopyAction]= useState(false);
  const toggleSharePop = ()=>
  {
    setSharePop(!sharePop);
    props.closepop();
  }

  const linkcopyAction = (action)=>
  {
    setCopyAction(action);
  }

  const {
    isLoading: revisionShareLoading,
    isError,
    error,
    data: revsionShareData,
  } = useGetRevisionShareLink(props.taskid, props.revsionid);
 
  const generateShareLink = () => {
      const data2 = {
        taskid: props.taskid,
        revsionid: props.revsionid
      }
      revisionShareGenerate(data2);
      setRegenerateActive(false);
  };
  const {isLoading:regenerateLoading, mutate:revisionShareGenerate} = useRevsionShareRegenerate();


  return (
    <Modal
        isOpen={sharePop}
        toggle={toggleSharePop}
        className="addtodo_wrapper public_share_wrapper"
      >
        <ModalHeader value="1" toggle={toggleSharePop}>
          Public Link
          <Button className="clase_btn_cp" value="1" onClick={toggleSharePop}>
            <MaterialIcon icon="close" />{" "}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} md={12}>
              <FormGroup>
               <div className="copy-share-wrap">
                    <LoadingWrapper isLoading={revisionShareLoading || regenerateLoading}>
                      {revsionShareData &&
                      (
                        <>
                          <div className={`copy-share-items ${copyAction && 'link-copied'}`}>
                            <div className={`d-flex justify-content-center`}>{urls.sourcedomain360+"source/r/"+props.taskid+"/"+props.revsionid+"/"+revsionShareData.data.sharedata.unique_sharekey+"/"+revsionShareData.data.sharedata.share_expiry}</div>
                          </div>
                          
                          {regenerateActive ?
                          (
                              <div className="regenerate-link-wrap">
                                <div className="new-lik-generate-msg">
                                  Please note, this action will generate a new unique url. Any links previous shared with anyone will become invalid.
                                </div>
                                <div className="regenerate-buttons">
                                  <Button className="btn btn-regenerate" onClick={()=>setRegenerateActive(false)}>Cancel</Button>
                                  <Button className="btn btn-green btn-copy-link" onClick={()=>generateShareLink()}>Confirm</Button>
                                </div>
                              </div>
                          ):
                          (
                            <>
                            {/* <div className="link-expiry">Link Expiry: {revsionShareData.data.sharedata.expire_on}</div> */}
                            <div className="d-flex share-buttons">
                               {/* <Button className="btn btn-regenerate" onClick={()=>setRegenerateActive(true)}>Regenerate</Button> */}
                               <ClipboardCopyLink
                                copyText={urls.sourcedomain360+"source/r/"+props.taskid+"/"+props.revsionid+"/"+revsionShareData.data.sharedata.unique_sharekey+"/"+revsionShareData.data.sharedata.share_expiry}
                                copyaction={linkcopyAction}
                                />
                            </div>
                            </>
                          )}
                          
                        </>
                      )}
                    </LoadingWrapper>
                  </div>
               
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
  );
}
