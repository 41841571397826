import React, { useState, useEffect } from 'react';
import './notification.css';
import { Button, Popover, PopoverHeader, PopoverBody, Input } from 'reactstrap';
import BellIcon from '../../images/bell-icon.png';
import {useSelector, useDispatch} from 'react-redux';
import Toast from 'react-toast-component';
import { EmptyNotifyMessage, MinusNotifyCount, SubstractNotifyMessages,
  AppendNotifyMessages,AddNotifyCount,  AppendNotifyMessage,AppendReadNotifyMessages } from '../redux/actions';
import ReactHtmlParser from 'react-html-parser';
import { urls } from '../../services/UrlList';
import { useHistory } from "react-router";
import axios from 'axios';
import { requestForToken, onMessageListener } from '../../firebase';
import { Notifications } from '@material-ui/icons'; 
import { Link } from "react-router-dom";
import BellNotifications from './Notifications';


export default function BellNotification (props)  {
  const dispatch = useDispatch();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  //const [notificationcount, setNotificationCount] = useState(props.ncount);
  const notificationcount = useSelector(state => state.NotificationCountReducer);
  //const notificationmessage = useSelector(state => state.NewNotificationMessageReducer);

  const [notificationmessage, setNotificationMessage] = useState({});

  //const notificationmessages = useSelector(state => state.NotificationMessagesReducer.reverse());
  const notificationmessages = useSelector(state => state.NotificationMessagesReducer);
  const [messagestoshow, setMessagesToShow] = useState([]);
  const [isOpen, setToast] = useState(false);
  const history = useHistory();
  const [notification, setNotification] = useState({title: '', body: ''});
  const [deleteitem, setDeleteItem] = useState('');
  useEffect(() => {
    if(notificationcount>0)
    {

      let allmessages = sortByDate(notificationmessages);
      //let allmessages = notificationmessages;
      if(notificationcount>4)
      {
        let items = allmessages.slice(0, 4);
        setMessagesToShow(items);
      }else
      {
        let items = allmessages;
        setMessagesToShow(items);
      }
    }
    if(notificationmessage.oldmessage === 0)
    {
      console.log(notificationmessage, 'testtt')
      setToast(true);
      dispatch(EmptyNotifyMessage(''));
    }
  },[notificationmessages, notificationcount, notificationmessage, isOpen]);


  useEffect(() => {
  if(notificationmessage && notificationmessage.title && notificationmessage.title!='')
    {
      console.log(notificationmessage);
      setToast(true);
    }
  },[notificationmessage]);


  const sortByDate = (messages) => {
    let temp = messages;
    temp.sort(descOrder);
    return temp;
    function ascOrder(a, b) {
      return new Date(a.message_time).getTime() - new Date(b.message_time).getTime();
    }
    function descOrder(a, b) {
      return new Date(b.message_time).getTime() - new Date(a.message_time).getTime();
    }
 }

  const findNotiUrl = (message) =>
  {
    if(message && message.receiver && message.receiver !='')
    {
      const notipart = message.receiver.split("@");
      return (urls.notificationUrl+notipart[0]);
    }
  }

  const markasRead = (item, type) =>
  {
    if(item && item.receiver && item.receiver !='')
    {
      setDeleteItem(item.receiver);
      const urlspart = item.receiver.split("@");
      const data = {
      access_id: urlspart[1],
      user_id: props.myid
      };
      const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
      }
      axios.post(urls.markNotificationasRead, data, {
          headers: headers
        })
        .then((response) => {
            if(response.data.success == 1)
            {
              setTimeout(()=>
              {
                dispatch(MinusNotifyCount());
                dispatch(SubstractNotifyMessages(item));
                dispatch(AppendReadNotifyMessages(item));
                setDeleteItem('');
              }, 300);
            }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  const markNotificationRead = (item) =>
  {
    if(item && item.receiver && item.receiver !='')
    {
      const urlspart = item.receiver.split("@");
      //window.location.href = urls.notificationUrl+urlspart[0];
      window.open(urls.notificationUrl+urlspart[0], "_blank");
    }
  }

  const goToNotifications = () =>
  {
    //window.location.href = urls.notificationUrl+"/notifications";
    history.push('/notifications');
  }

  onMessageListener()
  .then((payload) => {
    let newnoti = payload.notification;
    newnoti.receiver = payload.data['gcm.notification.receiver'];
    setNotificationMessage(newnoti);
    dispatch(AddNotifyCount(payload.data.unread));
    //dispatch(AppendNotifyMessages(payload));  
    //dispatch(AppendNotifyMessage(payload)); 
  })
  .catch((err) => console.log('failed: ', err));
  return ( 
    <div>
       {(isOpen === true && notificationmessage && notificationmessage.title!='') &&
       (
        <Toast
          isOpen={isOpen}
          hasAutoDismiss={true}
          hasCloseBtn
          closeCallback={() => setToast(false)}
          title={notificationmessage.title}
          description={''}
          duration={6000}
          classNames={['info']}  // 'success', 'info', 'warning', 'error'
          >
          <a className='toaster-with-link' href={findNotiUrl(notificationmessage.receiver)} target="_blank" >{ReactHtmlParser( notificationmessage.body +" ")}</a>
        </Toast>
       )}
      {notificationcount > 0 ?
      (
        <Button id="Popover1" className="bell-icon red-icon cursor-pointer" type="button">
          <Notifications/>
          {notificationcount > 0 &&
          (
            <span className="noti_alert">{notificationcount}</span>
          )}
        </Button>
      )
      :
      (
        <Button id="Popover1"  className="bell-icon cursor-pointer" type="button" onClick={goToNotifications}>
          <Notifications/>
        </Button>
      )}
      <Popover  className="custom_popover" placement="top-end" isOpen={popoverOpen} target="Popover1"
       toggle={notificationcount > 0 && toggle} >
        <div onMouseLeave={toggle}>
          <div className='d-flex justify-content-between popover-header-wrap align-items-center'>
            <PopoverHeader>Notifications</PopoverHeader>
            <a href="/notifications">All Notifications</a>
          </div>
          <BellNotifications myid={props.myid} />
        </div>
      </Popover>
    </div>
  );
};