import React, { Component, Fragment } from 'react';
import './basecampmodule.css'
import { urls } from "../../services/UrlList";
import axios from 'axios';
import { AccessCheck, AccessCheckBlock } from './AccessCheck';
import MaterialIcon from "material-icons-react";
import {
  Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, Tooltip, Breadcrumb, BreadcrumbItem,
  Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import MoveTodo from './MoveTodo';
export default class TaskMoveDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      listmodal: false,
      todoModal: false,
      taskgroup: '',
      taskList: '',
      taskGroup: [],
      taskLists: [],
      taskListGroup: [],
      activeList: 0,
      activListGroup: [],
      showList:true,
      listEditId:0,
      groupEditId:0,
      listCopyModal:false,
      listCopyId:'',
      listCopyName:'',
      groupCopyModal:false,
      groupCopyId:'',
      groupCopyName:'',
      showDeleteConfirmation:false,
      deleteListId:0,
      nonLinkedTasks:false,
      showMenu:true,
      open:false,
      dropdownOpen: false,
      moveTaskModal:false,
      moveTaskId:0
    };
  }

  componentDidMount() {
    document.title = "KIMPHUB - Boards";
    AccessCheck('access_projects_management_module');
    this.getTaskList();
  };

  getTaskList = async () => {
    this.setState({
      progressBar: true
    })
    const data = {
      project_id: this.props.boardid
    };
    const headers = {
      'Content-Type': 'application/json',
      'Access-Control-Allow-origin': '*',
    }
    axios.post(urls.getTaskListGroup, data, {
      headers: headers
    })
      .then((response) => {
        console.log(response.data);
        this.setState({
          accessDenied: response.data.success === 10 ? true : false,
          progressBar: false
        })
        let listgroups = this.reArrangeTasks(response.data);
        this.setState({ taskListGroup: listgroups, projectDetails: response.data.project_details[0], moveTaskModal:true});
      })
      .catch((error) => {
        this.setState({
          progressBar: false,
          accessDenied: true
        })
      })
  };

  reArrangeTasks = (data) => {
    let lists = data.tasks_list;
    let groups = data.tasks_group;
    let tasks = data.tasks;
    if(lists.length<=0 && groups.length<=0 && tasks.length>0)
      this.setState({nonLinkedTasks:true});
    else
      this.setState({nonLinkedTasks:false});
    let temp = [];
    let temp1 = [];
    var l = 0; var g = 0; var t = 0;
    lists.forEach(list =>   // Lists 
    {
      temp[l] = [];
      temp[l] = list;
      let temp1 = [];
      var g = 0;
      temp[l].tasks = [];
      groups.forEach(group =>  // Groups
      {
        if (list.id === group.task_list_id) {
          temp1[g] = [];
          temp1[g] = group;
          temp1[g]['tasks'] = [];
          temp1[g]['tasks_completed'] = [];
        }
        //let temp2=[];
        //var t=0;
        tasks.forEach(task =>  // group tasks 
        {
          if (task.task_list_id === list.id && task.task_group_id === group.id) {
            if(task.status==1)
            {
              temp1[g]['tasks'].push(task);
            }else
            {
              temp1[g]['tasks_completed'].push(task);
            }
          }
        })
        g = g + 1;
      })
      temp[l]['group'] = temp1.filter(val => val);
      tasks.forEach(task =>  // list tasks 
      {
        if (task.task_list_id === list.id && task.task_group_id === null) {
          temp[l].tasks.push(task);
        }
      })
      l = l + 1;
    })
    return temp;
  }

  moveTaskToggle = (taskid) =>
  {
    this.setState({moveTaskModal: !this.state.moveTaskModal, moveTaskId:taskid})
  }

  moveTaskUpdate = () =>
  {
    this.setState({moveTaskModal:false, moveTaskId:0});
    this.props.onmoveupdate();
  }

  redirecToBoard = (bid)=>
  {
    this.setState({
      groupCopyModal: false, 
      groupCopyId:'', 
      groupCopyName:'',
      listCopyModal: false, 
      listCopyId:'', 
      listCopyName:''
    });
    window.open(urls.notificationUrl+"/internal-task-list/"+bid, "_blank");
  }

 

  render() {
    return (
      <div className="fullwidth_mobilemenu">
        <Modal isOpen={this.state.moveTaskModal} toggle={this.moveTaskToggle} className="addtodo_wrapper movetodo_pop copy_list_wrapper">
          <ModalHeader toggle={this.moveTaskToggle}>Move a To-Do
            <Button className="clase_btn_cp" onClick={this.moveTaskToggle}>
                <MaterialIcon icon="close" /> 
            </Button>
          </ModalHeader>
          <ModalBody>
              <MoveTodo 
              tasklistgroup={this.state.taskListGroup} 
              taskid={this.props.moveTaskId}
              onmoveupdate={this.moveTaskUpdate} />
          </ModalBody>
        </Modal>
      </div>
    )
  }
}
