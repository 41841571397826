import React, { Component } from "react";
import { Input, Button, Container, Row, Col } from "reactstrap";
import PageSubTitle from "../../../page-title/PageSubTitle";
import "./Logamend.css";
import LoadingBar from "../../../common/LoadingBar";
import ConfirmAction from "../../../admincomment/ConfirmAction";
import CloseIcon from "../../../svgicon/CloseIcon";
import PeopleTags from "../../PeopleTag";
import MaterialIcon from "material-icons-react";
import { Scrollbars } from 'react-custom-scrollbars';
export default class LogAmend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tassignees: this.props.tassignees,
      //newassignees: ["" + this.props.myid + "", "" +this.props.managerid+ ""],
      newassignees: ["" + this.props.myid + ""],
      newassignees: this.props.taskassigneearray,
      myid: this.props.myid,
      activeme: true,
      completionStatus: false,
      showCompletionDialogue: false,
      myPrivileges: [],
      showCompletion: false,
      hideshowbox:true,
      showMinimumMsg:false,
      passigneesOriginal: this.props.passignees,
      passignees: this.props.passignees,
      tassignees: this.props.tassignees,
      searchKey:'',
      MinimumMsg:"Please select at least one person"
    };
  }

  componentDidMount() {
    this.checkUserPrivilege();
  }
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({ myPrivileges: login.privileges });
      if (login.privileges.includes("mark_task_completed")) {
        this.setState({ showCompletion: true });
      }
    }
  };

 

 

  filterItems = (key) => {
    if(key.length>0)
    {
      let temp = this.props.passignees;
      let list =[];
      temp.forEach((p1)=>
      {
        if(p1.name.toLowerCase().includes(key.toLowerCase()))
        {
          list.push(p1);
        }
      })
      this.setState({
        passignees:list
      })
    }else
    {
      this.setState({
        passignees:this.props.passignees
      })
    }
  }

  handleClick = e => {
    let members = this.state.newassignees;
    this.setState({ myid: "", showMinimumMsg:false});
    var ret = this.state.tassignees.replace(e.target.value, "");
    this.setState({ tassignees: ret });
    if (e.target.checked) {
      members.push(e.target.value);
      this.setState({
        tassignees: this.state.tassignees + "," + e.target.value
      });
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
      }
      this.setState({ newassignees: array });
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handleunassignme = e => {
    this.setState({ activeme: !this.state.activeme, showMinimumMsg:false });
    let members = this.state.newassignees;
    if (e.target.checked) {
      members.push(e.target.value);
    } else {
      var array = [...this.state.newassignees]; // make a separate copy of the array
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({ newassignees: array });
      }
    }
    const _this = this;
    setTimeout(function() {
      //Start the timer
      console.log(_this.state.newassignees);
    }, 1000);
  };

  handlecompletion = () => {
    this.setState({ showCompletionDialogue: true, hideshowbox:false });
  };

  handleAssigneeUpdate = () => {
    if(this.state.tassignees.length<=0)
    {
      this.setState({
        showMinimumMsg:true
      });
      return false;
    }else
    {
      this.props.onassigneupdate(
        this.state.tassignees,
        this.state.completionStatus
      );
    }
   
  };

  closeDialogue = () => {
    this.setState({
      showCompletionDialogue: false,
      hideshowbox:true
    });
  };

  handleCompletionConfirm = () => {
    this.setState({ completionStatus: !this.state.completionStatus });
    // this.setState({completionStatus: false});
    const _this = this;
    setTimeout(function() {
      //Start the timer
      _this.handleAssigneeUpdate();
    }, 1000);
  };

  closePopup = () => {
    this.props.closeaction();
  };

  handleChange = ({ target }) => {
		this.setState({ [target.name]: target.value });
    this.filterItems(target.value);
	};

 checkMatchingItem = (id) => {
    var arrNum = this.state.tassignees;
    let numbermatching = 0;
    for (var i = 0; i < arrNum.length; i++) {
      if (id === arrNum[i]) {
        numbermatching = arrNum[i];
      }
    }
    if(numbermatching>0)
    {
      return true;
    }else
    {
      return false;
    }
  }

  setAssignees = (tags) =>
  {
    this.setState({tassignees: tags, showMinimumMsg:false})
  }

  render() {
    return (
      <Container className="logamend-modal">
        
        <div className="overlay-log" onClick={this.closePopup}></div>
        {this.state.hideshowbox && 
        (
          <div className="logamend center-fixed-modal">
           <Button className="close-icon" onClick={this.closePopup}> <MaterialIcon icon="close"/></Button>
          {/* <CloseIcon closeaction={this.closePopup} /> */}
          {this.props.loading === true ? (
            <LoadingBar />
          ) : (
            <Row>
              <Col className="has-border-right">
                <div className="change-assignee">
                  <PageSubTitle title="Reassign Task" />
                    <span className="member-not-found text-center ">
                      Can't see the team member you wish to reassign to? Please ask your project manager to add the member to this project.
                      </span>
                
                   
                    <Row>
                      {this.state.showMinimumMsg &&
                      (
                        <Col sm="12"><span className="minimum-text text-center">{this.state.MinimumMsg}</span></Col>
                      )}
                      
                      <Col sm="12">
                      <Scrollbars style={{ height: 170 }} className="xhide">
                        {(this.state.passignees && this.state.passignees.length>0) &&
                        (
                          <PeopleTags tagslist={this.state.passignees} 
                             settags={this.setAssignees}
                              tags={this.state.tassignees}
                              placeholder="Add Assignees"
                              />
                        )} 
                        </Scrollbars>
                      </Col>

                      <Col sm="12">
                        <Button
                          className="project-assignee-submit btn-green"
                          onClick={this.handleAssigneeUpdate.bind(this)}
                        >Reassign</Button>

                        {/* this.state.showCompletion && (
                          <Button
                            className="project-assignee-submit btn-green"
                            onClick={this.handlecompletion.bind(this)}
                          >Mark as Completed</Button>
                        ) */}
                      </Col>
                    </Row>
            
                </div>
              </Col>
            </Row>
          )}
        </div>
        )
        }
        {this.state.showCompletionDialogue ? (
          <ConfirmAction
            purpose="change"
            closeDialogue={this.closeDialogue}
            show={this.state.showCompletionDialogue}
            note="*This action cannot be undone, are you sure?"
            content="You are about to change the status of this task to completed. This will mark the task status in basecamp as completed."
            handleCompletionConfirm={this.handleCompletionConfirm}
            resolveid=""
            resolveStatus=""
            adminid=""
            confirmBoxAction="markascomplete"
          />
        ) : null}
      </Container>
    );
  }
}
