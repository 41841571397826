import React from 'react';
import './PageTitle.css';

export default function PageTitle( props ) {
  return (
    <h1 className="page-title text-center">
      { props.title }
    </h1>
  )
}
