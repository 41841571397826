import React, { Component } from "react";
import { Card, Row, Col, Table } from "reactstrap";
import "./LeaveBalenceCard.css";
import LoadingWrapper from '../../../LoadingBox/LoadingWrapper/LoadingWrapper'

export default class LeaveBalenceCard extends Component {  
  render() {
    let cls = " leave-balence-card " + this.props.classname +" "+ this.props.flipcard;
    return (
          <LoadingWrapper isLoading={this.props.loading} >
            <>
            <tbody>
                <tr>
          
                
                    <td colSpan="1">{this.props.lname}</td>
                    {this.props.fwd>=0 &&
                    (
                      <td colSpan="1">{this.props.fwd}</td> 

                    )}
                  <td colSpan="1">{this.props.yearly}</td>
                  <td colSpan="1">{this.props.allocated}</td>
                  <td colSpan="1">{this.props.eligible}</td>
                  <td colSpan="1">{this.props.taken}</td>
                  <td colSpan="1">{this.props.lbalance}</td>
                  </tr>
             
                  </tbody>
            </>
          </LoadingWrapper>
       
    );
  }
}
