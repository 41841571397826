import React, { useState, useEffect, Fragment } from 'react';
import './salarytable.css';
import { Container, Row, Col, Card, FormGroup, Input, Button, Table, CardTitle, InputGroup, Label } from 'reactstrap';
import MaterialIcon from "material-icons-react";
import {useGetPaymentInfoSoft} from "./useQueryHooks";
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { urls } from "../../services/UrlList";
import axios from 'axios';

export default function SalaryTableItemSoft({user, monthselected, handleverification}) {
  
  const [changeUpdate, setChangeUpdate] = useState(false)
  const [member, setMember] = useState(user);
  const [verifyaction, setVerifyAction] = useState(true);
  const [isShown, setIsShown] = useState(false);
  const [toggleFinalize, setToggleFinalize] = React.useState(true);
  const [hrFinalized, setHrFinalized] = React.useState(""); 
  const [hrNote, setHrNote] = useState(user.hr_note);

  const { isLoading, isError, error, data, isFetching} = useGetPaymentInfoSoft(user, monthselected);
  useEffect(()=>
  {
    if(!isLoading)
    {
        let info = data.data;
        setMember({...member, ...info});
    }
  },[data])

  function toggleInput() {
    setToggleFinalize(false);
  }

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
        updateSalary();
    }
  }

  const handleNoteChange = (e)=>
  {
    setHrNote(e.target.value);
  }
  
  function updateNotes ()
  {
    let membertemp = member;
    membertemp.hr_note = hrNote;
    setMember(membertemp);

      const data = {
          user_id: member.user_id,
          hr_note:hrNote,
          monthselected:monthselected
        };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin':'*',
        }
        axios.post(urls.updateSalaryHrNotes, data, {
            headers: headers
          })
          .then((response) => {
            setIsShown(!isShown);
          })
          .catch((error) => {
            setIsShown(!isShown);
          })
  }

  function updateSalary ()
  {
    let membertemp = member;
    membertemp.hr_finalised_amount = hrFinalized;
    setMember(membertemp);
    const data = {
        user_id: member.user_id,
        hr_finalised_amount:hrFinalized,
        monthselected:monthselected
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateSalaryHrFinalized, data, {
        headers: headers
      })
      .then((response) => {
        setToggleFinalize(!toggleFinalize);
      })
      .catch((error) => {
        setToggleFinalize(!toggleFinalize);
      })
  }


  function handleChange(event) {
    let membertemp = member;
    membertemp.hr_finalised_amount = event.target.value;
    setMember(membertemp);
    setHrFinalized(event.target.value);
  }

  const handleClicknote = event => {
    setIsShown(current => !current);
  };

  const handleClick = () => {
    const updatedState = member;
    if (updatedState.other == '0') {
      updatedState.other = '1';
    } else {
      updatedState.other = '0';
    }
    setChangeUpdate(!changeUpdate);
  };
 
  const calculatePay = (memdata) =>
  {
    let salary = memdata.amount;
    let singledaypay = salary/parseFloat(memdata.workingdays);
    let payment = 0, deduction=0;
    if(memdata.newjoinee == 0 && parseFloat(memdata.presentdays)>0)  // old employee
    {
         deduction = parseFloat(memdata.lopdays) * singledaypay;
         //payment = salary - deduction;
         payment = (parseFloat(memdata.presentdays) + parseFloat(memdata.leavedays)) * singledaypay;
    }else if(memdata.newjoinee == 1 && parseFloat(memdata.presentdays)>0)  // new joinee
    {
         payment = (parseFloat(memdata.presentdays) + parseFloat(memdata.leavedays)) * singledaypay;
    }else if(memdata.presentdays == 0 && memdata.leavedays == 0)  // non paying staff
    {
         payment = 0;
    }else if(parseFloat(memdata.presentdays) == 0 && parseFloat(memdata.leavedays) >0 )  // still paying staff due to leave applied
    {
        payment = parseFloat(memdata.leavedays) * singledaypay;
    }
    return Math.round((payment + Number.EPSILON) * 100) / 100;
  }

  const hrVerification = () =>
  {
    setVerifyAction(false);
    const data = {
        user_id: member.user_id,
        hr_finalised_amount: Math.round(member.hr_finalised_amount >0 ? member.hr_finalised_amount : member.calculatedpay),
        hr_verification: member.hr_verification == 0 ? 1 : 0,
        monthselected:monthselected
    };
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-origin':'*',
    }
    axios.post(urls.updateSalaryHrVerification, data, {
        headers: headers
      })
      .then((response) => {
        let membertemp = member;
        membertemp.hr_verification = member.hr_verification == 0 ? 1 : 0;
        membertemp.hr_finalised_amount = Math.round(member.hr_finalised_amount> 0 ? member.hr_finalised_amount : member.calculatedpay); 
        setMember(membertemp);
        setVerifyAction(true);
        handleverification();
      })
      .catch((error) => {
        setVerifyAction(true);
      })
  }

 
  return (
    <Fragment key={user.user_id}>
    {(isLoading  || isFetching)?
    (
        <tr>
            <LoadingWrapper isLoading="true" />
        </tr>
    ):
    (
      <tr>
      <td onClick={handleClick}  style={{ cursor: "pointer" }}  className={`payee-name ${member.newjoinee == 1 && 'new-joinee '} ${(parseFloat(member.presentdays) == 0 && parseFloat(member.leavedays) == 0) && 'non-paying '} ${(parseFloat(member.presentdays) == 0 && parseFloat(member.leavedays) >0) && 'still-paying '} `}>{member.name}</td>
      <td>{member.bankCode}</td>
      <td>{member.branchCode}</td>
      <td>{member.accountNumber}</td>
      <td>
      {member.addressCountryCode}<br/>
      </td>
      <td>{member.targetCurrency} {member.amount}</td>
      <td>{member.targetCurrency} {member.calculatedpay}</td>
      
      <td style={{width:"140px"}}>
      {toggleFinalize ? (
         <p onClick={toggleInput}>
          {member.targetCurrency} {member.hr_finalised_amount === '0.00' ? member.calculatedpay :  member.hr_finalised_amount}
         </p>
      ) : (
        <input type="text" className="hrf_input" value={member.hr_finalised_amount === '0.00' ? member.calculatedpay :  member.hr_finalised_amount}
         onChange={handleChange} 
         onKeyDown={_handleKeyDown} />
      )}
      </td>
      <td>
        <>
        {verifyaction &&
        (
          <>
            {member.hr_verification === 1 ? 
            (<span className='hr_verified' onClick={hrVerification}>Verified</span>)
            :
            (<span onClick={hrVerification}>Verify</span>)}
              </>
            )}
        </>
      </td>
      {(member.other && member.other == 1) ? (
          // <tr>
          //   <td colSpan={15}>
          <div className="more_salary_details">
              <div onClick={handleClick}  style={{ cursor: "pointer" }} className='top-name'>{member.name}</div>
              <div style={{position:'relative'}}>
                  <h3>HR Note</h3>
                  <span onClick={handleClicknote} title={member.hr_note && member.hr_note ?  member.hr_note : ''} >
                      {member.hr_note === '' ? "..." : <MaterialIcon icon="visibility"  /> }
                    </span> 
                    {isShown && (
                    
                    <div className="add_hr_note">
                        <FormGroup>
                          <Input type="textarea" name="text" value={hrNote}  onChange={handleNoteChange}  />
                      </FormGroup>
                      <Button className="update_btn" onClick={updateNotes}>Update</Button>
                      <Button className="close_btn" onClick={handleClicknote}>close</Button>
                    </div>
                  )}
               </div>
              <div>
                  <h3>Present days</h3>
                  <span>{parseFloat(member.presentdays)}</span>
              </div>
              <div>
                  <h3>Leaves</h3>
                  <span>{parseFloat(member.leavedays)}</span>
              </div>
              <div>
                  <h3>LOP</h3>
                  <span>{parseFloat(member.lopdays)}</span>
              </div>
              {/* <div>
                  <h3>Loan Cutting</h3>
                  <span>---</span>
              </div> */}
              <div>
                  <h3>Amount - Source - Target</h3>
                  {member.amountCurrency} - {member.sourceCurrency} - {member.targetCurrency}
              </div>
          </div>

          //   </td>
          // </tr>
      ) : null}
    </tr>
    )}
  </Fragment>
  )

}


