import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import "./Task.css";
import {Input, Container, Row, Col, Card } from "reactstrap";
import Header from "../header/Header";
import { urls } from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import PageTitle from "../page-title/PageTitle";
import Alertlogmsg from "../messages/Alertlogmsg";
import AddToProject from "./task-single/AddToProject";
import AddButton from "../../images/add-button.png";
import {isMobile} from 'react-device-detect';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import {motion} from 'framer-motion';

export default class ProjectAccess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalTaks: [1, 2, 3],
      myPrivileges: [],
      myId: "",
      myBaseId: "",
      myName: "",
      teamId:'',
      showProgress: true,
      showAddTaskModalBox:true,
      showMenu:true,
    };
  }

  componentDidMount = () => {
    document.title = "HUB - My Assignments";    
    this.checkUserPrivilege();
    this.renderContentDevice(); 
    const _this = this;
    setTimeout(function() {
      //Start the timer
    }, 1000);
  };
  checkUserPrivilege = () => {
    if (localStorage.getItem("user") !== null) {
      var login = JSON.parse(localStorage.getItem("user"));
      this.setState({
        myPrivileges: login.privileges,
        myId: login.uid,
        myBaseId: login.basecampid,
        myName: login.name,
        teamId: login.user_team
      });
      if(!login.privileges.includes('kimp_team'))
      {
        this.props.history.push("/home");
      }
    }
  };

  renderContentDevice = () => {
    if (isMobile && !this.state.myPrivileges.includes('mobile_access')) {
        this.setState({isMobileView:1});
    } 
    if (isMobile) {
      this.setState({showMenu:false});
    }  
  };

  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }


  componentWillUnmount = () =>
  {
    //this.dotoTracking();
  }

  handleProcessUpdate = (result) =>
  {
    if(result === 1)
    {
      this.setState({
        accessMsg:'Access Granted',
        showAccessMsg:true
      })
    }else
    {
      this.setState({
        accessMsg:'Sorry, Kimp Bot Cannot add you to this project for some reason. Please contact administrator.',
        showAccessMsg:true
      })
    }
  }
  
  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");
        fetch(urls.logmeout)
          .then(response => response.json())
          .then(response => {
            localStorage.clear();
            this.props.history.push("/");
          })
          .catch(error => {
            localStorage.clear();
            this.props.history.push("/");
          });
      });
  };

  componentDidUpdate(prevState) {
    if (
      prevState.showAccessMsg !== this.state.showAccessMsg &&
      this.state.showAccessMsg === true
    ) {
      const _this = this;
      setTimeout(function() {
        _this.setState({ showAccessMsg: false });
      }, 8000);
    }
  }
  render() {
    const item = {
      visible: { opacity: 1, y: 0 },
      hidden: { opacity: 0, y: -20 },
    }

    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')}>
      <Row>
        <div className="theme_header">
        <Header {...this.props} 
                    classname="tick-timer-header"
                    logout={this.signOut.bind(this)}
                    onmenuaction={this.toggleSidebar.bind(this)}
                    quickactionblock={true}
                  />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="kimpteam" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
        <Row>
            <Col sm="12">
              <div className="add_task_link">
              <Card id="all-tasks" className={this.state.runningTaskStatus !== 1 ? "task-in-active" : "task-active-running"}>
                <PageTitle title="Request Project Access" />
                {this.state.showAccessMsg ? (
                    <Alertlogmsg
                      msgtext={this.state.accessMsg}
                      boxclass="task-running access-message"
                    />
                  ) : null}
                  {this.state.showAddTaskModalBox ? (
                    <AddToProject
                      ontaskupdate={this.handleProcessUpdate}
                      active={this.state.showAddTaskModalBox}
                      myid={this.state.myId}
                      baseid={this.state.myBaseId}
                    />
                  ) : null}
              </Card>
            </div>
          </Col>
        </Row>
        </div>
      </Row>
      </Container>
      
    </div>
    );
  }
}
