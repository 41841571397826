import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input
} from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import './InternaltimerStyle.css'
import MaterialIcon from "material-icons-react";
export default function UrgentMessageModal() {
  const inputRef = useRef(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggle = () => setModalIsOpen(!modalIsOpen);
  const handleOpen = () => inputRef.current.focus();

  // This will work too
  // useEffect(() => {
  //   if (modalIsOpen) {
  //     setTimeout(() => inputRef.current.focus(), 0);
  //   }
  // }, [modalIsOpen]);

  return (
     <div className="button_grp_activitys">
        <Button onClick={() => setModalIsOpen(true)}>Send an Urgent Message</Button>
        <Modal className="urgnet_message_modal" isOpen={modalIsOpen} toggle={toggle} onOpened={handleOpen} size="lg">
          <ModalHeader toggle={toggle}>Send an Urgent Message</ModalHeader>
          <ModalBody>
              
            <Input type="textarea" className="add_message_box" innerRef={inputRef} placeholder="Type your message here..." />
          </ModalBody>
           <Button className="send_message_btn">Send Message</Button>
           <Button className="close_btn_message" onClick={toggle}><MaterialIcon icon="close"/></Button>
       </Modal>
      </div>
  
  );
}
