import React, { Component, Fragment } from "react";
import "./Approvalhead.css";
import { Container, Col, Row } from "reactstrap";

export default class ApprovalHead extends Component {
  constructor(props) {
    super();
    this.state = {
      isSticky: false,
      breakActive: false,
      unblockShiftActive: false,
      wfhActive: false,
      leaveActive: true,
      activeItem:'leave'
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.sticky);
    this.props.activetabitem(this.state.activeItem);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.sticky);
  }

  sticky = () => {
    // this.buttonActiveState();
    const _this = this;
    let approveHead = _this.ApprovalHeadRef;    
    if (window.scrollY > 200) {
      _this.setState({ isSticky: true });
    } else if (window.scrollY < 200) {
      _this.setState({ isSticky: false });
    }
  };

  scrollToElem = id => {
    //this.props.scrollToElem(id);
    if(id == 'approve-leave')
    {
      this.setState({
        breakActive: false,
        unblockShiftActive: false,
        wfhActive: false,
        leaveActive: true,
        activeItem:'leave'
      })
    }else if(id == 'break-module')
    {
      this.setState({
        breakActive: true,
        unblockShiftActive: false,
        wfhActive: false,
        leaveActive: false,
        activeItem:'break'
      })
    }else if(id == 'shift-unblock-module')
    {
      this.setState({
        breakActive: false,
        unblockShiftActive: true,
        wfhActive: false,
        leaveActive: false,
        activeItem:'shift'
      })
    }
    const _this=this;
    setTimeout(function() { 
      _this.props.activetabitem(_this.state.activeItem);            
    }, 100);
    
  };

  // buttonActiveState = () => {
  //   let select = document.querySelector;
  //   document.querySelectorAll("#approval-head button").forEach(function(item) {
  //     let hasClass = Array.from(item.classList).includes("active");
  //   });
  // };

  render() {
    let apprClass = ["d-flex justify-content-start", this.state.isSticky && "header_is_sticky"].join(
      " "
    );
    return (
      <section
        id="approval-head"
        className={apprClass}
        ref={this.ApprovalHeadRef}
      >
        <button
          className={
            "appr-leave " +
            (this.props.leavecount > 0
              ? "appr-tab-has-msg approve-leave"
              : "") +
            (this.state.leaveActive && " active")
          }         
          data-right={this.props.leavecount}
          onClick={this.scrollToElem.bind(this, "approve-leave")}
        >
          leave
        </button>
        <button
          className={
            "appr-break " +
            (this.props.breakcount > 0 ? "appr-tab-has-msg break-module" : "") +
            (this.state.breakActive && " active")
          }
          data-right={this.props.breakcount}         
          onClick={this.scrollToElem.bind(this, "break-module")}
        >
          Break Over Quota
        </button>
        <button
          className={
            "appr-unblock-shift " +
            (this.props.shiftcount > 0
              ? "appr-tab-has-msg shift-unblock-module"
              : "") +
            (this.state.unblockShiftActive && " active")
          }         
          data-right={this.props.shiftcount}
          onClick={this.scrollToElem.bind(this, "shift-unblock-module")}
        >
          unblock shift
        </button>
        
        {/*<button
          className={
            "appr-wfh " +
            (this.props.wfhcount > 0 ? "appr-tab-has-msg wfh" : "") +
            (this.state.wfh && "active")
          }
          data-right={this.props.wfhcount}
          onClick={this.scrollToElem.bind(this, "wfh")}
        >
          wfh
        </button>
        <button className="appr-shift-change">shift change</button>
        <button className="appr-loan">loan</button>*/}
      </section>
    );
  }
}
