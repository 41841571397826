import React,{useState, useEffect} from 'react';
import './basecampmodule.css';
import {Button} from "reactstrap";
import { AccessCheckBlock } from './AccessCheck';
import ReactHtmlParser from 'react-html-parser';
import Linkify from 'linkifyjs/react';
import ConfirmAction from '../admincomment/ConfirmAction';
import moment from "moment";
import { useFetchEmails } from './SourceManagement/useQueryHooks';

export default function CanvaAccess(props)
{
    const [showCanvaEmail, setShowCanvaEmail] = useState(false);
    const {
        isLoading: emailsloading,
        isError,
        error,
        isFetching,
        data: emailsData,
        refetch: fetchEmailsList,
      } = useFetchEmails('1234',  props.email);

    
    const filterMessage = (msg)=>
    {
        const text = msg;
        const regex = /^(\d+)\s+(.+)$/; // regex to match the code number and the rest of the text
        const [, code, message] = text.match(regex);

        return(
            <div>
                <span className='passcode'>
                    {code}
                </span>
                <span className='passcode-msg'>
                    {message}
                </span>
            </div>
        )
    }


    if (emailsloading || isFetching) {
        return <div>Loading....</div>;
    }else

    return(         
        <div>
            {!showCanvaEmail ?
            (
                <Button className="show-all-work-logs" onClick={setShowCanvaEmail}>Canva Access</Button>
            ):
            (
                <>
                    <h6 className='canva-access-head'>Canva Access</h6>
                    {emailsData?.data.success === 0 ?
                    (
                        <div className='d-flex canva-fetch-block'>
                            <div>
                                {emailsData?.data.msg}
                            </div>
                            <div>
                                Canva Account: {props.email}
                            </div>
                            <div>
                                <Button onClick={fetchEmailsList}>Fetch Passcode</Button>
                            </div>
                        </div>
                    ):
                    (
                        <div className='d-flex canva-fetch-block'>
                            <span>
                                {emailsData?.data.subject && filterMessage(emailsData?.data.subject)}
                            </span>
                            <span>
                                Valid Till: {emailsData?.data.expiry}
                            </span>
                            <div>
                                <Button onClick={fetchEmailsList}>Refetch Passcode</Button>
                            </div>
                        </div>
                    )}
                </>
            )}
            
        </div>
    )
}