import React, { Component } from 'react';
import { Fragment } from 'react';
import ReactTags from 'react-tag-autocomplete'

export default class QaitemslogoutReport extends Component {     
  constructor(props){
    super(props);
    this.state = {
    showcolor:false,
    activeoption:0,
    showoption:false,
    activeItem:0 ,
    answer:'',
    tagdata:[]
    };
    this.reactTags = React.createRef();
  }

  enableYes =(id, triger, type) =>
  {
    this.setState({
      showcolor:true,
      activeoption:1,
      showoption: triger == 1 ? true : false,
      activeItem:id
    })
  }
  enableNo =(id, triger, type) =>
  {
    this.setState({
      showcolor:true,
      activeoption:0,
      showoption: triger == 0 ? true : false,
      activeItem:id
    })
  }
  
  updateAnswerText = (e)=>
  {
  }

  updateQrAnswer = (qstype, qid, answer_opt, answer_text, pms, dsgns)=>
  {
  }

  onDeleteDesigners = ()=>
  {

  }
  onDeletePms = ()=>
  {

  }

  onAdditionDesigners (section, k, tag, etags) {
   }

  onAdditionPms (section, k, tag, etags) {
  }

render() {
  return (    
      <>    
          {this.props.logoutquestions.map((itm,k)=>{
          return(
            <>
            {itm.question &&
            (
              <>
              {itm.special_question == 1 ?
              (
                  <>
                    {this.props.privileges.includes('co_founder_only') &&
                    (
                        <>
                            <div className="qa-card clearfix">
                            <div className="das-qa">{itm.question}</div>
                            <div className="button-wrap">
                              <div onClick={this.enableYes.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 1 ? 'checkqa green': 'checkqa'}>Yes</div>
                              <div onClick={this.enableNo.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 0 ?  'checkqa green': 'checkqa'}>No</div>
                            </div>
                                  
                            { this.state.showoption && this.state.activeItem == itm.question_id ? 
                              <div class="desc qa_addcomment">
                              {itm.answer_type == 1 &&
                              (
                                <>
                                  <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                                </>
                              )}
                              {(itm.answer_type == 2 && itm.related_group == 1) &&
                              (
                                <>
                                  <div className="d-flex answers-part cant-edit">
                                    <ReactTags
                                        ref={this.reactTags}
                                        tags={itm.designers}
                                        suggestions={this.props.designers}
                                        onDelete={this.onDeleteDesigners.bind(this)}
                                        onAddition={this.onAdditionDesigners.bind(this, 5, k, itm.designers)}
                                        placeholderText="Start typing to select designers" />
                                    <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                                  </div>
                                </>
                              )}
                              {(itm.answer_type == 2 && (itm.related_group == 2 || itm.related_group == 3)) &&
                              (
                                <>
                                  <div className="d-flex answers-part cant-edit">
                                    <ReactTags
                                        ref={this.reactTags}
                                        tags={itm.project_managers}
                                        suggestions={this.props.managers}
                                        onDelete={this.onDeletePms.bind(this)}
                                        onAddition={this.onAdditionPms.bind(this, 5, k, itm.project_managers)}
                                        placeholderText="Start typing to select project managers" />
                                    <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                                  </div>
                                </>
                              )}

                            </div>
                            : null 
                            }
                          </div>  
                        </>
                    )}
                  </>
              )
              :
              (
                <>
                  <div className="qa-card clearfix">
                    <div className="das-qa">{itm.question}</div>
                    <div className="button-wrap">
                      <div onClick={this.enableYes.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 1 ? 'checkqa green': 'checkqa'}>Yes</div>
                      <div onClick={this.enableNo.bind(this,itm.question_id, itm.popup_required, 5)} className={itm.answer_yes_no === 0 ?  'checkqa green': 'checkqa'}>No</div>
                    </div>
                          
                    { this.state.showoption && this.state.activeItem == itm.question_id ? 
                      <div class="desc qa_addcomment">
                      {itm.answer_type == 1 &&
                      (
                        <>
                          <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                        </>
                      )}
                      {(itm.answer_type == 2 && itm.related_group == 1) &&
                      (
                        <>
                          <div className="d-flex answers-part cant-edit">
                            <ReactTags
                                ref={this.reactTags}
                                tags={itm.designers}
                                suggestions={this.props.designers}
                                onDelete={this.onDeleteDesigners.bind(this)}
                                onAddition={this.onAdditionDesigners.bind(this, 5, k, itm.designers)}
                                placeholderText="Start typing to select designers" />
                            <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                          </div>
                        </>
                      )}
                      {(itm.answer_type == 2 && (itm.related_group == 2 || itm.related_group == 3)) &&
                      (
                        <>
                          <div className="d-flex answers-part cant-edit">
                            <ReactTags
                                ref={this.reactTags}
                                tags={itm.project_managers}
                                suggestions={this.props.managers}
                                onDelete={this.onDeletePms.bind(this)}
                                onAddition={this.onAdditionPms.bind(this, 5, k, itm.project_managers)}
                                placeholderText="Start typing to select project managers" />
                            <textarea disabled  stype="5" id={itm.question_id} class="textareabox" >{itm.answer_text}</textarea>
                          </div>
                        </>
                      )}

                    </div>
                    : null 
                    }
                  </div>  
                </>
              )}
              </>
            )
            }
          </>  
          )}
        )}
      </>          
    )}
}


