import React, { useState, useEffect, useRef  } from "react";
import {Row, Button,UncontrolledTooltip, Spinner} from "reactstrap";
import ReactQuill, { Quill } from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'quill-mention/dist/quill.mention.min.css';
import MaterialIcon from "material-icons-react";
import axios from 'axios';
import Hashids from 'hashids';
import toast, { Toaster } from 'react-hot-toast';
import { urls } from "../../../services/UrlList";
import { findMockups,excludeMockupFromOther360 } from "./HelperFunctions";

const hashids = new Hashids('',6,'abcdefghijklmopqrstuvwxyz0123456789','', );

export default function ComposeMessage(props) { 
  const [newMessage, setNewMessage] = useState(props.newcomment360 ? props.newcomment360 : '');
  const [saveupdateProgress, setSaveupdateProgress] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [mockupFolders, setMockupFolders] = useState([]);
  const [otherFoldersFiltered, setOtherFoldersFiltered] = useState([]);


  const inputElement = useRef();
  
  const actualId = hashids.decode(props.cardId);


  // useEffect(()=>
  // {
    
  //   setAttachingFiles(props.attachingFiles);
  //   setAttachingFoldersOutput(props.attachingFoldersOutput);
  //   setAttachingFoldersSource(props.attachingFoldersSource);
  //   setAttachingFoldersOthers(props.attachingFoldersOthers);

  //   setMockupFolders(findMockups(props.attachingFoldersOthers));
  //   setOtherFoldersFiltered(excludeMockupFromOther360(props.attachingFoldersOthers));

  // },[props.attachingFiles, props.attachingFoldersOutput, props.attachingFoldersSource, props.attachingFoldersOthers])


  const toolbarOptions = [
    ['bold', 'italic', 'underline'],        
    [{ 'color': [] }, { 'background': [] }],           
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],     
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }] ,
    ['link','image'],
    ['clean']    
  ];

  const modules = React.useMemo(
    () => ({
        toolbar: toolbarOptions,
        imageUploader: {
          upload: file => {
            return new Promise((resolve, reject) => {
              const data = new FormData();
              data.append('file', file);
              data.append('filename', file.name);
              data.append('filesize', file.size);
              data.append('filetype', file.type);
              data.append('folder_path', 'editor')
              data.append('user_id', 1);
              axios.post(urls.uploadProjectMediaEditor, data, { 
              })
              .then(res => {
                  resolve(res.data.file_name);
              })
              .catch(error => {
                  reject("Upload failed");
                  console.error("Error:", error);
                });
            });
          }
        }
      }),
    []
 );


  useEffect(()=>
  {
    setNewMessage(props.newcomment360);
  },[props.newcomment360])

  useEffect(()=>
  {
    autoSavedComment();
  },[])

  useEffect(()=>
  {
    setMockupFolders(findMockups(props.attachingFoldersOthers));
    setOtherFoldersFiltered(excludeMockupFromOther360(props.attachingFoldersOthers));
  },[props.attachingFoldersOthers])


  const setCommentStorage = (type, value) => 
 {
      let commentData60 = {};
      if (localStorage.getItem("commentData60") !== null) {
        let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
        if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
        {
          commentData60 = commentTemp;
        }
      }
      commentData60.taskID = props.tid_cpt;
      if (type == 'taskNewComment')
        commentData60.taskNewComment = value;
      else if (type == 'commentAttachments')
        commentData60.commentAttachments = value;

      console.log(commentData60);
      localStorage.setItem("commentData60", JSON.stringify(commentData60));
  }

  const autoSavedComment = () => {
    if (localStorage.getItem("commentData60") !== null) {
      var commentData60 = JSON.parse(localStorage.getItem("commentData60"));
      if(commentData60.taskID && commentData60.taskID === props.tid_cpt)
      {
        setNewMessage(commentData60.taskNewComment ? commentData60.taskNewComment : '');
        
        // setAttachingFiles(commentData60.chubattachments ? commentData60.chubattachments : []);
        // setAttachingFoldersOutput(commentData60.attachingFoldersOutput ? commentData60.attachingFoldersOutput : []);
        // setAttachingFoldersSource(commentData60.attachingFoldersSource ?  commentData60.attachingFoldersSource :[]);
        // setAttachingFoldersOthers(commentData60.attachingFoldersOthers ? commentData60.attachingFoldersOthers : []);

        // props.updateattachfolders(commentData60.attachingFoldersOutput ? commentData60.attachingFoldersOutput :[], commentData60.attachingFoldersSource ? commentData60.attachingFoldersSource :[], commentData60.attachingFoldersOthers ? commentData60.attachingFoldersOthers:[]);
        // props.updateattachfiles(commentData60.chubattachments ? commentData60.chubattachments :[]);
      
      }
    }
    }
  
  const handleChangeEditor = (value) =>  {
    setMessageError(false);
    setNewMessage(value);
    setCommentStorage('taskNewComment', value);
  }


  const saveComment = (type) =>
  {
    if(newMessage === '' || newMessage.trim() ==='<p><br></p>')
    {
      setMessageError(true);
      setSaveupdateProgress(false);
      return false;
    }
    setSaveupdateProgress(true);
      const data = {
          type: type,
          userid: props.kim360id,
          comment: newMessage,
          medias: props.attachingFiles,
          source_files:{
            'output_folders': props.attachingFoldersOutput,
            'source_folders': props.attachingFoldersSource,
            'other_folders': props.attachingFoldersOthers
          }
      };
        const headers = {
          'Content-Type': 'application/json',
          'Access-Control-Allow-origin':'*',
        }
        axios.post(`${urls.domain360}api/v1/task/${actualId[0]}/comment`, data, {
            headers: headers
          })
        .then((response) => {
          setSaveupdateProgress(false);
          setNewMessage('');
          props.updateattachfolders([], [], []);
          props.updateattachfiles([]);
          toast('Message posted to kimp 360 successfully');
        })
        .catch((error) => {
          setSaveupdateProgress(false);
          toast(error.response?.data.message);
        })
  }
  
  const attachComposerFiles = (file)=>
  {
    let temp = [...props.attachingFiles];
    let index = temp.findIndex(x => x.fileid == file.fileid);
    if(index>=0)
    {
      temp.splice(index, 1);
    }

    let commentData60 = {};
    if (localStorage.getItem("commentData60") !== null) {
      let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
      if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
      {
        commentData60 = commentTemp;
      }
    }
    commentData60.chubattachments = temp;
    localStorage.setItem("commentData60", JSON.stringify(commentData60));

    props.updateattachfiles(temp);
  }

  const deleteSourceViewFolder = (foldertype, folderpath) =>
  {
    let attachingFoldersOutput = [...props.attachingFoldersOutput];
    let attachingFoldersSource = [...props.attachingFoldersSource];
    let attachingFoldersOthers = [...props.attachingFoldersOthers];

    if(foldertype === 1)
    {
      var itemkey = attachingFoldersOutput.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersOutput.splice(itemkey, 1);
    }else if(foldertype === 2)
    {
      var itemkey = attachingFoldersSource.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersSource.splice(itemkey, 1);
    }else if(foldertype === 3)
    {
      var itemkey = attachingFoldersOthers.map(function (item) { return item.path; }).indexOf(folderpath); // (2D) finding the matching key providing value
      attachingFoldersOthers.splice(itemkey, 1);
    }

    // let commentData60 = {};
    // if (localStorage.getItem("commentData60") !== null) {
    //   let commentTemp = JSON.parse(localStorage.getItem("commentData60"));
    //   if(commentTemp.taskID && commentTemp.taskID === props.tid_cpt)
    //   {
    //     commentData60 = commentTemp;
    //   }
    // }
    // commentData60.attachingFoldersOutput = attachingFoldersOutput;
    // commentData60.attachingFoldersSource = attachingFoldersSource;
    // commentData60.attachingFoldersOthers = attachingFoldersOthers;
    // localStorage.setItem("commentData60", JSON.stringify(commentData60));

    props.updateattachfolders(attachingFoldersOutput, attachingFoldersSource, attachingFoldersOthers);

  }

  return (
    <div className="compose_message-container composer-360">
    <Row className="uploaded-versions d-flex" style={{ marginLeft:0 }}>
      <ReactQuill value={newMessage}
        onChange={handleChangeEditor}
        placeholder="Add Comments Here....."
        theme="snow"
        modules={modules}
        id="new-comment"
        bounds={'.app'}
        ref={inputElement}
        className={messageError ? 'field-error' : ''}
     />
    </Row>
    <div className="compose-attachments">
      {props.attachingFiles?.length>0 &&
      (
        <h3>Attachments</h3>
      )}
      {props.attachingFiles?.map((file, index)=>
      {
        return(
          <div key={index} className="compose-attach-items">
            {['jpg', 'jpeg', 'png'].includes(file.file_type) ?
            (
              <div className="d-flex items">
               <div className="d-flex">
               <span className="items" style={{width:'auto'}}><img src={file.path} className="compose-attaches" /></span>
                <span className="compose-file-name"  id={"catttach"+index}><p>{file.file_name}</p></span>
                <UncontrolledTooltip  placement="top"  target={"catttach"+index} style={{maxWidth:'600px',width:'auto'}} >
                  {file.file_name}
                </UncontrolledTooltip >
               </div>
               
                <span onClick={()=>attachComposerFiles(file)} className="unlink-attach"><MaterialIcon icon="remove_circle"/> </span>
              </div>
            ):
            (
              <div className="d-flex items">
                  <div className="d-flex">
                   <span className="compose-file-ext" style={{width:'auto'}}>{file.file_type}</span>
                   <span className="compose-file-name"  id={"catttach"+index}><p>{file.file_name}</p></span>
                   <UncontrolledTooltip  placement="top"  target={"catttach"+index} style={{maxWidth:'600px',width:'auto'}} >
                      {file.file_name}
                    </UncontrolledTooltip >
                   </div>
                   <span onClick={()=>attachComposerFiles(file)}  className="unlink-attach" ><MaterialIcon icon="remove_circle"/></span>
              </div>
            )}
          </div>
        )
      })}

        <div className="source-view-folders d-flex">
                {mockupFolders?.length>0 &&(
                      <div className="other-folders">
                        <span className="folder-type-name">Mockups</span>
                        <div className="d-flex folder-items">
                          {mockupFolders?.map((outputfolder, index) =>
                          {
                            return(
                              <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                                <span className="folder_remove_btn" onClick={()=>deleteSourceViewFolder(3,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/> </span>
                                <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                  )}

                  {props.attachingFoldersOutput?.length>0 &&
                  (
                    <div className="output-folders">
                      <span className="folder-type-name">Output Folders</span>
                      <div className="d-flex folder-items">
                        {props.attachingFoldersOutput?.map((outputfolder, index) =>
                        {
                          return(
                            <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                              <span className="folder_remove_btn"  onClick={()=>deleteSourceViewFolder(1,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/></span>
                              <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {props.attachingFoldersSource?.length>0 &&(
                  <div className="source-folders">
                    <span className="folder-type-name">Source  Folders</span>
                    <div className="d-flex folder-items">
                      {props.attachingFoldersSource?.map((outputfolder, index) =>
                      {
                        return(
                           <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                              <span className="folder_remove_btn"  onClick={()=>deleteSourceViewFolder(2,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/></span>
                              <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                           </div>
                        )
                      })}
                    </div>
                  </div>
                  )}
                  {otherFoldersFiltered?.length>0 &&(
                  <div className="other-folders">
                    <span className="folder-type-name">Other Folders</span>
                    <div className="d-flex folder-items">
                      {otherFoldersFiltered?.map((outputfolder, index) =>
                      {
                        return(
                          <div key={outputfolder.id} className="cmh_foldercopy_wrap">
                            <span className="folder_remove_btn" onClick={()=>deleteSourceViewFolder(3,outputfolder.path)}><MaterialIcon icon="do_disturb_on"/> </span>
                            <span className="foldername"><a href={outputfolder.path} target="_blank">{outputfolder.folder_name}</a></span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                  )}
            </div>
    </div>
    <div className="d-flex com-360-with-btn">
      <Button 
        onClick={()=>saveComment(1)}  id={"postingprogress"}
          className={`btn btn-green task_status_change  btn btn-secondary false btn btn-secondary post-360-button icon-btn ${saveupdateProgress && 'disable-button'}`}>
            {saveupdateProgress ? <Spinner color="primary" size="sm"/> : ''}
      </Button>
      <UncontrolledTooltip  placement="top"  target={"postingprogress"} style={{maxWidth:'600px',width:'auto'}} >
          {'Post to In Progress'}
       </UncontrolledTooltip >
      <Button 
        onClick={()=>saveComment(2)}
          className={`btn btn-green task_status_change  btn btn-secondary false btn btn-secondary post-360-button ${saveupdateProgress && 'disable-button'}`}>
            {saveupdateProgress ? 'Posting...': 'Post to In Review'}
      </Button>
    </div>
    </div>
  );
}
