import React,{useState, useEffect} from "react";
import { urls } from "../../../services/UrlList";
import { SRLWrapper } from "simple-react-lightbox";
import SimpleReactLightbox from 'simple-react-lightbox';
import AttachmentIcon from "../../svgicon/AttachmentIcon";
import MaterialIcon from "material-icons-react";
import { Spinner } from "reactstrap";

import {Input,UncontrolledTooltip} from 'reactstrap';

export default function FileViewer(props)
{
    const [attachments, setAttachments] = useState(props.attachments);
    const [attachmentstext, setAttachmentsText] = useState(props.taskAttachmenttextarray);
    const [downloadProgress, setDownloadProgress] = useState(false);
    const [downloadId, setDownloadId] = useState([]);

     useEffect(() => {
        setAttachments(attachments);
    },[attachments]);
    
    const handleUpdate = ({target}) =>
    {
        let temp = [...attachmentstext];
        temp[target.name]=target.value;
        setAttachmentsText(temp);
        props.updatefiletext(props.arrayname, temp);
    }

    const toolValueGenerate = (filename)=>
    {
        return filename.replace(/[^a-z0-9]+/gi, "");
    }

    return(
        <div class={`attachment-items d-flex ${((props.listtype && props.listtype == 2)) && 'split-instructions' }`}>
            <SimpleReactLightbox className="test">
                <SRLWrapper className="test">
                {(attachments || []).map((attachitem, index) => (
                <div className="attch-items" key={index}>
             <div className="d-flex flex-nowrap">
                {attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpg' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'jpeg' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'JPG' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'JPEG' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'PNG' 
                || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'png' || attachitem.file_name.match(/\.([^\./\?]+)($|\?)/)[1] == 'gif' ?
            
                (
                <div className="attach_img_close">
                <a className="bg-medium-attachment"  href={`${urls.storageUrl}storage/leaves/${attachitem.path}/${attachitem.file_name}`} target="_blank">
                    <img src={`${urls.storageUrl}storage/leaves/${attachitem.path}/thumb-${attachitem.file_name}`} alt={attachitem.file_name}   />
                   <p>{attachitem.file_name}</p> 
                 </a>
                {props.deleteitem &&  props.deleteitem === 'yes' &&
                (
                    <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem.file_name, props.arrayname)}></span>
                )}
                </div>
                ):
                (
                <div className="attach_img_close">
                    <a href={`${urls.storageUrl}storage/leaves/${attachitem.path}/${attachitem.file_name}`} target="_blank" className="attachment-bg bg-small attach_other">
                        <span className="attachment-type">
                            <AttachmentIcon />
                            {attachitem.file_name.split('.').pop()}
                        </span>
                    </a>
                   {props.deleteitem &&  props.deleteitem === 'yes' &&
                    (
                        <span className="remove_img_btn" onClick={()=>props.deletefiles(attachitem.file_name, props.arrayname)}></span>
                    )}
                </div>
                )}
                </div>
                </div>
                ))}
                </SRLWrapper>
            </SimpleReactLightbox>
    </div>
    )
}
