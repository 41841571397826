import React, {useState, useEffect} from "react";
import { urls } from '../../services/UrlList';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import MaterialIcon from "material-icons-react";
import ConfirmAction from '../admincomment/ConfirmAction';

import {
    Container, Row, Col, Card, Input, Textarea, Button, Label, InputGroup, Tooltip, Breadcrumb, BreadcrumbItem,
    Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
  } from 'reactstrap';

export default function MyBoards(props)
{
    const [selectedBoard, setSelectedBoard]= useState([]);
    const [showAtionForm, setShowActionForm] = useState(false);
    const userBoards = async()=>
    {
        return await  axios.get(urls.getMyBoardNames);
    }
    const { isLoading, isError, error, isFetching, data:boards} = useQuery(['user-boards'], userBoards);
    const handleInputChange = (input, e) => {
        console.log("value", input)
    }

    const handleChange = (selectedOptions) => {
        var size = Object.keys(selectedOptions).length;
        if(size>0)
        {
            setSelectedBoard(selectedOptions[0]);
        }else
        {
            setSelectedBoard('');  
        }
    }

    const handleMoveActionTriger = ()=>
    {
        setShowActionForm(true);
    }

    const handleMoveAction = ()=>
    {
        setShowActionForm(true);
        props.moveboard(selectedBoard);
    }

    const closeDialogue=()=>
    {
        props.hidemodal();
    }
    return (
        <div>
            <Modal isOpen={props.movemodal} toggle={props.hidemodal} 
               className="addtodo_wrapper movetodo_pop copy_list_wrapper">
                <ModalHeader toggle={props.hidemodal}>Move Card
                    <Button className="clase_btn_cp" onClick={props.hidemodal}>
                        <MaterialIcon icon="close" /> 
                    </Button>
                </ModalHeader>
                <ModalBody>
                    {isLoading ? 
                    (
                        <LoadingWrapper isLoading={true} />
                    ):
                    (
                        <Typeahead
                        id="user-picker"
                        labelKey="name"
                        onInputChange={handleInputChange}
                        onChange={handleChange}
                        options={boards.data.projects}
                        placeholder="Choose board"
                        selected=''                        />
                    )}
                   {selectedBoard!='' &&
                   (
                    <div className="d-flex justify-content-center move-card-button">
                        <Button className="btn btn-green" onClick={handleMoveActionTriger}>Submit</Button>
                    </div>
                   )}
                </ModalBody>
            </Modal>
            {showAtionForm ? (
            <ConfirmAction
              purpose="change"
              closeDialogue={closeDialogue}
              show={showAtionForm}
              note="*Are you sure?"
              content="You are about to move this card"
              handlecardmovement={handleMoveAction}
              resolveid=""
              resolveStatus=""
              adminid=""
              confirmBoxAction="moveclientcard"
            />
          ) : null}
        </div>
    )
   
}