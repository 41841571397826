import React, { Component, Fragment } from 'react';
import { render } from "react-dom";
import Header from '../header/Header';
import Filter from "../filter/Filter";
import './list-head.css';
import './listcard.css';
import './LogTimer.css'
import './Healthreport.css'
import {urls} from "../../services/UrlList";
import SideMenu from "../NewSideMenu/NewSideMenu";
import { Container, Row, Col, Card, FormGroup, Input, Button } from 'reactstrap';
import LoadingWrapper from "../LoadingBox/LoadingWrapper/LoadingWrapper"
import {motion} from 'framer-motion'
import { Typeahead } from 'react-bootstrap-typeahead';
import StarRatings from 'react-star-ratings';
import moment from "moment";
import DatePickerTick from "../filter/DatePickerTick";
import ReactQuill from 'react-quill'; 
import Commenticonblack from '../../images/comment_black.svg';
import Commenticongreen from '../../images/comment_green.svg';
import Statuswating from '../../images/statusicon_waiting.svg';
import Closepopup from '../../images/close-icon.png';
import RatioFilter from "../filter/RatioFilter";

export default class ClientRatio extends Component {
  constructor( props ) {
    super( props );
    this.state = {         
      myPrivileges:[], 
      myId:'',  
      myPrivileges:[],
      orginalUsers: [],
      orginalClients:[],
      singleCollection:[],
      orginalReportsData:[],
      ratingFilter:false,
      initialLoader:false,
      reportsDataPM:[],
      reportsDataPL:[],
      showCommentBox:false,
      showMenu:true
    };
  }

  componentDidMount =() =>
  {   
    document.title = "HUB - Client Health Report";
    this.checkUserPrivilege(); 
    const _this = this;
    setTimeout(function() {     
     // _this.getMyTrelloCollection();
        _this.getAllCollections();
        _this.getAllClients();
        _this.searchClientRatio();
    }, 200);
  };

  signOut = () => {
    fetch(urls.logmeout)
      .then(response => response.json())
      .then(response => {
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch(error => {
        localStorage.clear();
        this.props.history.push("/");      
          fetch(urls.logmeout)
            .then(response => response.json())
            .then(response => {
              localStorage.clear();
              this.props.history.push("/");
            })
            .catch(error => {
              localStorage.clear();
              this.props.history.push("/");
            });
       
      });
  };

  checkUserPrivilege = () =>
  {
    if(localStorage.getItem("user") !== null)
    {
      var login = JSON.parse( localStorage.getItem("user") );
      this.setState({myPrivileges:login.privileges, userTeam:login.user_team, myId:login.uid, myBaseId:login.basecampid });
    }
    if(!login.privileges.includes('health_report'))
    {
      this.props.history.push("/home");
    }
  };


  toggleSidebar = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  hideMenu = () =>
  {
     this.setState({showMenu: !this.state.showMenu});
  }

  searchClientRatio = (startdate, enddate, collection) =>{  
    this.setState({initialLoader:true});
   
    fetch(urls.findClientRatio,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {
        console.log(response);
        this.setState({
            reportsDataPM: response.pms,
            reportsDataPL: response.pls,
            initialLoader:false
          });
    })
  };

  filterByRating = (items) =>
  {
    var list = [];
    var temp = items;
    temp.forEach(p1=>
      {   
      if(p1.rating<=4)
       {
         list.push(p1);
       }
      }) 
    this.setState({reportsData: list});
  }

  filterList = (rating) =>
  {
    if(rating)
    {
      var list = [];
      var temp = this.state.orginalReportsData;
      temp.forEach(p1=>
        {   
        if(p1.rating<=4)
        {
          list.push(p1);
        }
        }) 
      this.setState({reportsData: list});
    }else
    {
      this.setState({reportsData: this.state.orginalReportsData});
    }
  }

  getAllCollections = () =>{        
    fetch(urls.getAllTrelloCollections,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalUsers: response    
        });            
    })
  };

  getAllClients = () =>{        
    fetch(urls.getTrelloClients,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json"
        }
      }
    )
    .then(response => response.json())
    .then(response => {   
        this.setState({
            orginalClients: response.clients    
        });            
    })
  };


  
  onChangePeople = (e) => {
    var size = Object.keys(e).length;
    if(size>0)
    {
    this.setState({ selectedUser:e[0].collection});
    const _this = this;
    setTimeout(() => {
      _this.getSingleBoard();
    }, 100);
    }else
    {
      this.setState({showSingle:false});
      this.getAllBoards();
    }
  };

  handleChange = (selectedOptions) => {
    var size = Object.keys(selectedOptions).length;
    if(size>0)
    {
      this.setState({ selectedUser:selectedOptions[0].collection});
    }
  }
  
  handleInputChange(input, e) {
    console.log("value", input)
  }

  
  showCommentBox = (id) =>
  {
    
    var showindex = this.state.singleCollection.map((el) => el.board_id).indexOf(id);  
    this.setState({
          showCBox:true,
          showCommentId:showindex
      })
  }

  closeCommentBox = () =>
  {
    this.setState({
        showCommentBox:false, 
    });
  }

  render() {
    const variants = {
      hidden: { opacity: 0 },
      visible: { opacity: 1 },
    }
    return (
      <div className="fullwidth_mobilemenu mobileflex">
      <Container fluid className={(this.state.myPrivileges.includes('i_am_designer')? 'top-bar-active': 'top-bar-inactive')+ " log-timer trello"}> 
      <Row>
        <div className="theme_header">
        <Header {...this.props}  classname="tick-timer-header"  
        logout={this.signOut.bind(this)} 
        onmenuaction={this.toggleSidebar.bind(this)}
        quickactionblock={true} />
        </div>
        <div className="SideNavbar">
        <SideMenu activeitem="report" showmenu={this.state.showMenu} hidemenu={this.hideMenu.bind(this)} />
       </div>
        <div className="themeContentBody">
           <Row>
            <Col sm={12} lg={12}>
            <Card className="cl_helth_report">
               <h1 class="page-title text-center her_title">Client - Designer Ratio</h1>
        {/* <RatioFilter peoplelist = {this.state.orginalUsers} 
        searchReportAction={this.searchClientRatio}  /> */}
<motion.div 
                initial="hidden"
                animate="visible"
                variants={variants}>
          <LoadingWrapper className="screenshot_wrap" isLoading={this.state.initialLoader}>
                      <div className="health_rpot_header pm_ratio_header">
                        <Row className="inner-row-wraper">
                            <Col sm="2"><span className="report-tab-head">PM</span></Col> 
                            <Col sm="1"><span className="report-tab-head">Date</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Boards</span></Col>  
                            <Col sm="2"><span className="report-tab-head">Designers</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Graphics</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Video</span></Col> 
                            <Col sm="1"><span className="report-tab-head">Ratio</span></Col> 
                        </Row>
                      </div>
                
                      {this.state.reportsDataPM.map((item, index) =>
                          {
                        return(
                          <Row key={item.key} className="inner-row-wraper-for-list">
                              <Col sm="2" className="font-weight-bold">{item.member}</Col>  
                              <Col sm="1">{item.shift}</Col>   
                              <Col sm="2">{item.boards_assigned}</Col>  
                              <Col sm="2">{item.designers_assigned}</Col> 
                              <Col sm="2">{item.graphics_count}</Col> 
                              <Col sm="2">{item.video_count}</Col>
                              <Col sm="1">{item.ratio}</Col>
                          </Row>
                        )
                    })}
                    <>
                    <div className="health_rpot_header pl_ratio_header">
                        <Row className="inner-row-wraper">
                            <Col sm="2"><span className="report-tab-head">PL</span></Col> 
                            <Col sm="1"><span className="report-tab-head">Date</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Boards</span></Col>  
                            <Col sm="2"><span className="report-tab-head">Designers</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Graphics</span></Col> 
                            <Col sm="2"><span className="report-tab-head">Video</span></Col> 
                            <Col sm="1"><span className="report-tab-head">Ratio</span></Col> 
                        </Row>
                      </div>
                    {this.state.reportsDataPL.map((item, index) =>
                        {
                            return(
                            <Row key={item.key} className="inner-row-wraper-for-list" >
                                <Col sm="2" className="font-weight-bold">{item.member}</Col>  
                                <Col sm="1">{item.shift}</Col>  
                                <Col sm="2">{item.boards_assigned}</Col>  
                                <Col sm="2">{item.designers_assigned}</Col> 
                                <Col sm="2">{item.graphics_count}</Col> 
                                <Col sm="2">{item.video_count}</Col>
                                <Col sm="1">{item.ratio}</Col>
                            </Row>
                            )
                        })}
                        </>
        </LoadingWrapper>
        </motion.div>
            </Card>
            </Col>
          </Row>
        </div>

      </Row>
      </Container>
      </div>
    )
  }
}
