

const TaskRunnerReducer = (state = 0, action) =>
{
    switch(action.type)
    {
        case 'TaskStarting':
            return state+1;
        case 'TaskEnding':
            return state+1;
        default:
            return state;
    }
}

export default TaskRunnerReducer;